import React from 'react';

// Pages
import BasePage from 'pages/BasePage';
import RecommendedAction from 'container/RecommendedAction';
import { RecommendedActionHeader } from 'constants/table-header-constants';

const RecommendedActionList = () => {
  return (
    <BasePage>
      <RecommendedAction tableHeader={RecommendedActionHeader} />
    </BasePage>
  );
};

export default RecommendedActionList;
