import RadioButton from 'app/shared/components/RadioButton';
import External from './ContentType/External';
import Button from 'app/components/button';
import './index.scss';
import { useState, useEffect } from 'react';
import Video from './ContentType/Video';
import SelfHosted from './ContentType/SelfHosted';
import Shorts from '../CmsContent/Content-Types/Shorts';
import Quiz from '../CmsContent/Content-Types/Quiz';
import { useHistory, useLocation } from 'react-router';
import Toast from 'app/utils/Toast';
import PageLoader from 'app/components/PageLoader';
import {
  bulkUploadContentApi,
  bulkUploadShortsApi,
} from 'app/api/createContentAPI';
import TabBar from 'app/components/NewDesign/TabBar';
import useTabBarOptions from 'app/utils/useTabbarOptions';

const CreateContentContainer = () => {
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  let history = useHistory();
  useEffect(() => {
    if (location.state) {
      setSelectedItem(location.state?.content_type);
      setEdit(true);
    } else {
      setSelectedItem('EXTERNAL_ARTICLE');
    }
    return () => {
      window.history.replaceState({}, document.title);
    };
  }, []);

  const [selectedItem, setSelectedItem] = useState('');
  const [edit, setEdit] = useState(false);
  const radioMenu = [
    {
      label: 'External Article',
      id: 'extArtcl',
      value: 'EXTERNAL_ARTICLE',
    },
    {
      label: 'Self Hosted Article',
      id: 'SELF_HOSTED_ARTICLE',
      value: 'SELF_HOSTED_ARTICLE',
    },
    {
      label: 'Video',
      id: 'video',
      value: 'VIDEO',
    },
    {
      label: 'Shorts',
      id: 'shorts',
      value: 'SHORTS',
    },
    {
      label: 'Quiz',
      id: 'quiz',
      value: 'QUIZ',
    },
  ];
  const radioButtonSelected = (item) => {
    setSelectedItem(item.value);
  };

  const bulkUpload = async (e) => {
    setLoader(true);
    let file = e.target.files[0];
    if (file?.type !== 'application/x-zip-compressed') {
      Toast.error('Invalid Format. Please select a zip file');
      setLoader(false);
      return;
    }
    let resp;
    if (selectedItem === 'SHORTS') {
      resp = await bulkUploadShortsApi(file);
      if (resp && resp?.length !== 0) {
        history.push({
          pathname: '/cms/bulk-summary',
          state: resp,
        });
      }
    } else {
      resp = await bulkUploadContentApi(file);
      if (resp && resp?.length !== 0) {
        history.push({
          pathname: '/cms/bulk-summary',
          state: resp,
        });
      }
    }
    setLoader(false);
    e.target.value = null;
  };
  const { LanguageOptions } = useTabBarOptions();
  const [activeTab, setActiveTab] = useState({
    id: '1',
    name: 'English',
    short_name: 'en',
    display_order: 1,
    status: 'ACTIVE',
    label: '+ English',
    value: 'English',
  });
  const handleTabChange = (val) => {
    setActiveTab(val);
    // Perform any other actions on tab change
  };

  return (
    <div className='container'>
      <h1 className='section-header__title'>
        {edit ? 'Edit' : 'Create'} Content
      </h1>
      <div className='content-container'>
        <div className='content-selector'>
          <label className='head-label'>Select Content Type</label>
          {radioMenu.map((item, key) => (
            <RadioButton
              isDisabled={edit}
              id={item.id}
              label={item.label}
              isChecked={selectedItem === item.value ? true : false}
              value={item.value}
              onSelect={() => {
                radioButtonSelected(item);
              }}
              name={item.label}
            />
          ))}
        </div>

        {selectedItem !== 'QUIZ' && !edit && (
          <>
            <div>
              <h4>Or</h4>
            </div>
            <div>
              <input
                type='file'
                id='bulkuploadfile'
                style={{ display: 'none' }}
                onChange={bulkUpload}
              />
              <Button
                name='Bulk Upload'
                onClick={() => {
                  const id = document.getElementById('bulkuploadfile');
                  id.click();
                }}
              />
            </div>
          </>
        )}
      </div>
      {location?.state?.id && (
        <TabBar
          tabs={LanguageOptions}
          initialTab={activeTab}
          onTabChange={handleTabChange}
          parentStyle={'service-tab-style'}
        />
      )}
      {selectedItem === 'EXTERNAL_ARTICLE' && (
        <External data={location.state} activeTab={activeTab.short_name} />
      )}
      {selectedItem === 'SELF_HOSTED_ARTICLE' && (
        <SelfHosted data={location.state} activeTab={activeTab.short_name} />
      )}
      {selectedItem === 'VIDEO' && (
        <Video data={location.state} activeTab={activeTab.short_name} />
      )}
      {selectedItem === 'SHORTS' && (
        <Shorts data={location.state} activeTab={activeTab.short_name} />
      )}
      {selectedItem === 'QUIZ' && (
        <Quiz data={location.state} activeTab={activeTab.short_name} />
      )}
      {loader && (
        <PageLoader pageLoading={loader} activeTab={activeTab.short_name} />
      )}
    </div>
  );
};

export default CreateContentContainer;
