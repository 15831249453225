import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import validate from './validateInfo';

// Utils
import { addCoins, getCoinsById, updateCoins } from 'app/api/latest/CoinsAPI';
import { getProductFamilyList } from 'app/api/latest/CouponAndOffer/couponAndOffer';
import Message from 'utils/Message';
import Toast from 'utils/Toast';
const useCreateCoins = () => {
  const history = useHistory();
  const location = useLocation();
  const editorRef = useRef(null);
  const [toggleStatus, setToggleStatus] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [productFamilyList, setproductFamilyList] = useState([]);

  const [values, setValues] = useState({
    id: '',
    product_family: '',
    discount: null,
    orders_above: null,
    maximum_discount_amount: null,
    status: false,
  });
  const couponData = location.state;
  useEffect(() => {
    getProductFamilyValues();

    if (couponData?.id) {
      loadCouponData(couponData?.id);
    }
  }, []);

  const getProductFamilyValues = async () => {
    setPageLoading(true);
    let response = await getProductFamilyList();

    if (response && response?.response?.code === 200) {
      let temp =
        (await response?.data) &&
        response?.data.map((item, index) => {
          return {
            ...item,
            value: item.id,
            label: item.value,
          };
        });
      setproductFamilyList(temp);
      setPageLoading(false);
    } else {
      setPageLoading(false);
      if (response?.data?.error) {
        Toast.error(
          response?.data?.error
            ? response?.data?.error
            : Message?.Error?.COMMON_MESSAGE
        );
      }
    }
    setPageLoading(false);
  };
  const loadCouponData = async (coupon_id) => {
    if (!coupon_id) {
      return;
    }
    setPageLoading(true);
    let response = await getCoinsById(coupon_id);
    setPageLoading(false);
    if (response?.response?.code === 200) {
      let responsedata = response.data;
      // const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
      setValues((prevState) => ({
        id: responsedata.id ? responsedata.id : '',
        product_family: responsedata.product_type
          ? responsedata.product_type
          : '',
        discount: responsedata.discount ? responsedata.discount : 0,
        orders_above: responsedata.orders_above ? responsedata.orders_above : 0,
        maximum_discount_amount: responsedata.maximum_discount_amount,
        status:
          responsedata.status && responsedata.status === 'ACTIVE'
            ? true
            : false,
      }));
      setToggleStatus(responsedata.status === 'ACTIVE' ? true : false);
      updateValue(responsedata);
    } else {
      Toast.error(
        response?.data ? response?.data : Message.Error.COMMON_MESSAGE
      );
    }
  };

  const callAddOrUpdateAPI = async () => {
    let data = {
      product_family: values.product_family,
      discount: values.discount,
      orders_above: values.orders_above,
      maximum_discount_amount: values.maximum_discount_amount,
      status: toggleStatus,
    };

    setPageLoading(true);
    let response = {};

    if (location?.state?.id) {
      response = await updateCoins(data, couponData?.id);
    } else {
      response = await addCoins(data);
    }
    setPageLoading(false);
    if (response?.response?.code === 200 || response?.response?.code === 201) {
      Toast.success(
        response?.response?.code === 200
          ? Message?.Coins?.UPDATED_SUCCESS
          : Message?.Coins?.CREATED_SUCCESS
      );
      history.goBack();
    } else {
      Toast.error(
        response?.data?.error
          ? response?.data?.error
          : Message?.Error?.COMMON_MESSAGE
      );
    }
  };

  const handleCreateFAQ = (e) => {
    const validationStatus = validate(values);
    if (!validationStatus) {
      callAddOrUpdateAPI();
    }
  };

  const error = {};

  const updateValue = (obj) => {
    setValues({
      ...obj,
    });
  };

  const resetValues = () => {
    setValues({
      id: '',
      product_family: '',
      discount: null,
      orders_above: null,
      maximum_discount_amount: null,
      status: false,
    });
  };

  const handleChange = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const handleToggleChange = () => {
    setToggleStatus(!toggleStatus);
  };
  return {
    updateValue,
    resetValues,
    toggleStatus,
    handleToggleChange,
    handleChange,
    values,
    error,
    setValues,
    editorRef,
    handleCreateFAQ,
    pageLoading,

    productFamilyList,
  };
};

export default useCreateCoins;
