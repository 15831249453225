import { deleteTagApi, tagPublishOrUnpublishAPI } from "app/api/cmsTagsApi";
import Toast from "app/utils/Toast";
import { useHistory } from "react-router";

const TagsTable = ({ tags, onGetTagsHandler, currentPage }) => {

     const history = useHistory();


     const handleDelete = async (id) => {
          await deleteTagApi(id);
          onGetTagsHandler()
     }
     const publishOrUnpublish = async (tag) => {
          const query = {
               "ACTIVE": "unpublish",
               "INACTIVE": "PUBLISH"
          }
          const resp = await tagPublishOrUnpublishAPI(tag.id, query[tag.status])
          if (resp?.data?.length != 0) {
               Toast.success("Updated Successfully")
          }
          onGetTagsHandler();
     }

     return (<table>
          <tr>
               <th>Backend Tag name</th>
               <th>Tag Name</th>      
               <th>Updated Date </th>
               <th>Status </th>
               <th>Tag Type</th>
               <th>Action</th>
          </tr>
          {tags.map((tag) => (<tr key={tag.id}>
               <td>{tag.formatted_tag || "-"}</td>
               <td>{tag.tag_name || "-"}</td>
               <td>{tag.updated_on || "-"}</td>
               <td>{tag.status || "-"}</td>
               <td>{tag.tag_type || "-"}</td>
               <td>
                    <div className="btn-group" role="group" aria-label="Basic outlined example">
                         <button
                              type="button"
                              className="btn btn-outline-primary"
                              onClick={() => {
                                   history.push({
                                        pathname: `/cms/tags/create`,
                                        state: {
                                             ...tag,
                                             comingFrom: currentPage
                                        },
                                   });
                              }}
                         >
                              Edit
                         </button>
                         <button type="button"
                              onClick={() => handleDelete(tag.id)}
                              className="btn btn-outline-primary">Delete</button>
                         <button onClick={() => publishOrUnpublish(tag)} type="button" className="btn btn-outline-primary">{tag.status == "ACTIVE" ?
                              "Unpublish"
                              :
                              "Publish"
                         }</button>
                    </div>
               </td>
          </tr>))}
     </table>)
};

export default TagsTable;