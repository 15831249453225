// Ajax
import * as dataAccess from 'utils/ajax';
import ApiParser from 'utils/ApiParser';

// Constants
import { API_CONSTANTS } from 'constants/api-contants';

export const getQuiz = async (start) => {
  let payload = {
    data: {
      page: start ? start : 1,
      limit: 10,
    },
  };

  let responseObj = await dataAccess.post(API_CONSTANTS.GET_QUIZ, payload);
  return ApiParser.parseQuizListing(responseObj);
};

export const getQuizDetail = async (quizId) => {
  if (!quizId) {
    return;
  }

  let payload = {
    data: {
      quiz_id: parseInt(quizId),
    },
  };

  return await dataAccess.post(API_CONSTANTS.GET_QUIZ_DETAIL, payload);
};

export const addOrUpdateQuiz = async (incomingData) => {
  let payload = {
    data: {
      publish: 1,
      ...incomingData,
    },
  };

  if (incomingData.quiz_id) {
    return await dataAccess.put(API_CONSTANTS.UPDATE_QUIZ, payload);
  } else {
    return await dataAccess.post(API_CONSTANTS.ADD_QUIZ, payload);
  }
};

export const deleteQuiz = async (articleId) => {
  if (!articleId) {
    return;
  }

  let payload = {
    data: {
      article_id: articleId,
    },
  };
  return await dataAccess.del(API_CONSTANTS.DELETE_QUIZ, payload);
};
