import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import Loader from 'components/Loader';
import ArticleComponent from 'components/ArticleComponent';
import NewTable from 'components/NewTable';
import PageLoader from 'components/PageLoader';

// Constants
import { APP_CONSTANTS } from 'constants/app-constants';

// API
import {
  getBreathefree,
  deleteBreathefree,
  publishOrUnpublishBreatheFree,
} from 'app/api/breathefree';

// Utils
import Toast from 'utils/Toast';
import Message from 'utils/Message';

const BreatheFree = ({ tableHeader }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [pageLoading, setPageLoading] = useState(false);

  const [feedSummary, setFeedSummary] = useState({
    totalUser: 0,
    inActiveUser: 0,
    activeUser: 0,
  });

  const loadNames = (start, end) => {
    setLoading(true);
    getBreathefree(start, end).then((response) => {
      setLoading(false);
      if (response && response.tableData && response.error === false) {
        setData({
          tableData: response.tableData,
        });
        // setFeedSummary(response.summary);
      }
    });
  };

  const handleResponseCallback = (response) => {
    if (response && response.tableData && response.error === false) {
      setFeedSummary(response.summary);
    }
  };

  const callDeleteAPI = async (data) => {
    setPageLoading(true);
    if (data && data.educator_video_id) {
      let responseObj = await deleteBreathefree(data.educator_video_id);
      setPageLoading(false);
      if (
        responseObj &&
        responseObj.response &&
        responseObj.response.code === 200
      ) {
        loadNames();
        Toast.success(Message.User.DELETE_SUCCESS);
      }
    } else {
      Toast.error(Message.User.DELETE_FAILURE);
    }
  };

  const callPublishOrUnpublishAPI = async (data) => {
    // console.log(data, 'breathefreedata');
    setPageLoading(true);
    if (data && data.educator_video_id && data.status) {
      let responseObj = await publishOrUnpublishBreatheFree(
        data.educator_video_id,
        data.status
      );
      setPageLoading(false);
      if (
        responseObj &&
        responseObj.response &&
        responseObj.response.code === 200
      ) {
        loadNames();
        let message = Message.Breathefree.PUBLISH_UNPUBLISH_SUCCESS.replace(
          '{{placeholder}}',
          data.status.toLowerCase() === 'active' ? 'Unpublished' : 'Published'
        );
        Toast.success(message);
      }
    } else {
      let message = Message.Breathefree.PUBLISH_UNPUBLISH_FAILURE.replace(
        '{{placeholder}}',
        data.status.toLowerCase() === 'active' ? 'Unpublish' : 'Publish'
      );
      Toast.error(message);
    }
  };

  const menuActionObj = [
    {
      name: 'Edit',
      onClick: (e, data) => {
        history.push({
          pathname: `/breathe-free/${data.educator_video_id}`,
          state: {
            ...data,
          },
        });
      },
    },

    {
      name: 'Publish',
      type: 'publish',
      onClick: (e, data) => {
        callPublishOrUnpublishAPI(data);
      },
    },
    {
      name: 'Delete',
      onClick: (e, data) => {
        let text = 'are you sure,want to delete';
        if (window.confirm(text) == true) {
          callDeleteAPI(data);
          // text = 'You pressed OK!';
        } else {
          // text = 'You canceled!';
        }
      },
    },
  ];

  const sectionObj = {
    sectionTitle: 'BreatheFree',
    // searchObj: {
    //   placeholder: 'Search',
    //   handleSearch: (e) => {
    //     console.log('Handle search: ', e);
    //   },
    // },
    buttons: [
      {
        title: 'Create Video',
        handleBtnClick: (e) => {
          history.push(APP_CONSTANTS.BREATEFREE_CREATE);
        },
      },
    ],
  };

  if (loading === true) {
    return <Loader />;
  }

  return (
    <div className='blogs'>
      <NewTable
        sectionObj={sectionObj}
        tableHeader={tableHeader}
        menuAction={menuActionObj}
        data={data}
        getData={getBreathefree}
        // searchKey='first_name'
        responseCallback={handleResponseCallback}
      />
      {pageLoading === true && <PageLoader pageLoading={pageLoading} />}
    </div>
  );
};

export default BreatheFree;
