/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router";

import moment from "moment";
// Components

import NewInput from "components/NewInput";
import Attachment from "components/Attachment";
import Button from "components/button";
import useContentForm from "components/useContentForm";
import validate from "components/ValidateInfo";
import PageLoader from "components/PageLoader";
import HeaderWithBackButton from "components/HeaderWithBackButton";
import NumberInput from "components/UI/NumberInput";
import RadioButton from "app/shared/components/RadioButton";
import TabBar from "app/components/NewDesign/TabBar";

// API
// import { addOrUpdateFeed, getArticleId } from 'api/feed';

import {
  addOrUpdateHabit,
  getHabitTip,
  getHabit,
  addOrUpdateHabitTip,
  addOrUpdateHabitTips,
  getHabitTipDetails,
  updateHabitLocalisationDetails,
  updateTipLocalisationDetails,
} from "app/api/habits";

// Constants
import { APP_CONSTANTS, URLType } from "constants/app-constants";

// Utils
import Toast from "utils/Toast";
import Message from "utils/Message";
import ValidationMessage from "utils/ValidationMessage";
import { Col } from "react-bootstrap";
// import NewTable from 'app/components/NewDraggableTable';
// import { addOrUpdateHabitTip } from 'app/api/habits';
import "./index.scss";
import useTabBarOptions from "app/utils/useTabbarOptions";

const CreateHabits = ({ onPreview, ...props }, row) => {
  const history = useHistory();
  const location = useLocation();
  const [id, setId] = useState(null);
  const [selFile, setSelectedFile] = useState({});
  const [selImg, setSelImg] = useState({});
  const [selbtnImg, setSelbtnImg] = useState({});
  const [image, setImage] = useState({});
  const [HabitId, setHabitId] = useState();
  const [videoThumbnail, setVideoThumbnail] = useState({});

  const [pageLoading, setPageLoading] = useState(false);
  const [htmlContent, setHTMLContent] = useState("");

  const [inputFields, setInputFields] = useState([]);
  const [activeTab, setActiveTab] = useState({
    id: "1",
    name: "English",
    short_name: "en",
    display_order: 1,
    status: "ACTIVE",
    value: "English",
  });
  // console.log(location, 'locationlocationlocation');
  const { handleChange, values, error, updateValue } = useContentForm(validate);
  const { LanguageOptions } = useTabBarOptions();

  useEffect(() => {
    let data = location.state;
    tipList();
    if (data && data.habit_id) {
      setHabitId(data.habit_id);
      setId(data.id);
    }
  }, [activeTab.short_name]);

  const handlePayloadText = (e, index) => {
    const findIndex = inputFields.findIndex(
      (item) => item?.id === Number(e.target.id)
    );
    const data = [...inputFields];
    data[findIndex].content = e.target.value;
    if (!data[findIndex].isAdd) {
      data[findIndex].isEdited = true;
    }
    setInputFields(data);
  };
  const tipPayload = (qid) => {
    // debugger;
    const tipEditData = [];
    const tipAddData = [];

    inputFields.map((item) => {
      if (item.isEdited) {
        if (activeTab?.name === "English") {
          tipEditData.push({
            publish: item.status === "INACTIVE" ? false : true,
            habit_id: HabitId,
            content: item.content,
            tip_id: item.id,
          });
        } else {
          tipEditData.push({
            content: item.content,
            tip_id: item.id,
          });
        }
      }
      if (item.isAdd) {
        tipAddData.push({
          publish: true,
          habit_id: HabitId,
          content: item.content,
        });
      }
    });

    const payload = {
      update_tip: 1,
      tip_data: tipEditData,
    };

    return payload;
  };

  const HabittipPayload = (qid) => {
    const tipEditData = [];
    const tipAddData = [];

    inputFields.map((item) => {
      if (item.isEdited) {
        tipEditData.push({
          publish: true,
          habit_id: HabitId,
          content: item.content,
          tip_id: item.id,
        });
      }
      if (item.isAdd) {
        tipAddData.push({
          publish: true,
          habit_id: HabitId,
          content: item.content,
        });
      }
    });

    const payload = {
      update_tip: 0,
      tip_data: tipAddData,
    };

    return payload;
  };
  const populatePayload = (qid) => {
    /* Populate Data */
    let {
      title,
      HabitId,
      HabitName,
      Image,
      ScratchImage,
      PlayBtn,
      tipValue,
      AllotedDays,
      PerDayPoints,
      TotalPoints,
      attachmentFile,
      attachment,
    } = values;
    let data = {
      update_habit: id,
      habit_id: id,
    };
    if (HabitName) {
      if (APP_CONSTANTS.EDIT_HABITS) {
        if (HabitName !== location?.state?.habit_name) {
          data.habit_name = HabitName;
        }
      } else {
        if (HabitName) {
          data.habit_name = HabitName;
        }
      }
    }
    if (activeTab?.name === "English") {
      if (attachmentFile) {
        if (APP_CONSTANTS.EDIT_HABITS) {
          if (attachmentFile !== location?.state?.habit_detail_image) {
            data.habit_detail_image = attachmentFile;
          }
        } else {
          if (attachmentFile) {
            data.habit_detail_image = attachmentFile;
          }
        }
      }

      if (attachment) {
        if (APP_CONSTANTS.EDIT_HABITS) {
          if (attachment !== location?.state?.habit_list_image) {
            data.habit_list_image = attachment;
          }
        } else {
          if (attachment) {
            data.habit_list_image = attachment;
          }
        }
      }

      if (AllotedDays) {
        if (APP_CONSTANTS.EDIT_HABITS) {
          if (AllotedDays !== location?.state?.alloted_days) {
            data.alloted_days = AllotedDays;
          }
        } else {
          if (AllotedDays) {
            data.alloted_days = AllotedDays;
          }
        }
      }

      if (PerDayPoints) {
        if (APP_CONSTANTS.EDIT_HABITS) {
          if (PerDayPoints !== location?.state?.per_day_alloted_point) {
            data.per_day_alloted_point = PerDayPoints;
          }
        } else {
          if (PerDayPoints) {
            data.per_day_alloted_point = PerDayPoints;
          }
        }
      }

      if (TotalPoints) {
        if (APP_CONSTANTS.EDIT_HABITS) {
          if (TotalPoints !== location?.state?.total_alloted_point) {
            data.total_alloted_point = TotalPoints;
          }
        } else {
          if (TotalPoints) {
            data.total_alloted_point = TotalPoints;
          }
        }
      }
    }
    if (!HabitName) {
      Toast.error(ValidationMessage.HABIT_NAME);
      return;
    }
    // AllotedDays,
    // PerDayPoints,
    //   TotalPoints,
    // debugger;
    if (activeTab?.name === "English") {
      if (AllotedDays <= "1") {
        Toast.error(ValidationMessage.ALLOTEDDAYS);
        return;
      }
      if (!PerDayPoints) {
        Toast.error(ValidationMessage.PERDAYPOINTS);
        return;
      }
      if (!TotalPoints) {
        Toast.error(ValidationMessage.TOTALPOINTS);
        return;
      }
      if (!attachmentFile) {
        Toast.error(ValidationMessage.ATTACHMENT);
        return;
      }

      if (!attachment) {
        Toast.error(ValidationMessage.ATTACHMENT_LIST);
        return;
      }
    }
    // if (!inputFields.content === 0) {
    //   Toast.error(ValidationMessage.TIP_LIST);
    //   return;
    // }

    // if (!image) {
    //   Toast.error(ValidationMessage.ATTACHMENT);
    //   return;
    // }

    if (title) {
      if (APP_CONSTANTS.EDIT_HABIT) {
        if (title !== location?.state?.title) {
          data.title = title;
        }
      } else {
        if (title) {
          data.title = title;
        }
      }
    }

    return data;
  };

  const callAddOrUpdateAPI = async () => {
    let data = populatePayload();
    if (!data) return;

    setPageLoading(true);
    let responseObj;
    if (activeTab?.name === "English") {
      responseObj = await addOrUpdateHabit(data);
    } else {
      let payload = {
        habit_name: data?.habit_name,
        lang: activeTab?.short_name,
      };
      responseObj = await updateHabitLocalisationDetails(payload, HabitId);
    }

    setPageLoading(false);
    if (
      responseObj &&
      responseObj.response &&
      responseObj.response.code === 200
    ) {
      // LocalStorage.remove(LocalSessionKey.FEED_ID);
      if (HabitId) {
        Toast.success(Message.Habit.UPDATED_SUCCESS);
      } else {
        Toast.success(Message.Habit.CREATED_SUCCESS);
      }
      history.goBack();
    } else {
      Toast.error(Message.Habit.CREATE_FAILURE);
    }
  };

  const callAddOrUpdateAPITIP = async () => {
    let data = tipPayload();
    let datatip = HabittipPayload();
    // console.log(data, "tipPayload");
    // return;
    if (!data) return;
    setPageLoading(true);
    let responseObj;
    if (activeTab?.name === "English") {
      responseObj = await addOrUpdateHabitTip(data);
      const responseObjj = await addOrUpdateHabitTips(datatip);
    } else {
      const payload = {
        lang: activeTab?.short_name,
        tip_list: data.tip_data,
      };
      responseObj = await updateTipLocalisationDetails(payload);
    }

    setPageLoading(false);
    if (
      responseObj &&
      responseObj.response &&
      responseObj.response.code === 200
    ) {
      // LocalStorage.remove(LocalSessionKey.FEED_ID);
      if (data) {
        Toast.success(Message.HabitTip.UPDATED_SUCCESS);
      } else {
        Toast.success(Message.HabitTip.CREATED_SUCCESS);
      }
      //   history.goBack();
    } else {
      Toast.error(Message.HabitTip.CREATE_FAILURE);
    }
  };

  // for (var inputFields = 0; j < 4; j++) {}

  const handleCreateHabit = (e) => {
    if (inputFields.length === 0) {
      Toast.error(ValidationMessage.TIP_LIST);
      return;
    }
    // if (!inputFields.content.length[0] === 0) {
    //   Toast.error(ValidationMessage.TIP_DATA);
    //   return;
    // }
    // debugger;
    for (var i = 0; i < inputFields?.length; i++) {
      if (!inputFields[i].content || inputFields[i]?.content.length === 0) {
        Toast.error(ValidationMessage.TIP_DATA);
        return;
      }
      // Toast.error(ValidationMessage.TIP_DATA);
      // return;
    }

    callAddOrUpdateAPI();
    callAddOrUpdateAPITIP();
  };

  const tipList = async () => {
    const resp = await getHabitTipDetails(
      location.state.habit_id,
      activeTab?.short_name
    );
    if (resp.data) {
      const data = resp.data;
      let newData = {
        HabitId: data.id,
        HabitName: data.habit_name ? data.habit_name : "",
        TotalPoints: data.total_alloted_point,
        PerDayPoints: data.per_day_alloted_point,
        attachmentFile: data.detail_image_url,
        attachment: data.habit_list_image,
        AllotedDays: data.alloted_days,
        HabitTipId: data.tip_id,
        HabitTipContent: data.content,
        HabitStatus: data.status,
      };
      updateValue(newData);
      setInputFields(data.tip_list);
    }
  };

  const handleRadioButtonClick = (e) => {
    const findIndex = inputFields.findIndex(
      (item) => item.id === Number(e.target.id)
    );
    const data = [...inputFields];

    if (!data[findIndex].isAdd) {
      data[findIndex].isEdited = true;
    }

    data[findIndex].status = e.target.value === "false" ? "INACTIVE" : "ACTIVE";
    setInputFields(data);
  };

  const onFileSelected = (file, id) => {
    let data = { ...values };

    data["attachmentFile"] = file;

    // if (file === null || file === undefined) {
    //   setVideoThumbnail(null);
    // }

    updateValue({
      ...data,

      webinars: data,
    });
  };

  const onFileSelectedList = (file, id) => {
    let dataOne = { ...values };

    dataOne["attachment"] = file;
    // if (file === null || file === undefined) {
    //   setVideoThumbnail(null);
    // }

    updateValue({
      ...dataOne,
      webinars: dataOne,
    });
  };

  // const onFileSelected1 = (file) => {
  //   console.log('123', file);
  //   setSelImg(file);
  //   if (file === null || file === undefined) {
  //     setImage(null);
  //   }
  // };

  // const onFileSelected2 = (file) => {
  //   console.log('123', file);

  //   if (file === null || file === undefined) {
  //     setSelbtnImg('');
  //   } else {
  //     setSelbtnImg(file);
  //   }
  // };

  const handleAdd = () => {
    setInputFields([
      ...inputFields,
      {
        update_tip: 0,
        tip_id: new Date().getUTCMilliseconds(),
        content: "",
        status: "ACTIVE",
        isAdd: true,
      },
    ]);
  };
  const [hideFields, setHideFields] = useState(false);
  const handleTabChange = (val) => {
    setActiveTab(val);
    if (val?.name === "English") {
      setHideFields(false);
    } else {
      setHideFields(true);
    }
    // Perform any other actions on tab change
  };
  return (
    <div className="blogsForm">
      <div className="blogsForm_content">
        <HeaderWithBackButton
          title="Add/edit a Habit section"
          goTo={APP_CONSTANTS.HABITS}
        />

        {HabitId && (
          <TabBar
            tabs={LanguageOptions}
            initialTab={activeTab}
            onTabChange={handleTabChange}
            parentStyle={"service-tab-style"}
          />
        )}
        <NewInput
          label="Habit Name"
          id="HabitName"
          type="text"
          placeholder="Habit"
          name="HabitName"
          value={values.HabitName}
          onChange={handleChange}
          errorText={error.title}
        />

        {/* <Attachment
          onFileSelected={onFileSelected}
          label='Image'
          name='Image'
          value={values.Image}
          className='attachment__selimage'
        /> */}

        {/* <Attachment
          value={values.Image}
          // id={data.id}
          onFileSelected={onFileSelected}
          fileType='image'
          className='attachment__selimage'
          // label={`Add Banner ${index + 1}`}
        /> */}

        {!hideFields && (
          <Attachment
            value={values.attachmentFile}
            // id={data.id}
            onFileSelected={onFileSelected}
            fileType="image"
            className="habit-detail-img"
            label="Add an Detail Attachment"
          />
        )}

        {!hideFields && (
          <Attachment
            value={values.attachment}
            // id={data.id}
            onFileSelected={onFileSelectedList}
            fileType="image"
            className="habit-img"
            label="Add an Listing Image"
          />
        )}

        {/* ScratchImage */}

        {/* <Attachment
          onFileSelected={onFileSelected1}
          label='ScrachImage'
          name='ScrachImage'
          value={values.ScratchImage}
          className='attachment__selimage'
        /> */}

        {!hideFields && (
          <NumberInput
            label="PerDayCoins"
            id="PerDayCoins"
            placeholder="PerDayCoins"
            name="PerDayPoints"
            value={values.PerDayPoints}
            onChange={handleChange}
          />
        )}
        {!hideFields && (
          <NumberInput
            label="TotalPoints"
            id="TotalPoints"
            placeholder="TotalPoints"
            name="TotalPoints"
            value={values.TotalPoints}
            onChange={handleChange}
          />
        )}
        {!hideFields && (
          <NumberInput
            label="AllotedDays"
            id="AllotedDays"
            placeholder="days"
            name="AllotedDays"
            value={values.AllotedDays}
            onChange={handleChange}
          />
        )}

        <p className="tips">Tip Section:</p>
        <div className="Habit_Container">
          {inputFields.map((item, index) => (
            <div className="CreateHabit" key={index}>
              {/* {console.log('inputFields', item.status)} */}

              {/* {console.log(inputFields, 'inputFieldsinputFields')} */}

              <NewInput
                label={`Tip ${index + 1}`}
                value={item?.content ? item?.content : ""}
                id={item?.id}
                name={item?.id}
                placeholder="content"
                onChange={(e) => handlePayloadText(e, index)}
                // handleRemove={handleRemove}
                type="text"
                handleAdd={handleAdd}
              />
              {!hideFields && (
                <div className="radio-tip">
                  <RadioButton
                    id={item.id}
                    label="Publish"
                    isChecked={item.status === "ACTIVE" ? true : false}
                    value={true}
                    onSelect={handleRadioButtonClick}
                    name={item.id}
                  />

                  <RadioButton
                    id={item.id}
                    label="UnPublish"
                    isChecked={item.status === "ACTIVE" ? false : true}
                    value={false}
                    onSelect={handleRadioButtonClick}
                    name={item.id}
                  />
                </div>
              )}
            </div>
          ))}
          <Button
            onClick={handleAdd}
            // style={{ marginTop: '55px' }}
            type="Add"
            name="Add"
          />
        </div>
        <div className="update-button">
          <Button onClick={handleCreateHabit} type="submit" name="Update" />
        </div>
      </div>

      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </div>
  );
};

export default CreateHabits;
