// Pages
import BasePage from 'pages/BasePage';

import { HomePageHeader } from 'constants/table-header-constants'; 
import CreateHomePageOrderContainer from 'app/container/HomePageOrdering/CreateHomePageOrder';

const CreateHomePageOrder = () => {
  return (
    <BasePage>
      <CreateHomePageOrderContainer tableHeader={HomePageHeader} />
    </BasePage>
  );
};

export default CreateHomePageOrder;
