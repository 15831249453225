import React from 'react';

// Pages
import BasePage from 'pages/BasePage';  
import { HomePageSectionHeader } from 'constants/table-header-constants';
import HomePageSections from 'app/container/HomePageSections';

const HomePageSection = () => {
  return (
    <BasePage>
      <HomePageSections tableHeader={HomePageSectionHeader}/>
    </BasePage>
  );
};

export default HomePageSection;
