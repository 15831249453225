import { API_CONSTANTS } from 'app/constants/api-contants';
import * as dataAccess from 'utils/ajax';
import Parsers from 'utils/Parsers';
import Toast from 'app/utils/Toast';

export const getNavigationList = async () => {
     let responseObj = await dataAccess.get(API_CONSTANTS.GET_NAVIGATION_LIST);
     if (
          responseObj &&
          responseObj.response &&
          responseObj.response.code === 200
     ) {
          return responseObj.data;
     } else {
          return [];
     }
};

export const createNavigation = async (incomingData) => {
    let formData = Parsers.ConvertObjectAsFormData(incomingData)
    let payload = {
        data: formData
    }
    return await dataAccess.upload(API_CONSTANTS.POST_NAVIGATION, payload)
}

export const getNavigationDetails = async (id) => {
    let responseObj = await dataAccess.get(`${API_CONSTANTS.GET_NAVIGATION_LIST}/${id}`);
    if (
         responseObj &&
         responseObj.response &&
         responseObj.response.code === 200
    ) {
         return responseObj.data;
    } else {
         return [];
    }
};

export const updateNavigation = async (incomingData) => {
    let formData = Parsers.ConvertObjectAsFormData(incomingData)
    let payload = {
        data: formData
    }
    let response = dataAccess.update(API_CONSTANTS.POST_NAVIGATION, payload)
    return response
}

export async function deleteNavigationById(id) {
    let responseObj = await dataAccess.del(`${API_CONSTANTS.GET_NAVIGATION_LIST}/${id}`);
    if (
         responseObj &&
         responseObj.response &&
         responseObj.response.code === 200
    ) {
         Toast.success(responseObj.response.alert[0].message)
    }
}

 