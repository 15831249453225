// Ajax
import * as dataAccess from 'utils/ajax';

// Constants
import { API_CONSTANTS } from 'constants/api-contants';

import Parsers from 'utils/Parsers';
import ApiParser from 'app/utils/ApiParser';

export const getWidgets = async (start, filterPayload) => {
  let responseObj = await dataAccess.get(API_CONSTANTS.GET_WIDGETS);
  return ApiParser.parseHomeListing(responseObj);
  // return responseObj;
};

export const updateWidgets = async (incomingData) => {
  let payloads = [];
  if (incomingData && incomingData.length > 0) {
    incomingData.forEach((item) => {
      var formData = Parsers.ConvertObjectAsFormData(item);

      payloads.push({
        data: formData,
        item,
      });
    });
  }

  if (payloads.length < 1) {
    return;
  }

  return await dataAccess.updateAll(API_CONSTANTS.GET_WIDGETS, payloads);
};
export const addWidgets = async (incomingData) => {
  let payloads = [];
  if (incomingData && incomingData.length > 0) {
    incomingData.forEach((item) => {
      var formData = Parsers.ConvertObjectAsFormData(item);

      payloads.push({
        data: formData,
      });
    });
  }

  if (payloads.length < 1) {
    return;
  }
  return await dataAccess.uploadAll(API_CONSTANTS.GET_WIDGETS, payloads);
};
