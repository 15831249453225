import React, { useEffect, useState } from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import { useHistory, useLocation } from "react-router";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ArrowUpImage, ArrowDownImage } from 'app/constants/image-constants';
import Toast from 'app/utils/Toast';
import Message from 'app/utils/Message';
import ActionMenu from '../../../components/ActionMenu';
import './index.scss';

const Table = ({
  columns,
  data,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  menuAction,
  setRowData,
  HomePageOrder,
  handleDelete,
  publishOrUnpublish,
  currentPage
}) => {
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,

    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      pageCount: controlledPageCount,
      autoResetPage: false,
    },
    useSortBy,
    usePagination
  );

  const history = useHistory();
  const [activeId, setActiveId] = useState('');
  const [homepageOrderId, sethomepageOrderId] = useState('');
  const [activityVitalsId, setactivityVitalsId] = useState('');
  const [pageData, setPageData] = useState([]);

  const createToggler = (row) => {
    sethomepageOrderId(row.original.id + row.index.toString());
    setactivityVitalsId(row.original.id + row.index.toString());
  };

  const handleDragEnd = async (e) => {
    if (!e.destination) return;

    let tempData = [...pageData];
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setPageData(tempData);


    let responseObj = await HomePageOrder(tempData);
  };

  const onClickOutside = () => {
    setActiveId('');
  };


  useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  useEffect(() => {
    setPageData(page);
  }, [page]);

  // Render the UI for your table
  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th className='nowrap text-center' {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <img
                            className='arrow'
                            src={ArrowDownImage}
                            alt='Desc'
                          />
                        ) : (
                          <img className='arrow' src={ArrowUpImage} alt='Asc' />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <Droppable droppableId='droppable-1'>
            {(provider) => (
              <tbody
                ref={provider.innerRef}
                {...provider.droppableProps}
                {...getTableBodyProps()}
              >
                {pageData.map((row, i) => {
                  prepareRow(row);
                  return (
                    <Draggable key={row.id} draggableId={row.id} index={i}>
                      {(provider) => (
                        <tr
                          {...provider.draggableProps}
                          ref={provider.innerRef}
                          {...row.getRowProps()}
                        >
                          {row.cells.map((cell) => {
                            return (
                              <>
                                {cell.column.id === 'Action' ? (
                                    <td>
                                        <div className="btn-group" role="group" aria-label="Basic outlined example">
                                            <button
                                                type="button"
                                                className="btn btn-outline-primary"
                                                onClick={() => {
                                                    history.push({
                                                        pathname: `/cms/stories/edit/${cell.row.original.id}`,
                                                        state: {
                                                                ...cell.row.original,
                                                                comingFrom: currentPage
                                                        },
                                                    });
                                                }}
                                            >
                                                Edit
                                            </button>
                                            <button type="button"
                                                onClick={() => handleDelete(cell.row.original.id)}
                                                className="btn btn-outline-primary">Delete</button>
                                            <button onClick={() => publishOrUnpublish(cell.row.original)} type="button" className="btn btn-outline-primary">{data.status == "ACTIVE" ?
                                                "Unpublish"
                                                :
                                                "Publish"
                                            }</button>
                                        </div>
                                    </td>
                                //   <td
                                //     style={{ position: 'relative' }}
                                //     className='pointer'
                                //     onClick={() => createToggler(row)}
                                //   >
                                //     :
                                //     <>
                                //       {homepageOrderId ===
                                //         row.original.id +
                                //           row.index.toString() &&
                                //       activityVitalsId ===
                                //         `${
                                //           row.original.id + row.index.toString()
                                //         }` ? (
                                //         <ActionMenu
                                //           menuAction={menuAction}
                                //           onClickOutside={onClickOutside}
                                //           row={row.original}
                                //         />
                                //       ) : null}
                                //     </>
                                //   </td>
                                ) :  cell.column.id === 'story_title' ? <td>
                                   
                                    {cell.value[0].title}
                                </td> :  cell.column.id === 'Drag' ? (
                                  <td {...provider.dragHandleProps}>▶</td>
                                ) : cell.column.id === 'SequenceOrder' ? (
                                  <td>{}</td>
                                ) : (
                                  <>
                                    {cell.column.id === 'tags' ? 
                                  <>
                                  <td>
                                    {cell.value.length > 0 ? cell.value.map((tag) => (` ${tag},`)) : ""}
                                  </td>
                                  </>  :
                                  
                                    <td {...cell.getCellProps()}>
                                      {cell.render('Cell')}
                                    </td>
                                  }
                                  </>
                                )}
                              </>
                            );
                          })}
                        </tr>
                      )}
                    </Draggable>
                  );
                })}
              </tbody>
            )}
          </Droppable>
        </table>
      </DragDropContext>
    </>
  );
};

function SampleTable({
  tableHeader,
  names,
  menuAction,
  pagination,
  type,
  getData,
  searchVal,
  searchKey = 'title',
  hasPagination,
  filterData,
  responseCallback,
  setRowData,
  HomePageOrder,
  selectedContent,
  handleDelete,
  publishOrUnpublish,
  currentPage
}) {
  const columns = React.useMemo(() => tableHeader, []);
  // We'll start our table without any data
  const [data, setData] = React.useState([]);
  const [rawData, setRawData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);

  const fetchData = React.useCallback(({ pageSize, pageIndex }) => {
    if (getData) {
      setLoading(true);

      getData(pageIndex + 1, filterData).then((response) => {
        responseCallback && responseCallback(response);
        setLoading(false);
        let pageCount = 0;
        if (response.error === false) {
          if (response && response.tableData) {
            pageCount = response.tableData.length;
            setData(response.tableData);
            setRawData(response.tableData);
          }
          if (
            response &&
            response.pagination &&
            response.pagination.total_records
          ) {
            setPageCount(
              Math.ceil(response.pagination.total_records / pageSize)
            );
          } else {
            setPageCount(Math.ceil(pageCount / pageSize));
          }
        } else {
          if (response.message) {
            Toast.error(response.message);
          } else {
            Toast.error(Message.Error.COMMON_MESSAGE);
          }
        }
      });
    }
    if(selectedContent&& selectedContent.length>0){
      setLoading(true);
      // getData(pageIndex + 1, filterData).then((response) => {
        responseCallback && responseCallback(selectedContent);
        setLoading(false);
        let pageCount = 0;
        // if (response.error === false) {
          if (selectedContent ) {
            pageCount = selectedContent.length;
            setData(selectedContent);
            setRawData(selectedContent);
          }
          // if (
          //   response &&
          //   response.pagination &&
          //   response.pagination.total_records
          // ) {
            setPageCount(
              Math.ceil(selectedContent.length / pageSize)
            );
          // } else {
          //   setPageCount(Math.ceil(pageCount / pageSize));
          // }
        // } else {
        //   if (response.message) {
        //     Toast.error(response.message);
        //   } else {
        //     Toast.error(Message.Error.COMMON_MESSAGE);
        //   }
        // }
      // });
    }
  }, []);

  useEffect(() => {
    if (searchVal && searchVal.trim().length > 0) {
      let searchString = searchVal.trim().toLowerCase();
      let newData = [...rawData];
      // We are searching. Filter the results.
      newData = newData.filter((e) => {
        if (e[searchKey]) {
          return e[searchKey].toLowerCase().match(searchString);
        }
        return false;
      });
      setData(newData);
    } else {
      setData([...rawData]);
    }
  }, [searchVal]);

  return (
    <Table
      columns={columns}
      data={selectedContent}
      fetchData={fetchData}
      loading={loading}
      pageCount={pageCount}
      menuAction={menuAction}
      hasPagination={hasPagination}
      setRowData={setRowData}
      HomePageOrder={HomePageOrder}
      handleDelete ={handleDelete}
      publishOrUnpublish = {publishOrUnpublish}
      currentPage={currentPage}
    />
  );
}

export default SampleTable;
