import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import Loader from 'components/Loader';
import NewTable from 'components/NewTable';
import PageLoader from 'components/PageLoader';
// Constants
import { APP_CONSTANTS } from 'constants/app-constants';

// API
import { getBanners, publishOrUnpublishBanner } from 'api/banner';

// Utils
import Toast from 'utils/Toast';
import Message from 'utils/Message';

const Banner = ({ tableHeader }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [data, setData] = useState({});

  const loadNames = async () => {
    setLoading(true);
    let response = await getBanners();
    setLoading(false);
    if (response && response.tableData && response.error === false) {
      setData({
        tableData: response.tableData,
      });
    } else {
      if (response.message) {
        Toast.error(response.message);
      } else {
        Toast.error(Message.Error.COMMON_MESSAGE);
      }
    }
  };

  const callPublishOrUnpublishAPI = async (data) => {
    setPageLoading(true);

    if (data && data.type_of_banner && data.status) {
      let responseObj = await publishOrUnpublishBanner(
        data.type_of_banner,
        data.status
      );
      setPageLoading(false);
      if (
        responseObj &&
        responseObj.response &&
        responseObj.response.code === 200
      ) {
        loadNames();
        let message = Message.Banner.PUBLISH_UNPUBLISH_SUCCESS.replace(
          '{{placeholder}}',
          data.status.toLowerCase() === 'active' ? 'Unpublished' : 'Published'
        );
        Toast.success(message);
      }
    } else {
      let message = Message.Banner.PUBLISH_UNPUBLISH_FAILURE.replace(
        '{{placeholder}}',
        data.status.toLowerCase() === 'active' ? 'Unpublish' : 'Publish'
      );
      Toast.error(message);
    }
  };

  const menuActionObj = [
    {
      name: 'Edit',
      onClick: (e, data) => {
        history.push({
          pathname: `/banner/edit/1`,
          state: {
            ...data,
          },
        });
      },
    },
    {
      name: 'Publish',
      type: 'publish',
      onClick: (e, data) => {
        callPublishOrUnpublishAPI(data);
      },
    },
  ];

  const sectionObj = {
    sectionTitle: 'Banners',
  };

  if (loading === true) {
    return <Loader />;
  }

  return (
    <div className='blogs'>
      <NewTable
        sectionObj={sectionObj}
        tableHeader={tableHeader}
        data={data}
        menuAction={menuActionObj}
        getData={getBanners}
      />
      {pageLoading === true && <PageLoader pageLoading={pageLoading} />}
    </div>
  );
};
export default Banner;
