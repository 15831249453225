import Button from 'app/components/button';
import Search from 'app/components/search';
import Pagination from '../CategoriesCustomTable/pagination';
import '../index.scss';

import ArticleModal from 'app/components/ArticleModal';
import CategoriesCustomTable from '../CategoriesCustomTable';
import HeaderWithBackButton from 'app/components/HeaderWithBackButton';
import NewAttachment from 'app/components/NewDesign/NewAttachment';
import NewInput from 'app/components/NewInput';
import Checkbox from 'app/components/checkbox';
import { APP_CONSTANTS } from 'app/constants/app-constants';
import Plus from 'images/plus.png';
import Toggle from 'react-toggle';
import useSubCategories from '../SubCategory/useSubCategories';
import PageLoader from 'app/components/PageLoader';

const MedPaySubCategories = () => {
  const {
    values,
    pageLoading,
    onFileSelected,
    OnhandleChange,
    showFilter,
    setShowFilter,
    currentData,
    // ProductListHeader,
    handleInputChangeHandler,
    handleEdit,
    handleDelete,
    paginationHandler,
    pageCount,
    subCategoryData,
    addProuctSearchBar,
    productsearchtoggle,
    productKeyword,
    searchProductList,
    productSearchHandler,
    productAddinList,
    subCategoryAllData,
    inputChangeHandler,
    medpaySubCategoriesList,
    productNameAndImage,
    toggled,
    openUp,
    bannertype,
    noProductStatus,
    productSearchLoader,
    LabTestListHeader,
    MedicineListHeader,
  } = useSubCategories();

  return (
    <>
      {' '}
      {showFilter && (
        <ArticleModal
          modalCloseClicked={() => setShowFilter(false)}
          filterApplyClicked={() => {}}
          ResetFilterCicked={() => {}}
          handleChange={() => {}}
          values={''}
          error={''}
        />
      )}
      <div className='subcategory_container'>
        <HeaderWithBackButton
          title={`${bannertype?.heading} Categories`}
          goTo={`${
            bannertype?.source === 'MEDICINE'
              ? APP_CONSTANTS.MEDICINECATEGORIES
              : APP_CONSTANTS.DIAGONOSTICCATEGORIES
          }`}
        />
        <div className='medpay_subcategory_container_topHeadingtoggle'>
          <h4> {bannertype?.heading} Category (Med Pay API)</h4>
          <Toggle checked={toggled} icons={false} onChange={openUp} />
        </div>
        <div className='subcategory_container__top'>
          <div className='Widget-container'>
            <div className='Widget-container__section-one'>
              <div className='Widget-container__Widget'>
                <div className='Widget-container__Image-Container'>
                  <div>
                    <NewInput
                      label='Heading'
                      id={subCategoryData.id}
                      name={'heading'}
                      value={subCategoryData.heading}
                      placeholder='COPD'
                      className='form-block'
                      onChange={OnhandleChange}
                    />
                    <NewAttachment
                      value={subCategoryData.image_url}
                      id={subCategoryData.id}
                      onFileSelected={onFileSelected}
                      fileType='image'
                      label={`Add an attachment`}
                      className='subcategory_container__top__namesection_feed__attachment'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='subcategory_container__top'>
            <div className='Widget-container'>
              <div className='categories-Widget-container-heading'>
                {' '}
                SELECT CATEGORIES
              </div>
              <div className='selectcategories_checkbox__list'>
                {medpaySubCategoriesList &&
                  medpaySubCategoriesList.map((item, index) => {
                    return (
                      <>
                        <div
                          key={index}
                          className='medpaySubCategoriesList_checkbox'
                          onClick={() => productNameAndImage(item, index)}
                        >
                          <Checkbox
                            id='publish'
                            label={item.heading}
                            // onChange={onChange}
                            checked={item.isChecked}
                          />
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
          </div>

          <div className='subcategory_container__top__filterSearch'>
            <Search
              value={values}
              placeholder='Search'
              className={''}
              onChange={inputChangeHandler}
            />
            <Button
              variant='Add Product'
              onClick={addProuctSearchBar}
              name={'Add Product'}
            />
          </div>
          {productsearchtoggle && (
            <div className='top__productSearch'>
              <Search
                value={productKeyword}
                placeholder='Search'
                className={'categorySearchBar'}
                onChange={productSearchHandler}
              />
              <div className='top__productSearch__dropdown'>
                {productSearchLoader ? (
                  <div className=' top__productSearch__dropdown__medicinename_new'>
                    Loading<div className='productSearchLoader'></div>
                  </div>
                ) : (
                  <>
                    {searchProductList &&
                      searchProductList.length > 0 &&
                      searchProductList.map((item, index) => {
                        return (
                          <>
                            <div
                              key={index}
                              className={
                                item.alreadyStatus
                                  ? 'top__productSearch__dropdown__medicinename_already'
                                  : 'top__productSearch__dropdown__medicinename_new'
                              }
                              onClick={() => {
                                if (!item.alreadyStatus) {
                                  productAddinList(item);
                                }
                              }}
                            >
                              {' '}
                              <span>
                                {item.name} {item.alreadyStatus}
                              </span>
                              <span>
                                {!item.alreadyStatus && (
                                  <img
                                    src={Plus}
                                    alt='Go back'
                                    width='25'
                                    height='25'
                                    // onClick={onClick}
                                  />
                                )}
                              </span>
                            </div>
                          </>
                        );
                      })}
                    {searchProductList.length === 0 && noProductStatus && (
                      <div className='top__productSearch__dropdown__medicinename'>
                        No Product Found
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
        <div className='subcategory-container__actionBox'></div>

        <div className='subcategory-container__table'>
          <CategoriesCustomTable
            data={currentData}
            tableHeader={
              bannertype.source === 'MEDICINE'
                ? MedicineListHeader
                : LabTestListHeader
            }
            handleInputChangeHandler={handleInputChangeHandler}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            bannertype={bannertype}
          />
        </div>

        <div className='subcategory-container__pagination'>
          <Pagination pages={pageCount} onChange={paginationHandler} />
        </div>
        <div className='button_section'>
          <Button variant='Save' onClick={subCategoryAllData} name={'Save'} />
        </div>
      </div>
      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </>
  );
};

export default MedPaySubCategories;
