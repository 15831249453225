import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './index.scss';
import { addMedia } from 'app/api/feed';
import { useCallback, useMemo } from 'react';
import { Quill } from 'react-quill';

const NewQuillTextEditor = ({ label, value, quillRef, Tooltiptext, onChange = () => {} }) => {
  const Font = ReactQuill.Quill.import('formats/font');
  Font.whitelist = ['poppins', 'oswald']; // allow ONLY these fonts and the default
  ReactQuill.Quill.register(Font, true);

  const imageHandler = useCallback(async function() {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      let responseObj = await addMedia(1, file);
      if (responseObj && responseObj.response && responseObj.response.code === 200) {
        if (responseObj.data && responseObj.data.length > 0) {
          const quill = this.quill; // Use `this` context to refer to the current Quill instance
          const range = quill.getSelection();
          quill.insertEmbed(range.index, 'image', responseObj.data[0]);
        }
      }
    };
  }, []);

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'font': ['poppins', 'oswald'] }],
        [{ 'align': [] }],
        ['clean'],
        ['link', 'image']
      ],
      handlers: {
        image: function() { imageHandler.call(this); } // Bind the handler to the current Quill instance
      },
    },
  }), [imageHandler]);

  return (
    <div className='cipla-editor'>
      {label && <label className='tooltip-on-hover'>{label}</label>}
      <ReactQuill
        ref={quillRef}
        onChange={(content, delta, source, editor) => onChange(content, delta, source, editor)}
        theme="snow"
        value={value}
        modules={modules}
      />
      <div className='tooltip1'>{Tooltiptext}</div>
    </div>
  );
}

export default NewQuillTextEditor;