import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import Loader from 'components/Loader';
import ArticleComponent from 'components/ArticleComponent';
import NewTable from 'components/NewTable';
import PageLoader from 'components/PageLoader';

// Constants
import { APP_CONSTANTS } from 'constants/app-constants';

// API
import {
  getUsers,
  deleteUser,
  makeUserAsActiveOrInactive,
  getResendEmail,
} from 'api/users';

// Utils
import Toast from 'utils/Toast';
import Message from 'utils/Message';

const User = ({ tableHeader }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [pageLoading, setPageLoading] = useState(false);
  const [rowData, setRowData] = useState({});
  const [feedSummary, setFeedSummary] = useState({
    totalUser: 0,
    inActiveUser: 0,
    activeUser: 0,
  });

  const resendHandleChange = async () => {
    let responseObj = await getResendEmail(rowData.email);
    console.log(responseObj, 'rrrrrr');
    if (
      responseObj &&
      responseObj.response &&
      responseObj.response.code === 200
    ) {
      console.log(responseObj);
      // LocalStorage.clear();
      // window.location.href = APP_CONSTANTS.LOGIN;
    }
  };

  const loadNames = (start, end) => {
    setLoading(true);
    getUsers(start, end).then((response) => {
      setLoading(false);

      if (response && response.tableData && response.error === false) {
        console.log(response, 'cmsusers');
        setData({
          tableData: response.tableData,
        });
        setFeedSummary(response.summary);
      }
    });
  };

  const handleResponseCallback = (response) => {
    if (response && response.tableData && response.error === false) {
      setFeedSummary(response.summary);
    }
  };

  const callDeleteAPI = async (data) => {
    setPageLoading(true);
    if (data && data.employee_id) {
      let responseObj = await deleteUser(data.employee_id);
      setPageLoading(false);
      if (
        responseObj &&
        responseObj.response &&
        responseObj.response.code === 200
      ) {
        loadNames();
        Toast.success(Message.User.DELETE_SUCCESS);
      }
    } else {
      Toast.error(Message.User.DELETE_FAILURE);
    }
  };

  const callActiveOrInactiveAPI = async (data) => {
    setPageLoading(true);
    if (data && data.employee_id && data.status) {
      let responseObj = await makeUserAsActiveOrInactive(
        data.employee_id,
        data.status
      );

      setPageLoading(false);
      if (
        responseObj &&
        responseObj.response &&
        responseObj.response.code === 200
      ) {
        loadNames();
        let message = Message.User.ACTIVE_INACTIVE_SUCCESS.replace(
          '{{placeholder}}',
          data.status.toLowerCase() === 'active' ? 'inactivated' : 'activated'
        );
        Toast.success(message);
      } else {
        let message = Message.User.ACTIVE_INACTIVE_FAILURE.replace(
          '{{placeholder}}',
          data.status.toLowerCase() === 'active' ? 'inactivate' : 'active'
        );
        Toast.error(message);
      }
    }
  };

  const menuActionObj = [
    {
      name: 'Edit',
      onClick: (e, data) => {
        console.log('Edit Clicked: ', JSON.stringify(data));
        history.push({
          pathname: `/user/edit/${data.employee_id}`,
          state: {
            ...data,
          },
        });
      },
    },

    {
      name: 'Active',
      type: 'active',
      onClick: (e, data) => {
        console.log('Active/InActive Clicked: ', data);
        callActiveOrInactiveAPI(data);
      },
    },
    {
      name: 'Delete',
      onClick: (e, data) => {
        console.log('Delete Clicked: ', data);
        let text = 'are you sure,want to delete';
        if (window.confirm(text) == true) {
          callDeleteAPI(data);
          // text = 'You pressed OK!';
        } else {
          // text = 'You canceled!';
        }
      },
    },
  ];

  // const abc = () => {
  if (!rowData.email_verified) {
    menuActionObj.push({
      name: 'Resend email',
      onClick: (e, data) => {
        resendHandleChange();
      },
      // onClick:  {
      //   resendHandleChange,
      //   // console.log('Edit Clicked: ', JSON.stringify(data));
      //   // history.push({
      //   //   pathname: `/user/edit/${data.employee_id}`,
      //   //   state: {
      //   //     ...data,
      //   //   },
      //   // });
      // },
    });
  }

  const sectionObj = {
    sectionTitle: 'CMS Users',
    searchObj: {
      placeholder: 'Search',
      handleSearch: (e) => {
        console.log('Handle search: ', e);
      },
    },
    buttons: [
      {
        title: 'Create user',
        handleBtnClick: (e) => {
          history.push(APP_CONSTANTS.CREATE_USER);
        },
      },
    ],
  };

  if (loading === true) {
    return <Loader />;
  }

  return (
    <div className='blogs'>
      {console.log(rowData, 'rowdatarowdata')}
      <div className='blogs_container'>
        <ArticleComponent number={feedSummary.totalUser} text='Total User' />
        <ArticleComponent number={feedSummary.activeUser} text='Active User' />
        <ArticleComponent
          number={feedSummary.inActiveUser}
          text='Inactive User'
        />
      </div>
      <NewTable
        sectionObj={sectionObj}
        tableHeader={tableHeader}
        menuAction={menuActionObj}
        data={data}
        getData={getUsers}
        setRowData={setRowData}
        searchKey='first_name'
        responseCallback={handleResponseCallback}
      />
      {pageLoading === true && <PageLoader pageLoading={pageLoading} />}
    </div>
  );
};

export default User;
