import React from 'react';

// Pages
import BasePage from 'app/pages/BasePage';   

import { AdvertisementHeader } from 'constants/table-header-constants';

import AdvertisementListConatainer from 'app/container/Exercises/AdvertisementList';
const AdvertisementList = () => {
  return (
    <BasePage> 
        <AdvertisementListConatainer tableHeader={AdvertisementHeader}/>
    </BasePage>
  );
};

export default AdvertisementList;
