import { useEffect, useState } from 'react';

import {
  addBanners,
  addFirstBanner,
  bannerPublishorUnpublish,
  getLatestBanner,
  updateBanners,
} from 'app/api/latest/LatestBannerAPI/latestBanner';
import { URLType } from 'app/constants/app-constants';
import Parsers from 'app/utils/Parsers';
import ValidationMessage from 'app/utils/ValidationMessage';
import { confirmAlert } from 'react-confirm-alert';
import Message from 'utils/Message';
import Toast from 'utils/Toast';

import { useLocation } from 'react-router-dom';
// import { validateUrl } from 'app/helpers/validation';

// import { getActiveUsers } from 'app/api/feed';
const useLatestBannerWidgets = (validate) => {
  const [removedService, setremovedService] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [count, setCount] = useState(1);
  const [bannerlistData, setBannerListData] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [toggled, setToggled] = useState(null);
  const [isAddButtonDisabled, setisAddButtonDisabled] = useState(true);
  const [sortedBannerList, setBannerSortedList] = useState([]);
  const location = useLocation();
  const [isPublishButtonDisabled, setisPublishButtonDisabled] = useState(true);

  const [activeTab, setActiveTab] = useState({
    id: '1',
    name: 'English',
    short_name: 'en',
    display_order: 1,
    status: 'ACTIVE',
    label: '+ English',
    value: 'English',
  });
  const [activeShow, setActiveShow] = useState(false);

  useEffect(() => {
    if (activeTab.name === 'English') {
      setActiveShow(true);
    } else {
      setActiveShow(false);
    }
  }, [activeTab.short_name]);

  const handleTabChange = (val) => {
    setActiveTab(val);

    // Perform any other actions on tab change
  };

  const [bannerType, setBannerType] = useState({
    placement: '',
    size: '',
    page: '',
    name: '',
  });

  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    if (location?.pathname === '/medicine-large-banner') {
      let payload = {
        placement: 'TOP',
        size: 'LARGE',
        page: 'MEDICINE',
      };
      setBannerType({ ...payload });

      loadServiceslist({ ...payload });
    }
    if (location?.pathname === '/medicine-small-banner') {
      let payload = {
        placement: 'TOP',
        size: 'SMALL',
        page: 'MEDICINE',
      };
      setBannerType({ ...payload });

      loadServiceslist({ ...payload });
    }
    if (location?.pathname === '/diagonostic-large-banner') {
      let payload = {
        placement: 'TOP',
        size: 'LARGE',
        page: 'DIAGNOSTIC',
      };
      setBannerType({ ...payload });
      loadServiceslist({ ...payload });
    }
    if (location?.pathname === '/diagonostic-small-banner') {
      let payload = {
        placement: 'TOP',
        size: 'SMALL',
        page: 'DIAGNOSTIC',
      };
      setBannerType({ ...payload });
      loadServiceslist({ ...payload });
    }
    return () => {
      setBannerListData([]);
      setBannerSortedList([]);
    };
  }, [location?.pathname, refresh, activeTab.short_name]);

  const [bannerId, setBannerId] = useState(null);

  const loadServiceslist = async (payload) => {
    setBannerListData([]);
    setBannerSortedList([]);
    setPageLoading(true);

    let response = await getLatestBanner(payload, activeTab.short_name);

    let temp = [];
    if (response && response.response && response.response.code === 200) {
      temp = response.data.TOP.map((item, index) => {
        let url = '';
        let externalUrl = '';
        if (item?.url_type && item?.url_type.trim() === URLType.EXTERNAL) {
          externalUrl = item?.url ? item?.url : '';
        }
        if (item?.url_type && item?.url_type.trim() === URLType.INTERNAL) {
          url = item?.url ? item?.url : '';
        }
        setToggled(item.banner_status === 'ACTIVE' ? true : false);
        setBannerId(item?.banner_id);
        return {
          carouselId: item?.id,
          banner_id: item?.banner_id,
          id: `banner_${index}`,
          title: item?.title,
          page: item?.page,
          placement: item?.placement,
          size: item?.size,
          displayOrder: item?.display_order ? item?.display_order : null,
          attachment: item?.attachment_path,
          urlType: item?.url_type ? item?.url_type.trim() : '',
          url: url,
          urlCopy: url,
          redirection_id: item?.redirection_id ? item?.redirection_id : '',
          redirection_idURL: item?.redirection_id ? item?.redirection_id : '',
          externalUrl: externalUrl,
          updated_by: item?.updated_by,
          updated_on: Parsers.ParseTableDateTime(item?.updated_on),
          isNew: false,
          wasEdited: false,
          banner_status: item?.banner_status ? item?.banner_status : '',
          banner_resource_status: item?.banner_resource_status
            ? item?.banner_resource_status
            : '',

          toggle: item?.banner_resource_status === 'ACTIVE' ? true : false,
        };
      });
    }
    let newtemp = temp.sort((a, b) => a?.displayOrder - b?.displayOrder);
    setBannerListData(newtemp);

    if (
      (payload.size === 'SMALL' && newtemp.length >= 5) ||
      (payload.size === 'LARGE' && newtemp.length >= 8)
    ) {
      setisAddButtonDisabled(true);
    } else {
      setisAddButtonDisabled(false);
    }

    setCount(newtemp.length);
    setPageLoading(false);
    if (response || response.response || response.response.error_code) {
      Toast.error(response.response.message);
    } else {
      Toast.error(Message.Error.COMMON_MESSAGE);
    }
  };

  const addServiceSection = () => {
    setisPublishButtonDisabled(false);
    const temp = [...bannerlistData];
    temp.push({
      banner_id: bannerId,
      id: Date.now(), // Basically its a index
      carouselId: null,
      title: '',
      urlType: '',
      urlCopy: '',
      url: '',
      externalUrl: '',
      attachment: '',
      attachmentFile: null,
      displayOrder: '',
      redirection_id: null,
      redirection_idURL: null,
      criteria: '',
      android_version: '',
      is_active: '',
      updated_on: '',
      updated_by: '',
      isNew: true,
      wasEdited: false,
      page: bannerType?.page,
      placement: bannerType?.placement,
      size: bannerType?.size,
      banner_resource_status: 'INACTIVE',
      banner_status: toggled ? 'ACTIVE' : 'INACTIVE',
      toggle: false,
    });
    setCount(count + 1);
    setBannerListData(temp);

    if (
      (bannerType.size === 'SMALL' && count + 1 >= 5) ||
      (bannerType.size === 'LARGE' && count + 1 >= 8)
    ) {
      setisAddButtonDisabled(true);
    } else {
      setisAddButtonDisabled(false);
    }
  };

  const removeServiceSection = (ind) => {
    const temp = [];
    let removedServiceTemp = [...removedService];
    bannerlistData.forEach((item, index) => {
      if (ind !== index) {
        temp.push(item);
      }
      if (ind === index && item.serviceId) {
        removedServiceTemp.push(parseInt(item.serviceId));
      }
    });

    setCount(temp.length);

    setBannerListData(temp);
    setremovedService(removedServiceTemp);
  };

  const OnhandleChange = (e) => {
    const { name, value, id } = e.target;

    if (value) {
      setisPublishButtonDisabled(false);
    }
    const valueCpy = [...bannerlistData];
    valueCpy.map((element, index) => {
      if (`${element.id}` === `${id}`) {
        if (element.serviceId !== null) {
          element.wasEdited = true;
        }
        element[name] = value;
      }

      return element;
    });
    setBannerListData([...valueCpy]);
  };

  const callAddOrUpdateAPI = async () => {
    // debugger;
    /* Populate Data */

    // debugger;

    let addpayloadData = [];
    let editpayloadData = [];

    bannerlistData.forEach((item, index) => {
      let consData = {};
      let newconsData = {};

      if (item.isNew) {
        // consData.is_active = item.toggle;
        if (item?.banner_id) {
          consData.banner_id = item.banner_id;
        }
        if (item?.banner_status) {
          consData.banner_status = item.banner_status;
        }
        if (item?.banner_resource_status) {
          consData.banner_resource_status = item.banner_resource_status;
        }

        if (item?.page) {
          consData.page = item.page;
        }
        if (item?.placement) {
          consData.placement = item.placement;
        }
        if (item?.size) {
          consData.size = item.size;
        }

        if (item?.displayOrder) {
          consData.display_order = item.displayOrder;
        }
        if (item?.title) {
          consData.title = item.title;
        }

        if (item?.attachmentFile) {
          consData.banner_image = item.attachmentFile;
        }
        if ((item.url || item.externalUrl) && item.urlType) {
          consData.url_type = item.urlType;
          if (item.urlType === URLType.EXTERNAL) {
            consData.url = item.externalUrl;
          }
          if (item.urlType === URLType.INTERNAL) {
            consData.url = item.url;
          }
        } else {
          consData.url_type = item.urlType;
        }
        addpayloadData.push(consData);
      }
      if (item?.wasEdited && !item?.isNew) {
        if (activeTab.short_name !== 'en') {
          if (item?.attachmentFile) {
            newconsData.banner_image = item.attachmentFile;
          }
          if (item?.banner_id) {
            newconsData.banner_id = item.banner_id;
          }
          if (item.carouselId) {
            newconsData.id = item.carouselId;
          }
        } else {
          if (item?.banner_id) {
            newconsData.banner_id = item.banner_id;
          }
          if (item.carouselId) {
            newconsData.id = item.carouselId;
          }
          if (item?.banner_resource_status) {
            newconsData.banner_resource_status = item.banner_resource_status;
          }

          if (item?.displayOrder) {
            newconsData.display_order = item.displayOrder;
          }
          if (item?.title) {
            newconsData.title = item.title;
          }

          if (item?.attachmentFile) {
            newconsData.banner_image = item.attachmentFile;
            newconsData.page = bannerType?.page;
            newconsData.placement = bannerType?.placement;
            newconsData.size = bannerType?.size;
          }
          if ((item.url || item.externalUrl) && item.urlType) {
            newconsData.url_type = item.urlType;
            if (item.urlType === URLType.EXTERNAL) {
              newconsData.url = item.externalUrl;
            }
            if (item.urlType === URLType.INTERNAL) {
              newconsData.url = item.url;
            }
          } else {
            newconsData.url_type = item.urlType;
          }
        }
        editpayloadData.push(newconsData);
      }
    });

    setPageLoading(true);

    // if (addpayloadData) {

    //   setPageLoading(true);
    //   if (bannerId === null) {
    //     const bannerval = await addFirstBanner(addpayloadData[0]);
    //     setPageLoading(false);
    //     setBannerId(bannerval);
    //     const valueCpy = [...addpayloadData];
    //     valueCpy.map((element, index) => {
    //       element.banner_id = bannerval;
    //       return element;
    //     });
    //     addpayloadData = [...valueCpy];
    //   }
    //   setPageLoading(true);
    //   await addBanners(addpayloadData, bannerId);
    //   setPageLoading(false);

    // }

    try {
      const promises = [];

      if (addpayloadData) {
        setPageLoading(true);
        if (bannerId === null) {
          // console.log("addpayloadData",addpayloadData[0]);
          const bannerval = await addFirstBanner(addpayloadData[0]);
          setPageLoading(false);
          setBannerId(bannerval);
          const valueCpy = [...addpayloadData];
          valueCpy.map((element, index) => {
            element.banner_id = bannerval;
            return element;
          });
          addpayloadData = [...valueCpy];
        }
        promises.push(addBanners(addpayloadData));
      }
      if (editpayloadData) {
        promises.push(updateBanners(editpayloadData, activeTab.short_name));
        //Toast.success('Banner Updated Successfully');
      }

      if (addpayloadData.length > 0) {
        Toast.success('Banner Added Successfully');
      }
      if (editpayloadData.length > 0) {
        Toast.success('Banner Updated Successfully');
      }

      // Wait for all promises to resolve (API calls to complete)
      await Promise.all(promises);
      // All other APIs have completed successfully

      setisPublishButtonDisabled(true);
      setisAddButtonDisabled(true);
      setRefresh(!refresh);
      setPageLoading(false);
    } catch (error) {
      Toast.error('Unable to update Banner Resources . Please try again.');
      // Handle errors here if any of the API calls fail
      console.error('API call failed:', error);
      setPageLoading(false);
    }

    setPageLoading(false);
  };

  const handleCreateServices = (e) => {
    handleClose();
    callAddOrUpdateAPI();
  };
  const onFileSelected = (file, id) => {
    let newData = [...bannerlistData];
    if (file) {
      setisPublishButtonDisabled(false);
    }
    if (newData && newData.length > 0 && file) {
      newData = newData.map((item) => {
        if (item.id === id && id) {
          // item.attachmentFile = file;
          if (item.serviceId !== null) {
            item.wasEdited = true;
          }
          item.attachmentFile = file;
        }
        return item;
      });
      setBannerListData(newData);
    }
  };

  const callServiePublishOrUnpublishAPI = async () => {
    let banner_status = '';
    if (!toggled) {
      banner_status = 'ACTIVE';
    } else {
      banner_status = 'INACTIVE';
    }

    if (banner_status && bannerId) {
      setPageLoading(true);
      let responseObj = await bannerPublishorUnpublish(bannerId, banner_status);
      setPageLoading(false);
      if (
        responseObj &&
        responseObj.response &&
        responseObj.response.code === 200
      ) {
        // loadNames();
        let message = Message.Services.PUBLISH_UNPUBLISH_SUCCESS.replace(
          '{{placeholder}}',
          banner_status.toLowerCase() === 'ACTIVE' ? 'Unpublished' : 'Published'
        );
        Toast.success(message);
        // refreshPage();
        // getServiceOrder();
      }
    } else {
      let message = Message.Services.PUBLISH_UNPUBLISH_FAILURE.replace(
        '{{placeholder}}',
        banner_status.toLowerCase() === 'ACTIVE' ? 'Unpublish' : 'Publish'
      );
      Toast.error(message);
    }
    setRefresh(!refresh);
  };
  const validateRequestpreviewScreen = async () => {
    let addimageCheck = false;
    let addheadingCheck = false;
    let adddisplayOrderCheck = false;
    let adddisplayOrderunique = false;
    // let urlCheck = false;
    let adddisplayOrderLimitCheck = false;
    if (activeTab.short_name === 'en') {
      bannerlistData.forEach((item, index) => {
        if (item.isNew) {
          if (item.attachmentFile === null) {
            Toast.error(
              `${ValidationMessage.ATTACH} for Banner Resources ${index + 1}`
            );
            addimageCheck = true;
            return;
          }
        }

        if (item?.title === '') {
          Toast.error(
            `${ValidationMessage.TEXT_TITLE} for Banner Resources ${index + 1}`
          );
          addheadingCheck = true;
          return;
        }
        if (item?.displayOrder === '' || !item?.displayOrder) {
          Toast.error(
            `${ValidationMessage.DISPLAY_ORDER} for Banner Resources ${
              index + 1
            }`
          );
          adddisplayOrderCheck = true;
          return;
        }
        if (
          bannerType.size === 'SMALL' &&
          item?.displayOrder > bannerlistData.length
        ) {
          Toast.error(
            `Display Order Can only be 5 for Banner Resources ${index + 1}`
          );
          adddisplayOrderLimitCheck = true;
          return;
        }
        if (
          bannerType.size === 'LARGE' &&
          item?.displayOrder > bannerlistData.length
        ) {
          Toast.error(
            `Display Order Can only be 8 for Banner Resources ${index + 1}`
          );
          adddisplayOrderLimitCheck = true;
          return;
        }
      });
    }

    let makePreviewList = [...bannerlistData];
    let temp = makePreviewList.sort((a, b) => a.displayOrder - b.displayOrder);

    let filteredData = [];
    temp.forEach((item) => {
      if (item.toggle === true) {
        filteredData.push(item);
      }
    });
    // setServiceSortedList(filteredData);
    setBannerSortedList(filteredData);

    const unique = [
      ...new Set(bannerlistData.map((item) => item.displayOrder)),
    ];
    if (activeTab.short_name === 'en') {
      if (unique && bannerlistData && unique.length < bannerlistData.length) {
        Toast.error('Display Order must be Unique');
        adddisplayOrderunique = true;
        return;
      }
    }
    if (
      addimageCheck ||
      addheadingCheck ||
      adddisplayOrderCheck ||
      // urlCheck ||
      adddisplayOrderunique ||
      adddisplayOrderLimitCheck
    ) {
      setPageLoading(false);
      return;
    }

    handleShow(true);
  };

  const handleResourseToggle = (id, itemindex) => {
    setisPublishButtonDisabled(false);
    let newData = [...bannerlistData];

    if (newData && newData.length > 0) {
      newData = newData.map((item, index) => {
        if (index === itemindex) {
          if (item.serviceId !== null) {
            item.wasEdited = true;
          }
          item.toggle = !item.toggle;
          item.banner_resource_status = item.toggle ? 'ACTIVE' : 'INACTIVE';
        }
        return item;
      });
      setBannerListData(newData);
    }
  };

  const openUp = () => {
    confirmAlert({
      title: '',
      message: toggled
        ? 'Are you sure you want to Unpublish Widgets?'
        : 'Are you sure you want to Publish  Widgets?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            await setToggled(!toggled);
            callServiePublishOrUnpublishAPI();
          },
        },
        {
          label: 'No',
          // onClick: () => handleToggle,
        },
      ],
    });
  };

  return {
    sortedBannerList,
    pageLoading,
    onFileSelected,
    OnhandleChange,
    addServiceSection,
    removeServiceSection,
    count,
    bannerlistData,
    setBannerListData,
    handleCreateServices,
    validateRequestpreviewScreen,
    handleClose,
    handleShow,
    show,
    callServiePublishOrUnpublishAPI,
    handleResourseToggle,
    isAddButtonDisabled,
    toggled,
    openUp,
    bannerType,
    isPublishButtonDisabled,

    activeTab,
    activeShow,
    handleTabChange,
  };
};

export default useLatestBannerWidgets;
