import { CoursesRecommendedTableHeader } from 'app/constants/table-header-constants';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import CustomDragabbleTable from './CustomDragabbleTable';

// import '../../index.scss';
import PageLoader from 'app/components/PageLoader';
import useRecommendedCoursesOrdering from './useRecommendedCoursesOrdering';

const RecommendedCourseList = () => {
  const {
    pageLoading,
    handleDragEnd,
    isSubmitClicked,
    handleSelectedItemsChange,
    handleResourseToggle,
    selectedAge,
    selectedCohort,
    filteredData,
    handleAgeSelection,
    handleCohortSelection,
  } = useRecommendedCoursesOrdering();
  return (
    <>
      <div className='recommended-action'>
        <div>
          <label>Cohorts</label>
          <ul className='multiselect-btn'>
            {['Asthma', 'COPD', 'ILD', 'AR', 'Other'].map((cohort) => (
              <li
                key={cohort}
                className={selectedCohort.includes(cohort) ? 'active' : ''}
                onClick={() => handleCohortSelection(cohort)}
              >
                {cohort}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <label>Age groups</label>
          <ul className='multiselect-btn'>
            {['<18', '18-55', '55+'].map((age) => (
              <li
                key={age}
                className={selectedAge.includes(age) ? 'active' : ''}
                onClick={() => handleAgeSelection(age)}
              >
                {age}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className='mt-4'>
        <label>Result</label>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId='droppable' direction='vertical'>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <CustomDragabbleTable
                  data={filteredData}
                  draggableTableHeader={CoursesRecommendedTableHeader}
                  isSubmitClicked={isSubmitClicked}
                  onSelectedItemsChange={handleSelectedItemsChange}
                  handleResourseToggle={handleResourseToggle}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </>
  );
};

export default RecommendedCourseList;
