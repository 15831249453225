import React from 'react';

// // Pages
import BasePage from 'pages/BasePage';

import { CelebrityHeader } from 'constants/table-header-constants';

import Celebrity from 'app/container/Celebrity';

const CelebrityVideoPage = () => {
  return (
    <BasePage>
      <Celebrity tableHeader={CelebrityHeader} />
    </BasePage>
  );
};

export default CelebrityVideoPage;
