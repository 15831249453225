import { deleteDiscoverHomeWidget, getStoriesDataApi, discoverHomeWidgetPublishOrUnpublishAPI } from "app/api/cmsDiscoverApi";

import { deleteGroupApi, getSelectGroupsDataApi, groupPublishOrUnpublishAPI } from "app/api/cmsDiscoverApi";
import Button from "app/components/button";
import Search from "app/components/search";
import Pagination from "app/container/CmsContent/pagination";
import SelectComp from "app/container/CmsContent/select-tag";
import Toast from "app/utils/Toast";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { getCmsContent, updateDiscoverGroups, addDiscoverGroup } from "app/api/cms-content";
import "./index.scss";

const options = [
    //  { value: 5, label: 5, },
     { value: 10, label: 10, },
     { value: 15, label: 15, },
     { value: 20, label: 20, },
     { value: 25, label: 25, },
]
const SelectGroupOfCollection = () => {
     const history = useHistory();
     const [dataCollection, setDataCollection] = useState([]);
     const [pageCount, setPageCount] = useState(1);
     const [dataPerPage, setDataPerPage] = useState(10);
     const [currentPage, setCurrentPage] = useState(1);
     const [searchValue, setSearchValue] = useState('') 
     const indexOfLastData = currentPage * dataPerPage;
     const indexOfFirstData = indexOfLastData - dataPerPage;
     let currentData = dataCollection.slice(indexOfFirstData, indexOfLastData)

    //  useEffect(() => {
    //     //   handlesGetData()
    //  }, []);


     useEffect(() => {
          getDataHandler(searchValue)
     }, [searchValue]);


     function inputChangeHandler(searchKey) {
          setSearchValue(searchKey)
     }

     async function handlesGetData() {
          let data = await getSelectGroupsDataApi();
          let updatedData =[]
          data.forEach((item) => {  
            updatedData.push(  {
                asthma_order :null,
                copd_order: null,
                ild_order: null,
                ar_order: null,
                other_order: null,
                isDisabled:true,
                ...item
            })
            
       });
          setDataCollection(updatedData)
          let count = Math.ceil(data.length / dataPerPage);
        //   setDataCollection(data);

          setPageCount(count)
     }

     async function getDataHandler(search) {
          let count;
          if (search !== "") {
               const data = await getSelectGroupsDataApi();
               let filteredData = []
               data.forEach((data) => {
                    if (data.group_title.toLowerCase().includes(search.toLowerCase())) {
                         filteredData.push(data);
                    }
               });
               count = Math.ceil(filteredData.length / dataPerPage);
               setDataCollection(filteredData)
               setPageCount(count)
          } else {
               handlesGetData();
          }
     }

     // Pagination
     function paginationHandler(page) {
          setCurrentPage(page);
     }
     function changeHandler(event) {
          const pageLength = event.target.value
          setDataPerPage(pageLength);
          let count = Math.ceil(dataCollection.length / pageLength);
          setPageCount(count)
     }

     const handleDelete = async (id) => {
          await deleteDiscoverHomeWidget(id)
          handlesGetData()
     }
     
     const handleSelect = async(e, data, index) =>{ 
        if(!data.is_selected){
        let {asthma_order, copd_order, ild_order, ar_order, other_order, id, group_id } = data
        let payload ={
            asthma_order, 
            copd_order, 
            ild_order, 
            ar_order, 
            other_order,
            group_id, 
        }
        let tempData = []
        dataCollection.forEach((val, i) => {
            if(index === i ){
            let temp = val
            val['is_selected'] =  e.target.value
            tempData.push(temp)

            }else{
                tempData.push(val)

            }
        });
        setDataCollection(tempData)

        const responseObj = await addDiscoverGroup(payload);
        if (
            responseObj &&
            responseObj.response &&
            responseObj.response.code === 200
       ) {
            Toast.success(responseObj.response.alert[0].message)
            handlesGetData();
       }else{
        Toast.error(responseObj.response.alert[0].message)
       }
     }else{
          handleDelete(data.id)
     }
       
        
    }

    

    const handleInputChangeHandler = (key,e, index)  => {
        // const { name, value } = event.target
        let tempData = []
        dataCollection.forEach((val, i) => {
            if(index === i ){
            let temp = val
            val[key] =   Number(e.target.value) ?  Number(e.target.value) : ''
            tempData.push(temp)

            }else{
                tempData.push(val)

            }
        });
        setDataCollection(tempData)
   }
   const handleEdit = async (index, key, data) => {
    if(key ==='Edit'){
        let tempData = []
        dataCollection.forEach((val, i) => {
            if(index === i ){
            let temp = val
            val['isDisabled'] = false
            tempData.push(temp)
            }else{
                tempData.push(val)
            }
        });
        setDataCollection(tempData)
    }else {
        let {asthma_order, copd_order, ild_order, ar_order, other_order,group_id, id, } = data
        let payload ={
            asthma_order:asthma_order?asthma_order:null, 
            copd_order:copd_order?copd_order:null, 
            ild_order:ild_order?ild_order:null, 
            ar_order:ar_order?ar_order:null, 
            other_order:other_order?other_order:null,
            group_id,
            id:id
        }
        const responseObj = await updateDiscoverGroups(payload);
        if (
            responseObj &&
            responseObj.response &&
            responseObj.response.code === 200
       ) {
            Toast.success(responseObj.response.alert[0].message);
            let tempData = []
            dataCollection.forEach((val, i) => {
                if(index === i ){
                let temp = val
                val['isDisabled'] = true
                tempData.push(temp)
                }else{
                    tempData.push(val)
                }
            });
            setDataCollection(tempData)
       }else{
        Toast.error(responseObj.response.alert[0].message)
       }
    }
   }

   const publishOrUnpublish = async (data) => {
     const query = {
          "ACTIVE": "unpublish",
          "INACTIVE": "PUBLISH"
     }
     const resp = await discoverHomeWidgetPublishOrUnpublishAPI(data.id, query[data.status])
     if (resp.response.code ===200) {
          Toast.success(resp.response.alert[0].message)
     }else{
          Toast.error(resp.response.alert[0].message)
     }
     handlesGetData()
}

     return (
          <div className="table_container">
               <h4>Home Widget Group of Collection</h4>
               <div className="table_container__actionBox">
                    <div className="table_container__filterSearch">
                         <Search
                              value={searchValue}
                              placeholder="Search"
                              className={""}
                              onChange={inputChangeHandler}
                         />
                    </div>
                    
               </div>
               <div className="table_container__items_perPage">
                    Show
                    <SelectComp
                         options={options}
                         onChangeHandler={changeHandler}
                    />
                    entries
               </div>

               <div className="table-box">
                    <table>
                         <tr>
                              <th>Group Title</th>
                              <th>Group Type</th>
                              <th>Published Date</th>
                              <th>Updated Date</th>
                              <th>Status</th>
                              {/* <th>Expired On</th> */}
                              <th>Action</th>
                              <th>Asthma Order</th>
                              <th>COPD Order</th>
                              <th>ILD Order</th>
                              <th>AR Order</th>
                              <th>Other Orders</th>
                              <th>Select</th>
                         </tr>
                         {currentData.length > 0 && currentData.map((data, index) => (<tr key={data.id}>
                              <td>{data.group_title || "-"}</td>
                              <td>{data.group_type}</td>
                              <td>{data.published_date || "-"}</td>
                              <td>{data.updated_on || "-"}</td>
                              <td>{data.status || "-"}</td>
                              {/* <td>{data.expired_on || "-"}</td> */}
                              <td>
                                   <div className="btn-group" role="group" aria-label="Basic outlined example">
                                       <button
                                             type="button"
                                             className="btn btn-outline-primary"
                                             onClick={() => { handleEdit(index, data.isDisabled ? 'Edit': 'Save', data)}}
                                             disabled={!data.is_selected}
                                        >
                                             {data.isDisabled ? 'Edit': 'Save'}
                                        </button>
                                        
                                        <button type="button"
                                             onClick={() => handleDelete(data.id)}
                                             className="btn btn-outline-primary"
                                             disabled={!data.is_selected}
                                             >Delete
                                        </button>
                                        <button onClick={() => publishOrUnpublish(data)} type="button" className="btn btn-outline-primary"
                                         disabled={!data.is_selected}
                                        >{data.status == "ACTIVE" ?
                                             "Unpublish"
                                             :
                                             "Publish"
                                        }</button>
                                       
                                   </div>
                              </td> 
                              <td>
                                 <input
                                    type='text'
                                    className='form-input'
                                    placeholder=''
                                    name='asthma_order'
                                    value={data.asthma_order}
                                    onChange={(e) => handleInputChangeHandler('asthma_order',e, index )}
                                    disabled={data.isDisabled}
                                    min={0}
                                />
                              </td>
                              <td>
                                 <input
                                    type='text'
                                    className='form-input'
                                    placeholder=''
                                    name='copd_order'
                                    value={data.copd_order}
                                    onChange={(e) => handleInputChangeHandler('copd_order',e, index )}
                                    disabled={data.isDisabled}
                                />
                              </td>
                              <td>
                                 <input
                                    type='text'
                                    className='form-input'
                                    placeholder=''
                                    name='ild_order'
                                    value={data.ild_order}
                                    onChange={(e) => handleInputChangeHandler('ild_order',e, index )}
                                    disabled={data.isDisabled}
                                />
                              </td>
                              <td>
                                 <input
                                    type='text'
                                    className='form-input'
                                    placeholder=''
                                    name='ar_order'
                                    value={data.ar_order}
                                    onChange={(e) => handleInputChangeHandler('ar_order',e, index )}
                                    disabled={data.isDisabled}
                                />
                              </td>
                              <td>
                                 <input
                                    type='text'
                                    className='form-input'
                                    placeholder=''
                                    name='other_order'
                                    value={data.other_order}
                                    onChange={(e) => handleInputChangeHandler('other_order',e, index)}
                                    disabled={data.isDisabled}
                                />
                              </td>
                              <td>
                                <input type="checkbox" id="topping" name="topping" value="Paneer" onChange={(e) => handleSelect(e, data, index)} checked={data.is_selected}/>
                                </td> 

                         </tr>))}
                    </table>
               </div>

               <div className="table_container__pagination">
                    <Pagination
                         pages={pageCount}
                         onChange={paginationHandler}
                    />
               </div>

          </div >);
}

export default SelectGroupOfCollection;