import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Toggle from 'react-toggle';
import { MenuIcon } from 'constants/image-constants';
import './index.scss';
const CustomDragabbleTable = ({
  data,
  draggableTableHeader,
  onSelectedItemsChange,
  isSubmitClicked,
  handleResourseToggle,
  //handleDragEnd,
}) => {
  const handleDragEnd = (result) => {
    if (!result.destination) {
      return; // No valid drop destination, do nothing
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    // Create a new array with the item moved to the new position
    const updatedData = Array.from(data);
    const [movedItem] = updatedData.splice(sourceIndex, 1);
    updatedData.splice(destinationIndex, 0, movedItem);
    onSelectedItemsChange(updatedData);
  };

  return (
    <div className='mt-4'>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId='droppable' direction='vertical'>
          {(provided) => (
            <table ref={provided.innerRef} {...provided.droppableProps}>
              <thead>
                {draggableTableHeader?.forEach((item, i) => (
                  <th key={i} className='nowrap text-center'>
                    {item.Header}
                  </th>
                ))}
              </thead>
              {
                <tbody>
                  {data && data?.length > 0 && (
                    <tr>
                      <td>
                        <img
                          className='blur-text'
                          src={MenuIcon}
                          alt='Drag'
                          width='10'
                          height='10'
                        />
                      </td>
                      <td className='blur-text'>English</td>
                      <td>
                        <Toggle checked={true} icons={false} disabled />
                      </td>
                    </tr>
                  )}

                  {data.map((items, idx) => (
                    <Draggable
                      key={items.id.toString()}
                      draggableId={items.id.toString()}
                      index={idx}
                    >
                      {(provided) => (
                        <tr
                          key={items.id.toString()} // Use item's ID as the key here
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {/* Render table row content as before */}
                          <td>
                            <img
                              src={MenuIcon}
                              alt='Drag'
                              width='10'
                              height='10'
                            />
                          </td>
                          <td>{items?.name}</td>
                          <td>
                            <Toggle
                              checked={items?.toggle}
                              icons={false}
                              onChange={(e) => {
                                e.preventDefault();
                                handleResourseToggle(items.id, idx);
                              }}
                            />
                          </td>
                        </tr>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </tbody>
              }
            </table>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default CustomDragabbleTable;
