import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
// Protected Routes
import ProtectedRoute, { LogonRoute } from './protected-route';

// Pages
import HabitCreatePage from 'app/pages/HabitCreatePage';
import ActivitiesCreatePage from 'pages/ActivitiesCreatePage';
import ActivityPage from 'pages/ActivityPage';
import BannersPage from 'pages/BannersPage';
import CreateBlogsPage from 'pages/CreateBlogsPage';
import CreateUsersPage from 'pages/CreateUsersPage';
import FAQPage from 'pages/FAQPage';
import FaqCreatePage from 'pages/FaqCreatePage';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import GamificationCreatePage from 'pages/GamificationCreatePage';
import GamificationPage from 'pages/GamificationPage';
import HabitsPage from 'pages/HabitsPage';
import Login from 'pages/Login';
import MasterDataPage from 'pages/MasterDataPage';
import NewsFeedPage from 'pages/NewsFeedPage';
import PageNotFound from 'pages/PageNotFound';
import ResetPasswordPage from 'pages/ResetPasswordPage';
import StaticCreatePage from 'pages/StaticCreatePage';
import StaticPage from 'pages/StaticPage';
import UpdatePasswordPage from 'pages/UpdatePasswordPage';
import UserPage from 'pages/UserPage';
// import QuizPage from 'pages/QuizPage';
import SplashScreenCreate from 'app/components/SplashScreenCreate';
import BannerCreatePage from 'app/pages/BannerCreatePage';
import BreatheFreeUserPage from 'app/pages/BreathefreeUserPage';
import BreatheFreePage from 'app/pages/BrethefreePage';
import CmsContent from 'app/pages/CMSPanel/Content';
import CreateContent from 'app/pages/CMSPanel/CreateContent';
import RecommendCourses from 'app/pages/RecommendCourses';
import OnBoardingScreen from 'components/OnBoardingScreen';
import CreateQuizPage from 'pages/CreateQuizPage';
// Constants
/* , LocalSessionKey */
import { APP_CONSTANTS } from 'constants/app-constants';
import { hanldeDontHaveAccess } from 'utils/Parsers';
// Load all the stylesheets
import BulkUploadSummary from 'app/container/CreateContentContainer/BulkUploadSummary';
import ActivityVitals from 'app/pages/ActivityVitals';
import CreateGroups from 'app/pages/CMSPanel/CreateGroups';
import CreateStories from 'app/pages/CMSPanel/CreateStories';
import CreateTags from 'app/pages/CMSPanel/CreateTags';
import CmsDiscoverHomeWidget from 'app/pages/CMSPanel/DiscoverHomeWidget';
import CmsDiscover from 'app/pages/CMSPanel/Disover';
import TagsTable from 'app/pages/CMSPanel/Tags';
import CelebrityUserPage from 'app/pages/CelebrityUserPage';
import CelebrityVideoPage from 'app/pages/CelebrityVideoPage';
import CreateWebinarPage from 'app/pages/CreateWepinarPage';
import DidYouKnowUser from 'app/pages/DidYouKnowUser';
import DidYouKnowPage from 'app/pages/DidyouKnowPage';
import EditActivityVitals from 'app/pages/EditActivityVitals';
import HomePageEdit from 'app/pages/HomePageEdit';
import HomePageOrder from 'app/pages/HomePageOrder';
import Understanding from 'app/pages/UnderStanding';
import UnderstandUserPage from 'app/pages/UnderstandUserPage';
import Webinar from 'app/pages/Webinar';
import 'styles/index.scss';
import Collections from '../pages/CMSPanel/Collections';
import CreateCollection from '../pages/CMSPanel/CreateCollection';
import EditCollection from '../pages/CMSPanel/EditCollection';

import CreateUserJourneyTask from 'app/pages/CreateUserJourneyTask';
import CreateExercises from 'app/pages/Exercises/CreateExercises';
import ExercisesList from 'app/pages/Exercises/ExercisesList';
import Navigations from 'app/pages/Navigations';
import CreateNavigation from 'app/pages/Navigations/CreateNavigation';
import CreateRecommendedAction from 'app/pages/RecommendedAction/CreateRecommendedAction';
import RecommendedActionList from 'app/pages/RecommendedAction/RecommendedActionList';
import ServicesWidget from 'app/pages/ServicesWidget';
import TipOfTheDay from 'app/pages/TipOfTheDay';
import CreateTip from 'app/pages/TipOfTheDay/CreateTip';
import UserJourneyPage from 'app/pages/UserJourney';
import StreakRewardList from 'app/pages/UserJourney/StreakRewardList';
import CouponList from 'pages/UserJourney/CouponList';
import CreateCoupon from 'pages/UserJourney/CreateCoupon';
import CreateStreakRewardContainer from 'pages/UserJourney/CreateStreakReward';
import VersionControl from 'pages/VersionControl';

import LargeBannersPage from 'app/pages/MedicineDelivery/LargeBannersPage';
import SmallBannersPage from 'app/pages/MedicineDelivery/SmallBannersPage';
import WidgetsCreatePage from 'app/pages/WidgetsCreatePage';
import MedicineLandingPageOrder from 'pages/MedicineDelivery/MedicineLandingPageOrder';
import MedicineReminder from 'app/pages/MedicineReminder';
import MedicineDiscount from 'app/pages/MedicineDiscount';

// CMS Vitals

import DiagnosticsLargeBannersPage from 'app/pages/Diagonostic/DiagnosticsLargeBannersPage';
import DiagnosticsSmallBannersPage from 'app/pages/Diagonostic/DiagnosticsSmallBannersPage';
import MedicineCategoriesPage from 'app/pages/MedicineDelivery/MedicineCategoriesPage';
import DiagnosticLandingPageOrder from 'pages/Diagonostic/DiagnosticLandingPageOrder';

import MedpaySubCategoriesPage from 'app/pages/MedpaySubCategoriesPage';
import SubCategoriesPage from 'app/pages/SubCategoriesPage';
import SearchScreen from 'pages/MedicineDelivery/MedicineSearchScreen';

import DiagnosticCategoriesPage from 'app/pages/Diagonostic/DiagnosticCategoriesPage';
import DiagnosticSearchPage from 'app/pages/Diagonostic/DiagnosticSearchPage';

import CreateCoins from 'app/container/BreathfreeCoins/CreateCoins';
import CoinsPage from 'app/pages/CoinsPage';

// CMS Vitals
import FamilyPlansCreatePage from 'app/pages/FamilyPlansCreatePage';
import FamilyPlansPage from 'app/pages/FamilyPlansPage';
import ProductFamilyCreatePage from 'app/pages/ProductFamilyCreatePage';
import ProductFamilyPage from 'app/pages/ProductFamilyPage';
import RecommendationsCreatePage from 'app/pages/RecommendationsCreatePage';
import RecommendationsPage from 'app/pages/RecommendationsPage';
import PlanActivation from 'app/pages/PlanActivation';

import AbTestingCreatePage from 'app/pages/AbTestingCreatePage';
import AbTestingPage from 'app/pages/AbTestingPage';

import AbTestingUploadPage from 'app/pages/AbTestingUploadPage';
import CouponAndOfferPage from 'app/pages/CouponAndOfferPage';
import CreateCouponAndOffer from 'container/CouponAndOffers/CreateCouponAndOffer';

//sprint 10
import CoursesBanner from 'app/pages/CoursesBanner';
import CoursesCreate from 'app/pages/CoursesCreate';
import CoursesCreation from 'app/pages/CoursesCreation';
import CoursesPageOrdering from 'app/pages/CoursesPageOrdering';
import CoursesQuiz from 'app/pages/CoursesQuiz';
import CoursesText from 'app/pages/CoursesText';
import CoursesVideo from 'app/pages/CoursesVideo';
import DynamicWidgets from 'app/pages/DynamicWidgets';
import CreateWidget from 'app/pages/DynamicWidgets/CreateWidget';
import ElsAdsSpace from 'app/pages/ElsAdsSpace';
import HomePageOrdering from 'app/pages/HomePageOrdering';
import CreateHomePageOrder from 'app/pages/HomePageOrdering/createHomePageOrder';
import HomePageSection from 'app/pages/HomePageSection';
import CreateHomePage from 'app/pages/HomePageSection/CreateHomePage';
import LanguageMasterPage from 'app/pages/LanguageMasterPage';
import StaticWidgets from 'app/pages/StaticWidgets';
import CreateStaticWidget from 'app/pages/StaticWidgets/CreateStaticWidget';
import AdTask from 'app/pages/UserJourney/AdTask';
import CreateAdTask from 'app/pages/UserJourney/CreateAdTask';
import CreateMilestone from 'app/pages/UserJourney/CreateMilestone';
import Milestone from 'app/pages/UserJourney/Milestone';

import CustomerOrders from 'app/pages/CustomerOrders';
import CreateCustomerOrders from 'app/pages/CustomerOrders/CreateCustomerOrder';
import CustomerDetails from 'app/pages/CustomerOrders/CustomerDetails';
import Rating from 'app/pages/Rating';
import CreateRating from 'app/pages/RatingCreate';
import RewardsMasterPage from 'app/pages/Rewards';
import SucessStoryMasterPage from 'pages/SuccessStory';
import AdvertisementList from 'app/pages/Exercises/AdvertisementList';
import PremiumExercises from 'app/pages/Exercises/PremiumExercises';
import CreateAdvertisement from 'app/pages/Exercises/CreateAdvertisement'; 
import UserLanding from 'app/pages/UserLanding';
import CreateNewLanding from 'app/pages/UserLanding/createNewLanding';
import LandingPages from 'app/pages/LandingPages';
import CreateLandingScreen from 'app/container/Landingpage/CreateLandingscreen';
import CreateLanding from 'app/pages/LandingPages/CreateLanding';
import UpdateUserRefillPage from 'app/pages/UpdateUserRefillPage';
import AuditLogs from 'app/pages/AuditLogs';
import LcpSession from 'app/pages/LcpSession';
const CmsRoutes = () => {
  return (
    <React.Suspense fallback={<p>Loading....</p>}>
      <BrowserRouter>
        <Switch>
          <LogonRoute exact path="/" component={Login} />
          <Route
            exact
            path={APP_CONSTANTS.FORGOT_PASSWORD}
            component={ForgotPasswordPage}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.SERVICES}
            component={ServicesWidget}
            isDontHaveAccess={hanldeDontHaveAccess("SERVICES")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.LATEST_COUPON_OFFER}
            component={CouponAndOfferPage}
            isDontHaveAccess={hanldeDontHaveAccess("LATESTCOUPONANDOFFERS")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.LATEST_CREATE_COUPON_OFFER}
            component={CreateCouponAndOffer}
            isDontHaveAccess={hanldeDontHaveAccess("LATESTCOUPONANDOFFERS")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.LATEST_EDIT_COUPON_OFFER}
            component={CreateCouponAndOffer}
            isDontHaveAccess={hanldeDontHaveAccess("LATESTCOUPONANDOFFERS")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CREATE_FEED}
            component={CreateBlogsPage}
            isDontHaveAccess={hanldeDontHaveAccess("FEED")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.EDIT_FEED}
            component={CreateBlogsPage}
            isDontHaveAccess={hanldeDontHaveAccess("FEED")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.NEWSFEED}
            component={NewsFeedPage}
            isDontHaveAccess={hanldeDontHaveAccess("FEED")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.BANNER_LIST}
            component={BannersPage}
            isDontHaveAccess={hanldeDontHaveAccess("BANNER")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.HABITS}
            component={HabitsPage}
            isDontHaveAccess={hanldeDontHaveAccess("HABITS")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.EDIT_HABITS}
            component={HabitCreatePage}
            isDontHaveAccess={hanldeDontHaveAccess("HABITS")}

            // exact
            // path={APP_CONSTANTS.EDIT_BANNER}
            // component={BannerCreatePage}
            // isDontHaveAccess={hanldeDontHaveAccess('BANNER')}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CREATE_GAMIFICATION}
            component={GamificationCreatePage}
            isDontHaveAccess={hanldeDontHaveAccess("CHALLANGES")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.EDIT_GAMIFICATION}
            component={GamificationCreatePage}
            isDontHaveAccess={hanldeDontHaveAccess("CHALLANGES")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.GAMIFICATION}
            component={GamificationPage}
            isDontHaveAccess={hanldeDontHaveAccess("CHALLANGES")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.USERS}
            component={UserPage}
            isDontHaveAccess={hanldeDontHaveAccess("CMS_USERS")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.HOMEPAGEORDERING}
            component={HomePageOrdering}
            isDontHaveAccess={hanldeDontHaveAccess("HOMEPAGEORDER")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.HOMEPAGEORDER}
            component={HomePageOrder}
            isDontHaveAccess={hanldeDontHaveAccess("HOMEPAGEORDER")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.EDIT_HOMEPAGEORDER}
            component={HomePageEdit}
            isDontHaveAccess={hanldeDontHaveAccess("HOMEPAGEORDER")}
          />
          <Route
            exact
            path={APP_CONSTANTS.CREATE_HOME_PAGE_ORDER}
            component={CreateHomePageOrder}
            // isDontHaveAccess={hanldeDontHaveAccess('HOMEPAGEORDER')}
          />
          <Route
            exact
            path={APP_CONSTANTS.EDIT_HOME_PAGE_ORDER}
            component={CreateHomePageOrder}
            // isDontHaveAccess={hanldeDontHaveAccess('HOMEPAGEORDER')}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.USERLANDING}
            component={UserLanding}
            // isDontHaveAccess={hanldeDontHaveAccess('HOMEPAGEORDER')}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CREATE_USER_LANDING}
            component={CreateNewLanding}
            // isDontHaveAccess={hanldeDontHaveAccess('HOMEPAGEORDER')}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.EDIT_USER_LANDING}
            component={CreateNewLanding}
            // isDontHaveAccess={hanldeDontHaveAccess('HOMEPAGEORDER')}
          />
          <Route
            exact
            path={APP_CONSTANTS.LANDING_PAGE}
            component={LandingPages}
            // isDontHaveAccess={hanldeDontHaveAccess('HOMEPAGEORDER')}
          />
          <Route
            exact
            path={APP_CONSTANTS.CREATE_LANDING_PAGE}
            component={CreateLanding}
            // isDontHaveAccess={hanldeDontHaveAccess('HOMEPAGEORDER')}
          />
          <Route
            exact
            path={APP_CONSTANTS.EDIT_LANDING_PAGE}
            component={CreateLanding}
            // isDontHaveAccess={hanldeDontHaveAccess('HOMEPAGEORDER')}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.ACTIVITY_VITALS}
            component={ActivityVitals}
            isDontHaveAccess={hanldeDontHaveAccess("ACTIVITY_VITALS")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.EDIT_ACTIVITY_VITALS}
            component={EditActivityVitals}
            isDontHaveAccess={hanldeDontHaveAccess("ACTIVITY_VITALS")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.WEBINAR}
            component={Webinar}
            isDontHaveAccess={hanldeDontHaveAccess("WEBINAR")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CMS_PANEL_CREATE}
            component={CreateContent}
            isDontHaveAccess={false}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CMS_PANEL}
            component={CmsContent}
            isDontHaveAccess={false}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CMS_PANEL_COLLECTIONS}
            component={Collections}
            isDontHaveAccess={false}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CMS_PANEL_CREATE_COLLECTION}
            component={CreateCollection}
            isDontHaveAccess={false}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CMS_PANEL_EDIT_COLLECTION}
            component={EditCollection}
            isDontHaveAccess={false}
          />
          <ProtectedRoute
            path={APP_CONSTANTS.CMS_PANEL_CREATE_TAGS}
            component={CreateTags}
            isDontHaveAccess={false}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CMS_PANEL_CREATE_STORIES}
            component={CreateStories}
            isDontHaveAccess={false}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CMS_PANEL_EDIT_STORIES}
            component={CreateStories}
            isDontHaveAccess={false}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CMS_PANEL_CREATE_GROUP}
            component={CreateGroups}
            isDontHaveAccess={false}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CMS_PANEL_EDIT_GROUP}
            component={CreateGroups}
            isDontHaveAccess={false}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CMS_PANEL_BULK_UPLOAD}
            component={BulkUploadSummary}
            isDontHaveAccess={false}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CMS_PANEL_TAGS}
            component={TagsTable}
            isDontHaveAccess={false}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CMS_PANEL_DISCOVER}
            component={CmsDiscover}
            isDontHaveAccess={false}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CMS_PANEL_DISCOVER_HOME_WIDGET}
            component={CmsDiscoverHomeWidget}
            isDontHaveAccess={false}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CREATE_WEBINAR}
            component={CreateWebinarPage}
            isDontHaveAccess={hanldeDontHaveAccess("WEBINAR")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.EDIT_WEBINAR}
            component={CreateWebinarPage}
            isDontHaveAccess={hanldeDontHaveAccess("WEBINAR")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.BREATEFREE}
            component={BreatheFreePage}
            isDontHaveAccess={false}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.VIDEO}
            // component={Breethfree}
            // isDontHaveAccess={hanldeDontHaveAccess('BREATEFREE')}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.ONBOARDING}
            component={OnBoardingScreen}
            isDontHaveAccess={hanldeDontHaveAccess("ONBOARDING")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.UPLOAD_MASTER_DATA}
            component={MasterDataPage}
            isDontHaveAccess={hanldeDontHaveAccess("UPLOAD_MASTER_DETAILS")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CREATE_STATIC}
            component={StaticCreatePage}
            isDontHaveAccess={hanldeDontHaveAccess("STATIC_CONTENT")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.EDIT_STATIC}
            component={StaticCreatePage}
            isDontHaveAccess={hanldeDontHaveAccess("STATIC_CONTENT")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.STATIC_PAGE}
            component={StaticPage}
            isDontHaveAccess={hanldeDontHaveAccess("STATIC_CONTENT")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CREATE_FAQ}
            component={FaqCreatePage}
            isDontHaveAccess={hanldeDontHaveAccess("FAQ")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.EDIT_FAQ}
            component={FaqCreatePage}
            isDontHaveAccess={hanldeDontHaveAccess("FAQ")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CREATE_BANNER}
            component={BannerCreatePage}
            isDontHaveAccess={hanldeDontHaveAccess("BANNER")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.EDIT_BANNER}
            component={BannerCreatePage}
            isDontHaveAccess={hanldeDontHaveAccess("BANNER")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.WIDGET_LIST}
            component={WidgetsCreatePage}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.EDIT_WIDGET}
            component={WidgetsCreatePage}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.MEDICINELARGEBANNER}
            component={LargeBannersPage}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.MEDICINESMALLBANNER}
            component={SmallBannersPage}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.MEDICINESEARCHSCREEN}
            component={SearchScreen}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.DIAGONOSTICLARGEBANNER}
            component={DiagnosticsLargeBannersPage}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.DIAGONOSTICSMALLBANNER}
            component={DiagnosticsSmallBannersPage}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.FAQ}
            component={FAQPage}
            isDontHaveAccess={hanldeDontHaveAccess("FAQ")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.ACTIVITIES}
            component={ActivityPage}
            isDontHaveAccess={hanldeDontHaveAccess("ACTIVITIES")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.EDIT_ACTIVITIES}
            component={ActivitiesCreatePage}
            isDontHaveAccess={hanldeDontHaveAccess("ACTIVITIES")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CREATE_USER}
            component={CreateUsersPage}
            isDontHaveAccess={hanldeDontHaveAccess("CMS_USERS")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.COURSES_RECOMMEND}
            component={RecommendCourses}
            isDontHaveAccess={hanldeDontHaveAccess("CMS_COURSES")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.COURSES_PAGE_ORDERING}
            component={CoursesPageOrdering}
            isDontHaveAccess={hanldeDontHaveAccess("CMS_COURSES")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.COURSES_BANNERS}
            component={CoursesBanner}
            isDontHaveAccess={hanldeDontHaveAccess("CMS_COURSES")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.COURSES_CREATION}
            component={CoursesCreation}
            isDontHaveAccess={hanldeDontHaveAccess("CMS_COURSES")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.COURSES_CREATE}
            component={CoursesCreate}
            isDontHaveAccess={hanldeDontHaveAccess("CMS_COURSES")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.COURSES_EDIT}
            component={CoursesCreate}
            isDontHaveAccess={false}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.COURSES_VIDEO}
            component={CoursesVideo}
            isDontHaveAccess={hanldeDontHaveAccess("CMS_COURSES")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.EDIT_COURSES_VIDEO}
            component={CoursesVideo}
            isDontHaveAccess={false}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.COURSES_TEXT}
            component={CoursesText}
            isDontHaveAccess={hanldeDontHaveAccess("CMS_COURSES")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.EDIT_COURSES_TEXT}
            component={CoursesText}
            isDontHaveAccess={false}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.COURSES_QUIZ}
            component={CoursesQuiz}
            isDontHaveAccess={hanldeDontHaveAccess("CMS_COURSES")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.EDIT_COURSES_QUIZ}
            component={CoursesQuiz}
            isDontHaveAccess={false}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.ELS_ADS_SPACE}
            component={ElsAdsSpace}
            isDontHaveAccess={false}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.EDIT_USER}
            component={CreateUsersPage}
            isDontHaveAccess={hanldeDontHaveAccess("CMS_USERS")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.BREATEFREE_CREATE}
            component={BreatheFreeUserPage}
            isDontHaveAccess={false}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CELEBRITY}
            component={CelebrityVideoPage}
            isDontHaveAccess={false}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CELEBRITY_CREATE}
            component={CelebrityUserPage}
            isDontHaveAccess={false}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.EDIT_CELEBRITY}
            component={CelebrityUserPage}
            isDontHaveAccess={false}
            // isDontHaveAccess={true}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.UNDERSTANDING_DEVICES}
            component={Understanding}
            isDontHaveAccess={false}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.UNDERSTANDING_DEVICES_CREATE}
            component={UnderstandUserPage}
            isDontHaveAccess={false}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.EDIT_UNDERSTANDING_DEVICES}
            component={UnderstandUserPage}
            isDontHaveAccess={false}
            // isDontHaveAccess={true}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.DIDYOUKNOW}
            component={DidYouKnowPage}
            isDontHaveAccess={false}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.DIDYOUKNOW_CREATE}
            component={DidYouKnowUser}
            isDontHaveAccess={false}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.EDIT_DIDYOUKNOW}
            component={DidYouKnowUser}
            isDontHaveAccess={false}
            // isDontHaveAccess={true}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.SPLASH_SCREEN_CREATE}
            component={SplashScreenCreate}
            isDontHaveAccess={hanldeDontHaveAccess("ONBOARDING")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.SPLASH_SCREEN_EDIT}
            component={SplashScreenCreate}
            isDontHaveAccess={hanldeDontHaveAccess("ONBOARDING")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.EDIT_BREATHEFREE}
            component={BreatheFreeUserPage}
            isDontHaveAccess={false}
            // isDontHaveAccess={true}
          />
          {/* <ProtectedRoute
            exact
            path={APP_CONSTANTS.CREATE_PASSWORD}
            component={UpdatePasswordPage}
          />

          <ProtectedRoute   
            exact
            path={APP_CONSTANTS.CREATE_PASSWORD}
            component={ResetPasswordPage}
          /> */}
          <Route
            exact
            path={APP_CONSTANTS.RESET_PASSWORD}
            component={UpdatePasswordPage}
          />
          <Route
            exact
            path={APP_CONSTANTS.CREATE_PASSWORD}
            component={ResetPasswordPage}
          />
          {/* <ProtectedRoute
            exact
            path={APP_CONSTANTS.QUIZ}
            component={QuizPage}
          />

          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CREATE_QUIZ}
            component={CreateQuizPage}
          /> */}
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.EDIT_QUIZ}
            component={CreateQuizPage}
          />
          <Route
            exact
            path={APP_CONSTANTS.PAGE_NOT_FOUND}
            component={PageNotFound}
          />
          <Route
            exact
            path={APP_CONSTANTS.BREATHING_EXERCISES}
            component={ExercisesList}
          />
          <Route
            exact
            path={APP_CONSTANTS.CREATE_BREATHING_EXERCISES}
            component={CreateExercises}
          />
          <Route
            exact
            path={APP_CONSTANTS.EDIT_BREATHING_EXERCISES}
            component={CreateExercises}
          />
          <Route
            exact
            path={APP_CONSTANTS.USER_JOURNEY}
            component={UserJourneyPage}
          />
          <Route
            exact
            path={APP_CONSTANTS.CREATE_USER_JOURNEY_TASK}
            component={CreateUserJourneyTask}
          />
          <Route
            exact
            path={APP_CONSTANTS.EDIT_USER_JOURNEY_TASK}
            component={CreateUserJourneyTask}
          />
          <Route
            exact
            path={APP_CONSTANTS.USER_JOURNEY_STREAK_REWARDS}
            component={StreakRewardList}
          />
          <Route
            exact
            path={APP_CONSTANTS.CREATE_USER_JOURNEY_STREAK_REWARD}
            component={CreateStreakRewardContainer}
          />
          <Route
            exact
            path={APP_CONSTANTS.EDIT_USER_JOURNEY_STREAK_REWARD}
            component={CreateStreakRewardContainer}
          />
          <Route
            exact
            path={APP_CONSTANTS.USER_JOURNEY_COUPON}
            component={CouponList}
          />
          <Route
            exact
            path={APP_CONSTANTS.CREATE_USER_JOURNEY_COUPON}
            component={CreateCoupon}
          />
          <Route
            exact
            path={APP_CONSTANTS.EDIT_USER_JOURNEY_COUPON}
            component={CreateCoupon}
          />
          <Route
            exact
            path={APP_CONSTANTS.USER_JOURNEY_MILESTONE}
            component={Milestone}
          />
          <Route
            exact
            path={APP_CONSTANTS.CREATE_USER_JOURNEY_MILESTONE}
            component={CreateMilestone}
          />
          <Route
            exact
            path={APP_CONSTANTS.CREATE_USER_JOURNEY_MILESTONE}
            component={CreateMilestone}
          />
          <Route
            exact
            path={APP_CONSTANTS.EDIT_USER_JOURNEY_MILESTONE}
            component={CreateMilestone}
          />
          <Route
            exact
            path={APP_CONSTANTS.USER_JOURNEY_ADTASK}
            component={AdTask}
          />
          <Route
            exact
            path={APP_CONSTANTS.CREATE_USER_JOURNEY_ADTASK}
            component={CreateAdTask}
          />
          <Route
            exact
            path={APP_CONSTANTS.EDIT_USER_JOURNEY_ADTASK}
            component={CreateAdTask}
          />
          <Route
            exact
            path={APP_CONSTANTS.RECOMMENDED_ACTION}
            component={RecommendedActionList}
          />
          <Route
            exact
            path={APP_CONSTANTS.TIP_OF_THE_DAY}
            component={TipOfTheDay}
          />
          <Route
            exact
            path={APP_CONSTANTS.CREATE_TIP_OF_THE_DAY}
            component={CreateTip}
          />
          <Route
            exact
            path={APP_CONSTANTS.EDIT_TIP_OF_THE_DAY}
            component={CreateTip}
          />
          <Route
            exact
            path={APP_CONSTANTS.CREATE_RECOMMENDED_ACTION}
            component={CreateRecommendedAction}
          />
          <Route
            exact
            path={APP_CONSTANTS.EDIT_RECOMMENDED_ACTION}
            component={CreateRecommendedAction}
          />
          <Route
            exact
            path={APP_CONSTANTS.NAVAGITAIONS}
            component={Navigations}
          />
          <Route
            exact
            path={APP_CONSTANTS.CREATE_NAVIGATION}
            component={CreateNavigation}
          />
          <Route
            exact
            path={APP_CONSTANTS.EDIT_NAVIGATION}
            component={CreateNavigation}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.MEDICINECATEGORIES}
            component={MedicineCategoriesPage}
            isDontHaveAccess={hanldeDontHaveAccess("MEDICINE_DELIVERY")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.MEDICINEPAGEORDER}
            component={MedicineLandingPageOrder}
            isDontHaveAccess={hanldeDontHaveAccess("MEDICINE_DELIVERY")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.EDIT_MEDICINEPAGEORDER}
            component={HomePageEdit}
            isDontHaveAccess={hanldeDontHaveAccess("MEDICINE_DELIVERY")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.SELFSUBCATEGORIES}
            component={SubCategoriesPage}
            isDontHaveAccess={hanldeDontHaveAccess("MEDICINE_DELIVERY")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.MEDPAYSUBCATEGORIES}
            component={MedpaySubCategoriesPage}
            isDontHaveAccess={hanldeDontHaveAccess("MEDICINE_DELIVERY")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.MEDICINESREMINDERSCREEN}
            component={MedicineReminder}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.MEDICINESDISCOUNT}
            component={MedicineDiscount}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.DIAGONOSTICPAGEORDER}
            component={DiagnosticLandingPageOrder}
            isDontHaveAccess={hanldeDontHaveAccess("DIAGNOSTIC")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.EDIT_DIAGONOSTICPAGEORDER}
            component={HomePageEdit}
            isDontHaveAccess={hanldeDontHaveAccess("DIAGNOSTIC")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.DIAGONOSTICCATEGORIES}
            component={DiagnosticCategoriesPage}
            isDontHaveAccess={hanldeDontHaveAccess("DIAGNOSTIC")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.DIAGONOSTICSEARCHSCREEN}
            component={DiagnosticSearchPage}
            isDontHaveAccess={hanldeDontHaveAccess("DIAGNOSTIC")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.VERSION_CONTROL}
            component={VersionControl}
            isDontHaveAccess={hanldeDontHaveAccess("INAPPUPDATE")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CMS_VITALS_PRODUCT_FAMILY}
            component={ProductFamilyPage}
            isDontHaveAccess={hanldeDontHaveAccess("PRODUCT_CATALOG")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CMS_VITALS_CREATE_PRODUCT_FAMILY}
            component={ProductFamilyCreatePage}
            isDontHaveAccess={hanldeDontHaveAccess("PRODUCT_CATALOG")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CMS_VITALS_EDIT_PRODUCT_FAMILY}
            component={ProductFamilyCreatePage}
            isDontHaveAccess={hanldeDontHaveAccess("PRODUCT_CATALOG")}
          />
          {/* <ProtectedRoute
            exact
            path={APP_CONSTANTS.COUPON_AND_OFFER}
            component={ProductCouponAndOfferPage}
            isDontHaveAccess={hanldeDontHaveAccess('PRODUCT_CATALOG')}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CREATE_COUPUN_OFFERS}
            component={CreateCouponAndOffer}
            isDontHaveAccess={hanldeDontHaveAccess('PRODUCT_CATALOG')}
          /> */}
          {/* <ProtectedRoute
            exact
            path={APP_CONSTANTS.EDIT_COUPUN_OFFERS}
            component={CreateCouponAndOffer}
            isDontHaveAccess={hanldeDontHaveAccess('PRODUCT_CATALOG')}
          /> */}
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CMS_VITALS_FAMILY_PLANS}
            component={FamilyPlansPage}
            isDontHaveAccess={hanldeDontHaveAccess("PRODUCT_CATALOG")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CMS_VITALS_CREATE_FAMILY_PLANS}
            component={FamilyPlansCreatePage}
            isDontHaveAccess={hanldeDontHaveAccess("PRODUCT_CATALOG")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CMS_VITALS_EDIT_FAMILY_PLANS}
            component={FamilyPlansCreatePage}
            isDontHaveAccess={hanldeDontHaveAccess("PRODUCT_CATALOG")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CMS_VITALS_RECOMMENDATIONS}
            component={RecommendationsPage}
            isDontHaveAccess={hanldeDontHaveAccess("PRODUCT_CATALOG")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CMS_VITALS_CREATE_RECOMMENDATIONS}
            component={RecommendationsCreatePage}
            isDontHaveAccess={hanldeDontHaveAccess("PRODUCT_CATALOG")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CMS_VITALS_EDIT_RECOMMENDATIONS}
            component={RecommendationsCreatePage}
            isDontHaveAccess={hanldeDontHaveAccess("PRODUCT_CATALOG")}
          />
          <Route
            exact
            path={APP_CONSTANTS.CMS_PLAN_ACTIVATION}
            component={PlanActivation}
            // isDontHaveAccess={hanldeDontHaveAccess('PRODUCT_CATALOG')}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.DYNAMIC_WIDGETS}
            component={DynamicWidgets}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.STATICS_WIDGETS}
            component={StaticWidgets}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.EDIT_STATIC_WIDGETS}
            component={CreateStaticWidget}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.HOME_PAGE_SECTIONS}
            component={HomePageSection}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CREATE_HOME_PAGE_SECTIONS}
            component={CreateHomePage}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.EDIT_HOME_PAGE_SECTIONS}
            component={CreateHomePage}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CREATE_WIDGETS}
            component={CreateWidget}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.EDIT_WIDGETS}
            component={CreateWidget}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CMS_VITALS_AB_TESTING}
            component={AbTestingPage}
            isDontHaveAccess={hanldeDontHaveAccess("AB_TESTING")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CMS_VITALS_UPLOAD_AB_TESTING}
            component={AbTestingUploadPage}
            isDontHaveAccess={hanldeDontHaveAccess("AB_TESTING")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CMS_VITALS_CREATE_AB_TESTING}
            component={AbTestingCreatePage}
            isDontHaveAccess={hanldeDontHaveAccess("AB_TESTING")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CMS_VITALS_EDIT_AB_TESTING}
            component={AbTestingCreatePage}
            isDontHaveAccess={hanldeDontHaveAccess("AB_TESTING")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.BREATHFREE_COIN}
            component={CoinsPage}
            isDontHaveAccess={hanldeDontHaveAccess("COINS")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.BREATHFREE_CREATE_COIN}
            component={CreateCoins}
            isDontHaveAccess={hanldeDontHaveAccess("COINS")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.BREATHFREE_EDIT_COIN}
            component={CreateCoins}
            isDontHaveAccess={hanldeDontHaveAccess("COINS")}
          />
          CreateCustomerOrders
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CUSTOMER_ORDERS}
            component={CustomerOrders}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CREATE_CUSTOMER_ORDER}
            component={CreateCustomerOrders}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.CUSTOMER_ORDER_DETAILS}
            component={CustomerDetails}
          />
          <Route
            exact
            path={APP_CONSTANTS.ADVERTISEMENT}
            component={AdvertisementList}
          />
          <Route
            exact
            path={APP_CONSTANTS.PREMIUMEXERCISES}
            component={PremiumExercises}
          />
          <Route
            exact
            path={APP_CONSTANTS.CREATE_ADVERTISEMENT}
            component={CreateAdvertisement}
          />
          <Route
            exact
            path={APP_CONSTANTS.EDIT_ADVERTISEMENT}
            component={CreateAdvertisement}
          />
          <Route
            exact
            path={APP_CONSTANTS.LOCALISATION_LANGUAGE_LIST}
            component={LanguageMasterPage}
            isDontHaveAccess={hanldeDontHaveAccess("LOCALISATION")}
          />
          <Route
            exact
            path={APP_CONSTANTS.REWARDS}
            component={RewardsMasterPage}
            isDontHaveAccess={hanldeDontHaveAccess("REFERRAL")}
          />
          <Route
            exact
            path={APP_CONSTANTS.REFRRAL_HOME}
            component={SucessStoryMasterPage}
            isDontHaveAccess={hanldeDontHaveAccess("REFERRAL")}
          />
          <Route
            exact
            path={APP_CONSTANTS.REFERRAL}
            component={RewardsMasterPage}
            isDontHaveAccess={hanldeDontHaveAccess("REFERRAL")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.RATING}
            component={Rating}
            isDontHaveAccess={hanldeDontHaveAccess("RATINGS")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.RATING_CREATE}
            component={CreateRating}
            isDontHaveAccess={hanldeDontHaveAccess("RATINGS")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.RATING_EDIT}
            component={CreateRating}
            isDontHaveAccess={hanldeDontHaveAccess("RATINGS")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.UPDATE_USER_REFILL}
            component={UpdateUserRefillPage}
            isDontHaveAccess={hanldeDontHaveAccess("UPDATE_USER_REFILL")}
          />
          <Route
            exact
            path={APP_CONSTANTS.LCP_SESSION}
            component={LcpSession}
            isDontHaveAccess={hanldeDontHaveAccess("LCP_SESSION")}
          />
          <ProtectedRoute
            exact
            path={APP_CONSTANTS.AUDIT_LOGS}
            component={AuditLogs}
            isDontHaveAccess={hanldeDontHaveAccess("AUDIT_LOGS")}
          />
          {/* Page Not Found page has to come */}
          <Redirect from="*" to={APP_CONSTANTS.PAGE_NOT_FOUND} />
        </Switch>
      </BrowserRouter>
    </React.Suspense>
  );
};

export default CmsRoutes;
