import React, { useState } from 'react';

// Components
import Loader from 'components/Loader';
import NewTable from 'components/NewTable';
import PageLoader from 'components/PageLoader';

// Constants
import { MasterDataType } from 'constants/app-constants';

// Utils
import Toast from 'utils/Toast';
import Message from 'utils/Message';

// API
import { getMasterData, uploadMasterData } from 'api/master-data';

const MasterData = ({ tableHeader }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [pageLoading, setPageLoading] = useState(false);

  const loadNames = async () => {
    setLoading(true);
    let response = await getMasterData();
    setLoading(false);
    if (response && response.tableData && response.error === false) {
      setData({
        tableData: response.tableData,
      });
    } else {
      if (response.message) {
        Toast.error(response.message);
      } else {
        Toast.error(Message.Error.COMMON_MESSAGE);
      }
    }
  };

  const callUploadAPI = async (type, attachment) => {
    setPageLoading(true);
    if (type && attachment) {
      let response = await uploadMasterData({
        type,
        attachment,
      });
      setPageLoading(false);
      if (response && response.error === false) {
        loadNames();
        let message = Message.MasterData.UPLOAD_SUCCESS.replace(
          '{{placeholder}}',
          type === MasterDataType.MEDICINE ? 'Medicine' : 'Doctor'
        );
        Toast.success(message);
      } else {
        if (response && response.message) {
          // Toast.error(response.message);
          Toast.error(Message.MasterData.UPLOAD_FAILURE);
        } else {
          Toast.error(Message.Error.COMMON_MESSAGE);
        }
      }
    }
  };

  const menuActionObj = [
    {
      name: 'Upload CSV',
      type: 'upload',
      fileType: '.csv',
      onClick: (e, data) => {
        if (data.attachment && data.type) {
          console.log('data.type', data.type);
          callUploadAPI(data.type, data.attachment);
        }
      },
    },
    {
      name: 'Download',
      onClick: (e, data) => {
        console.log('Delete Clicked: ', data);
        if (data && data.url) {
          window.location.href = data.url;
        }
      },
    },
  ];

  const sectionObj = {
    sectionTitle: 'Master Records',
    searchObj: {
      placeholder: 'Search',
      handleSearch: (e) => {
        console.log('Handle search: ', e);
      },
    },
  };

  if (loading === true) {
    return <Loader />;
  }

  return (
    <div className='blogs'>
      <NewTable
        tableHeader={tableHeader}
        data={data}
        menuAction={menuActionObj}
        sectionObj={sectionObj}
        getData={getMasterData}
        searchKey='master_data_name'
      />
      {pageLoading === true && <PageLoader pageLoading={pageLoading} />}
    </div>
  );
};

export default MasterData;
