import { CloseBlackIcon } from 'constants/image-constants';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import './index.scss';

const WidgetCard = ({
  id,
  index,
  children,
  handleRemoveCard = {},
  isNew = false,
  type = '',
  isCreate = false,
  createFunc = () => {},
  data = [],
  closeSection = false, 
  status='ACTIVE',
  handleResourseToggle={},  
  isEdit=false
}) => {
  const onRemoveCard = () => {
    handleRemoveCard && handleRemoveCard(index);
  };   
  return (
    <div key={index} className='Widget-container'>
      <div className='Widget-container__section-one'>
        <div className='card__header'>
          <p className='Widget-container__section'>{`${type} ${
            index ? index + 1 : ''
          } `}</p>
          {(isNew || closeSection) && (
            <img src={CloseBlackIcon} alt='Close' onClick={onRemoveCard} />
          )}
           {(!isNew && isEdit) && (
            <span>
              <Toggle
                checked={status==='ACTIVE'?true:false}
                icons={false}
                onChange={() => handleResourseToggle()}
              />
            </span>
          )}
          {/* For create button on refrel page Start */}
          {isCreate && (
            <button
              className='button primary-btn btn-sm'
              disabled={data.length >= 5 ? true : false}
              onClick={createFunc}
            >
              Create
            </button>
          )}
          {/* For create button on refrel page End */}
        </div> 
        <div className='Widget-container__Widget'>
          <div className='Widget-container__Image-Container'>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default WidgetCard;
