import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import Loader from 'components/Loader';
import Search from 'app/components/search';
import Button from 'app/components/button';
import PageLoader from 'components/PageLoader';
import Pagination from 'components/NewPagination/pagination';
import CustomTable from './CustomTable';

// API
import { getAbTesting } from 'api/latest/AbTesting/abTesting';

// Constants
import { APP_CONSTANTS } from 'constants/app-constants';

// Utils
import Toast from 'utils/Toast';
import Message from 'utils/Message';

const AbTesting = ({ tableHeader }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  // const [pageLoading, setPageLoading] = useState(false);

  const [values, setValues] = useState('');
  const [data, setData] = useState({
    tableData: [],
  });
  const [totalData, setTotalData] = useState([]);

  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const dataArray = data.tableData;
  const totalPages = Math.ceil(dataArray.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentData = dataArray.slice(startIndex, endIndex);

  useEffect(() => {
    loadAbTesting();
    setValues('');
  }, []);

  const loadAbTesting = async () => {
    setLoading(true);
    let response = await getAbTesting();
    setLoading(false);
    if (response) {
      setData({
        tableData: response.tableData,
      });
      setTotalData({
        tableData: response.tableData,
      });
    } else {
      Toast.error(
        response?.error ? response?.message : Message?.Error?.COMMON_MESSAGE
      );
    }
  };

  const paginationHandler = (page) => {
    setCurrentPage(page);
  };

  const inputChangeHandler = (searchVal) => {
    if (searchVal.trim() === '') {
      setValues(searchVal.toLowerCase());
      setData(totalData);
      return;
    }
    const filteredData = [];
    totalData.tableData.forEach((item) => {
      if (item.module_name.toLowerCase().includes(searchVal.toLowerCase())) {
        filteredData.push(item);
      }
    });
    setData(filteredData);
    setData({
      tableData: filteredData,
    });
    setValues(searchVal);
  };

  if (loading === true) {
    return <Loader />;
  }

  return (
    <>
      <div className='cms-content__container'>
        <h4>A/B Test</h4>
        <div className='content-container__actionBox'>
          <div className='content-container__filterSearch'>
            <Search
              value={values}
              placeholder='Search'
              className={''}
              onChange={inputChangeHandler}
            />
          </div>
          <div>
            <Button
              onClick={() =>
                history.push(APP_CONSTANTS.CMS_VITALS_UPLOAD_AB_TESTING)
              }
              className='content-container__filter-btn'
            >
              Upload CSV
            </Button>
            <Button
              onClick={() =>
                history.push(APP_CONSTANTS.CMS_VITALS_CREATE_AB_TESTING)
              }
              className='content-container__filter-btn ms-1'
            >
              Create A/B Test
            </Button>
          </div>
        </div>

        <div className='mt-3 content-container__table'>
          <CustomTable
            data={currentData}
            tableHeader={tableHeader}
            startIndex={startIndex}
          />
        </div>

        {currentData.length !== 0 && (
          <div className='content-container__pagination'>
            <Pagination pages={totalPages} onChange={paginationHandler} />
          </div>
        )}

        {loading === true && <PageLoader pageLoading={loading} />}
      </div>
    </>
  );
};

export default AbTesting;
