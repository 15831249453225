import { API_CONSTANTS } from 'app/constants/api-contants';
import * as dataAccess from 'utils/ajax';
import Parsers from 'utils/Parsers';
import Toast from 'app/utils/Toast';

export const getRatingList = async () => {
  let responseObj = await dataAccess.get(API_CONSTANTS.GET_RATING_LIST);
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data ? responseObj.data : [];
  } else {
    return [];
  }
};

export const getRatingDetailsById = async (id) => {
  let responseObj = await dataAccess.get(
    `${API_CONSTANTS.GET_RATING_LIST}/${id}`
  );
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data ? responseObj.data : [];
  } else {
    return [];
  }
};

export async function deleteRatingListById(id) {
  let responseObj = await dataAccess.del(
    `${API_CONSTANTS.GET_RATING_LIST}/${id}`
  );
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    Toast.success(responseObj.response.alert[0].message);
  }
}

export const updateRatingStatus = async (incomingData, id) => {
  let payload = {
    data: {
      ...incomingData,
    },
  };
  let url = `${API_CONSTANTS.UPDATE_RATING_STATUS}/${id}`;
  return await dataAccess.put(url, payload);
};

export const getNudgeList = async () => {
  let responseObj = await dataAccess.get(API_CONSTANTS.GET_RATING_NUDGE_LIST);
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data ? responseObj.data : [];
  } else {
    return [];
  }
};

export const updateNudgeList = async (incomingData, id) => {
  let payload = {
    data: {
      ...incomingData,
    },
  };
  let url = `${API_CONSTANTS.GET_RATING_NUDGE_LIST}/${id}`;
  return await dataAccess.put(url, payload);
};

export const getRatingDropdownList = async () => {
  let responseObj = await dataAccess.get(
    API_CONSTANTS.GET_RATING_DROPDOWN_LIST
  );
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data ? responseObj.data : [];
  } else {
    return [];
  }
};

export const createRating = async (incomingData) => {
  let data = {
    ...incomingData,
  };
  let formData = Parsers.ConvertObjectAsFormData(data);
  let payload = {
    data: formData,
  };

  return await dataAccess.upload(API_CONSTANTS.GET_RATING_LIST, payload);
};

export const updateRatingDetails = async (incomingData, id) => {
  let data = {
    ...incomingData,
  };
  let formData = Parsers.ConvertObjectAsFormData(data);
  let payload = {
    data: formData,
  };

  let url = `${API_CONSTANTS.GET_RATING_LIST}/${id}`;
  return await dataAccess.update(url, payload);
};