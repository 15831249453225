import {
  addSubCategory,
  getMedPaySubCategories,
  getSubCategoryById,
  searchMedicine,
  updateSubCategories,
} from 'app/api/latest/Categories/categoriesAPI';
import { APP_CONSTANTS } from 'app/constants/app-constants';
import {
  LabTestListHeader,
  MedicineListHeader,
} from 'app/constants/table-header-constants';
import ValidationMessage from 'app/utils/ValidationMessage';
import { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Message from 'utils/Message';
import Toast from 'utils/Toast';

const useSubCategories = () => {
  const [productKeyword, setProductKeyword] = useState('');
  const [pageLoading, setPageLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState({
    source: '',
    publish: 'INACTIVE',
    heading: '',
    image_url: '',
    medicines: [],
    attachmentFile: null,
  });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [toggled, setToggled] = useState(null);
  const [noProductStatus, setNoProductStatus] = useState(false);
  const [values, setValues] = useState('');
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const dataPerPage = 10;
  const [medpaySubCategoriesList, setMedpaySubCategoriesList] = useState([]);
  const [searchProductList, setSearchProductList] = useState([]);
  const [productsearchtoggle, setProductSearchToggle] = useState(false);
  // const [productDropDowntoggle, setproductDropDowntoggle] = useState(false);
  const [productSearchLoader, setProductSearchLoader] = useState(false);
  const [isMedPaypage, setIsMedPaypage] = useState('s');
  const history = useHistory();
  const location = useLocation();

  const category_id = location?.state?.category_id
    ? location?.state?.category_id
    : '';

  const sub_id = location?.state?.sub_id ? location?.state?.sub_id : '';

  const bannertype = location?.state?.bannertype
    ? location?.state?.bannertype
    : '';

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  let currentData = data.slice(indexOfFirstData, indexOfLastData);

  useEffect(() => {
    loadCategorieslist();
  }, [sub_id]);

  useEffect(() => {
    if (location?.pathname === APP_CONSTANTS.MEDPAYSUBCATEGORIES) {
      getsubcategoriesdatafromMedpay(bannertype);
      setIsMedPaypage('c');
    }
  }, [bannertype, location?.pathname]);

  const getsubcategoriesdatafromMedpay = async () => {
    setPageLoading(true);

    if (!bannertype?.source) {
      return;
    }
    let response = await getMedPaySubCategories(bannertype?.source, 'c');
    setPageLoading(false);
    if (response?.response?.code === 200) {
      let responsedata = response.data;

      let updatedData = [];
      responsedata.forEach((item) => {
        updatedData.push({
          isChecked: false,
          ...item,
        });
      });
      setMedpaySubCategoriesList(updatedData);
    }
    if (response.response.code === 204) {
      Toast.success('No Medpay Sub-Category Available, Please Add Mannauly');
      setMedpaySubCategoriesList([]);
      setIsMedPaypage('s');
    } else {
      Toast.error(
        response.data.error ? response.data.error : Message.Error.COMMON_MESSAGE
      );
    }
  };

  const loadCategorieslist = async () => {
    if (!sub_id) {
      return;
    }
    setPageLoading(true);
    let response = await getSubCategoryById(sub_id);
    setPageLoading(false);
    if (response?.response?.code === 200) {
      let responsedata = response?.data;

      let updatedData = [];

      responsedata?.mapping_product_data &&
        responsedata?.mapping_product_data?.forEach((item) => {
          if (
            responsedata?.mapping_product_data &&
            bannertype?.source === 'MEDICINE' &&
            item?.medicine_id
          ) {
            updatedData.push({
              id: item.id,
              updated_by: item?.updated_by,
              discount: item?.discount,
              ranking: item?.ranking,
              medicine_id: item?.medicine_id,
              medicine_name: item?.medicine_name,
              isNew: false,
              isDisabled: true,
            });
          } else if (
            responsedata?.mapping_product_data &&
            bannertype?.source === 'DIAGNOSTIC' &&
            item?.diagnostic_id
          ) {
            updatedData.push({
              isNew: false,
              isDisabled: true,
              id: item?.id,
              updated_by: item?.updated_by,
              discount: item?.discount,
              ranking: item?.ranking,
              medicine_id: item?.diagnostic_id,
              medicine_name: item?.diagnostic_name,
            });
          }
        });

      setTotalData(updatedData);
      setData(updatedData);
      let count = Math.ceil(updatedData.length / dataPerPage);
      setPageCount(count);
      let newData = {
        display_order: responsedata?.publish,
        publish: responsedata?.publish,
        heading: responsedata?.heading,
        image_url: responsedata?.image_url,
        medicines: updatedData,
        attachmentFile: null,
        medpayCategoryCid: responsedata?.category_id
          ? responsedata?.category_id
          : '',
        category_type: responsedata.category_type
          ? responsedata.category_type
          : '',
      };
      setToggled(responsedata?.publish === 'ACTIVE' ? true : false);
      setSubCategoryData(newData);
      setPageLoading(false);
    } else {
      Toast.error(
        response.data.error ? response.data.error : Message.Error.COMMON_MESSAGE
      );
    }
  };

  const OnhandleChange = (e) => {
    const { name, value } = e.target;
    setSubCategoryData({ ...subCategoryData, [name]: value });
  };

  const onFileSelected = (file) => {
    if (file) {
      setSubCategoryData({ ...subCategoryData, attachmentFile: file });
    }
  };

  function inputChangeHandler(searchVal) {
    if (searchVal.trim() === '') {
      if (totalData) {
        setValues(searchVal.toLowerCase());
        setData(totalData);
      }
      return;
    }
    const filteredData = [];
    totalData.forEach((item) => {
      if (
        (item?.medicine_name &&
          item?.medicine_name
            .toLowerCase()
            .includes(searchVal.toLowerCase())) ||
        (item?.medicine_id && item?.medicine_id.toString().includes(searchVal))
      ) {
        filteredData.push(item);
      }
    });
    setData(filteredData);
    setValues(searchVal);
  }

  const productSearchHandler = async (searchVal) => {
    setProductKeyword(searchVal.toLowerCase());
    if (searchVal.trim() === '') {
      setNoProductStatus(false);
      setSearchProductList([]);

      return;
    }
    let response = {};
    setProductSearchLoader(true);
    if (bannertype?.source === 'DIAGNOSTIC') {
      let postal_code = '400013';
      response = await searchMedicine(
        searchVal,
        bannertype?.source,
        postal_code
      );
    }
    if (bannertype?.source === 'MEDICINE') {
      response = await searchMedicine(searchVal, bannertype?.source);
    }
    setProductSearchLoader(false);
    if (response?.response?.code === 200) {
      if (response?.data?.results?.length === 0) {
        setNoProductStatus(true);
      } else {
        setNoProductStatus(false);
        let searcharray = response?.data.results.slice(0, 10);
        let tempData = [];
        searcharray.forEach((searchItem, searchindex) => {
          searchItem['alreadyStatus'] = false;
          data.forEach((dataItem, dataindex) => {
            if (searchItem.id === dataItem.id) {
              searchItem['alreadyStatus'] = true;
              return;
            }
          });
          tempData.push(searchItem);
        });

        setSearchProductList(tempData);
      }
    } else {
      Toast.error(
        response.data.error ? response.data.error : Message.Error.COMMON_MESSAGE
      );
    }
  };

  function paginationHandler(page) {
    setCurrentPage(page);
  }

  const handleInputChangeHandler = (key, e, indexnew) => {
    let index = (currentPage - 1) * 10 + indexnew;
    let tempData = [];
    data.forEach((val, i) => {
      if (index === i) {
        let temp = val;
        val[key] = Number(e.target.value) ? Number(e.target.value) : '';
        tempData.push(temp);
      } else {
        tempData.push(val);
      }
    });
    setData(tempData);
    setTotalData(tempData);
  };

  const handleEdit = async (item, key, indexnew) => {
    let index = (currentPage - 1) * 10 + indexnew;
    if (key === 'Edit') {
      let tempData = [];
      data.forEach((val, i) => {
        if (index === i) {
          let temp = val;
          val['isDisabled'] = false;
          tempData.push(temp);
        } else {
          tempData.push(val);
        }
      });
      setData(tempData);
      setTotalData(tempData);
    } else {
      let tempData = [];
      data.forEach((val, i) => {
        if (index === i) {
          let temp = val;
          val['isDisabled'] = true;
          tempData.push(temp);
        } else {
          tempData.push(val);
        }
      });
      setData(tempData);
      setTotalData(tempData);
    }
  };

  const handleDelete = async (key, indexnew) => {
    let index = (currentPage - 1) * 10 + indexnew;
    let tempData = [];
    await data.forEach((val, i) => {
      if (index === i) {
        return;
      }
      if (val.isNew === true) {
        let temp = val;
        val[key] = val.ranking - 1;
        tempData.push(temp);
      } else {
        tempData.push(val);
      }
    });

    setData(tempData);
    setTotalData(tempData);
  };

  const addProuctSearchBar = async (data) => {
    setProductKeyword('');
    setNoProductStatus(false);
    setProductSearchToggle(!productsearchtoggle);
    setSearchProductList([]);
  };
  const productAddinList = async (item) => {
    let temp = [...data];

    const newdata = {
      id: item.id,
      ranking: temp.length + 1,
      medicine_name: item.medicine_name ? item.medicine_name : item.name,
      medicine_id: item.sku_id ? item?.sku_id : item?.test_id,
      discount: item?.discount ? item?.discount : 0,
      updated_by: item?.updated_by ? item?.updated_by : '',
      isDisabled: true,
      isNew: true,
    };

    temp.push(newdata);
    setProductKeyword('');
    setData(temp);
    setTotalData(temp);
    setSearchProductList([]);
    setProductSearchToggle(false);
  };

  const subCategoryAllData = async () => {
    let addimageCheck = false;
    let addheadingCheck = false;
    if (sub_id) {
      if (subCategoryData.image_url === '') {
        Toast.error(`${ValidationMessage.ATTACH} for Sub-Category`);
        addimageCheck = true;
        return;
      }
    } else {
      if (subCategoryData.attachmentFile === null) {
        Toast.error(`${ValidationMessage.ATTACH} for Sub-Category`);
        addimageCheck = true;
        return;
      }
    }

    if (subCategoryData?.heading === '') {
      Toast.error(`${ValidationMessage.TEXT_TITLE} for Sub-Categories `);
      addheadingCheck = true;
      return;
    }
    if (addimageCheck || addheadingCheck) {
      setPageLoading(false);
      return;
    }

    const unique = [...new Set(data.map((item) => item.ranking))];
    if (unique && data && unique.length < data.length) {
      Toast.error('Ranking Order must be Unique');
      return;
    }

    let formData = new FormData();
    formData.append(
      'sub_category_heading',
      subCategoryData.heading ? subCategoryData.heading : ''
    );
    formData.append(
      'display_order',
      subCategoryData?.display_order ? subCategoryData?.display_order : 0
    );
    formData.append('source', bannertype?.source ? bannertype?.source : '');
    formData.append('page', bannertype?.source ? bannertype?.source : '');
    formData.append(
      'product_type',
      bannertype?.source ? bannertype?.source : ''
    );
    formData.append(
      'sub_category_image',
      subCategoryData?.attachmentFile
        ? subCategoryData?.attachmentFile
        : subCategoryData?.image_url
    );

    formData.append('category_id', category_id);

    if (subCategoryData?.medpayCategoryCid !== '') {
      formData.append(
        'medpay_category_id',
        subCategoryData?.medpayCategoryCid
          ? subCategoryData?.medpayCategoryCid
          : ''
      );
    }

    if (subCategoryData?.category_type) {
      formData.append('category_type', subCategoryData?.category_type);
    } else {
      formData.append('category_type', isMedPaypage);
    }

    if (bannertype.source === 'DIAGNOSTIC') {
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        formData.append('diagnostic_id[]', element.id);
        formData.append('mapping_display_order[]', element.ranking);
      }
    } else {
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        formData.append('medicine_id[]', element.id);
        formData.append('mapping_display_order[]', element.ranking);
      }
    }

    setPageLoading(true);
    let response = {};
    if (sub_id) {
      formData.append('publish', toggled ? 'ACTIVE' : 'INACTIVE');
      response = await updateSubCategories(formData, sub_id);
    } else {
      response = await addSubCategory(formData);
    }
    setPageLoading(false);
    if (response?.response?.code === 201) {
      Toast.success(`Sub-Category updated successfully`);

      history.push({
        pathname: bannertype.linkName,
      });
    } else {
      Toast.error(
        response?.data?.error
          ? response?.data?.error
          : Message.Error.COMMON_MESSAGE
      );
    }
  };

  const productNameAndImage = async (item, index) => {
    setNoProductStatus(false);
    setSubCategoryData({
      ...subCategoryData,
      heading: item.heading,
      medpayCategoryCid: item.category_id,
      medpayCategorydMid: item.id,
    });

    let tempMedpaySubCategoriesList = [...medpaySubCategoriesList];

    let tempData = [];
    await tempMedpaySubCategoriesList.forEach((val, i) => {
      if (index === i) {
        let temp = val;
        if (val.isChecked) {
          val['isChecked'] = false;
          tempData.push(temp);
        } else {
          val['isChecked'] = true;
          tempData.push(temp);
        }
      } else {
        val['isChecked'] = false;
        tempData.push(val);
      }
    });

    setMedpaySubCategoriesList(tempData);
  };

  const openUp = () => {
    // if (data.length > 5) {
    //   Toast.error(`Sub-Category must be in Complete State for publish`);
    //   return;
    // }

    confirmAlert({
      title: '',
      message: toggled
        ? 'Are you sure you want to Unpublish Sub-Categories?'
        : 'Are you sure you want to Publish  Sub-Categories?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => setToggled(!toggled),
        },
        {
          label: 'No',
        },
      ],
    });
  };

  // ------------------------------------------------

  return {
    pageLoading,
    onFileSelected,
    OnhandleChange,
    noProductStatus,
    listData,
    setListData,
    handleClose,
    handleShow,
    show,
    showFilter,
    setShowFilter,
    currentData,
    handleInputChangeHandler,
    handleEdit,
    handleDelete,
    paginationHandler,
    pageCount,
    subCategoryData,
    inputChangeHandler,
    addProuctSearchBar,
    productsearchtoggle,
    productKeyword,
    searchProductList,
    productSearchHandler,
    productAddinList,
    values,
    subCategoryAllData,
    medpaySubCategoriesList,
    productNameAndImage,
    openUp,
    toggled,
    bannertype,
    productSearchLoader,
    LabTestListHeader,
    MedicineListHeader,
  };
};

export default useSubCategories;
