import CreateGamification from 'container/CreateGamification';
import BasePage from 'pages/BasePage';

const GamificationCreatePage = () => {
  return (
    <BasePage>
      <CreateGamification />
    </BasePage>
  );
};

export default GamificationCreatePage;
