import { API_CONSTANTS } from 'app/constants/api-contants';
import * as dataAccess from 'utils/ajax';
import Parsers from 'utils/Parsers';
import Toast from 'app/utils/Toast';

export const getTipOftheDayList = async () => {
     let responseObj = await dataAccess.get(API_CONSTANTS.GET_TIP_OF_THE_DAY_LIST);
     if (
          responseObj &&
          responseObj.response &&
          responseObj.response.code === 200
     ) {
          return responseObj.data;
     } else {
          return [];
     }
};

export const createTipOfTheDay= async (formData) => {
     let payload = {
         data: formData
     }
     return await dataAccess.post(API_CONSTANTS.CREATE_TIP_OF_THE_DAY_LIST, payload)
 }

 export const updateTipOfTheDay= async (formData) => {
     let payload = {
         data: formData
     }
     return await dataAccess.put(API_CONSTANTS.UPDATE_TIP_OF_THE_DAY_LIST, payload)
 }

 export async function deleteTipById(id) {
     let payload = {
          data: {
            id
          },
        };
     let responseObj = await dataAccess.del(API_CONSTANTS.DELETE_TIP_OF_THE_DAY_LIST, payload);
     if (
          responseObj &&
          responseObj.response &&
          responseObj.response.code === 200
     ) {
          Toast.success(responseObj.response.alert[0].message)
     }
 }