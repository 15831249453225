import React from 'react';

// Pages
import BasePage from 'pages/BasePage';

import MedPaySubCategories from 'container/LatestCategories/MedPaySubCategories';

const MedpaySubCategoriesPage = () => {
  return (
    <BasePage>
      <MedPaySubCategories />
    </BasePage>
  );
};

export default MedpaySubCategoriesPage;
