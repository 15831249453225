import { MenuIcon } from 'constants/image-constants';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import './index.scss';
import moment from 'moment';
const CustomDragabbleTable = ({
  data,
  draggableTableHeader,
  onSelectedItemsChange,
  //handleDragEnd,
}) => {
  const handleDragEnd = (result) => {
    if (!result.destination) {
      return; // No valid drop destination, do nothing
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    // Create a new array with the item moved to the new position
    const updatedData = Array.from(data);
    const [movedItem] = updatedData.splice(sourceIndex, 1);
    updatedData.splice(destinationIndex, 0, movedItem);
    onSelectedItemsChange(updatedData);
  };

  return (
    <div className='mt-4'>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId='droppable' direction='vertical'>
          {(provided) => (
            <table
              ref={provided.innerRef}
              {...provided.droppableProps}
              className='dashed-border'
            >
              <thead>
                {draggableTableHeader.map((item, i) => (
                  <th key={i} className='nowrap text-center'>
                    {item.Header}
                  </th>
                ))}
              </thead>
              {
                <tbody>
                  {data &&
                    data.map((items, idx) => (
                      <Draggable
                        key={items.id.toString()}
                        draggableId={items.id.toString()}
                        index={idx}
                        isDragDisabled={items?.sorting_order >=1000}
                      >
                        {(provided) => (
                          <tr
                            key={items.id.toString()} // Use item's ID as the key here
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {/* Render table row content as before */}
                            <td>
                              <img
                                src={MenuIcon}
                                alt='Drag'
                                width='10'
                                height='10'
                              />
                            </td>
                            <td>{items?.title ? items?.title : '-'}</td>
                            <td>{items.last_edited_time ? moment(items.last_edited_time).format('DD/MM/YYYY') : '-'} </td>
                            <td>{items?.last_updated_by ? items?.last_updated_by : '-'} </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </tbody>
              }
            </table>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default CustomDragabbleTable;
