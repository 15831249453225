// Pages
import { DiagonosticOrderHeader } from 'constants/table-header-constants';
import DiagonosticLandingPageOrdering from 'container/Diagnostic/DiagonosticLandingPageOrdering';
import BasePage from 'pages/BasePage';

const DiagnosticLandingPageOrder = () => {
  return (
    <BasePage>
      <DiagonosticLandingPageOrdering tableHeader={DiagonosticOrderHeader} />
    </BasePage>
  );
};

export default DiagnosticLandingPageOrder;
