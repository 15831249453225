import * as dataAccess from 'utils/ajax';
import ApiParser from 'utils/ApiParser';
import { API_CONSTANTS } from 'constants/api-contants';
import { ServiceType } from 'constants/app-constants';

export const getActivities = async () => {
  let urls = [
    `${API_CONSTANTS.GET_ACTIVITIES}`,
    `${API_CONSTANTS.GET_CONTENT_ACTIVITIES}`,
  ];

  let responseObj = await dataAccess.getAll(urls);
  return ApiParser.parseActivitiesListing(responseObj);
};

export const updateActivity = async (incomingData) => {
  let payload = {
    data: {
      ...incomingData,
    },
  };

  let url = '';
  if (incomingData.service_type) {
    if (incomingData.service_type.toUpperCase() === ServiceType.PATIENT_DAIRY) {
      url = API_CONSTANTS.UPDATE_ACTIVITIES;
    } else if (
      incomingData.service_type.toUpperCase() === ServiceType.CONTENT
    ) {
      url = API_CONSTANTS.UPDATE_CONTENT_ACTIVITIES;
    }
  }

  if (!url) {
    return {};
  }

  return await dataAccess.put(url, payload);
};
