export const AppLogoImage = `${process.env.PUBLIC_URL}/images/AppLogo.svg`;
export const LogoutImage = `${process.env.PUBLIC_URL}/images/Logout.png`;
export const ArrowUpImage = `${process.env.PUBLIC_URL}/images/ArrowUp.svg`;
export const ArrowDownImage = `${process.env.PUBLIC_URL}/images/ArrowDown.svg`;
export const CloseBlackIcon = `${process.env.PUBLIC_URL}/images/CloseIconBlack.svg`;
export const FavIcon = `${process.env.PUBLIC_URL}/favicon.ico`;
export const PlusBlackIcon = `${process.env.PUBLIC_URL}/images/Plus.png`;
export const AttachmentIcon = `${process.env.PUBLIC_URL}/images/Attachment.png`;
export const MenuIcon = `${process.env.PUBLIC_URL}/images/menu.png`;
export const CrossCircle = `${process.env.PUBLIC_URL}/images/crosscircle.svg`;

export const VisiblEye = `${process.env.PUBLIC_URL}/images/visibleeye.svg`;
export const Trash = `${process.env.PUBLIC_URL}/images/trash.svg`;

export const Plus = `${process.env.PUBLIC_URL}/images/plus.png`;
export const file = `${process.env.PUBLIC_URL}/images/file.png`;
