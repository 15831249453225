import React from 'react';
import BasePage from 'pages/BasePage';
import CreateHomePage from 'app/container/CreateHomePage';

const HomePageEdit = () => {
  return (
    <BasePage>
      <CreateHomePage />
    </BasePage>
  );
};

export default HomePageEdit;
