import * as dataAccess from "utils/ajax";
import ApiParser from "utils/ApiParser";
import Parsers from "utils/Parsers";
import Toast from "app/utils/Toast";

import { API_CONSTANTS } from "constants/api-contants";

export const getHomePageSectionList = async () => {
  let responseObj = await dataAccess.get(
    API_CONSTANTS.GET_HOME_PAGE_SECTION_LIST
  );
  return responseObj;
};

export const createHomePageSection = async (formData) => {
  let payload = {
    data: formData,
  };
  return await dataAccess.post(API_CONSTANTS.CREATE_HOME_PAGE_SECTION, payload);
};
export const updateHomePageSection = async (formData) => {
  let payload = {
    data: formData,
  };
  return await dataAccess.put(API_CONSTANTS.CREATE_HOME_PAGE_SECTION, payload);
};

export async function deleteHomePageSection(id) {
  let responseObj = await dataAccess.del(
    `${API_CONSTANTS.CREATE_HOME_PAGE_SECTION}/${id}`
  );
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    Toast.success(responseObj.response.alert[0].message);
  }
}

export const getLocalisationDetails = async (id, lang) => {
  let responseObj = await dataAccess.get(
    `${API_CONSTANTS.GET_HOME_PAGE_SECTION_DETAILS}/${id}?lang=${lang}`
  );
  return responseObj;
};

export const updateHomePageSectionLocalisation = async (formData, lang) => {
  let payload = {
    data: formData,
  };
  return await dataAccess.put(
    `${API_CONSTANTS.GET_HOME_PAGE_SECTION_DETAILS}/${formData?.id}?lang=${lang}`,
    payload
  );
};
