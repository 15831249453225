import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Components

// API
import {
  addAbTesting,
  getAbTestingDetails,
  getSelectedProductOptions,
  getServices,
  getVersionControl,
  updateAbTesting,
} from 'api/latest/AbTesting/abTesting';

// Constants
import {
  ageRangeOptions,
  cohortOptions,
  filteredOptionsData,
  finalSourceOptions,
  genderOptions,
  locationOptions,
  moduleOptions,
  variantOptions,
} from 'JsonData/ab-testing-dropdowns-list';

// Utils
import Message from 'app/utils/Message';
import Toast from 'utils/Toast';
import ValidationMessage from 'utils/ValidationMessage';

const useAbTesting = () => {
  const history = useHistory();
  const params = useParams();
  const [pageLoading, setPageLoading] = useState(false);
  const [toggleStatus, setToggleStatus] = useState(false);
  const [appVersionOptions, setAppVersionOptions] = useState([]);
  // Module
  const [moduleSelectedOption, setModuleSelectedOption] = useState(null);
  // Product Catalog
  const [productCatalogSelectedOption, setProductCatalogSelectedOption] =
    useState(null);
  // Services
  const [servicesOptions, setServicesOptions] = useState([]);
  const [servicesSelectedOption, setServicesSelectedOption] = useState('');
  // Variant Selection
  const [variantSelectedOption, setVariantSelectedOption] = useState(null);
  // Variant Input & Dropdown Based on Selected Product Catalog like Family Plan, Coupons & Offers and Recommendations
  const [selectedProductBaseOptions, setSelectedProductBaseOptions] = useState(
    []
  );
  const [variantAPercent, setVariantAPercent] = useState(0);
  const [variantBPercent, setVariantBPercent] = useState(0);
  const [variantCPercent, setVariantCPercent] = useState(0);
  const [optionASelectedOption, setoptionASelectedOption] = useState('');
  const [optionBSelectedOption, setoptionBSelectedOption] = useState('');
  const [optionCSelectedOption, setoptionCSelectedOption] = useState('');
  // To Select User Type Random or Selective
  const [usersSelectedOption, setUsersSelectedOption] = useState(null);
  // Add Filters
  const [addFiltersSelectedOptions, setAddFiltersSelectedOptions] = useState(
    []
  );
  const [varIdPlanA, setVarIdPlanA] = useState('');
  const [varIdPlanB, setVarIdPlanB] = useState('');
  const [varIdPlanC, setVarIdPlanC] = useState('');
  const [variantAOptions, setVariantAOptions] = useState([]);
  const [variantBOptions, setVariantBOptions] = useState([]);
  const [variantCOptions, setVariantCOptions] = useState([]);
  const [selectedDropdownAValues, setSelectedDropdownAValues] = useState({});
  const [selectedDropdownBValues, setSelectedDropdownBValues] = useState({});
  const [selectedDropdownCValues, setSelectedDropdownCValues] = useState({});

  const [blankOnRemoveArray, setBlankOnRemoveArray] = useState([]);

  const toTitleCase = (str) => {
    return str
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const versionList = async () => {
    const response = await getVersionControl();
    let res = response?.data?.available_versions;
    const newRes = Object.keys(res)?.map((key) => ({
      value: res[key],
      label: res[key].toString(), // Convert the value to a string for the label
    }));
    setAppVersionOptions(newRes);
  };

  const servicesOptionsData = async () => {
    const response = await getServices();
    setServicesOptions(response?.data);
  };

  useEffect(() => {
    versionList();
    servicesOptionsData();
  }, []);

  useEffect(() => {
    if (usersSelectedOption === 'Random') {
      setAddFiltersSelectedOptions([]);
      setSelectedDropdownAValues({});
      setSelectedDropdownBValues({});
      setSelectedDropdownCValues({});
    }
  }, [usersSelectedOption]);

  const getAbTestDetailsById = async (id) => {
    setPageLoading(true);
    let responseObj = await getAbTestingDetails(id);
    let resServiceId = responseObj?.ab_testing_data?.service_id;
    const selectedServices = servicesOptions?.find(
      (option) => option.id === resServiceId
    );
    const selectedServicesName = selectedServices?.name;
    setServicesSelectedOption(
      selectedServicesName ? selectedServicesName : null
    );
    setUsersSelectedOption(responseObj?.ab_testing_data?.user_type);
    setModuleSelectedOption(responseObj?.ab_testing_data?.module_name);
    setProductCatalogSelectedOption(
      (responseObj?.ab_testing_data?.product_catelog === 'Family Plans' &&
        'FAMILY_PLANS') ||
        (responseObj?.ab_testing_data?.product_catelog === 'Coupons & Offers' &&
          'COUPONS_AND_OFFERS') ||
        (responseObj?.ab_testing_data?.product_catelog === 'Recommendations' &&
          'RECOMMENDATIONS')
    );
    setVariantSelectedOption(responseObj.ab_testing_data.variant);

    // For Variant Plan Result
    const objA = responseObj?.variant_plan_result?.find(
      (obj) => obj.variant_type === 'A'
    );
    const objB = responseObj?.variant_plan_result?.find(
      (obj) => obj.variant_type === 'B'
    );
    const objC = responseObj?.variant_plan_result?.find(
      (obj) => obj.variant_type === 'C'
    );

    setVarIdPlanA(objA?.id);
    setVarIdPlanB(objB?.id);
    setVarIdPlanC(objC?.id);
    setVariantAPercent(objA.variant_value);
    setVariantBPercent(objB?.variant_value);
    setVariantCPercent(objC?.variant_value ? objC?.variant_value : null);
    setoptionASelectedOption(objA?.product_catelog_type);
    setoptionBSelectedOption(objB?.product_catelog_type);
    setoptionCSelectedOption(
      objC?.product_catelog_type ? objC?.product_catelog_type : null
    );
    if (
      usersSelectedOption === 'Selective' ||
      filteredOptionsData?.length > 0
    ) {
      let res = responseObj?.ab_testing_data?.filter_data;
      const preSelectedFilterOptions = filteredOptionsData
        .filter((option) => res.includes(option.label))
        .map((option) => ({
          value: option.value,
          label: option.label,
        }));
      setAddFiltersSelectedOptions(preSelectedFilterOptions);
      // For Variant Result

      const objVarA = responseObj?.variant_result?.find(
        (obj) => obj.variant_type === 'A'
      );
      const objVarB = responseObj?.variant_result?.find(
        (obj) => obj.variant_type === 'B'
      );
      const objVarC = responseObj?.variant_result?.find(
        (obj) => obj.variant_type === 'C'
      );

      const newSelectedAValues = { id: objVarA?.id };

      for (const prop in objVarA) {
        if (Array.isArray(objVarA[prop]) && objVarA[prop].length > 0) {
          newSelectedAValues[prop] = objVarA[prop].map((value) => ({
            label: value,
            value: value,
          }));
        }
      }

      setSelectedDropdownAValues(newSelectedAValues);

      const newSelectedBValues = { id: objVarB?.id };
      for (const prop in objVarB) {
        if (Array.isArray(objVarB[prop]) && objVarB[prop].length > 0) {
          newSelectedBValues[prop] = objVarB[prop].map((value) => ({
            label: value,
            value: value,
          }));
        }
      }
      setSelectedDropdownBValues(newSelectedBValues);
      if (Number(variantSelectedOption) === 3) {
        const newSelectedCValues = { id: objVarC?.id };
        for (const prop in objVarC) {
          if (Array.isArray(objVarC[prop]) && objVarC[prop].length > 0) {
            newSelectedCValues[prop] = objVarC[prop].map((value) => ({
              label: value,
              value: value,
            }));
          }
        }
        setSelectedDropdownCValues(newSelectedCValues);
      }
    }

    setToggleStatus(
      responseObj.ab_testing_data.status === 'ACTIVE' ? true : false
    );
    setPageLoading(false);
  };

  const productCatalogBasedOptionsData = async () => {
    const response = await getSelectedProductOptions(
      productCatalogSelectedOption
    );
    setSelectedProductBaseOptions(response.data);
  };

  useEffect(() => {
    if (productCatalogSelectedOption) {
      productCatalogBasedOptionsData();
    }
    if (params.id) {
      getAbTestDetailsById(params.id);
    }
  }, [productCatalogSelectedOption, params.id]);

  const handleToggleChange = () => {
    setToggleStatus(!toggleStatus);
  };

  const addNewAbTest = async () => {
    if (moduleSelectedOption === null) {
      Toast.error(ValidationMessage.MODULE);
      setPageLoading(false);
      return;
    }
    if (productCatalogSelectedOption === null) {
      Toast.error(ValidationMessage.PRODUCT_CATALOG);
      setPageLoading(false);
      return;
    }

    if (servicesSelectedOption === '') {
      Toast.error(ValidationMessage.SERVICE);
      setPageLoading(false);
      return;
    }
    if (variantSelectedOption === null) {
      Toast.error(ValidationMessage.VARIANT);
      setPageLoading(false);

      return;
    }
    if (variantAPercent === 0) {
      Toast.error(ValidationMessage.VARIANT_A);
      setPageLoading(false);
      return;
    }
    if (variantBPercent === 0) {
      Toast.error(ValidationMessage.VARIANT_B);
      setPageLoading(false);
      return;
    }

    if (Number(variantSelectedOption) === 3 && variantCPercent === 0) {
      Toast.error(ValidationMessage.VARIANT_C);
      setPageLoading(false);
      return;
    }

    if (optionASelectedOption === '') {
      Toast.error(ValidationMessage.OPTION_A);
      setPageLoading(false);
      return;
    }
    if (optionBSelectedOption === '') {
      Toast.error(ValidationMessage.OPTION_B);
      setPageLoading(false);
      return;
    }

    if (variantSelectedOption === 3 && !optionCSelectedOption) {
      Toast.error(ValidationMessage.OPTION_C);
      setPageLoading(false);
      return;
    }
    if (usersSelectedOption === null) {
      Toast.error(ValidationMessage.USER_TYPE);
      setPageLoading(false);
      return;
    }
    if (
      usersSelectedOption === 'Selective' &&
      addFiltersSelectedOptions?.length === 0
    ) {
      Toast.error(ValidationMessage.ADD_FILTERS);
      setPageLoading(false);
      return;
    }

    //---------------------validationre replaced by above values start-------------

    if (
      usersSelectedOption === 'Selective' &&
      addFiltersSelectedOptions?.length > 0 &&
      (variantAPercent === 0 || !variantAPercent)
    ) {
      Toast.error(ValidationMessage.VARIANT_SELECTIVE_VALUE_A);
      setPageLoading(false);
      return;
    }
    if (
      usersSelectedOption === 'Selective' &&
      addFiltersSelectedOptions.length > 0 &&
      (variantBPercent === 0 || !variantBPercent)
    ) {
      Toast.error(ValidationMessage.VARIANT_SELECTIVE_VALUE_B);
      setPageLoading(false);
      return;
    }
    if (
      Number(variantSelectedOption) === 3 &&
      usersSelectedOption === 'Selective' &&
      addFiltersSelectedOptions.length > 0 &&
      (variantCPercent === 0 || !variantCPercent)
    ) {
      Toast.error(ValidationMessage.VARIANT_SELECTIVE_VALUE_C);
      setPageLoading(false);
      return;
    }

    //---------------------validationre replaced by above values end-------------

    if (
      usersSelectedOption === 'Selective' &&
      addFiltersSelectedOptions.length > 0 &&
      Object.entries(selectedDropdownAValues).length === 1
    ) {
      Toast.error(ValidationMessage.DROPDOWN_VALUE);
      setPageLoading(false);
      return;
    }

    if (
      usersSelectedOption === 'Selective' &&
      addFiltersSelectedOptions.length > 0 &&
      Object.entries(selectedDropdownBValues).length === 1
    ) {
      Toast.error(ValidationMessage.DROPDOWN_VALUE);
      setPageLoading(false);
      return;
    }
    if (
      Number(variantSelectedOption) === 3 &&
      usersSelectedOption === 'Selective' &&
      addFiltersSelectedOptions.length > 0 &&
      Object.entries(selectedDropdownCValues).length === 1
    ) {
      Toast.error(ValidationMessage.DROPDOWN_VALUE);
      setPageLoading(false);
      return;
    }

    const selectedModule = moduleOptions.find(
      (option) => option.name === moduleSelectedOption
    );
    const selectedModuleName = selectedModule?.name;

    const selectedServices = servicesOptions.find(
      (option) => option.name === servicesSelectedOption
    );
    const selectedServicesId = selectedServices?.id;

    const selectedVariant = variantOptions.find(
      (option) => Number(option.value) === Number(variantSelectedOption)
    );
    const selectedVariantId = Number(selectedVariant.value);

    const selectedFilterIds = addFiltersSelectedOptions.map(
      (option) => option.label
    );

    let varPlanAObj = {
      id: varIdPlanA,
      variant_plan_type: 'A',
      variant_plan_value: Number(variantAPercent),
      product_catelog_type: optionASelectedOption,
    };
    let varPlanBObj = {
      id: varIdPlanB,
      variant_plan_type: 'B',
      variant_plan_value: Number(variantBPercent),
      product_catelog_type: optionBSelectedOption,
    };
    let varPlanCObj = {
      id: varIdPlanC,
      variant_plan_type: 'C',
      variant_plan_value: Number(variantCPercent),
      product_catelog_type: optionCSelectedOption,
    };

    // For Filter A
    let varAObj = {
      type: 'A',
      value: Number(variantAPercent),
    };
    let varBObj = {
      type: 'B',
      value: Number(variantBPercent),
    };
    let varCObj = {
      type: 'C',
      value: Number(variantCPercent),
    };

    if (selectedDropdownAValues['app_versionA']) {
      varAObj.app_version = selectedDropdownAValues['app_versionA'];
    }
    if (selectedDropdownAValues['cohort_nameA']) {
      varAObj.cohort_name = selectedDropdownAValues['cohort_nameA'];
    }
    if (selectedDropdownAValues['age_rangeA']) {
      varAObj.age_range = selectedDropdownAValues['age_rangeA'];
    }
    if (selectedDropdownAValues['final_sourceA']) {
      varAObj.final_source = selectedDropdownAValues['final_sourceA'];
    }
    if (selectedDropdownAValues['genderA']) {
      varAObj.gender = selectedDropdownAValues['genderA'];
    }
    if (selectedDropdownAValues['locationA']) {
      varAObj.location = selectedDropdownAValues['locationA'];
    }
    varAObj.id = selectedDropdownAValues.id;

    // For Filter B
    if (selectedDropdownBValues['app_versionB']) {
      varBObj.app_version = selectedDropdownBValues['app_versionB'];
    }
    if (selectedDropdownBValues['cohort_nameB']) {
      varBObj.cohort_name = selectedDropdownBValues['cohort_nameB'];
    }
    if (selectedDropdownBValues['age_rangeB']) {
      varBObj.age_range = selectedDropdownBValues['age_rangeB'];
    }
    if (selectedDropdownBValues['final_sourceB']) {
      varBObj.final_source = selectedDropdownBValues['final_sourceB'];
    }
    if (selectedDropdownBValues['genderB']) {
      varBObj.gender = selectedDropdownBValues['genderB'];
    }
    if (selectedDropdownBValues['locationB']) {
      varBObj.location = selectedDropdownBValues['locationB'];
    }
    varBObj.id = selectedDropdownBValues.id;

    // For Filter C
    if (selectedDropdownCValues['app_versionC']) {
      varCObj.app_version = selectedDropdownCValues['app_versionC'];
    }
    if (selectedDropdownCValues['cohort_nameC']) {
      varCObj.cohort_name = selectedDropdownCValues['cohort_nameC'];
    }
    if (selectedDropdownCValues['age_rangeC']) {
      varCObj.age_range = selectedDropdownCValues['age_rangeC'];
    }
    if (selectedDropdownCValues['final_sourceC']) {
      varCObj.final_source = selectedDropdownCValues['final_sourceC'];
    }
    if (selectedDropdownCValues['genderC']) {
      varCObj.gender = selectedDropdownCValues['genderC'];
    }
    if (selectedDropdownCValues['locationC']) {
      varCObj.location = selectedDropdownCValues['locationC'];
    }
    varCObj.id = selectedDropdownCValues.id;

    for (let index = 0; index < blankOnRemoveArray.length; index++) {
      const element = blankOnRemoveArray[index];
      if (element) {
        varAObj[element] = [];
        varBObj[element] = [];
        varCObj[element] = [];
      }
    }
    let tempArray = [];
    if (Number(variantSelectedOption) === 2) {
      tempArray.push(varPlanAObj, varPlanBObj);
    }
    if (Number(variantSelectedOption) === 3) {
      tempArray.push(varPlanAObj, varPlanBObj, varPlanCObj);
    }
    const totalVariantPlanValue = tempArray.reduce(
      (sum, obj) => sum + obj.variant_plan_value,
      0
    );

    let commonData = {
      module_name: selectedModuleName,
      service_id: selectedServicesId,
      product_catelog:
        (productCatalogSelectedOption === 'FAMILY_PLANS' && 'Family Plans') ||
        (productCatalogSelectedOption === 'COUPONS_AND_OFFERS' &&
          'Coupons & Offers') ||
        (productCatalogSelectedOption === 'RECOMMENDATIONS' &&
          'Recommendations'),
      varient_plan_data: tempArray,
      variant: Number(selectedVariantId),
      user_type: usersSelectedOption,
      status: toggleStatus ? 'ACTIVE' : 'INACTIVE',
    };
    let payload;
    if (usersSelectedOption === 'Selective') {
      let temparr = [];
      if (Number(variantSelectedOption) === 2) {
        temparr.push(varAObj, varBObj);
      }
      if (Number(variantSelectedOption) === 3) {
        temparr.push(varAObj, varBObj, varCObj);
      }

      payload = {
        ...commonData,
        filter_data: selectedFilterIds,
        variant_data: temparr,
      };
    } else {
      payload = {
        ...commonData,
      };
    }

    const totalValue =
      payload?.variant_data?.reduce((sum, obj) => sum + obj?.value, 0) || null;

    if (totalVariantPlanValue !== 100) {
      Toast.error(ValidationMessage.VARIANT_SELECTIVE_VALUE);
      setPageLoading(false);
      return;
    }

    if (usersSelectedOption === 'Selective' && totalValue !== 100) {
      Toast.error(ValidationMessage.VARIANT_SELECTIVE_VALUE);
      setPageLoading(false);
      return;
    }

    setPageLoading(true);
    let responseObj;
    if (params?.id) {
      const responseObj = await updateAbTesting(payload, params?.id);
      setPageLoading(false);
      if (responseObj.response.code === 200) {
        Toast.success('The A/B Test has been updated successfully');
        history.goBack();
      }

      if (usersSelectedOption === 'Selective') {
        const hasBlankArrayProperty = (objects) => {
          for (const obj of objects) {
            for (const key in obj) {
              if (
                payload?.filter_data?.includes(key) &&
                Array.isArray(obj[key]) &&
                obj[key].length === 0
              ) {
                return true; // Found a blank array property, return true
              }
            }
          }
          return false; // No blank array properties found in any object
        };

        const hasBlank = hasBlankArrayProperty(payload?.variant_data);
        if (hasBlank) {
          Toast.error(ValidationMessage.DROPDOWN_VALUE);
          setPageLoading(false);
          return;
        }
      }
    } else {
      // Check if the sum is not more than 100

      responseObj = await addAbTesting(payload);
      setPageLoading(false);
      if (responseObj.response.code === 201) {
        Toast.success('The A/B Test has been created successfully');
        history.goBack();
      } else {
        Toast.error(
          responseObj?.data ? responseObj?.data : Message.Error.COMMON_MESSAGE
        );
      }
    }

    // return responseObj;
  };

  const handleCreateAbTest = async (e) => {
    e.preventDefault();
    addNewAbTest();
  };

  const handleModuleSelectChange = (e) => {
    e.preventDefault();
    setModuleSelectedOption(e.target.value);
  };

  const handleProductCatalogSelectChange = (e) => {
    e.preventDefault();
    setProductCatalogSelectedOption(e.target.value);
  };

  const handleServicesSelectChange = (e) => {
    e.preventDefault();
    setServicesSelectedOption(e.target.value);
  };

  const handleVariantSelectChange = (e) => {
    e.preventDefault();
    setVariantAPercent(0);
    setVariantBPercent(0);
    setVariantCPercent(0);

    setVariantSelectedOption(e.target.value);
  };

  const handleUsersSelectChange = (e) => {
    e.preventDefault();
    setUsersSelectedOption(e.target.value);
  };

  const handleInputVariantAPercentChange = (e) => {
    e.preventDefault();
    const value = parseInt(e.target.value);
    setVariantAPercent(value);
    // Ensure the input value is a number
    if (!isNaN(value)) {
      const remainingPercentage = 100 - value;
      if (Number(variantSelectedOption) === 2) {
        const halfRemaining = remainingPercentage / 1;
        setVariantBPercent(halfRemaining);
      }
    }
  };

  const handleInputVariantBPercentChange = (e) => {
    e.preventDefault();
    const value = parseInt(e.target.value);
    setVariantBPercent(value);
    // Ensure the input value is a number
    if (!isNaN(value)) {
      const remainingPercentage = 100 - value;
      if (Number(variantSelectedOption) === 2) {
        const halfRemaining = remainingPercentage / 1;
        setVariantAPercent(halfRemaining);
      }
    }
  };

  const handleInputVariantCPercentChange = (e) => {
    e.preventDefault();
    const value = parseInt(e.target.value);
    setVariantCPercent(value);
    // Ensure the input value is a number
  };

  const handleAVariantSelectChange = (e) => {
    e.preventDefault();
    setoptionASelectedOption(e.target.value);
  };
  const handleBVariantSelectChange = (e) => {
    e.preventDefault();
    setoptionBSelectedOption(e.target.value);
  };
  const handleCVariantSelectChange = (e) => {
    e.preventDefault();
    setoptionCSelectedOption(e.target.value);
  };

  const handleADropdownChange = (filterLabel, selectedOption) => {
    setSelectedDropdownAValues((prevValues) => ({
      ...prevValues,
      [`${filterLabel}A`]: selectedOption.map((option) => option.value),
      [filterLabel]: selectedOption.map((option) => ({
        label: option.label,
        value: option.value,
      })),
    }));
  };

  const handleBDropdownChange = (filterLabel, selectedOption) => {
    setSelectedDropdownBValues((prevValues) => ({
      ...prevValues,
      [`${filterLabel}B`]: selectedOption.map((option) => option.value),
      [filterLabel]: selectedOption.map((option) => ({
        label: option.label,
        value: option.value,
      })),
    }));
  };

  const handleCDropdownChange = (filterLabel, selectedOption) => {
    setSelectedDropdownCValues((prevValues) => ({
      ...prevValues,
      [`${filterLabel}C`]: selectedOption.map((option) => option.value),
      [filterLabel]: selectedOption.map((option) => ({
        label: option.label,
        value: option.value,
      })),
    }));
  };

  const filterOptionsMap = {
    1: appVersionOptions,
    2: cohortOptions,
    3: finalSourceOptions,
    4: ageRangeOptions,
    5: genderOptions,
    6: locationOptions,
  };

  useEffect(() => {
    // Filter the selected filters
    const selectedFilters = addFiltersSelectedOptions.map(
      (option) => option.value
    );
    // Create Variant A, Variant B and Variant C options based on selected filters
    const variantAOptions = selectedFilters.map((filter) => {
      const optionData = filteredOptionsData.find(
        (option) => option.value === filter
      );
      return {
        label: optionData.value,
        options: filterOptionsMap[optionData.id],
      };
    });
    const variantBOptions = selectedFilters.map((filter) => {
      const optionData = filteredOptionsData.find(
        (option) => option.value === filter
      );
      return {
        label: optionData.value,
        options: filterOptionsMap[optionData.id],
      };
    });
    const variantCOptions = selectedFilters.map((filter) => {
      const optionData = filteredOptionsData.find(
        (option) => option.value === filter
      );
      return {
        label: optionData.value,
        options: filterOptionsMap[optionData.id],
      };
    });

    setVariantAOptions(variantAOptions);
    setVariantBOptions(variantBOptions);
    setVariantCOptions(variantCOptions);
  }, [addFiltersSelectedOptions, appVersionOptions]);

  const handleAddFiltersMultipleSelectChange = (selectedOptions) => {
    const selectedFilters = addFiltersSelectedOptions.map(
      (option) => option.value
    );
    const oldselectedFilters = selectedOptions.map((option) => option.value);
    const uniqueValue = getUniqueValue(selectedFilters, oldselectedFilters);
    setBlankOnRemoveArray([...blankOnRemoveArray, uniqueValue]);
    setAddFiltersSelectedOptions(selectedOptions);
  };

  const getUniqueValue = (selectedFilters, oldselectedFilters) => {
    const uniqueValues = selectedFilters.filter(
      (filter) => !oldselectedFilters.includes(filter)
    );
    return uniqueValues.length === 1 ? uniqueValues[0] : null;
  };

  return {
    moduleSelectedOption,
    handleModuleSelectChange,
    productCatalogSelectedOption,
    handleProductCatalogSelectChange,
    servicesSelectedOption,
    handleServicesSelectChange,
    servicesOptions,
    variantSelectedOption,
    handleVariantSelectChange,
    variantAPercent,
    handleInputVariantAPercentChange,
    optionASelectedOption,
    handleAVariantSelectChange,
    selectedProductBaseOptions,
    variantBPercent,
    handleInputVariantBPercentChange,
    optionBSelectedOption,
    handleBVariantSelectChange,
    variantCPercent,
    handleInputVariantCPercentChange,
    optionCSelectedOption,
    handleCVariantSelectChange,
    usersSelectedOption,
    handleUsersSelectChange,
    addFiltersSelectedOptions,
    handleAddFiltersMultipleSelectChange,
    variantAOptions,
    toTitleCase,
    selectedDropdownAValues,
    handleADropdownChange,
    variantBOptions,
    selectedDropdownBValues,
    handleBDropdownChange,
    variantCOptions,
    selectedDropdownCValues,
    handleCDropdownChange,
    toggleStatus,
    handleToggleChange,
    handleCreateAbTest,
    pageLoading,
  };
};

export default useAbTesting;
