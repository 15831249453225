import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import Loader from 'components/Loader';
import NewTable from 'components/NewTable';
import PageLoader from 'components/PageLoader';

// Constants
import { APP_CONSTANTS } from 'constants/app-constants';

// Utils
import Toast from 'utils/Toast';
import Message from 'utils/Message';

// API
import { getStaticContent, deleteStatic } from 'api/static-content';

const Static = ({ tableHeader }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [pageLoading, setPageLoading] = useState(false);

  const loadNames = (start, end, increase) => {
    setLoading(true);
    getStaticContent(start, end).then((response) => {
      setLoading(false);
      if (response && response.tableData && response.error === false) {
        setData({
          tableData: response.tableData,
        });
      }
    });
  };

  const callDeleteAPI = async (data) => {
    setPageLoading(true);
    if (data && data.static_page_id) {
      let responseObj = await deleteStatic(data.static_page_id);
      setPageLoading(false);
      if (
        responseObj &&
        responseObj.response &&
        responseObj.response.code === 200
      ) {
        loadNames();
        Toast.success(Message.Static.DELETE_SUCCESS);
      } else {
        Toast.error(Message.Static.DELETE_FAILURE);
      }
    }
  };

  const menuActionObj = [
    {
      name: 'Edit',
      onClick: (e, data) => {
        history.push({
          pathname: `/static-content/edit/${data.static_page_id}`,
          state: {
            ...data,
          },
        });
      },
    },
    {
      name: 'Delete',
      onClick: (e, data) => {
        let text = 'are you sure,want to delete';
        if (window.confirm(text) === true) {
          callDeleteAPI(data);
          // text = 'You pressed OK!';
        } else {
          // text = 'You canceled!';
        }
      },
    },
  ];

  const sectionObj = {
    sectionTitle: 'Static Content',
    searchObj: {
      placeholder: 'Search',
      handleSearch: (e) => {},
    },
    buttons: [
      {
        title: 'Create a static content',
        handleBtnClick: (e) => {
          history.push(APP_CONSTANTS.CREATE_STATIC);
        },
      },
    ],
  };

  if (loading === true) {
    return <Loader />;
  }

  return (
    <div className='blogs'>
      <NewTable
        sectionObj={sectionObj}
        tableHeader={tableHeader}
        menuAction={menuActionObj}
        data={data}
        getData={getStaticContent}
        searchKey='title'
      />
      {pageLoading === true && <PageLoader pageLoading={pageLoading} />}
    </div>
  );
};

export default Static;
