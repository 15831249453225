import * as dataAccess from 'utils/ajax';
import ApiParser from 'utils/ApiParser';
import Parsers from 'utils/Parsers';
import { API_CONSTANTS } from 'constants/api-contants';

export const getBanners = async () => {
  let responseObj = await dataAccess.get(API_CONSTANTS.GET_BANNERS);
  return ApiParser.parseBannersListing(responseObj);
};

export const getBannerTag = async () => {
  let responseObj = await dataAccess.get(API_CONSTANTS.GET_BANNER_TAGS);
  return responseObj;
};

export const getEventBanner = async () => {
  let responseObj = await dataAccess.get(API_CONSTANTS.GET_EVENT_BANNERS);
  return responseObj;
};

export const publishOrUnpublishBanner = async (publishedby, status) => {
  if (!status || !publishedby) {
    return;
  }

  let data = {
    banner_type: publishedby,
    update_banner: 1,
    publish: status.toLowerCase() === 'inactive' ? 1 : 0,
  };

  var formData = Parsers.ConvertObjectAsFormData(data);

  let payload = {
    data: formData,
  };

  return await dataAccess.upload(API_CONSTANTS.UPDATE_BANNER_CONTENT, payload);
};

export const addOrUpdateBanner = async (incomingData) => {
  let data;

  let payloads = [];
  if (incomingData.banner_details && incomingData.banner_details.length > 0) {
    incomingData.banner_details.forEach((banner) => {
      data = {
        publish: 1,
        ...incomingData,
        update_banner: banner.update_banner,
        banner_details: JSON.stringify(banner),
      };
      // console.log(data, 'dataedit');
      if (banner.banner_image) {
        data.banner_image = banner.banner_image;
      }
      var formData = Parsers.ConvertObjectAsFormData(data);
      payloads.push({
        data: formData,
      });
    });
  }

  if (incomingData.removedBanners && incomingData.removedBanners.length > 0) {
    dataAccess
      .del(API_CONSTANTS.DELETE_BANNER_CONTENT, {
        data: {
          banner_type: incomingData.banner_type,
          banner_id: incomingData.removedBanners,
        },
      })
      .then((res) => {
        console.log('Delete Response: ', res);
      });
  }

  if (payloads.length < 1) {
    return;
  }

  return await dataAccess.uploadAll(
    API_CONSTANTS.UPDATE_BANNER_CONTENT,
    payloads
  );
};
