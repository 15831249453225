

import { deleteStoryApi, getStoriesDataApi, storyPublishOrUnpublishAPI, reorderStories } from "app/api/cmsDiscoverApi";
import Button from "app/components/button";
import Search from "app/components/search";
import Pagination from "app/container/CmsContent/pagination";
import SelectComp from "app/container/CmsContent/select-tag";
import Toast from "app/utils/Toast";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import "./index.scss";

import { StoriesHeader } from 'app/constants/table-header-constants';

import DragTable from './drag'


const options = [
     // { value: 5, label: 5, },
     { value: 10, label: 10, },
     { value: 15, label: 15, },
     { value: 20, label: 20, },
     { value: 25, label: 25, },
]
const StoriesTable = () => {
     const history = useHistory();
     const location = useLocation()
     const [dataCollection, setDataCollection] = useState([]);
     const [totalDataCollection, setTotalDataCollection] = useState([]);
     const [pageCount, setPageCount] = useState(1);
     const [dataPerPage, setDataPerPage] = useState(10);
     const [currentPage, setCurrentPage] = useState(location.state?.goto || 1);
     const [searchValue, setSearchValue] = useState('')

     const indexOfLastData = currentPage * dataPerPage;
     const indexOfFirstData = indexOfLastData - dataPerPage;
     let currentData = dataCollection.slice(indexOfFirstData, indexOfLastData)

     useEffect(() => {
          handlesGetData()
          return () => {
               window.history.replaceState({}, document.title)
          }

     }, []);


     useEffect(() => {
          getDataHandler(searchValue)
     }, [searchValue]);


     function inputChangeHandler(searchKey) {
          setSearchValue(searchKey)
     }

     async function handlesGetData() {
          const data = await getStoriesDataApi();
          const numAscending = [...data].sort((a, b) => a.order_id - b.order_id);
          setDataCollection(numAscending)
          setTotalDataCollection(numAscending)
          let count = Math.ceil(numAscending.length / dataPerPage);
          // setDataCollection(data);
          setPageCount(count)
     }

     async function getDataHandler(search) {
          let count;
          if (search !== "") {
               // const data = await getStoriesDataApi();
               let filteredData = []
               totalDataCollection.forEach((data) => {
                    if (data?.story_title[0]?.title.toLowerCase().includes(search)) {
                         filteredData.push(data);
                    }
               });
               count = Math.ceil(filteredData.length / dataPerPage);
               setDataCollection(filteredData)
               setPageCount(count)
          } else {
               // handlesGetData();
               count = Math.ceil(totalDataCollection.length / dataPerPage);
               setDataCollection(totalDataCollection)
               setPageCount(count)
          }
     }

     // Pagination
     function paginationHandler(page) {
          setCurrentPage(page);
     }
     function changeHandler(event) {
          const pageLength = event.target.value
          setDataPerPage(pageLength);
          let count = Math.ceil(dataCollection.length / pageLength);
          setPageCount(count)
     }

     const handleDelete = async (id) => {
          await deleteStoryApi(id)
          handlesGetData()
     }
     const publishOrUnpublish = async (data) => {
          const query = {
               "ACTIVE": "unpublish",
               "INACTIVE": "PUBLISH"
          }
          const resp = await storyPublishOrUnpublishAPI(data.id, query[data.status])
          if (resp?.data?.length != 0) {
               Toast.success("Updated Successfully")
          }
          handlesGetData()
     }

     const HomePageOrder = async (tempData) => {
          const storiesData = tempData.map((data, index) => {
            return {
              story_id: data.original.id,
              order_id: index + 1,
            };
          });
          // console.log(storiesData, 'storiesData')
          const res = await reorderStories(storiesData);
        };
      
        const menuActionObj = [
          {
            name: 'Edit',
            onClick: (e, data) => {
              history.push({
                pathname: `/edit-homepage-order/${data.id}`,
                state: {
                  ...data,
                },
              });
            },
          },
        ];
      
     //    const sectionObj = {
     //      sectionTitle: 'HomePage-Order',
     //      searchObj: {
     //        placeholder: 'Search',
     //        handleSearch: (e) => {
     //          console.log('Handle search: ', e);
     //        },
     //      },
     //    };

     return (
          <div className="table_container">
               <h4>Stories</h4>
               <div className="table_container__actionBox">
                    <div className="table_container__filterSearch">
                         <Search
                              value={searchValue}
                              placeholder="Search"
                              className={""}
                              onChange={inputChangeHandler}
                         />
                    </div>
                    <Button onClick={() => history.push('/cms/stories/create')} >Create Story</Button>
               </div>
               {/* <div className="table_container__items_perPage">
                    Show
                    <SelectComp
                         options={options}
                         onChangeHandler={changeHandler}
                    />
                    entries
                    
               </div> */}
               <div className='w-100'>
                    {/* <NewTable
                    // sectionObj={sectionObj}
                    tableHeader={StoriesHeader}
                    menuAction={menuActionObj}
                    data={currentData}
                    HomePageOrder={HomePageOrder}
                    getData={getStoriesDataApi}
                    hasPagination={false}
                    /> */}
                    <DragTable
                         tableHeader={StoriesHeader}
                         // sectionObj={sectionObj}
                         menuAction={menuActionObj}
                         data={totalDataCollection}
                         HomePageOrder={HomePageOrder}
                         hasPagination={false}
                         selectedContent={totalDataCollection}
                         currentPage={currentPage}
                         handleDelete={handleDelete}
                         publishOrUnpublish={publishOrUnpublish}
                    />
               </div>
               {/* <div className="table-box">
                    <table>
                         <tr>
                              <th className='nowrap text-center'>Story Name</th>
                              <th className='nowrap text-center'>Story Type</th>
                              <th className='nowrap text-center'>Published Date</th>
                              <th className='nowrap text-center'>Updated Date</th>
                              <th className='nowrap text-center'>Status</th>
                              <th className='nowrap text-center'>Action</th>
                              <th className='nowrap text-center'>Collection ID</th>
                         </tr>
                         {currentData.length > 0 && currentData.map((data) => (<tr key={data.id}>
                              <td>{data.story_title[0].title || "-"}</td>
                              <td>{data.story_type || "-"}</td>
                              <td>{data.published_date || "-"}</td>
                              <td>{data.updated_on || "-"}</td>
                              <td>{data.status || "-"}</td>
                              <td>
                                   <div className="btn-group" role="group" aria-label="Basic outlined example">
                                        <button
                                             type="button"
                                             className="btn btn-outline-primary"
                                             onClick={() => {
                                                  history.push({
                                                       pathname: `/cms/stories/create`,
                                                       state: {
                                                            ...data,
                                                            comingFrom: currentPage
                                                       },
                                                  });
                                             }}
                                        >
                                             Edit
                                        </button>
                                        <button type="button"
                                             onClick={() => handleDelete(data.id)}
                                             className="btn btn-outline-primary">Delete</button>
                                        <button onClick={() => publishOrUnpublish(data)} type="button" className="btn btn-outline-primary">{data.status == "ACTIVE" ?
                                             "Unpublish"
                                             :
                                             "Publish"
                                        }</button>
                                   </div>
                              </td>
                              <td>{data.collection_id || "-"}</td>

                         </tr>))}
                         
                    </table>
               </div> */}

               {/* <div className="table_container__pagination">
                    <Pagination
                         pages={pageCount}
                         onChange={paginationHandler}
                         pageNumber={currentPage}
                    />
               </div> */}

          </div >);
}

export default StoriesTable;