import './index.scss';

function CategoriesCustomTable({
  data,
  tableHeader,
  handleInputChangeHandler,
  handleEdit,
  handleDelete,
}) {
  return (
    <table>
      <tr>
        {tableHeader.map((item, i) => {
          return <th className='nowrap'>{item.Header}</th>;
        })}
      </tr>
      {data.map((items, index) => (
        <tr key={index}>
          <td>
            <input
              type='number'
              className='form-input'
              placeholder=''
              name='ranking'
              value={items.ranking}
              onChange={(e) => handleInputChangeHandler('ranking', e, index)}
              disabled={items.isDisabled}
            />
          </td>
          <td>{items.medicine_name}</td>
          <td>{items.medicine_id}</td>
          <td>{items.discount}</td>
          <td>{items.updated_by}</td>

          <td>
            <div
              className='btn-group'
              role='group'
              aria-label='Basic outlined example'
            >
              <button
                type='button'
                onClick={() => {
                  handleDelete('ranking', index);
                }}
                className='btn btn-outline-primary'
              >
                Delete
              </button>
            </div>
          </td>

          <td>
            <div
              className='btn-group'
              role='group'
              aria-label='Basic outlined example'
            >
              <button
                type='button'
                className='btn btn-outline-primary'
                onClick={() => {
                  handleEdit(items, items.isDisabled ? 'Edit' : 'Save', index);
                }}
              >
                {items.isDisabled ? 'Edit' : 'Save'}
              </button>
            </div>
          </td>
        </tr>
      ))}
      {data.length === 0 && (
        <tr>
          <td colspan='10000'>No data found</td>{' '}
        </tr>
      )}
    </table>
  );
}

export default CategoriesCustomTable;
