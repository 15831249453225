import React from 'react';

// Pages
import BasePage from 'pages/BasePage'; 
import StaticWidget from 'app/container/StaticWidgets';
import { StaticWidgetHeader } from 'constants/table-header-constants';

const StaticWidgets = () => {
  return (
    <BasePage>
      <StaticWidget tableHeader={StaticWidgetHeader}/>
    </BasePage>
  );
};

export default StaticWidgets;
