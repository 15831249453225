import * as dataAccess from 'utils/ajax';
import ApiParser from 'utils/ApiParser';
import Parsers from 'utils/Parsers';

import { API_CONSTANTS } from 'constants/api-contants';

export const getUsers = async (start) => {
  let payload = {
    data: {
      page: start ? start : 1,
      limit: 10,
    },
  };
  let responseObj = await dataAccess.post(API_CONSTANTS.GET_USERS, payload);
  return ApiParser.parseUsersListing(responseObj);
};

export const getResendEmail = async (email) => {
  let payload = {
    data: {
      email: email,
    },
  };
  let responseObj = await dataAccess.post(API_CONSTANTS.RESEND_EMAIL, payload);
  // console.log(responseObj, 'responseObjresponseObjresponseObj');
  return responseObj;
};

export const addOrUpdateUser = async (incomingData) => {
  let data = {
    ...incomingData,
  };

  var formData = Parsers.ConvertObjectAsFormData(data);

  let payload = {
    data: formData,
  };

  return await dataAccess.upload(API_CONSTANTS.UPDATE_USER, payload);
};

export const deleteUser = async (employeeId) => {
  if (!employeeId) {
    return;
  }

  let payload = {
    data: {
      employee_id: employeeId,
    },
  };

  return await dataAccess.del(API_CONSTANTS.DELETE_USER, payload);
};

export const makeUserAsActiveOrInactive = async (employeeId, status) => {
  if (!employeeId || !status) {
    return;
  }

  let data = {
    employee_id: parseInt(employeeId),
    status: status.toLowerCase() === 'inactive' ? 'ACTIVE' : 'INACTIVE',
    update_user: 1,
  };

  var formData = Parsers.ConvertObjectAsFormData(data);

  let payload = {
    data: formData,
  };

  return await dataAccess.upload(API_CONSTANTS.UPDATE_USER, payload);
};
