const countryListOptions = [
  {
    id: 1,
    value: 'Afghanistan',
  },
  {
    id: 2,
    value: 'Aland',
  },
  {
    id: 3,
    value: 'Albania',
  },
  {
    id: 4,
    value: 'Algeria',
  },
  {
    id: 5,
    value: 'American Samoa',
  },
  {
    id: 6,
    value: 'Andorra',
  },
  {
    id: 7,
    value: 'Angola',
  },
  {
    id: 8,
    value: 'Anguilla',
  },
  {
    id: 9,
    value: 'Antigua and Barbuda',
  },
  {
    id: 10,
    value: 'Argentina',
  },
  {
    id: 11,
    value: 'Armenia',
  },
  {
    id: 12,
    value: 'Aruba',
  },
  {
    id: 13,
    value: 'Ascension',
  },
  {
    id: 14,
    value: 'Australia',
  },
  {
    id: 15,
    value: 'Australian Antarctic Territory',
  },
  {
    id: 16,
    value: 'Austria',
  },
  {
    id: 17,
    value: 'Azerbaijan',
  },
  {
    id: 18,
    value: 'Bahamas, The',
  },
  {
    id: 19,
    value: 'Bahrain',
  },
  {
    id: 20,
    value: 'Bangladesh',
  },
  {
    id: 21,
    value: 'Barbados',
  },
  {
    id: 22,
    value: 'Belarus',
  },
  {
    id: 23,
    value: 'Belgium',
  },
  {
    id: 24,
    value: 'Belize',
  },
  {
    id: 25,
    value: 'Benin',
  },
  {
    id: 26,
    value: 'Bermuda',
  },
  {
    id: 27,
    value: 'Bhutan',
  },
  {
    id: 28,
    value: 'Bolivia',
  },
  {
    id: 29,
    value: 'Bonaire, Saint Eustatius and Saba',
  },
  {
    id: 30,
    value: 'Bosnia and Herzegovina',
  },
  {
    id: 31,
    value: 'Botswana',
  },
  {
    id: 32,
    value: 'Bouvet Island',
  },
  {
    id: 33,
    value: 'Brazil',
  },
  {
    id: 34,
    value: 'British Indian Ocean Territory',
  },
  {
    id: 35,
    value: 'British Sovereign Base Areas',
  },
  {
    id: 36,
    value: 'British Virgin Islands',
  },
  {
    id: 37,
    value: 'Brunei',
  },
  {
    id: 38,
    value: 'Bulgaria',
  },
  {
    id: 39,
    value: 'Burkina Faso',
  },
  {
    id: 40,
    value: 'Burundi',
  },
  {
    id: 41,
    value: 'Cambodia',
  },
  {
    id: 42,
    value: 'Cameroon',
  },
  {
    id: 43,
    value: 'Canada',
  },
  {
    id: 44,
    value: 'Cape Verde',
  },
  {
    id: 45,
    value: 'Cayman Islands',
  },
  {
    id: 46,
    value: 'Central African Republic',
  },
  {
    id: 47,
    value: 'Chad',
  },
  {
    id: 48,
    value: 'Chile',
  },
  {
    id: 49,
    value: 'China, Peoples Republic ofile',
  },
  {
    id: 50,
    value: 'China, Republic of (Taiwan)',
  },
  {
    id: 51,
    value: 'Christmas Island',
  },
  {
    id: 52,
    value: 'Clipperton Island',
  },
  {
    id: 53,
    value: 'Cocos (Keeling) Islands',
  },
  {
    id: 54,
    value: 'Colombia',
  },
  {
    id: 55,
    value: 'Comoros',
  },
  {
    id: 56,
    value: 'Congo (Congo-Brazzaville)',
  },
  {
    id: 57,
    value: 'Congo (Congo-Kinshasa)',
  },
  {
    id: 58,
    value: 'Cook Islands',
  },
  {
    id: 59,
    value: 'Costa Rica',
  },
  {
    id: 60,
    value: "Côte d'Ivoire (Ivory Coast)",
  },
  {
    id: 61,
    value: 'Croatia',
  },
  {
    id: 62,
    value: 'Cuba',
  },
  {
    id: 63,
    value: 'Curacao',
  },
  {
    id: 64,
    value: 'Cyprus',
  },
  {
    id: 65,
    value: 'Czech Republic',
  },
  {
    id: 66,
    value: 'Denmark',
  },
  {
    id: 67,
    value: 'Djibouti',
  },
  {
    id: 68,
    value: 'Dominica',
  },
  {
    id: 69,
    value: 'Dominican Republic',
  },
  {
    id: 70,
    value: 'Ecuador',
  },
  {
    id: 71,
    value: 'Egypt',
  },
  {
    id: 72,
    value: 'El Salvador',
  },
  {
    id: 73,
    value: 'Equatorial Guinea',
  },
  {
    id: 74,
    value: 'Eritrea',
  },
  {
    id: 75,
    value: 'Estonia',
  },
  {
    id: 76,
    value: 'Eswatini',
  },
  {
    id: 77,
    value: 'Ethiopia',
  },
  {
    id: 78,
    value: 'Falkland Islands (Islas Malvinas)',
  },
  {
    id: 79,
    value: 'Faroe Islands',
  },
  {
    id: 80,
    value: 'Fiji',
  },
  {
    id: 81,
    value: 'Finland',
  },
  {
    id: 82,
    value: 'France',
  },
  {
    id: 83,
    value: 'French Guiana',
  },
  {
    id: 84,
    value: 'French Southern and Antarctic Lands',
  },
  {
    id: 85,
    value: 'Gabon',
  },
  {
    id: 86,
    value: 'Gambia, The',
  },
  {
    id: 87,
    value: 'Georgia',
  },
  {
    id: 88,
    value: 'Germany',
  },
  {
    id: 89,
    value: 'Ghana',
  },
  {
    id: 90,
    value: 'Gibraltar',
  },
  {
    id: 91,
    value: 'Greece',
  },
  {
    id: 92,
    value: 'Greenland',
  },
  {
    id: 93,
    value: 'Grenada',
  },
  {
    id: 94,
    value: 'Guadeloupe',
  },
  {
    id: 95,
    value: 'Guam',
  },
  {
    id: 96,
    value: 'Guatemala',
  },
  {
    id: 97,
    value: 'Guernsey',
  },
  {
    id: 98,
    value: 'Guinea',
  },
  {
    id: 99,
    value: 'Guinea-Bissau',
  },
  {
    id: 100,
    value: 'Guyana',
  },
  {
    id: 101,
    value: 'Haiti',
  },
  {
    id: 102,
    value: 'Heard Islands and McDonald Islands',
  },
  {
    id: 103,
    value: 'Honduras',
  },
  {
    id: 104,
    value: 'Hong Kong',
  },
  {
    id: 105,
    value: 'Hungary',
  },
  {
    id: 106,
    value: 'Iceland',
  },
  {
    id: 107,
    value: 'India',
  },
  {
    id: 108,
    value: 'Indonesia',
  },
  {
    id: 109,
    value: 'Iran',
  },
  {
    id: 110,
    value: 'Iraq',
  },
  {
    id: 111,
    value: 'Ireland',
  },
  {
    id: 112,
    value: 'Isle of Man',
  },
  {
    id: 113,
    value: 'Israel',
  },
  {
    id: 114,
    value: 'Italy',
  },
  {
    id: 115,
    value: 'Jamaica',
  },
  {
    id: 116,
    value: 'Japan',
  },
  {
    id: 117,
    value: 'Jersey',
  },
  {
    id: 118,
    value: 'Jordan',
  },
  {
    id: 119,
    value: 'Kazakhstan',
  },
  {
    id: 120,
    value: 'Kenya',
  },
  {
    id: 121,
    value: 'Kiribati',
  },
  {
    id: 122,
    value: 'Korea, North',
  },
  {
    id: 123,
    value: 'Korea, South',
  },
  {
    id: 124,
    value: 'Kuwait',
  },
  {
    id: 125,
    value: 'Kyrgyzstan',
  },
  {
    id: 126,
    value: 'Laos',
  },
  {
    id: 127,
    value: 'Latvia',
  },
  {
    id: 128,
    value: 'Lebanon',
  },
  {
    id: 129,
    value: 'Lesotho',
  },
  {
    id: 130,
    value: 'Liberia',
  },
  {
    id: 131,
    value: 'Libya',
  },
  {
    id: 132,
    value: 'Liechtenstein',
  },
  {
    id: 133,
    value: 'Lithuania',
  },
  {
    id: 134,
    value: 'Luxembourg',
  },
  {
    id: 135,
    value: 'Macau',
  },
  {
    id: 136,
    value: 'Macedonia',
  },
  {
    id: 137,
    value: 'Madagascar',
  },
  {
    id: 138,
    value: 'Malawi',
  },
  {
    id: 139,
    value: 'Malaysia',
  },
  {
    id: 140,
    value: 'Maldives',
  },
  {
    id: 141,
    value: 'Mali',
  },
  {
    id: 142,
    value: 'Malta',
  },
  {
    id: 143,
    value: 'Marshall Islands',
  },
  {
    id: 144,
    value: 'Martinique',
  },
  {
    id: 145,
    value: 'Mauritania',
  },
  {
    id: 146,
    value: 'Mauritius',
  },
  {
    id: 147,
    value: 'Mayotte',
  },
  {
    id: 148,
    value: 'Mexico',
  },
  {
    id: 149,
    value: 'Micronesia',
  },
  {
    id: 150,
    value: 'Moldova',
  },
  {
    id: 151,
    value: 'Monaco',
  },
  {
    id: 152,
    value: 'Mongolia',
  },
  {
    id: 153,
    value: 'Montenegro',
  },
  {
    id: 154,
    value: 'Montserrat',
  },
  {
    id: 155,
    value: 'Morocco',
  },
  {
    id: 156,
    value: 'Mozambique',
  },
  {
    id: 157,
    value: 'Myanmar (Burma)',
  },
  {
    id: 158,
    value: 'Namibia',
  },
  {
    id: 159,
    value: 'Nauru',
  },
  {
    id: 160,
    value: 'Nepal',
  },
  {
    id: 161,
    value: 'Netherlands',
  },
  {
    id: 162,
    value: 'Netherlands Antilles',
  },
  {
    id: 163,
    value: 'New Caledonia',
  },
  {
    id: 164,
    value: 'New Zealand',
  },
  {
    id: 165,
    value: 'Nicaragua',
  },
  {
    id: 166,
    value: 'Niger',
  },
  {
    id: 167,
    value: 'Nigeria',
  },
  {
    id: 168,
    value: 'Niue',
  },
  {
    id: 169,
    value: 'Norfolk Island',
  },
  {
    id: 170,
    value: 'Northern Mariana Islands',
  },
  {
    id: 171,
    value: 'Norway',
  },
  {
    id: 172,
    value: 'Oman',
  },
  {
    id: 173,
    value: 'Pakistan',
  },
  {
    id: 174,
    value: 'Palau',
  },
  {
    id: 175,
    value: 'Panama',
  },
  {
    id: 176,
    value: 'Papua New Guinea',
  },
  {
    id: 177,
    value: 'Paraguay',
  },
  {
    id: 178,
    value: 'Peru',
  },
  {
    id: 179,
    value: 'Philippines',
  },
  {
    id: 180,
    value: 'Pitcairn Islands',
  },
  {
    id: 181,
    value: 'Poland',
  },
  {
    id: 182,
    value: 'Portugal',
  },
  {
    id: 183,
    value: 'Puerto Rico',
  },
  {
    id: 184,
    value: 'Qatar',
  },
  {
    id: 185,
    value: 'Reunion',
  },
  {
    id: 186,
    value: 'Romania',
  },
  {
    id: 187,
    value: 'Russia',
  },
  {
    id: 188,
    value: 'Rwanda',
  },
  {
    id: 189,
    value: 'Saint Barthélemy',
  },
  {
    id: 190,
    value: 'Saint Helena',
  },
  {
    id: 191,
    value: 'Saint Kitts and Nevis',
  },
  {
    id: 192,
    value: 'Saint Lucia',
  },
  {
    id: 193,
    value: 'Saint Martin',
  },
  {
    id: 194,
    value: 'Saint Pierre and Miquelon',
  },
  {
    id: 195,
    value: 'Saint Vincent and the Grenadines',
  },
  {
    id: 196,
    value: 'Samoa',
  },
  {
    id: 197,
    value: 'San Marino',
  },
  {
    id: 198,
    value: 'Sao Tome and Principe',
  },
  {
    id: 199,
    value: 'Saudi Arabia',
  },
  {
    id: 200,
    value: 'Senegal',
  },
  {
    id: 201,
    value: 'Serbia',
  },
  {
    id: 202,
    value: 'Seychelles',
  },
  {
    id: 203,
    value: 'Sierra Leone',
  },
  {
    id: 204,
    value: 'Singapore',
  },
  {
    id: 205,
    value: 'Sint Maarten',
  },
  {
    id: 206,
    value: 'Slovakia',
  },
  {
    id: 207,
    value: 'Slovenia',
  },
  {
    id: 208,
    value: 'Solomon Islands',
  },
  {
    id: 209,
    value: 'Somalia',
  },
  {
    id: 210,
    value: 'South Africa',
  },
  {
    id: 211,
    value: 'South Georgia and South Sandwich Islands',
  },
  {
    id: 212,
    value: 'South Sudan',
  },
  {
    id: 213,
    value: 'Spain',
  },
  {
    id: 214,
    value: 'Sri Lanka',
  },
  {
    id: 215,
    value: 'Sudan',
  },
  {
    id: 216,
    value: 'Suriname',
  },
  {
    id: 217,
    value: 'Svalbard',
  },
  {
    id: 218,
    value: 'Sweden',
  },
  {
    id: 219,
    value: 'Switzerland',
  },
  {
    id: 220,
    value: 'Syria',
  },
  {
    id: 221,
    value: 'Tajikistan',
  },
  {
    id: 222,
    value: 'Tanzania',
  },
  {
    id: 223,
    value: 'Thailand',
  },
  {
    id: 224,
    value: 'Timor-Lesta (East Timor)',
  },
  {
    id: 225,
    value: 'Togo',
  },
  {
    id: 226,
    value: 'Tokelau',
  },
  {
    id: 227,
    value: 'Tonga',
  },
  {
    id: 228,
    value: 'Trinidad and Tobago',
  },
  {
    id: 229,
    value: 'Tristan da Cunha',
  },
  {
    id: 230,
    value: 'Tunisia',
  },
  {
    id: 231,
    value: 'Turkey',
  },
  {
    id: 232,
    value: 'Turkmenistan',
  },
  {
    id: 233,
    value: 'Turks and Caicos Islands',
  },
  {
    id: 234,
    value: 'Tuvalu',
  },
  {
    id: 235,
    value: 'US Virgin Islands',
  },
  {
    id: 236,
    value: 'Uganda',
  },
  {
    id: 237,
    value: 'Ukraine',
  },
  {
    id: 238,
    value: 'United Arab Emirates',
  },
  {
    id: 239,
    value: 'United Kingdom',
  },
  {
    id: 240,
    value: 'United States',
  },
  {
    id: 241,
    value: 'Uruguay',
  },
  {
    id: 242,
    value: 'Uzbekistan',
  },
  {
    id: 243,
    value: 'Vanuatu',
  },
  {
    id: 244,
    value: 'Vatican City',
  },
  {
    id: 245,
    value: 'Venezuela',
  },
  {
    id: 246,
    value: 'Vietnam',
  },
  {
    id: 247,
    value: 'Wake Island',
  },
  {
    id: 248,
    value: 'Wallis and Futuna',
  },
  {
    id: 249,
    value: 'Western Sahara',
  },
  {
    id: 250,
    value: 'Yemen',
  },
  {
    id: 251,
    value: 'Zambia',
  },
  {
    id: 252,
    value: 'Zimbabwe',
  },
];

export default countryListOptions;
