import { deleteContent, publishOrUnpublishAPI } from 'app/api/cms-content';
import Toast from 'app/utils/Toast';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import './index.scss';

function ContentTable(props) {
  const history = useHistory();
  const [pages, setPages] = useState();
  const [data, setData] = useState([]);
  const [searchVal, setSearchVal] = useState('');
  const [isSearchActive, setIsSearchActive] = useState(false);
  // let data

  // let data = useMemo(() => {
  //      if (isSearchActive) {
  //           return props.totalData;
  //      } else {
  //           return props.content;
  //      }
  // }, [isSearchActive, props.totalData, props.content])

  useEffect(() => {
    setPages(props.currentPage);
    setData(props.content);
    setPageSize(props.dataPerPage);
  }, [props.currentPage, props.content, props.dataPerPage]);
  const columns = useMemo(
    () => [
      {
        Header: 'Coupon ID',
        accessor: 'coupon_id',
        Cell: ({ row }) => (
          <p className='table-cells-center'>{row.original.coupon_id || '-'}</p>
        ),
      },
      {
        Header: 'Coupon Code',
        accessor: 'coupon_code',
        Cell: ({ row }) => (
          <p className='table-cells-center'>
            {row.original.coupon_code || '-'}
          </p>
        ),
      },
      {
        Header: 'Coupon Name',
        accessor: 'coupon_name',
        Cell: ({ row }) => (
          <p className='table-cells-center'>
            {row.original.coupon_name || '-'}
          </p>
        ),
      },

      {
        Header: 'Product Type',
        accessor: 'product_type',
        Cell: ({ row }) => (
          <p className='table-cells-center'>
            {row.original.product_type || '-'}
          </p>
        ),
      },
      {
        Header: 'Discount',
        accessor: 'discount',
        Cell: ({ row }) => (
          <p className='table-cells-center'>{row.original.discount || '-'}</p>
        ),
      },
      {
        Header: 'Start Time',
        accessor: 'start_from',
        Cell: ({ row }) => (
          <p className='table-cells-center'>{row.original.start_from || '-'}</p>
        ),
      },
      {
        Header: 'End Time',
        accessor: 'end_at',
        Cell: ({ row }) => (
          <p className='table-cells-center'>{row.original.end_at || '-'}</p>
        ),
      },
      {
        Header: 'Published Date',
        accessor: 'published_date',
        Cell: ({ row }) => (
          <p className='table-cells-center'>{row.original.created_on || '-'}</p>
        ),
      },
      {
        Header: 'Updated Date',
        accessor: 'updated_on',
        Cell: ({ row }) => (
          <p className='table-cells-center'>{row.original.updated_on || '-'}</p>
        ),
      },

      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }) => (
          <p className='table-cells-center'>{row.original.status || '-'}</p>
        ),
      },

      {
        Header: 'Components',
        Cell: ({ row }) => (
          <div
            className='btn-group'
            role='group'
            aria-label='Basic outlined example'
          >
            <button
              type='button'
              className='btn btn-outline-primary'
              onClick={() => {
                history.push({
                  pathname: `/coupons/edit/:${row.original.id}`,
                  state: {
                    ...row.original,
                  },
                });
              }}
            >
              Manage Coupon
            </button>
          </div>
        ),
      },
    ],
    [pages]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
    setGlobalFilter,
    row,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  async function searchHandler(search) {
    setSearchVal(search);
    if (search) {
      setIsSearchActive(true);
      setGlobalFilter(search);
    } else {
      setIsSearchActive(false);
    }
    return;
  }

  const handleDelete = async (id) => {
    const resp = await deleteContent(id);
    if (resp?.data?.length != 0) {
      Toast.success('Successfully Deleted');
    }
    props.getContentDataHandler();
  };
  const publishOrUnpublish = async (item) => {
    //cms/discover/content/unpublish/118
    const query = {
      ACTIVE: 'unpublish',
      INACTIVE: 'PUBLISH',
    };
    const resp = await publishOrUnpublishAPI(item.id, query[item.status]);
    if (resp?.data?.length != 0) {
      Toast.success('Updated Successfully');
    }
    props.getContentDataHandler();
  };

  const arrowShow = ({ isSorted, isSortedDesc }) => {
    if (isSorted) {
      if (isSortedDesc) {
        return <span className='my-table__arrow'>🠻</span>;
      } else {
        return <span className='my-table__arrow'>🠹</span>;
      }
    } else {
      return <span className='my-table__arrow'>⬍</span>;
    }
  };

  return (
    <div className='table'>
      {/* <Search
               value={searchVal}
               placeholder="Column Search"
               onChange={searchHandler} 
          /> */}
      {props.content.length > 0 && (
        <table {...getTableProps()} className='my-table'>
          <thead>
            {headerGroups.map((headerGroup) => {
              return (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render('Header')}
                        <>{arrowShow(column)}</>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);

              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      {isSearchActive ? (
        <div className='react-table-pagination'>
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className='page-item page-link'
          >
            {'<'}
          </button>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className='page-item page-link '
          >
            {'>'}
          </button>
        </div>
      ) : (
        <>{props.children}</>
      )}
    </div>
  );
}

export default ContentTable;
