import React from 'react'
import AuditLogsList from './AuditLogsList'
import { AuditLogsTableHeader } from 'app/constants/table-header-constants'
const AuditLogsPage = () => {
  return (
    <div>
        <section className='section-header'>
          <h1 className='section-header__title'>Audit Logs</h1>
        </section>
        <div className='table-container'>
        <AuditLogsList tableHeader={AuditLogsTableHeader}/>
      </div>
    </div>
  )
}

export default AuditLogsPage