// Pages
// import HomePageOrdering from 'app/container/HomePageOrdering';
import BasePage from 'pages/BasePage';

import { HomePageHeader } from 'constants/table-header-constants';
import HomePageOrderContainer from 'app/container/HomePageOrder';

const HomePageOrder = () => {
  return (
    <BasePage>
      <HomePageOrderContainer tableHeader={HomePageHeader} />
    </BasePage>
  );
};

export default HomePageOrder;
