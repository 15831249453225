import '../index.scss';
import { dataFormats } from 'app/utils/dateChecker';

function CustomTable({ data, tableHeader }) {
  return (
    <table>
      <thead>
        {tableHeader.map((item, i) => {
          return <th className='nowrap'>{item.Header}</th>;
        })}
      </thead>
      <tbody>
        {data.map((items, index) => (
          <tr key={index}>
            <td>
              {items.phone_number ? items.phone_number : 'NA'}
            </td>
            <td>
              {items.created_on
                ? dataFormats(items.created_on, 'DD MMM YYYY HH:mm:ss')
                : '-'}
            </td>
          </tr>
        ))}
        {data.length === 0 && (
          <tr>
            <td colSpan='10000'>No data found</td>{' '}
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default CustomTable;
