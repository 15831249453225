import React from 'react';
import Button from 'app/components/button';
import WidgetCard from 'app/components/WidgetCard';
import NewInput from 'app/components/NewInput';
import Toggle from 'react-toggle';
import useRefferee from './useRefferee';
import PageLoader from 'app/components/PageLoader';

const Referee = () => {
  const {
    pageLoading,
    values,
    handleChangeRefree,
    callAddOrUpdateRefree,
    handleChangeRefreeToggle,
  } = useRefferee();
  return (
    <>
      <div className='referee-content'>
        <WidgetCard
          // key={data.id}
          title={'Signup coins'}
          type={'Signup coins'}
        >
          <div className='widget_box'>
            <div className='flex-field'>
              <NewInput
                label='Condition*'
                placeholder='Sign up'
                className='form-block'
                type='text'
                name='condition'
                value={values?.condition}
                isDisabled={true}
              />
              <div className='w-50'>
                <NewInput
                  label='Signup Coins*'
                  placeholder='200'
                  className='demo'
                  type='number'
                  name='signup_coins'
                  value={values?.signup_coins}
                  onChange={handleChangeRefree}
                />
              </div>
              <div className='mt1 text-toggle-wrap'>
                <label>Status</label>
                <div className='text-toggle'>
                  <span className='text-danger'>Off</span>
                  <Toggle
                    checked={values.refreeStatus}
                    icons={false}
                    onChange={handleChangeRefreeToggle}
                  />
                  <span className='text-success'>ON</span>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </WidgetCard>
        <div className='text-right mt2'>
          <Button
            onClick={callAddOrUpdateRefree}
            type='submit'
            name='submit'
            isDisabled={!values.condition || !values.signup_coins}
            // cl
          />
        </div>
      </div>
      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </>
  );
};

export default Referee;
