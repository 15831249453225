import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import NewInput from 'app/components/NewInput';
import Toast from 'app/utils/Toast';
import PageLoader from 'app/components/PageLoader';
import CTAType from 'app/components/CTAType';
import Button from 'app/components/button';
import DropdownOptions from 'app/utils/DropdownOptions';
import MultiSelectDropdown from 'app/components/MultiSelectDropdown';
import {
  getWidgetType,
  updateStaticWidget,
  getStaticWidgetLocalisationDetails,
  updateStaticWidgetLocalisation,
} from 'app/api/widgets';
import TabBar from 'app/components/NewDesign/TabBar';
import useTabBarOptions from 'app/utils/useTabbarOptions';

const CreateStaticWidgets = () => {
  const location = useLocation();
  let history = useHistory();
  const params = useParams();
  const { LanguageOptions } = useTabBarOptions();
  const [loader, setLoader] = useState(false);
  const [internalUrl, setInternalURL] = useState([]);
  const [cohortOptions, setCohortOptions] = useState([]);
  const [ageOptions, setAgeOptions] = useState([]);
  const [activeTab, setActiveTab] = useState({
    id: '1',
    name: 'English',
    short_name: 'en',
    display_order: 1,
    status: 'ACTIVE',
    value: 'English',
  });
  const [disableFields, setDisableFields] = useState(false);
  const [state, setState] = useState({
    widget_name: '',
    url_type: 'INTERNAL',
    url: '',
    internal_for: '',
    internal_url: '',
    profile_data: false,
    custom_object: '',
    redirection_id: '',
    detail_cta_text: '',
    cohort_tags: [],
    age_tags: [],
    widget_id: '',
  });

  useEffect(() => {
    updateStates();
    if (activeTab?.name === 'English') {
      getDropdown();
    }
  }, [activeTab?.short_name, params.id]);

  const updateStates = async () => {
    let internalUrl = [];
    DropdownOptions.InternalUrl.map((item, i) => {
      if (
        item.value !== 'feed' &&
        item.value !== 'content_detail' &&
        item.value !== 'quiz' &&
        item.value !== 'vital_activity' &&
        item.value !== 'webinar_details' &&
        item.value !== 'individual_quiz'
      ) {
        internalUrl.push(item);
      }
    });
    setInternalURL(internalUrl);

    if (params.id) {
      const resp = await getStaticWidgetLocalisationDetails(
        params.id,
        activeTab?.short_name
      );
      let staticWidgetDetails = resp;
      if (activeTab?.name === 'English') {
        setState({
          widget_name: staticWidgetDetails.widget_name,
          url_type: staticWidgetDetails.url_type
            ? staticWidgetDetails.url_type
            : '',
          url: staticWidgetDetails.url ? staticWidgetDetails.url : '',
          internal_for: staticWidgetDetails.internal_for
            ? staticWidgetDetails.internal_for
            : '',
          internal_url: staticWidgetDetails.internal_url
            ? staticWidgetDetails.internal_url
            : '',
          profile_data: staticWidgetDetails.profile_data
            ? staticWidgetDetails.profile_data
            : '',
          custom_object: staticWidgetDetails.custom_object
            ? staticWidgetDetails.custom_object
            : '',
          redirection_id: staticWidgetDetails.redirection_id
            ? staticWidgetDetails.redirection_id
            : '',
          detail_cta_text: staticWidgetDetails.detail_cta_text
            ? staticWidgetDetails.detail_cta_text
            : '',
          cohort_tags: staticWidgetDetails.cohort_tags
            ? staticWidgetDetails.cohort_tags
            : [],
          age_tags: staticWidgetDetails.age_tags
            ? staticWidgetDetails.age_tags
            : [],
          widget_id: staticWidgetDetails.widget_id
            ? staticWidgetDetails.widget_id
            : '',
        });
      } else {
        setState((prev) => ({
          ...prev,
          widget_name: staticWidgetDetails.widget_name
            ? staticWidgetDetails.widget_name
            : '',
        }));
      }
    }
  };

  const getDropdown = async () => {
    setLoader(true);
    const dataArr = await getWidgetType();
    let tempAgeArray = [];
    let tempCohortArray = [];

    dataArr.data.age_tags.map((item, i) => {
      tempAgeArray.push({
        name: item,
        value: item,
      });
    });
    dataArr.data.cohort_tags.map((item, i) => {
      tempCohortArray.push({
        name: item,
        value: item,
      });
    });
    setCohortOptions(tempCohortArray);
    setAgeOptions(tempAgeArray);
    setLoader(false);
  };
  const handleTextChange = (e, index) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    setLoader(true);
    let payload = {
      ...state,
    };
    let responseObj;
    if (params.id) {
      payload.widget_id = params.id;
      if (activeTab?.name === 'English') {
        responseObj = await updateStaticWidget(payload);
      } else {
        responseObj = await updateStaticWidgetLocalisation(
          payload,
          activeTab?.short_name
        );
      }
    } else {
      // responseObj = await addWidget(payload, thumbnail_image)
    }

    if (
      responseObj &&
      responseObj.response &&
      responseObj.response.code === 200
    ) {
      Toast.success(responseObj.response.alert[0].message);
      history.push('/static-widgets');
    } else {
      setLoader(false);
      Toast.error(
        responseObj.data && responseObj.data.msg
          ? responseObj.data.msg
          : responseObj.response.alert[0].message
      );
    }
  };

  const handleTabChange = (val) => {
    setActiveTab(val);
    if (val?.name === 'English') {
      setDisableFields(false);
    } else {
      setDisableFields(true);
    }
  };

  return (
    <div className='container'>
      <div className='cms-content__container'>
        <h1 className='section-header__title'>
        Landing pages \Static Widgets \ Edit Widget
        </h1>
        {params?.id ? (
          <div className='col-md-4 d-flex'>
            <b>Select Language</b>
          </div>
        ) : null}
        {params?.id ? (
          <TabBar
            tabs={LanguageOptions}
            initialTab={activeTab}
            onTabChange={handleTabChange}
            parentStyle={'service-tab-style'}
          />
        ) : null}
        <div className='cms-content__container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='userInput'>
                <NewInput
                  label='Widget Name/Title'
                  name={'widget_name'}
                  value={state.widget_name}
                  onChange={handleTextChange}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-4'>
              <MultiSelectDropdown
                label='Cohort Tag'
                name='cohort_tags'
                value={state.cohort_tags}
                options={cohortOptions}
                onChange={handleTextChange}
                tag_type={false}
                disable={disableFields}
              />
            </div>
            <div className='col-md-4'>
              <MultiSelectDropdown
                label='Age Tag'
                name='age_tags'
                value={state.age_tags}
                options={ageOptions}
                onChange={handleTextChange}
                tag_type={false}
                disable={disableFields}
              />
            </div>
          </div>
          <div className='row'>
            <div className='userFormArea'>
              <label>Redirection Type</label>
              <CTAType
                cta_type={state.url_type}
                handleChange={(key, data) => {
                  if (key === 'cta_type') {
                    if(!data ){
                      setState({ ...state, url_type: data, url:'' });
                    }else{
                      setState({ ...state, url_type: data });
                    } 
                  } else {
                    if (
                      key === 'url' &&
                      (data !== 'zoom_live_session' ||
                        data !== 'infinite_scroll' ||
                        data !== 'group_of_collection')
                    ) {
                      setState({ ...state, [key]: data, redirection_id: '' });
                    } else {
                      setState({ ...state, [key]: data });
                    }
                  }
                }}
                internalUrl={DropdownOptions.InternalUrl}
                url={state.url}
                handleTextChange={handleTextChange}
                internal_for={state.internal_for}
                internal_url={state.internal_url}
                profile_data={state.profile_data}
                custom_object={state.custom_object}
                redirection_id={state.redirection_id}
                isDisabled={disableFields}
              />
            </div>
          </div>
          <div className='row'>
            <div className='userFormArea mt-5'>
              <Button name={'Save'} onClick={handleSubmit} />
              <label
                style={{ paddingLeft: '10px', cursor: 'pointer' }}
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancel
              </label>
            </div>
          </div>
        </div>
      </div>
      {loader && <PageLoader pageLoading={loader} />}
    </div>
  );
};

export default CreateStaticWidgets;
