import React from 'react';
// Pages
import BasePage from 'pages/BasePage';
import { ServicesWidgetsTableHeader } from 'constants/table-header-constants';
import CreateService from 'app/container/Services/CreateService';

const ServicesWidget = () => {
  return (
    <BasePage>
      <CreateService tableHeader={ServicesWidgetsTableHeader} />
      {/* <Services tableHeader={ServicesWidgetsTableHeader} /> */}
    </BasePage>
  );
};

export default ServicesWidget;
