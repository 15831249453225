import { useRef, useState } from 'react';

// Components
import HeaderWithBackButton from 'components/HeaderWithBackButton';
import PageLoader from 'components/PageLoader';

// API

// Constants
import { uploadABTestingData } from 'app/api/latest/AbTesting/abTesting';
import Button from 'app/components/button';
import Message from 'app/utils/Message';
import Toast from 'app/utils/Toast';
import { APP_CONSTANTS } from 'constants/app-constants';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import fileTest from 'app/constants/tableConvert.com_xbcjpg.csv';
// Utils

const UploadAbTesting = () => {
  const imageRef = useRef();
  const [fileName, setFileName] = useState(null);
  let fileUrl = fileTest;
  const [pageLoading, setPageLoading] = useState(false);

  const updateImageDisplay = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const csvFile = event.target.files[0];
      if (csvFile?.name === fileName?.name) {
        Toast.error('CSV with same name already exists. ');
        return;
      } else {
        setFileName(csvFile?.name);
        callUploadAPI(csvFile);
      }
    }
  };

  const callUploadAPI = async (attachment) => {
    setPageLoading(true);
    if (attachment) {
      let response = await uploadABTestingData({ csv_file: attachment });
      setPageLoading(false);
      if (response?.response?.code === 201) {
        Toast.success(
          'CSV has been uploaded successfully and free plan gets activated'
        );
      } else {
        Toast.error(
          response?.data?.error
            ? response?.data?.error
            : Message.Error.UPLOAD_FAILURE
        );
      }
    }
  };

  return (
    <div className='blogsForm'>
      <div className='blogsForm_content'>
        <HeaderWithBackButton
          title={'Upload CSV'}
          goTo={APP_CONSTANTS.CMS_VITALS_AB_TESTING}
        />
        <div className='upload_note'>
          Note:
          <ul>
            <li>
              {' '}
              Users should be active for the phone numbers that you are going to
              upload from CSV.{' '}
            </li>
            <li>
              {' '}
              Please make sure profile details are properly filled and complete
              for the phone numbers that you are going to upload from CSV.
            </li>{' '}
            <li>
              {' '}
              Make sure free plan has been configured and active from CMS.
            </li>
            <li>
              {' '}
              Make Sure no free plan has been assigned earlier for the phone
              number that you are going to upload from CSV.
            </li>{' '}
            <li>
              Make Sure that all the numbers provided in the csv are unique and
              also should not added in previous uploaded files.
            </li>{' '}
          </ul>
        </div>
        <div>
          <input
            ref={imageRef}
            id='file'
            type='file'
            style={{ display: 'none' }}
            accept='.csv'
            onChange={updateImageDisplay}
            // onchange="file_changed(this)"
            onClick={(event) => (event.target.value = '')}
          />
          <Button
            htmlFor='file'
            name='Upload CSV'
            onClick={(e) => {
              if (imageRef && imageRef.current) {
                imageRef.current.click && imageRef.current.click();
              }
            }}
          />

          <Link
            to={fileUrl}
            className='ms-1 button text-decoration-none'
            download='AB_Testing_CSV'
            target='_blank'
            rel='noreferrer'
          >
            Download CSV
          </Link>
        </div>
      </div>
      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </div>
  );
};

export default UploadAbTesting;
