import { useHistory } from 'react-router';
// import '../index.scss';
import moment from 'moment';
import Toggle from 'react-toggle';

function CustomTable({
  data,
  tableHeader,
  handleDelete,
  handleInputChangeHandler,
  handleEdit,
  onchange
}) {
  const history = useHistory();

//  console.log(data)

  return (
    <table>
      <tr>
        {tableHeader.map((item, i) => {
          return <th className='nowrap text-center'>{item.Header}</th>;
        })}
      </tr>

      {data?.map((items, indx) => (
        <tr key={indx}>
          <td>
            <p>
              <span className='text-primary'>{items.CommType}</span>
            </p>
          </td>
          <td>
          <Toggle icons={false} checked={items.toggle}	 onChange={(e) => onchange(e,items.CommType,items.var_name)} />
          </td>
        </tr>
      ))}
    </table>
  );
}

export default CustomTable;
