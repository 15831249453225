import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import Loader from 'components/Loader';
import ArticleComponent from 'components/ArticleComponent';
import NewTable from 'components/NewTable';
import PageLoader from 'components/PageLoader';
import ArticleModal from 'components/ArticleModal';
import useContentForm from 'components/useContentForm';
import validate from 'components/ValidateInfo';

// API
// import {
// getFeed,
// publishOrUnpublishFeed,
// deleteFeed,
// getActiveUsers,
// } from 'api/feed';

import {
  deleteWebinar,
  getWebinar,
  publishOrUnpublishWebinar,
} from 'api/webinar';

// Constants
import { APP_CONSTANTS } from 'constants/app-constants';

// Utils
import Toast from 'utils/Toast';
import Message from 'utils/Message';

// import './index.scss';

const WebinarList = ({ tableHeader }) => {
  const history = useHistory();
  const [data, setData] = useState({});
  const [value, setValue] = useState('');
  const [select, setSelect] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [feedSummary, setFeedSummary] = useState({
    totalArticle: 0,
    inActiveArticle: 0,
    activeArticle: 0,
  });

  // useEffect(() => {
  //   callActiveUser();
  // }, []);

  const onChange = (e) => {
    setValue(e.value);
    console.log('hi', e.value);
    const data = {
      filters: {
        user_name: e.value,
      },
    };
    loadNames(0, data);
    setFilterData({
      filters: {
        user_name: e.value,
      },
    });
  };

  const [CopySuccess, setCopySuccess] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const [filterData, setFilterData] = useState({});

  const { handleChange, values, error, resetValues } = useContentForm(validate);

  // debugger;
  const loadNames = (start, filterPayload) => {
    setLoading(true);
    getWebinar(start, filterPayload).then((response) => {
      if (response && response.tableData && response.error === false) {
        console.log(response.tableData, 'responsedata');
        setData({
          tableData: response.tableData,
        });
        setFeedSummary(response.summary);
      } else {
        if (response.message) {
          Toast.error(response.message);
        } else {
          Toast.error(Message.Error.COMMON_MESSAGE);
        }
      }
      setLoading(false);
    });
  };

  const handleResponseCallback = (response) => {
    if (response && response.tableData && response.error === false) {
      setFeedSummary(response.summary);
    }
  };

  const callDeleteAPI = async (data) => {
    setPageLoading(true);
    if (data && data.webinar_id) {
      let responseObj = await deleteWebinar(data.webinar_id);
      setPageLoading(false);
      if (
        responseObj &&
        responseObj.response &&
        responseObj.response.code === 200
      ) {
        loadNames();
        Toast.success(Message.Feed.DELETE_SUCCESS);
      }
    } else {
      Toast.error(Message.Feed.DELETE_FAILURE);
    }
  };

  // const ShareLink = async (copyMe) => {
  //   console.log('click');
  //   try {
  //     await navigator.clipboard.readText(copyMe.share_link);
  //     alert('Link Copied!');
  //   } catch (err) {
  //     alert('Failed to copy!');
  //   }
  // };

  const ShareLink = (copyMe) => {
    var textField = document.createElement('textarea');
    textField.innerText = copyMe.share_link;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    alert('link copied to clipboard');
    textField.remove();
  };

  const callPublishOrUnpublishAPI = async (data) => {
    // console.log(data, 'articledata');
    setPageLoading(true);
    if (data && data.webinar_id && data.status) {
      let responseObj = await publishOrUnpublishWebinar(
        data.webinar_id,
        data.status
      );
      setPageLoading(false);
      if (
        responseObj &&
        responseObj.response &&
        responseObj.response.code === 200
      ) {
        loadNames();
        let message = Message.Webinar.PUBLISH_UNPUBLISH_SUCCESS.replace(
          '{{placeholder}}',
          data.status.toLowerCase() === 'active' ? 'Unpublished' : 'Published'
        );
        Toast.success(message);
      }
    } else {
      let message = Message.Webinar.PUBLISH_UNPUBLISH_FAILURE.replace(
        '{{placeholder}}',
        data.status.toLowerCase() === 'active' ? 'Unpublish' : 'Publish'
      );
      Toast.error(message);
    }
  };

  const menuActionObj = [
    {
      name: 'Edit',
      onClick: (e, data) => {
        console.log('Edit Clicked: ', data);
        history.push({
          pathname: `/webinar/edit/${data.webinar_id}`,
          state: {
            ...data,
          },
        });
      },
    },
    {
      name: 'Publish',
      type: 'publish',
      onClick: (e, data) => {
        console.log('Publish/Unpublish Clicked: ', data);
        callPublishOrUnpublishAPI(data);
      },
    },
    {
      name: 'Delete',
      onClick: (e, data) => {
        console.log('Delete Clicked: ', data);
        let text = 'are you sure,want to delete';
        if (window.confirm(text) == true) {
          callDeleteAPI(data);
          // text = 'You pressed OK!';
        } else {
          // text = 'You canceled!';
        }
      },
    },
  ];

  if (loading === true) {
    return <Loader />;
  }

  const sectionObj = {
    sectionTitle: 'Webinar List',
    // searchObj: {
    //   placeholder: 'Search',
    //   handleSearch: (e) => {
    //     console.log('Handle search: ', e);
    //   },
    // },
    // dropObj: {
    //   placeholder: 'Active users',
    //   options: select,
    //   onChange: onChange,
    //   value: value,
    // },
    buttons: [
      //   {
      //     title: 'Filter',
      //     children: () => {
      //       return <i class='fa fa-filter mlp5' />;
      //     },
      //     handleBtnClick: (e) => {
      //       // debugger;
      //       setShowFilter(true);
      //     },
      //   },
      {
        title: 'Create a Webinar',
        handleBtnClick: (e) => {
          history.push(APP_CONSTANTS.CREATE_WEBINAR);
        },
      },
    ],
  };

  const handleModalCloseClicked = () => {
    setShowFilter(false);
  };

  // if (data && data.tableData) {
  return (
    <div className='blogs'>
      {/* <div className='blogs_container'>
        <ArticleComponent number={feedSummary.totalArticle} text='Total feed' />
        <ArticleComponent
          number={feedSummary.activeArticle}
          text='Active feed'
        />
        <ArticleComponent
          number={feedSummary.inActiveArticle}
          text='Inactive feed'
        />
      </div> */}

      <NewTable
        tableHeader={tableHeader}
        data={data}
        menuAction={menuActionObj}
        sectionObj={sectionObj}
        getData={getWebinar}
        isHide={true}
        searchKey='title'
        // filterData={filterData}
        ShareLink={ShareLink}
        responseCallback={handleResponseCallback}
      />
      {pageLoading === true && <PageLoader pageLoading={pageLoading} />}
    </div>
  );
};

export default WebinarList;
