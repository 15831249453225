import { getTagsDataApi } from "app/api/cmsTagsApi";
import Button from "app/components/button";
import Search from "app/components/search";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import Pagination from "../CmsContent/pagination";
import SelectComp from "../CmsContent/select-tag";
import TagsTable from "./components/tags-table";
import './index.scss';

const options = [
     { value: 5, label: 5, },
     { value: 10, label: 10, },
     { value: 15, label: 15, },
     { value: 20, label: 20, },
     { value: 25, label: 25, },
]
const CmsTagsTable = () => {
     const history = useHistory();
     const location = useLocation()
     const [tagsCollection, setTagsCollection] = useState([]);
     const [isLoading, setIsLoading] = useState(false);
     const [pageCount, setPageCount] = useState(1);
     const [dataPerPage, setDataPerPage] = useState(5);
     const [currentPage, setCurrentPage] = useState(location.state?.goto || 1);
     const [searchValue, setSearchValue] = useState('')
     console.log(location.state)
     const indexOfLastData = currentPage * dataPerPage;
     const indexOfFirstData = indexOfLastData - dataPerPage;
     let currentData = tagsCollection.slice(indexOfFirstData, indexOfLastData)

     useEffect(() => {
          handlesGetTags()
          window.history.replaceState({}, document.title)
     }, []);


     useEffect(() => {
          getTagsHandler(searchValue)
     }, [searchValue]);


     function inputChangeHandler(searchKey) {
          setSearchValue(searchKey)
     }

     async function handlesGetTags() {
          const tags = await getTagsDataApi();
          let count = Math.ceil(tags.length / dataPerPage);
          setTagsCollection(tags);
          setPageCount(count)


     }

     async function getTagsHandler(search) {
          let count;
          if (search !== "") {
               const tags = await getTagsDataApi();
               let filteredTags = []
               tags.forEach((item) => {
                    if (item.tag_name.toLowerCase().includes(search) || item.formatted_tag.includes(search)) {
                         filteredTags.push(item);
                    }
               });
               count = Math.ceil(filteredTags.length / dataPerPage);
               setTagsCollection(filteredTags)
               setPageCount(count)


          } else {
               handlesGetTags();
          }
     }

     // Pagination
     function paginationHandler(page) {
          setCurrentPage(page);
     }
     function changeHandler(event) {
          const pageLength = event.target.value
          setDataPerPage(pageLength);
          let count = Math.ceil(tagsCollection.length / pageLength);
          setPageCount(count)
     }

     return (
          <div className="cms_tags_table_container">
               <h4>Tags</h4>
               <div className="cms_tags_table_container__actionBox">
                    <div className="cms_tags_table_container__filterSearch">
                         <Search
                              value={searchValue}
                              placeholder="Search"
                              className={""}
                              onChange={inputChangeHandler}
                         />
                         {/* <Button>Filter</Button> */}
                    </div>
                    <Button onClick={() => history.push('/cms/tags/create')} >Create Tag</Button>
               </div>
               <div className="cms_tags_table_container__items_perPage">
                    Show
                    <SelectComp
                         options={options}
                         onChangeHandler={changeHandler}
                    />
                    entries
               </div>
               <TagsTable
                    tags={currentData}
                    onGetTagsHandler={handlesGetTags}
                    currentPage={currentPage}
               />
               <div className="cms_tags_table_container__pagination">
                    <Pagination
                         pages={pageCount}
                         onChange={paginationHandler}
                         pageNumber={currentPage}
                    />
               </div>
          </div>);
}

export default CmsTagsTable;