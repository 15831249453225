import { useEffect, useRef, useState } from 'react';

// Components

// Utils
import { newimageSizeValidation } from 'utils/Parsers';

// Constants
import { AttachmentIcon, CloseBlackIcon } from 'constants/image-constants';

import Toast from 'app/utils/Toast';
import './index.scss';

const NewAttachment = ({
  onFileSelected,
  value,
  id,
  label,
  fileType,
  className,
  onVideoThumbnail,
  videoThumbnail,
  sizevalidationUpto = null,
  asterisk = false,
}) => {
  const imageRef = useRef();
  const videoRef = useRef();
  const [selImage, setSelImage] = useState(null);
  // const [selVideo, setSelVideo] = useState(null);
  // const [selVideoThumbnail, setSelVideoThumbnail] = useState(null);
  // const [showActionPopup, setShowActionPopup] = useState(null);

  useEffect(() => {
    let val = value ? `${process.env.REACT_APP_S3_BUCKET_URL}/${value}` : null;
    // console.log('link value', val);
    // let extension = Parsers.GetURLExtension(val);

    setSelImage(val);
  }, [value]);

  const attachmentClicked = () => {
    if (fileType === 'image' || fileType === 'video') {
      onActionTriggered(fileType);
    }
  };

  const updateImageDisplay = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const imageFile = event.target.files[0];

      if (
        (imageFile.type === 'image/webp' ||
          imageFile.type === 'image/png' ||
          imageFile.type === 'image/jpeg' ||
          imageFile.type === 'image/jpg' ||
          imageFile.type === 'image/gif') &&
        newimageSizeValidation(
          event,
          sizevalidationUpto && sizevalidationUpto * 1000
        )
      ) {
        if (sizevalidationUpto) {
          Toast.error(
            `Please select Image UPTO ${sizevalidationUpto} KB JPG/PNG/WEBP`
          );
        } else {
          Toast.error('Please select Image UPTO 150 KB JPG/PNG/WEBP');
        }

        return;
      }

      if (
        imageFile.type === 'image/webp' ||
        imageFile.type === 'image/png' ||
        imageFile.type === 'image/jpeg' ||
        imageFile.type === 'image/jpg' ||
        imageFile.type === 'image/gif'
      ) {
        onFileSelected && onFileSelected(imageFile, id);
        var reader = new FileReader();
        reader.onloadend = function () {
          setSelImage(reader.result);
        };
        reader.readAsDataURL(imageFile);
      } else {
        onFileSelected && onFileSelected(imageFile, id);
      }
    }
  };

  const handleRemove = () => {
    setSelImage(null);

    onVideoThumbnail && onVideoThumbnail(null, id);
    onFileSelected && onFileSelected(null, id);
  };

  const onActionTriggered = (type) => {
    if (type === 'image') {
      // setSelVideo(null);
      if (imageRef && imageRef.current) {
        imageRef.current.click && imageRef.current.click();
      }
    } else if (type === 'video') {
      setSelImage(null);
      if (videoRef && videoRef.current) {
        videoRef.current.click && videoRef.current.click();
      }
    }
  };

  const RenderImage = ({ children }) => {
    return (
      <div className='newattachment_img_wrapper'>
        {children}
        <div className='attachment_img_wrapper_close'>
          <img src={CloseBlackIcon} alt='Remove' onClick={handleRemove} />
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className='newattachment'>
        <img src={AttachmentIcon} alt='Add Attachment' width='25' height='25' />
        <p className='newattachment_text' onClick={attachmentClicked}>
          {label ? label : 'Add an attachment'}{' '}
          <span className='newjpgsizelabel'>
            `(JPG/PNG/WEBP) under{' '}
            {sizevalidationUpto ? sizevalidationUpto : '150'} kb
          </span>
          {asterisk && <span className='asterisk'>*</span>}
        </p>
        <input
          ref={imageRef}
          type='file'
          id={id ? id : 'file'}
          accept='.jpg, .jpeg, .png, .gif'
          className='newattachment__input-file'
          onChange={(e) => updateImageDisplay(e)}
        />
      </div>
      {selImage && (
        <RenderImage>
          <img src={selImage} alt='sample' className={className} />
        </RenderImage>
      )}
    </div>
  );
};

export default NewAttachment;
