import { useHistory } from 'react-router';
import './index.scss';

function CustomTable({ data, tableHeader, startIndex }) {
  const history = useHistory();

  const toTitleCase = (str) => {
    return str
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <table>
      <tr>
        {tableHeader.map((item, i) => {
          return <th className='nowrap text-center'>{item.Header}</th>;
        })}
      </tr>
      {data &&
        data.map((items, idx) => (
          <tr key={idx}>
            <td>{startIndex + idx + 1}</td>
            <td>{items.module_name}</td>
            <td>{items.variant}</td>
            <td>{items.user_type}</td>
            <td className='text-capitalize'>
              {toTitleCase(items.filter_data.join(', '))}
            </td>
            <td>{items.published_date}</td>
            <td>{items.updated_date}</td>
            <td>{items.status}</td>
            <td
              className='text-primary text-decoration-underline cursor-pointer'
              onClick={() => {
                history.push({
                  pathname: `/vitals/create-ab-testing/edit/${items.id}`,
                });
              }}
            >
              Manage Tests
            </td>
          </tr>
        ))}
      {data.length === 0 && (
        <tr>
          <td colspan='10000'>No data found</td>{' '}
        </tr>
      )}
    </table>
  );
}

export default CustomTable;
