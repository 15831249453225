import React from 'react';

// Pages
import BasePage from 'pages/BasePage'; 
import UserJourneyList from 'container/UserJourney';
import { UserJourneyHeader } from 'constants/table-header-constants';

const UserJourney = () => {
  return (
    <BasePage>
      <UserJourneyList tableHeader={UserJourneyHeader}/>
    </BasePage>
  );
};

export default UserJourney;
