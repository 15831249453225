import React, { useEffect, useState } from 'react';
import Button from 'app/components/button';
import CustomTable from './table';
import { useHistory } from 'react-router-dom';
import Loader from 'components/Loader';
import {
  getLandingPageListScreen,
  deleteLandingPageById,
  updateLandingPageScreen
} from 'app/api/widgets';
import Toast from 'app/utils/Toast'; 

import Pagination from 'app/components/ReactPagination';
const ListingScreens = (tableHeader) => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [toggle, setToggle] = useState(null);

  useEffect(() => {
    getContentDataHandler();
  }, []);
  function paginationHandler(page) {
    setCurrentPage(page);
  }
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  let currentData = data.slice(indexOfFirstData, indexOfLastData);

  async function getContentDataHandler() {
    let dataArr;
    setLoader(true);
    dataArr = await getLandingPageListScreen();
    setLoader(false);
    if (dataArr && dataArr.response && dataArr.response.code === 200) {
      if (dataArr.data) {
        setTotalData(dataArr.data);
        setData(dataArr.data);
        let count = Math.ceil(dataArr.data.length / dataPerPage);
        setPageCount(count);
      }

      // Toast.success(dataArr.response.alert[0].message);
      setLoader(false);
    } else {
      setLoader(false);
      Toast.error(
        dataArr.data && dataArr.data.msg
          ? dataArr.data.msg
          : dataArr.response.alert[0].message
      );
    }
  }

  const handleDelete = async (data) => {
    let text = 'Are you sure, want to delete';
    if (window.confirm(text) == true) { 
      setLoader(true);
      await deleteLandingPageById(data);
      getContentDataHandler();
    }
  };

  const handleUnpublish = async(data) => {
    let text = `Are you sure, want to ${data.status === "ACTIVE"? "Unpublish":"Publish"}`;
    if (window.confirm(text) == true) {
        if(data){
            setLoader(true)
            // let thumbnail_image = data.thumbnail_path
            let payload = { 
               ...data,
                status: data.status === "ACTIVE"? "INACTIVE": "ACTIVE", 
            } 
           let responseObj = await updateLandingPageScreen(payload, data.landing_page_id);
            
    
            if (
                responseObj &&
                responseObj.response &&
                responseObj.response.code === 200
            ) {
                Toast.success(responseObj.response.alert[0].message);
                getContentDataHandler()
    
            } else {
                setLoader(false)
                Toast.error(responseObj.data?responseObj.data.msg: responseObj.response.alert[0].message?responseObj.response.alert[0].message : '');
            }
            setLoader(false)
        }
    } 
  }
   

  if (loader === true) {
    return <Loader />;
  }
  let pageMeta = {
    total: data,
    pageSize: Number(dataPerPage),
    page: currentPage,
    pageCount: pageCount,
  };
  return (
    <div className='cms-content__container'>
      <h4>Landing Pages \ Landing Pages Listing Screen</h4>
      <div className='content-container__actionBox'>
        <div className='content-container__filterSearch'></div>
        <Button
          onClick={() => history.push('/landing-page/create')}
          className='content-container__filter-btn'
        >
         Create a Page
        </Button>
      </div>

      <div className='content-container__table'>
        <CustomTable
          data={currentData}
          tableHeader={tableHeader.tableHeader}
          handleDelete={handleDelete}  
          toggle={toggle}
          setToggle={setToggle}
          handleUnpublish={handleUnpublish}
        />
      </div>
      <div className='content-container__pagination'>
        <Pagination
          pageMeta={pageMeta}
          initialPage={Number(pageCount)}
          handleChange={paginationHandler}
        />
        {/* <Pagination
                    pages={pageCount}
                    onChange={paginationHandler}
                /> */}
      </div>
    </div>
  );
};

export default ListingScreens;
