import Button from 'app/components/button';
import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import PageLoader from 'app/components/PageLoader';
import NewInput from 'app/components/NewInput';
import Search from 'app/components/search';
import { imageSizeValidation } from 'app/utils/Parsers';
import ValidationMessage from 'app/utils/ValidationMessage';
import Message from 'app/utils/Message';
import Toast from 'app/utils/Toast';
import { CloseBlackIcon } from 'app/constants/image-constants'; 
import Dropdown from 'app/shared/components/Dropdown';
import Modal from 'react-bootstrap/Modal';
import Checkbox from 'components/checkbox';
import {
  searchMedicine,
  getDeliveryAddress,
  setAddress,
  addNewAddress,
  medicineDetail,
  medicineCheckOut,
  getPrescriptionList,
  prescriptionIsForAll,
  uploadPrescription,
  deletePrescription
} from 'app/api/latest/CustomerOrders/customerorders';
import Plus from 'images/plus.png';
import CustomTable from '../SelectedMedicineTable/table';
import { CustomerOrderMedicineHeader } from 'app/constants/table-header-constants';
import HeaderWithBackButton from 'app/components/HeaderWithBackButton';
import { APP_CONSTANTS } from 'app/constants/app-constants';

const CreateOrderContainer = () => {
  const location = useLocation();
  let history = useHistory();
  const params = useParams();
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState({
    name: '',
    phone_no: '',
    alternative_mobile: '',
    email: '',
    doctor_name: '',
    upload_prescription: '',
    selected_medicines: [],
  });

  const initialAddress = {
    address_line_1: '',
    address_line_2: '',
    address_line_3: '',
    postal_code: '',
    city: '',
    state: '',
    address_type: 'HOME',
    selected_address: '',
  };

  const [addressDetails, setAddressDetails] = useState(initialAddress);

  const address_typeList = [
    {
      label: 'HOME',
      name: 'HOME',
      id: 'HOME',
      value: 'HOME',
    },
    {
      label: 'WORK',
      name: 'WORK',
      id: 'WORK',
      value: 'WORK',
    },
    {
      label: 'OTHER',
      name: 'OTHER',
      id: 'OTHER',
      value: 'OTHER',
    },
  ];
 
  const [productKeyword, setProductKeyword] = useState('');
  const [noProductStatus, setNoProductStatus] = useState(false);
  const [searchProductList, setSearchProductList] = useState([]);
  const [productSearchLoader, setProductSearchLoader] = useState(false);
  const [data, setData] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [prescriptionDetails, setPrescriptionDetails] = useState();
  const [user_id, setUserid] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isDisabled, setIsDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const [isCheckBoxActive, setIsCheckBoxActive] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleAddAddress = () => {
    setAddressDetails(initialAddress);
    setShow(true);
  };

  const handleTextChange = async (e, index) => {
    if (e.target.name === 'phone_no') {
      setState({ ...state, [e.target.name]: e.target.value });
      if (e.target.value && e.target.value.length === 10) {
        let addressdata = await getaddressList(e.target.value);
        let userdetails = addressdata.user_details;
        let cartdetails = addressdata.cart_details
          ? addressdata.cart_details
          : '';
        let prescriptionList = cartdetails && cartdetails.prescriptions; 
        if(prescriptionList && prescriptionList.attachments.length>0){
          setIsCheckBoxActive(prescriptionList?.attachments[0].is_for_all)
        }
        setPrescriptionDetails(prescriptionList);
        if (cartdetails) {
          let productdetails = cartdetails.products;
          let temMedicine = [];
          productdetails.map((item, i) => {
            const newdata = {
              id: item.id,
              medicine_name: item.name,
              medicine_id: item.id,
              discount: item?.discount ? item?.discount : 0,
              updated_by: item?.updated_by ? item?.updated_by : '',
              isDisabled: true,
              isNew: true,
              mrp: item.mrp ? item.mrp : 0,
              discounted_price: item.discounted_price
                ? item.discounted_price
                : 0,
              qty: item.quantity,
              total_amount: item.discounted_price
                ? item.discounted_price * item.quantity
                : 0,
              packfrom: item.mfr ? item.mfr : '',
              packqty: item.pack_quantity ? item.pack_quantity : '',
            };
            temMedicine.push(newdata);
          });
          setData(temMedicine);
        }

        // setCartDetails(addressdata.cart_details)
        if (addressdata && addressdata.address_list) {
          let addresslist = [];
          addressdata.address_list.map((item, i) => {
            addresslist.push({
              name: `${item.address_line_1}, ${item.address_line_2}, ${item.address_line_3}, ${item.city}, ${item.state}, ${item.postal_code}`,
              label: `${item.address_line_1}, ${item.address_line_2}, ${item.address_line_3}, ${item.city}, ${item.state}, ${item.postal_code}`,
              id: item.id,
              value: item.id,
              ...item,
            });
          });
          setAddressList(addresslist.length > 0 ? addresslist : []);
          setAddressDetails({
            ...addressDetails,
            selected_address:
              addresslist.length > 0 ? addresslist[0].value : '',
          });

          if (addresslist.length > 0) {
            setCartAddress(
              addresslist[0].value,
              userdetails.uuid ? userdetails.uuid : ''
            );
          }
          if (addressdata.user_details) {
            setState({
              ...state,
              [e.target.name]: e.target.value,
              name: userdetails.name,
              email: userdetails.email,
            });
            setUserid(userdetails.uuid ? userdetails.uuid : '');
          } else {
            setState({ ...state, [e.target.name]: e.target.value });
          }
          setIsDisabled(false);
        } else {
          setAddressDetails(initialAddress);
          setAddressList([]);
          setIsDisabled(true);
          setUserid('');
        }
      }
    } else {
      setState({ ...state, [e.target.name]: e.target.value });
    }
  };

  const handleAddress = async (e) => {
    if (e.target.name === 'selected_address') {
      const found = addressList.find((add) => add.id === e.target.value);
      setAddressDetails({
        ...addressDetails,
        [e.target.name]: e.target.value,
      });
      await setCartAddress(found.id, user_id);

      setAddressDetails({ ...addressDetails, [e.target.name]: e.target.value });
    } else {
      setAddressDetails({ ...addressDetails, [e.target.name]: e.target.value });
    }
  };

  const getaddressList = async (data) => {
    setLoader(true);
    let response = {};
    let payload = {
      phone_no: data,
    };
    response = await getDeliveryAddress(payload);
    setLoader(false);
    if (response?.response?.code === 200) {
      return response?.data;
    } else {
      return response;
    }
  };
  // prescription list detsils api
  // const getPrescriptionListDetails = async (userid) => {
  //   setLoader(true);
  //   let response = {};
  //   let payload = {
  //     user_id: userid,
  //   };
  //   response = await getPrescriptionList(payload);
  //   setLoader(false);
  //   if (response?.response?.code === 200) {
  //     return response?.data;
  //   } else {
  //     return response;
  //   }
  // };

  const setCartAddress = async (data, userid) => {
    if (!userid) {
      Toast.error("User id can't Empty");
      return;
    }
    setLoader(true);
    let payload = {
      address_id: data,
      product_type: 'MEDICINE',
      user_id: userid,
    };
    await setAddress(payload);
    setLoader(false);
     
  };

   

  const handleMediaUpload = async (e) => {
    let file = e.target.files[0];
    if (
      (file.type === 'image/jpeg' ||
        file.type === 'image/jpg' ||
        file.type === 'image/png') &&
      imageSizeValidation(e)
    ) {
      Toast.error(ValidationMessage.IMAGE_SIZE);
      return;
    } else {
      let payload = {
        prescription_file: file,
        user_id:user_id
      }
      let responseObj = await uploadPrescription(payload); 
      if(responseObj&& responseObj.response.code === 201){
        if(responseObj?.data){
          let prescriptionattachment = [...prescriptionDetails.attachments]  
          prescriptionattachment.push(responseObj?.data) 
          setPrescriptionDetails({
            ...prescriptionDetails, attachments:prescriptionattachment
          })
        }
      }  
      Toast.success(file?.name + ' uploaded');
    }
  };

  const productSearchHandler = async (searchVal) => {
    setProductKeyword(searchVal.toLowerCase());

    if (searchVal.trim() === '') {
      setNoProductStatus(false);
      setSearchProductList([]);

      return;
    }
    let response = {};
    setProductSearchLoader(true);

    response = await searchMedicine(searchVal, 'MEDICINE'); 
    setProductSearchLoader(false);
    if (response?.response?.code === 200) {
      if (response?.data?.results?.length === 0) {
        setNoProductStatus(true);
      } else {
        setNoProductStatus(false);
        let searcharray = response?.data.results.slice(0, 10);
        let tempData = [];
        searcharray.forEach((searchItem, searchindex) => {
          searchItem['alreadyStatus'] = false;
          data.forEach((dataItem, dataindex) => {
            if (searchItem.id === dataItem.id) {
              searchItem['alreadyStatus'] = true;
              return;
            }
          });
          tempData.push(searchItem);
        });

        setSearchProductList(tempData);
      }
    } else {
      Toast.error(
        response.data.error ? response.data.error : Message.Error.COMMON_MESSAGE
      );
    }
  };

  const productAddinList = async (item) => {
    let temp = [...data];
    let meddetails = {};
    meddetails = await medicineDetail(productKeyword, item.id);
    const newdata = {
      id: item.id,
      ranking: temp.length + 1,
      medicine_name: item.medicine_name ? item.medicine_name : item.name,
      medicine_id: item.sku_id ? item?.sku_id : item?.test_id,
      discount: item?.discount ? item?.discount : 0,
      updated_by: item?.updated_by ? item?.updated_by : '',
      isDisabled: true,
      isNew: true,
      mrp: item.mrp ? item.mrp : 0,
      discounted_price: item.discounted_price ? item.discounted_price : 0,
      qty: 1,
      total_amount: item.discounted_price ? item.discounted_price : 0,
      packfrom: meddetails.data.pack_form ? meddetails.data.pack_form : '',
      packqty: meddetails.data.pack_quantity
        ? meddetails.data.pack_quantity
        : '',
    };

    temp.push(newdata);
    setProductKeyword('');
    setData(temp);
    setSearchProductList([]);
  };

  const handleInputChangeHandler = (key, e, indexnew) => {
    let index = (currentPage - 1) * 10 + indexnew;
    let tempData = [];

    data.forEach((val, i) => {
      if (index === i) {
        let temp = val;
        val[key] = Number(e.target.value)
          ? Number(e.target.value) == 0
            ? 1
            : Number(e.target.value)
          : 1;
        val['total_amount'] = val.discounted_price * val.qty;
        tempData.push(temp);
      } else {
        tempData.push(val);
      }
    });
    setData(tempData);
  };

  const handleDelete = async (key, indexnew) => {
    let index = (currentPage - 1) * 10 + indexnew;
    let tempData = [];
    await data.forEach((val, i) => {
      if (index === i) {
        return;
      }
      if (val.isNew === true) {
        let temp = val;
        val[key] = val.ranking - 1;
        tempData.push(temp);
      } else {
        tempData.push(val);
      }
    });

    setData(tempData);
  };

  const handleSubmit = async () => {
    setLoader(true);

    let medicine_details = [];
    data.map((item, i) => {
      medicine_details.push({
        medicine_id: item.id,
        quantity: item.qty,
      });
    });
    let payload = {
      user_id,
      medicine_details,
      prescriptions:prescriptionDetails
    };

    let response = {};
    response = await medicineCheckOut(payload);

    if (response?.response?.code === 201) {
      Toast.success(
        response & response.data
          ? response.data.error
          : response.response.alert[0].message
      );
      setLoader(false);
      history.push({
        pathname: `/customer-orders`,
      });
    } else {
      setLoader(false);
      Toast.error(
        response && response.data
          ? response.data.error
          : response.response.alert[0]
          ? response.response.alert[0].message
          : ''
      );
    }
  };

  const handleAddAddressSubmit = async () => {
    setLoader(true);
    let {
      address_line_1,
      address_line_2,
      address_line_3,
      city,
      postal_code,
      address_type,
    } = addressDetails;
    let response = {};
    let payload = {
      phone_no: state.phone_no,
      address_line_1,
      address_line_2,
      address_line_3,
      city,
      state: addressDetails.state,
      postal_code,
      address_type,
      user_id,
    };
    response = await addNewAddress(payload);
    handleClose();
    setLoader(false);
    if (response?.response?.code === 200) {
      Toast.success(
        response & response.data
          ? response.data.error
          : response.response.alert[0].message
      );
      getaddressList(state.phone_no);
    } else {
      Toast.error(
        response.data.error ? response.data.error : Message.Error.COMMON_MESSAGE
      );
    }
  };

  const handleCheckbox = async() => {
    setLoader(true);
    let payload = {
      prescription_uuid:prescriptionDetails.attachments[0].id,
      user_id:user_id,
      is_for_all: isCheckBoxActive?0:1
    }
    let responseobj = await prescriptionIsForAll(payload)
    if(responseobj?.response?.code === 200){
      Toast.success(responseobj?.data);
      setIsCheckBoxActive(!isCheckBoxActive);
      setLoader(false);
    } else{
      Toast.error(
        responseobj.data.error ? responseobj.data.error : Message.Error.COMMON_MESSAGE
      );
      setLoader(false);
    }
   
  };

  const handleDeletePrescription = async(index) => {
    setLoader(true);
    let payload = {
      prescription_uuid:prescriptionDetails.attachments[index].id,
      user_id:user_id, 
    }
    let responseobj = await deletePrescription(payload) 
    if(responseobj?.response?.code === 200 ){
      Toast.success(responseobj&&responseobj.data ? responseobj.data : '');
      let copyPrescriptionDetails = prescriptionDetails
       copyPrescriptionDetails.attachments.splice(index, 1) 
      setPrescriptionDetails(copyPrescriptionDetails)
      setLoader(false);
    } else if(responseobj?.response?.code === 204){
      Toast.success('Prescription Image deleted');
      let copyPrescriptionDetails = prescriptionDetails
      copyPrescriptionDetails.attachments.splice(index, 1) 
      setPrescriptionDetails(copyPrescriptionDetails)
      setLoader(false);
    }else{
      setLoader(false);
    }
  } 
 
  return (
    <div className='container-fluid'>
      <HeaderWithBackButton
        title='Customer Order/ Create Order'
        goTo={APP_CONSTANTS.CUSTOMER_ORDERS}
      />
      {/* <h1 className='section-header__title'>Customer Order/ Create Order</h1> */}
      <div className='cms-content__container'>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='separator'>
              <p>Customer Details</p>
            </div>
          </div>
          <div className='col-3'>
            <div className='userInput'>
              <NewInput
                type='number'
                label='Mobile'
                name={'phone_no'}
                value={state.phone_no}
                onChange={handleTextChange}
                asterisk={true}
              />
            </div>
          </div>
          <div className='col-3'>
            <div className='userInput'>
              <NewInput
                label='Full Name'
                name={'name'}
                value={state.name}
                onChange={handleTextChange}
                isDisabled={true}
              />
            </div>
          </div>

          {/* <div className='col-3'>
            <div className='userInput'>
              <NewInput
                label='Alternate Mobile'
                name={'alternative_mobile'}
                value={state.alternative_mobile}
                onChange={handleTextChange}
                isDisabled={true}
              />
            </div>
          </div> */}
          <div className='col-3'>
            <div className='userInput'>
              <NewInput
                label='Email'
                name={'email'}
                value={state.email}
                onChange={handleTextChange}
                isDisabled={true}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='separator'>
              <p>Address Details</p>
            </div>
          </div>
        </div>

        {addressList && addressList.length > 0 ? (
          <div className='row'>
            <div className='col-sm-6'>
              <Dropdown
                label='Select Address'
                placeholder='Selected Address'
                name='selected_address'
                value={addressDetails.selected_address}
                options={addressList}
                handleChange={(e) => {
                  handleAddress(e);
                }}
              />
            </div>
            <div className='col-sm-6'>
              <div className='mt-5'>
                <Button
                  name={'Add a New Address'}
                  onClick={() => {
                    handleAddAddress();
                  }}
                  type='button'
                />
              </div>
            </div>
          </div>
        ) : (
          <div className='row'>
            <div className='col-sm-4'>
              <div className='userInput'>
                <NewInput
                  label='House/Flat no. & Building'
                  name={'address_line_1'}
                  value={addressDetails.address_line_1}
                  onChange={handleAddress}
                  asterisk={true}
                  isDisabled={isDisabled}
                />
              </div>
            </div>
            <div className='col-sm-4'>
              <div className='userInput'>
                <NewInput
                  label='Street name and locality'
                  name={'address_line_2'}
                  value={addressDetails.address_line_2}
                  onChange={handleAddress}
                  asterisk={true}
                  isDisabled={isDisabled}
                />
              </div>
            </div>
            <div className='col-sm-4'>
              <div className='userInput'>
                <NewInput
                  label='Near by Landmark'
                  name={'address_line_3'}
                  value={addressDetails.address_line_3}
                  onChange={handleAddress}
                  asterisk={true}
                  isDisabled={isDisabled}
                />
              </div>
            </div>
            <div className='col-4'>
              <div className='userInput'>
                <NewInput
                  label='Pincode'
                  name={'postal_code'}
                  value={addressDetails.postal_code}
                  onChange={handleAddress}
                  asterisk={true}
                  isDisabled={isDisabled}
                />
              </div>
            </div>
            <div className='col-4'>
              <div className='userInput'>
                <NewInput
                  label='City'
                  name={'city'}
                  value={addressDetails.city}
                  onChange={handleAddress}
                  asterisk={true}
                  isDisabled={isDisabled}
                />
              </div>
            </div>
            <div className='col-4'>
              <div className='userInput'>
                <NewInput
                  label='State'
                  name={'state'}
                  value={addressDetails.state}
                  onChange={handleAddress}
                  asterisk={true}
                  isDisabled={isDisabled}
                />
              </div>
            </div>
            <div className='col-4'>
              <Dropdown
                label='Saved address as'
                placeholder='saved address as'
                name='address_type'
                value={addressDetails.address_type}
                options={address_typeList}
                handleChange={handleAddress}
                asterisk={true}
                isDisabled={isDisabled}
              />
            </div>
            <div className='col-sm-12 text-center my-4'>
              <Button
                isDisabled={
                  !addressDetails.postal_code ||
                  !addressDetails.state ||
                  !addressDetails.address_type ||
                  !addressDetails.address_line_1 ||
                  !user_id
                }
                name='Add Address'
                onClick={() => {
                  handleAddAddressSubmit();
                }}
              />
            </div>
          </div>
        )}
        <Modal
          show={show}
          onHide={handleClose}
          size='xl'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          ariaHideApp={false}
        >
          <Modal.Body>
            <div className='row'>
              <div className='col-sm-4'>
                <div className='userInput'>
                  <NewInput
                    label='House/Flat no. & Building'
                    name={'address_line_1'}
                    value={addressDetails.address_line_1}
                    onChange={handleAddress}
                    asterisk={true}
                  />
                </div>
              </div>
              <div className='col-sm-4'>
                <div className='userInput'>
                  <NewInput
                    label='Street name and locality'
                    name={'address_line_2'}
                    value={addressDetails.address_line_2}
                    onChange={handleAddress}
                    asterisk={true}
                  />
                </div>
              </div>
              <div className='col-sm-4'>
                <div className='userInput'>
                  <NewInput
                    label='Near by Landmark'
                    name={'address_line_3'}
                    value={addressDetails.address_line_3}
                    onChange={handleAddress}
                    asterisk={true}
                  />
                </div>
              </div>
              <div className='col-4'>
                <div className='userInput'>
                  <NewInput
                    label='Pincode'
                    name={'postal_code'}
                    value={addressDetails.postal_code}
                    onChange={handleAddress}
                    asterisk={true}
                  />
                </div>
              </div>
              <div className='col-4'>
                <div className='userInput'>
                  <NewInput
                    label='City'
                    name={'city'}
                    value={addressDetails.city}
                    onChange={handleAddress}
                    asterisk={true}
                  />
                </div>
              </div>
              <div className='col-4'>
                <div className='userInput'>
                  <NewInput
                    label='State'
                    name={'state'}
                    value={addressDetails.state}
                    onChange={handleAddress}
                    asterisk={true}
                  />
                </div>
              </div>
              <div className='col-4'>
                <Dropdown
                  label='Saved address as'
                  placeholder='saved address as'
                  name='address_type'
                  value={addressDetails.address_type}
                  options={address_typeList}
                  handleChange={handleAddress}
                  asterisk={true}
                />
              </div>
              <div className='col-sm-12 text-center my-4'>
                <Button
                  name='Add Address'
                  onClick={() => {
                    handleAddAddressSubmit();
                  }}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <div className='row'>
          <div className='col-sm-6'>
            <div className='top__productSearch'>
              <Search
                value={productKeyword}
                placeholder='Search'
                className={'categorySearchBar'}
                onChange={productSearchHandler}
              />
              {productSearchLoader ? (
                <div>
                  Loading<div className='productSearchLoader'></div>
                </div>
              ) : (
                <>
                  <div className='top__productSearch__dropdown'>
                    {searchProductList &&
                      searchProductList.length > 0 &&
                      searchProductList.map((item, index) => {
                        return (
                          <>
                            <div
                              key={index}
                              className={
                                item.alreadyStatus
                                  ? 'top__productSearch__dropdown__medicinename_already'
                                  : 'top__productSearch__dropdown__medicinename_new'
                              }
                              onClick={() => {
                                if (!item.alreadyStatus) {
                                  productAddinList(item);
                                }
                              }}
                            >
                              {' '}
                              <span>
                                {item.name} {item.alreadyStatus}
                              </span>
                              <span>
                                {!item.alreadyStatus && (
                                  <img
                                    src={Plus}
                                    alt='Go back'
                                    width='25'
                                    height='25'
                                  />
                                )}
                              </span>
                            </div>
                          </>
                        );
                      })}
                    {searchProductList.length === 0 && noProductStatus && (
                      <div className='top__productSearch__dropdown__medicinename'>
                        No Product Found
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='content-container__table'>
              <CustomTable
                data={data}
                tableHeader={CustomerOrderMedicineHeader}
                handleInputChangeHandler={handleInputChangeHandler}
                handleDelete={handleDelete}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-4'>
            <div className='userInput'>
              <NewInput
                label='Doctor Name'
                name={'doctor_name'}
                value={state.doctor_name}
                onChange={handleTextChange}
              />
            </div>
          </div>
          <div className='col-12'>
            <div className='row'>
              {prescriptionDetails &&
                prescriptionDetails.required_by &&
                prescriptionDetails.required_by.length > 0 && (
                  <>
                    <div className='col-sm-12 mt-2'>
                      <h5>
                        {prescriptionDetails.required_by.length +
                          ' ' +
                          'items in the cart need a prescription'}
                      </h5>
                      {prescriptionDetails.required_by.map((item, i) => {
                        return (
                          <img
                            src={item.image_url}
                            alt='sample'
                            className='medicine_icon'
                            id={i}
                          />
                        );
                      })}
                    </div>
                  </>
                )}
                {/* {renderImage()} */}
              {prescriptionDetails &&
                prescriptionDetails.attachments &&
                prescriptionDetails.attachments.length > 0 && (
                  <>
                    <div className='upload-buttons'> 
                      {prescriptionDetails.attachments.map((item, i) => { 
                        return (
                          <div className='attachment_img_wrapper' key={i}>
                            <img
                              src={item.file_url}
                              alt='sample'
                              className='feed__attachment'
                              id={i}
                            />
                            <div className='attachment_img_wrapper_close'>
                              <img
                                id={i}
                                src={CloseBlackIcon}
                                alt='Remove'
                                onClick={(e) => {handleDeletePrescription(i)}}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}

              {(prescriptionDetails &&
                prescriptionDetails.attachments &&
                prescriptionDetails.attachments.length) <
                (prescriptionDetails &&
                  prescriptionDetails.required_by &&
                  prescriptionDetails.required_by.length) && (
                <>
                  <div className='col-sm-12 my-3'>
                    <Checkbox
                      id='allitem'
                      label='Apply this prescription to all items'
                      onChange={handleCheckbox}
                      checked={isCheckBoxActive}
                    />
                  </div>
                  {!isCheckBoxActive && 
                  <div className='upload-buttons '>
                    <input
                      type='file'
                      id='uploadPrescription'
                      style={{ display: 'none' }}
                      name='upload_prescription'
                      onChange={(e) => {
                        handleMediaUpload(e);
                      }}
                    />
                    <div className='upload-label'>
                      <label>Upload Prescription</label>
                      <Button
                        onClick={() => {
                          const image =
                            document.getElementById('uploadPrescription');
                          image.click();
                        }}
                        isDisabled={state.upload_prescription}
                        type='button'
                        name={
                          state.upload_prescription != ''
                            ? 'Uploaded'
                            : 'Upload Prescription'
                        }
                      />
                    </div>
                  </div>
                    }
                </>
              )}
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='userFormArea'>
            <Button
              name={params.id ? 'Update' : 'Save'}
              isDisabled={
                (!user_id ||
                  !addressDetails.postal_code ||
                  !addressDetails.address_line_1 ||
                  !addressDetails.address_line_2 ||
                  data.length === 0) &&
                !addressDetails.selected_address
              }
              onClick={() => {
                handleSubmit();
              }}
            />
            <label
              style={{ paddingLeft: '10px', cursor: 'pointer' }}
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </label>
          </div>
        </div>
      </div>

      {loader && <PageLoader pageLoading={loader} />}
    </div>
  );
};

export default CreateOrderContainer;
