import React from 'react';
import { isMobile } from 'react-device-detect';

import CmsRoutes from './CmsRoutes';
import MobileAppRoutes from './MobileAppRoutes';

const Router = () => {
  if (isMobile === true) {
    return <MobileAppRoutes />;
  }

  return <CmsRoutes />;
};

export default Router;
