import CreateTagsContainer from "app/container/CreateTagsContainer";
import BasePage from "app/pages/BasePage";

const CreateTags = () => {
    return (
        <>
            <BasePage>
                <CreateTagsContainer />
            </BasePage>
        </>
    );
}

export default CreateTags;