import CreateContentContainer from "app/container/CreateContentContainer";
import BasePage from "app/pages/BasePage";
import CreateCollection from "app/container/Collections/CreateCollection";

const CreateContent = () => {
    return (
        <BasePage>
            <CreateCollection />
        </BasePage>
    );
}

export default CreateContent;