import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import Loader from 'components/Loader';
import NewTable from 'components/NewTable';

// API
/* , deleteChallenges */
import { getChallenges } from 'api/gamification';

// // Utils
// import Toast from 'utils/Toast';
// import Message from 'utils/Message';

// Constants
import { APP_CONSTANTS } from 'constants/app-constants';

const Gamification = ({ tableHeader, handleDelete }) => {
  const history = useHistory();
  // const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  // useEffect(() => {
  //   loadNames(0, 4, 0);
  // }, []);

  // const loadNames = (start, end, increase) => {
  //   setLoading(true);
  //   getChallenges(start, end).then((response) => {
  //     setLoading(false);
  //     if (response && response.tableData && response.error === false) {
  //       setData({
  //         tableData: response.tableData,
  //       });
  //     }
  //     // if (response && response.response && response.response.code === 200) {
  //     //   setData({
  //     //     tableData: response.data,
  //     //   });
  //     // }
  //   });
  // };

  // const callDeleteAPI = async (data) => {
  //   setPageLoading(true);
  //   if (data && data.challenge_id) {
  //     let responseObj = await deleteChallenges(data.challenge_id);
  //     setPageLoading(false);
  //     if (
  //       responseObj &&
  //       responseObj.response &&
  //       responseObj.response.code === 200
  //     ) {
  //       loadNames();
  //       Toast.success(Message.Challenges.DELETE_SUCCESS);
  //     }
  //   } else {
  //     Toast.error(Message.Challenges.DELETE_FAILURE);
  //   }
  // };

  /* {
      name: 'Delete',
      onClick: (e, data) => {
        console.log('Delete Clicked: ', data);
        callDeleteAPI(data);
      },
    }, */
  const menuActionObj = [
    {
      name: 'Edit',
      onClick: (e, data) => {
        console.log('Edit Clicked: ', data);
        history.push({
          pathname: `/challenges/edit/${data.challenge_id}`,
          state: {
            ...data,
          },
        });
      },
    },
  ];

  const sectionObj = {
    sectionTitle: 'Challenges',
    searchObj: {
      placeholder: 'Search',
      handleSearch: (e) => {
        console.log('Handle search: ', e);
      },
    },
    // buttons: [
    //   {
    //     title: 'Create a challenge',
    //     handleBtnClick: (e) => {
    //       history.push(APP_CONSTANTS.CREATE_GAMIFICATION);
    //     },
    //   },
    // ],
  };

  if (loading === true) {
    return <Loader />;
  }

  return (
    <div className='blogs'>
      <NewTable
        sectionObj={sectionObj}
        tableHeader={tableHeader}
        menuAction={menuActionObj}
        data={data}
        getData={getChallenges}
        searchKey='description'
      />
    </div>
  );
};
export default Gamification;
