import React from 'react';

// Pages
import BasePage from 'pages/BasePage';
import Banner from 'container/Banner';

import { BannersTableHeader } from 'constants/table-header-constants';

const BannersPage = () => {
  return (
    <BasePage>
      <Banner tableHeader={BannersTableHeader} />
    </BasePage>
  );
};

export default BannersPage;
