import { useState } from 'react';
import { Link } from 'react-router-dom';

// Constants
import AltTag from 'constants/alt-tag';
import { APP_CONSTANTS, LocalSessionKey } from 'constants/app-constants';
import { AppLogoImage } from 'constants/image-constants';

// Utils
import { handleDecodeValues, hanldeEncodedValues } from 'app/utils/Parsers';
import Message from 'utils/Message';
import Toast from 'utils/Toast';
import { LocalStorage } from 'utils/storage';

// Components
// import Input from 'components/Input';
import SideMenuData from 'JsonData/SideMenuData';
import NewInput from 'components/NewInput';
import Button from 'components/button';

// API
import { getEncryptedText, loginToken } from 'api/onboarding';

import './index.scss';

const Login = () => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [userNameStatus, setUserNameStatus] = useState();
  const [passStatus, setPassStatus] = useState();
  const [loading, setLoading] = useState(false);

  const handlePushRoute = () => {
    const intialValue = handleDecodeValues();
    let pathname;

    if (intialValue) {
      // console.log(intialValue, 'abc1');
      let feedData = SideMenuData.filter((val) => {
        return intialValue.includes(val.key);
      });
      pathname = feedData[0].to;
    } else {
      pathname = APP_CONSTANTS.LOGIN;
    }
    return pathname;
  };

  const formValidate = () => {
    if (loading === true) {
      return;
    }

    if (!userName || userName === undefined) {
      setUserNameStatus('Invalid email!');
    } else {
      setUserNameStatus('');
    }

    if (!password || password.length < 6) {
      setPassStatus('Password should be more than 6 characters');
    } else {
      setPassStatus('');
    }

    const payload = {
      email: userName,
      password: password,
    };

    if (!userName || !password) {
      return;
    }

    setLoading(true);

    getEncryptedText(password).then((encriptedText) => {
      payload.password = encriptedText;
      loginToken(payload, (responseObj) => {
        setLoading(false);
        if (responseObj) {
          if (responseObj.response && responseObj.response.code === 200) {
            if (responseObj.data && responseObj.data.access_token) {
              LocalStorage.set(
                LocalSessionKey.ACCESS_TOKEN,
                responseObj.data.access_token
              );
              const encodedValues = hanldeEncodedValues(
                responseObj.data.permission_tag
              );

              LocalStorage.set(
                LocalSessionKey.REFRESH_TOKEN,
                responseObj.data.refresh_token
              );
              localStorage.setItem('EncodedValues', encodedValues);

              if (encodedValues.length === 0) {
                window.localStorage.clear();
              }

              Toast.success(Message.Login.LOGIN_SUCCESS);
              window.location.href = `${handlePushRoute()}`;
              return;
            }
          } else if (
            responseObj.response &&
            responseObj.response.code !== 200
          ) {
            if (
              responseObj.response.alert &&
              responseObj.response.alert.length > 0 &&
              responseObj.response.alert[0].message
            ) {
              Toast.error(responseObj.response.alert[0].message);
              return;
            }
          } else {
            Toast.error(Message.Login.LOGIN_FAILURE);
            return;
          }
        }

        Toast.error(Message.Error.COMMON_MESSAGE);
      });
    });
  };

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      formValidate();
    }
  };
  const handleUserNameChange = (e) => {
    setUserName(e.target.value);
  };
  const handlePassWordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <div className='App'>
      <div className='loginWrapper bg-light h-100'>
        <div className='bg-white rounded shadow col-md-4 bgimage data'>
          <form noValidate>
            <img src={AppLogoImage} alt={AltTag.AppLogo} height='30' />
            <NewInput
              id='emailId'
              type='text'
              placeholder='Enter Email Id'
              name='emailId'
              value={userName}
              onKeyPress={handleKeypress}
              label='Username'
              onChange={handleUserNameChange}
              errorText={userNameStatus}
            />

            <NewInput
              id='password'
              type='password'
              placeholder='Enter Password'
              name='password'
              value={password}
              onKeyPress={handleKeypress}
              label='Password'
              onChange={handlePassWordChange}
              errorText={passStatus}
            />
          </form>
          <Link to={APP_CONSTANTS.FORGOT_PASSWORD}>
            <p className='password'>Forgot Password ?</p>
          </Link>
          <Button type='submit' onClick={formValidate} disabled={loading}>
            {loading === true ? 'Logging...' : 'SIGN IN'}
            {console.log('new checking deployment: ', new Date())}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Login;
