import {
  deleteUploadedMedia,
  getQuizFkkey,
  postQuiz,
  updateQuiz,
  uplaodMedia,
} from 'app/api/cms-content';
import {
  getContentFromId,
  getTagsApi,
  updateContentAPI,
} from 'app/api/createContentAPI';
import Calender from 'app/components/Calender';
import MultiSelectDropdown from 'app/components/MultiSelectDropdown';
import NewInput from 'app/components/NewInput';
import PageLoader from 'app/components/PageLoader';
import TextArea from 'app/components/Textarea';
import Button from 'app/components/button';
import { imageSizeValidation, removeNullValues } from 'app/utils/Parsers';
import Toast from 'app/utils/Toast';
import { calendarValidation } from 'app/utils/dateChecker';
import { CloseBlackIcon } from 'constants/image-constants';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import ValidationMessage from '../../../utils/ValidationMessage';
import AddedQuiz from './components/added-quiz';
import CreateQuizTable from './components/quiz-create-table';

function Quiz({ data, activeTab }) {
  const history = useHistory();

  const [fkKey, setFKkey] = useState('');
  const [loader, setLoader] = useState(false);
  const [isEditMode, setIsEditMode] = useState(data ? true : false);
  const [activate, setActivate] = useState(true);
  const [validated, setValidated] = useState(true);
  const [tags, setTags] = useState([]);
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [endlessScrollMedia, setEndlessScrollImage] = useState(null);
  const [landingPageImage, setLandingPageImage] = useState(null);

  const [inputObj, setInputObj] = useState({
    content_type: 'QUIZ',
    title: '',
    description: '',
    tags: [],
    landing_page_image: '',
    endless_scroll_media: '',
    thumbnail_image: '',
    endless_scroll_cta: '',
    start_date: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    end_date: null,
    quiz: [],
    hex_code: '#323739',
  });

  useEffect(() => {
    getTagsHandler();
  }, []);

  async function getTagsHandler() {
    const tagsData = await getTagsApi();
    setTags(tagsData);
  }

  function inputChangeHandler(event) {
    const { name, value } = event.target;
    setInputObj({ ...inputObj, [name]: value });
  }
  function addQuizHandler(quiz) {
    inputObj.quiz = quiz;
  }
  const handleMediaUpload = async (e) => {
    let file = e.target.files[0];

    if (
      (file.type === 'image/jpeg' ||
        file.type === 'image/jpg' ||
        file.type === 'image/png') &&
      imageSizeValidation(e)
    ) {
      Toast.error(ValidationMessage.IMAGE_SIZE);
      return;
    }

    if (file.type.includes('image')) {
      const { filename } = await uplaodMedia('media', file, 'QUIZ');
      setInputObj({ ...inputObj, [e.target.name]: filename });
      let reader = new FileReader();
      if (e.target.name === 'thumbnail_image') {
        reader.onloadend = function () {
          setThumbnailImage(reader.result);
        };
        reader.readAsDataURL(file);
      } else if (e.target.name === 'endless_scroll_media') {
        reader.onloadend = function () {
          setEndlessScrollImage(reader.result);
        };
        reader.readAsDataURL(file);
      } else if (e.target.name === 'landing_page_image') {
        reader.onloadend = function () {
          setLandingPageImage(reader.result);
        };
        reader.readAsDataURL(file);
      }

      Toast.success(file?.name + ' uploaded');
    } else {
      Toast.error('Please select image file!');
    }
  };

  async function removeUploadedMediaHandler(fileName) {
    await deleteUploadedMedia(fileName);
  }

  // If edit
  const updateStates = async () => {
    if (data) {
      if (!data?.id || !activeTab) {
        return;
      }
      setThumbnailImage(null);
      setEndlessScrollImage(null);
      setLandingPageImage(null);

      let response = await getContentFromId(data.id, activeTab);
      let requiredResponse = response.data[0];
      let stateValues = Object.keys(inputObj);

      let thumbnail_image = requiredResponse?.thumbnail_image
        ? `${process.env.REACT_APP_S3_BUCKET_URL}/${requiredResponse.thumbnail_image}`
        : null;
      setThumbnailImage(thumbnail_image);

      let endless_scroll_media = requiredResponse?.endless_scroll_media
        ? `${process.env.REACT_APP_S3_BUCKET_URL}/${requiredResponse.endless_scroll_media}`
        : null;
      setEndlessScrollImage(endless_scroll_media);

      let landing_page_image = requiredResponse?.landing_page_image
        ? `${process.env.REACT_APP_S3_BUCKET_URL}/${requiredResponse.landing_page_image}`
        : null;
      setLandingPageImage(landing_page_image);
      // }
      let temporaryState = {};
      for (let index in stateValues) {
        temporaryState[stateValues[index]] =
          requiredResponse[stateValues[index]];
      }
      setInputObj(temporaryState);
      const fk_key = await getQuizFkkey(data.id);
      setFKkey(fk_key);
    }
  };

  useEffect(() => {
    updateStates();
  }, [activeTab]);

  const validateForm = () => {
    let val = calendarValidation(inputObj.start_date, inputObj.end_date);
    if (!val) {
      Toast.error('Start date is much larger than end date');
      return;
    }
    let flag = true;
    let validationArray = Object.keys(inputObj);
    let optionalFields = ['end_date', 'description'];
    for (let index in validationArray) {
      if (
        inputObj[validationArray[index]] === '' &&
        !optionalFields.includes(validationArray[index])
      ) {
        flag = false;
        Toast.error('Please complete all fields');
        break;
      } else if (
        validationArray[index] === 'tags' &&
        inputObj.tags?.length === 0
      ) {
        flag = false;
        Toast.error('Please complete all fields');
        break;
      } else if (
        validationArray[index] === 'start_date' &&
        inputObj.start_date == null
      ) {
        flag = false;
        Toast.error('Please complete all fields');
        break;
      }
    }

    return flag;
  };

  // Submit Handler
  async function submitHandler() {
    if (activeTab === 'en') {
      let validated = validateForm();
      if (!validated) {
        return;
      }
    }
    inputObj?.quiz &&
      inputObj?.quiz.map((item, index) => {
        inputObj.quiz[index].question_id = index + 1;
      });
    if (activeTab === 'en') {
      if (inputObj?.quiz.length === 0) {
        Toast.error('Minimum one Quiz must be there!!');
        return;
      }

      inputObj.start_date = inputObj?.start_date
        ? moment(inputObj.start_date).format('YYYY-MM-DD HH:mm:ss')
        : null;
      inputObj.end_date = inputObj?.end_date
        ? moment(inputObj.end_date).format('YYYY-MM-DD HH:mm:ss')
        : null;
    }
    if (activeTab !== 'en') {
      if (!inputObj?.thumbnail_image) {
        Toast.error('Please Add Thumbnail Image');
        return;
      }
      delete inputObj?.start_date;
      delete inputObj?.end_date;
      delete inputObj?.tags;
    }
    setLoader(true);
    let responseObj;
    if (data) {
      inputObj.content_id = data.id;
      inputObj.fk_quiz_id = fkKey;
      let temporaryStates = removeNullValues(inputObj);
      responseObj = await updateQuiz(temporaryStates, data?.id, activeTab);
      setLoader(false);
    } else {
      responseObj = await postQuiz(inputObj);
      setLoader(false);
    }
    setLoader(false);

    if (
      responseObj &&
      responseObj.response &&
      responseObj.response.code === 200
    ) {
      if (data) {
        Toast.success('Updated Quiz');
        history.push({
          pathname: `/cms/content`,
          state: {
            goto: data?.comingFrom,
          },
        });
      } else {
        Toast.success('Quiz Added');
        history.push('/cms/content');
      }
    } else {
      Toast.error('Something went wrong!!');
    }
  }

  return (
    <>
      {loader && <PageLoader pageLoading={loader} />}
      <div className='cms-quiz__container'>
        <div className='quiz-con__separator separator'>
          <p>Quiz - Add Details</p>
        </div>

        <div className='quiz-con__inputs'>
          <NewInput
            label='Title'
            onChange={inputChangeHandler}
            name={'title'}
            value={inputObj.title ? inputObj.title : ''}
          />
          <TextArea
            label={'Short Description'}
            onChange={inputChangeHandler}
            name={'description'}
            value={inputObj.description ? inputObj.description : ''}
          />
        </div>
        {activeTab === 'en' && (
          <div>
            <MultiSelectDropdown
              label='Add Tags'
              name='tags'
              value={inputObj.tags}
              options={tags}
              onChange={inputChangeHandler}
              tag_type={true}
            />
          </div>
        )}

        <div className='shorts-con__thumbnail-box d-flex'>
          <label>Upload Thumbnail Image</label>
          <input
            type='file'
            id='thumbnailFile'
            style={{ display: 'none' }}
            name='thumbnail_image'
            onChange={(e) => {
              handleMediaUpload(e);
            }}
          />
          <Button
            onClick={() => {
              const image = document.getElementById('thumbnailFile');
              image.click();
            }}
            isDisabled={inputObj?.thumbnail_image !== ''}
            type='button'
            name={
              inputObj?.thumbnail_image !== ''
                ? 'Uploaded Thumbnail Image'
                : 'Upload Thumbnail Image'
            }
          />
          {inputObj?.thumbnail_image && (
            <p
              className='cancel'
              onClick={() => {
                removeUploadedMediaHandler(inputObj.thumbnail_image);
                setInputObj({ ...inputObj, thumbnail_image: '' });
                setThumbnailImage(null);
              }}
            >
              Cancel Thumbnail
            </p>
          )}
        </div>
        <div className='d-flex'>
          {thumbnailImage && (
            <div className='attachment_img_wrapper'>
              <img
                src={thumbnailImage}
                alt='sample'
                className='feed__attachment'
              />
              <div className='attachment_img_wrapper_close'>
                <img
                  src={CloseBlackIcon}
                  alt='Remove'
                  onClick={() => {
                    removeUploadedMediaHandler(inputObj.thumbnail_image);
                    setInputObj({ ...inputObj, thumbnail_image: '' });
                    setThumbnailImage(null);
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className='colorpicker'>
          <label>Background Fill</label>
          <input
            type='color'
            name='hex_code'
            value={inputObj.hex_code}
            onChange={inputChangeHandler}
          />
          <p>{inputObj.hex_code}</p>
        </div>
        {/* <div className="quiz-con__file-uploads"> */}
        <div className=''>
          <div className='file-upload__btns mb-4'>
            <label>Endless Scroll Page Image</label>
            <input
              type='file'
              id='endless_scroll_media'
              style={{ display: 'none' }}
              label={'Upload Image'}
              name={'endless_scroll_media'}
              onChange={(e) => {
                handleMediaUpload(e);
              }}
            />
            <Button
              onClick={() => {
                const image = document.getElementById('endless_scroll_media');
                image.click();
              }}
              type='button'
              className='mx-3'
              isDisabled={inputObj.endless_scroll_media !== ''}
              name={
                inputObj.endless_scroll_media !== ''
                  ? 'Uploaded'
                  : 'Upload Image'
              }
            />
            {inputObj.endless_scroll_media && (
              <p
                className='cancel'
                onClick={() => {
                  removeUploadedMediaHandler(inputObj.endless_scroll_media);
                  setInputObj({ ...inputObj, endless_scroll_media: '' });
                  setEndlessScrollImage(null);
                }}
              >
                Cancel
              </p>
            )}
            <div className='d-flex my-3'>
              {endlessScrollMedia && (
                <div className='attachment_img_wrapper'>
                  <img
                    src={endlessScrollMedia}
                    alt='sample'
                    className='feed__attachment'
                  />
                  <div className='attachment_img_wrapper_close'>
                    <img
                      src={CloseBlackIcon}
                      alt='Remove'
                      onClick={() => {
                        removeUploadedMediaHandler(
                          inputObj.endless_scroll_media
                        );
                        setInputObj({ ...inputObj, endless_scroll_media: '' });
                        setEndlessScrollImage(null);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='file-upload__btns'>
            <label>Quiz Landing Page Image</label>
            <input
              type='file'
              id='landing_page_image'
              style={{ display: 'none' }}
              label={'Upload Image'}
              name={'landing_page_image'}
              onChange={(e) => {
                handleMediaUpload(e);
              }}
            />
            <Button
              onClick={() => {
                const image = document.getElementById('landing_page_image');
                image.click();
              }}
              type='button'
              className='mx-3'
              isDisabled={inputObj.landing_page_image !== ''}
              name={
                inputObj.landing_page_image !== '' ? 'Uploaded' : 'Upload Image'
              }
            />
            {inputObj.landing_page_image && (
              <p
                className='cancel'
                onClick={() => {
                  removeUploadedMediaHandler(inputObj.landing_page_image);
                  setInputObj({ ...inputObj, landing_page_image: '' });
                  setLandingPageImage(null);
                }}
              >
                Cancel
              </p>
            )}
            <div className='d-flex my-3'>
              {landingPageImage && (
                <div className='attachment_img_wrapper'>
                  <img
                    src={landingPageImage}
                    alt='sample'
                    className='feed__attachment'
                  />
                  <div className='attachment_img_wrapper_close'>
                    <img
                      src={CloseBlackIcon}
                      alt='Remove'
                      onClick={() => {
                        removeUploadedMediaHandler(inputObj.landing_page_image);
                        setInputObj({ ...inputObj, landing_page_image: '' });
                        setLandingPageImage(null);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className='quiz-con__table'>
          <label>Quiz Details</label>
          {isEditMode ? (
            <>
              <AddedQuiz
                quizArray={inputObj.quiz}
                onUpdateQuiz={(newQuiz) =>
                  setInputObj({ ...inputObj, quiz: newQuiz })
                }
              />
              <Button
                style={{
                  margin: '2rem',
                }}
                onClick={() => {
                  setInputObj({ ...inputObj, quiz: [] });
                  setIsEditMode(false);
                }}
              >
                Clear All Quiz & Start new quiz
              </Button>
            </>
          ) : (
            <CreateQuizTable onAddQuizDetails={addQuizHandler} />
          )}
        </div>

        <NewInput
          placeholder={'Type..'}
          onChange={inputChangeHandler}
          name={'endless_scroll_cta'}
          label={'Endless Scroll CTA Text'}
          value={
            inputObj?.endless_scroll_cta ? inputObj?.endless_scroll_cta : ''
          }
        />
        {activeTab === 'en' && (
          <div className='quiz-con__date-inputs'>
            <Calender
              onStartDateSelected={(e) => {
                setInputObj({ ...inputObj, start_date: e });
              }}
              onEndDateSelected={(e) => {
                setInputObj({ ...inputObj, end_date: e });
              }}
              startDate={inputObj?.start_date ? inputObj?.start_date : null}
              endDate={inputObj?.end_date ? inputObj?.end_date : null}
              validated={validated}
              activate={activate}
            />
          </div>
        )}
        <div className='quiz-con__actions'>
          <Button onClick={submitHandler}>
            {data ? 'Edit' : 'Create'} Quiz
          </Button>
          <label
            onClick={() => {
              history.push('/cms/content');
            }}
          >
            Cancel
          </label>
        </div>
      </div>
    </>
  );
}

export default Quiz;
