import { useEffect, useState } from 'react';

import { updateBanners } from 'app/api/latest/LatestBannerAPI/latestBanner';
import { URLType } from 'app/constants/app-constants';
import Parsers from 'app/utils/Parsers';
import ValidationMessage from 'app/utils/ValidationMessage';
import Message from 'utils/Message';
import Toast from 'utils/Toast';

import { getLatestCoursesBanner } from 'app/api/latest/Courses/courses';

const useCoursesBanner = (validate) => {
  const [pageLoading, setPageLoading] = useState(false);
  const [bannerlistData, setBannerListData] = useState([]);
  const [isPublishButtonDisabled, setisPublishButtonDisabled] = useState(true);

  const [activeTab, setActiveTab] = useState({
    id: '1',
    name: 'English',
    short_name: 'en',
    display_order: 1,
    status: 'ACTIVE',
    label: '+ English',
    value: 'English',
  });

  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    let payload = {
      page: 'COURSE',
    };

    loadServiceslist({ ...payload });

    return () => {
      setBannerListData([]);
    };
  }, [refresh]);

  const loadServiceslist = async (payload) => {
    setBannerListData([]);
    setPageLoading(true);
    let response = await getLatestCoursesBanner(payload);
    setPageLoading(false);
    let temp = [];
    if (response && response.response && response.response.code === 200) {
      temp = response.data.TOP.map((item, index) => {
        let url = '';
        let externalUrl = '';
        if (item?.url_type && item?.url_type.trim() === URLType.EXTERNAL) {
          externalUrl = item?.url ? item?.url : '';
        }
        if (item?.url_type && item?.url_type.trim() === URLType.INTERNAL) {
          url = item?.url ? item?.url : '';
        }
        return {
          carouselId: item?.id,
          banner_id: item?.banner_id,
          id: `banner_${index}`,
          title: item?.title,
          page: item?.page,
          placement: item?.placement,
          size: item?.size,
          displayOrder: item?.display_order ? item?.display_order : index,
          attachment: item?.attachment_path,
          urlType: item?.url_type ? item?.url_type.trim() : '',
          url: url,
          urlCopy: url,
          redirection_id: item?.redirection_id ? item?.redirection_id : '',
          redirection_idURL: item?.redirection_id ? item?.redirection_id : '',
          externalUrl: externalUrl,
          updated_by: item?.updated_by,
          updated_on: Parsers.ParseTableDateTime(item?.updated_on),
          isNew: false,
          wasEdited: false,
          banner_status: item?.banner_status ? item?.banner_status : '',
          banner_resource_status: item?.banner_resource_status
            ? item?.banner_resource_status
            : '',

          toggle: item?.banner_resource_status === 'ACTIVE' ? true : false,
        };
      });
    }

    setBannerListData(temp);

    setPageLoading(false);
    if (response || response.response || response.response.error_code) {
      Toast.error(response.response.message);
    } else {
      Toast.error(Message.Error.COMMON_MESSAGE);
    }
  };

  const OnhandleChange = (e) => {
    const { name, value, id } = e.target;

    if (value) {
      setisPublishButtonDisabled(false);
    }
    const valueCpy = [...bannerlistData];
    valueCpy.map((element, index) => {
      if (`${element.id}` === `${id}`) {
        if (element.serviceId !== null) {
          element.wasEdited = true;
        }
        element[name] = value;
      }

      return element;
    });
    setBannerListData([...valueCpy]);
  };

  const callAddOrUpdateAPI = async () => {
    // debugger;
    /* Populate Data */

    // debugger;
    let editpayloadData = [];

    bannerlistData.forEach((item, index) => {
      let newconsData = {};
      if (item?.wasEdited && !item?.isNew) {
        if (item?.banner_id) {
          newconsData.banner_id = item.banner_id;
        }
        if (item.carouselId) {
          newconsData.id = item.carouselId;
        }
        if (item?.banner_status) {
          newconsData.banner_status = item.banner_resource_status;
        }
        if (item?.banner_resource_status) {
          newconsData.banner_resource_status = item.banner_resource_status;
        }

        if (item?.title) {
          newconsData.title = item.title;
        }

        if (item?.attachmentFile) {
          newconsData.banner_image = item.attachmentFile;
        }
        if ((item.url || item.externalUrl) && item.urlType) {
          newconsData.url_type = item.urlType;
          if (item.urlType === URLType.EXTERNAL) {
            newconsData.url = item.externalUrl;
          }
          if (item.urlType === URLType.INTERNAL) {
            newconsData.url = item.url;
          }
        } else {
          newconsData.url_type = item.urlType;
        }

        editpayloadData.push(newconsData);
      }
    });

    setPageLoading(true);

    try {
      const promises = [];

      if (editpayloadData) {
        promises.push(updateBanners(editpayloadData, activeTab.short_name));
        //Toast.success('Banner Updated Successfully');
      }
      if (editpayloadData.length > 0) {
        Toast.success('Banner Updated Successfully');
      }

      // Wait for all promises to resolve (API calls to complete)
      await Promise.all(promises);
      // All other APIs have completed successfully

      setisPublishButtonDisabled(true);
      setRefresh(!refresh);
      setPageLoading(false);
    } catch (error) {
      Toast.error('Unable to update Banner Resources . Please try again.');
      // Handle errors here if any of the API calls fail
      console.error('API call failed:', error);
      setPageLoading(false);
    }

    setPageLoading(false);
  };

  const onFileSelected = (file, id) => {
    let newData = [...bannerlistData];
    if (file) {
      setisPublishButtonDisabled(false);
    }
    if (newData && newData.length > 0) {
      newData = newData.map((item) => {
        if (item.id === id && id) {
          if (item.serviceId !== null) {
            item.wasEdited = true;
          }
          item.attachmentFile = file;
        }
        return item;
      });
      setBannerListData(newData);
    }
  };

  const validateRequestpreviewScreen = async () => {
    let addimageCheck = false;
    let addheadingCheck = false;

    bannerlistData.forEach((item, index) => {
      if (item.attachmentFile === null) {
        Toast.error(
          `${ValidationMessage.ATTACH} for Banner Resources ${index + 1}`
        );
        addimageCheck = true;
        return;
      }

      if (item?.title === '') {
        Toast.error(
          `${ValidationMessage.TEXT_TITLE} for Banner Resources ${index + 1}`
        );
        addheadingCheck = true;
        return;
      }
    });

    if (addimageCheck || addheadingCheck) {
      setPageLoading(false);
      return;
    }
    callAddOrUpdateAPI();
    //handleShow(true);
  };

  const handleResourseToggle = (id, itemindex) => {
    setisPublishButtonDisabled(false);
    let newData = [...bannerlistData];

    if (newData && newData.length > 0) {
      newData = newData.map((item, index) => {
        if (index === itemindex) {
          if (item.serviceId !== null) {
            item.wasEdited = true;
          }
          item.toggle = !item.toggle;
          item.banner_resource_status = item.toggle ? 'ACTIVE' : 'INACTIVE';
        }
        return item;
      });
      setBannerListData(newData);
    }
  };

  return {
    pageLoading,
    OnhandleChange,
    bannerlistData,
    isPublishButtonDisabled,
    onFileSelected,
    handleResourseToggle,
    validateRequestpreviewScreen,
  };
};

export default useCoursesBanner;
