import React from 'react';

// Pages
import BasePage from 'pages/BasePage';
import CreateBanner from 'app/container/CreateBanner';

const BannerCreatePage = () => {
  return (
    <BasePage>
      <CreateBanner />
    </BasePage>
  );
};

export default BannerCreatePage;
