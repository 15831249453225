// Ajax
import * as dataAccess from 'utils/ajax';
import ApiParser from 'utils/ApiParser';
import Parsers from 'utils/Parsers';

// Constants
import { API_CONSTANTS } from 'constants/api-contants';

// Get Lists of Items
export const getPlanActvationHistory = async (value) => {
  let url = `${API_CONSTANTS.GET_PLAN_ACTIVATION_HISTORY}?product_type=${value}`;
  let responseObj = await dataAccess.get(url);
  return responseObj;
};

// Create Item
export const createPlanActivation = async (incomingData) => {
    let formData = Parsers.ConvertObjectAsFormData(incomingData)
    let payload = {
        data: formData
    }

  return await dataAccess.upload(API_CONSTANTS.CREATE_PLAN_ACTIVATION, payload);
};
 