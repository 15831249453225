import { useEffect, useState } from 'react';

import {
  getMedPaySubCategories,
  getMedPaySubCategoriesforScearchScreen,
  getSearchPageTogglePublish,
  saveSearchAPI,
  searchPageTogglePublish,
} from 'app/api/latest/Categories/categoriesAPI';
import { confirmAlert } from 'react-confirm-alert';
import { useLocation } from 'react-router-dom';
import Message from 'utils/Message';
import Toast from 'utils/Toast';
const useContentSearchScreenForm = (validate) => {
  const [pageLoading, setPageLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [first, setFirst] = useState({});
  const [second, setSecond] = useState({});
  const [third, setThird] = useState({});
  const [forth, setForth] = useState({});

  const [selectedCategories, setselectedCategories] = useState([]);
  const location = useLocation();
  const [bannerType, setBannerType] = useState({
    source: '',
    publish: null,
  });
  const [refresh, setRefresh] = useState(false);

  const [toggleStatus, setToggleStatus] = useState(null);
  useEffect(() => {
    if (location?.pathname === '/medicine-search-screen') {
      let payload = {
        heading: 'Medicine Delivery',
        source: 'MEDICINE',
      };
      setBannerType(payload);
      getToggleStatus(payload);
      loadSubCategorieslist(payload);
      preloadCategorieslist(payload);
    }
    if (location?.pathname === '/diagonostic-search-screen') {
      let payload = {
        heading: 'Diagnostic',
        source: 'DIAGNOSTIC',
      };
      getToggleStatus(payload);
      loadSubCategorieslist(payload);
      setBannerType(payload);
      preloadCategorieslist(payload);
    }
  }, [refresh, location?.pathname]);

  const getToggleStatus = async (payload) => {
    let response = await getSearchPageTogglePublish(payload.source);

    if (response && response?.response?.code === 200) {
      setToggleStatus(response?.data?.status === 'ACTIVE' ? true : false);
    } else {
      Toast.error(
        response?.data?.error
          ? response?.data?.error
          : 'Something went wrong Please try later.'
      );
    }
  };
  const callSearchScreenPublishOrUnpublishAPI = async () => {
    let banner_status = {
      product_type: bannerType.source,
      status: !toggleStatus,
    };
    if (banner_status) {
      setPageLoading(true);
      let response = await searchPageTogglePublish(banner_status);
      setPageLoading(false);
      if (response?.response?.code === 200) {
        let message = `Search Page ${
          !toggleStatus ? 'Publisdhed' : 'Unpublished'
        } Successfully`;
        Toast.success(message);
      } else {
        Toast.error(
          response?.data?.error
            ? response?.data?.error
            : 'Something went wrong. please try later'
        );
      }
      setRefresh(!refresh);
    }
  };

  const openUp = () => {
    confirmAlert({
      title: '',
      message: toggleStatus
        ? 'Are you sure you want to Unpublish Search?'
        : 'Are you sure you want to Publish  Search?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            await setToggleStatus(!toggleStatus);
            callSearchScreenPublishOrUnpublishAPI();
          },
        },
        {
          label: 'No',
          // onClick: () => handleToggle,
        },
      ],
    });
  };
  const loadSubCategorieslist = async (item) => {
    setPageLoading(true);
    if (!item?.source) {
      return;
    }
    let response = await getMedPaySubCategories(item?.source, 's');
    setPageLoading(false);
    if (response?.response?.code === 200) {
      let temp =
        (await response?.data) &&
        response?.data.map((item, index) => {
          return {
            sub_category_id: item.id,
            value: item.heading,
            label: item.heading,
            sub_category: item.heading,
          };
        });

      setListData(temp);
    }
    if (response?.response?.code === 204) {
      Toast.success('No Content');
    } else {
      Toast.error(
        response?.data.error
          ? response?.data.error
          : Message.Error.COMMON_MESSAGE
      );
    }
  };

  const preloadCategorieslist = async (item) => {
    if (!item?.source) {
      return;
    }
    setPageLoading(true);
    let response = await getMedPaySubCategoriesforScearchScreen(item?.source);
    setPageLoading(false);
    if (response?.response?.code === 200) {
      let temp =
        (await response?.data) &&
        response?.data.map((item, index) => {
          return {
            id: item?.id,
            noId: index + 1,
            sub_category_id: item?.sub_category_id,
            sub_category: item?.sub_category,
            value: item?.sub_category ? item?.sub_category : '',
            label: item?.sub_category ? item?.sub_category : '',
            product_type: item?.product_type,
          };
        });

      await setselectedCategories(temp);
      setFirst(temp[0]);
      setSecond(temp[1]);
      setThird(temp[2]);
      setForth(temp[3]);
    } else {
      Toast.error(
        response?.data.error
          ? response?.data.error
          : Message.Error.COMMON_MESSAGE
      );
    }
  };

  const validateRequestpreviewScreen = async () => {
    const unique = [
      ...new Set(selectedCategories.map((item) => item.sub_category)),
    ];
    if (
      unique &&
      selectedCategories &&
      unique.length < selectedCategories.length
    ) {
      Toast.error('Please select unique categories ');
      return;
    }

    handleCreateServices();
  };

  const OnhandleChange = async (e, listIndex) => {
    const { value, id } = e.target;
    let temp = [...selectedCategories];
    let selectedValTemp = null;
    let productType = '';
    let newval = {};
    await temp.forEach((tempval, index) => {
      if (tempval?.noId === id) {
        selectedValTemp = tempval?.id ? tempval?.id : id + 12;
        productType = tempval?.product_type
          ? tempval?.product_type
          : bannerType?.source;
      }
    });
    await listData.forEach((listDataval, index) => {
      if (listDataval.value === value) {
        newval = {
          value: listDataval.label,
          sub_category_id: listDataval.sub_category_id,
          sub_category: listDataval.label,
          // product_type: bannerType?.source,
          id: selectedValTemp,
          product_type: productType,
        };
      }
    });
    temp.splice(id - 1, 1, newval);
    setselectedCategories(temp);
  };

  const callAddOrUpdateAPI = async () => {
    setPageLoading(true);
    const response = await saveSearchAPI(selectedCategories);
    setPageLoading(false);
    if (response?.response?.code === 201 || response?.response?.code === 200) {
      Toast.success('Categories Published Successfully');
    } else {
      Toast.error(
        response?.data?.error
          ? response?.data?.error
          : Message?.Error?.COMMON_MESSAGE
      );
    }
    setRefresh(!refresh);
  };

  const handleCreateServices = (e) => {
    callAddOrUpdateAPI();
  };

  return {
    pageLoading,
    OnhandleChange,
    listData,
    validateRequestpreviewScreen,
    bannerType,
    first,
    second,
    third,
    forth,
    toggleStatus,
    openUp,
  };
};

export default useContentSearchScreenForm;
