import SlidingToggle from 'app/components/SlidingToggle';
import { useState } from 'react';
import Referee from './Referee';
import Referrer from './Referrer';
import './index.scss';
const RewardsAndReferral = () => {
  const [referrerStatus, setRreferrerStatus] = useState(false);
  const handleCheckboxChange = () => {
    setRreferrerStatus(!referrerStatus);
  };
  return (
    <>
      <div className='blogsForm'>
        <div className='blogsForm_content'>
          <h1> {referrerStatus ? 'Rewards' : 'Rewards  & Referrals'}</h1>
          <SlidingToggle
            selectedSlide='Referrer'
            secondarySlide='Referee'
            slideCheck={referrerStatus}
            handleSlideChange={handleCheckboxChange}
          />
          {referrerStatus ? <Referee /> : <Referrer />}
        </div>
      </div>
    </>
  );
};

export default RewardsAndReferral;
