import { useHistory } from 'react-router';
import '../index.scss';
import moment from 'moment';

function CustomTable({
  data,
  tableHeader,
  handleDelete,
  handlePublishUnpublish,
  handleNavigation,
  handleInputChangeHandler,
  handleEdit,
  selectedItemValue,
  tableHeaderTestimonial

}) {
  return (
    <table>
      {selectedItemValue === 'testimonial_collection' ?
      <tr>
      {tableHeaderTestimonial.map((item, i) => {
        return <th className='nowrap text-center'>{item.Header}</th>;
      })}
    </tr>:  
      <tr>
        {tableHeader.map((item, i) => {
          return <th className='nowrap text-center'>{item.Header}</th>;
        })}
      </tr>}
      {data.map((items, indx) => (
        <tr key={indx}>
          <td>{items.banner_name}</td>
          <td>{items.banner_id}</td>
          {selectedItemValue === "testimonial_collection" && 
          <td>{items.testimonial_type == "IMAGE_ONLY"?"Type 2":"Type 1"}</td>
          }

          <td>{items.age_tags?.toString()}</td>
          <td>
            {items.updated_on
              ? moment(items.updated_on).format('DD-MM-YYYY')
              : '-'}
          </td>
          <td>{items.status}</td>
          <td>
            {items.end_date ? moment(items.end_date).format('DD-MM-YYYY') : '-'}
          </td>
          <td>
            <div
              className='btn-group'
              role='group'
              aria-label='Basic outlined example'
            >
              <button
                type='button'
                className='btn btn-outline-primary'
                onClick={() => handleNavigation(items, indx)}
              >
                Edit
              </button>
              <button
                type='button'
                onClick={() => handleDelete(items, indx)}
                className='btn btn-outline-primary'
              >
                Delete
              </button>
              {items.banner_id && (
                <button
                  type='button'
                  onClick={() => handlePublishUnpublish(items, indx)}
                  className='btn btn-outline-primary'
                >
                  {items.status && items.status === 'ACTIVE'
                    ? 'UnPublish'
                    : 'Publish'}
                </button>
              )}
            </div>
          </td>
          <td>
            <input
              type='text'
              className='form-input'
              placeholder=''
              name='asthma_order'
              value={items.asthma_order ? items.asthma_order : ''}
              onChange={(e) =>
                handleInputChangeHandler('asthma_order', e, indx)
              }
              disabled={items.isDisabled}
            />
          </td>
          <td>
            <input
              type='text'
              className='form-input'
              placeholder=''
              name='copd_order'
              value={items.copd_order ? items.copd_order : ''}
              onChange={(e) => handleInputChangeHandler('copd_order', e, indx)}
              disabled={items.isDisabled}
            />
          </td>
          <td>
            <input
              type='text'
              className='form-input'
              placeholder=''
              name='ild_order'
              value={items.ild_order ? items.ild_order : ''}
              onChange={(e) => handleInputChangeHandler('ild_order', e, indx)}
              disabled={items.isDisabled}
            />
          </td>
          <td>
            <input
              type='text'
              className='form-input'
              placeholder=''
              name='ar_order'
              value={items.ar_order ? items.ar_order : ''}
              onChange={(e) => handleInputChangeHandler('ar_order', e, indx)}
              disabled={items.isDisabled}
            />
          </td>
          <td>
            <input
              type='number'
              className='form-input'
              placeholder=''
              name='other_order'
              value={items.other_order ? items.other_order : ''}
              onChange={(e) => handleInputChangeHandler('other_order', e, indx)}
              disabled={items.isDisabled}
            />
          </td>
          <td>
            <div
              className='btn-group'
              role='group'
              aria-label='Basic outlined example'
            >
              <button
                type='button'
                className='btn btn-outline-primary'
                onClick={() => {
                  handleEdit(items, items.isDisabled ? 'Edit' : 'Save', indx);
                }}
              >
                {items.isDisabled ? 'Edit' : 'Save'}
              </button>
            </div>
          </td>
        </tr>
      ))}
      {data.length === 0 && (
        <tr>
          <td colspan='10000'>No data found</td>{' '}
        </tr>
      )}
    </table>
  );
}

export default CustomTable;
