import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

// Components
import Calender from 'components/Calender';
import Challenges from 'components/Challenges';
import HeaderWithBackButton from 'components/HeaderWithBackButton';
import NewInput from 'components/NewInput';
import PageLoader from 'components/PageLoader';
import TextArea from 'components/Textarea';
import validate from 'components/ValidateInfo';
import Button from 'components/button';
import useContentForm from 'components/useContentForm';
import Dropdown from 'shared/components/Dropdown';

// API
import { addOrUpdateChallenges } from 'api/gamification';

// Constants
import { APP_CONSTANTS, URLType } from 'constants/app-constants';

// Utils
import DropdownOptions from 'utils/DropdownOptions';
import Message from 'utils/Message';
import Toast from 'utils/Toast';

const CreateGamification = (props) => {
  const history = useHistory();
  const location = useLocation();

  const activate = true;
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const validated = true;
  const [challengeId, setChallengeId] = useState('');

  const [pageLoading, setPageLoading] = useState(false);

  const { handleChange, values, error, setValues } = useContentForm(validate);

  useEffect(() => {
    let data = location.state;
    if (data && data.challenge_id) {
      console.log(data, 'chadata');
      setChallengeId(data.challenge_id);
      setValues((prevState) => ({
        challengeType: data.challenge_type,
        title: data.title,
        description: data.description,
        day: data.alloted_days,
        points: data.total_point,
        startDate: data.start_date,
        endDate: data.end_date,
        hasChallenge: data.alloted_days > 0 ? URLType.YES : URLType.NO,
        serviceType: data.service_type,
      }));
      setStartDate(data.start_date);
      setEndDate(data.end_date);
    }
  }, []);

  const callAddOrUpdateAPI = async () => {
    let {
      challengeType,
      title,
      description,
      points,
      hasChallenge,
      day,
      serviceType,
    } = values;

    if (!challengeType || !title || !description || !points) {
      Toast.error(Message.Challenges.ERROR);
      return;
    }

    if (hasChallenge === URLType.YES && !day) {
      Toast.error(Message.Challenges.FREQUENCY_ERROR);
      return;
    }

    // let defaultVal = null;

    let data = {
      challenge_id: challengeId ? challengeId : 0,
      update_challenge: challengeId ? 1 : 0,
      title: title ? title : '',
      description: description ? description : '',
      service_type: serviceType,
      // alloted_days: defaultVal,
    };

    if (challengeType) {
      data.challenge_type = challengeType;
    }

    if (points) {
      data.alloted_point = points;
    }

    if (hasChallenge === URLType.YES) {
      data.alloted_days = day;
    }

    if (hasChallenge === URLType.NO) {
      data.alloted_days = 0;
    }

    if (startDate) {
      data.start_date = startDate;
    }

    if (endDate) {
      data.end_date = endDate;
    }

    setPageLoading(true);
    const responseObj = await addOrUpdateChallenges(data);
    setPageLoading(false);
    if (
      responseObj &&
      responseObj.response &&
      responseObj.response.code === 200
    ) {
      Toast.success(Message.Challenges.CREATED_SUCCESS);
      history.goBack();
    } else {
      Toast.error(Message.Challenges.CREATE_FAILURE);
    }
  };

  const handleCreateChallenges = (e) => {
    callAddOrUpdateAPI();
  };

  const onStartDateSelected = (date) => {
    console.log('Start Date: ', date);
    setStartDate(date);
  };

  const onEndDateSelected = (date) => {
    console.log('End Date: ', date);
    setEndDate(date);
  };

  return (
    <div className='blogsForm'>
      <div className='blogsForm_content'>
        <HeaderWithBackButton
          title='Add/edit a challenge'
          goTo={APP_CONSTANTS.GAMIFICATION}
        />

        <Dropdown
          label='Challenge Type'
          name='challengeType'
          options={DropdownOptions.ChallengeOptions}
          placeholder='Select type'
          value={values.challengeType}
          handleChange={handleChange}
        />

        <NewInput
          label='Title'
          id='title'
          type='text'
          placeholder='Enter Title'
          name='title'
          value={values.title}
          onChange={handleChange}
          errorText={error.Title}
        />

        <TextArea
          label='Description'
          id='description'
          placeholder='Enter Description'
          rows='3'
          type='text'
          name='description'
          value={values.description}
          onChange={handleChange}
          errorText={error.description}
        />

        <NewInput
          label='Bonus Points'
          id='points'
          type='number'
          placeholder='Enter points'
          name='points'
          value={values.points}
          onChange={handleChange}
        />

        <Challenges
          hasChallenge={values.hasChallenge}
          day={values.day}
          onChange={handleChange}
        />

        <Calender
          onStartDateSelected={onStartDateSelected}
          onEndDateSelected={onEndDateSelected}
          startDate={startDate}
          endDate={endDate}
          validated={validated}
          activate={activate}
        />

        <Button
          className='mt1'
          onClick={handleCreateChallenges}
          type='submit'
          name='Submit'
        />
      </div>
      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </div>
  );
};

export default CreateGamification;
