import React from 'react';
import './index.scss';

const CustomTable = ({
  data,
  tableHeader,
  selectedItems,
  onSelectedItemsChange,
}) => {
  const handleCheckboxClick = (item) => {
    if (selectedItems.some((selectedItem) => selectedItem.id === item.id)) {
      const updatedSelectedItems = selectedItems.filter(
        (selectedItem) => selectedItem.id !== item.id
      );

      onSelectedItemsChange(updatedSelectedItems); // Update parent's selectedItems
    } else {
      const updatedSelectedItems = [...selectedItems, item];
      updatedSelectedItems.forEach((item, idx) => {
        item.content_id = item.id;
        item.display_order = idx;
      });
      onSelectedItemsChange(updatedSelectedItems); // Update parent's selectedItems
    }
  };

  return (
    <>
      <table>
        <tr>
          <th colspan='10' className='text-center'>
            Content List
          </th>
        </tr>
        <tr>
          {tableHeader.map((item, i) => {
            return <th className='nowrap text-center'>{item.Header}</th>;
          })}
        </tr>
        {data &&
          data.map((items, idx) => (
            <tr key={idx}>
              <td>
                <input
                  type='checkbox'
                  checked={selectedItems.some(
                    (selectedItem) => selectedItem.id === items.id
                  )}
                  onChange={() => handleCheckboxClick(items)}
                />
              </td>
              <td>{items.title}</td>
              <td>{items.combinedTags}</td>
              <td>{items.created_on}</td>
              <td>{items.updated_on}</td>
              <td>{items.id}</td>
              <td>{items.content_type}</td>
              <td>{items.status}</td>
              <td>{items.end_date ? items.end_date : '-'}</td>
            </tr>
          ))}
        {data.length === 0 && (
          <tr>
            <td colspan='10000'>No data found</td>{' '}
          </tr>
        )}
      </table>
    </>
  );
};

export default CustomTable;
