import React from 'react';
import BasePage from 'pages/BasePage';
import CreateStatic from 'container/CreateStatic';

const StaticCreatePage = () => {
  return (
    <BasePage>
      <CreateStatic />
    </BasePage>
  );
};

export default StaticCreatePage;
