// Components

import PageLoader from 'components/PageLoader';
import validate from 'components/ValidateInfo';
import Button from 'components/button';
import Toggle from 'react-toggle';
import Dropdown from 'shared/components/Dropdown';

// Constants

// Utils

import MedDropdownCard from 'app/components/MedDropdownCard';
import './index.scss';
import useContentSearchScreenForm from './useContentSearchScreenForm';

const CreateSearchCategoryMed = (props) => {
  const {
    pageLoading,
    OnhandleChange,
    listData,
    validateRequestpreviewScreen,
    bannerType,
    first,
    second,
    third,
    forth,
    toggleStatus,
    openUp,
  } = useContentSearchScreenForm({ validate });

  return (
    <div className='blogsForm'>
      <div className='blogsForm_content'>
        <h1>
          {bannerType?.heading ? bannerType?.heading : ''} Search{' '}
          <Toggle checked={toggleStatus} icons={false} onChange={openUp} />
        </h1>

        <MedDropdownCard key={'1'} title={''} index={''} type={'Category 1'}>
          <Dropdown
            className='newdropdown'
            id={1}
            label='Select Category'
            name='categories'
            options={listData && listData}
            placeholder='Select from category'
            value={first?.label}
            handleChange={OnhandleChange}
          />
        </MedDropdownCard>
        <MedDropdownCard key={'2'} title={''} index={''} type={'Category 2'}>
          <Dropdown
            className='newdropdown'
            id={2}
            label='Select Category'
            name='categories'
            options={listData && listData}
            placeholder='Select from category'
            value={second?.label}
            handleChange={OnhandleChange}
          />
        </MedDropdownCard>

        <MedDropdownCard key={'3'} title={''} index={''} type={'Category 3'}>
          <Dropdown
            className='newdropdown'
            id={3}
            label='Select Category'
            name='categories'
            options={listData && listData}
            placeholder='Select from category'
            value={third?.label}
            handleChange={OnhandleChange}
          />
        </MedDropdownCard>

        <MedDropdownCard key={'4'} title={''} index={''} type={'Category 4'}>
          <Dropdown
            className='newdropdown'
            id={4}
            label='Select Category'
            name='categories'
            options={listData && listData}
            placeholder='Select from category'
            value={forth?.label}
            handleChange={OnhandleChange}
          />
        </MedDropdownCard>

        <div className='mt1 widget-button-section'>
          <Button
            onClick={validateRequestpreviewScreen}
            type='submit'
            name='Publish'
          />
        </div>
      </div>
      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </div>
  );
};

export default CreateSearchCategoryMed;
