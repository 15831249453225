import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

// Pages
import PageNotFound from 'pages/PageNotFound';
import ArticleDetailPage from 'pages/ArticleDetailPage';
import TermsOfUse from 'pages/TermsOfUse';

// Constants
import { APP_CONSTANTS } from 'constants/app-constants';

// Load all the stylesheets
import 'styles/index.scss';

const MobileAppRoutes = () => {
  return (
    <React.Suspense fallback={<p>Loading....</p>}>
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path={APP_CONSTANTS.ARTICLE_DETAIL}
            component={ArticleDetailPage}
          />

          <Route
            exact
            path={APP_CONSTANTS.TERMS_OF_USE}
            component={TermsOfUse}
          />

          <Route
            exact
            path={APP_CONSTANTS.PRIVACY_POLICY}
            component={TermsOfUse}
          />

          <Route
            exact
            path={APP_CONSTANTS.PAGE_NOT_FOUND}
            component={PageNotFound}
          />

          {/* Page Not Found page has to come */}
          <Redirect from='*' to={APP_CONSTANTS.PAGE_NOT_FOUND} />
        </Switch>
      </BrowserRouter>
    </React.Suspense>
  );
};

export default MobileAppRoutes;
