import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import * as QueryString from 'query-string';
import NewInput from 'app/components/NewInput';
import Toast from 'app/utils/Toast';
import PageLoader from 'app/components/PageLoader';
import {
  createAdvertisement,
  getAdvertisementDetails,
  updateAdvertisement,
  updateAdvertisementStatus,
} from 'app/api/exercises';

import WidgetCard from 'app/components/WidgetCard';
import NewAttachment from 'app/components/NewDesign/NewAttachment';
import { uplaodMedia } from 'app/api/widgets';
import Button from 'app/components/button';
import '../index.scss';
import DropdownOptions from 'app/utils/DropdownOptions';
import { getVersionControl } from 'app/api/versionList';
import CTAType from 'app/components/CTAType';
import VersionDropDown from 'app/components/VersionDropDown';

const CreateAdvertisementContainer = () => {
  const location = useLocation();
  const params = useParams();
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState({
    advertisement_name: '',
    advertisement_type: '',
    android_version: '',
    criteria: '',
    ios_version: '',
    ios_criteria: '',
    display_order: '',
    published: true,
    url_type: 'INTERNAL',
    url: '',
    redirection_id: null,
    profile_data: false,
    internal_for: '',
    internal_url: '',
    custom_object: '',
    detail_cta_text: '',
    url_type_secondary: '',
    redirection_id_secondary: null,
    internal_for_secondary: '',
    internal_url_secondary: '',
    url_secondary: '',
    detail_cta_text_secondary: '',
    profile_data_secondary: false,
    custom_object_secondary: '',
    secondaryCTA: false,
    banner_details: [
      {
        display_order: '',
        thumbnail_image: '',
        status: 'ACTIVE',
        isNew: true,
      },
    ],
  });
  let [versionLists, setVersionLists] = useState([]);
  let [iosversionLists, setIosVersionLists] = useState([]);

  let history = useHistory();

  const VersionRange = [
    {
      value: 'GREATER_THAN',
      label: 'Greater Than',
    },
    {
      value: 'LESS_THAN',
      label: 'Lesser Than',
    },
    {
      value: 'EQUALS',
      label: 'Equals',
    },
  ];

  useEffect(() => {
    if (params.id) {
      getAdvertisementDetailsById(params.id);
    } else {
      let advertisementtype = QueryString.parse(location.search).key;
      setState({ ...state, advertisement_type: advertisementtype });
      // payload.advertisement_type = advertisement_type;
    }
  }, []);

  useEffect(() => {
    async function fetchVersionControl() {
      let androidResponse = await getVersionControl('Android');

      const data = androidResponse.data.available_versions;
      let newState = data.map((key) => {
        return {
          value: [key][0].toString(),
          label: [key][0].toString(),
        };
      });
      setVersionLists(newState);

      let iosResponse = await getVersionControl('IOS');

      const iosdata = iosResponse.data.ios_available_versions;
      let newiosState = iosdata.map((key) => {
        return {
          value: [key][0].toString(),
          label: [key][0].toString(),
        };
      });
      setIosVersionLists(newiosState);
    }
    fetchVersionControl();
  }, []);

  const getAdvertisementDetailsById = async (id) => {
    setLoader(true);
    let responseObj = await getAdvertisementDetails(id);
    let bannerdetails = responseObj[0];
    setState({
      advertisement_name: bannerdetails.advertisement_name,
      advertisement_type: bannerdetails.advertisement_type,
      android_version: bannerdetails.android_version
        ? bannerdetails.android_version.toString()
        : '',
      criteria: bannerdetails.criteria,
      ios_version: bannerdetails.ios_version
        ? bannerdetails.ios_version.toString()
        : '',
      ios_criteria: bannerdetails.ios_criteria
        ? bannerdetails.ios_criteria
        : '',
      display_order: bannerdetails.display_order !== null ? bannerdetails.display_order.toString():'' ,
      status: bannerdetails.status,
      url_type: bannerdetails.url_type ? bannerdetails.url_type : '',
      url: bannerdetails.url ? bannerdetails.url : '',
      redirection_id: bannerdetails.redirection_id
        ? bannerdetails.redirection_id
        : '',
      profile_data: bannerdetails.profile_data
        ? bannerdetails.profile_data
        : '',
      internal_for: bannerdetails.internal_for
        ? bannerdetails.internal_for
        : '',
      internal_url: bannerdetails.internal_url
        ? bannerdetails.internal_url
        : '',
      custom_object: bannerdetails.custom_object
        ? bannerdetails.custom_object
        : '',
      detail_cta_text: bannerdetails.detail_cta_text
        ? bannerdetails.detail_cta_text
        : '',
      url_type_secondary: bannerdetails.url_type_secondary
        ? bannerdetails.url_type_secondary
        : '',
      redirection_id_secondary: bannerdetails.redirection_id_secondary
        ? bannerdetails.redirection_id_secondary
        : '',
      internal_for_secondary: bannerdetails.internal_for_secondary
        ? bannerdetails.internal_for_secondary
        : '',
      internal_url_secondary: bannerdetails.internal_url_secondary
        ? bannerdetails.internal_url_secondary
        : '',
      url_secondary: bannerdetails.url_secondary
        ? bannerdetails.url_secondary
        : '',
      detail_cta_text_secondary: bannerdetails.detail_cta_text_secondary
        ? bannerdetails.detail_cta_text_secondary
        : '',
      profile_data_secondary: bannerdetails.profile_data_secondary
        ? bannerdetails.profile_data_secondary
        : '',
      custom_object_secondary: bannerdetails.custom_object_secondary
        ? bannerdetails.custom_object_secondary
        : '',
      secondaryCTA: bannerdetails.url_type_secondary ? true : false,
      banner_details: bannerdetails.banner_details,
      advertisement_id: bannerdetails.advertisement_id,
    });

    setLoader(false);
  };
  const handleTextChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    const valueCpy = state;

    delete valueCpy['published'];
    delete valueCpy['secondaryCTA'];
    let payload = {
      ...valueCpy,
    };
    let banners = state.banner_details;

    banners.map((item, i) => {
      delete item.isNew;
      return item;
    });
    payload.banner_details = banners;
    setLoader(true);
    let responseObj;
    if (params.id) {
      responseObj = await updateAdvertisement(payload);
    } else {
      // let advertisement_type = QueryString.parse(location.search).key;
      // payload.advertisement_type = advertisement_type;

      responseObj = await createAdvertisement(payload);
    }

    if (
      responseObj &&
      responseObj.response &&
      responseObj.response.code === 200
    ) {
      Toast.success(responseObj.response.alert[0].message);
      history.push('/advertisement');
    } else {
      setLoader(false);
      Toast.error(
        responseObj.data && responseObj.data.msg
          ? responseObj.data.msg
          : responseObj.response.alert[0].message
      );
    }
  };

  const removeServiceSection = (index) => {
    const valueCpy = [...state.banner_details];

    valueCpy.splice(index, 1);

    setState({ ...state, banner_details: [...valueCpy] });
  };

  const handleMediaUpload = async (file, index) => {
    setLoader(true);
    let resp = await uplaodMedia('image', file);
    const valueCpy = [...state.banner_details];
    valueCpy[index]['thumbnail_image'] = resp?.filename;

    setState({ ...state, banner_details: [...valueCpy] });
    setLoader(false);

    Toast.success(file?.name + ' uploaded');
  };

  const OnhandleChange = (e, index) => {
    const { value } = e.target;
    const valueCpy = [...state.banner_details];
    const duplicateOrder = valueCpy.find(
      (option) => option.display_order === value
    );
      if(value && duplicateOrder){
        Toast.error(`same display order already added. can't add duplicate display order `);
        return
      }else{
        valueCpy[index]['display_order'] = value;  
        setState({ ...state, banner_details: [...valueCpy] });
      } 
   
  };

  const handleSecondaryInputChange = (key, value) => {
    setState({ ...state, [key]: value });
  };

  const handleCTATextChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  const handleSecondaryCTA = () => {
    if(state.secondaryCTA){
      setState({ 
        ...state, 
        secondaryCTA: !state.secondaryCTA, 
        url_type_secondary: '',
        redirection_id_secondary: null,
        internal_for_secondary: '',
        internal_url_secondary: '',
        url_secondary: '',
        detail_cta_text_secondary: '',
        profile_data_secondary: false,
        custom_object_secondary: '',
      });
    }else{
      setState({ ...state, secondaryCTA: !state.secondaryCTA });
    }
    
  };

  const handleAdvertisementToggle = async () => {
    let text = `Are you sure, want to ${
      state.status === 'ACTIVE' ? 'Unpublish' : 'Publish'
    } Advertisement`;

    if (window.confirm(text) == true) {
      setLoader(true);
      let payload = {
        advertisement_id: state.advertisement_id,
        status: state.status === 'ACTIVE' ? 'Unpublish' : 'Publish',
      };

      let responseObj = await updateAdvertisementStatus(payload);
      if (
        responseObj &&
        responseObj.response &&
        responseObj.response.code === 200
      ) {
        Toast.success(responseObj.response.alert[0].message);
      } else {
        setLoader(false);
        Toast.error(
          responseObj.data
            ? responseObj.data.msg
            : responseObj.response.alert[0].message
            ? responseObj.response.alert[0].message
            : ''
        );
      }
      setLoader(false);
    }
  };

  const handlePublishUnpublishBanner = async (index) => {
    const valueCpy = [...state.banner_details];
    let text = `Are you sure, want to ${
      valueCpy[index].status === 'ACTIVE' ? 'Unpublish' : 'Publish'
    } banner`;
    valueCpy[index].status =
      state.banner_details[index].status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';

    if (window.confirm(text) == true) {
      setLoader(true);
      let payload = {
        advertisement_id: state.advertisement_id,
        banner_details: valueCpy,
      };

      let responseObj = await updateAdvertisementStatus(payload);
      if (
        responseObj &&
        responseObj.response &&
        responseObj.response.code === 200
      ) {
        Toast.success(responseObj.response.alert[0].message);
      } else {
        setLoader(false);
        Toast.error(
          responseObj.data
            ? responseObj.data.msg
            : responseObj.response.alert[0].message
            ? responseObj.response.alert[0].message
            : ''
        );
      }
      setLoader(false);
    }
  };

  const handleNewBanner = () => {
    const valueCpy = [...state.banner_details];
    valueCpy.push({
      thumbnail_image: '',
      display_order: '',
      status: 'ACTIVE',
      isNew: true,
    });
    setState({ ...state, banner_details: [...valueCpy] });
  }; 
  return (
    <div className='container'>
      <h1 className='section-header__title'>
        Breathing Exercises / Advertisement
      </h1>

      <div className='separator space-between'>
        <p>Create Advertisement</p>
        {params.id && (
          <span>
            <Toggle
              defaultChecked={state.published}
              icons={false}
              onChange={() => handleAdvertisementToggle()}
            />
          </span>
        )}
      </div>
      <div className='userInput'>
        <NewInput
          label='Advertisement Name'
          name={'advertisement_name'}
          value={state.advertisement_name}
          onChange={handleTextChange}
        />
        {state.advertisement_type === 'LANDING_PAGE' && (
          <NewInput
            label='Order Position'
            name={'display_order'}
            value={state.display_order}
            onChange={handleTextChange}
          />
        )}

        <div className='my-4'>
          <h5>Primary CTA</h5>
          <CTAType
            cta_type={state.url_type}
            handleChange={(key, data) => {
              if (key === 'cta_type') {
                if(!data ){
                  setState({ ...state, url_type: data, url:'' });
                }else{
                  setState({ ...state, url_type: data });
                } 
              } else if (key === 'cta_type_secondary') {
                if(!data ){
                  setState({ ...state, url_type_secondary: data, url_secondary:'' });
                }else{
                  setState({ ...state, url_type_secondary: data });
                }  
              } else {
                if (
                  key === 'url' &&
                  (data !== 'zoom_live_session' ||
                    data !== 'infinite_scroll' ||
                    data !== 'group_of_collection')
                ) {
                  setState({ ...state, [key]: data, redirection_id: '' });
                } else if (
                  key === 'url_secondary' &&
                  (data !== 'zoom_live_session' ||
                    data !== 'infinite_scroll' ||
                    data !== 'group_of_collection')
                ) {
                  setState({ ...state, [key]: data, redirection_id_secondary: '' });
                } 
                 else {
                  setState({ ...state, [key]: data });
                }
              }
            }} 
            internalUrl={DropdownOptions.InternalUrl}
            url={state.url}
            handleTextChange={(e) => {
              handleCTATextChange(e);
            }}
            internal_for={state.internal_for}
            internal_url={state.internal_url}
            profile_data={state.profile_data}
            custom_object={state.custom_object}
            redirection_id={state.redirection_id}
            secondaryCTA={state.secondaryCTA}
            cta_type_secondary={state.url_type_secondary}
            url_secondary={state.url_secondary}
            internal_for_secondary={state.internal_for_secondary}
            internal_url_secondary={state.internal_url_secondary}
            profile_data_secondary={state.profile_data_secondary}
            custom_object_secondary={state.custom_object_secondary}
            redirection_id_secondary={state.redirection_id_secondary}
            ctaText={true}
            detail_cta_text={state.detail_cta_text}
            detail_cta_text_secondary={state.detail_cta_text_secondary}
          />

          <div className='row'>
            <div className='col d-flex justify-content-start my-3'>
              <Button
                type='button'
                onClick={() => {
                  handleSecondaryCTA();
                }}
              >
                {state.secondaryCTA
                  ? 'Remove Secondary CTA'
                  : 'Add Secondary CTA'}
              </Button>
            </div>
          </div>
        </div>
        <VersionDropDown
          versionLists={versionLists}
          iosLists={iosversionLists}
          VersionRange={VersionRange}
          handleTextChange={handleTextChange}
          android_version={state.android_version}
          criteria={state.criteria}
          ios_version={state.ios_version}
          ios_criteria={state.ios_criteria}
        />
        {state.banner_details.map((data, index) => {
          return (
            <div key={index}>
              <WidgetCard
                widgetcardkey={index}
                index={index}
                handleRemoveCard={removeServiceSection}
                type={'Banner'}
                closeSection={
                  state.banner_details.length > 1 && data.isNew ? true : false
                }
                handleResourseToggle={() => handlePublishUnpublishBanner(index)}
                isEdit={params.id ? true : false}
                isNew={
                  state.banner_details.length > 1 && data.isNew ? true : false
                }
                status={data.status}
              >
                <div className=''>
                  <div>
                    <NewAttachment
                      value={data.thumbnail_image}
                      id={data.id}
                      onFileSelected={(e) => handleMediaUpload(e, index)}
                      fileType='image'
                      label={`Add Banner ${index + 1} `}
                      className='widget_box_feed__attachment'
                    />

                    <NewInput
                      label='Display order'
                      id={'display_order'}
                      placeholder='Enter display Order 1/2/3'
                      name='display_order'
                      value={data.display_order}
                      onChange={(e) => OnhandleChange(e, index)}
                      type='number'
                    />
                  </div>
                </div>
              </WidgetCard>
            </div>
          );
        })}

        {state.banner_details && state.banner_details.length < 4 && (
          <div className='row'>
            <div className='col d-flex justify-content-start my-3'>
              <Button
                type='button'
                onClick={() => {
                  handleNewBanner();
                }}
              >
                Add Banner
              </Button>
            </div>
          </div>
        )}

        <div className='userFormArea'>
          <Button
            name={params.id ? 'Update' : 'Save'}
            onClick={handleSubmit}
            isDisabled={!state.advertisement_name}
          />
          <label
            style={{ paddingLeft: '10px', cursor: 'pointer' }}
            onClick={() => {
              history.goBack();
            }}
          >
            Cancel
          </label>
        </div>
      </div>
      {loader && <PageLoader pageLoading={loader} />}
    </div>
  );
};

export default CreateAdvertisementContainer;
