import React, {useState} from 'react';
import { useHistory } from "react-router"
import './index.scss'
import moment from 'moment';


function CustomTable({ data, handleSelect, selectedContent=[]}) {
    
     return (
          <table>
               <tr>
                    <th className='nowrap text-center'>Select</th>
                    <th className='nowrap text-center'>Title</th>
                    <th className='nowrap text-center'>Tags</th>
                    <th className='nowrap text-center'>Published Date</th>
                    <th className='nowrap text-center'>Update Date</th>
                    <th className='nowrap text-center'>Content ID</th>
                    <th className='nowrap text-center'>Content Type</th>
                    <th className='nowrap text-center'>Status</th>
                    {/* <th className='nowrap text-center'>Expired On</th>  */}
               </tr>
               {data.map((items, index) => { 
                    let isChecked =  selectedContent.findIndex((item) => item.id === items.id);
                    console.log(isChecked, 'isChecked')
                    return (
                    <tr key={index}>
                         <td>
                         <input type="checkbox" id="topping" name="topping" value="Paneer" checked={isChecked !== -1} onChange={(e) => handleSelect(e,items)} />
                         </td>
                         <td>{items.title}</td>
                         <td>
                              {items.tags&& items.tags.length > 0 ? items.tags.map((tag, i) => (` ${ tag} ${i !==items.tags.length-1 ? ',':''}`)) : ""}
                         </td>
                    
                         <td>{items.created_on? moment(items.created_on).format('DD/MM/YYYY'):''}</td>
                         <td>{items.updated_on? moment(items.updated_on).format('DD/MM/YYYY'):''}</td>
                         <td>{items.id}</td>
                         <td>{items.content_type}</td>
                         <td>{items.status}</td>
                        
                    </tr>     
                    )
               })}
               
          </table>
     )
}

export default CustomTable