import React from 'react'
import { useState, useEffect } from 'react'; 
import Search from 'app/components/search';
import Plus from 'images/plus.png';
import {
    searchMedicine,
    getMedicineDiscountData,
    updateSubCategories,
    medicineDetail,
    addSubCategory,
    getSubCategoryById,
} from 'app/api/latest/Categories/categoriesAPI';
import Message from 'app/utils/Message';
import Toast from 'app/utils/Toast';
import CustomTable from '../MedicineDiscountTable/table';
import {
    MedicineDiscount
} from 'app/constants/table-header-constants';
import Button from 'app/components/button';
import Pagination from 'app/container/LatestCategories/CategoriesCustomTable/pagination';
import Toggle from 'react-toggle';
import NumberInput from 'components/UI/NumberInput';
import { useLocation } from 'react-router-dom'; 
import PageLoader from 'app/components/PageLoader';

const MedicineDiscountContainer = () => {
   
    const [productKeyword, setProductKeyword] = useState('');
    const [noProductStatus, setNoProductStatus] = useState(false);
    const [searchProductList, setSearchProductList] = useState([]);
    const [productSearchLoader, setProductSearchLoader] = useState(false);
    const [data, setData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [values, setValues] = useState('');
    const [toggled, setToggled] = useState(null);
    const [discount, setDiscount] = useState("");
    const [pageLoading, setPageLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [selectedID, setSelectedID] = useState('');
    const [initialData, setInitialData] = useState([])
    const [loader, setLoader] = useState(false);
    
    const location = useLocation();
    
    const dataPerPage = 10;
    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    let currentData = data.slice(indexOfFirstData, indexOfLastData);
    const productSearchHandler = async (searchVal) => {
        setProductKeyword(searchVal.toLowerCase());

        if (searchVal.trim() === '') {
            setNoProductStatus(false);
            setSearchProductList([]);

            return;
        }
        let response = {};
        setProductSearchLoader(true);
        // if (bannertype?.source === 'DIAGNOSTIC') {
        //   let postal_code = '400013';
        //   response = await searchMedicine(
        //     searchVal,
        //     bannertype?.source,
        //     postal_code
        //   );
        // }
        // if (bannertype?.source === 'MEDICINE') {
        response = await searchMedicine(searchVal, 'MEDICINE');
        // }
        setProductSearchLoader(false);
        if (response?.response?.code === 200) {
            if (response?.data?.results?.length === 0) {
                setNoProductStatus(true);
            } else {
                setNoProductStatus(false);
                let searcharray = response?.data.results.slice(0, 10);
                let tempData = [];
                searcharray.forEach((searchItem, searchindex) => {
                    searchItem['alreadyStatus'] = false;
                    data.forEach((dataItem, dataindex) => {
                        if (searchItem.id === dataItem.id) {
                            searchItem['alreadyStatus'] = true;
                            return;
                        }
                    });
                    tempData.push(searchItem);
                });

                setSearchProductList(tempData);
            }
        } else {
            Toast.error(
                response.data.error ? response.data.error : Message.Error.COMMON_MESSAGE
            );
        }
    };
    const productAddinList = async (item) => { 
        let temp = [...data];
        const newdata = {
            id: item.id,
            ranking: temp.length + 1,
            med_name: item.medicine_name ? item.medicine_name : item.name,
            sku_id: item.sku_id ? item?.sku_id : item?.test_id,
            discount: item?.discount ? item?.discount : 0,
            updated_by: item?.updated_by ? item?.updated_by : '',
            // isDisabled: true,
            // isNew: true,
        };

        temp.push(newdata);
        let count = Math.ceil(temp.length / dataPerPage);
        setPageCount(count);
        setProductKeyword('');
        setData(temp);
        setTotalData(temp);
        setSearchProductList([]);
    };
    const handleEdit = async (item, key, indexnew) => {
        let index = (currentPage - 1) * 10 + indexnew;
        if (key === 'Edit') {
            let tempData = [];
            data.forEach((val, i) => {
                if (index === i) {
                    let temp = val;
                    val['isDisabled'] = false;
                    tempData.push(temp);
                } else {
                    tempData.push(val);
                }
            });
            setData(tempData);
            setTotalData(tempData);
        } else {
            let tempData = [];
            data.forEach((val, i) => {
                if (index === i) {
                    let temp = val;
                    val['isDisabled'] = true;
                    tempData.push(temp);
                } else {
                    tempData.push(val);
                }
            });
            setData(tempData);
            setTotalData(tempData);
        }
    };
    function inputChangeHandler(searchVal) {
        if (searchVal.trim() === '') {
            setValues(searchVal);
            setData(initialData)
            return;

        }
        const filteredData = [];
        data.forEach((item) => {
            if (
                (item?.med_name &&
                    item?.med_name
                        .toLowerCase()
                        .includes(searchVal.toLowerCase())) ||
                (item?.sku_id && item?.sku_id.toString().includes(searchVal))
            ) {
                filteredData.push(item);
            }
        });
        setData(filteredData);
        setValues(searchVal);
    }


    const handleInputChangeHandler = (key, e, indexnew) => { 
        let index = (currentPage - 1) * 10 + indexnew; 
        let tempData = [];
        data.forEach((val, i) => {
            if (index === i) {
                let temp = val;
                val[key] = Number(e.target.value) ? Number(e.target.value) : '';
                tempData.push(temp);
            } else {
                tempData.push(val);
            }
        });
        setData(tempData);
        setTotalData(tempData);
    };

    function paginationHandler(page) {
        setCurrentPage(page);
        // let count = Math.ceil(updatedData.length / dataPerPage);
        //   setPageCount(count);
    }


    const subCategoryAllData = async () => {
        setLoader(true);
        let formData = new FormData();
        for (let index = 0; index < data.length; index++) {
            const element = data[index];

            formData.append('medicine_id[]', element.id);
            formData.append('discount[]', element.discount);
        }
        formData.append("remaining_medicine_discount", discount);
        formData.append("remaining_medicine_check", toggled);
        setPageLoading(true);
        const response = await getMedicineDiscountData(formData)
        setPageLoading(false);
        if (response?.response?.code === 201) {
            Toast.success(response?.data?.message);
            medicineList()
            setLoader(false);
        } else {
            Toast.error(
                response?.data?.error
                    ? response?.data?.error
                    : Message.Error.COMMON_MESSAGE
            );
            setLoader(false);
        }


    }

    const medicineList = async () => {
        setPageLoading(true);
        let response = await medicineDetail();
        setPageLoading(false);
        if (response?.response?.code === 200) {
            let updatedData = [];
            let responsedata = response?.data;
            updatedData=responsedata?.medicine_data;
            setData(responsedata?.medicine_data);
            setInitialData(responsedata?.medicine_data);
            setToggled(responsedata?.remaining_medicine_check?.global_discount_check);
            setDiscount(responsedata?.remaining_medicine_check?.global_discount);
            let count = Math.ceil(updatedData.length / dataPerPage);
            setPageCount(count);
            setPageLoading(false);
        } else {
            Toast.error(
                response.data.error ? response.data.error : Message.Error.COMMON_MESSAGE
            );
        }
    };
 
    useEffect(() => {
        medicineList()

    }, [])
 
    const handleDelete = async (key, indexnew) => { 
        let index = (currentPage - 1) * 10 + indexnew; 
        let tempData = [];
        data.forEach((val, i) => {
            if (index === i) {
                return;
            }
            if (val.isNew === true) {
                let temp = val;
                val[key] = val.discount;
                tempData.push(temp);
            } else {
                tempData.push(val);
            }
        })
        setData(tempData);
        setTotalData(tempData);
        let count = Math.ceil(tempData.length / dataPerPage); 
        setPageCount(count);
        setCurrentPage(count);
        
    };

    const OnhandleChange = (e) => {
        setDiscount(e.target.value)
    }
    const toggleChange = (e) => {
        setToggled(!toggled)

    }
    
    return (
        <>
            <div className='top__productSearch'>
                <div className='d-flex align-items-center justify-content-between'>
                    <Search
                        value={values}
                        placeholder='Search'
                        className={''}
                        onChange={inputChangeHandler}
                    />
                    <div className=''>
                        <div className='d-flex justify-content-between'>
                            <p>OFF for all</p>
                            <Toggle icons={false} checked={toggled} onChange={(e) => toggleChange(e)} />
                        </div>
                        <NumberInput
                            label=''
                            id="discount"
                            name="discount"
                            value={discount}
                            placeholder='40%'
                            className='form-block'
                            onChange={OnhandleChange}
                        />
                    </div>
                </div>
                <Search
                    value={productKeyword}
                    placeholder='Search'
                    className={'categorySearchBar'}
                    onChange={productSearchHandler}
                />

                
                {productSearchLoader ? (
                    <div
                    // className=' top__productSearch__dropdown'
                    >
                        Loading<div className='productSearchLoader'></div>
                    </div>
                ) : (
                    <>
                        <div className='top__productSearch__dropdown'>
                            {searchProductList &&
                                searchProductList.length > 0 &&
                                searchProductList.map((item, index) => {
                                    return (
                                        <>
                                            <div
                                                key={index}
                                                className={
                                                    item.alreadyStatus
                                                        ? 'top__productSearch__dropdown__medicinename_already'
                                                        : 'top__productSearch__dropdown__medicinename_new'
                                                }
                                                onClick={() => {
                                                    if (!item.alreadyStatus) {
                                                        productAddinList(item);
                                                    }
                                                }}
                                            >
                                                {' '}
                                                <span>
                                                    {item.name} {item.alreadyStatus}
                                                </span>
                                                <span>
                                                    {!item.alreadyStatus && (
                                                        <img
                                                            src={Plus}
                                                            alt='Go back'
                                                            width='25'
                                                            height='25'
                                                        // onClick={onClick}
                                                        />
                                                    )}
                                                </span>
                                            </div>
                                        </>
                                    );
                                })}
                            {searchProductList.length === 0 && noProductStatus && (
                                <div className='top__productSearch__dropdown__medicinename'>
                                    No Product Found
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
            <div className='row'>
                <div className='col-sm-12'>
                    <div className="content-container__table">
                        <CustomTable
                            data={currentData}
                            tableHeader={MedicineDiscount}
                            handleInputChangeHandler={handleInputChangeHandler}
                            handleDelete={handleDelete}
                            handleEdit={handleEdit}
                            isDisabled={isDisabled}
                            selectedID={selectedID}
                            setSelectedID={setSelectedID}
                        />
                    </div>
                </div>
                <div className='subcategory-container__pagination'>
                    <Pagination pages={pageCount} onChange={paginationHandler} />
                </div>
                {searchProductList.length === 0 && (
                    <div className='button_section'>
                        <Button variant='Save' onClick={subCategoryAllData} name={'Save'} />
                    </div>)}
            </div>
            {loader && <PageLoader pageLoading={loader} />}
        </>
    )
}

export default MedicineDiscountContainer