import { useHistory } from 'react-router';
import '../index.scss';
import { dataFormats } from 'app/utils/dateChecker';


function CustomTable({
  data,
  tableHeader, 
  handleDelete,
  handlePublishUnpublish
}) {
  const history = useHistory();
  const handleNavigation = (items) => { 
      history.push({
        pathname: `/advertisement/edit/${items.advertisement_id}?key=${items.advertisement_type}`,
      }); 
  };

  return (
    <table>
      <thead> 
          {tableHeader.map((item, i) => {
            return <th className='nowrap'>{item.Header}</th>;
          })} 
      </thead>
      <tbody>
      {data.map((items, index) => (
        <tr key={index}>
          <td>{items.advertisement_name}</td>
          <td>{items.advertisement_id}</td>
          <td>{items.published_date?dataFormats(items.published_date):'-'}</td>
          <td>{items.status}</td>
          <td>
            <div
              className='btn-group'
              role='group'
              aria-label='Basic outlined example'
            >
              <button
                type='button'
                className='btn btn-outline-primary'
                onClick={() => handleNavigation(items)}
              >
                Edit
              </button>
              <button
                type='button'
                onClick={() => handlePublishUnpublish(items)} 
                className='btn btn-outline-primary'
              >
                {items.status === 'ACTIVE'?'UnPublish':'Publish'}
              </button>
              <button
                type='button'
                onClick={() => handleDelete(items)} 
                className='btn btn-outline-primary'
              >
                Delete
              </button>
            </div>
          </td>
        </tr>
      ))}
      {data.length === 0 && (
        <tr>
          <td colSpan='10000'>No data found</td>{' '}
        </tr>
      )}
      </tbody>
    </table>
  );
}

export default CustomTable;
