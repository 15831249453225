import React from 'react';

// Pages
import BasePage from 'pages/BasePage';
import ActivityVitalsPage from 'app/container/ActivityVitalsPage';

import { ActivityandVitalsHeader } from 'constants/table-header-constants';

const ActivityVitals = () => {
  return (
    <BasePage>
      <ActivityVitalsPage tableHeader={ActivityandVitalsHeader} />
    </BasePage>
  );
};

export default ActivityVitals;
