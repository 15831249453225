import { API_CONSTANTS } from 'app/constants/api-contants';
import * as dataAccess from 'utils/ajax';

export const getCoins = async () => {
  let responseObj = await dataAccess.get(API_CONSTANTS.BREATHEFREE_COINS);
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data;
  } else {
    return [];
  }
};
export const addCoins = async (incomingData) => {
  let payload = {
    data: {
      // publish: 1,
      ...incomingData,
    },
  };

  return await dataAccess.post(API_CONSTANTS.BREATHEFREE_COINS, payload);
};
export const updateCoins = async (incomingData, id) => {
  let url = `${API_CONSTANTS.BREATHEFREE_COINS}/${id}`;
  let payload = {
    data: {
      ...incomingData,
    },
  };

  return await dataAccess.put(url, payload);
};

export const getCoinsById = async (id) => {
  let url = `${API_CONSTANTS.BREATHEFREE_COINS}/${id}`;
  let responseObj = await dataAccess.get(url);
  return responseObj;
};

export const getConversion = async (id) => {
  let url = `${API_CONSTANTS.CONVERSION_COINS}`;
  let responseObj = await dataAccess.get(url);
  return responseObj;
};

export const updateConversion = async (incomingData) => {
  let url = `${API_CONSTANTS.CONVERSION_COINS}`;
  let payload = {
    data: {
      ...incomingData,
    },
  };

  return await dataAccess.post(url, payload);
};
