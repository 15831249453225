import React from 'react';
import BasePage from 'pages/BasePage'; 
import PlanActivationContainer from 'app/container/PlanActivation';

const PlanActivation = () => {
  return (
    <>
      <BasePage>
         <PlanActivationContainer />
      </BasePage>
    </>
  );
};

export default PlanActivation;
