import RadioButton from 'app/shared/components/RadioButton';
import Toast from 'app/utils/Toast';
import { useEffect, useState } from 'react';

function AddedQuiz({ quizArray, onUpdateQuiz }) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentQuiz, setCurrentQuiz] = useState({});

  const updateQuizHandler = (updatedQuiz) => {
    setIsLoading(true);

    const newUpdatedQuiz = quizArray.map((quiz) => {
      if (quiz.question_id === updatedQuiz.question_id) {
        quiz = updatedQuiz;
      }
      return quiz;
    });

    onUpdateQuiz(newUpdatedQuiz);
    setIsEditMode(false);
    setIsLoading(false);
  };

  if (isLoading) {
    return <h6>Loading...</h6>;
  }
  return (
    <>
      {isEditMode ? (
        <table>
          <thead>
            <tr>
              <th>Id</th>
              <th>Question Type</th>
              <th>Question</th>
              <th>Answers</th>
              <th>Explanation</th>
              <th>Points</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <Quiz quiz={currentQuiz} onUpdateQuiz={updateQuizHandler} />
          </tbody>
        </table>
      ) : (
        <>
          {' '}
          {quizArray?.length > 0 && (
            <table>
              <tr>
                <th>No</th>
                <th>Question Type</th>
                <th>Question Text</th>
                <th>Answers</th>
                <th>Explanation</th>
                <th>Coins Awareded</th>
                <th>Edit</th>
              </tr>
              {quizArray?.map((question) => (
                <tr key={question.id}>
                  <td>{question.question_id}</td>
                  <td>
                    {question.question_type === 'boolean'
                      ? 'True or False'
                      : 'Single Select'}
                  </td>
                  <td>{question.question}</td>
                  <td>
                    {question?.options?.map((q, key) => (
                      <p>{q.cms_answer ? q.title : ''}</p>
                    ))}
                  </td>
                  <td>
                    {question?.options?.map((q, key) => (
                      <p>{q.cms_answer ? q.explanation : ''}</p>
                    ))}
                  </td>
                  <td>{question.coins_awarded}</td>

                  <td>
                    <button
                      className='btn border bg-white'
                      onClick={() => {
                        setCurrentQuiz(question);
                        setIsEditMode(true);
                      }}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </table>
          )}
        </>
      )}
    </>
  );
}

const Quiz = ({ quiz, onUpdateQuiz }) => {
  const [quizSmallDetails, setQuizSmallDetails] = useState({
    cmsAns: '',
    explanation: '',
  });
  const [quizOptions] = useState([]);

  useEffect(() => {
    let isSet = false;
    if (isSet) return;
    quiz.options?.map((opt) => {
      if (opt?.cms_answer) {
        setQuizSmallDetails({
          ...quizSmallDetails,
          cmsAns: opt?.id,
          explanation: opt.explanation,
        });
      }
      quizOptions.push({
        label: opt?.title,
        value: opt?.id,
        id: opt?.id,
      });
    });

    return () => (isSet = true);
  }, []);

  if (quiz?.question_type === 'boolean') {
    return (
      <BooleanType
        quiz={quiz}
        quizDetail={quizSmallDetails}
        onAddExplanation={(e) =>
          setQuizSmallDetails({
            ...quizSmallDetails,
            explanation: e.target.value,
          })
        }
        onUpdateQuiz={onUpdateQuiz}
      />
    );
  }

  if (
    quiz?.question_type === 'mcq' ||
    quiz?.question_type === 'single_select'
  ) {
    return (
      <SingleSelectType
        quizDetail={quizSmallDetails}
        quiz={quiz}
        onAddExplanation={(e) =>
          setQuizSmallDetails({
            ...quizSmallDetails,
            explanation: e.target.value,
          })
        }
        quizOptions={quizOptions}
        onUpdateQuiz={onUpdateQuiz}
      />
    );
  }

  return <div>Can't find quiz</div>;
};

const SingleSelectType = ({
  quiz,
  quizDetail,
  onAddExplanation,
  quizOptions,
  onUpdateQuiz,
}) => {
  const [quizState, setQuizState] = useState({
    question_id: quiz?.question_id || '',
    question_type: quiz?.question_type || '',
    question: quiz?.question || '',
    coins_awarded: quiz?.coins_awarded || '',
    options: quiz?.options || '',
  });
  const [addedQues, setAddedQeus] = useState(quizOptions || []);
  const [updateOptions, setUpdatedOptions] = useState(quizOptions || []);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedQues, setSelectedQues] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);

  function inputChangeHandler(event) {
    const { name, value } = event.target;
    setQuizState({ ...quizState, [name]: value });
  }

  useEffect(() => {
    const findIndex = quiz.options.findIndex((item) => item.cms_answer);
    setSelectedQues(findIndex + 1);
  }, []);

  function transformObject(obj, ans) {
    let title = '';
    for (let key in obj) {
      if (obj[key].id === ans) {
        obj[key].cms_answer = true;
        obj[key].explanation = false;
      } else {
        obj[key].explanation = true;
      }
      obj[key].title = obj[key].label;
      delete obj[key]['label'];
      delete obj[key]['value'];
    }
    for (let key in obj) {
      if (!obj[key].explanation) {
        obj[key].explanation = false;
        title = obj[key].title;
      }
    }
    for (let key in obj) {
      if (!obj[key].explanation) {
        obj[key].explanation = quizDetail.explanation;
      } else {
        obj[
          key
        ].explanation = `${title} is the right answer. ${quizDetail.explanation}`;
      }
    }
    return obj;
  }

  function updateQuizHandler() {
    if (selectedQues == 0) {
      Toast.error('Please Select a right answer!');
      return;
    }

    const newOptions = transformObject(addedQues, selectedQues);
    quizState.options = newOptions;

    if (
      quizState.coins_awarded === '' ||
      !quizState.question ||
      quizState.question_id === '' ||
      !quizState.question_type ||
      quizState.options.length <= 0
    ) {
      Toast.error('All fields are required!');
      return;
    }
    quizState.coins_awarded = +quizState.coins_awarded;
    onUpdateQuiz(quizState);
  }

  return (
    <tr key={quiz?.id}>
      <td>{quizState.question_id}</td>

      <td>
        {quiz?.question_type === 'boolean' ? 'True or False' : 'Single Select'}
      </td>
      <td>
        <textarea
          required
          disabled={false}
          placeholder={'Question'}
          onChange={inputChangeHandler}
          value={quizState.question}
          name={'question'}
        />
      </td>
      {isEditMode ? (
        <td className='edit-quiz-options-box'>
          {addedQues.map((option) => (
            <EditOption
              key={option.id}
              option={option}
              onEditOption={(updatedOpt) => {
                let newArr = updateOptions.map((opt) => {
                  if (opt.id == updatedOpt.id) {
                    opt = updatedOpt;
                  }
                  return opt;
                });
                setUpdatedOptions(newArr);
              }}
            />
          ))}
          <div>
            <button
              onClick={() => {
                setAddedQeus(updateOptions);
                setIsEditMode(false);
              }}
            >
              update
            </button>
            <button onClick={() => setIsEditMode(false)}>cancel</button>
          </div>
        </td>
      ) : (
        <td>
          {addedQues.map((item, key) => (
            <RadioButton
              disabled={isDisabled}
              id={item.id}
              label={item.label}
              isChecked={selectedQues == item.value ? true : false}
              value={item.value}
              onSelect={() => {
                setSelectedQues(item.value);
              }}
              name={item.label}
            />
          ))}
          <button onClick={() => setIsEditMode(true)}>Edit options</button>
        </td>
      )}
      <td>
        <textarea
          disabled={false}
          placeholder={'explanation'}
          onChange={onAddExplanation}
          value={quizDetail.explanation}
          name={'explanation'}
        />
      </td>
      <td>
        <input
          disabled={isDisabled}
          placeholder='Point'
          name={'coins_awarded'}
          type={'number'}
          value={quizState.coins_awarded}
          onChange={inputChangeHandler}
        />
      </td>
      <td>
        <button onClick={updateQuizHandler} className='btn primary'>
          Update
        </button>
      </td>
    </tr>
  );
};

const EditOption = ({ option, onEditOption }) => {
  const [opt, setOpt] = useState(option || {});
  const [isAdded, setIsAdded] = useState(false);

  function quizOptionsEditHandler(event) {
    const { value } = event.target;
    setOpt({ ...opt, label: value });
  }

  return (
    <div className='option-inputAndBtn'>
      <input
        key={opt.id}
        value={opt.label}
        name={opt.id}
        onChange={quizOptionsEditHandler}
      />
      <button
        disabled={isAdded}
        onClick={() => {
          onEditOption(opt);
          setIsAdded(true);
        }}
      >
        {isAdded ? 'Added' : 'Add'}
      </button>
    </div>
  );
};

const BooleanType = ({ quiz, quizDetail, onAddExplanation, onUpdateQuiz }) => {
  const trueOrFalse = [
    {
      label: 'True',
      value: 1,
      id: 1,
    },
    {
      label: 'False',
      value: 2,
      id: 2,
    },
  ];

  const [quizState, setQuizState] = useState({
    question_id: quiz?.question_id || '',
    question_type: quiz?.question_type || '',
    question: quiz?.question || '',
    coins_awarded: quiz?.coins_awarded || '',
    options: quiz?.options || '',
  });
  const [selectedQues, setSelectedQues] = useState(0);
  function inputChangeHandler(event) {
    const { name, value } = event.target;
    setQuizState({ ...quizState, [name]: value });
  }

  useEffect(() => {
    const findIndex = quiz.options.findIndex((item) => item.cms_answer);
    setSelectedQues(findIndex + 1);
  }, []);

  function transformObject(obj, ans) {
    let title = '';
    for (let key in obj) {
      if (obj[key].id == ans) {
        obj[key].cms_answer = true;
        obj[key].explanation = false;
      } else {
        obj[key].explanation = true;
      }
      obj[key].title = obj[key].label;
      delete obj[key]['label'];
      delete obj[key]['value'];
    }
    for (let key in obj) {
      if (!obj[key].explanation) {
        obj[key].explanation = false;
        title = obj[key].title;
      }
    }
    for (let key in obj) {
      if (!obj[key].explanation) {
        obj[key].explanation = quizDetail.explanation;
      } else {
        obj[
          key
        ].explanation = `${title} is the right answer. ${quizDetail.explanation}`;
      }
    }
    return obj;
  }

  function updateQuizHandler() {
    if (selectedQues == 0) {
      Toast.error('Please Select a right answer!');
      return;
    }

    const newOptions = transformObject(trueOrFalse, selectedQues);
    quizState.options = newOptions;

    if (
      quizState.coins_awarded == '' ||
      !quizState.question ||
      quizState.question_id === '' ||
      !quizState.question_type ||
      quizState.options.length <= 0
    ) {
      Toast.error('All fields are required!');
      return;
    }
    quizState.coins_awarded = +quizState.coins_awarded;
    onUpdateQuiz(quizState);
  }

  return (
    <tr key={quiz?.id}>
      <td>{quizState.question_id}</td>
      <td>
        {quiz?.question_type == 'boolean' ? 'True or False' : 'Single Select'}
      </td>
      <td>
        <textarea
          required
          disabled={false}
          placeholder={'Question'}
          onChange={inputChangeHandler}
          value={quizState.question}
          name={'question'}
        />
      </td>
      <td>
        {trueOrFalse.map((item, key) => (
          <RadioButton
            id={item.id}
            label={item.label}
            isChecked={selectedQues == item.value ? true : false}
            value={item.value}
            onSelect={() => {
              setSelectedQues(item.value);
            }}
            name={item.label}
          />
        ))}
      </td>
      <td>
        <textarea
          disabled={false}
          placeholder={'explanation'}
          onChange={onAddExplanation}
          value={quizDetail.explanation}
          name={'explanation'}
        />
      </td>
      <td>
        <input
          placeholder='Point'
          name={'coins_awarded'}
          type={'number'}
          value={quizState.coins_awarded}
          onChange={inputChangeHandler}
        />
      </td>
      <td>
        <button onClick={updateQuizHandler} className='btn primary'>
          Update
        </button>
      </td>
    </tr>
  );
};

export default AddedQuiz;
