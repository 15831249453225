import React, { useState } from 'react';

// Pages
import BasePage from 'pages/BasePage';
import CreateFeed from 'container/CreateFeed';

const CreateBlogsPage = (props) => {
  console.log('eeee', props);
  const [showPreview, setShowPreview] = useState(false);
  const [data, setData] = useState(null);

  const handlePreviewClicked = (data) => {
    setData(data);
    setShowPreview(!showPreview);
  };

  return (
    <BasePage
      showPreview={showPreview}
      data={data}
      onCloseClicked={handlePreviewClicked}
    >
      <CreateFeed onPreview={handlePreviewClicked} />
    </BasePage>
  );
};

export default CreateBlogsPage;
