import RadioButton from 'app/shared/components/RadioButton';
import Button from 'app/components/button';
import '../index.scss';
import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import NewInput from 'app/components/NewInput';
import Toast from 'app/utils/Toast';
import PageLoader from 'app/components/PageLoader';
import MultiSelectDropdown from 'app/components/MultiSelectDropdown';
import Dropdown from 'app/shared/components/Dropdown';
import {
  createMilestone,
  updateMilestone,
  getUserJourneyCouponList,
  getRewardLocalisationDetailsByID,
  updateMilestoneLocalisation,
} from 'app/api/user-journey';
import { getVersionControl } from 'app/api/versionList';
import { getWidgetType } from 'app/api/widgets';
import useTabBarOptions from 'app/utils/useTabbarOptions';
import TabBar from 'app/components/NewDesign/TabBar';
import VersionDropDown from 'app/components/VersionDropDown';
import moment from 'moment';
import WebinarCalendar from 'app/components/WebinarCalendar';

const CreateMilestoneContainer = () => {
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const params = useParams();
  const { LanguageOptions } = useTabBarOptions();
  let [versionLists, setVersionLists] = useState([]);
  let [iosversionLists, setIosVersionLists] = useState([]);
  const [cohortOptions, setCohortOptions] = useState([]);
  const [ageOptions, setAgeOptions] = useState([]);
  const [couponOptions, setCouponOptions] = useState([]);
  const [appVersion, setappVersion] = useState('Android');
  const [activeTab, setActiveTab] = useState({
    id: '1',
    name: 'English',
    short_name: 'en',
    display_order: 1,
    status: 'ACTIVE',
    value: 'English',
  });
  const [disableFields, setDisableFields] = useState(false);
  const [state, setState] = useState({
    coupon_title: '',
    coupon_id: '',
    reward_message_title: '',
    reward_message_subtitle: '',
    reward_type: '',
    streak_milestone: '',
    streak_type: '',
    reward_value: '',
    valid_for: '',
    expiry_on: '',
    redeem_link: '',
    android_version: '',
    criteria: '',
    ios_version: '',
    ios_criteria: '',
    cohort_tags: [],
    allotted_point: null,
  });
  let history = useHistory();
  useEffect(() => {
    async function fetchVersionControl() {
      let response = await getVersionControl(appVersion);

      const data = response.data.available_versions;
      let newState = data.map((key) => {
        return {
          value: [key][0].toString(),
          label: [key][0].toString(),
        };
      });
      setVersionLists(newState);

      let iosResponse = await getVersionControl('IOS');

      const iosdata = iosResponse.data.ios_available_versions;
      let newiosState = iosdata.map((key) => {
        return {
          value: [key][0].toString(),
          label: [key][0].toString(),
        };
      });
      setIosVersionLists(newiosState);
    }
    fetchVersionControl();
    getDropdown();
  }, []);

  useEffect(() => {
    if (params.id) {
      getDetails(params.id);
    }
  }, [activeTab?.name, params.id]);

  const getDetails = async (id) => {
    const resp = await getRewardLocalisationDetailsByID(
      id,
      activeTab?.short_name
    );
    if (resp) {
      let milestoneDetails = resp;
      if (activeTab?.name === 'English') {
        setState({
          coupon_title: milestoneDetails.coupon_title,
          coupon_id: milestoneDetails.coupon_id,
          allotted_point: milestoneDetails.allotted_point,
          reward_message_title: milestoneDetails.reward_message_title
            ? milestoneDetails.reward_message_title
            : '',
          reward_message_subtitle: milestoneDetails.reward_message_subtitle
            ? milestoneDetails.reward_message_subtitle
            : '',
          reward_type: milestoneDetails.reward_type
            ? milestoneDetails.reward_type
            : '',
          streak_milestone: milestoneDetails.streak_milestone
            ? milestoneDetails.streak_milestone
            : '',
          streak_type: milestoneDetails.streak_type
            ? milestoneDetails.streak_type
            : '',
          reward_value: milestoneDetails.reward_value
            ? milestoneDetails.reward_value
            : '',
          valid_for: milestoneDetails.valid_for
            ? milestoneDetails.valid_for
            : '',
          redeem_link: milestoneDetails.redeem_link
            ? milestoneDetails.redeem_link
            : '',
          android_version: milestoneDetails.android_version
            ? milestoneDetails.android_version.toString()
            : '',
          criteria: milestoneDetails.criteria ? milestoneDetails.criteria : '',
          ios_version: milestoneDetails.ios_version
            ? milestoneDetails.ios_version.toString()
            : '',
          ios_criteria: milestoneDetails.ios_criteria
            ? milestoneDetails.ios_criteria
              ? milestoneDetails.ios_criteria
              : ''
            : '',
          cohort_tags: milestoneDetails.cohort_tag
            ? milestoneDetails.cohort_tag
            : [],
          expiry_on: milestoneDetails.expiry_on
            ? moment(milestoneDetails.expiry_on).format('YYYY-MM-DD HH:mm:ss')
            : '',
        });
      } else {
        setState((prev) => ({
          ...prev,
          coupon_title: milestoneDetails.coupon_title
            ? milestoneDetails.coupon_title
            : '',
          reward_message_title: milestoneDetails.reward_message_title
            ? milestoneDetails.reward_message_title
            : '',
          reward_message_subtitle: milestoneDetails.reward_message_subtitle
            ? milestoneDetails.reward_message_subtitle
            : '',
          valid_for: milestoneDetails.valid_for
            ? milestoneDetails.valid_for
            : '',
        }));
      }
    }
  };

  const getDropdown = async () => {
    setLoader(true);
    const dataArr = await getWidgetType();
    let tempAgeArray = [];
    let tempCohortArray = [];

    dataArr.data.age_tags.map((item, i) => {
      tempAgeArray.push({
        name: item,
        value: item,
      });
    });
    dataArr.data.cohort_tags.map((item, i) => {
      tempCohortArray.push({
        name: item,
        value: item,
      });
    });

    const couponlist = await getUserJourneyCouponList();
    let tempCoupontArray = [];
    couponlist.map((item, i) => {
      tempCoupontArray.push({
        label: item.id + '-' + item.coupon_code,
        value: item.id,
      });
    });
    setCouponOptions(tempCoupontArray);
    setCohortOptions(tempCohortArray);
    setAgeOptions(tempAgeArray);
    setLoader(false);
  };

  const VersionRange = [
    {
      value: 'GREATER_THAN',
      label: 'Greater Than',
    },
    {
      value: 'LESS_THAN',
      label: 'Lesser Than',
    },
    {
      value: 'EQUALS',
      label: 'Equals',
    },
  ];

  const rewardTypeOptions = [
    {
      label: 'Coins',
      id: '1',
      value: 'COIN',
    },
    {
      label: 'Coupons',
      id: '3',
      value: 'COUPON',
    },
  ];
  //   const milestoneTypeOptions = [
  //     {
  //       label: 'Coins',
  //       id: '1',
  //       value: 'COIN',
  //     },
  //     {
  //       label: 'Coupons',
  //       id: '3',
  //       value: 'COUPON',
  //     },
  //     {
  //       label: 'Medicine',
  //       id: '2',
  //       value: 'MEDICINE',
  //     },
  //     {
  //       label: 'Services',
  //       id: '4',
  //       value: 'SERVICES',
  //     },
  //   ];
  const streakTypeOptions = [
    {
      label: 'App Streak',
      id: '1',
      value: 'APP',
    },
    {
      label: 'Medicine Streak',
      id: '2',
      value: 'REMINDER',
    },

    {
      label: 'Exercise',
      id: '3',
      value: 'EXERCISE',
    },
    {
      label: 'Content Streak',
      id: '4',
      value: 'CONTENT',
    },
  ];

  const handleTextChange = (e) => {
    if (e.target.name === 'redirection_id') {
      setState({ ...state, webinar_id: e.target.value });
    } else if (e.target.name === 'reward_message_subtitle') {
      if (e.target.value.length <= 24) {
        setState({ ...state, [e.target.name]: e.target.value });
      }
    } else if (
      e.target.name === 'reward_message_title' ||
      e.target.name === 'coupon_title'
    ) {
      if (e.target.value.length <= 34) {
        setState({ ...state, [e.target.name]: e.target.value });
      }
    } else {
      setState({ ...state, [e.target.name]: e.target.value });
    }
  };
  const handleDateAndTime = (date) => {
    setState({ ...state, expiry_on: date });
  };

  const handleSubmit = async () => {
    let payload = { ...state };
    setLoader(true);
    let responseObj;
    if (params.id) {
      payload.reward_id = params.id;
      if (activeTab?.name === 'English') {
        responseObj = await updateMilestone(payload);
      } else {
        let newPayload = {
          reward_id: Number(params.id),
          coupon_title: payload?.coupon_title,
          reward_message_title: payload?.reward_message_title,
          reward_message_subtitle: payload?.reward_message_subtitle,
          valid_for: payload?.valid_for,
        };
        responseObj = await updateMilestoneLocalisation(
          newPayload,
          activeTab?.short_name
        );
      }
    } else {
      responseObj = await createMilestone(payload);
    }

    if (
      responseObj &&
      responseObj.response &&
      responseObj.response.code === 200
    ) {
      Toast.success(responseObj.response.alert[0].message);
      history.push('/userjourney/milestones');
    } else {
      setLoader(false);
      Toast.error(
        responseObj.data
          ? responseObj.data.msg
          : responseObj.response.alert[0].message
          ? responseObj.response.alert[0].message
          : ''
      );
    }
  };
  const handleTabChange = (val) => {
    setActiveTab(val);
    if (val?.name === 'English') {
      setDisableFields(false);
    } else {
      setDisableFields(true);
    }
  };
  return (
    <div className='container'>
      {params.id ? (
        <h1 className='section-header__title'>
          User Journey {`>`} Milestone {`>`} Edit{' '}
        </h1>
      ) : (
        <h1 className='section-header__title'>User Journey {`>`} Milestone</h1>
      )}
      <div className='cms-content__container'>
        {params.id ? (
          <div className='col-md-4 d-flex'>
            <b>Select Language</b>
          </div>
        ) : null}
        {params.id ? (
          <TabBar
            tabs={LanguageOptions}
            initialTab={activeTab}
            onTabChange={handleTabChange}
            parentStyle={'service-tab-style'}
          />
        ) : null}
        <div className='separator'>
          <p>Add Milestone</p>
        </div>
        <div className='userInput'>
          <div className='row'>
            <div className='col-sm-4'>
              <NewInput
                label='Milestone Name'
                name={'coupon_title'}
                value={state.coupon_title}
                onChange={handleTextChange}
              />
            </div>
            <div className='col-sm-4'>
              <NewInput
                label='Reward Messaging Title'
                name={'reward_message_title'}
                value={state.reward_message_title}
                onChange={handleTextChange}
              />
            </div>
            <div className='col-sm-4'>
              <NewInput
                label='Reward Messaging Subtitle'
                name={'reward_message_subtitle'}
                value={state.reward_message_subtitle}
                onChange={handleTextChange}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-4'>
              <div className='logonWrapper-text'>
                <label>Reward Type</label>
                <select
                  name='reward_type'
                  className='custom-select w-100'
                  onChange={(e) => {
                    handleTextChange(e);
                  }}
                  disabled={disableFields}
                >
                  <option value=''>Select</option>
                  {rewardTypeOptions?.map((item, indx) => (
                    <option
                      key={indx}
                      value={item.value}
                      selected={state.reward_type == item.value ? true : false}
                    >
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {state.reward_type === 'COIN' && (
              <div className='col-md-4'>
                <NewInput
                  label='Coin'
                  name={'allotted_point'}
                  value={state.allotted_point}
                  onChange={handleTextChange}
                  type={'number'}
                  isDisabled={disableFields}
                />
              </div>
            )}
            {state.reward_type === 'COUPON' && (
              <div className='col-md-4'>
                <Dropdown
                  label='Select Coupon'
                  placeholder='Select Coupon'
                  name='coupon_id'
                  value={state.coupon_id}
                  options={couponOptions}
                  handleChange={handleTextChange}
                  isDisabled={disableFields}
                />
              </div>
            )}
            {/* <div className="col-sm-4">
                <div className="logonWrapper-text">
                    <label>Milestone Type</label> 
                    <select
                        name="milestone_type"
                        
                        className="custom-select w-100" onChange={(e) => {
                            handleTextChange(e)
                        }}>
                        <option value="">Select</option>
                        {
                            milestoneTypeOptions?.map((item, indx) => (
                                <option key={indx}
                                    value={item.value}
                                    selected={state.milestone_type == item.value ? true : false}
                                >
                                    {item.label}</option>
                            ))
                        }

                    </select>
                </div>  
            </div> */}

            <div className='col-sm-4'>
              <NewInput
                label='Streak Day'
                name={'streak_milestone'}
                value={state.streak_milestone}
                onChange={handleTextChange}
                type='number'
                isDisabled={disableFields}
              />
            </div>
            <div className='col-sm-4'>
              <div className='logonWrapper-text'>
                <label>Streak Type</label>
                <select
                  name='streak_type'
                  className='custom-select w-100'
                  onChange={(e) => {
                    handleTextChange(e);
                  }}
                  disabled={disableFields}
                >
                  <option value=''>Select</option>
                  {streakTypeOptions?.map((item, indx) => (
                    <option
                      key={indx}
                      value={item.value}
                      selected={state.streak_type == item.value ? true : false}
                    >
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* </div> */}
            {/* <div className="row"> */}
            <div className='col-sm-4'>
              <NewInput
                label='Reward Value'
                name={'reward_value'}
                value={state.reward_value}
                onChange={handleTextChange}
                isDisabled={disableFields}
              />
            </div>
            <div className='col-sm-4'>
              {/* <NewInput
                label='Valid For'
                name={'valid_for'}
                value={state.valid_for}
                onChange={handleTextChange}
              /> */}
              <WebinarCalendar
                onStartDateSelected={handleDateAndTime}
                startDate={state.expiry_on}
                label='Expiry On'
                minDate={new Date()}
              />
            </div>
            <div className='col-sm-4'>
              <NewInput
                label='Redeem Link'
                name={'redeem_link'}
                value={state.redeem_link}
                onChange={handleTextChange}
                isDisabled={disableFields}
              />
            </div>
            {/* </div> */}
            {/* <div className="row"> */}
            {/* <div className='col-sm-4'>
              <Dropdown
                label='Selected Version'
                placeholder='Selected Version'
                name='android_version'
                value={state.android_version}
                options={versionLists}
                handleChange={handleTextChange}
                isDisabled={disableFields}
              />
            </div>
            <div className='col-sm-4'>
              <Dropdown
                label='Version Range'
                placeholder='Version Range'
                name='criteria'
                value={state.criteria}
                options={VersionRange}
                handleChange={handleTextChange}
                isDisabled={disableFields}
              />
            </div> */}
            <div className='col-sm-4'>
              <MultiSelectDropdown
                label='Cohort Tag'
                name='cohort_tags'
                value={state.cohort_tags}
                options={cohortOptions}
                onChange={handleTextChange}
                tag_type={false}
                disable={disableFields}
              />
            </div>
            <VersionDropDown
              android_version={state.android_version}
              criteria={state.criteria}
              versionLists={versionLists}
              iosLists={iosversionLists}
              VersionRange={VersionRange}
              handleTextChange={handleTextChange}
              ios_version={state.ios_version}
              ios_criteria={state.ios_criteria}
            />
          </div>

          <div className='userFormArea'>
            <Button name={'Save'} onClick={handleSubmit} />
            <label
              style={{ paddingLeft: '10px', cursor: 'pointer' }}
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </label>
          </div>
        </div>
      </div>
      {loader && <PageLoader pageLoading={loader} />}
    </div>
  );
};

export default CreateMilestoneContainer;
