import {
  deleteDiscoverHomeWidget,
  discoverHomeWidgetPublishOrUnpublishAPI,
} from 'app/api/cmsDiscoverApi';
import Search from 'app/components/search';
// import Pagination from "app/container/CmsContent/pagination";
import SelectComp from 'app/container/CmsContent/select-tag';
import Toast from 'app/utils/Toast';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import Pagination from 'app/components/ReactPagination';

import {
  addDiscoverGroup,
  getCmsSelectContent,
  updateDiscoverGroups,
} from 'app/api/cms-content';
import PageLoader from 'app/components/PageLoader';
import './index.scss';

const options = [
  //  { value: 5, label: 5, },
  { value: 10, label: 10 },
  { value: 15, label: 15 },
  { value: 20, label: 20 },
  { value: 25, label: 25 },
];

const sortOptions = [
  { value: 'asce', label: 'Ascending' },
  { value: 'desc', label: 'Descending' },
  { value: 'updateon', label: 'Updated On' },
];
const SelectContentTable = () => {
  const history = useHistory();
  const [dataCollection, setDataCollection] = useState([]);
  const [totalDataCollection, setTotalDataCollection] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [searchId, setSearchId] = useState('');
  const [loader, setLoader] = useState(false);
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  let currentData = dataCollection.slice(indexOfFirstData, indexOfLastData);

  useEffect(() => {
    handlesGetData();
  }, []);

  useEffect(() => {
    getDataHandler(searchValue);
  }, [searchValue, searchId]);

  function inputChangeHandler(value, key) {
    if (key === 'name') {
      setSearchValue(value.toLowerCase());
    } else if (key === 'id') {
      setSearchId(value);
    }
  }

  async function handlesGetData() {
    setLoader(true);
    let data = await getCmsSelectContent();
    let updatedData = [];
    data.forEach((item) => {
      updatedData.push({
        asthma_order: null,
        copd_order: null,
        ild_order: null,
        ar_order: null,
        other_order: null,
        isDisabled: true,
        ...item,
      });
    });

    let selectedArray = [];
    let nonSelectedArray = [];
    updatedData.forEach((item) => {
      if (item.is_selected) {
        selectedArray.push(item);
      } else {
        nonSelectedArray.push(item);
      }
    });
    const numAscending = [...nonSelectedArray].sort(
      (a, b) => b.content_id - a.content_id
    );

    let newDataCollection = [];
    newDataCollection.push(...selectedArray);
    newDataCollection.push(...numAscending);
    setDataCollection(newDataCollection);
    setTotalDataCollection(newDataCollection);
    let count = Math.ceil(data.length / dataPerPage);
    setPageCount(count);
    setLoader(false);
  }

  async function getDataHandler(search) {
    let count;
    if (search !== '') {
      // const data = await getCmsSelectContent();
      let filteredData = [];
      totalDataCollection.forEach((data) => {
        if (data.title.toLowerCase().includes(search.toLowerCase())) {
          filteredData.push(data);
        }
      });

      if (searchId !== '') {
        let newfilteredData = [];
        filteredData.forEach((data) => {
          if (data.content_id.toString().includes(searchId.toString())) {
            newfilteredData.push(data);
          }
        });
        count = Math.ceil(newfilteredData.length / dataPerPage);
        setDataCollection(newfilteredData);
        setPageCount(count);
      } else {
        count = Math.ceil(filteredData.length / dataPerPage);
        setDataCollection(filteredData);
        setPageCount(count);
      }
    } else if (searchId !== '') {
      let filteredData = [];
      dataCollection.forEach((data) => {
        if (data.content_id.toString().includes(searchId.toString())) {
          filteredData.push(data);
        }
      });
      count = Math.ceil(filteredData.length / dataPerPage);
      setDataCollection(filteredData);
      setPageCount(count);
    } else {
      // handlesGetData();
      count = Math.ceil(totalDataCollection.length / dataPerPage);
      setDataCollection(totalDataCollection);
      setPageCount(count);
    }
  }

  // Pagination
  function paginationHandler(page) {
    setCurrentPage(page);
  }
  function changeHandler(event, key) {
    if (key) {
      if (event.target.value === 'asce') {
        let selectedArray = [];
        let nonSelectedArray = [];
        dataCollection.forEach((item) => {
          if (item.is_selected) {
            selectedArray.push(item);
          } else {
            nonSelectedArray.push(item);
          }
        });
        const ascendingSelectedArray = [...selectedArray].sort(
          (a, b) => a.content_id - b.content_id
        );
        const numAscending = [...nonSelectedArray].sort(
          (a, b) => a.content_id - b.content_id
        );

        let newDataCollection = [];
        newDataCollection.push(...ascendingSelectedArray);
        newDataCollection.push(...numAscending);
        setDataCollection(newDataCollection);

        // const numAscending = [...dataCollection].sort((a, b) => a.content_id - b.content_id);

        // setDataCollection(numAscending)
      } else if (event.target.value === 'desc') {
        let selectedArray = [];
        let nonSelectedArray = [];
        dataCollection.forEach((item) => {
          if (item.is_selected) {
            selectedArray.push(item);
          } else {
            nonSelectedArray.push(item);
          }
        });
        const ascendingSelectedArray = [...selectedArray].sort(
          (a, b) => b.content_id - a.content_id
        );
        const numAscending = [...nonSelectedArray].sort(
          (a, b) => b.content_id - a.content_id
        );

        let newDataCollection = [];
        newDataCollection.push(...ascendingSelectedArray);
        newDataCollection.push(...numAscending);
        setDataCollection(newDataCollection);

        // const numAscending = [...dataCollection].sort((a, b) => b.content_id - a.content_id);

        // setDataCollection(numAscending)
      } else if (event.target.value === 'updateon') {
        setDataCollection(totalDataCollection);
      }
    } else {
      const pageLength = event.target.value;
      setDataPerPage(pageLength);
      let count = Math.ceil(dataCollection.length / pageLength);
      setPageCount(count);
    }
  }

  const handleDelete = async (id) => {
    await deleteDiscoverHomeWidget(id);
    handlesGetData();
  };

  const handleSelect = async (e, data, index) => {
    if (!data.is_selected) {
      let {
        asthma_order,
        copd_order,
        ild_order,
        ar_order,
        other_order,
        content_id,
      } = data;
      let payload = {
        asthma_order,
        copd_order,
        ild_order,
        ar_order,
        other_order,
        content_id,
      };
      let tempData = [];
      dataCollection.forEach((val, i) => {
        if (index === i) {
          let temp = val;
          val['is_selected'] = e.target.value;
          tempData.push(temp);
        } else {
          tempData.push(val);
        }
      });
      setDataCollection(tempData);
      const response = await addDiscoverGroup(payload);
      handlesGetData();
    } else {
      handleDelete(data.id);
    }
  };

  const handleInputChangeHandler = (key, e, index) => {
    let tempData = [];
    dataCollection.forEach((val, i) => {
      if (index === i) {
        let temp = val;
        val[key] = Number(e.target.value) ? Number(e.target.value) : '';
        tempData.push(temp);
      } else {
        tempData.push(val);
      }
    });
    setDataCollection(tempData);
  };
  const handleEdit = async (index, key, data) => {
    if (key === 'Edit') {
      let tempData = [];
      dataCollection.forEach((val, i) => {
        if (index === i) {
          let temp = val;
          val['isDisabled'] = false;
          tempData.push(temp);
        } else {
          tempData.push(val);
        }
      });
      setDataCollection(tempData);
    } else {
      let {
        asthma_order,
        copd_order,
        ild_order,
        ar_order,
        other_order,
        content_id,
        id,
      } = data;
      let payload = {
        asthma_order: asthma_order ? asthma_order : null,
        copd_order: copd_order ? copd_order : null,
        ild_order: ild_order ? ild_order : null,
        ar_order: ar_order ? ar_order : null,
        other_order: other_order ? other_order : null,
        content_id,
        id,
      };

      const responseObj = await updateDiscoverGroups(payload);

      if (
        responseObj &&
        responseObj.response &&
        responseObj.response.code === 200
      ) {
        Toast.success(responseObj.response.alert[0].message);
        let tempData = [];
        dataCollection.forEach((val, i) => {
          if (index === i) {
            let temp = val;
            val['isDisabled'] = true;
            tempData.push(temp);
          } else {
            tempData.push(val);
          }
        });
        setDataCollection(tempData);
        handlesGetData();
      } else {
        Toast.error(
          responseObj.data && responseObj.data.msg.error
            ? responseObj.data.msg.error
            : responseObj.response.alert[0].message
        );
      }
    }
  };

  const publishOrUnpublish = async (data) => {
    const query = {
      ACTIVE: 'unpublish',
      INACTIVE: 'PUBLISH',
    };
    const resp = await discoverHomeWidgetPublishOrUnpublishAPI(
      data.id,
      query[data.status]
    );
    if (resp.response.code === 200) {
      Toast.success(resp.response.alert[0].message);
    } else {
      Toast.error(resp.response.alert[0].message);
    }
    handlesGetData();
  };
  let pageMeta = {
    total: dataCollection,
    pageSize: Number(dataPerPage),
    page: currentPage,
    pageCount: pageCount,
  };
  return (
    <div className='table_container'>
      <h4>Home Widget Content</h4>
      <div className='table_container__actionBox'>
        <div className='table_container__filterSearch col-auto'>
          <Search
            value={searchValue}
            placeholder='Search Name'
            onChange={(value) => inputChangeHandler(value, 'name')}
          />
        </div>
        <div className='table_container__filterSearch col'>
          <Search
            value={searchId}
            placeholder='Search Id'
            onChange={(value) => inputChangeHandler(value, 'id')}
          />
        </div>
      </div>
      <div className='table_container__items_perPage'>
        Show
        <SelectComp
          options={options}
          onChangeHandler={(e) => changeHandler(e)}
        />
        entries &nbsp;&nbsp;&nbsp;&nbsp; Sort by ID
        <SelectComp
          options={sortOptions}
          selected={'updateon'}
          // selectedTitle={'Update On'}
          onChangeHandler={(e) => changeHandler(e, 'sort')}
        />
      </div>
      {loader && <PageLoader pageLoading={loader} />}
      <div className='table-box'>
        <table>
          <tr>
            <th>Content Name</th>
            <th>Content ID</th>
            <th>Published Date</th>
            <th>Updated Date</th>
            <th>Status</th>
            {/* <th>Expired On</th> */}
            <th>Action</th>
            <th>Asthma Order</th>
            <th>COPD Order</th>
            <th>ILD Order</th>
            <th>AR Order</th>
            <th>Other Orders</th>
            <th>Select</th>
          </tr>
          {currentData.length > 0 &&
            currentData.map((data, index) => {
              return (
                <tr key={index}>
                  <td>{data.title || '-'}</td>
                  <td>{data.content_id}</td>
                  <td>{data.published_date || '-'}</td>
                  <td>{data.updated_on || '-'}</td>
                  <td>{data.status || '-'}</td>
                  {/* <td>{data.expired_on || "-"}</td> */}
                  <td>
                    <div
                      className='btn-group'
                      role='group'
                      aria-label='Basic outlined example'
                    >
                      <button
                        type='button'
                        className='btn btn-outline-primary'
                        onClick={() => {
                          handleEdit(
                            index,
                            data.isDisabled ? 'Edit' : 'Save',
                            data
                          );
                        }}
                        disabled={!data.is_selected}
                      >
                        {data.isDisabled ? 'Edit' : 'Save'}
                      </button>
                      <button
                        type='button'
                        onClick={() => handleDelete(data.id)}
                        className='btn btn-outline-primary'
                        disabled={!data.is_selected}
                      >
                        Delete
                      </button>
                      <button
                        onClick={() => publishOrUnpublish(data)}
                        type='button'
                        className='btn btn-outline-primary'
                        disabled={!data.is_selected}
                      >
                        {data.status == 'ACTIVE' ? 'Unpublish' : 'Publish'}
                      </button>
                    </div>
                  </td>
                  {/* <td>
                                   <div className="btn-group" role="group" aria-label="Basic outlined example">
                                        <button
                                             type="button"
                                             className="btn btn-outline-primary"
                                             onClick={() => { handleEdit(index, data.isDisabled ? 'Edit': 'Save', data)}}
                                        >
                                             {data.isDisabled ? 'Edit': 'Save'}
                                        </button>
                                        <button type="button"
                                             onClick={() => handleDelete(data.id)}
                                             className="btn btn-outline-primary">Delete</button>
                                       
                                   </div>
                              </td>  */}
                  <td>
                    <input
                      type='text'
                      className='form-input'
                      placeholder=''
                      name='asthma_order'
                      value={data.asthma_order ? data.asthma_order : ''}
                      onChange={(e) =>
                        handleInputChangeHandler('asthma_order', e, index)
                      }
                      disabled={data.isDisabled}
                      key={index}
                    />
                  </td>
                  <td>
                    <input
                      type='text'
                      className='form-input'
                      placeholder=''
                      name='copd_order'
                      value={data.copd_order ? data.copd_order : ''}
                      onChange={(e) =>
                        handleInputChangeHandler('copd_order', e, index)
                      }
                      disabled={data.isDisabled}
                      key={index}
                    />
                  </td>
                  <td>
                    <input
                      type='text'
                      className='form-input'
                      placeholder=''
                      name='ild_order'
                      value={data.ild_order ? data.ild_order : ''}
                      onChange={(e) =>
                        handleInputChangeHandler('ild_order', e, index)
                      }
                      disabled={data.isDisabled}
                      key={index}
                    />
                  </td>
                  <td>
                    <input
                      type='text'
                      className='form-input'
                      placeholder=''
                      name='ar_order'
                      value={data.ar_order ? data.ar_order : ''}
                      onChange={(e) =>
                        handleInputChangeHandler('ar_order', e, index)
                      }
                      disabled={data.isDisabled}
                      key={index}
                    />
                  </td>
                  <td>
                    <input
                      type='number'
                      className='form-input'
                      placeholder=''
                      name='other_order'
                      value={data.other_order ? data.other_order : ''}
                      onChange={(e) =>
                        handleInputChangeHandler('other_order', e, index)
                      }
                      disabled={data.isDisabled}
                      key={index}
                    />
                  </td>
                  <td>
                    <input
                      type='checkbox'
                      id='topping'
                      name='topping'
                      value='Paneer'
                      onChange={(e) => handleSelect(e, data, index)}
                      checked={data.is_selected}
                    />
                  </td>
                </tr>
              );
            })}
        </table>
      </div>

      <div className='table_container__pagination'>
        <Pagination
          pageMeta={pageMeta}
          initialPage={Number(pageCount)}
          handleChange={paginationHandler}
        />
      </div>
    </div>
  );
};

export default SelectContentTable;
