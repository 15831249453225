/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';
import moment from 'moment';

// Container
import CreateQuiz from 'container/CreateQuiz';

// Components
import TextArea from 'components/Textarea';
import NewInput from 'components/NewInput';
import QuillTextEditor from 'app/shared/components/QuillTextEditor';
import Attachment from 'components/Attachment';
import MultiSelectDropdown from 'components/MultiSelectDropdown';
import Button from 'components/button';
import Calender from 'components/Calender';
import CTAComponent from 'components/CTAComponent';
import useContentForm from 'components/useContentForm';
import validate from 'components/ValidateInfo';
import Dropdown from 'shared/components/Dropdown';
import WebinarCalendar from 'components/WebinarCalendar';
import PageLoader from 'components/PageLoader';
import HeaderWithBackButton from 'components/HeaderWithBackButton';
import NumberInput from 'components/UI/NumberInput';
import Checkbox from 'components/checkbox';
import useQuizForm from 'components/useQuizForm';

// API
import { addOrUpdateFeed, getArticleId } from 'api/feed';
import { addOrUpdateQuiz, getQuizDetail } from 'api/quiz';

// Constants
import { APP_CONSTANTS, URLType } from 'constants/app-constants';
import { LocalSessionKey } from 'constants/app-constants';

// Utils
import Toast from 'utils/Toast';
import Message from 'utils/Message';
import ValidationMessage from 'utils/ValidationMessage';
import { LocalStorage } from 'utils/storage';
import DropdownOptions from 'utils/DropdownOptions';

// Module Components
import FeedTypeComponent from 'app/modules/feeds/FeedTypeComponent';

import './index.scss';

const CreateFeed = ({ onPreview, ...props }) => {
  const history = useHistory();
  const location = useLocation();
  const editorRef = useRef(null);

  const [activate, setActivate] = useState(true);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [validated, setValidated] = useState(true);
  const [selFile, setSelectedFile] = useState({});
  const [videoThumbnail, setVideoThumbnail] = useState({});
  const [feedId, setFeedId] = useState();

  const [pageLoading, setPageLoading] = useState(false);
  const [webinarStartDate, setWebinarStartDate] = useState('');
  const [htmlContent, setHTMLContent] = useState('');
  const [showQuiz, setShowQuiz] = useState(false);
  const [quizData, setQuizData] = useState();

  const { handleChange, values, error, updateValue } = useContentForm(validate);

  const {
    quizValues,
    quizError,
    updateQuizValue,
    onHandleChange,
    addQuizSection,
    removeQuizSection,
    onHandleOptionRadioClick,
    onHandleOptionLabelChanged,
    onHandleAddOptionClick,
    onHandleRemoveOption,
  } = useQuizForm(validate);

  const callGetArticleIdAPI = async () => {
    // Get the article id for creating feed
    let articleId = await getArticleId();
    if (articleId) {
      setFeedId(articleId);
    }
  };

  const callQuizDetailAPI = async (quizId) => {
    if (!quizId) return;
    setPageLoading(true);
    const responseObj = await getQuizDetail(quizId);
    setPageLoading(false);
    if (
      responseObj &&
      responseObj.response &&
      responseObj.response.code === 200
    ) {
      let data = responseObj.data;
      setQuizData({
        quiz_id: quizId,
        ...data,
      });
    }
  };

  useEffect(() => {
    let feedData = location.state;
    if (feedData && feedData.article_id) {
      setFeedId(feedData.article_id);
      setHTMLContent(feedData.content);
      let newData = {
        title: feedData.title,
        description: feedData.description,
        content: feedData.content,
        tag: feedData.tag,
        url: feedData.url,
        urlType: feedData.url_type,
        attachment: feedData.attachment,
        age: feedData.age,
        ctaTitle: feedData.cta_title,
        day: feedData.day,
        homepage: feedData.homepage,
        homePageSection: feedData.article_type,
        webinarStartDate: feedData.webinar_time,
        quizId: feedData.quiz_id,
        startDate: feedData.start_date,
        endDate: feedData.end_date,
        videoThumbnail: feedData.video_thumbnail,
      };
      if (newData.urlType === URLType.EXTERNAL) {
        newData.externalUrl = feedData.url ? feedData.url : '';
      }
      if (newData.urlType === URLType.INTERNAL) {
        newData.url = feedData.url ? feedData.url : '';
      }

      if (newData.quizId) {
        newData.quizEnabled = true;
        callQuizDetailAPI(newData.quizId);
      }

      const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
      updateValue(newData);
      if (newData.startDate) {
        setStartDate(moment(newData.startDate).format(dateTimeFormat));
      }
      if (newData.endDate) {
        setEndDate(moment(newData.endDate).format(dateTimeFormat));
      }
      if (newData.webinarStartDate) {
        setWebinarStartDate(
          moment(newData.webinarStartDate).format(dateTimeFormat)
        );
      }
    } else {
      callGetArticleIdAPI();
    }
  }, []);

  useEffect(() => {
    if (values.quizEnabled === true || values.quizEnabled === false) {
      setShowQuiz(values.quizEnabled);
    }
  }, [values.quizEnabled]);

  const populatePayload = (qid) => {
    /* Populate Data */
    let {
      title,
      description,
      urlType,
      url,
      externalUrl,
      day,
      age,
      ctaTitle,
      homepage,
      homePageSection,
      tag,
      quizId,
      quizEnabled,
      attachment,
    } = values;

    // Validations Start
    // if (!title) {
    //   Toast.error(ValidationMessage.EMPTY_TITLE);
    //   return;
    // }

    if (!title || title.length > 60) {
      Toast.error(ValidationMessage.TEXT_TITLE);
      return;
    }

    if (!description || description.length > 150) {
      Toast.error(ValidationMessage.TEXT_DESCRIPTION);
      return;
    }

    if (!attachment) {
      Toast.error(ValidationMessage.ATTACH);
      return;
    }

    if (homepage === true && !homePageSection) {
      Toast.error(
        'Please select the Home page section as you wanted to post the feed on Home screen.'
      );
      return;
    }

    // if (!(url || externalUrl) && ctaTitle) {
    //   Toast.error('Please Enter a CTA Title');
    //   return;
    // }
    console.log(values, 'valuesssss');
    if (
      values.urlType === 'EXTERNAL' &&
      (values?.ctaTitle?.length === 0 || !values.ctaTitle)
    ) {
      Toast.error('Please Enter a CTA Title');
      return;
    }

    if (
      values.urlType === 'EXTERNAL' &&
      (values?.externalUrl?.length === 0 || !values.externalUrl)
    ) {
      Toast.error('Please Enter a External URL');
      return;
    }

    /* Validation End */
    /* attachment: {}, */

    let defaultVal = '';
    // let defaultContent = '';
    // let defaultStartDate = '';
    // let defaultEndDate = '';
    // let defaultDay = '';
    // let defaultAge = '';
    // let defaultTag = '';

    let data = {
      title: title ? title : '',
      description: defaultVal,
      content: defaultVal,
      homepage: 0,
      article_id: feedId ? feedId : 0,
      update_article: feedId ? 1 : 0,
      tag: defaultVal,
      start_date: defaultVal,
      end_date: defaultVal,
      webinar_time: defaultVal,
      day: defaultVal,
      age: defaultVal,
      cta_title: defaultVal,
      attachment: attachment,
      // url_type: defaultVal,
      url: defaultVal,
    };

    if (quizEnabled && qid) {
      data.quiz_id = qid;
    } else if (quizEnabled && quizId) {
      data.quiz_id = quizId;
    } else if (!quizEnabled) {
      data.remove_quiz = 1;
    }
    // else {
    //   data.remove_quiz = 1;
    // }

    if (homepage === true && homePageSection) {
      data.homepage = 1;
      data.article_type = homePageSection;
    }

    if (description) {
      data.description = description;
    }

    let content;
    if (editorRef.current) {
      content = editorRef.current.value;
      if (content) {
        data.content = content;
      }
    }

    if ((url || externalUrl) && urlType && urlType !== URLType.NO && ctaTitle) {
      data.url_type = urlType;
      if (urlType === URLType.EXTERNAL) {
        data.url = externalUrl;
      }
      if (urlType === URLType.INTERNAL) {
        data.url = url;
      }
      data.cta_title = ctaTitle;
    }

    if (age) {
      data.age = age;
    }

    if (day) {
      data.day = day;
    }

    // if (selFile) {
    //   data.attachment = selFile;
    // } else if (selFile === null) {
    //   /*  || selFile === undefined */
    //   // data.attachment = {};
    //   data.remove_attachment = 1;
    // }

    if (videoThumbnail) {
      data.video_thumbnail = videoThumbnail;
    }

    // if ((startDate || !endDate) && (endDate || !startDate)) {
    //   alert('Both start date and End date needs to be selected');
    //   return;
    // }

    if (startDate) {
      data.start_date = startDate;
    }

    if (endDate) {
      data.end_date = endDate;
    }

    if (webinarStartDate) {
      data.webinar_time = webinarStartDate;
    }

    if (tag && tag.length > 0) {
      data.tag = JSON.stringify(tag);
    }

    return data;
  };

  const state = {
    options: [
      { name: 'Asthma', value: 'ASTHMA' },
      { name: 'Smoker', value: 'SMOKER' },
      { name: 'Amber', value: 'AMBER' },
      { name: 'Yellow', value: 'YELLOW' },
      { name: 'Green', value: 'GREEN' },
      { name: 'Webinar', value: 'WEBINAR' },
      { name: 'COPD', value: 'COPD' },
      { name: 'Diabetic', value: 'DIABETIC' },
      { name: 'Allergic rhinitis', value: 'ALLERGIC_RHINITIS' },
    ],
  };

  const populateQuizPayload = () => {
    /* Populate Data */
    let { title, quizId } = values;
    let { questions } = quizValues;

    // Validations Start
    if (!title) {
      Toast.error(ValidationMessage.EMPTY_TITLE);
      return;
    }
    /* Validation End */

    let data = {
      section_title: title ? title : '',
      section_1: [],
    };

    if (quizId) {
      data.quiz_id = quizId;
    }

    let consQuestions = [];
    questions.forEach((item, index) => {
      const {
        // questionId,
        questionType,
        question,
        isRequired,
        answer,
        dateTimeAnswer,
        options,
      } = item;
      let qdata = {
        question_id: index,
        question_type: questionType,
        question: question,
        is_required: isRequired,
        options: [],
      };
      if (questionType === URLType.FILL) {
        qdata.cms_answer = answer;
      } else if (questionType === URLType.DATE_TIME) {
        const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
        if (dateTimeAnswer && dateTimeAnswer.format) {
          qdata.cms_answer = dateTimeAnswer.format(dateTimeFormat);
        } else {
          qdata.cms_answer = dateTimeAnswer;
        }
      } else if (questionType === URLType.MCQ) {
        if (options && options.length > 0) {
          options.forEach((option) => {
            qdata.options.push({
              title: option.title,
              cms_answer: option.isSelected,
            });
          });
        }
      } else if (questionType === URLType.CHECKBOX) {
        qdata.question_type = URLType.QUIZ_CHECKBOX;
        if (options && options.length > 0) {
          options.forEach((option) => {
            qdata.options.push({
              title: option.title,
              cms_answer: option.isChecked,
            });
          });
        }
      }
      consQuestions.push(qdata);
    });

    data['section_1'] = consQuestions;

    return data;
  };

  const callAddOrUpdateQuizAPI = async () => {
    let data = populateQuizPayload();

    if (!data) return;

    setPageLoading(true);
    const responseObj = await addOrUpdateQuiz(data);
    setPageLoading(false);
    if (
      responseObj &&
      responseObj.response &&
      responseObj.response.code === 200
    ) {
      if (responseObj.data && responseObj.data.quiz_id) {
        updateValue({
          ...values,
          quizId: responseObj.data.quiz_id,
        });
        return {
          code: 200,
          quizId: responseObj.data.quiz_id,
        };
      } else {
        let { quizId } = values;
        return {
          code: 200,
          quizId: quizId,
        };
      }
    } else {
      Toast.error(Message.Feed.CREATE_FAILURE);
    }
  };

  const callAddOrUpdateAPI = async () => {
    const { quizEnabled } = values;

    let quizId = '';
    if (quizEnabled === true) {
      let quizResponse = await callAddOrUpdateQuizAPI();

      if (quizResponse && quizResponse.code === 200) {
        quizId = quizResponse.quizId;
      }
    }

    let data = populatePayload(quizId);
    if (!data) return;

    setPageLoading(true);
    const responseObj = await addOrUpdateFeed(data);
    setPageLoading(false);
    if (
      responseObj &&
      responseObj.response &&
      responseObj.response.code === 200
    ) {
      // LocalStorage.remove(LocalSessionKey.FEED_ID);
      if (feedId) {
        Toast.success(Message.Feed.UPDATED_SUCCESS);
      } else {
        Toast.success(Message.Feed.CREATED_SUCCESS);
      }
      history.goBack();
    } else {
      Toast.error(Message.Feed.CREATE_FAILURE);
    }
  };

  const handleCreateArticle = (e) => {
    callAddOrUpdateAPI();
  };

  // const onFileSelected = (file) => {
  //   setSelectedFile(file);
  //   if (file === null || file === undefined) {
  //     setVideoThumbnail(null);
  //   }
  // };

  const onFileSelected = (file, id) => {
    let data = { ...values };
    data['attachment'] = file;

    // if (file === null || file === undefined) {
    //   setVideoThumbnail(null);
    // }

    updateValue({
      ...data,
      webinars: data,
    });
  };

  const onVideoThumbnail = (thumbnail) => {
    setVideoThumbnail(thumbnail);
  };

  const onStartDateSelected = (date) => {
    setStartDate(date);
  };

  const onEndDateSelected = (date) => {
    setEndDate(date);
  };

  const onWebinarStartDateSelected = (date) => {
    setWebinarStartDate(date);
  };

  const onAddQuizChange = (e) => {
    setShowQuiz(e.target.checked);
    handleChange &&
      handleChange({
        target: {
          name: 'quizEnabled',
          value: e.target.checked,
        },
      });
  };

  return (
    <div className='blogsForm'>
      <div className='blogsForm_content'>
        <HeaderWithBackButton
          title='Add/edit a Feed'
          goTo={APP_CONSTANTS.NEWSFEED}
        />

        <NewInput
          label='Title'
          Tooltiptext=' title length is 60 characters. This includes spaces and special
          characters.'
          id='title'
          type='text'
          placeholder='Ex: Breathfree Educator'
          name='title'
          value={values.title}
          onChange={handleChange}
          errorText={error.title}
        />

        <TextArea
          id='description'
          Tooltiptext='description length is 150 characters. This includes spaces and special characters'
          label='Description'
          placeholder='Enter Description'
          rows='3'
          type='text'
          name='description'
          value={values.description}
          onChange={handleChange}
          errorText={error.description}
        />

        <QuillTextEditor
          label='RichText Editor'
          Tooltiptext='DO NOT COPY AND PASTE FROM WORD DOCUMENT OR OTHER EDITOR. Directly write here or copy from notepad only.'
          value={htmlContent}
          quillRef={editorRef}
          setHTMLContent={setHTMLContent}
        />

        <Attachment
          onFileSelected={onFileSelected}
          value={values.attachment}
          onVideoThumbnail={onVideoThumbnail}
          videoThumbnail={values.videoThumbnail}
          className='feed__attachment'
        />
        <CTAComponent
          externalUrl={values.externalUrl}
          ctaValue={values.ctaTitle}
          urlType={values.urlType}
          value={values.url}
          onChange={handleChange}
          errorText={error.url}
        />
        <MultiSelectDropdown
          label='Tag'
          name='tag'
          value={values.tag}
          onChange={handleChange}
          options={state.options}
        />

        <NumberInput
          label='Day'
          Tooltiptext='Enter day on which the content will be shown to user in feed post registration.'
          id='day'
          placeholder='Ex: 3'
          name='day'
          value={values.day}
          onChange={handleChange}
        />

        <Dropdown
          Tooltiptext='Select appropriate age group'
          label='Classify Age'
          placeholder='Select Age'
          name='age'
          value={values.age}
          options={DropdownOptions.Age}
          handleChange={handleChange}
        />

        <WebinarCalendar
          onStartDateSelected={onWebinarStartDateSelected}
          startDate={webinarStartDate}
          label='Webinar Event Time'
        />

        <FeedTypeComponent
          value={values.homepage}
          homePageValue={values.homePageSection}
          handleChange={handleChange}
        />

        <Checkbox
          id='quizcheckbox'
          label='Add Quiz'
          onChange={onAddQuizChange}
          checked={showQuiz}
        />

        {showQuiz && (
          <CreateQuiz
            quizData={quizData}
            values={quizValues}
            error={quizError}
            updateValue={updateQuizValue}
            onHandleChange={onHandleChange}
            addQuizSection={addQuizSection}
            removeQuizSection={removeQuizSection}
            onHandleOptionRadioClick={onHandleOptionRadioClick}
            onHandleOptionLabelChanged={onHandleOptionLabelChanged}
            onHandleAddOptionClick={onHandleAddOptionClick}
            onHandleRemoveOption={onHandleRemoveOption}
          />
        )}

        <Calender
          onStartDateSelected={onStartDateSelected}
          onEndDateSelected={onEndDateSelected}
          startDate={startDate}
          endDate={endDate}
          validated={validated}
          activate={activate}
        />
        <p
          className='Preview-text'
          onClick={() => {
            let data = populatePayload();
            if (
              data.attachment &&
              Object.keys(data.attachment).length === 0 &&
              Object.getPrototypeOf(data.attachment) === Object.prototype
            ) {
              if (values.attachment && typeof values.attachment === 'string') {
                data.attachment = `${process.env.REACT_APP_S3_BUCKET_URL}/${values.attachment}`;
              }
            }

            if (data && data.title) {
              onPreview && onPreview(data);
            }
          }}
        >
          Preview post
        </p>
        <Button
          onClick={handleCreateArticle}
          type='submit'
          name='Post a feed'
        />
      </div>
      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </div>
  );
};

export default CreateFeed;
