import React from 'react';
import BasePage from 'pages/BasePage';
import AbTesting from 'app/container/AbTesting';

import { AbTestingTableHeader } from 'constants/table-header-constants';
const AbTestingPage = () => {
  return (
    <>
      <BasePage>
        <AbTesting tableHeader={AbTestingTableHeader} />
      </BasePage>
    </>
  );
};

export default AbTestingPage;
