import {
  createContentAPI,
  getContentFromId,
  getTagsApi,
  updateContentAPI,
} from 'app/api/createContentAPI';
import CTAType from 'app/components/CTAType';
import Calender from 'app/components/Calender';
import MultiSelectDropdown from 'app/components/MultiSelectDropdown';
import NewInput from 'app/components/NewInput';
import PageLoader from 'app/components/PageLoader';
import TextArea from 'app/components/Textarea';
import Button from 'app/components/button';
import DropdownOptions from 'app/utils/DropdownOptions';
import Message from 'app/utils/Message';
import {
  checkIfUrl,
  imageSizeValidation,
  removeNullValues,
  urlParser,
} from 'app/utils/Parsers';
import Toast from 'app/utils/Toast';
import { calendarValidation } from 'app/utils/dateChecker';
import { CloseBlackIcon } from 'constants/image-constants';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import ValidationMessage from '../../../utils/ValidationMessage';
import '../index.scss';

import moment from 'moment';
import QuillTextEditor from 'app/shared/components/QuillTextEditor';
const SelfHosted = ({ data, activeTab }) => {
  const activate = true;
  const validated = true;
  const refContent = '';
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [htmlContent,setHTMLContent]=useState('');
  const [referenceContent,setReferenseContent]=useState('');
  const [options, setOptions] = useState(null);
  const [internalUrl, setInternalURL] = useState([]);
  const [endlessScrollImage, setEndlessScrollImage] = useState(null);
  const [landingPageImage, setLandingPageImage] = useState(null);
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [disabledKeys, setDisabled] = useState({
    internalApp: true,
    externalRedirect: true,
    cta: true,
  });
  // const radioMenu = [
  //   {
  //     id: 'internal_redirect',
  //     label: 'Internal Redirect',
  //     value: 'INTERNAL',
  //   },
  //   {
  //     id: 'external_redirect_pwa',
  //     label: 'External Redirect(PWA)',
  //     value: 'EXTERNAL_PWA',
  //   },
  //   {
  //     id: 'external_redirect_outside_app',
  //     label: 'External Redirect(Outside APP)',
  //     value: 'EXTERNAL',
  //   },
  // ];
  const editorRef = useRef(null);
  const referenceRef = useRef(null);

  const [state, setState] = useState({
    title: '',
    description: '',
    content: '',
    cta_type: 'INTERNAL',
    youtube_url: null,
    url: '',
    endless_scroll_media: '',
    landing_page_image: '',
    thumbnail_image: '',
    powered_by: '',
    detail_cta_text: '',
    endless_scroll_cta: '',
    start_date: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    end_date: '',
    tags: [],
    hex_code: '#323739',
    references: '',
    internal_for: '',
    internal_url: '',
    profile_data: false,
    custom_object: '',
    redirection_id: '',
  });

  const updateStates = async () => {
    if (data) {
      if (!data?.id || !activeTab) {
        return;
      }
      let response = await getContentFromId(data.id, activeTab);
      let requiredResponse = response.data[0];

      let stateValues = Object.keys(state);

      let thumbnail_image = requiredResponse.thumbnail_image
        ? `${process.env.REACT_APP_S3_BUCKET_URL}/${requiredResponse.thumbnail_image}`
        : null;

      let endless_scroll_media = requiredResponse?.endless_scroll_media
        ? `${process.env.REACT_APP_S3_BUCKET_URL}/${requiredResponse.endless_scroll_media}`
        : null;

      let landing_page_image = requiredResponse.landing_page_image
        ? `${process.env.REACT_APP_S3_BUCKET_URL}/${requiredResponse.landing_page_image}`
        : null;
      setEndlessScrollImage(endless_scroll_media);
      setThumbnailImage(thumbnail_image);
      setLandingPageImage(landing_page_image);
      setHTMLContent(requiredResponse.content);
      setReferenseContent(requiredResponse.references)
      let temporaryState = {};
      for (let index in stateValues) {
        temporaryState[stateValues[index]] =
          requiredResponse[stateValues[index]];
      }
      if (temporaryState.youtube_url === 'null') {
        temporaryState['youtube_url'] = null;
      }
      temporaryState['cta_type'] = requiredResponse['url_type'];

      setState(temporaryState);
    }
    let internalUrl = [];
    DropdownOptions.InternalUrl.forEach((item, i) => {
      if (
        item.value !== 'feed' &&
        item.value !== 'content_detail' &&
        item.value !== 'quiz' &&
        item.value !== 'vital_activity' &&
        item.value !== 'webinar_details' &&
        item.value !== 'individual_quiz'
      ) {
        internalUrl.push(item);
      }
    });
    setInternalURL(internalUrl);
  };
  useEffect(() => {
    updateStates();
  }, [activeTab]);
  const validateForm = () => {
    let flag = true;
    let validationArray = Object.keys(state);
    let optionalFields = [
      'youtube_url',
      'detail_cta_text',
      'content',
      'end_date',
      'description',
      'references',
      'endless_scroll_media',
      'powered_by',
      'redirection_id',
    ];

    if (state.cta_type !== 'INTERNAL_PWA') {
      optionalFields = [
        ...optionalFields,
        'internal_for',
        'internal_url',
        'profile_data',
        'custom_object',
      ];
    }
    if (state.cta_type !== 'INTERNAL') {
      optionalFields = [...optionalFields, 'url'];
    }

    for (let index in validationArray) {
      if (
        state[validationArray[index]] === '' &&
        (!optionalFields.includes(validationArray[index]) ||
          editorRef.current.value === '')
      ) {
        flag = false;
        Toast.error('Please complete all fields');
        break;
      } else if (
        validationArray[index] === 'tags' &&
        state.tags?.length === 0
      ) {
        flag = false;
        Toast.error('Please complete all fields');
        break;
      }
    }
    return flag;
  };

  const handleTextChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    if (activeTab === 'en') {
      let val = calendarValidation(state.start_date, state.end_date);

      if (!val) {
        Toast.error('Start date is much larger than end date');
        return;
      }
      let validated = validateForm();
      if (!validated) {
        return;
      }
    }

    let dataObj;
    let responseObj;

    if (data?.id) {
      dataObj = {
        ...state,
        content_type: 'SELF_HOSTED_ARTICLE',
        content: editorRef.current.value,
        references: referenceRef.current.value,
        tags: JSON.stringify(state.tags),
        url: checkIfUrl(state.url) ? urlParser(state.url) : state.url,
        content_id: data.id,
      };
      if (activeTab === 'en') {
        if (
          (dataObj?.endless_scroll_media === '' &&
            dataObj?.youtube_url === null) ||
          dataObj?.landing_page_image === '' ||
          dataObj?.thumbnail_image === ''
        ) {
          Toast.error('Please complete all fields');
          return;
        }

        dataObj.start_date = moment(dataObj?.start_date).format(
          'YYYY-MM-DD HH:mm:ss'
        );
        dataObj.end_date = moment(dataObj?.end_date).format(
          'YYYY-MM-DD HH:mm:ss'
        );
      }

      if (activeTab !== 'en') {
        delete dataObj?.start_date;
        delete dataObj?.end_date;
        delete dataObj?.tags;
      }
      setLoader(true);
      let temporaryStates = removeNullValues(dataObj);

      responseObj = await updateContentAPI(
        temporaryStates,
        activeTab,
        data?.id
      );
    } else {
      dataObj = {
        ...state,
        content_type: 'SELF_HOSTED_ARTICLE',
        content: editorRef.current.value,
        references: referenceRef.current.value,
        url: checkIfUrl(state.url) ? urlParser(state.url) : state.url,
        tags: JSON.stringify(state.tags),
      };
      setLoader(true);
      responseObj = await createContentAPI(dataObj);
      setLoader(false);
    }
    setLoader(false);
    if (
      responseObj &&
      responseObj.response &&
      responseObj.response.code === 200
    ) {
      // LocalStorage.remove(LocalSessionKey.FEED_ID);
      Toast.success(Message.External.EXTERNAL_SUCCESS);
      if (data) {
        history.push({
          pathname: '/cms/content',
          state: { goto: data.comingFrom },
        });
        return;
      } else {
        history.push('/cms/content');
      }
    } else {
      Toast.error(Message.External.EXTERNAL_FAIL);
    }
  };
  const handleMediaUpload = (e) => {
    let file = e.target.files[0];

    if (
      (file.type === 'image/jpeg' ||
        file.type === 'image/jpg' ||
        file.type === 'image/png') &&
      imageSizeValidation(e)
    ) {
      Toast.error(ValidationMessage.IMAGE_SIZE);
      return;
    } else {
      let reader = new FileReader();

      if (e.target.name === 'endless_scroll_media') {
        reader.onloadend = function () {
          setEndlessScrollImage(reader.result);
        };
        reader.readAsDataURL(file);
      } else if (e.target.name === 'landing_page_image') {
        reader.onloadend = function () {
          setLandingPageImage(reader.result);
        };
        reader.readAsDataURL(file);
      } else if (e.target.name === 'thumbnail_image') {
        reader.onloadend = function () {
          setThumbnailImage(reader.result);
        };
        reader.readAsDataURL(file);
      }
      setState({ ...state, [e.target.name]: file });
      Toast.success(file?.name + ' uploaded');
    }
  };

  const loadTagsfromApi = async () => {
    const tagValues = await getTagsApi();
    setOptions(tagValues);
  };
  useEffect(() => {
    if (activeTab === 'en') {
      loadTagsfromApi();
    }
  }, []);

  useEffect(() => {
    if (state.cta_type === 'INTERNAL') {
      setDisabled({ externalRedirect: true, internalApp: false, cta: false });
    } else if (state.cta_type === 'EXTERNAL') {
      setDisabled({ cta: false, internalApp: true, externalRedirect: false });
    } else if (state.cta_type === 'EXTERNAL_PWA') {
      setDisabled({ internalApp: true, externalRedirect: false, cta: false });
    }
  }, [state.cta_type]);

  const cancelMedia = async (e, file) => {
    setState({ ...state, [e.target.name]: '' });
    // await deleteUploadedMedia(file)
  };

  return (
    <>
      <>
        <div className='separator'>
          <p>Self Hosted - Add Details</p>
        </div>
        <div className='userInput'>
          <NewInput
            label='Title'
            name={'title'}
            onChange={handleTextChange}
            value={state.title ? state.title : ''}
          />
          <TextArea
            label={'Short Description'}
            name={'description'}
            onChange={handleTextChange}
            value={state?.description ? state?.description : ''}
          />
          <div className='userFormArea'>
            <div>
              {(state.endless_scroll_media || state.youtube_url) && (
                <p
                  className='cancel'
                  onClick={(e) => {
                    if (data) {
                      cancelMedia(e, state.endless_scroll_media);
                    }
                    setState({
                      ...state,
                      endless_scroll_media: '',
                      youtube_url: '',
                    });
                    setEndlessScrollImage(null);
                  }}
                >
                  Cancel Upload
                </p>
              )}
            </div>
            <div className='upload-buttons'>
              <input
                type='file'
                id='imageFile'
                style={{ display: 'none' }}
                name='endless_scroll_media'
                onChange={(e) => {
                  handleMediaUpload(e);
                }}
              />

              <div className='upload-label'>
                <label>Endless scroll image/video</label>
                <Button
                  onClick={() => {
                    const image = document.getElementById('imageFile');
                    image.click();
                  }}
                  isDisabled={
                    state.youtube_url
                      ? true
                      : state.endless_scroll_media
                        ? true
                        : false
                  }
                  type='button'
                  name={
                    state.endless_scroll_media !== ''
                      ? 'Uploaded'
                      : 'Upload Image/video'
                  }
                />
              </div>
              {endlessScrollImage && (
                <div className='attachment_img_wrapper'>
                  <img
                    src={endlessScrollImage}
                    alt='sample'
                    className='feed__attachment'
                  />
                  <div className='attachment_img_wrapper_close'>
                    <img
                      src={CloseBlackIcon}
                      alt='Remove'
                      onClick={(e) => {
                        setState({ ...state, endless_scroll_media: '' });
                        setEndlessScrollImage(null);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className='mt-3'>
              {state.landing_page_image && (
                <p
                  className='cancel'
                  onClick={(e) => {
                    if (data) {
                      cancelMedia(e, state.landing_page_image);
                    }
                    setState({ ...state, landing_page_image: '' });
                  }}
                >
                  Cancel Landing Page
                </p>
              )}
            </div>
            <div className='upload-buttons'>
              {/* -------Landing Page image-------- */}

              <input
                type='file'
                id='videoFile'
                style={{ display: 'none' }}
                name='landing_page_image'
                onChange={(e) => {
                  handleMediaUpload(e);
                }}
              />
              <div className='upload-label'>
                <label>Landing Page</label>
                <Button
                  onClick={() => {
                    const image = document.getElementById('videoFile');
                    image.click();
                  }}
                  isDisabled={state.landing_page_image}
                  type='button'
                  name={
                    state.landing_page_image !== ''
                      ? 'Uploaded'
                      : 'Upload Landing Page'
                  }
                />
              </div>
              {landingPageImage && (
                <div className='attachment_img_wrapper'>
                  <img
                    src={landingPageImage}
                    alt='sample'
                    className='feed__attachment'
                  />
                  <div className='attachment_img_wrapper_close'>
                    <img
                      src={CloseBlackIcon}
                      alt='Remove'
                      onClick={(e) => {
                        setState({ ...state, landing_page_image: '' });
                        setLandingPageImage(null);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className='mt-3'>
              {state.thumbnail_image && (
                <p
                  className='cancel'
                  onClick={(e) => {
                    if (data) {
                      cancelMedia(e, state.thumbnail_image);
                    }
                    setState({ ...state, thumbnail_image: '' });
                  }}
                >
                  Cancel Thumbnail
                </p>
              )}
            </div>
            <div className='upload-buttons '>
              {/* -------Upload Thumbnail image-------- */}
              <input
                type='file'
                id='thumbnailFile'
                style={{ display: 'none' }}
                name='thumbnail_image'
                onChange={(e) => {
                  handleMediaUpload(e);
                }}
              />
              <div className='upload-label'>
                <label>Upload Thumbnail Image</label>
                <Button
                  onClick={() => {
                    const image = document.getElementById('thumbnailFile');
                    image.click();
                  }}
                  isDisabled={state.thumbnail_image}
                  type='button'
                  name={
                    state.thumbnail_image !== ''
                      ? 'Uploaded'
                      : 'Upload Thumbnail Image'
                  }
                />
              </div>
              {thumbnailImage && (
                <div className='attachment_img_wrapper'>
                  <img
                    src={thumbnailImage}
                    alt='sample'
                    className='feed__attachment'
                  />
                  <div className='attachment_img_wrapper_close'>
                    <img
                      src={CloseBlackIcon}
                      alt='Remove'
                      onClick={(e) => {
                        setState({ ...state, thumbnail_image: '' });
                        setThumbnailImage(null);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='colorpicker'>
            <label>Background Fill</label>
            <input
              type='color'
              name='hex_code'
              value={state.hex_code}
              onChange={handleTextChange}
            />
            <p>{state.hex_code}</p>
          </div>
          <NewInput
            label='Youtube URL'
            name={'youtube_url'}
            value={state.youtube_url}
            disabled={state.endless_scroll_media ? true : false}
            onChange={handleTextChange}
          />
          {activeTab === 'en' && (
            <div className='userFormArea'>
              {options && (
                <MultiSelectDropdown
                  label='Add Tags'
                  name='tags'
                  value={state?.tags}
                  options={options ? options : null}
                  onChange={handleTextChange}
                  tag_type={true}
                />
              )}
            </div>
          )}
          <QuillTextEditor value={htmlContent} setHTMLContent={setHTMLContent} quillRef={editorRef} label='RichText Editor' Tooltiptext='DO NOT COPY AND PASTE FROM WORD DOCUMENT OR OTHER EDITOR. Directly write here or copy from notepad only.' />
          <div className='userFormArea'>
            <label>Detailed CTA Type</label>
            <CTAType
              cta_type={state.cta_type}
              handleChange={(key, data) => {
                if (
                  key === 'url' &&
                  (data !== 'zoom_live_session' ||
                    data !== 'infinite_scroll' ||
                    data !== 'group_of_collection' ||
                    data !== 'breathing_exercise'
                  )
                ) {
                  setState({ ...state, [key]: data, redirection_id: '' });
                } else {
                  setState({ ...state, [key]: data });
                }
              }}
              internalUrl={DropdownOptions.InternalUrl}
              url={state.url}
              handleTextChange={handleTextChange}
              internal_for={state.internal_for}
              internal_url={state.internal_url}
              profile_data={state.profile_data}
              custom_object={state.custom_object}
              redirection_id={state.redirection_id}
            />
          </div>

          {/* {radioMenu.map((item, index) => (
                        <RadioButton
                            id={item.id}
                            label={item.label}
                            isChecked={state.cta_type === item.value ? true : false}
                            value={item.value}
                            onSelect={() => { setState({ ...state, cta_type: item.value }) }}
                            name={item.label}
                            style={{ marginTop: '-15px'}}
                        />
                    ))} */}

          <div className='userFormArea horizontal-align'>
            {/* <div className="dropdown-area">
                        <label>Internal App</label>
                       
                        <select
                            name="url"
                            disabled={
                                disabledKeys.internalApp
                            }
                            className="custom-select" onChange={(e) => {
                                setState({ ...state, url: e.target.value })
                            }}>
                            <option value="">Select</option>
                            {
                                internalUrl?.map((item, indx) => (
                                    <option key={indx}
                                        value={item.value}
                                        selected={state.url === item.value ? true : false}
                                    >
                                        {item.label}</option>
                                ))
                            }

                        </select>
                    </div> */}
            <div className='input-container'>
              {/* <NewInput
                            label="External Redirect Link"
                            name="url"
                            value={disabledKeys.externalRedirect ? null : state.url}
                            onChange={handleTextChange}
                            disabled={
                                disabledKeys.externalRedirect
                            }

                        /> */}
              {(state?.cta_type === 'INTERNAL' || state?.cta_type === 'EXTERNAL_PWA' || state?.cta_type === 'EXTERNAL') && (
                <NewInput
                  name='detail_cta_text'
                  label={'Detailed CTA text'}
                  value={state?.detail_cta_text ? state?.detail_cta_text : ''}
                  onChange={handleTextChange}
                  disabled={disabledKeys.cta}
                />
              )}
            </div>
          </div>
          <NewInput
            label='Endless scroll CTA'
            name={'endless_scroll_cta'}
            value={state?.endless_scroll_cta ? state?.endless_scroll_cta : ''}
            onChange={handleTextChange}
          />
          <NewInput
            label='Powered By'
            name={'powered_by'}
            value={state.powered_by ? state.powered_by : ''}
            onChange={handleTextChange}
          />
          <QuillTextEditor value={referenceContent} setHTMLContent={setReferenseContent} quillRef={referenceRef} label='References' Tooltiptext='DO NOT COPY AND PASTE FROM WORD DOCUMENT OR OTHER EDITOR. Directly write here or copy from notepad only.' />

          {activeTab === 'en' && (
            <Calender
              onStartDateSelected={(e) => {
                setState({ ...state, start_date: e });
              }}
              onEndDateSelected={(e) => {
                setState({ ...state, end_date: e });
              }}
              startDate={state.start_date ? state.start_date : null}
              endDate={state.end_date ? state.end_date : null}
              validated={validated}
              activate={activate}
            />
          )}

          <div className='userFormArea'>
            <Button
              name={
                data ? 'Edit Self Hosted Article' : 'Create Self Hosted Article'
              }
              onClick={handleSubmit}
            />
            <label
              style={{ paddingLeft: '10px', cursor: 'pointer' }}
              onClick={() => {
                if (data) {
                  history.push({
                    pathname: '/cms/content',
                    state: { goto: data.comingFrom },
                  });
                } else {
                  history.goBack();
                }
              }}
            >
              Cancel
            </label>
          </div>
        </div>
        {loader && <PageLoader pageLoading={loader} />}
      </>
    </>
  );
};

export default SelfHosted;
