import React from 'react';
import BasePage from 'pages/BasePage';
import UploadAbTesting from 'app/container/CreateAbTesting/UploadAbTesting';

const AbTestingUploadPage = () => {
  return (
    <>
      <BasePage>
        <UploadAbTesting />
      </BasePage>
    </>
  );
};

export default AbTestingUploadPage;
