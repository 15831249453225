// Ajax
import * as dataAccess from 'utils/ajax';
import ApiParser from 'utils/ApiParser';

// Constants
import { API_CONSTANTS } from 'constants/api-contants';

export const getDiagnosticPageOrder = async (start, filterPayload) => {
  let url = `${API_CONSTANTS.COMMON_HOMEPAGE}?page=DIAGNOSTIC`;
  let responseObj = await dataAccess.get(url);

  return ApiParser.parseHomeListing(responseObj);
};
export const addOrUpdateDiagnosticPageOrder = async (incomingData) => {
  let url = `${API_CONSTANTS.COMMON_HOMEPAGE}?page=DIAGNOSTIC`;
  let payload = {
    data: {
      home_page_update: incomingData,
    },
  };
  return await dataAccess.post(url, payload);
};
