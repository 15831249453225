import React, { useState } from 'react'; 
import Button from 'app/components/button'; 
import './index.scss';
import UploadContainer from './UploadField';
import HistoryContainer from './HistoryContainer';

const UpdateUserRefill = () => {  
  const [selectedItem, setSelectedItem] = useState('activate');

  return (
    <>
      <div className='cms-content__container'>
      <h4>Update User Refill</h4> 
        <div className='mb-5'>         
          <div className='row'>
            <div className='col-sm-12 activate-tab'>
              <Button
                className={selectedItem === 'activate' ? 'active-btn' : ''}
                onClick={() => {
                  setSelectedItem('activate');
                }}
              >
                Upload
              </Button>
              <Button
                className={selectedItem === 'history' ? 'active-btn' : ''}
                onClick={() => {
                  setSelectedItem('history');
                }}
              >
                History
              </Button>
            </div>
          </div>

          <div className='row'>
            <div className='col-sm-12 my-5'>
              {selectedItem === 'activate' && <UploadContainer />}
              {selectedItem === 'history' && <HistoryContainer />}
            </div>
          </div>
        </div> 
      </div>
    </>
  );
};

export default UpdateUserRefill;
