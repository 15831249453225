export const scansPerDayOptions = [
  {
    id: 1,
    value: '1',
  },
  {
    id: 2,
    value: '2',
  },
  {
    id: 3,
    value: '3',
  },
  {
    id: 4,
    value: '4',
  },
  {
    id: 5,
    value: '5',
  },
  {
    id: 6,
    value: '6',
  },
  {
    id: 7,
    value: '7',
  },
  {
    id: 8,
    value: '8',
  },
  {
    id: 9,
    value: '9',
  },
  {
    id: 10,
    value: '10',
  },
  {
    id: 100,
    value: '100',
  },
];

export const emergencyScansPerDayOptions = [
  {
    id: 1,
    value: '1',
  },
  {
    id: 2,
    value: '2',
  },
  {
    id: 3,
    value: '3',
  },
  {
    id: 4,
    value: '4',
  },
  {
    id: 5,
    value: '5',
  },
  {
    id: 6,
    value: '6',
  },
  {
    id: 7,
    value: '7',
  },
  {
    id: 8,
    value: '8',
  },
  {
    id: 9,
    value: '9',
  },
  {
    id: 10,
    value: '10',
  },
];

export const billingFrequencyOptions = [
  {
    id: 1,
    value: 'MONTHLY',
  },
  {
    id: 2,
    value: 'QUARTERLY',
  },
  {
    id: 3,
    value: 'ANNUALLY',
  },
  {
    id: 4,
    value: 'DAYS',
  },
];

export const planDurationOptions = [
  {
    id: 1,
    value: 'DAY',
    label: 'DAY',
  },
  {
    id: 2,
    value: 'CUSTOM_DATE_RANGE',
    label: 'CUSTOM DATE RANGE',
  },
];
