import Button from "app/components/button";
import "../CreateTaskContainer/index"
import { useState, useEffect } from "react"; 
import { useHistory, useLocation } from "react-router"; 
import * as QueryString from 'query-string'; 
import { useParams } from "react-router-dom"; 
import NewInput from "app/components/NewInput"; 
import Toast from "app/utils/Toast";
import PageLoader from "app/components/PageLoader"; 
import { createCoupon, getCouponDetailsByID, updateCouponDetail, getUserJourneyRewardList} from "app/api/user-journey";

const CreateCouponContainer = () => {
    const location = useLocation()
    let history = useHistory() 
    const params = useParams();
    const [loader, setLoader] = useState(false)  
    const [streakRewardList, setStreakRewardList] = useState([]);
    const [state, setState] = useState({
        coupon_code: "",  
        reward_id: "", 

    })
    
    useEffect(() => { 
        getDropdown()
        if(params.id){
            getDetails(params.id)
       }
    }, [])

    const getDropdown = async() => {

        const dataArr = await getUserJourneyRewardList()
        let temp= []
        dataArr.map((item,i) => {
            if(item.reward_type === "COUPON"){
                temp.push({
                    label:item.id + '-' +item.coupon_title,
                    value:item.id,
                    ...item
                })
                setStreakRewardList(temp)
            }
           
        })
    }
 
    const handleTextChange = (e, index) => {
        
            setState({ ...state, [e.target.name]: e.target.value })
      
    }
    
    const getDetails = async(id) => {
        const taskDetail = await getCouponDetailsByID(id) 
        setState({
            coupon_code: taskDetail.coupon_code,  
            reward_id: taskDetail.fk_reward_id,
            coupon_id: taskDetail.id
        })

    }

    const handleSubmit = async() => {
        let payload = {
            ...state, 
           
        }  
       
        if(!state.coupon_code || !state.reward_id){
            Toast.error("Please complete all fields");
            return
        }
 
        setLoader(true)
        let responseObj
        if(params.id){   
            responseObj = await updateCouponDetail(payload)
        }else{
            responseObj = await createCoupon(payload)
        }
         
        if (
            responseObj &&
            responseObj.response &&
            responseObj.response.code === 200
        ) { 
            Toast.success(responseObj.response.alert[0].message);
            history.push("/userjourney/coupons") 
    
        } else {
            setLoader(false)
            Toast.error(responseObj.data && responseObj.data.msg?responseObj.data.msg:responseObj.response.alert[0].message);
        } 
    }
    
    return (
        <div className="container">
            <h1 className="section-header__title">User Journey</h1>
             
            <div className="separator">
                <p>Create Coupon</p>
            </div>
            <div className="userInput">
                
                <NewInput
                    label="Coupon Code"
                    name={"coupon_code"}
                    value={state.coupon_code}
                    onChange={handleTextChange}
                />
                <div className="userInput">
                    <div className="userFormArea horizontal-align">
                        <div className="dropdown-area">
                            <div className="row">
                                <div className="col-md-4">
                                    <label >Select Reward</label> 
                                </div>
                                <div className="col-md-8">
                                    <select
                                    name="reward_id" 
                                    className="custom-select w-100" onChange={(e) => {
                                        setState({ ...state, reward_id: Number(e.target.value) })
                                    }}>
                                        <option value="">Select</option>
                                        {
                                            streakRewardList?.map((item, indx) => (
                                                <option key={indx}
                                                    value={item.value}
                                                    selected={state.reward_id == item.value ? true : false}
                                                >
                                                    {item.label}</option>
                                            ))
                                        }

                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                    
                
                 <div className="userFormArea">
                    <Button
                        name={"Save"}
                        onClick={handleSubmit}
                        // isDisabled={!state.video_thumbnail || !state.endless_scroll_media || !state.title}
                    />
                    <label style={{ paddingLeft: "10px", cursor: "pointer" }}

                        onClick={  () => { history.goBack() }  }
                    >Cancel</label>
                </div>
                 
            </div>
            {loader && <PageLoader pageLoading={loader} />}
        </div>


    );
}

export default CreateCouponContainer