import { uplaodMedia } from 'app/api/cms-content';
import Button from 'app/components/button';
import NewInput from 'app/components/NewInput';
import TextArea from 'app/components/Textarea';
import Toast from 'app/utils/Toast';
import { useEffect, useState } from 'react';
import FileUploadBtn from './file-upload-btn';
import './index.scss';
import Loader from 'app/components/Loader';

function SlideDetails({ slideNo, onPassFiles }) {
  const slides = [];
  for (let i = 1; i <= +slideNo; i++) {
    slides.push(i);
  }

  const [allFiles, setAllFiles] = useState([]);

  useEffect(() => {
    onPassFiles(allFiles);
  }, [allFiles]);

  function addAllFilesHandler(file) {
    setAllFiles([...allFiles, file]);
  }
  return (
    <div className='slide-details-container'>
      <table>
        <tr>
          <th>Slide number</th>
          <th>Media Details</th>
        </tr>
        {slides.map((item, key) => (
          <tr key={key}>
            <td>{item}</td>
            <td>
              <UploadBox slideNo={item} onAddDetails={addAllFilesHandler} />
            </td>
          </tr>
        ))}
      </table>
    </div>
  );
}

const UploadBox = ({ onAddDetails, slideNo }) => {
  const [videoDur, setVideoDur] = useState(0);
  const [isMediaUploaded, setIsMediaUploaded] = useState(false);
  const [disableInput, setdisableInput] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [videoDetails, setVideoDetails] = useState({
    slide: slideNo,
    media: '',
    duration: '',
    description: '',
    is_video: false,
  });

  async function uploadMediaHandler(file) {
    setIsLoading(true);
    const { filename } = await uplaodMedia('media', file, 'SHORTS');
    Toast.success('Added file.');
    setIsMediaUploaded(true);
    setIsLoading(false);
    return filename;
  }

  function getSecondsOfVideo(videoFile) {
    let file = videoFile;
    if (file.type?.split('/')?.[0] === 'video') {
      const src = URL.createObjectURL(file);
      var video = document.createElement('video');
      video.src = src;
      video.addEventListener('loadedmetadata', function () {
        setVideoDur(parseInt(parseFloat(video.duration)));
      });
    }
  }

  async function inputChangeHandler(event) {
    const { name, value } = event.target;
    if (name === 'media') {
      if (event.target.files[0].type.includes('video')) {
        getSecondsOfVideo(event.target.files[0]);
        setdisableInput(true);
        const fileName = await uploadMediaHandler(event.target.files[0]);
        setVideoDetails({ ...videoDetails, [name]: fileName });
      } else if (event.target.files[0].type.includes('image')) {
        const fileName = await uploadMediaHandler(event.target.files[0]);
        setVideoDetails({ ...videoDetails, [name]: fileName });
      } else {
        Toast.error('Wrong file');
      }
      return;
    }
    setVideoDetails({ ...videoDetails, [name]: value });
  }

  function addDetails() {
    if (disableInput) {
      videoDetails.duration = videoDur;
      videoDetails.is_video = disableInput;
    }
    if (videoDetails.media === '' || videoDetails.duration === '') {
      Toast.error('File and duration is required!!');
      return;
    }
    onAddDetails(videoDetails);
    setIsDisabled(true);
    Toast.success('Added media.');
  }

  return (
    <div className='form'>
      {isLoading && <Loader />}
      <div className='form-controls media-details-box'>
        <FileUploadBtn
          name={'media'}
          onFileHandler={inputChangeHandler}
          label={isMediaUploaded ? 'Uploaded' : 'Upload'}
        />
        <div className='d-flex'>
          {videoDetails.media?.split('.')?.[1] === 'mp4' ? (
            <div className='attachment_img_wrapper'>
              <video className='attachment__selimage' controls>
                <source
                  src={`${process.env.REACT_APP_S3_BUCKET_URL}/${videoDetails.media}`}
                  type='video/mp4'
                />
              </video>
            </div>
          ) : (
            <div className='attachment_img_wrapper'>
              <img
                src={`${process.env.REACT_APP_S3_BUCKET_URL}/${videoDetails.media}`}
                alt='sample'
                className='feed__attachment'
              />
            </div>
          )}
        </div>

        {disableInput ? (
          <NewInput
            placeholder='Duration in sec'
            name={'duration'}
            type={'number'}
            value={videoDur}
          />
        ) : (
          <NewInput
            placeholder='Duration in sec'
            name={'duration'}
            type={'number'}
            onChange={inputChangeHandler}
          />
        )}
        <TextArea
          placeholder={'Description'}
          onChange={inputChangeHandler}
          name={'description'}
        />
        <Button className='btn' isDisabled={isDisabled} onClick={addDetails}>
          {isDisabled ? 'Added' : 'Add'}
        </Button>
      </div>
    </div>
  );
};

export default SlideDetails;
