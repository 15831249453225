import BasePage from "../BasePage";
import UpdateUserRefill from "app/container/UpdateUserRefill";

const UpdateUserRefillPage = () => {
  return (
    <BasePage>
      <UpdateUserRefill />
    </BasePage>
  );
}

export default UpdateUserRefillPage;