
import moment from 'moment';

export const calendarValidation = (start_date, end_date) => {
    if (end_date && (start_date > end_date)) {
        return false
    }
    return true

}

export const dataFormats = (date, format='MM-DD-YYYY') => {

    return moment(new Date(date)).format(format)

}