/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

// Components
import Loader from 'components/Loader';

import { getTC, getPrivacyPolicy } from 'api/static-content';

import './index.scss';
import { APP_CONSTANTS } from 'app/constants/app-constants';

const TermsOfUse = (props) => {
  const location = useLocation();

  const [htmlContent, setHtmlContent] = useState(null);
  const [title, setTitle] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    console.log('Props: ', props);
    let apiMethod;
    if (props && props.location && props.location.pathname) {
      if (props.location.pathname === APP_CONSTANTS.PRIVACY_POLICY) {
        apiMethod = getPrivacyPolicy;
      } else if (props.location.pathname === APP_CONSTANTS.TERMS_OF_USE) {
        apiMethod = getTC;
      }
    }
    if (apiMethod) {
      setLoading(true);
      const response = await apiMethod();
      setLoading(false);
      if (response && response.data && response.error === false) {
        if (response.data.title) {
          setTitle(response.data.title);
        }
        if (response.data.content) {
          setHtmlContent(response.data.content);
        }
      }
    }
  }, [location]);

  if (loading) {
    return (
      <div className='tc'>
        <Loader />
      </div>
    );
  }

  const renderContent = () => {
    if (htmlContent) {
      return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    }

    return <div className='nocontent'>No Content</div>;
  };

  return (
    <div className='tc'>
      {title && <div className='tc__header'>{title}</div>}
      <div className='tc__content'>{renderContent()}</div>
    </div>
  );
};

export default TermsOfUse;
