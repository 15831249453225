/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
// Components
import Attachment from 'components/Attachment';
import HeaderWithBackButton from 'components/HeaderWithBackButton';
import NewInput from 'components/NewInput';
import PageLoader from 'components/PageLoader';
import TextArea from 'components/Textarea';
import NumberInput from 'components/UI/NumberInput';
import validate from 'components/ValidateInfo';
import Button from 'components/button';
import useContentForm from 'components/useContentForm';

// API
// import { addOrUpdateFeed, getArticleId } from 'api/feed';
import { addOrUpdateSplashScreen } from 'api/splashscreen';

// Constants
import { APP_CONSTANTS } from 'constants/app-constants';

// Utils
import Message from 'utils/Message';
import Toast from 'utils/Toast';
import ValidationMessage from 'utils/ValidationMessage';

const CreateSplashScreen = ({ onPreview, ...props }) => {
  const history = useHistory();
  const location = useLocation();
  const [id, setId] = useState(null);
  const [selFile, setSelectedFile] = useState({});
  const [videoThumbnail, setVideoThumbnail] = useState({});
  const [splashId, setSplashId] = useState();

  const [pageLoading, setPageLoading] = useState(false);
  const [htmlContent, setHTMLContent] = useState('');

  const { handleChange, values, error, updateValue } = useContentForm(validate);

  useEffect(() => {
    let SplashScreenData = location.state;
    console.log(SplashScreenData, 'SplashScreenData');
    if (SplashScreenData && SplashScreenData.id) {
      setSplashId(SplashScreenData.id);
      setHTMLContent(SplashScreenData.content);
      let newData = {
        SplashId: SplashScreenData.id,
        title: SplashScreenData.title,
        description: SplashScreenData.description,
        attachment: SplashScreenData.attachment,
        sequenceOrder: SplashScreenData.sorting_order,
        videoThumbnail: SplashScreenData.video_thumbnail,
      };
      setId(newData.SplashId);
      updateValue(newData);
    }
  }, []);

  const populatePayload = (qid) => {
    /* Populate Data */
    let {
      title,
      SplashId,
      description,
      sequenceOrder,
      homepage,
      homePageSection,
    } = values;

    if (!title || title.length > 60) {
      Toast.error(ValidationMessage.TEXT_TITLE);
      return;
    }

    if (!description || description.length > 150) {
      Toast.error(ValidationMessage.TEXT_DESCRIPTION);
      return;
    }

    if (homepage === true && !homePageSection) {
      Toast.error(
        'Please select the Home page section as you wanted to post the feed on Home screen.'
      );
      return;
    }

    let data = {
      update_splash_screen: id ? 1 : 0,
      splash_id: SplashId,
    };

    // debugger;

    if (title) {
      if (APP_CONSTANTS.SPLASH_SCREEN_EDIT) {
        if (title !== location?.state?.title) {
          data.title = title;
        }
      } else {
        if (title) {
          data.title = title;
        }
      }
    }

    if (description) {
      if (APP_CONSTANTS.SPLASH_SCREEN_EDIT) {
        if (description !== location?.state?.description) {
          data.description = description;
        }
      } else {
        if (description) {
          data.description = description;
        }
      }
    }

    if (sequenceOrder) {
      if (APP_CONSTANTS.SPLASH_SCREEN_EDIT) {
        if (sequenceOrder !== location?.state?.sorting_order) {
          data.sorting_order = sequenceOrder;
        }
      } else {
        if (sequenceOrder) {
          data.sorting_order = sequenceOrder;
        }
      }
    }

    if (selFile) {
      data.attachment = selFile;
    } else if (selFile === null) {
      /*  || selFile === undefined */
      // data.attachment = {};
      data.remove_attachment = 1;
    }

    return data;
  };

  const callAddOrUpdateAPI = async () => {
    let data = populatePayload();
    if (!data) return;

    setPageLoading(true);
    const responseObj = await addOrUpdateSplashScreen(data);
    setPageLoading(false);
    if (
      responseObj &&
      responseObj.response &&
      responseObj.response.code === 200
    ) {
      // LocalStorage.remove(LocalSessionKey.FEED_ID);
      if (splashId) {
        Toast.success(Message.SplashScreen.UPDATED_SUCCESS);
      } else {
        Toast.success(Message.SplashScreen.CREATED_SUCCESS);
      }
      history.goBack();
    } else {
      Toast.error(Message.SplashScreen.CREATE_FAILURE);
    }
  };

  const handleCreateSplashScreen = (e) => {
    callAddOrUpdateAPI();
  };

  const onFileSelected = (file) => {
    setSelectedFile(file);
    if (file === null || file === undefined) {
      setVideoThumbnail(null);
    }
  };

  const onVideoThumbnail = (thumbnail) => {
    setVideoThumbnail(thumbnail);
  };

  return (
    <div className='blogsForm'>
      <div className='blogsForm_content'>
        <HeaderWithBackButton
          title='Add/edit a SplashScreen'
          goTo={APP_CONSTANTS.ONBOARDING}
        />

        <NewInput
          label='Title'
          Tooltiptext=' title length is 60 characters. This includes spaces and special
          characters.'
          id='title'
          type='text'
          placeholder='Ex: Breathfree Educator'
          name='title'
          value={values.title}
          onChange={handleChange}
          errorText={error.title}
        />

        <TextArea
          id='description'
          Tooltiptext='description length is 150 characters. This includes spaces and special characters'
          label='Description'
          placeholder='Enter Description'
          rows='3'
          type='text'
          name='description'
          value={values.description}
          onChange={handleChange}
          errorText={error.description}
        />

        <NumberInput
          label='Sequence order'
          id='sequenceOrder'
          placeholder='Enter sequence order'
          name='sequenceOrder'
          value={values.sequenceOrder}
          onChange={handleChange}
        />

        <Attachment
          onFileSelected={onFileSelected}
          value={values.attachment}
          onVideoThumbnail={onVideoThumbnail}
          videoThumbnail={values.videoThumbnail}
          className='attachment__selimage'
        />

        <Button
          onClick={handleCreateSplashScreen}
          type='submit'
          name='Post a SplashScreen'
        />
      </div>
      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </div>
  );
};

export default CreateSplashScreen;
