import NewAttachment from 'app/components/NewDesign/NewAttachment';
import NewInput from 'app/components/NewInput';
import Button from 'app/components/button';
import Toggle from 'react-toggle';

import { CloseBlackIcon } from 'app/constants/image-constants';
import './index.scss';
import useSuccessStory from './useSuccessStory';
import FaqActionMenu from '../CreateFaq/FaqActionMenu';
import PageLoader from 'app/components/PageLoader';
const SuccessStoryPage = () => {
  const {
    pageLoading,
    OnhandleChange,
    addStorySection,
    storylistData,
    onFileSelected,
    validateRequestpreviewScreen,
    isAddButtonDisabled,
    isPublishButtonDisabled,
    storyToogleChangestatus,
    createToggler,
    activeId,
    menuActionObj,
    onClickOutside,
  } = useSuccessStory();
  return (
    <>
      <div className='blogsForm'>
        <div className='blogsForm_content'>
          <h1>Referral Home</h1>
          <h6>Success Stories</h6>

          {storylistData?.map((data, index) => {
            return (
              <>
                <div key={index} className='Widget-container'>
                  <div className='Widget-container__section-one'>
                    <div className='card__header'>
                      <p className='Widget-container__section'>
                        {' '}
                        Success Story image {index + 1}{' '}
                      </p>
                      <div className='d-flex justify-content-ceter'>
                        <div
                          className='pointer position-relative'
                          onClick={() => createToggler(data)}
                        >
                          {!data.isNew && (
                            <>
                              <img
                                className='delete_icon'
                                src={CloseBlackIcon}
                                alt='Close'
                              />
                              {activeId === data?.id ? (
                                <FaqActionMenu
                                  menuAction={menuActionObj}
                                  onClickOutside={onClickOutside}
                                  row={data}
                                  index={index}
                                />
                              ) : null}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='Widget-container__Widget'>
                      <div className='Widget-container__Image-Container'>
                        <div className='widget_box'>
                          <div>
                            <NewAttachment
                              value={data?.attachment && data?.attachment}
                              id={data?.id}
                              onFileSelected={onFileSelected}
                              fileType='image'
                              label={`Add story ${index + 1} `}
                              className='widget_box_feed__attachment'
                              sizevalidationUpto={100}
                            />
                            <NewInput
                              label='Display order'
                              id={data?.id}
                              placeholder='Enter display Order 1/2/3'
                              name='displayOrder'
                              value={data?.displayOrder}
                              onChange={OnhandleChange}
                              type='number'
                            />
                            <div className='mt1 text-toggle-wrap'>
                              <label>Status</label>
                              <div className='text-toggle'>
                                <span className='text-danger'>Off</span>
                                <Toggle
                                  checked={data?.status}
                                  icons={false}
                                  onChange={() =>
                                    storyToogleChangestatus(index)
                                  }
                                />
                                <span className='text-success'>ON</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* </WidgetCard> */}
              </>
            );
          })}
          <div className='text-right mt2'>
            <>
              {' '}
              <span> {isAddButtonDisabled && ' Can add upto 10 Banners'}</span>
              <Button
                //className='addbutton mr2'
                className='button  primary-btn'
                onClick={addStorySection}
                type='submit'
                name='Add Banner'
                isDisabled={isAddButtonDisabled}
              />
            </>

            <Button
              onClick={validateRequestpreviewScreen}
              type='submit'
              name='Submit'
              isDisabled={isPublishButtonDisabled}
              // cl
            />
          </div>
        </div>
      </div>
      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </>
  );
};

export default SuccessStoryPage;
