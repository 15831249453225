import { API_CONSTANTS } from 'app/constants/api-contants';
import * as dataAccess from 'utils/ajax';
import Parsers from 'app/utils/Parsers';
import Toast from 'app/utils/Toast';

export const getCmsContent = async () => {
  let responseObj = await dataAccess.get(API_CONSTANTS.POST_CONTENT_CMS);
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data;
  } else {
    return [];
  }
};

export const getCmsSelectContent = async () => {
  let responseObj = await dataAccess.get(API_CONSTANTS.SELECT_CONTENT_CMS);
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data;
  } else {
    return [];
  }
};

export async function postShorts(submittedData) {
  let payload = {
    data: submittedData,
  };
  let responseObj = await dataAccess.post(
    `${API_CONSTANTS.POST_SHORTS}`,
    payload
  );

  return responseObj;
}
export async function updateShorts(submittedData, lang) {
  let payload = {
    data: submittedData,
  };
  let url = `${API_CONSTANTS.POST_SHORTS_VERSION2}?lang=${lang}`;
  console.log('url', url);
  let responseObj = await dataAccess.put(url, payload);

  return responseObj;
}

export async function postQuiz(submittedData) {
  let payload = {
    data: submittedData,
  };
  let responseObj = await dataAccess.post(
    `${API_CONSTANTS.POST_QUIZ}`,
    payload
  );

  return responseObj;
}

export async function getQuizFkkey(id) {
  let responseObj = await dataAccess.get(`${API_CONSTANTS.GET_QUIZ_FK}/${id}`);
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data[0].fk_quiz_id;
  } else {
    return '';
  }
}

export async function updateQuiz(submittedData, id, lang) {
  console.log(submittedData);
  let payload = {
    data: submittedData,
  };

  let responseObj = await dataAccess.put(
    `${API_CONSTANTS.POST_QUIZ_VERSION2}?lang=${lang}`,
    payload
  );
  console.log(responseObj);
  return responseObj;
}

export async function uplaodMedia(name, data, path) {
  let formData = new FormData();
  formData.append(name, data);
  let payload = {
    data: formData,
  };

  let responseObj = await dataAccess.upload(
    `${API_CONSTANTS.UPLOAD_MEDIA}/${path}`,
    payload
  );

  return responseObj.data;
}

export async function deleteUploadedMedia(fileName) {
  let responseObj = await dataAccess.del(
    `${API_CONSTANTS.DELETE_MEDIA}${fileName}`
  );
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    Toast.success('Removed Media');
    return true;
  } else {
    Toast.error('Something went wrong!');
    return false;
  }
}

export const getCmsFilter = async (filter) => {
  let responseObj = await dataAccess.post(API_CONSTANTS.SEARCH_FILTER_CMS, {
    data: filter,
  });
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    if (responseObj?.data) {
      return responseObj.data;
    }
    return [];
  } else {
    return [];
  }
};

export const deleteContent = async (id) => {
  let responseObj = await dataAccess.del(
    `${API_CONSTANTS.DELETE_CMS_CONTENT}/${id}`
  );
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data;
  } else {
    return [];
  }
};

export const publishOrUnpublishAPI = async (id, type) => {
  let responseObj;
  if (type === 'unpublish') {
    responseObj = await dataAccess.get(
      `${API_CONSTANTS.UNPUBLISH_CMS_CONTENT}/${id}`
    );
  } else {
    responseObj = await dataAccess.get(
      `${API_CONSTANTS.PUBLISH_CMS_CONTENT}/${id}`
    );
  }
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data;
  } else {
    return [];
  }
};

export async function updateDiscoverGroups(submittedData) {
  console.log(submittedData);
  let payload = {
    data: submittedData,
  };
  let responseObj = await dataAccess.put(
    `${API_CONSTANTS.UPDATE_DISCOVER_GROUPS}`,
    payload
  );
  // console.log(responseObj);
  return responseObj;
}
export async function addDiscoverGroup(submittedData) {
  console.log(submittedData);
  let payload = {
    data: submittedData,
  };
  let responseObj = await dataAccess.post(
    `${API_CONSTANTS.UPDATE_DISCOVER_GROUPS}`,
    payload
  );
  // console.log(responseObj);
  return responseObj;
}

export const getCollectionDetailsByID = async (id) => {
  let responseObj = await dataAccess.get(
    `${API_CONSTANTS.GET_COLLECTIONS_DETAILS_BY_ID}/${id}`
  );
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data;
  } else {
    return [];
  }
};

export const getCollectionDetailsLocalisationByID = async (id, lang) => {
  let responseObj = await dataAccess.get(
    `${API_CONSTANTS.GET_COLLECTIONS_DETAILS_BY_ID_VERSION}/${id}?lang=${lang}`
  );
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data;
  } else {
    return [];
  }
};
