import { API_CONSTANTS } from 'app/constants/api-contants';
import * as dataAccess from 'utils/ajax';

export const getLanguages = async () => {
  let responseObj = await dataAccess.get(API_CONSTANTS.LOCALISATION_lANGUAGE);
  return responseObj;
};

export const addOrUpdateLanguageOrderAndStatus = async (incomingData) => {
  // let payload = {
  //   data: incomingData,
  // };

  let payload = {
    data: {
      data: incomingData,
    },
  };
  return await dataAccess.put(API_CONSTANTS.LOCALISATION_lANGUAGE, payload);
};
