import React from 'react';
import BasePage from 'pages/BasePage';
import CreateProductFamily from 'app/container/CreateProductFamily';

const ProductFamilyCreatePage = () => {
  return (
    <>
      <BasePage>
        <CreateProductFamily />
      </BasePage>
    </>
  );
};

export default ProductFamilyCreatePage;
