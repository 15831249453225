import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import moment from 'moment';

import './index.scss';

// Components
import Loader from 'components/Loader';
import HeaderWithBackButton from 'components/HeaderWithBackButton';
import NewInput from 'components/NewInput';
import Search from 'app/components/search';
import Button from 'components/button';
import PageLoader from 'components/PageLoader';
import CustomTable from './CustomTable';
import CustomDragabbleTable from './CustomDragabbleTable';

// API
import {
  addRecommendations,
  updateRecommendations,
  getRecommendationsDetails,
  getVitals,
  getAgeRange,
  getVitalRange,
  getCmsContent,
  getRiskLevel,
  getCohort,
} from 'api/latest/Recommendations/recommendations';

// Constants
import { APP_CONSTANTS } from 'constants/app-constants';
import Calender from 'app/components/Calender';

// Utils
import Toggle from 'react-toggle';
import Toast from 'utils/Toast';
import ValidationMessage from 'utils/ValidationMessage';
import Message from 'utils/Message';
import { Messages } from 'app/constants/messages';
import TabBar from 'app/components/NewDesign/TabBar';
import TabBarOptions from 'app/utils/TabBarOptions';

const CreateRecommendations = ({ tableHeader, draggableTableHeader }) => {
  const history = useHistory();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [toggleStatus, setToggleStatus] = useState(false);

  const [vitalsOptions, setVitalsOptions] = useState([]);
  const [vitalSelectedOption, setVitalSelectedOption] = useState('');
  const [ageRangeOptions, setAgeRangeOptions] = useState([]);
  const [ageRangeSelectedOption, setAgeRangeSelectedOption] = useState('');
  const [vitalRangeOptions, setVitalRangeOptions] = useState([]);
  const [vitalRangeSelectedOption, setVitalRangeSelectedOption] = useState('');
  const [riskLevel, setRiskLevel] = useState('');
  const [cohortOptions, setCohortOptions] = useState([]);
  const [cohortSelectedOptions, setCohortSelectedOptions] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  console.log('selectedItems', selectedItems);
  const [isSubmitClicked, setIsSubmitClicked] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [activeTab, setActiveTab] = useState({
    id: '1',
    name: 'English',
    short_name: 'en',
    display_order: 1,
    status: 'ACTIVE',
    label: '+ English',
    value: 'English',
  });

  const [createRecommendationsForm, setCreateRecommendationsForm] = useState({
    internal_name: '',
    vital_id: '',
    age_range: '',
    vital_range: '',
    risk_level: '',
    cohorts: '',
    recommendation_name: '',
    recommended_contents: [{ content_id: '', display_order: '' }],
    start_from: '',
    end_at: '',
    status: '',
  });

  // Table and Pagination
  const [values, setValues] = useState('');
  const [data, setData] = useState({
    tableData: [],
  });
  const [totalData, setTotalData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const dataArray = data.tableData;
  const totalPages = Math.ceil(dataArray.length / itemsPerPage);
  const visiblePageRange = 5; // Number of visible page numbers
  const halfRange = Math.floor(visiblePageRange / 2);
  const firstVisiblePage = Math.max(currentPage - halfRange, 1);
  const lastVisiblePage = Math.min(currentPage + halfRange, totalPages);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const [hideFields, setHideFields] = useState(false);
  const currentData = dataArray.slice(startIndex, endIndex);
  useEffect(() => {
    if (values) {
      setCurrentPage(1);
    }
  }, [values]);
  const cohortOptionsData = async () => {
    const response = await getCohort();
    setCohortOptions(response.data);
  };

  const loadCmsContent = async () => {
    setLoading(true);
    let response = await getCmsContent();
    setLoading(false);
    if (response) {
      setData({
        tableData: response?.tableData,
      });
      setTotalData({
        tableData: response?.tableData,
      });
      // Save the data to localStorage
      localStorage.setItem('tableData', JSON.stringify(response?.tableData));
    } else {
      if (response?.data?.error) {
        Toast.error(response?.data?.error);
      } else {
        Toast.error(Message?.Error?.COMMON_MESSAGE);
      }
    }
  };

  useEffect(() => {
    cohortOptionsData();
    loadCmsContent();
    // Retrieve the data from localStorage, if available
    const storedData = localStorage.getItem('tableData');
    if (storedData) {
      setData({ tableData: JSON.parse(storedData) });
    } else {
      // If data is not in localStorage, load it
      loadCmsContent();
    }
    setValues('');
  }, []);

  const handleTabChange = (val) => {
    setActiveTab(val);
    if (val.name === 'English') {
      setHideFields(false);
    } else {
      setHideFields(true);
    }
    // Perform any other actions on tab change
  };

  const vitalsOptionsData = async () => {
    const response = await getVitals();
    setVitalsOptions(response.data);
  };

  const ageRangeOptionsData = async () => {
    const response = await getAgeRange();
    setAgeRangeOptions(response.data);
  };

  const vitalRangeOptionsData = async () => {
    const response = await getVitalRange(
      vitalSelectedOption,
      ageRangeSelectedOption
    );
    setVitalRangeOptions(response.data);
  };

  const riskLevelData = async () => {
    const response = await getRiskLevel(
      vitalSelectedOption,
      ageRangeSelectedOption,
      vitalRangeSelectedOption
    );
    setRiskLevel(response.data.risk_level);
  };

  const handleGenerateRiskLevel = (e) => {
    e.preventDefault();
    if (
      vitalSelectedOption === 'Respiration Rate' &&
      ageRangeSelectedOption === ''
    ) {
      Toast.error('Please select age range too');
      return;
    }
    if (vitalSelectedOption === '') {
      Toast.error('Please select vital');

      return;
    }
    if (vitalRangeSelectedOption === '') {
      Toast.error('Please select vital range');

      return;
    }

    riskLevelData();
  };

  useEffect(() => {
    if (!hideFields) {
      if (
        vitalSelectedOption !== '' &&
        vitalSelectedOption !== 'Respiration Rate'
      ) {
        vitalRangeOptionsData();
      }

      if (
        vitalSelectedOption === 'Respiration Rate' &&
        ageRangeSelectedOption !== ''
      ) {
        vitalRangeOptionsData();
      }
      vitalsOptionsData();
      ageRangeOptionsData();
    }
  }, [vitalSelectedOption, ageRangeSelectedOption]);

  const [langContent, setLangContent] = useState([]);

  const getRecommendationsById = async (id) => {
    const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
    setPageLoading(true);
    let responseObj = await getRecommendationsDetails(id, activeTab.short_name);
    if (activeTab.short_name !== 'en') {
      setLangContent(responseObj?.recommended_contents);
    }
    let res = responseObj?.cohorts;
    const preSelectedOptions =
      cohortOptions &&
      cohortOptions
        .filter((item) => res && res.includes(item.id))
        .map((item) => ({
          label: item.value,
          value: item.id,
        }));
    setCohortSelectedOptions(preSelectedOptions ? preSelectedOptions : null);
    setToggleStatus(responseObj.status === 'ACTIVE' ? true : false);
    setVitalSelectedOption(responseObj.vital);
    setAgeRangeSelectedOption(responseObj.age_range);
    setVitalRangeSelectedOption(responseObj.vital_range);
    setRiskLevel(responseObj.risk_level);
    let contentOrderArray = responseObj.recommended_contents;
    // Retrieve data from localStorage
    const storedData = localStorage.getItem('tableData');
    const tableData = storedData ? JSON.parse(storedData) : [];
    if (tableData.length > 0) {
      const newContentOrderArray = [];
      for (const item of contentOrderArray) {
        const matchingItem = tableData.find(
          (contentItem) => Number(contentItem.id) === Number(item.content_id)
        );
        if (matchingItem) {
          newContentOrderArray.push(matchingItem);
        }
      }
      setSelectedItems(newContentOrderArray);
    }
    setCreateRecommendationsForm({
      internal_name: responseObj.internal_name,
      vital_id: responseObj.id,
      recommendation_name: responseObj.recommendation_name,
      start_from: moment(responseObj?.start_from).format(dateTimeFormat),
      end_at: moment(responseObj?.end_at).format(dateTimeFormat),
    });
    // updateValue(responsedata);
    if (responseObj?.start_from) {
      setStartDate(moment(responseObj?.start_from).format(dateTimeFormat));
    }
    if (responseObj?.end_at) {
      setEndDate(moment(responseObj?.end_at).format(dateTimeFormat));
    }
    setPageLoading(false);
  };

  useEffect(() => {
    if (params.id) {
      getRecommendationsById(params.id);
    }
  }, [params.id, cohortOptions, activeTab.short_name]);

  const addNewRecommendations = async () => {
    const selectedVital = vitalsOptions.find(
      (option) => option.value === vitalSelectedOption
    );
    const selectedVitalId = selectedVital.id;

    const selectedAgeRange =
      ageRangeOptions.find(
        (option) => option.value === ageRangeSelectedOption
      ) || '';
    const selectedAgeRangeValue = selectedAgeRange.value;

    const selectedVitalRange = vitalRangeOptions.find(
      (option) => option.value === vitalRangeSelectedOption
    );
    const selectedVitalRangeValue = selectedVitalRange.value;

    const selectedCohortsIds = cohortSelectedOptions?.map(
      (option) => option.value
    );

    let data = {
      internal_name: createRecommendationsForm.internal_name,
      vital_id: selectedVitalId,
      age_range: selectedAgeRangeValue ? selectedAgeRangeValue : '',
      vital_range: selectedVitalRangeValue ? selectedVitalRangeValue : '',
      risk_level: riskLevel,
      cohorts: selectedCohortsIds,
      recommendation_name: createRecommendationsForm.recommendation_name,
      recommended_contents: selectedItems,
      start_from: createRecommendationsForm.start_from,
      end_at: createRecommendationsForm.end_at,
    };
    setPageLoading(true);
    const responseObj = await addRecommendations(data);
    setPageLoading(false);
    if (responseObj.response.code === 201) {
      history.goBack();
      Toast.success('The recommendations has been created successfully');
      localStorage.removeItem('tableData');
    } else {
      Toast.error(
        responseObj?.data?.error
          ? responseObj?.data?.error
          : Messages?.Error?.COMMON_MESSAGE
      );
    }
  };

  const handleFormInputChange = (e) => {
    e.preventDefault();
    const { value, name } = e.target;
    setCreateRecommendationsForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const inputChangeHandler = (searchVal) => {
    if (searchVal.trim() === '') {
      setValues(searchVal.toLowerCase());
      setData(totalData);
      return;
    }
    const filteredData = [];
    totalData.tableData.forEach((item) => {
      if (
        (item?.title &&
          item?.title.toLowerCase().includes(searchVal.toLowerCase())) ||
        (item?.id && item?.id.toString().includes(searchVal)) ||
        (item?.combinedTags &&
          item?.combinedTags.toLowerCase().includes(searchVal.toLowerCase()))
      ) {
        filteredData.push(item);
      }
    });
    setData(filteredData);
    setData({
      tableData: filteredData,
    });
    setValues(searchVal);
  };

  const handleVitalSelectChange = (e) => {
    e.preventDefault();
    setRiskLevel('');
    setAgeRangeSelectedOption('');
    setVitalRangeSelectedOption('');
    setVitalSelectedOption(e.target.value);
  };

  const handleAgeRangeSelectChange = (e) => {
    e.preventDefault();
    setRiskLevel('');
    setVitalRangeSelectedOption('');
    setAgeRangeSelectedOption(e.target.value);
  };

  const handleVitalRangeSelectChange = (e) => {
    e.preventDefault();
    setRiskLevel('');
    setVitalRangeSelectedOption(e.target.value);
  };

  const handleCreateRecommendations = async (e) => {
    e.preventDefault();
    let { internal_name, recommendation_name, start_from, end_at } =
      createRecommendationsForm;

    if (internal_name === '') {
      Toast.error(ValidationMessage.RECOMMENDATION_NAME);
      setPageLoading(false);
      return;
    }
    if (activeTab.short_name === 'en') {
      if (
        vitalSelectedOption === 'Respiration Rate' &&
        ageRangeSelectedOption === ''
      ) {
        Toast.error('Please select age range too');
        return;
      }
      if (vitalSelectedOption === '') {
        Toast.error(ValidationMessage.VITAL);
        setPageLoading(false);
        return;
      }
      if (vitalRangeSelectedOption === '') {
        Toast.error(ValidationMessage.VITAL_RANGE);
        setPageLoading(false);
        return;
      }
      if (recommendation_name === '') {
        Toast.error(ValidationMessage.INTERNAL_NAME);
        setPageLoading(false);
        return;
      }
      if (cohortSelectedOptions.length === 0) {
        Toast.error(ValidationMessage.COHORT);
        setPageLoading(false);
        return;
      }
      if (selectedItems.length === 0) {
        Toast.error(ValidationMessage.RECOMMEND_CONTENT);
        setPageLoading(false);
        return;
      }
      if (start_from === '') {
        Toast.error(ValidationMessage.START_FROM);
        setPageLoading(false);
        return;
      }
      if (end_at === '') {
        Toast.error(ValidationMessage.END_AT);
        setPageLoading(false);
        return;
      }

      const selectedVital = vitalsOptions.find(
        (option) => option.value === vitalSelectedOption
      );
      const selectedVitalId = selectedVital.id;

      const selectedAgeRange =
        ageRangeOptions.find(
          (option) => option.value === ageRangeSelectedOption
        ) || '';

      const selectedAgeRangeId = selectedAgeRange.id;

      const selectedVitalRange = vitalRangeOptions.find(
        (option) => option.value === vitalRangeSelectedOption
      );
      const selectedVitalRangeId = selectedVitalRange?.id;

      const generatedRecommendedContents = selectedItems?.map(
        (selectedItem, index) => ({
          id: `${index + 1}`, // You can generate a unique ID here if needed
          content_id: selectedItem.id,
          recommendation_id: params.id ? params.id : '',
          display_order: index,
        })
      );

      let payload = {
        // id: params.id,
        internal_name: createRecommendationsForm.internal_name,
        vital_id: selectedVitalId,
        age_range: selectedAgeRangeId ? selectedAgeRangeId : '',
        vital_range: selectedVitalRangeId ? selectedVitalRangeId : '',
        risk_level: riskLevel ? riskLevel : '',
        cohorts: cohortSelectedOptions?.map((option) => option.value),
        start_from: createRecommendationsForm.start_from,
        end_at: createRecommendationsForm.end_at,
        status: toggleStatus ? 'ACTIVE' : 'INACTIVE',
        recommendation_name: createRecommendationsForm.recommendation_name,
        recommended_contents: generatedRecommendedContents,
      };

      setPageLoading(true);
      let responseObj;
      if (params.id) {
        payload.id = params.id;
        responseObj = await updateRecommendations(
          payload,
          payload.id,
          activeTab.short_name
        );

        if (responseObj.response.code === 200) {
          Toast.success('The recommendations has been updated successfully');
          history.goBack();
          localStorage.removeItem('tableData');
        } else {
          Toast.error(
            responseObj?.data?.error
              ? responseObj?.data?.error
              : Messages?.Error?.COMMON_MESSAGE
          );
        }
      } else {
        addNewRecommendations();
      }
    } else {
      if (params.id) {
        let payload = {
          id: params.id,
          internal_name,
        };
        let responseObj = await updateRecommendations(
          payload,
          payload.id,
          activeTab.short_name
        );
        if (responseObj.response.code === 200) {
          Toast.success('The recommendations has been updated successfully');
          history.goBack();
          localStorage.removeItem('tableData');
        } else {
          Toast.error(
            responseObj?.data?.error
              ? responseObj?.data?.error
              : Messages?.Error?.COMMON_MESSAGE
          );
        }
      }
    }
  };

  const handleToggleChange = () => {
    setToggleStatus(!toggleStatus);
  };

  const handleMultipleSelectChange = (cohortSelectedOptions) => {
    setCohortSelectedOptions(cohortSelectedOptions);
  };

  const options = cohortOptions?.map((item) => ({
    label: item.value,
    value: item.id,
  }));

  const handleSelectedItemsChange = (newSelectedItems) => {
    setSelectedItems(newSelectedItems);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(selectedItems);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSelectedItems(items);
  };

  const onStartDateSelected = (date) => {
    setStartDate(date);
    setCreateRecommendationsForm((prevFormData) => ({
      ...prevFormData,
      start_from: date,
    }));
  };

  const onEndDateSelected = (date) => {
    setEndDate(date);
    setCreateRecommendationsForm((prevFormData) => ({
      ...prevFormData,
      end_at: date,
    }));
  };

  if (loading === true) {
    return <Loader />;
  }

  return (
    <>
      <div className='py-3 px-5'>
        <HeaderWithBackButton
          title='Add/edit Recommendations'
          goTo={APP_CONSTANTS.CMS_VITALS_RECOMMENDATIONS}
        />
        {params?.id ? (
          <TabBar
            tabs={TabBarOptions.LanguageOptions}
            initialTab={activeTab}
            onTabChange={handleTabChange}
            parentStyle={'service-tab-style'}
          />
        ) : null}
        <NewInput
          id='internal_name'
          label='Recommendation Name*'
          type='text'
          placeholder='Recommendation Name'
          name='internal_name'
          value={
            createRecommendationsForm.internal_name
              ? createRecommendationsForm.internal_name
              : ''
          }
          onChange={handleFormInputChange}
        />

        {!hideFields ? (
          <>
            <label className='mt-4 d-block w-100'>
              Vital*
              <select
                className='mt-2 w-100 d-block c-select'
                name='vital'
                value={vitalSelectedOption}
                onChange={handleVitalSelectChange}
              >
                <option value=''>Please select an option</option>
                {vitalsOptions?.map((option) => (
                  <option
                    key={option.id}
                    value={option.value ? option.value : ''}
                  >
                    {option.value}
                  </option>
                ))}
              </select>
            </label>

            <label className='mt-4 d-block w-100'>
              Age Range
              <select
                className='mt-2 w-100 d-block c-select'
                name='age_range'
                value={ageRangeSelectedOption}
                onChange={handleAgeRangeSelectChange}
              >
                <option value=''>Please select an option</option>
                {ageRangeOptions?.map((option) => (
                  <option
                    key={option.id}
                    value={option.value ? option.value : ''}
                  >
                    {option.value}
                  </option>
                ))}
              </select>
            </label>

            <label className='mt-4 d-block w-100'>
              Vital Range*
              <select
                className='mt-2 w-100 d-block c-select'
                name='vital_range'
                value={vitalRangeSelectedOption}
                onChange={handleVitalRangeSelectChange}
              >
                <option value=''>Please select an option</option>
                {vitalRangeOptions?.map((option) => (
                  <option
                    key={option.id}
                    value={option.value ? option.value : ''}
                  >
                    {option.value}
                  </option>
                ))}
              </select>
            </label>

            <div className='d-flex align-items-center mt-4'>
              <label className='d-inline-block me-3'>Risk Level</label>
              <label
                className={`d-inline-flex align-items-center me-3 border py-1 px-3 ${
                  riskLevel === 'Out of Range' && 'text-danger'
                }`}
                style={{ minWidth: '120px', height: '40px' }}
              >
                {riskLevel}
              </label>
              <Button
                onClick={handleGenerateRiskLevel}
                type='submit'
                name='Generate'
              />
            </div>

            <NewInput
              id='recommendation_name'
              label='Internal Recommendation Name*'
              type='text'
              placeholder='Internal Recommendation Name'
              name='recommendation_name'
              value={createRecommendationsForm.recommendation_name}
              onChange={handleFormInputChange}
            />

            <label className='mt-4 d-block w-100'>
              Cohort*
              <Select
                isMulti
                options={options}
                value={cohortSelectedOptions}
                onChange={handleMultipleSelectChange}
                className='mt-2'
              />
            </label>

            <div className='content-container__actionBox mt-4'>
              <label className='mt-4 d-block w-100'>Recommended Content*</label>
              <div className='content-container__filterSearch'>
                <Search
                  value={values}
                  placeholder='Search'
                  className={''}
                  onChange={inputChangeHandler}
                />
              </div>
            </div>

            <div className='mt-3 content-container__table'>
              <CustomTable
                data={currentData}
                tableHeader={tableHeader}
                startIndex={startIndex}
                selectedItems={selectedItems} // Pass selectedItems as a prop
                onSelectedItemsChange={handleSelectedItemsChange} // Pass the function to update selectedItems
              />
            </div>

            {/* Custom Pagination */}
            {currentData.length !== 0 && (
              <div className='pagination'>
                <button
                  onClick={() =>
                    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
                  }
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                {Array.from(
                  { length: lastVisiblePage - firstVisiblePage + 1 },
                  (_, index) => (
                    <button
                      key={firstVisiblePage + index}
                      onClick={() => setCurrentPage(firstVisiblePage + index)}
                      className={
                        currentPage === firstVisiblePage + index ? 'active' : ''
                      }
                    >
                      {firstVisiblePage + index}
                    </button>
                  )
                )}
                <button
                  onClick={() =>
                    setCurrentPage((prevPage) =>
                      Math.min(prevPage + 1, totalPages)
                    )
                  }
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            )}

            {/* Dragabble Table */}

            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId='droppable' direction='vertical'>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <CustomDragabbleTable
                      data={selectedItems}
                      draggableTableHeader={draggableTableHeader}
                      isSubmitClicked={isSubmitClicked}
                      onSelectedItemsChange={handleSelectedItemsChange}
                    />
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            {/* Date Picker */}

            <Calender
              onStartDateSelected={onStartDateSelected}
              onEndDateSelected={onEndDateSelected}
              startDate={startDate}
              endDate={endDate}
              mandatory={true}
            />

            {/* Status Toggle Start */}

            <div className='d-block my-4'>
              <span className='me-2'>Status*</span>
              <label className='d-inline-block'>
                <div className='d-inline-flex align-items-center'>
                  <div className='d-inline-flex text-danger'>OFF</div>
                  <div className='d-inline-flex mx-2'>
                    <Toggle
                      checked={toggleStatus}
                      icons={false}
                      onChange={handleToggleChange}
                    />
                  </div>
                  <div className='d-inline-flex text-green'>ON</div>
                </div>
              </label>
            </div>
          </>
        ) : null}

        {activeTab.short_name !== 'en' ? (
          <table className='mb-5 mt-5'>
            <tr>
              <th className='nowrap'>Content Id</th>
              <th className='nowrap'>Title</th>
              <th className='nowrap'>Translation Status</th>
            </tr>
            {langContent?.map((items, index) => (
              <tr key={index}>
                <td>{items?.content_id}</td>
                <td>{items?.title}</td>
                <td>
                  {items?.translation_available ? 'Available' : 'Not Available'}
                </td>
              </tr>
            ))}
            {langContent?.length === 0 && (
              <tr>
                <td colspan='10000'>No data found</td>{' '}
              </tr>
            )}
          </table>
        ) : null}

        <Button
          onClick={handleCreateRecommendations}
          type='submit'
          name='Submit'
        />
      </div>

      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </>
  );
};

export default CreateRecommendations;
