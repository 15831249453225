import React from 'react';

// Pages
import BasePage from 'pages/BasePage'; 
import TipOfTheDayList from 'container/TipoftheDay';
import { TipOfTheDayHeader } from 'constants/table-header-constants';

const TipOfTheDay = () => {
  return (
    <BasePage>
        <TipOfTheDayList  tableHeader={TipOfTheDayHeader} />
    </BasePage>
  );
};

export default TipOfTheDay;
