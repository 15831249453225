import React from 'react';

// Components
import NewInput from 'components/NewInput';
import Button from 'components/button';

import { CloseBlackIcon } from 'constants/image-constants';

import './index.scss';

const QuizRadioButton = ({
  id,
  label,
  isChecked,
  value,
  onSelect,
  handleOptionLabelChanged,
  handleAddOption,
  handleRemoveOption,
  isLast,
  showCloseMark,
  ...props
}) => {
  const addOptionClick = (e) => {
    handleAddOption && handleAddOption(e);
  };

  const removeOptionClick = (e) => {
    handleRemoveOption && handleRemoveOption(e);
  };

  return (
    <div className='radio-btn'>
      <input
        {...props}
        id={id}
        className='form-check-input'
        type='radio'
        name={props.name ? props.name : 'inlineRadioOptions'}
        onChange={(e) => {
          onSelect && onSelect(e);
        }}
        value={value}
        checked={isChecked}
        defaultChecked={isChecked}
      />
      <NewInput
        id={id}
        type='text'
        placeholder='Ex: Option goes here'
        name='title'
        value={label}
        onChange={handleOptionLabelChanged}
      />
      {showCloseMark && (
        <img
          id={id}
          className='radio-btn_remove'
          src={CloseBlackIcon}
          alt='Remove'
          onClick={removeOptionClick}
        />
      )}
      {isLast === true && (
        <Button
          className='radio-btn_addoption'
          onClick={addOptionClick}
          type='submit'
          name='Add option'
        />
      )}
    </div>
  );
};

export default QuizRadioButton;
