import React from 'react';

// Pages
import BasePage from 'pages/BasePage'; 
import DynamicWidgetList from 'app/container/DynamicWidgets';
import { DynamicWidgetHeader } from 'constants/table-header-constants';

const DynamicWidgets = () => {
  return (
    <BasePage>
      <DynamicWidgetList tableHeader={DynamicWidgetHeader}/>
    </BasePage>
  );
};

export default DynamicWidgets;
