import React from 'react';
import Toggle from 'react-toggle';
import NewInput from 'app/components/NewInput';
import NewAttachment from 'app/components/NewDesign/NewAttachment';
import Button from 'app/components/button';
import AddedQuiz from 'app/container/CmsContent/Content-Types/components/added-quiz';
import CreateQuizTable from 'app/container/CmsContent/Content-Types/components/quiz-create-table';
import { useEffect, useState } from 'react';
import Toast from 'app/utils/Toast';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import {
  getCourseQiuzApi,
  updateCoursesQuiz,
} from 'app/api/latest/Courses/courses';

const CoursesQuizContainer = ({ data }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [endlessScrollMedia, setEndlessScrollImage] = useState(null);
  const [landingPageImage, setLandingPageImage] = useState(null);
  const [chapterData, setChapterData] = useState([]);
  const [inputObj, setInputObj] = useState({
    QuizPublishStatus: false,
    title: '',
    quiz_image: '',
    course_id: '',
    quiz_duration: '',
    content_type: '',
    quiz: [],
    publish_status: '',
    quiz_attachment_image: null,
  });

  const onQuizImageSeleted = (file) => {
    setInputObj((prevState) => ({
      ...prevState,
      quiz_attachment_image: file,
    }));
  };

  /////////////// Functions for quiz///////////////
  const history = useHistory();

  const [loader, setLoader] = useState(false);

  const [tags, setTags] = useState([]);

  const params = useParams();
  const location = useLocation();
  const course_id = location?.state?.courseId
    ? location?.state?.courseId
    : chapterData?.course_id;

    const courseTitle = location?.state?.courseTitle;

  // useEffect(() => {
  //   getTagsHandler();
  // }, []);

  const courseDuration = (value) => {
    let splitTime = value.split(':');
    return splitTime;
  };

  const updateStates = async () => {
    // const Quizid = '5fa73f0c-a919-4ddf-920f-6b7a3bb4a0e0';
    if (params?.id) {
      if (!params?.id) {
        return;
      }

      let response = await getCourseQiuzApi(params?.id);
      setChapterData(response?.data);
      setIsEditMode(true);
      let requiredResponse = response.data;
      let stateValues = Object.keys(inputObj);
      let temporaryState = {};
      for (let index in stateValues) {
        temporaryState[stateValues[index]] =
          requiredResponse[stateValues[index]];
      }
      temporaryState.title = requiredResponse.quiz_title;
      temporaryState.duration_minute = courseDuration(
        requiredResponse?.quiz_duration
      )[0];
      temporaryState.duration_second = courseDuration(
        requiredResponse?.quiz_duration
      )[1];

      temporaryState.quiz = requiredResponse.quiz;

      setInputObj(temporaryState);
    }
  };

  useEffect(() => {
    updateStates();
  }, []);

  function inputChangeHandler(event) {
    const { name, value } = event.target;
    setInputObj({ ...inputObj, [name]: value });
  }
  function addQuizHandler(quiz) {
    console.log('quiz', quiz);
    setInputObj({ ...inputObj, quiz: quiz });
  }

  const validateForm = () => {
    let flag = true;
    let validationArray = Object.keys(inputObj);
    let optionalFields = ['end_date', 'description'];
    for (let index in validationArray) {
      if (
        inputObj[validationArray[index]] === '' &&
        !optionalFields.includes(validationArray[index])
      ) {
        flag = false;
        Toast.error('Please complete all fields');
        break;
      } else if (
        validationArray[index] === 'tags' &&
        inputObj.tags?.length === 0
      ) {
        flag = false;
        Toast.error('Please complete all fields');
        break;
      }
    }

    return flag;
  };

  // Submit Handler

  const handleSubmit = async () => {

    if (!course_id) return;

    if (!inputObj.title) {
      Toast.error('Title is required');
      return;
    }
  
    if (!inputObj.quiz_image && !inputObj.quiz_attachment_image) {
      Toast.error('Quiz Image is required');
      return;
    }
  
    if (!inputObj.duration_minute) {
      Toast.error('Duration (Minutes) is required');
      return;
    }

    if (!inputObj.duration_second || inputObj.duration_second < 0 || inputObj.duration_second > 59) {
      Toast.error('Duration (Seconds) is required and should be between 0 and 59');
      return;
    }

    inputObj?.quiz &&
      inputObj?.quiz.map((item, index) => {
        inputObj.quiz[index].question_id = index + 1;
      });

    if (inputObj?.quiz.length === 0) {
      Toast.error('Minimum one Quiz must be there!!');
      return;
    }

    setLoader(true);
    let formData = new FormData();
    formData.append('title', inputObj.title ? inputObj.title : '');
    formData.append(
      'quiz_image',
      inputObj.quiz_attachment_image
        ? inputObj.quiz_attachment_image
        : inputObj.quiz_image
    );
    formData.append(
      'quiz_duration',
      inputObj.duration_minute
        ? `${inputObj.duration_minute}:${inputObj.duration_second}`
        : ''
    );
    formData.append(
      `quiz`,
      inputObj?.quiz ? JSON.stringify(inputObj?.quiz) : []
    );
    formData.append(
      'publish_status',
      inputObj?.QuizPublishStatus ? true : false
    );

    formData.append('course_id', course_id ? course_id : '');
    formData.append('quiz_id', params?.id ? params?.id : '');

    let responseObj;
    {
      responseObj = await updateCoursesQuiz(formData);

      setLoader(false);
    }
    setLoader(false);

    if (responseObj?.response?.code === 200) {
      if (data) {
        Toast.success('Updated Quiz');
        history.goBack();
      } else {
        Toast.success('Quiz Added');
        history.goBack();
      }
    } else {
      Toast.error('Something went wrong!!');
    }
  };

  return (
    <>
      <div className='section-header'>
        <h4>Course Creation &gt; {courseTitle} &gt; {inputObj?.title ? inputObj?.title : 'Enter Quiz Chapter' }</h4>
      </div>

      <section className='course-section'>
        <div className='course-section_title'>
          <h4 className={!inputObj?.title && 'heading-fade'}>{inputObj?.title ? inputObj?.title : 'Enter Quiz Chapter' }</h4>
          <Toggle
            icons={false}
            checked={inputObj.QuizPublishStatus}
            name='ios'
            onChange={(e) => {
              setInputObj({
                ...inputObj,
                QuizPublishStatus: !inputObj.QuizPublishStatus,
              });
            }}
          />
        </div>

        <div className='_content'>
          <div className='form-wrapper'>
            <div className='video-field-wrap'>
              <div className='form-field'>
                <NewInput
                  label='Title'
                  onChange={inputChangeHandler}
                  name={'title'}
                  value={inputObj.title ? inputObj.title : ''}
                  asterisk={true}
                />
              </div>
              <div className='form-field'>
                <NewAttachment
                  label='Add Chapter Image'
                  name='course_image'
                  value={inputObj?.quiz_image}
                  id='CI'
                  onFileSelected={onQuizImageSeleted}
                  fileType='image'
                  className='widget_box_feed__attachment'
                  asterisk={true}
                />
              </div>
              <div className='form-field duration-field'>
                <label>Duration<span className='asterisk'>*</span></label>
                <div className='two-field'>
                  <NewInput
                    placeholder='MM'
                    name={'duration_minute'}
                    value={inputObj?.duration_minute}
                    onChange={inputChangeHandler}
                    type={'number'}
                  />
                  <span>:</span>
                  <NewInput
                    placeholder='SS'
                    name={'duration_second'}
                    value={inputObj?.duration_second}
                    onChange={inputChangeHandler}
                    type={'number'}
                  />
                </div>
              </div>
            </div>
            <div className='mt-4 w-100'>
              <div className='quiz-con__table'>
                <label>Quiz Details</label>
                {isEditMode ? (
                  <>
                    <AddedQuiz
                      quizArray={inputObj.quiz}
                      onUpdateQuiz={(newQuiz) =>
                        setInputObj({ ...inputObj, quiz: newQuiz })
                      }
                    />
                    <Button
                      style={{
                        margin: '2rem',
                      }}
                      onClick={() => {
                        setInputObj({ ...inputObj, quiz: [] });
                        setIsEditMode(false);
                      }}
                    >
                      Clear All Quiz & Start new quiz
                    </Button>
                  </>
                ) : (
                  <CreateQuizTable onAddQuizDetails={addQuizHandler} />
                )}
              </div>
            </div>
            <div className='d-flex flex-row-reverse w-100 btn-right mt-4'>
              <button className='primary-btn button' onClick={handleSubmit}>
                Save
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CoursesQuizContainer;
