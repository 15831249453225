import React from 'react';

// Pages
import BasePage from 'pages/BasePage';

import CreateSplashScreen from 'app/container/CreateSplashScreen';

const SplashScreenCreate = () => {
  return (
    <BasePage>
      <CreateSplashScreen />
    </BasePage>
  );
};

export default SplashScreenCreate;
