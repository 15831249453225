import CoursesTextContainer from "app/container/CoursesCreationContainer/CoursesText";
import BasePage from "app/pages/BasePage";

const CoursesText = () => {
    return (
        <BasePage>
            <CoursesTextContainer />
        </BasePage>
    );
}

export default CoursesText;