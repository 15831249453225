import {
  getStartCourseList,
  submitCoursesByCohortAPI,
} from 'app/api/latest/Courses/courses';
import { useEffect, useState } from 'react';
import Toast from 'utils/Toast';
const useStartRecommendedCourse = (validate) => {
  const [startListData, setStartListData] = useState([]);
  const [startListOriginalData, setStartListOriginalData] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);

  const getStartList = async () => {
    setPageLoading(true)
    let response = await getStartCourseList();
    setPageLoading(false)
    let tempResponse =
      response?.data &&
      response?.data.map((item) => {
        return {
          id: item?.id,
          cohorts: item?.cohorts,
          course_uuid: item?.course_uuid,
          age_range: item?.age_range,
        };
      });
    setStartListOriginalData(tempResponse);

    const groupedItems =
      response?.data &&
      response?.data?.reduce((acc, item) => {
        if (!acc[item?.cohorts]) {
          acc[item?.cohorts] = [];
        }
        // Assign sortOrder based on age_range
        const sortOrder =
            item?.age_range === '<18'
            ? 0
            : item?.age_range === '18-55'
            ? 1
            : item?.age_range === '55+'
            ? 2
            : null;
        // Assign sortOrder to each item and push to the cohort array
        const newItem = { ...item, sortOrder };
        acc[item?.cohorts]?.push(newItem);
        acc[item?.cohorts]?.sort((a, b) => a.sortOrder - b.sortOrder);
        return acc;
      }, {});
    setStartListData(groupedItems);
  };

  useEffect(() => {
    getStartList();
  }, []);

  const startSelectedValue = (value, id) => {
    let newData = startListOriginalData.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          course_uuid: value && value,
        };
      }
      return item;
    });
    setStartListOriginalData(newData);
  };

  const publishStartCourses = async () => {
    setPageLoading(true)
    let responseObj = await submitCoursesByCohortAPI(startListOriginalData);
    setPageLoading(false)
    if (responseObj?.response?.code === 200) {
      Toast.success(responseObj?.data?.message);
      getStartList();
    } else {
      Toast.error(
        responseObj?.response?.error
          ? responseObj?.response?.error
          : 'Something went wrong .'
      );
    }
  };

  return {
    pageLoading,
    publishStartCourses,
    startListData,
    startSelectedValue,
  };
};

export default useStartRecommendedCourse;
