import Toast from 'app/utils/Toast';
import { API_CONSTANTS } from 'constants/api-contants';
import Parsers from 'utils/Parsers';
import * as dataAccess from 'utils/ajax';

export const getWidgetList = async () => {
  let responseObj = await dataAccess.get(API_CONSTANTS.GET_WIDGETS_LIST);
  return responseObj;
};

export const getWidgetType = async () => {
  let responseObj = await dataAccess.get(API_CONSTANTS.GET_WIDGETS_TYPES);
  return responseObj;
};

export const addWidget = async (incomingData) => {
  var formData = Parsers.ConvertObjectAsFormData(incomingData);

  let payload = {
    data: formData,
  };

  return await dataAccess.upload(API_CONSTANTS.ADD_WIDGETS, payload);
};

export const updateWidget = async (incomingData) => {
  let formData = Parsers.ConvertObjectAsFormData(incomingData);
  let payload = {
    data: formData,
  };
  let response = dataAccess.update(API_CONSTANTS.UPDATE_WIDGETS, payload);
  return response;
};

export const updateWidgetStatus = async (incomingData) => {
  let formData = Parsers.ConvertObjectAsFormData(incomingData);
  let payload = {
    data: formData,
  };
  let response = dataAccess.update(API_CONSTANTS.UPDATE_WIDGET_STATUS, payload);
  return response;
};

export const deleteWidget = async (id) => {
  let responseObj = await dataAccess.del(
    `${API_CONSTANTS.DELETE_WIDGETS}/${id}`
  );
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    Toast.success(responseObj.response.alert[0].message);
    return responseObj.data;
  } else {
    console.log(responseObj, 'responseObj');
    Toast.success('Something went wrong try again');
    return [];
  }
};

export const deleteBanner = async (id) => {
  let responseObj = await dataAccess.del(
    `${API_CONSTANTS.DELETE_BANNER}/${id}`
  );
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data;
  } else {
    return [];
  }
};

export const getWidgetBannerDetailsById = async (id, lang) => {
  let responseObj = await dataAccess.get(
    `${API_CONSTANTS.GET_BANNER_LOCALISATION_DETAILS}/${id}?lang=${lang}`
  );
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data;
  } else {
    return [];
  }
};
export const getWidgetDetailsById = async (id, lang) => {
  let responseObj = await dataAccess.get(
    `${API_CONSTANTS.GET_WIDGET_LOCALISATION_DETAILS}/${id}?lang=${lang}`
  );
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data;
  } else {
    return [];
  }
};

export const updateWidgetBannerDetailsById = async (incomingData, id, lang) => {
  // let formData = Parsers.ConvertObjectAsFormData(incomingData);
  let payload = {
    data: incomingData,
  };
  let response = dataAccess.put(
    `${API_CONSTANTS.UPDATE_BANNER_LOCALISATION_DETAILS}/${id}?lang=${lang}`,
    payload
  );
  return response;
};

export async function uplaodMedia(name, data) {
  let formData = new FormData();
  formData.append(name, data);
  let payload = {
    data: formData,
  };

  let responseObj = await dataAccess.upload(
    `${API_CONSTANTS.UPLOAD_WIDGET_IMAGE}`,
    payload
  );

  return responseObj.data;
}

export const getStaticWidgetList = async () => {
  let responseObj = await dataAccess.get(API_CONSTANTS.GET_STATIC_WIDGETS_LIST);
  return responseObj;
};

export const getStaticWidgetLocalisationDetails = async (id, lang) => {
  let responseObj = await dataAccess.get(
    `${API_CONSTANTS.GET_STATIC_WIDGETS_LIST_VERSION}/${id}?lang=${lang}`
  );
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data;
  } else {
    return [];
  }
};

export const updateStaticWidget = async (incomingData) => {
  let data = {
    widget_details: JSON.stringify(incomingData),
  };
  let formData = Parsers.ConvertObjectAsFormData(data);
  let payload = {
    data: formData,
  };
  let response = dataAccess.update(
    API_CONSTANTS.UPDATE_STATIC_WIDGETS,
    payload
  );
  return response;
};

export const updateStaticWidgetLocalisation = async (incomingData, lang) => {
  let data = {
    widget_details: JSON.stringify(incomingData),
  };
  let formData = Parsers.ConvertObjectAsFormData(data);
  let payload = {
    data: formData,
  };
  let response = dataAccess.update(
    `${API_CONSTANTS.UPDATE_STATIC_WIDGETS_VERSION}/${incomingData?.widget_id}?lang=${lang}`,
    payload
  );
  return response;
};

export const updateDynamicWidgetLocalisation = async (
  incomingData,
  id,
  lang
) => {
  let formData = Parsers.ConvertObjectAsFormData(incomingData);
  let payload = {
    data: formData,
  };
  let response = dataAccess.update(
    `${API_CONSTANTS.GET_WIDGET_LOCALISATION_DETAILS}/${id}?lang=${lang}`,
    payload
  );
  return response;
};

export const getLandingPageList = async () => {
  let responseObj = await dataAccess.get(API_CONSTANTS.GET_LANDING_LIST);
  return responseObj;
};

export async function deleteLandingById(id) {
  let responseObj = await dataAccess.del(
    `${API_CONSTANTS.GET_LANDING_LIST}/${id}`
  );
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    Toast.success(responseObj.response.alert[0].message);
  }
}


export const createLandingScreen = async (incomingData) => {
  let payload = {
    data: incomingData,
  };
  return await dataAccess.post(API_CONSTANTS.GET_LANDING_LIST,payload);
  
};

export const updateLandingActiveStatus = async (incomingData, id) => {
  console.log(id)
  // let formData = Parsers.ConvertObjectAsFormData(incomingData);
  let payload = {
    data: incomingData,
  };
  let response = dataAccess.put(
    `${API_CONSTANTS.GET_LANDING_LIST}/${id}`,
    payload
  );
  return response;
};

export const getUserLandingByID = async (id) => {
  let responseObj = await dataAccess.get(
    `${API_CONSTANTS.GET_LANDING_LIST}/${id}`
  );
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data;
  } else {
    return [];
  }
};

export const updateUserLandingScreen = async (incomingData,id) => {
  console.log(id,incomingData,"id");
  let payload = {
    data: incomingData,
  };
  let response = dataAccess.put(
    `${API_CONSTANTS.GET_LANDING_LIST}/${id}`,
    payload
  );
  return response;
};

export const getLandingPageListScreen = async () => {
  let responseObj = await dataAccess.get(API_CONSTANTS.GET_LANDING_PAGE_LIST_SCREEN);
  return responseObj;
};


export const createLandingPageScreen = async (incomingData) => {
  let payload = {
    data: incomingData,
  };
  return await dataAccess.post(API_CONSTANTS.CREATE_LANDING_PAGE_SCREEN,payload);
  
};

export const updateLandingPageScreen = async (incomingData) => {
  let payload = {
    data: incomingData,
  };
  return await dataAccess.put(API_CONSTANTS.UPDATE_LANDING_PAGE_SCREEN,payload);
  
};

export async function deleteLandingPageById(id) {
  let responseObj = await dataAccess.del(
    `${API_CONSTANTS.DELETE_LANDING_PAGE_SCREEN}/${id}`
  );
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    Toast.success(responseObj.response.alert[0].message);
  }
}

export async function deleteLandingPageSectionById(id) {
  let responseObj = await dataAccess.del(
    `${API_CONSTANTS.DELETE_LANDING_PAGE_ORDER}/${id}`
  );
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    Toast.success(responseObj.response.alert[0].message);
  }
}

