import React, { useEffect, useState } from 'react';

// Shared Components
import RadioButton from 'shared/components/RadioButton';
import Dropdown from 'shared/components/Dropdown';

// Utils
import DropdownOptions from 'utils/DropdownOptions';

// Constants
import { URLType } from 'constants/app-constants';

import './index.scss';

const Challenges = ({ onChange, hasChallenge, day }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [type, setType] = useState(false);

  useEffect(() => {
    setShowDropdown(hasChallenge === URLType.YES ? true : false);
    setType(hasChallenge);
  }, [hasChallenge]);

  const isRadioButtonSelected = (data) => {
    if (type && type.toUpperCase() === data) {
      return true;
    }
    return false;
  };

  const handleRadioButtonClick = (e) => {
    if (e.target.value === URLType.YES) {
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
    onChange &&
      onChange({
        target: {
          name: 'hasChallenge',
          value: e.target.value,
        },
      });
  };

  const handleChange = (e) => {
    onChange &&
      onChange({
        target: {
          name: 'day',
          value: e.target.value,
        },
      });
  };

  return (
    <>
      <div className='radio'>
        <div className='radio_container'>
          <h5 className='radio_container__title'>Challenges</h5>
          <RadioButton
            id='challenge_yes'
            label='Yes'
            isChecked={isRadioButtonSelected(URLType.YES)}
            value={URLType.YES}
            onSelect={handleRadioButtonClick}
          />

          <RadioButton
            id='challenge_no'
            label='No'
            isChecked={isRadioButtonSelected(URLType.NO)}
            value={URLType.NO}
            onSelect={handleRadioButtonClick}
          />
        </div>
      </div>
      {showDropdown && (
        <div className='radio__dropdown'>
          <Dropdown
            placeholder='Select Days'
            name='day'
            value={day}
            options={DropdownOptions.ChallengeDaysOption}
            handleChange={handleChange}
          />
        </div>
      )}
    </>
  );
};

export default Challenges;
