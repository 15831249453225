import { useEffect, useRef, useState } from 'react';
import VideoSnapshot from 'video-snapshot';

// Components
import ActionMenuUpload from 'components/ActionMenuUpload';

// Utils
import Parsers from 'utils/Parsers';

// Constants
import { AttachmentIcon, CloseBlackIcon } from 'constants/image-constants';

const WebinarAttachment = ({
  onFileSelected,
  value,
  id,
  label,
  fileType,
  className,
  onVideoThumbnail,
  videoThumbnail,
}) => {
  const imageRef = useRef();
  const videoRef = useRef();
  const [selImage, setSelImage] = useState(null);
  const [selVideo, setSelVideo] = useState(null);
  const [selVideoThumbnail, setSelVideoThumbnail] = useState(null);
  const [showActionPopup, setShowActionPopup] = useState(null);
  useEffect(() => {
    if (value) {
      let val = `${process.env.REACT_APP_S3_BUCKET_URL}/${value}`;
      let extension = Parsers.GetURLExtension(val);
      if (extension === 'mp4') {
        setSelVideo(val);
      } else {
        setSelImage(val);
      }
    } else {
      setSelVideo(null);
      setSelImage(null);
    }
  }, [value]);

  useEffect(() => {
    if (videoThumbnail) {
      let val = `${process.env.REACT_APP_S3_BUCKET_URL}/${videoThumbnail}`;
      setSelVideoThumbnail(val);
    }
  }, [videoThumbnail]);

  const attachmentClicked = () => {
    if (fileType === 'image' || fileType === 'video') {
      onActionTriggered(fileType);
    } else {
      setShowActionPopup(true);
    }
  };

  const handleClickOutside = () => {
    setShowActionPopup(false);
  };

  const updateImageDisplay = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const imageFile = event.target.files[0];
      if (
        imageFile.type === 'image/png' ||
        imageFile.type === 'image/jpeg' ||
        imageFile.type === 'image/jpg' ||
        imageFile.type === 'image/gif'
      ) {
        // setSelImage(imageFile);
        onFileSelected && onFileSelected(imageFile, id);
        var reader = new FileReader();
        reader.onloadend = function () {
          setSelImage(reader.result);
        };
        reader.readAsDataURL(imageFile);
      } else {
        onFileSelected && onFileSelected(imageFile, id);
      }
    }
  };

  const updateVideoDisplay = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const videoFile = event.target.files[0];
      onFileSelected && onFileSelected(videoFile);
      var reader = new FileReader();
      reader.onloadend = function () {
        setSelVideo(reader.result);
      };
      reader.readAsDataURL(videoFile);
      if (!selVideoThumbnail) {
        const snapshoter = new VideoSnapshot(videoFile);
        const previewSrc = await snapshoter.takeSnapshot();
        setSelVideoThumbnail(previewSrc);
        const base64Response = await fetch(previewSrc);
        const blob = await base64Response.blob();
        // const blobUrl = URL.createObjectURL(blob);
        var videoThumbnail = new File([blob], 'name.png', { type: blob.type });
        onVideoThumbnail && onVideoThumbnail(videoThumbnail);
      }
    }
  };

  // const handleVideoThumbnail = (imageFile, id) => {
  //   setSelVideoThumbnail(imageFile);
  //   onVideoThumbnail && onVideoThumbnail(imageFile, id);
  // };

  const handleRemove = () => {
    setSelImage(null);
    setSelVideo(null);
    setSelVideoThumbnail(null);
    onVideoThumbnail && onVideoThumbnail(null, id);
    onFileSelected && onFileSelected(null, id);
  };

  const onActionTriggered = (type) => {
    if (type === 'image') {
      setSelVideo(null);
      if (imageRef && imageRef.current) {
        imageRef.current.click && imageRef.current.click();
      }
    } else if (type === 'video') {
      setSelImage(null);
      if (videoRef && videoRef.current) {
        videoRef.current.click && videoRef.current.click();
      }
    }
    handleClickOutside();
  };

  const RenderImage = ({ children }) => {
    return (
      <div className='attachment_img_wrapper'>
        {children}
        <div className='attachment_img_wrapper_close'>
          <img src={CloseBlackIcon} alt='Remove' onClick={handleRemove} />
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className='attachment'>
        <img src={AttachmentIcon} alt='Add Attachment' width='25' height='25' />
        <p className='attachment_text' onClick={attachmentClicked}>
          {label ? label : 'Add an attachment'}
        </p>
        <input
          ref={imageRef}
          type='file'
          id={id ? id : 'file'}
          accept='.jpg, .jpeg, .png, .gif'
          className='attachment__input-file'
          onChange={(e) => updateImageDisplay(e)}
        />
        <input
          ref={videoRef}
          type='file'
          id={id ? id : 'video'}
          accept='video/mp4, video/x-m4v'
          className='attachment__input-file'
          onChange={(e) => updateVideoDisplay(e)}
        />
        {selImage && (
          <RenderImage>
            <img src={selImage} alt='sample' className={className} />
          </RenderImage>
        )}
        {selVideo && (
          <RenderImage>
            <video className='attachment__selimage' controls>
              <source src={selVideo} type='video/mp4' />
            </video>
          </RenderImage>
        )}
        {showActionPopup && (
          <ActionMenuUpload
            onClickOutside={handleClickOutside}
            onActionTriggered={onActionTriggered}
          />
        )}
      </div>

      {/* {selVideo && (
        <FileAttachment
          label='Add Video Thumbnail'
          onFileSelected={handleVideoThumbnail}
          value={selVideoThumbnail}
          fileFormat='.jpg, .jpeg, .png, .gif'
          isRemoveAttachment={false}
        />
      )} */}
    </div>
  );
};

export default WebinarAttachment;
