import { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import Loader from 'components/Loader';
// import NewTable from 'components/NewTable';
import NewTable from 'components/NewDraggableTable';

import PageLoader from 'components/PageLoader';

// Constants

// Utils
import Message from 'utils/Message';
import Toast from 'utils/Toast';

// API
import {
  addOrUpdateHabitDragDrop,
  getHabit,
  publishOrUnpublishHabit,
} from 'app/api/habits';

const HabitList = ({ tableHeader }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [pageLoading, setPageLoading] = useState(false);

  // const [feedSummary, setFeedSummary] = useState({
  //   totalFaq: 0,
  // });

  const callPublishOrUnpublishAPI = async (data) => {
    console.log(data, 'articledata');
    setPageLoading(true);
    if (data && data.habit_id && data.status) {
      let responseObj = await publishOrUnpublishHabit(
        data.habit_id,
        data.status
      );
      setPageLoading(false);
      if (
        responseObj &&
        responseObj.response &&
        responseObj.response.code === 200
      ) {
        loadNames();
        let message = Message.Habit.PUBLISH_UNPUBLISH_SUCCESS.replace(
          '{{placeholder}}',
          data.status.toLowerCase() === 'active' ? 'Unpublished' : 'Published'
        );
        Toast.success(message);
      }
    } else {
      let message = Message.Habit.PUBLISH_UNPUBLISH_FAILURE.replace(
        '{{placeholder}}',
        data.status.toLowerCase() === 'active' ? 'Unpublish' : 'Publish'
      );
      Toast.error(message);
    }
  };

  const HomePageOrder = (tempData) => {
    const HomepageData = tempData.map((data, index) => {
      console.log(data, 'sdfghjk');
      return {
        habit_id: data.original.habit_id,
        sorting_order: index + 1,
      };
    });
    addOrUpdateHabitDragDrop(HomepageData);
  };

  const loadNames = (start, end, increase) => {
    setLoading(true);
    getHabit(start, end).then((response) => {
      console.log(response, 'response');
      setLoading(false);
      if (response && response.tableData && response.error === false) {
        // console.log(response.tableData, 'tableData');
        setData({
          tableData: response.tableData,
        });
        // setFeedSummary(response.summary);
      }
    });
  };

  // const handleResponseCallback = (response) => {
  //   if (response && response.tableData && response.error === false) {
  //     setFeedSummary(response.summary);
  //   }
  // };

  // const callDeleteAPI = async (data) => {
  //   setPageLoading(true);
  //   if (data && data.faq_id) {
  //     let responseObj = await deletefaq(data.faq_id);
  //     setPageLoading(false);
  //     if (
  //       responseObj &&
  //       responseObj.response &&
  //       responseObj.response.code === 200
  //     ) {
  //       loadNames();
  //       Toast.success(Message.Faq.DELETE_SUCCESS);
  //     } else {
  //       Toast.error(Message.Faq.DELETE_FAILURE);
  //     }
  //   }
  // };

  const menuActionObj = [
    {
      name: 'Edit',
      onClick: (e, data) => {
        console.log('Edit Clicked: ', data);
        history.push({
          pathname: `/habit/edit/${data.habit_id}`,
          state: {
            ...data,
          },
        });
      },
    },
    {
      name: 'Publish',
      type: 'publish',
      onClick: (e, data) => {
        console.log('Publish/Unpublish Clicked: ', data);
        callPublishOrUnpublishAPI(data);
      },
    },
    // {
    //   name: 'Delete',
    //   onClick: (e, data) => {
    //     console.log('Delete Clicked: ', data);
    //     callDeleteAPI(data);
    //   },
    // },
  ];

  const sectionObj = {
    sectionTitle: 'Habit List',
  };

  if (loading === true) {
    return <Loader />;
  }

  return (
    <div className='blogs'>
      {/* <NewTable
        sectionObj={sectionObj}
        tableHeader={tableHeader}
        menuAction={menuActionObj}
        data={data}
        getData={getHabit}
        searchKey='question'
        responseCallback={handleResponseCallback}
      /> */}

      <NewTable
        sectionObj={sectionObj}
        tableHeader={tableHeader}
        menuAction={menuActionObj}
        data={data}
        HomePageOrder={HomePageOrder}
        getData={getHabit}
        searchKey='question'
        // searchKey='activity_name'
        // hasPagination={false}
      />

      {pageLoading === true && <PageLoader pageLoading={pageLoading} />}
    </div>
  );
};

export default HabitList;
