import "./index.scss";
import Search from "app/components/search";
import { useEffect, useState } from "react";
import Button from "app/components/button";
import CustomTable from './table';
import Pagination from "./pagination";
import SelectComp from "./select-tag";
import { useHistory } from 'react-router-dom';
import ArticleModal from "app/components/ArticleModal";
import PageLoader from "app/components/PageLoader";
import { getCmsCollections, deleteCollection } from "app/api/cms-collections";

const options = [
    { value: 10, label: 10, },
    { value: 20, label: 20, },
    { value: 30, label: 30, },
    { value: 40, label: 40, },
    { value: 50, label: 50, },
]

const Collections = () => {
    const history = useHistory();

    const [values, setValues] = useState("");
    const [data, setData] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [pageCount, setPageCount] = useState(1); 
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10);
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        getContentDataHandler();
    }, []);

    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    let currentData = data.slice(indexOfFirstData, indexOfLastData)

    async function getContentDataHandler() {
        setLoader(true)
        const dataArr = await getCmsCollections()
        setData(dataArr);
        let count = Math.ceil(dataArr.length / dataPerPage);
        setPageCount(count)
        setLoader(false)
    }

    async function handleDelete(id) {
        setLoader(true)
        await deleteCollection(id)
        getContentDataHandler()
    }
    function inputChangeHandler(searchVal) {
        if (searchVal.trim() === "") {
            setValues(searchVal.toLowerCase());
            // getContentDataHandler()
            return;
        }
        const filteredData = []
        data.forEach((item) => {
            
            if (item.collection_name.toLowerCase().includes(searchVal.toLowerCase())) {
                filteredData.push(item);
            }
        });
        setData(filteredData);
        setValues(searchVal);
    };

    function paginationHandler(page) {
        setCurrentPage(page);
    }

    function changeHandler(event) {
        const pageLength = event.target.value
        setDataPerPage(pageLength);
        let count = Math.ceil(data.length / pageLength);
        setPageCount(count)
    }

    return (<> {showFilter && (
        <ArticleModal
            modalCloseClicked={() => setShowFilter(false)}
            filterApplyClicked={() => { }}
            ResetFilterCicked={() => { }}
            handleChange={() => { }}
            values={""}
            error={""}
        />
    )}
        <div className="cms-content__container">
            <h4>Collections</h4>
            {
               loader && <PageLoader pageLoading={loader} />
          }
            <div className="content-container__actionBox">
                <div className="content-container__filterSearch">
                    <Search
                        value={values}
                        placeholder="Search"
                        className={""}
                        onChange={inputChangeHandler}
                    />
                    {/* <Button
                        onClick={() => setShowFilter(true)}
                        className="content-container__filter-btn">Filter</Button> */}
                </div>
                <Button onClick={() => history.push('/cms/collection/create')} className="content-container__filter-btn">Create Collection</Button>
            </div>
            <div className="content-container__perPage">
                Show
                <SelectComp
                    options={options}
                    onChangeHandler={changeHandler}
                />
                entries
            </div>

            <div className="content-container__table">
                <CustomTable
                    data={currentData}
                    handleDelete={handleDelete}
                />
            </div>

            <div className="content-container__pagination">
                <Pagination
                    pages={pageCount}
                    onChange={paginationHandler}
                />
            </div>
        </div>
    </>

    );
}

export default Collections