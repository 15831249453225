import React, { useEffect, useState } from "react";

// Constants
import AltTag from "constants/alt-tag";
import { APP_CONSTANTS } from "constants/app-constants";

// Images
import { AppLogoImage, LogoutImage } from "constants/image-constants";

// API
import { logoutAction } from "api/onboarding";

// utils
import { LocalStorage } from "utils/storage";

import "./index.scss";

const Header = () => {
  const [showProductionBanner, setShowProductionBanner] = useState(false);

  useEffect(() => {
    const productionBanner = process.env.REACT_APP_PRODUCTION_BANNER === "true";
    setShowProductionBanner(productionBanner);
  }, []);
  const handleLogOut = async () => {
    let responseObj = await logoutAction();
    if (
      responseObj &&
      responseObj.response &&
      responseObj.response.code === 200
    ) {
      LocalStorage.clear();
      window.location.href = APP_CONSTANTS.LOGIN;
    }
  };

  return (
    <>
     {showProductionBanner && (
        <div
          className="p-1 text-white text-center tracking-widest"
          style={{
            background: "#ff0000",
            letterSpacing: "3px",
            fontWeight: "500",
          }}
        >
          "THIS IS PRODUCTION CMS"
        </div>
      )}
      <div className="header">
        <img className="header__logo" alt={AltTag.AppLogo} src={AppLogoImage} />
        <img
          // onClick={() => {
          //   LocalStorage.clear();
          //   window.location.href = APP_CONSTANTS.LOGIN;
          // }}
          onClick={handleLogOut}
          className="header__logout"
          src={LogoutImage}
          alt={AltTag.Logout}
          data-toggle="tooltip"
          data-placement="bottom"
          title="Logout"
        />
      </div>
    </>
  );
};

export default Header;
