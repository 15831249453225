import { useHistory } from "react-router"
import { useState } from 'react';
import './index.scss'
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
function CustomTable({ data, tableHeader, currentPage }) { 
     const [showModal, setShowModal] = useState(false); 
  const [modalData, setModalData] = useState(null); 
     function extractCmsLink(url) {
          return '/' + url.split('/cms/')[1] || 'cms';
        }
        const handleModalClose = () => {
          setShowModal(false); 
        };
        const handleDataClick = (jsonData) => {
          setModalData(jsonData); 
          setShowModal(true); 
        };
     return (
          <div>
          <table>
               <tr>
                    {tableHeader.map((item,i) => {
                         return (
                              <th key={item.Header} className='nowrap text-center'>{item.Header}</th>
                         )
                    })} 
               </tr>
               {data?.map((items, index) => (<tr key={index}>
                    <td>{index + 1}</td>  
                    <td>{items.method_type?items.method_type.toString(): '-'}</td>
                    <td>{items.url && extractCmsLink(items.url)}</td>
                    <td><button className="btn btn-outline-primary" onClick={()=>handleDataClick(items)}>View Data</button></td>
                    <td>{items.created_on?moment(items.created_on).format('DD-MM-YYYY HH:mm'):"-"}</td>
                    <td>{items.updated_by}</td>
                   
               </tr>))}
               {data?.length ===0 &&  <tr><td colspan="10000">No data found</td> </tr>}
          </table>
          <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header>
          <Modal.Title>Data Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <pre style={{ whiteSpace: 'pre-wrap' }}>
            JSON Data: {JSON.stringify(modalData?.json_data, null, 2) || '-'}
          </pre>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
          </div>
     )
}

export default CustomTable