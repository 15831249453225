import { API_CONSTANTS } from "app/constants/api-contants";
import * as dataAccess from "utils/ajax";

export const getCmsCollections = async () => {
  let responseObj = await dataAccess.get(API_CONSTANTS.GET_COLLECTIONS_LIST);
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data;
  } else {
    return [];
  }
};

export async function uplaodMedia(name, data, path) {
  let formData = new FormData();
  formData.append(name, data);
  let payload = {
    data: formData,
  };

  let responseObj = await dataAccess.upload(
    `${API_CONSTANTS.UPLOAD_MEDIA}/${path}`,
    payload
  );

  return responseObj.data;
}

export async function createCollection(submittedData) {
  let responseObj = await dataAccess.post(
    `${API_CONSTANTS.CREATE_COLLECTION}`,
    { data: submittedData }
  );
  return responseObj;
}

export async function updateCollection(submittedData) {
  let responseObj = await dataAccess.put(`${API_CONSTANTS.CREATE_COLLECTION}`, {
    data: submittedData,
  });
  return responseObj;
}

export const deleteCollection = async (id) => {
  let responseObj = await dataAccess.del(
    `${API_CONSTANTS.DELETE_COLLECTION}/${id}`
  );
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data;
  } else {
    return [];
  }
};

export async function updateLocalisationCollection(submittedData, lang) {
  let responseObj = await dataAccess.put(`${API_CONSTANTS.CREATE_COLLECTION_VERSION}?lang=${lang}`, {
    data: submittedData,
  });
  return responseObj;
}
