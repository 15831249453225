import React from 'react';

// Pages
import BasePage from 'pages/BasePage';
import CreateSearchCategoryMed from 'app/container/CreateSearchCategoryMed';

const DiagnosticSearchPage = () => {
  return (
    <BasePage>
      <CreateSearchCategoryMed type={`Diagnostic Search`} />
    </BasePage>
  );
};

export default DiagnosticSearchPage;
