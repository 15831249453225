import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router"
// import './index.scss'
import moment from 'moment';
import { updateLandingActiveStatus} from 'app/api/widgets';
import Toast from 'app/utils/Toast';

import Toggle from 'react-toggle';

function CustomTable({ data, tableHeader, handleDelete, handleToggle, toggle, setToggle }) {
     const history = useHistory();
     const [toggleStatus, setToggleStatus] = useState(false);

     const handleNavigation = (items) => {
          history.push({
               pathname: `/user-landing/edit/${items.id}`,
               state: {
                    ...items,
               },
          });
     }

     useEffect(() => {
          const initialToggleStatus = {};
          data.forEach((item) => {
               initialToggleStatus[item.id] = item.status; // Assuming 'id' and 'status' are fields in your API response
          });

          setToggleStatus(initialToggleStatus);

     }, [data]);
     const handleToggleChange = (item) => {
          // setToggleStatus(!toggleStatus)

          // let newStatus = e.target.checked;
          let itemId = item.id
          const currentStatus = toggleStatus[itemId];
         
          const newStatus = currentStatus === "ACTIVE" ? "INACTIVE" :"ACTIVE" ;
          

          setToggleStatus((prevState) => ({
               ...prevState,
               [itemId]: newStatus,
             }));
             
          // Construct a payload with both 'id' and 'status' fields
          const payload = {
            is_active: newStatus === 'ACTIVE' ? true : false,
            
          };
          // Call your updateFamilyPlans function to update the status
          updateLandingActiveStatus(payload, item.id) // Pass the payload object
            .then((response) => { 
              if (response&&response.response.code === 200) {
                
                Toast.success('Successfully Updated')
                setToggleStatus((prevState) => ({
                  ...prevState,
                  [itemId]: newStatus,
                }));
              } else {
                Toast.error(response?.data)
               
                console.error('API update error', response);
              }
            })
            .catch((error) => {
              
              console.error(error, 'error');
            });
        };
        
     //  const newData=data.map((item)=>item.status)
     //  setToggle(newData)
     return (

          <table>
               <tr>
                    {tableHeader.map((item, i) => {
                         return (
                              <th className='nowrap text-center'>{item.Header}</th>
                         )
                    })}
               </tr>
               {data.map((items, index) => (<tr key={index}>
                    <td>{items.id ? items.id : ''}</td>
                    <td>{items.cohort ? items.cohort : ''}</td>


                    <td>{items.section ? items.section : ''}</td>
                    {/* <td>{items.start_date ? items.start_date : ''}</td>
                    <td>{items.end_date ? items.end_date : ''}</td> */}
                    <td>{items.start_time ? items.start_time.split("+", 1) : ''}</td>
                    {/* <td>{items.published_date?moment(items.published_date).format('DD-MM-YYYY'):'-'}</td> 
                    <td>{items.updated_date?moment(items.updated_date).format('DD-MM-YYYY'):"-"}</td>  */}
                    <td>{items.end_time ? items.end_time.split("+", 1) : ''}</td>
                    <td>
                         <Toggle checked={toggleStatus[items.id] == "ACTIVE" ? true : false}  onChange={() => handleToggleChange(items)} icons={false} />
                    </td>
                    <td>
                    <div class="btn-group" role="group" aria-label="Basic outlined example">     
                     <button
                         type="button"
                         className="btn btn-outline-primary"
                         onClick={() => handleNavigation(items)}
                    >
                         Edit
                    </button>
                    
                    <button type="button"
                              onClick={() => handleDelete(items.id)}
                              className="btn btn-outline-primary ml-2">Delete
                         </button>
                         </div>
                    </td>
                    {/* <td>
                         
                    </td> */}
                    {/* <td>
                    <div className="btn-group" role="group" aria-label="Basic outlined example">
                              <button
                                   type="button"
                                   className="btn btn-outline-primary"
                                   onClick={() => handleNavigation(items) }
                              >
                                   Edit
                              </button>
                              <button type="button"
                                   onClick={() => handleUnpublish(items) }
                                   className="btn btn-outline-primary">{items.status ==="ACTIVE"? "Unpublish" : "Publish"}
                              </button>
                               <button type="button"
                                   onClick={() => handleDelete(items) }
                                   className="btn btn-outline-primary">Delete
                              </button>
                               
                         </div> 
                    </td> */}

               </tr>))}
               {data.length ===0 &&  <tr><td colspan="10000">No data found</td> </tr>}
          </table>
     )
}

export default CustomTable