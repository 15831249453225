import BasePage from 'pages/BasePage';
import SuccessStoryPage from 'app/container/SuccessStoryPage';

const SuccessStoryMasterPage = () => {
  return (
    <>
      <BasePage>
        <SuccessStoryPage></SuccessStoryPage>
      </BasePage>
    </>
  );
};

export default SuccessStoryMasterPage;
