import {
  getCoins,
  getConversion,
  updateConversion,
} from 'app/api/latest/CoinsAPI';
import { ParseTableDateTime } from 'app/utils/Parsers';
import { useEffect, useState } from 'react';
import Toast from 'utils/Toast';
import { useHistory, useLocation } from 'react-router-dom';
import Message from 'app/utils/Message';

// import { getActiveUsers } from 'app/api/feed';
const useCoins = () => {
  const history = useHistory();
  const location = useLocation();
  const [values, setValues] = useState('');
  const [data, setData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(location?.state?.goto || 1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [pageLoading, setPageLoading] = useState(false);

  const [filters, setFilters] = useState({});

  useEffect(() => {
    let filterKeys = Object.keys(filters);
    if (filterKeys.length === 0) {
      getContentDataHandler(false);
    } else {
      getContentDataHandler(true);
    }
  }, [filters]);
  const [conversionData, setconversionData] = useState({
    coins: '',
    rupees: '',
  });
  useEffect(() => {
    getConversionData();
  }, []);

  const getConversionData = async () => {
    setPageLoading(true);
    let response = await getConversion();
    setPageLoading(false);
    if (response?.response?.code === 200) {
      setconversionData(response?.data);
    } else {
      Toast.error(
        response?.data ? response?.data : Message?.Error?.COMMON_MESSAGE
      );
    }
  };

  const updateFilters = (values) => {
    let temporaryFilter = {};
    let filterArray = Object.keys(values);
    for (let index in filterArray) {
      if (
        values[filterArray[index]] !== '' ||
        values[filterArray[index]].length !== 0
      ) {
        temporaryFilter[filterArray[index]] = values[filterArray[index]];
      }
    }
    setFilters(temporaryFilter);

    setShowFilter(false);
    setCurrentPage(1);
  };
  const resetFilter = (values) => {
    setFilters({});
    setShowFilter(false);
    setValues('');
  };

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  let currentData = data.slice(indexOfFirstData, indexOfLastData);

  async function getContentDataHandler(filter) {
    let dataArr;
    setPageLoading(true);
    dataArr = await getCoins();
    setPageLoading(false);
    let updatedData = [];
    dataArr.forEach((item, index) => {
      updatedData.push({
        id: item.id,
        coins_id: index + 1,
        product_family_name: item.product_family_name,
        discount: item.discount,
        higher_limit: item.orders_above,
        maximum_discount_amount: item.maximum_discount_amount,
        published_date: item.created_on
          ? ParseTableDateTime(item.created_on)
          : null,
        updated_on_date: item.updated_on
          ? ParseTableDateTime(item.updated_on)
          : null,
        status: item.status,
      });
    });

    setData(updatedData);
    let count = Math.ceil(dataArr.length / dataPerPage);
    setPageCount(count);
  }

  function paginationHandler(page) {
    setCurrentPage(page);
  }

  function changeHandler(event) {
    const pageLength = event.target.value;
    setDataPerPage(pageLength);
    let count = Math.ceil(data.length / pageLength);
    setPageCount(count);
  }

  function inputChangeHandler(searchVal) {
    if (searchVal.trim() === '') {
      setValues(searchVal.toLowerCase());
      getContentDataHandler();
      return;
    }
    const filteredData = [];
    data.forEach((item) => {
      if (
        item?.product_family_name
          .toLowerCase()
          .includes(searchVal.toLowerCase())
      ) {
        filteredData.push(item);
      }
    });
    setData(filteredData);
    setValues(searchVal);
  }

  let pageMeta = {
    total: data,
    pageSize: Number(dataPerPage),
    page: currentPage,
    pageCount: pageCount,
  };

  // ----------------------------- Conversion APIS ----------------------------
  const updateConversionData = async () => {
    let data = {
      coins: conversionData.coins,
      rupees: conversionData.rupees,
    };
    setPageLoading(true);
    let response = await updateConversion(data);
    setPageLoading(false);
    if (response?.response?.code === 200 || response?.response?.code === 201) {
      setconversionData(response?.data);
      Toast.success(Message?.Coins?.CONVERSION_UPDATE_SUCCESS);
    } else {
      Toast.error(
        response.data ? response.data : Message?.Error?.COMMON_MESSAGE
      );
    }
    getConversionData();
  };
  const handleChange = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      setconversionData({
        ...conversionData,
        [name]: parseInt(value),
      });
    }
  };

  // ------------------------------------------------

  return {
    filters,
    showFilter,
    setShowFilter,
    updateFilters,
    resetFilter,
    values,
    inputChangeHandler,
    changeHandler,
    pageLoading,
    currentData,
    data,
    getContentDataHandler,
    currentPage,
    dataPerPage,
    pageMeta,
    pageCount,
    paginationHandler,
    history,
    conversionData,
    handleChange,
    updateConversionData,
  };
};

export default useCoins;
