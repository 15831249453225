import RecommendCoursesContainer from "app/container/RecommendCoursesContainer";
import BasePage from "app/pages/BasePage";

const RecommendCourses = () => {
    return (
        <BasePage>
            <RecommendCoursesContainer  />
        </BasePage>
    );
}

export default RecommendCourses;


 