import AuditLogsPage from "app/container/AuditLogs";
import BasePage from "app/pages/BasePage";

const AuditLogs = () => {
    return (
        <BasePage>
            <AuditLogsPage />
        </BasePage>
    );
}

export default AuditLogs;