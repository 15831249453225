import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SideMenuData from 'app/json-data/SideMenuData';
import './index.scss';
import { handleDecodeValues } from 'app/utils/Parsers';

const Sidebar = () => {
  const location = useLocation();
  const [data, setData] = useState(SideMenuData);
  const activeItemRef = useRef(null);
  const intialValue = handleDecodeValues();
  useEffect(() => {
    // Scroll to the active item when the location changes
    if (activeItemRef.current) {
      activeItemRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location.pathname]);

  const handleSubMenuClick = (itemId) => {
    const updatedData = data.map((item) => {
      if (item.id === itemId) {
        item.active = !item.active;
      } else {
        item.active = false;
      }
      return item;
    });

    setData(updatedData);
  };
  return (
    <nav className='sidebar'>
      <ul className='sidebar-list list-unstyled m-0'>
        {data.map((item, index) => {
          return (
            intialValue?.includes(item.key) && (
              <>
                <li
                  ref={(ref) => {
                    if (location.pathname === item.to) {
                      activeItemRef.current = ref;
                    }
                  }}
                  className={`sidebar-item ${
                    location.pathname === item.to ? 'active-item' : ''
                  }`}
                  key={index}
                >
                  <Link
                    to={item.to}
                    className={`sidebar-link  ${
                      location.pathname === item.to ? 'active' : ''
                    }`}
                    onClick={() => handleSubMenuClick(item.id)}
                  >
                    {item.title}
                    {item.subNav && (
                      <span className='sidebar-arrow'>
                        {item.active ? item.iconOpened : item.iconClosed}
                      </span>
                    )}
                  </Link>
                </li>
                {item.subNav && item.active && (
                  <ul className='sub-menu p-0 m-0'>
                    {item.subNav.map((subItem, subIndex) => (
                      <li
                        ref={(ref) => {
                          if (location.pathname === subItem.to) {
                            activeItemRef.current = ref;
                          }
                        }}
                        style={{
                          backgroundColor:
                            location.pathname === subItem.to ? '#ddd' : 'white',
                        }}
                        key={subIndex}
                      >
                        <Link
                          to={subItem.to}
                          className={`sub-menu-link ${
                            location.pathname === subItem.to ? 'active' : ''
                          }`}
                        >
                          {subItem.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </>
            )
          );
        })}
      </ul>
    </nav>
  );
};

export default Sidebar;
