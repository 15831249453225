// Ajax
import * as dataAccess from 'utils/ajax';
import Parsers from 'utils/Parsers';


// Constants
import { API_CONSTANTS } from 'constants/api-contants';
 
export const searchMedicine = async (keyword, type, postal_code) => { 
  let url = '';
  if (postal_code === '' || postal_code === undefined) {
    url = `${API_CONSTANTS.GET_MEDICINE_SEARCH}?query=${keyword}&type=${type}&autocomplete=1`;
  } else {
    url = `${API_CONSTANTS.GET_MEDICINE_SEARCH}?query=${keyword}&postal_code=${postal_code}&type=${type}&autocomplete=1`;
  }

  let responseObj = await dataAccess.get(url);

  return responseObj;
};

export const medicineDetail = async (keyword, id) => { 
  let url = `${API_CONSTANTS.MEDICINE_DETAILS}/${id}?search_query=${keyword}`; 

  let responseObj = await dataAccess.get(url);

  return responseObj;
};

export const getDeliveryAddress = async (incomingData) => {
    let url = `${API_CONSTANTS.GET_DELIVERY_ADDRESS_LIST}`;

    let payload = {
        data: incomingData,
      };
      const responseObj = await dataAccess.post( url,  payload  );

    return responseObj;

}

export const getPrescriptionList = async (incomingData) => {
  let url = `${API_CONSTANTS.GET_PRESCRIPTION_LIST}`;

  let payload = {
      data: incomingData,
    };
    const responseObj = await dataAccess.post( url,  payload  );

  return responseObj;

}

export const uploadPrescription = async (incomingData) => {
  let url = `${API_CONSTANTS.UOPLOAD_PRESCRIPTION}`;

  let formData = Parsers.ConvertObjectAsFormData(incomingData)

  let payload = {
      data: formData,
    }; 
    const responseObj = await dataAccess.upload( url,  payload  );

  return responseObj;

}
export const prescriptionIsForAll = async (incomingData) => {
  let url = `${API_CONSTANTS.PRESCRIPTION_IS_FOR_ALL}`;

  let formData = Parsers.ConvertObjectAsFormData(incomingData)

  let payload = {
      data: formData,
    }; 
    const responseObj = await dataAccess.upload( url,  payload  );

  return responseObj;

}
export const deletePrescription = async (incomingData) => {
  let url = `${API_CONSTANTS.DELETE_PRESCRIPTION}`;
  let formData = Parsers.ConvertObjectAsFormData(incomingData)
  let payload = {
      data: formData,
    }; 
    const responseObj = await dataAccess.deletemethod( url,  payload  );

  return responseObj;

}

export const setAddress = async (incomingData) => {
  let url = `${API_CONSTANTS.SET_ADDRESS}`;

  let payload = {
      data: incomingData,
    };
    const responseObj = await dataAccess.post( url,  payload  ); 

  return responseObj;

}

export const addNewAddress = async (incomingData) => {
  let url = `${API_CONSTANTS.ADD_NEW_ADDRESS}`;

  let payload = {
      data: incomingData,
    };
    const responseObj = await dataAccess.post( url,  payload  );
 
  return responseObj;

}

export const medicineCheckOut = async (incomingData) => {
  let url = `${API_CONSTANTS.MEDICINE_CHECK_OUT}`;

  let payload = {
      data: incomingData,
    };
    const responseObj = await dataAccess.post( url,  payload  );
 
  return responseObj;

}
