import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

// Components
import { getVersionControl } from 'app/api/versionList';
import HeaderWithBackButton from 'components/HeaderWithBackButton';
import NewInput from 'components/NewInput';
import PageLoader from 'components/PageLoader';
import validate from 'components/ValidateInfo';
import Button from 'components/button';
import useContentForm from 'components/useContentForm';
import Dropdown from 'shared/components/Dropdown';
import RadioButton from 'shared/components/RadioButton';
// API
// import { updateActivity } from 'api/activity';

import { addOrUpdateHomePageOrder } from 'api/homepageorder';
// Constants

// Utils
import Message from 'utils/Message';
import Toast from 'utils/Toast';
import './index.scss';

const CreateHomePage = ({ onChange }) => {
  const history = useHistory();
  const location = useLocation();

  const [pageLoading, setPageLoading] = useState(false);
  let [versionLists, setVersionLists] = useState([]);
  const [appVersion, setappVersion] = useState('Android');

  const { handleChange, values, setValues } = useContentForm(validate);
  const VersionRange = [
    {
      value: 'GREATER_THAN',
      label: 'Greater Than',
    },
    {
      value: 'LESS_THAN',
      label: 'Lesser Than',
    },
    {
      value: 'EQUALS',
      label: 'Equals',
    },
  ];
  console.log('location.state', location.state);

  useEffect(() => {
    let data = location.state;
    // console.log(data, 'dataertyui');
    // debugger;
    if (data && data.id) {
      setValues(() => ({
        homepageId: data.id,
        title: data.title,
        title_visibility: data.title_visibility,
        section_visibility: data.section_visibility,
        criteria: data.criteria,
        android_version: data.android_version,
      }));
    }
  }, []);

  useEffect(() => {
    async function fetchBanner() {
      let response = await getVersionControl(appVersion);

      const data = response.data.available_versions;
      console.log(data, 'datadatadata');

      let newState = data.map((key) => {
        return {
          value: [key][0].toString(),
          label: [key][0].toString(),
        };
      });

      console.log('newState', newState);

      setVersionLists(newState);
    }
    fetchBanner();
  }, []);

  const callUpdateAPI = async () => {
    let {
      homepageId,
      title,
      title_visibility,
      section_visibility,
      criteria,
      android_version,
    } = values;

    if (!criteria || !android_version || !title) {
      Toast.error('Please complete all fields');
      return;
    }

    let data = [
      {
        home_page_id: homepageId,
        title: title,
        title_visibility: title_visibility,
        section_visibility: section_visibility,
        criteria,
        android_version,
      },
    ];

    setPageLoading(true);
    let page = '';

    if (location.state.section_title === 'Medicine Delivery Order') {
      page = 'MEDICINE';
    }
    if (location.state.section_title === 'Diagonostic-Order') {
      page = 'DIAGNOSTIC';
    }
    if (location.state.section_title === 'Homepage Order') {
      page = 'HOME';
    }

    const responseObj = await addOrUpdateHomePageOrder(data, page);
    setPageLoading(false);
    if (
      responseObj &&
      responseObj.response &&
      responseObj.response.code === 200
    ) {
      Toast.success(Message.Activities.UPDATE_SUCCESS);
      history.goBack();
    } else {
      Toast.error(Message.Activities.UPDATE_FAILURE);
    }
  };

  const handleRadioButtonClick = (e) => {
    setValues((prevstate) => {
      return {
        ...prevstate,
        title_visibility: e.target.value === 'false' ? false : true,
      };
    });
  };

  const handleSectionClick = (e) => {
    setValues((prevstate) => {
      return {
        ...prevstate,
        section_visibility: e.target.value === 'false' ? false : true,
      };
    });
  };

  const handleCreateHomePage = (e) => {
    callUpdateAPI();
  };

  return (
    <div className='blogsForm'>
      {/* {console.log('valuesdsfsdfs', values)} */}
      <div className='blogsForm_content'>
        <HeaderWithBackButton
          title={
            values.activityName
              ? values.activityName
              : `${location.state?.section_title}`
          }
          canGoBack={true}
          // goTo={APP_CONSTANTS.HOMEPAGEORDER}
        />

        <NewInput
          label='Title'
          Tooltiptext=' title length is 60 characters. This includes spaces and special
          characters.'
          id='title'
          type='text'
          placeholder='Ex: Breathfree Educator'
          name='title'
          value={values.title}
          onChange={handleChange}
          //   errorText={error.title}
        />

        <Dropdown
          id={values.id}
          label='Selected Version'
          placeholder='Selected Version'
          name='android_version'
          value={values.android_version.toString()}
          options={versionLists}
          handleChange={handleChange}
        />

        <Dropdown
          id={values.id}
          label='Version Range'
          placeholder='Version Range'
          name='criteria'
          value={values.criteria}
          options={VersionRange}
          handleChange={handleChange}
        />

        <div className='homepage-title'>
          <div className='radio_container'>
            <h5 className='radio_container__title'>Title Visiblity</h5>
            <RadioButton
              id='title_visibility'
              label='Yes'
              isChecked={values.title_visibility}
              value={true}
              onSelect={handleRadioButtonClick}
              name='title_visibility'
            />

            <RadioButton
              id='title_visibility'
              label='No'
              isChecked={!values.title_visibility}
              value={false}
              onSelect={handleRadioButtonClick}
              name='title_visibility'
            />
          </div>
        </div>

        <div className='homepage-title'>
          <div className='radio_container'>
            <h5 className='radio_container__title'>Section Visiblity</h5>
            <RadioButton
              id='section_visibility'
              label='Yes'
              isChecked={values.section_visibility}
              value={true}
              onSelect={handleSectionClick}
              name='section_visibility'
            />

            <RadioButton
              id='section_visibility'
              label='No'
              isChecked={!values.section_visibility}
              value={false}
              onSelect={handleSectionClick}
              name='section_visibility'
            />
          </div>
        </div>

        <Button
          className='mt1'
          onClick={handleCreateHomePage}
          type='submit'
          name='Submit'
        />
      </div>
      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </div>
  );
};

export default CreateHomePage;
