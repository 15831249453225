import React from "react";
import Button from "app/components/button";
import Search from "app/components/search";
import SelectComp from "./SelectComp";
import "./index.scss";
import { APP_CONSTANTS } from "app/constants/app-constants";
import Pagination from "./pagination"; // Import your custom Pagination component
import ContentTable from "./react-table";
import useCommonCouponAndOffer from "./useCommonCouponAndOffer";
import PageLoader from "app/components/PageLoader";
import Select from "react-select";

const options = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
  { value: 40, label: 40 },
  { value: 50, label: 50 },
];

const CouponAndOffers = () => {
  const {
    values,
    inputChangeHandler,
    changeHandler,
    pageLoading,
    currentData,
    filteredData,
    getContentDataHandler,
    currentPage,
    dataPerPage,
    pageCount,
    paginationHandler,
    history,
    selectedProductTypes,
    handleProductTypeChange,
    productTypes,
  } = useCommonCouponAndOffer();

  return (
    <>
      <div className="cms-content__container">
        <h4>Coupons & Offers</h4>
        <div className="content-container__actionBox">
          <div className="content-container__filterSearch">
            <Search
              value={values}
              placeholder="Search"
              className={""}
              onChange={inputChangeHandler}
            />
          </div>
          <Button
            onClick={() =>
              history.push(`${APP_CONSTANTS.LATEST_CREATE_COUPON_OFFER}`)
            }
            className="content-container__filter-btn"
          >
            Create Coupon
          </Button>
        </div>
        <div className="d-flex gap-5">
          <div className="content-container__perPage">
            Show
            <SelectComp options={options} onChangeHandler={changeHandler} />
            entries
          </div>
          <div className="content-container__perPage">
            Filter by Product Type
            <Select
              isMulti
              options={productTypes}
              value={selectedProductTypes}
              onChange={handleProductTypeChange}
              placeholder="Select product types..."
            />
          </div>
        </div>
        <div className="content-container__table">
          <ContentTable
            content={currentData}
            totalData={filteredData}
            getContentDataHandler={getContentDataHandler}
            currentPage={currentPage}
            dataPerPage={dataPerPage}
          />
          <div className="content-container__pagination">
            <Pagination
              pages={pageCount}
              onChange={paginationHandler}
              pageNumber={currentPage}
            />
          </div>
        </div>
      </div>
      {pageLoading === true && <PageLoader pageLoading={pageLoading} />}
    </>
  );
};

export default CouponAndOffers;