import {
  addServices,
  getServiceOrder,
  servicePublishorUnpublish,
  updateServiceOrder,
  updateServices,
  deleteServices,
} from 'app/api/latest/Services/services';
import { URLType } from 'app/constants/app-constants';
import { validateUrl } from 'app/helpers/validation';
import ValidationMessage from 'app/utils/ValidationMessage';
import { useEffect, useState } from 'react';

import { MyContext } from 'app/context/MyContext';
import { useContext } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import Message from 'utils/Message';
import Toast from 'utils/Toast';
import { getLanguages } from 'app/api/latest/Localisation/MasterLanguages/masterLanguages';

const useContentServicesForm = () => {
  const { setlanguageFunc = {} } = useContext(MyContext);

  const [removedService, setremovedService] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(1);
  const [servicelistData, setServiceListData] = useState([]);
  const [servicesortedList, setServiceSortedList] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isPublishButtonDisabled, setisPublishButtonDisabled] = useState(true);
  const [isAddButtonDisabled, setisAddButtonDisabled] = useState(true);
  const [tablelist, setTablelist] = useState([]);

  const [activeTab, setActiveTab] = useState({
    id: '1',
    name: 'English',
    short_name: 'en',
    display_order: 1,
    status: 'ACTIVE',
    label: '+ English',
    toggle: true,
  });

  const getLanguagesList = async () => {
    try {
      setPageLoading(true);
      let response = await getLanguages();
      setPageLoading(false);
      if (response?.response?.code === 200) {
        let newtemp = response?.data?.map((item, index) => {
          return {
            ...item,
            toggle: item.status === 'ACTIVE' ? true : false,
          };
        });
        await setlanguageFunc(newtemp);
      } else {
        setPageLoading(false);
        Toast.error(
          response?.data ? response?.data : Message?.Error?.COMMON_MESSAGE
        );
      }
    } catch (error) {
      setPageLoading(false);
      //  handleUpdate(tempLanguages);
      console.log('catch response');
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    getLanguagesList();
  }, []);

  useEffect(() => {
    if (!activeTab?.short_name) return;
    loadServiceslist(activeTab?.short_name);

    if (activeTab?.short_name !== 'en') {
      return;
    }
    loadTablelist('en');
  }, [activeTab?.short_name]);

  const handleTabChange = (val) => {
    setActiveTab(val);
    // Perform any other actions on tab change
  };

  const loadTablelist = async (short_name) => {
    let response = await getServiceOrder(short_name);
    setTablelist(response?.tableData);
    // debugger;
    setLoading(false);
    if (response && response.tableData && response.error === false) {
    } else {
      if (response.message) {
        Toast.error(response.message);
      } else {
        Toast.error(Message.Error.COMMON_MESSAGE);
      }
    }
  };

  const loadServiceslist = async (short_name) => {
    setLoading(true);
    let response = await getServiceOrder(short_name);
    let temp = [];
    if (response && response?.tableData?.length > 0) {
      temp = response?.tableData.map((item, index) => {
        let url = '';
        let externalUrl = '';
        if (
          item?.cta_type &&
          (item?.cta_type.trim() === URLType.EXTERNAL ||
            item?.cta_type.trim() === URLType.EXTERNAL_PWA)
        ) {
          externalUrl = item?.deep_link_url ? item?.deep_link_url : '';
        }
        if (item?.cta_type && item?.cta_type.trim() === URLType.INTERNAL) {
          url = item?.deep_link_url ? item?.deep_link_url : '';
        } 
        return {
          serviceId: item?.id,
          id: `service_${index}`,
          title: item?.service_name,
          displayOrder: item?.display_order ? item?.display_order : null,
          attachment: item?.image_url,
          urlType: item?.cta_type ? item?.cta_type.trim() : '',
          url: url,
          urlCopy: url,
          redirection_id: item?.redirection_id ,
          redirection_idURL: item?.redirection_id ,
          externalUrl: externalUrl,
          is_active: item?.is_active,
          status: item?.status,
          updated_by: item?.updated_by,
          updated_on: item?.updated_on,
          isNew: false,
          wasEdited: false,
          profile_data: item?.profile_data ? item.profile_data : false,
          internal_for: item?.internal_for ? item?.internal_for : '',
          internal_url: item?.internal_url ? item?.internal_url : '',
          custom_object: item?.custom_object ? item?.custom_object : '',
          detail_cta_text: item?.detail_cta_text ? item?.detail_cta_text : '',
          android_version: item?.android_version ? item.android_version : '',
          criteria: item?.criteria ? item.criteria : '',
          ios_version: item?.ios_version ? item.ios_version : '',
          ios_criteria: item?.ios_criteria ? item.ios_criteria : '', 
        };
      });
    }

    let newtemp = temp.sort((a, b) => a.displayOrder - b.displayOrder);

    setServiceListData(newtemp);
    if (newtemp.length >= 10) {
      setisAddButtonDisabled(true);
    } else {
      setisAddButtonDisabled(false);
    }
    setCount(newtemp.length);

    setLoading(false);
    if (response && response.tableData && response.error === false) {
    } else {
      if (response.message) {
        Toast.error(response.message);
      } else {
        Toast.error(Message.Error.COMMON_MESSAGE);
      }
    }
  };

  const addServiceSection = () => {
    setisPublishButtonDisabled(false);

    const temp = [...servicelistData];

    temp.push({
      id: Date.now(), // Basically its a index
      serviceId: null,
      isNew: true,
      title: '',
      urlType: '',
      url: '',
      externalUrl: '',
      attachment: '',
      attachmentFile: null,
      displayOrder: '',
      redirection_id: null,
      redirection_idURL: null,
      criteria: '',
      is_active: '',
      updated_by: '',
      updated_on: '',
      status: 'INACTIVE',
      wasEdited: false,
      profile_data: false,
      internal_for: '',
      internal_url: '',
      custom_object: '',
      detail_cta_text: '',
      ios_version: '',
      ios_criteria: '',
    });
    setCount(count + 1);

    setServiceListData(temp);

    if (count + 1 >= 10) {
      setisAddButtonDisabled(true);
    }
  };

  const removeServiceSection = async (ind) => {
    const temp = [];
    let removedServiceTemp = [];
    servicelistData.forEach((item, index) => {
      if (ind !== index) {
        temp.push(item);
      }
      if (ind === index && item.serviceId) {
        removedServiceTemp.push(parseInt(item.serviceId));
      }
    });
    if(servicelistData&& servicelistData.length<= 10){
    setisAddButtonDisabled(false);
    }
    if (removedServiceTemp.length > 0) {
      setPageLoading(true);
      let text = `Are you sure, want to delete this services?`;
      if (window.confirm(text) == true) {
        await deleteServices(removedServiceTemp[0]);
        Toast.success('Service deleted Successfully') 
        setPageLoading(false);
      } else {
        setPageLoading(false);
      }
    }

    setCount(temp.length);
    setServiceListData(temp);
    setremovedService(removedServiceTemp);
  };

  const OnhandleChange = (e) => {
    const { name, value, id } = e.target;
    if (value) setisPublishButtonDisabled(false);
    const valueCpy = [...servicelistData];

    valueCpy.forEach((element, index) => {
      if (`${element.id}` == `${id}`) {
        if (element.serviceId !== null) {
          element.wasEdited = true;
        }
        if (name === 'displayOrder') {
          element[name] = value && parseInt(value);
          return;
        }

        element[name] = value;
      }

      return element;
    });
    setServiceListData([...valueCpy]);
  };

  const handleSubmitting = (e) => {
    // e.preventDefault();
    // setErrors(validate(values));
  };

  const validateRequestpreviewScreen = async () => {
    let addimageCheck = false;
    let addheadingCheck = false;
    let adddisplayOrderCheck = false;
    let adddisplayOrderunique = false;
    let urlCheck = false;
    let adddisplayOrderMaxvalueCheck = false;

    servicelistData?.forEach((item, index) => {
      if (activeTab?.short_name === 'en') {
        if (item?.isNew) {
          if (item?.attachmentFile === null) {
            Toast.error(`${ValidationMessage.ATTACH} for Service ${index + 1}`);
            addimageCheck = true;
            return;
          }
        }

        if (item?.wasEdited && item?.attachmentFile === null) {
          Toast.error(`${ValidationMessage.ATTACH} for Service ${index + 1}`);
          addimageCheck = true;
          return;
        }
      }

      if (activeTab?.short_name === 'en') {
        if (item?.title === '') {
          Toast.error(
            `${ValidationMessage.TEXT_TITLE} for Service ${index + 1}`
          );
          addheadingCheck = true;
          return;
        }
        if (item?.displayOrder === '' || !item?.displayOrder) {
          Toast.error(
            `${ValidationMessage.DISPLAY_ORDER} for Service ${index + 1}`
          );
          adddisplayOrderCheck = true;
          return;
        }

        if (item?.displayOrder > servicelistData.length) {
          Toast.error(
            `Display Order Must be Under ${
              servicelistData.length
            } for Service ${index + 1}`
          );
          adddisplayOrderMaxvalueCheck = true;
          return;
        }
        if (item?.externalUrl !== '' && !validateUrl(item?.externalUrl)) {
          Toast.error(
            `${ValidationMessage.NOT_CORRECT_URL} for Banner Resources ${
              index + 1
            }`
          );
          urlCheck = true;
          return;
        }
      }
    });

    let makePreviewList = [...servicelistData];
    const unique = [
      ...new Set(servicelistData.map((item) => item.displayOrder)),
    ];
    let newtemp = makePreviewList.sort(
      (a, b) => a.displayOrder - b.displayOrder
    );

    let filteredData = [];

    newtemp?.forEach((item) => {
      if (item?.is_active === 'ACTIVE') {
        filteredData.push(item);
      }
    });

    setServiceSortedList(filteredData);

    // setServiceSortedList(newtemp);

    if (activeTab?.short_name === 'en') {
      if (unique && servicelistData && unique.length < servicelistData.length) {
        Toast.error('Display Order must be Unique');
        adddisplayOrderunique = true;
        return;
      }
    }

    if (
      addimageCheck ||
      addheadingCheck ||
      adddisplayOrderCheck ||
      adddisplayOrderunique ||
      adddisplayOrderMaxvalueCheck ||
      urlCheck
    ) {
      setPageLoading(false);
      return;
    }
    handleShow(true);
  };

  const callAddOrUpdateAPI = async () => {
    // debugger;
    /* Populate Data */

    // debugger;

    let addpayloadData = [];
    let editpayloadData = [];

    servicelistData.forEach((item, index) => {
      var consData = {};
      var newconsData = {};
 
      if (item.isNew) {
        if (item?.displayOrder) {
          consData.display_order = item.displayOrder;
        }
        if (item?.title) {
          consData.service_name = item.title;
        }
        if (item?.is_active) {
          consData.is_active = item.is_active;
        }

        if (item.serviceId) {
          consData.id = item.serviceId;
        }

        if (item?.attachmentFile) {
          consData.image_file = item.attachmentFile;
        }
        if ((item.url || item.externalUrl) && item.urlType) {
          consData.cta_type = item.urlType;
          if (
            item.urlType === URLType.EXTERNAL ||
            item.urlType === URLType.EXTERNAL_PWA
          ) {
            consData.deep_link_url = item.externalUrl;
          }
          if (item.urlType === URLType.INTERNAL) {
            consData.deep_link_url = item.url;
          }
        } else {
          consData.cta_type =
            item?.urlType !== '' ? item?.urlType : URLType?.NO;
        }
        if (item?.redirection_id) {
          consData.redirection_id = item.redirection_id;
        }
        if (item?.detail_cta_text) {
          consData.detail_cta_text = item.detail_cta_text;
        }
        if (item?.internal_for) {
          consData.internal_for = item.internal_for;
        }
        if (item?.internal_url) {
          consData.internal_url = item.internal_url;
        }
        if (item?.android_version) {
          consData.android_version = item.android_version;
        }
        if (item?.criteria) {
          consData.criteria = item.criteria;
        }
        if (item?.ios_version) {
          consData.ios_version = item.ios_version;
        }
        if (item?.ios_criteria) {
          consData.ios_criteria = item.ios_criteria;
        }
        consData.profile_data = item.profile_data;
        consData.custom_object = item.custom_object;

        addpayloadData.push(consData);
      }
      if (item.wasEdited && !item?.isNew) {
        if (activeTab.short_name !== 'en') {
          if (item?.attachmentFile) {
            newconsData.image_file = item.attachmentFile;
          }
          if (item.serviceId) {
            newconsData.id = item.serviceId;
          }
        } else {
          if (item?.displayOrder) {
            newconsData.display_order = item.displayOrder;
          }
          if (item?.title) {
            newconsData.service_name = item.title;
          }
          if (item?.is_active) {
            newconsData.is_active = item.is_active;
          }

          if (item.serviceId) {
            newconsData.id = item.serviceId;
          }

          if (item?.attachmentFile) {
            newconsData.image_file = item.attachmentFile;
          }
          if ((item.url || item.externalUrl) && item.urlType) {
            newconsData.cta_type = item.urlType;
            if (
              item.urlType === URLType.EXTERNAL ||
              item.urlType === URLType.EXTERNAL_PWA
            ) {
              newconsData.deep_link_url = item.externalUrl;
            }
            if (item.urlType === URLType.INTERNAL) {
              newconsData.deep_link_url = item.url;
            }
          } else {
            newconsData.cta_type =
              item?.urlType !== '' ? item?.urlType : URLType?.NO;
          }
        }
        if (item?.redirection_id) {
          newconsData.redirection_id = item.redirection_id;
        }
        if (item?.detail_cta_text) {
          newconsData.detail_cta_text = item.detail_cta_text;
        }
        if (item?.internal_for) {
          newconsData.internal_for = item.internal_for;
        }
        if (item?.internal_url) {
          newconsData.internal_url = item.internal_url;
        }
        if (item?.android_version) {
          newconsData.android_version = item.android_version;
        }
        if (item?.criteria) {
          newconsData.criteria = item.criteria;
        }
        if (item?.ios_version) {
          newconsData.ios_version = item.ios_version;
        }
        if (item?.ios_criteria) {
          newconsData.ios_criteria = item.ios_criteria;
        }

        newconsData.profile_data = item.profile_data;
        newconsData.custom_object = item.custom_object;
        editpayloadData.push(newconsData);
      }
    });
    try {
      const promises = [];
      if (addpayloadData) {
        promises.push(addServices(addpayloadData));
      }
      if (editpayloadData) {
        promises.push(updateServices(activeTab?.short_name, editpayloadData));
      }

      // Wait for all promises to resolve (API calls to complete)
      await Promise.all(promises);

      // All other APIs have completed successfully
      await loadServiceslist(activeTab?.short_name);
      setPageLoading(false);
    } catch (error) {
      // Handle errors here if any of the API calls fail
      console.error('API call failed:', error);
      setPageLoading(false);
    }
  };
  const handleCreateServices = (e) => {
    handleClose();
    callAddOrUpdateAPI();
  };
  const onFileSelected = (file, id) => {
    let newData = [...servicelistData];
    // if (file)

    setisPublishButtonDisabled(false);
    // if (newData && newData.length > 0 && file)
    if (newData && newData.length > 0) {
      newData = newData.map((item) => {
        if (item.id === id && id) {
          if (item.serviceId !== null) {
            item.wasEdited = true;
          }
          item.attachmentFile = file;
        }
        return item;
      });
      setServiceListData(newData);
    }
  };
  const ServicePageOrder = async (tempData) => {
    const ServiceData = tempData.map((data, index) => {
      return {
        service_id: data.original.id,
        display_order: index + 1,
      };
    });
    setPageLoading(true);
    await updateServiceOrder(ServiceData);
    // setRefresh(!refresh);
    loadServiceslist(activeTab?.short_name);
    loadTablelist('en');
    setPageLoading(false);
  };

  const callServiePublishOrUnpublishAPI = async (data, value) => {
    setPageLoading(true);

    if (data) {
      data.is_active = value;

      let responseObj = await servicePublishorUnpublish(data);
      setPageLoading(false);
      if (
        responseObj &&
        responseObj.response &&
        responseObj.response.code === 200
      ) {
        // loadNames();
        let message = Message.Services.PUBLISH_UNPUBLISH_SUCCESS.replace(
          '{{placeholder}}',
          data.status.toLowerCase() === 'active' ? 'Unpublished' : 'Published'
        );
        Toast.success(message);
        loadTablelist('en');
        loadServiceslist(activeTab?.short_name);
        // setRefresh(!refresh);
      }
    } else {
      let message = Message.Services.PUBLISH_UNPUBLISH_FAILURE.replace(
        '{{placeholder}}',
        data.status.toLowerCase() === 'active' ? 'Unpublish' : 'Publish'
      );
      Toast.error(message);
    }
  };

  const handleResourseToggle = (data, value) => {
    callServiePublishOrUnpublishAPI(data, value);
  };

  const openUp = (data, value) => {
    confirmAlert({
      title: '',
      message: `Are you sure you want to ${value ? 'Publish' : 'Unpublish'} ${
        data.service_name
      } Service`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            //  setToggled(!toggled);
            handleResourseToggle(data, value);
          },
        },
        {
          label: 'No',
        },
      ],
    });
  };
  return {
    isPublishButtonDisabled,
    loading,
    pageLoading,
    onFileSelected,
    handleSubmitting,
    isAddButtonDisabled,
    OnhandleChange,
    addServiceSection,
    removeServiceSection,
    count,
    servicelistData,
    setServiceListData,
    updateServiceOrder,
    handleCreateServices,
    ServicePageOrder,
    validateRequestpreviewScreen,
    handleClose,
    handleShow,
    show,
    callServiePublishOrUnpublishAPI,
    servicesortedList,
    tablelist,
    openUp,
    handleTabChange,
    activeTab,
  };
};

export default useContentServicesForm;
