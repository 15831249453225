import React from 'react';
import { CloseBlackIcon } from 'constants/image-constants';

const WebinarCard = ({ id, index, children, handleRemoveCard, text }) => {
  const onRemoveCard = () => {
    handleRemoveCard && handleRemoveCard(index, id);
  };

  return (
    <div key={id} className='Banner-container'>
      <div className='Banner-container__section-one'>
        <div className='card__header'>
          {/* <p className='Banner-container__section'>{`${text} ${index + 1}`}</p> */}
          <p className='Banner-container__section'>{`${text} ${index + 1}`}</p>
          <img src={CloseBlackIcon} alt='Close' onClick={onRemoveCard} />
        </div>
        <div className='Banner-container__Banner'>
          <div className='Banner-container__Image-Container'>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default WebinarCard;
