import React from 'react';

// // Pages
import BasePage from 'pages/BasePage';

import { BreatheFreeHeader } from 'constants/table-header-constants';
import BreatheFree from 'container/Breathefree';

const BreatheFreePage = () => {
  return (
    <BasePage>
      <BreatheFree tableHeader={BreatheFreeHeader} />
    </BasePage>
  );
};

export default BreatheFreePage;
