import React from 'react';
import BasePage from 'pages/BasePage';
import FamilyPlans from 'app/container/FamilyPlans';

import { FamilyPlansTableHeader } from 'constants/table-header-constants';

const FamilyPlansPage = () => {
  return (
    <>
      <BasePage>
        <FamilyPlans tableHeader={FamilyPlansTableHeader} />
      </BasePage>
    </>
  );
};

export default FamilyPlansPage;
