import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import Loader from 'components/Loader';
import NewTable from 'components/NewDraggableTable';

// API
// import { getActivities } from 'api/activity';

// import { getHomePage } from 'api/homepageorder';
import { getActivityVitals } from 'api/activityvitals';
import { updateActivityVitals } from 'api/activityvitals';
// // Utils
// import Toast from 'utils/Toast';
// import Message from 'utils/Message';

const ActivityVitalsPage = ({ tableHeader }) => {
  const history = useHistory();
  // const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  // const loadNames = (start, end, increase) => {
  //   setLoading(true);
  //   getActivities(start, end).then((response) => {
  //     setLoading(false);
  //     if (response && response.tableData && response.error === false) {
  //       setData({
  //         tableData: response.tableData,
  //       });
  //     }
  //   });
  // };

  const HomePageOrder = (tempData) => {
    const HomepageData = tempData.map((data, index) => {
      return {
        id: data.original.vitalId,
        key: data.original.key,
        sorting_order: index + 1,
      };
    });
    const res = updateActivityVitals(HomepageData);
  };

  const menuActionObj = [
    {
      name: 'Edit',
      onClick: (e, data) => {
        history.push({
          pathname: `/edit-activity-vitals/${data.id}`,
          state: {
            ...data,
          },
        });
      },
    },
  ];

  const sectionObj = {
    sectionTitle: 'Activity-Vitals',
    searchObj: {
      placeholder: 'Search',
      handleSearch: (e) => {
        console.log('Handle search: ', e);
      },
    },
  };

  if (loading === true) {
    return <Loader />;
  }

  return (
    <div className='blogs'>
      <NewTable
        sectionObj={sectionObj}
        tableHeader={tableHeader}
        menuAction={menuActionObj}
        data={data}
        HomePageOrder={HomePageOrder}
        getData={getActivityVitals}
        // searchKey='activity_name'
        hasPagination={false}
      />
    </div>
  );
};
export default ActivityVitalsPage;
