import React from 'react';

// // Pages
import BasePage from 'pages/BasePage';

import { UnderStandingHeader } from 'constants/table-header-constants';

import UnderStand from 'app/container/Understand';

const Understanding = () => {
  return (
    <BasePage>
      <UnderStand tableHeader={UnderStandingHeader} />
    </BasePage>
  );
};

export default Understanding;
