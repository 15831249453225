import "./index.scss";
import Search from "app/components/search";
import { useEffect, useState } from "react";
import Button from "app/components/button";
import CustomTable from "./table";
// import Pagination from "./pagination";
import SelectComp from "./select-tag";
import { useHistory, useLocation } from 'react-router-dom';
import ArticleModal from "app/components/ArticleModal";
import { getCmsContent, getCmsFilter } from "app/api/cms-content";
import ContentFilterModal from "app/components/ContentFilterModal";
import ContentTable from "./react-table";
import Pagination from '../../components/ReactPagination'

const options = [
    { value: 10, label: 10, },
    { value: 20, label: 20, },
    { value: 30, label: 30, },
    { value: 40, label: 40, },
    { value: 50, label: 50, },
]

const CmsContainer = () => {
    const history = useHistory();
    const location = useLocation()
    const [values, setValues] = useState("");
    const [data, setData] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [pageCount, setPageCount] = useState(1);
     const [searchVal, setSearchVal] = useState('');
     const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(location?.state?.goto || 1);
    const [dataPerPage, setDataPerPage] = useState(10);

    const [filters, setFilters] = useState({})

    useEffect(() => {
        let filterKeys = Object.keys(filters)
        if (filterKeys.length == 0) {
            getContentDataHandler(false);
        }
        else {
            getContentDataHandler(true);
        }

    }, [filters]);

    const updateFilters = (values) => {
        let temporaryFilter = {}
        let filterArray = Object.keys(values)
        for (let index in filterArray) {
            if (values[filterArray[index]] != "" || values[filterArray[index]].length != 0) {
                temporaryFilter[filterArray[index]] = values[filterArray[index]]
            }
        }
        setFilters(temporaryFilter)

        setShowFilter(false)
        setCurrentPage(1)
    }
    const resetFilter = (values) => {
        setFilters({})
        setShowFilter(false)
        setValues("")
    }

    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    let currentData = data.slice(indexOfFirstData, indexOfLastData)

    async function getContentDataHandler(filter) {
        let dataArr;
        if (filter) {
            dataArr = await getCmsFilter(filters)
        }
        else {
            dataArr = await getCmsContent()
        }
        setData(dataArr);
        let count = Math.ceil(dataArr.length / dataPerPage);
        setPageCount(count)

    }

    function inputChangeHandler(searchVal) {
        setFilters({ title: searchVal })
        setValues(searchVal)

    };

    function paginationHandler(page) {
        setCurrentPage(page);
    }

    function changeHandler(event) {
        const pageLength = event.target.value
        setDataPerPage(pageLength);
        let count = Math.ceil(data.length / pageLength);
        setPageCount(count)
    }

    async function searchHandler(search) {
        setSearchVal(search);
        if (search) {
            //  setIsSearchActive(true)
            //  setGlobalFilter(search);
        } else {
            //  setIsSearchActive(false)
        }
        return;
   }

   function inputChangeHandler(searchVal) {
    if (searchVal.trim() === "") {
        setValues(searchVal.toLowerCase());
        getContentDataHandler()
        return;
    }
    const filteredData = []
    data.forEach((item) => {
        if (item.title.toLowerCase().includes(searchVal.toLowerCase())) {
            filteredData.push(item);
        }
    });
    setData(filteredData);
    setValues(searchVal);
};

let pageMeta = {
    total : data,
    pageSize: Number(dataPerPage),
    page: currentPage,
    pageCount: pageCount
}

    return (<> {showFilter && (
        <ContentFilterModal
            modalCloseClicked={() => setShowFilter(false)}
            filters={filters}
            updateFilters={updateFilters}
            resetFilter={resetFilter}
        />
    )}
        <div className="cms-content__container">
            <h4>Content</h4>
            {/* <Search
               value={searchVal}
               placeholder="Search"
               onChange={searchHandler}
          /> */}
            <div className="content-container__actionBox">
                <div className="content-container__filterSearch">
                    <Search
                        value={values}
                        placeholder="Search"
                        className={""}
                        onChange={inputChangeHandler}
                    />
                    <Button
                        onClick={() => setShowFilter(true)}
                        className="content-container__filter-btn">Filter</Button>
                </div>
                <Button onClick={() => history.push('/cms/content/create')} className="content-container__filter-btn">Create Content</Button>
            </div>
            <div className="content-container__perPage">
                Show
                <SelectComp
                    options={options}
                    onChangeHandler={changeHandler}
                />
                entries
            </div>

            <div className="content-container__table">
                <ContentTable
                    content={currentData}
                    totalData={data}
                    getContentDataHandler={getContentDataHandler}
                    currentPage={currentPage}
                    dataPerPage={dataPerPage}
                >
                    <div className="content-container__pagination">
                        <Pagination
                             pageMeta={pageMeta}
                             initialPage={Number(pageCount)}
                             handleChange={paginationHandler}
                        />
                    </div>
                </ContentTable>
            </div>
        </div>
    </>

    );
}

export default CmsContainer