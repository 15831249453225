// Pages
import CouponAndOffers from 'container/CouponAndOffers';
import BasePage from 'pages/BasePage';

const CouponAndOfferPage = () => {
  return (
    <BasePage>
      <CouponAndOffers />
    </BasePage>
  );
};

export default CouponAndOfferPage;
