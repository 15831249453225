import React from 'react';

// Pages
import BasePage from 'app/pages/BasePage';
import CreateAdvertisementContainer from 'app/container/Exercises/CretateAdvertisement';
const CreateAdvertisement = () => {
  return (
    <BasePage>
        <CreateAdvertisementContainer /> 
    </BasePage>
  );
};

export default CreateAdvertisement;
