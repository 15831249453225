import Button from "app/components/button";
import RadioButton from "app/shared/components/RadioButton";
import { useEffect, useState } from "react";
import './index.scss';
import SelectComp from "../../select-tag";
import Toast from "app/utils/Toast";
import NewInput from "app/components/NewInput";

const CreateQuizTable = ({ onAddQuizDetails }) => {

     const [noOfQuestions] = useState([1, 2, 3, 4, 5]);
     const [quizArray, setQuizArray] = useState([]);

     useEffect(() => {
          onAddQuizDetails(quizArray)
     }, [quizArray])


     return (<>
          <table>
               <tr>
                    <th>Question No.</th>
                    <th>Question Type</th>
                    <th>Question Text</th>
                    <th>Answers (Choose right answer)</th>
                    <th>Explanation</th>
                    <th>Coin Awareded</th>
                    <th>Action</th>
               </tr>
               {noOfQuestions.map((ques, key) =>
               (<QuestionField
                    key={key}
                    id={key}
                    quesNumber={ques}
                    quizArray={quizArray}
                    onAddAnswers={(answer) => {
                         setQuizArray([...quizArray, answer]);
                    }}
               />)
               )}

          </table>
     </>)
};


const QuestionField = ({ quesNumber, id, onAddAnswers, quizArray }) => {
     const options = [
          { value: 1, label: 'True & False', },
          { value: 2, label: 'Single Select', },
     ]

     const trueOrFalse = [
          {
               label: "True",
               id: 1,
               value: '1',
               boolVal: true
          },
          {
               label: "False",
               id: 2,
               value: '2',
               boolVal: false
          }
     ]

     const [inputValues, setInputValues] = useState({
          question_id: quesNumber,
          question: "",
          coins_awarded: "",
          question_type: "",
          options: [],
     })
     const [explanation, setExplantion] = useState('')
     const [selectedItem, setSelectedItem] = useState('0');
     const [quesId, setQuesId] = useState(1);
     const [selectedQues, setSelectedQues] = useState(null)
     const [addedQues, setAddedQeus] = useState([]);
     const [singleQues, setSingleQues] = useState({
          label: '',
          value: '',
          id: ''
     });
     const [isLoading, setIsLoading] = useState(false);
     const [isDisabled, setIsDisabled] = useState(false);

     const radioButtonSelected = (item) => {
          if(item.value){
               setSelectedQues(item.value);
          }
         
     }

     function selectQuesTypeHandler(event) {
          setSelectedItem(event.target.value)
     }

     function inputChangeHandler(event) {
          const { name, value } = event.target;
          setInputValues({ ...inputValues, [name]: value });
     }

     function addQuesInputChangeHandler(event) {
          const { value } = event.target;
          setSingleQues({ ...singleQues, label: value, id: quesId, value: quesId });
     }

     async function addQuestionsHandler() {
          if (singleQues.label === '') {
               Toast.error('Please add question!')
               return;
          }
          setIsLoading(true)
          setAddedQeus([...addedQues, singleQues]);
          setSingleQues({
               label: '',
               value: '',
               id: ''
          });
          setQuesId(prev => prev + 1);
          setIsLoading(false);
     }

     // add quiz
     function transfromObject(obj, ans) {
          for (let key in obj) {
               if (obj[key].id == ans) {
                    obj[key].cms_answer = true;
                    obj[key].explanation = false;
               } else {
                    obj[key].explanation = true;
               }
               obj[key].title = obj[key].label;
               delete obj[key]["label"];
               delete obj[key]["value"];
               delete obj[key]["boolVal"];
          }
          return obj;
     }
     function addExplanationInObj(obj) {
          let title = ''
          for (let key in obj) {
               if (!obj[key].explanation) {
                    obj[key].explanation = false;
                    title = obj[key].title
               }
          }
          for (let key in obj) {
               if (!obj[key].explanation) {
                    obj[key].explanation = explanation;
               } else {
                    obj[key].explanation = `${title} is the right answer. ${explanation}`;
               }
          }
          return obj;
     }
     function submitQuizHandler() {

          if (selectedItem == '1') {
               if(selectedQues){
                    let newObj = transfromObject(trueOrFalse, selectedQues);
                    newObj = addExplanationInObj(newObj)
                    inputValues.question_type = "boolean";
                    inputValues.options = newObj;
               }else{
                    Toast.error('Select any one answer')
                    return
               }
               
          }
          if (selectedItem == '2') {
               if(selectedQues){
                    let newObj = transfromObject(addedQues, selectedQues);
                    newObj = addExplanationInObj(newObj)
                    inputValues.question_type = "mcq";
                    inputValues.options = newObj; 
               }else{
                    Toast.error('Select any one answer')
                    return
               }
          }
 
          if (
               inputValues.coins_awarded.trim() == "" ||
               !inputValues.question ||
               inputValues.question_id === "" ||
               !inputValues.question_type ||
               inputValues.options.length <= 0 
          ) {
               Toast.error('All fields are required!')
               return
          }
          
          inputValues.coins_awarded = +inputValues.coins_awarded;
          onAddAnswers(inputValues)
          setIsDisabled(true)
     }

     const handleDeleteQues = (item, i,isDisabled) =>{
          if(!isDisabled){
               let tempData = [...addedQues]; 
               tempData.splice(i, 1); 
               tempData =   tempData.map((item,i) => {
                    return { ...item , id: i+1}
               }) 
               setAddedQeus(tempData) 
          }
         
     } 
     return (<tr key={id}>
          <td>{quesNumber}</td>
          <td>
               <SelectComp
                    disabled={isDisabled}
                    options={options}
                    selected={true}
                    selectedTitle={'Select Type'}
                    name={'quesType'}
                    onChangeHandler={selectQuesTypeHandler}
               />
          </td>
          <td>
               <textarea
                    required
                    disabled={isDisabled}
                    placeholder={"Question"}
                    onChange={inputChangeHandler}
                    name={'question'} />
          </td>
          {selectedItem === '1' ?
               <td>
                    {trueOrFalse.map((item, key) => (
                         <RadioButton
                              disabled={isDisabled}
                              id={quesNumber}
                              label={item.label}
                              isChecked={selectedQues == item.value ? true : false}
                              value={item.value}
                              onSelect={() => { radioButtonSelected(item) }}
                              name={item.label}
                              
                         />
                    ))}
               </td> :
               selectedItem === '2' ? <td>

                    {addedQues.map((item, i) => (
                         <div className="row">
                         <div className="col-md-9 d-flex">
                              <RadioButton
                                   disabled={isDisabled}
                                   id={item.id}
                                   label={item.label?item.label:item.title?item.title:''}
                                   isChecked={selectedQues == item.value ? true : item.cms_answer?true:false}
                                   value={item.value}
                                   onSelect={() => { radioButtonSelected(item) }}
                                   name={item.label}
                              />
                         </div>
                          <div className="col-md-2 d-flex align-items-center  ">
                              <p className="m-0" onClick={() => handleDeleteQues(item, i, isDisabled)}> Delete</p>
                          </div>
                         </div>
                    ))}
                    {!isDisabled &&
                    <div className="quiz-add-btn-box">
                         <input
                              disabled={addedQues.length == 4 || isDisabled}
                              value={singleQues.label}
                              placeholder={"Add answers"}
                              onChange={addQuesInputChangeHandler}
                              name={'answers'}
                         />
                         <button disabled={addedQues.length == 4 || isDisabled} onClick={addQuestionsHandler}>{isLoading ? 'Adding...' : 'Add'}</button>
                    </div>
                    }
               </td>
                    : <td>Select Type</td>}
          <td>
               <textarea
                    required
                    disabled={isDisabled}
                    value={explanation}
                    placeholder={"Explanation"}
                    onChange={(e) => { setExplantion(e.target.value) }}
                    name={'explanation'} />
          </td>
          <td>
               <input
                    disabled={isDisabled}
                    placeholder='Point'
                    name={'coins_awarded'}
                    type={'number'}
                    onChange={inputChangeHandler}
               />

          </td>
          <td>
               <Button onClick={submitQuizHandler}>{isDisabled ? 'Added' : 'Add'}</Button>
          </td>     
     </tr>)
}

export default CreateQuizTable;