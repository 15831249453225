import React from 'react';

// Pages
import BasePage from 'pages/BasePage'; 
import CreateTipContainer from 'container/TipoftheDay/CreateTipContainer'
const CreateTip = () => {
  return (
    <BasePage>
      <CreateTipContainer />
    </BasePage>
  );
};

export default CreateTip;
