import React from 'react';

// // Pages
import BasePage from 'pages/BasePage';

import { DidYouKnowHeader } from 'constants/table-header-constants';

import DidYouKnow from 'app/container/DidYouKnow';

const DidYouKnowPage = () => {
  return (
    <BasePage>
      <DidYouKnow tableHeader={DidYouKnowHeader} />
    </BasePage>
  );
};

export default DidYouKnowPage;
