import React, { useState } from 'react';
import Modal from 'react-modal';

import MultiSelectDropdown from 'components/MultiSelectDropdown';
import Calender from 'components/Calender';
import NewInput from 'components/NewInput';
import Button from 'components/button';
import NumberInput from 'components/UI/NumberInput';
import Checkbox from 'components/checkbox';
import FeedTypeComponent from 'app/modules/feeds/FeedTypeComponent';
import Incorrect from 'images/incorrect.png';
import './index.scss';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const ArticleModal = ({
  modalCloseClicked,
  filterApplyClicked,
  ResetFilterCicked,
  handleChange,
  values,
  error,
}) => {
  const onChange = (e) => {
    handleChange &&
      handleChange({
        target: {
          name: 'published',
          value: e.target.checked,
        },
      });
  };

  const onQuizChange = (e) => {
    handleChange &&
      handleChange({
        target: {
          name: 'isQuiz',
          value: e.target.checked,
        },
      });
  };

  const [validated, setValidated] = useState(true);
  const [activate, setActivate] = useState(true);
  // const { handleChange, values, error, setValues } = useContentForm(validate);

  const handleApplyFilter = (e) => {
    // callFilterAPI();
    let data = populatePayload();
    if (!data) return;
    filterApplyClicked && filterApplyClicked(data);
  };
  const handleResetFilter = (e) => {
    ResetFilterCicked && ResetFilterCicked({});
  };

  const populatePayload = () => {
    let {
      email,
      mobile,
      homepage,
      homePageSection,
      tag,
      published,
      isQuiz,
      startDate,
      endDate,
    } = values;

    let data = {};

    if (email) {
      data.email = email;
    }

    if (mobile) {
      data.mobile = mobile;
    }

    if (published) {
      data.published = published ? false : true;
    }

    if (isQuiz) {
      data.is_quiz = isQuiz ? true : false;
    }

    if (tag && tag.length > 0) {
      data.tag = tag;
    }

    if (homepage === true && homePageSection) {
      data.homepage = 1;
      data.article_type = homePageSection;
    }

    if (startDate) {
      data.start_date = startDate;
    }

    if (endDate) {
      data.end_date = endDate;
    }

    return {
      filters: {
        ...data,
      },
    };
  };

  const onStartDateSelected = (date) => {
    handleChange &&
      handleChange({
        target: {
          name: 'startDate',
          value: date,
        },
      });
  };

  const onEndDateSelected = (date) => {
    handleChange &&
      handleChange({
        target: {
          name: 'endDate',
          value: date,
        },
      });
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={modalCloseClicked}
      style={customStyles}
      className='ArticleModal'
      overlayClassName='ArticleModal__Overlay'
    >
      <div className='Filters'>
        <div className='Filters_title'>Filter</div>
        <img
          onClick={modalCloseClicked}
          src={Incorrect}
          alt='Go to Blogs'
          width='29'
          className='Wrong-Icon'
          height='29'
        />
      </div>
      <div className='wrapped-component'>
        <NewInput
          label='Email'
          id='email'
          type='email'
          placeholder='Enter Email'
          name='email'
          value={values.email}
          onChange={handleChange}
          errorText={error.title}
        />
        <NumberInput
          label='Mobile no'
          id='mobile'
          placeholder='Mobile no'
          name='mobile'
          value={values.mobile}
          onChange={handleChange}
        />

        <Calender
          onStartDateSelected={onStartDateSelected}
          onEndDateSelected={onEndDateSelected}
          startDate={values.startDate}
          endDate={values.endDate}
          validated={validated}
          activate={activate}
          isFilter={true}
        />

        <MultiSelectDropdown
          label='Select Tag'
          name='tag'
          value={values.tag}
          onChange={handleChange}
        />

        <FeedTypeComponent
          value={values.homepage}
          homePageValue={values.homePageSection}
          handleChange={handleChange}
        />
        <Checkbox
          id='publish'
          label='Unpublished feeds'
          onChange={onChange}
          checked={values.published}
        />
        <Checkbox
          id='isQuiz'
          label='Show Feeds as Quiz'
          onChange={onQuizChange}
          checked={values.isQuiz}
        />
      </div>
      <div className='mt1'>
        <Button onClick={handleApplyFilter} type='submit' name='Apply filter' />
        <Button
          className='ml1'
          onClick={handleResetFilter}
          type='submit'
          name='Reset filter'
        />
      </div>
    </Modal>
  );
};

export default ArticleModal;
