import React from 'react';
import BasePage from 'pages/BasePage';
import ProductFamily from 'app/container/ProductFamily';

import { ProductFamilyTableHeader } from 'constants/table-header-constants';

const ProductFamilyPage = () => {
  return (
    <>
      <BasePage>
        <ProductFamily tableHeader={ProductFamilyTableHeader} />
      </BasePage>
    </>
  );
};

export default ProductFamilyPage;
