import React from 'react';

// Pages
import BasePage from 'pages/BasePage';

import { MedicineOrderHeader } from 'constants/table-header-constants';
import MedicineLandingPageOrdering from 'container/MedicineDelivery/MedicineLandingPageOrdering';

const MedicineLandingPageOrder = () => {
  return (
    <BasePage>
      <MedicineLandingPageOrdering tableHeader={MedicineOrderHeader} />
    </BasePage>
  );
};

export default MedicineLandingPageOrder;
