import React from 'react';

// Pages
import BasePage from 'pages/BasePage'; 
import UserJourneyList from 'container/UserJourney'
import CouponList from 'container/UserJourney/CouponList'

import { CouponHeader } from 'constants/table-header-constants';

const UserJourney = () => {
  return (
    <BasePage>
      <CouponList  tableHeader={CouponHeader}/>
    </BasePage>
  );
};

export default UserJourney;
