import RadioButton from 'app/shared/components/RadioButton';
import { useState } from 'react';
import './index.scss';
import SelectContentTable from './Tables.js/SelectContentTable';
import SelectGroupOfCollection from './Tables.js/SelectgroupOfCollection';
// import PreferencesTable from "./Tables.js/Preferences";

const CmsDiscoverHomeWidgetContainer = () => {
  const [selectedItem, setSelectedItem] = useState('1');

  const radioMenu = [
    {
      label: 'Home Widget Content',
      id: '1',
      value: '1',
    },
    {
      label: 'Home Widget Group of Collection',
      id: '2',
      value: '2',
    },
  ];
  const radioButtonSelected = (item) => {
    setSelectedItem(item.value);
  };
  return (
    <div className='discover__container'>
      <div className='content-selector'>
        <h2>Discover Home Widget</h2>
        {radioMenu.map((item, key) => (
          <RadioButton
            id={item.id}
            label={item.label}
            isChecked={selectedItem === item.value ? true : false}
            value={item.value}
            onSelect={() => {
              radioButtonSelected(item);
            }}
            name={item.label}
          />
        ))}
      </div>
      <div className='tables__discover'>
        {selectedItem === '1' && <SelectContentTable />}
        {selectedItem === '2' && <SelectGroupOfCollection />}
      </div>
    </div>
  );
};

export default CmsDiscoverHomeWidgetContainer;
