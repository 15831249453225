import React, { useEffect, useState } from 'react';
import DateTime from 'shared/components/DateTime';

// Components
import NewInput from 'components/NewInput';
import RadioButton from 'shared/components/RadioButton';
import QuizRadioButton from 'shared/components/QuizRadioButton';
import QuizCheckbox from 'shared/components/QuizCheckbox';
import Checkbox from 'components/checkbox';

// Constants
import { URLType } from 'constants/app-constants';

import { CloseBlackIcon, PlusBlackIcon } from 'constants/image-constants';

import './index.scss';

const QuestionCard = ({
  ind,
  index,
  questionObj,
  onHandleChange,
  handleRemoveCard,
  onHandleOptionRadioClick,
  onHandleOptionLabelChanged,
  onHandleAddOptionClick,
  onHandleRemoveOption,
  onHandleAddQuizSection,
  showAddQuestion,
}) => {
  const [type, setType] = useState('');

  useEffect(() => {
    if (questionObj.questionType) {
      setType(questionObj.questionType);
    }
  }, [questionObj.questionType]);

  const isRadioButtonSelected = (data) => {
    if (type === data) {
      return true;
    }
    return false;
  };

  const handleRadioButtonClick = (e) => {
    onHandleChange &&
      onHandleChange({
        target: {
          id: e.target.id,
          name: 'questionType',
          value: e.target.value,
        },
      });
  };

  const handleOptionRadioClick = (e, choice) => {
    onHandleOptionRadioClick &&
      onHandleOptionRadioClick({
        target: {
          id: e.target.id,
          name: 'isSelected',
          value: e.target.value,
          choice,
          qid: questionObj.id,
        },
      });
  };

  const handleIsRequiredChecked = (e) => {
    onHandleChange &&
      onHandleChange({
        target: {
          id: e.target.id,
          name: 'isRequired',
          value: e.target.checked,
        },
      });
  };

  const handleOptionLabelChanged = (e) => {
    onHandleOptionLabelChanged &&
      onHandleOptionLabelChanged({
        target: {
          id: e.target.id,
          name: 'title',
          value: e.target.value,
        },
      });
  };

  const handleAddOption = () => {
    onHandleAddOptionClick && onHandleAddOptionClick(questionObj.id);
  };
  /* handleRemoveOption */
  const handleRemoveOption = (e) => {
    //  debugger;
    onHandleRemoveOption && onHandleRemoveOption(e.target.id);
  };

  const onStartDateChange = (e) => {
    onHandleChange &&
      onHandleChange({
        target: {
          id: questionObj.id,
          name: 'dateTimeAnswer',
          value: e,
        },
      });
  };

  const onRemoveCard = () => {
    handleRemoveCard && handleRemoveCard(questionObj.id);
  };

  const renderQuizType = () => {
    return (
      <div className='qcard__type'>
        <div className='qcard__type__title'>Type</div>
        <RadioButton
          id={questionObj.id}
          label='Single Choice'
          isChecked={isRadioButtonSelected(URLType.MCQ)}
          value={URLType.MCQ}
          onSelect={handleRadioButtonClick}
          name={`radio_${questionObj.id}`}
        />
        <RadioButton
          id={questionObj.id}
          label='Multiple Choice'
          isChecked={isRadioButtonSelected(URLType.CHECKBOX)}
          value={URLType.CHECKBOX}
          onSelect={handleRadioButtonClick}
          name={`radio_${questionObj.id}`}
        />
        <RadioButton
          id={questionObj.id}
          label='Text'
          isChecked={isRadioButtonSelected(URLType.FILL)}
          value={URLType.FILL}
          onSelect={handleRadioButtonClick}
          name={`radio_${questionObj.id}`}
        />
        <RadioButton
          id={questionObj.id}
          label='Datetime'
          isChecked={isRadioButtonSelected(URLType.DATE_TIME)}
          value={URLType.DATE_TIME}
          onSelect={handleRadioButtonClick}
          name={`radio_${questionObj.id}`}
        />
      </div>
    );
  };

  const renderSingleChoice = () => {
    // if (questionObj.answerKey === true) {
    //   return (
    //     <div className='qcard_mcq'>
    //       {questionObj.options &&
    //         questionObj.options.length > 0 &&
    //         questionObj.options.map((option) => {
    //           return (
    //             <RadioButton
    //               id={option.id}
    //               label={option.title}
    //               isChecked={option.isSelected}
    //               value={option.title}
    //               onSelect={(e) => {
    //                 handleOptionRadioClick(e, 'single_choice');
    //               }}
    //               name={`radio_options${questionObj.id}`}
    //             />
    //           );
    //         })}
    //     </div>
    //   );
    // }

    return (
      <div className='qcard_mcq qcard_mcq_edit'>
        {questionObj.options &&
          questionObj.options.length > 0 &&
          questionObj.options.map((option, index) => {
            return (
              <QuizRadioButton
                showCloseMark={
                  0 === questionObj.options.length - 1 ? false : true
                }
                isLast={index === questionObj.options.length - 1 ? true : false}
                id={option.id}
                label={option.title}
                isChecked={option.isSelected}
                value={option.title}
                onSelect={(e) => {
                  handleOptionRadioClick(e, 'single_choice');
                }}
                handleOptionLabelChanged={handleOptionLabelChanged}
                name={`radio_options${questionObj.id}`}
                handleAddOption={handleAddOption}
                handleRemoveOption={handleRemoveOption}
              />
            );
          })}
      </div>
    );
  };

  const renderMultipleChoice = () => {
    // return (
    //   <div className='qcard_mcq'>
    //     {questionObj.options &&
    //       questionObj.options.length > 0 &&
    //       questionObj.options.map((option) => {
    //         return (
    //           <Checkbox
    //             id={option.id}
    //             label={option.title}
    //             onChange={(e) => {
    //               handleOptionRadioClick(e, 'multiple_choice');
    //             }}
    //             checked={option.isChecked}
    //           />
    //         );
    //       })}
    //   </div>
    // );

    return (
      <div className='qcard_mcq qcard_mcq_edit'>
        {questionObj.options &&
          questionObj.options.length > 0 &&
          questionObj.options.map((option, index) => {
            return (
              <QuizCheckbox
                showCloseMark={
                  0 === questionObj.options.length - 1 ? false : true
                }
                isLast={index === questionObj.options.length - 1 ? true : false}
                id={option.id}
                label={option.title}
                checked={option.isChecked}
                value={option.title}
                onChange={(e) => {
                  handleOptionRadioClick(e, 'multiple_choice');
                }}
                handleOptionLabelChanged={handleOptionLabelChanged}
                handleAddOption={handleAddOption}
                handleRemoveOption={handleRemoveOption}
              />
            );
          })}
      </div>
    );
  };

  const renderText = () => {
    return (
      <NewInput
        className='mt1'
        label='Answer'
        id={questionObj.id}
        type='text'
        placeholder='Enter answer'
        name='answer'
        value={questionObj.answer}
        onChange={onHandleChange}
      />
    );
  };

  const renderDatetime = () => {
    return (
      <div className='qcard__datetime'>
        <label>DateTime</label>
        <DateTime
          dateFormat='DD-MM-yyyy'
          onChange={onStartDateChange}
          value={questionObj.dateTimeAnswer}
        />
      </div>
    );
  };

  const renderDifferentChoices = () => {
    switch (type) {
      case URLType.FILL:
        return renderText();
      case URLType.DATE_TIME:
        return renderDatetime();
      case URLType.MCQ:
        return renderSingleChoice();
      case URLType.CHECKBOX:
        return renderMultipleChoice();
      default:
        break;
    }
  };

  return (
    <div className='qcard'>
      <div className='qcard__header'>
        <div className='qcard_sectitle'>{`Question ${ind + 1}`}</div>
        <img src={CloseBlackIcon} alt='Close' onClick={onRemoveCard} />
      </div>
      <div className='qcard_wrapper'>
        {renderQuizType()}
        <NewInput
          label='Question'
          id={questionObj.id}
          type='text'
          placeholder='Enter question'
          name='question'
          value={questionObj.question}
          onChange={onHandleChange}
          errorText={questionObj.errorQuestion}
        />
        {renderDifferentChoices()}
        <div className='qcard_footer'>
          <Checkbox
            id={questionObj.id}
            label='Is Required'
            onChange={handleIsRequiredChecked}
            checked={questionObj.isRequired}
          />
        </div>
      </div>
      {showAddQuestion && (
        <div className='qcard__add'>
          <img
            src={PlusBlackIcon}
            alt='Add Question'
            onClick={onHandleAddQuizSection}
          />
        </div>
      )}
    </div>
  );
};

export default QuestionCard;
