import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import NewInput from 'app/components/NewInput';
import Toast from 'app/utils/Toast';
import PageLoader from 'app/components/PageLoader';
import Button from 'app/components/button';
import MultiSelectDropdown from 'app/components/MultiSelectDropdown';
import { getWidgetList, getStaticWidgetList } from 'app/api/widgets';
import {
  createHomePageSection,
  updateHomePageSection,
  getLocalisationDetails,
  updateHomePageSectionLocalisation,
} from 'app/api/homepagesection';
import TabBar from 'app/components/NewDesign/TabBar';
import useTabBarOptions from 'app/utils/useTabbarOptions';
import CTAType from 'app/components/CTAType';
import DropdownOptions from 'app/utils/DropdownOptions';

const CreateHomePageContainer = () => {
  const location = useLocation();
  let history = useHistory();
  const params = useParams();
  const { LanguageOptions } = useTabBarOptions();
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState({
    widget_list: [],
    name: '',
    section_bg_color: '#FFFFFF',
    cta_type: null,
    url: '',
    internal_for: '',
    internal_url: '',
    profile_data: true,
    custom_object: '',
    redirection_id: '',
    detail_cta_text: '',
  });
  const [widgetLists, setWidgetLists] = useState([]);
  const [activeTab, setActiveTab] = useState({
    id: '1',
    name: 'English',
    short_name: 'en',
    display_order: 1,
    status: 'ACTIVE',
    value: 'English',
  });
  const [disableFields, setDisableFields] = useState(false);
  useEffect(() => {
    getContentDataHandler();
    if (params.id) {
      updateStateValue();
    }
  }, [activeTab?.name]);

  async function getContentDataHandler() {
    let dataArr;
    setLoader(true);
    dataArr = await getWidgetList();
    let widgetListOptions = [];
    dataArr.data.map((item) => {
      widgetListOptions.push({
        name: item.widget_name,
        id: item.widget_id,
        value: item.widget_id.toString(),
        tag_type: 'Dynamic Widget',
      });
    });

    let staticWidget = await getStaticWidgetList();
    staticWidget.data.map((item, i) => {
      widgetListOptions.push({
        name: item.widget_name,
        id: item.widget_id,
        value: item.widget_id.toString(),
        tag_type: 'Static Widget',
      });
    });

    setWidgetLists(widgetListOptions);

    if (dataArr && dataArr.response && dataArr.response.code === 200) {
      // Toast.success(dataArr.response.alert[0].message);
      setLoader(false);
    } else {
      setLoader(false);
      Toast.error(
        dataArr.data && dataArr.data.msg
          ? dataArr.data.msg
          : dataArr.response.alert[0].message
      );
    }
    setLoader(false);
  }

  const updateStateValue = async () => {
    const resp = await getLocalisationDetails(params.id, activeTab?.short_name);
    if (resp && resp.response && resp.response.code === 200) {
      let widgetListArray = [];
      let homePageDetails = resp.data;
      if (homePageDetails) {
        if (activeTab?.name === 'English') {
          homePageDetails.widget_mapped.map((item) => {
            widgetListArray.push(item.id.toString());
          });
          setState({
            name: homePageDetails.section_name,
            widget_list: widgetListArray,
            section_bg_color: homePageDetails.section_bg_color,
            cta_type: homePageDetails?.cta_type || null,
            url: homePageDetails?.url || '',
            internal_for: homePageDetails?.internal_for || '',
            internal_url: homePageDetails?.internal_url || '',
            profile_data: homePageDetails?.profile_data || true,
            custom_object: homePageDetails?.custom_object || '',
            redirection_id: homePageDetails?.redirection_id || '',
            detail_cta_text: homePageDetails?.detail_cta_text || '',
          });
        } else {
          setState((prev) => ({
            ...prev,
            name: homePageDetails.section_name
              ? homePageDetails.section_name
              : '',
            cta_type: homePageDetails?.cta_type || null,
            url: homePageDetails?.url || '',
            internal_for: homePageDetails?.internal_for || '',
            internal_url: homePageDetails?.internal_url || '',
            profile_data: homePageDetails?.profile_data || true,
            custom_object: homePageDetails?.custom_object || '',
            redirection_id: homePageDetails?.redirection_id || '',
            detail_cta_text: homePageDetails?.detail_cta_text || '',
          }));
        }
      }
    } else {
      Toast.error(
        resp.data && resp.data.msg
          ? resp.data.msg
          : resp.response.alert[0].message
      );
    }
  };

  const handleTextChange = (e) => {
    if (e.target.name === 'widget_list') {
      setState({ ...state, [e.target.name]: e.target.value });
    } else if (e.target.name === 'name') {
      if (e.target.value.length <= 34) {
        setState({ ...state, [e.target.name]: e.target.value });
      }
    } else {
      setState({ ...state, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async () => {
    if (state.name === '') {
      Toast.error('Please Enter Section Name');
      return;
    }
    if (state.widget_list.length === 0) {
      Toast.error('Please Select any one widget');
      return;
    }
    if (state.section_bg_color === '') {
      Toast.error('Please select Background color');
      return;
    }
    let payload = {
      ...state,
    };
    setLoader(true);
    let responseObj;
    if (params.id) {
      payload.id = params.id;
      if (activeTab?.name === 'English') {
        responseObj = await updateHomePageSection(payload);
      } else {
        let localisationPayload = {
          ...state,
          id: params.id,
        };
        responseObj = await updateHomePageSectionLocalisation(
          localisationPayload,
          activeTab?.short_name
        );
      }
    } else {
      responseObj = await createHomePageSection(payload);
    }

    if (
      responseObj &&
      responseObj.response &&
      responseObj.response.code === 200
    ) {
      Toast.success(responseObj.response.alert[0].message);
      history.push('/home-page-sections');
    } else {
      setLoader(false);
      Toast.error(
        responseObj.data && responseObj.data.msg
          ? responseObj.data.msg
          : responseObj.response.alert[0].message
      );
    }
  };
  const handleTabChange = (val) => {
    setActiveTab(val);
    if (val?.name === 'English') {
      setDisableFields(false);
    } else {
      setDisableFields(true);
    }
  };

  return (
    <div className='container'>
      {params.id ? (
        <h1 className='section-header__title'>
          Landing pages \ Home Page Sections \ Edit Section
        </h1>
      ) : (
        <h1 className='section-header__title'>
          Landing pages \ Home Page Sections \ Add Section
        </h1>
      )}
      <div className='cms-content__container'>
        {params.id ? (
          <div className='col-md-4 d-flex'>
            <b>Select Language</b>
          </div>
        ) : null}
        {params.id ? (
          <TabBar
            tabs={LanguageOptions}
            initialTab={activeTab}
            onTabChange={handleTabChange}
            parentStyle={'service-tab-style'}
          />
        ) : null}
        <div className='cms-content__container'>
          <div className='row'>
            <div className='col-md-6'>
              <div className='userInput'>
                <NewInput
                  label='Section Name/Title'
                  name={'name'}
                  value={state.name}
                  onChange={handleTextChange}
                  max={34}
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-4'>
              <MultiSelectDropdown
                label='Widgets'
                name='widget_list'
                value={state.widget_list}
                options={widgetLists}
                onChange={handleTextChange}
                tag_type={true}
                disable={disableFields}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-4'>

              <div className={`${disableFields ? 'bg-light' : ""} colorpicker`}>
                <label>Background Fill</label>
                <input
                  type='color'
                  name='section_bg_color'
                  value={state.section_bg_color ? state.section_bg_color : '#FFFFFF'}
                  onChange={handleTextChange}
                  disabled={disableFields}
                />
                <p>{state.section_bg_color}</p>
              </div>
            </div>
          </div>
          <div className='row'>
            <CTAType
              cta_type={state.cta_type}
              handleChange={(key, data) => {
                console.log(key, data)
                if (key === 'cta_type') {
                  if (!data) {
                    setState({ ...state, cta_type: data, url: '', detail_cta_text: '' });
                  } else {
                    setState({ ...state, cta_type: data });
                  }
                } else {
                  if (
                    key === 'url' &&
                    (data !== 'zoom_live_session' ||
                      data !== 'infinite_scroll' ||
                      data !== 'group_of_collection')
                  ) {
                    setState({ ...state, [key]: data, redirection_id: '' });
                  } else if (
                    key === 'url_secondary' &&
                    (data !== 'zoom_live_session' ||
                      data !== 'infinite_scroll' ||
                      data !== 'group_of_collection')
                  ) {
                    setState({
                      ...state,
                      [key]: data,
                      redirection_id_secondary: '',
                    });
                  } else {
                    setState({ ...state, [key]: data });
                  }
                }
              }}
              url={state.url}
              handleTextChange={handleTextChange}
              internal_for={state.internal_for}
              internal_url={state.internal_url}
              profile_data={state.profile_data}
              custom_object={state.custom_object}
              redirection_id={state.redirection_id}
              isDisabled={disableFields}
              ctaText={true}
              detail_cta_text={state.detail_cta_text}
              internalUrl={DropdownOptions.InternalUrl}
            />
          </div>
          <div className='row'>
            <div className='userFormArea mt-5'>
              <Button name={'Save'} onClick={handleSubmit} />
              <label
                style={{ paddingLeft: '10px', cursor: 'pointer' }}
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancel
              </label>
            </div>
          </div>
        </div>
      </div>
      {loader && <PageLoader pageLoading={loader} />}
    </div>
  );
};

export default CreateHomePageContainer;
