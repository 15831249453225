import React, { useState } from 'react';

// Container
import SectionHeader from 'container/SectionHeader';

// Components
import Table from 'components/DraggableTable';

const NewTable = ({
  tableHeader,
  menuAction,
  sectionObj,
  VitalsActivity,
  handleDragEnd,
  pagination,
  type,
  getData,
  HomePageOrder,
  setRowData,
  isHide,
  searchKey,
  hasPagination,
  filterData,
  responseCallback,
  selectedContent=[],
  disabledFirstAndLast=false
}) => {
  const [currentPage] = useState(0);
  const [pageLimit] = useState(4);
  const [searchVal, setSearchVal] = useState('');

  const onSearch = (val) => {
    setSearchVal(val);
  };

  return (
    <div>
      <SectionHeader
        sectionObj={sectionObj}
        searchVal={searchVal}
        isHide={isHide}
        handleSearch={onSearch}
      />

      <Table
        // handleDragEnd={handleDragEnd}
        tableHeader={tableHeader}
        currentPage={currentPage}
        pageLimit={pageLimit}
        menuAction={menuAction}
        pagination={pagination}
        type={type}
        HomePageOrder={HomePageOrder}
        VitalsActivity={VitalsActivity}
        setRowData={setRowData}
        getData={getData}
        searchVal={searchVal}
        searchKey={searchKey}
        hasPagination={hasPagination}
        filterData={filterData}
        responseCallback={responseCallback}
        selectedContent={selectedContent}
        disabledFirstAndLast={disabledFirstAndLast}
      />
    </div>
  );
};
export default NewTable;
