import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import moment from 'moment';
 
const DraggableTable = ({handleNavigation,handleDelete, sectionList, changeOrdering }) => { 
  const history = useHistory();
  const [items, setItems] = useState([]);

  useEffect(() => { 
    setItems(sectionList)
  }, [sectionList]);
 
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    setItems(newItems);
    changeOrdering(newItems)
  };
  
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId='droppable'>
        {(provided) => (
          <table
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{ borderCollapse: 'collapse', width: '100%' }}
          >
            <thead>
              <tr>
                <th style={{  padding: '8px' }}>
                  Drag
                </th>
                <th style={{ padding: '8px' }}>
                  Title
                </th>
                <th style={{ padding: '8px' }}>
                  Last Edited
                </th>
                <th style={{ padding: '8px' }}>
                  Last updated by
                </th>
                <th style={{ padding: '8px' }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody> 
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}  >
                  {(provided) => (
                    <tr
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...provided.draggableProps.style,
                        border: '1px solid black',
                        padding: '8px',
                      }}
                    >
                      <td>▶</td>
                      <td>{item.widget_name?item.widget_name: item.section_name}</td>
                      <td>{item.last_edited_time?moment(item.last_edited_time).format('DD-MM-YYYY'):''}</td>
                      <td>{item.last_updated_by}</td> 
                      <td>
                        <div
                          class='btn-group'
                          role='group'
                          aria-label='Basic outlined example'
                        >
                          <button
                            type='button'
                            className='btn btn-outline-primary'
                            onClick={() => handleNavigation(item, index)}
                          >
                            Edit
                          </button>

                          <button
                            type='button'
                            onClick={() => handleDelete(item.editid)}
                            className='btn btn-outline-primary ml-2'
                          >
                            Delete
                          </button>
                        </div>
                      </td>
                    </tr>
                  )}
                </Draggable>
              ))}
            </tbody>
            {provided.placeholder}
          </table>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableTable;
