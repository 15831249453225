import React from 'react';

// Pages
import BasePage from 'pages/BasePage';
import StreakRewards from 'container/UserJourney/StreakRewardList';

import { StreakRewardHeader } from 'constants/table-header-constants';

const UserJourney = () => {
  return (
    <BasePage>
      <StreakRewards tableHeader={StreakRewardHeader} />
    </BasePage>
  );
};

export default UserJourney;
