import * as dataAccess from 'utils/ajax';
import ApiParsers from 'utils/ApiParser';
import Parsers from 'utils/Parsers';
import { API_CONSTANTS } from 'constants/api-contants';
import { MasterDataType } from 'app/constants/app-constants';

export const getMasterData = async () => {
  let urls = [
    `${API_CONSTANTS.GET_MEDICINE_MASTER_DATA}`,
    `${API_CONSTANTS.GET_DOCTOR_MASTER_DATA}`,
  ];

  let responseObj = await dataAccess.getAll(urls);
  return ApiParsers.parseMasterData(responseObj);
};

export const uploadMasterData = async (incomingData) => {
  let data = {
    ...incomingData,
  };

  var formData = Parsers.ConvertObjectAsFormData(data);

  let payload = {
    data: formData,
  };

  let url = '';
  if (incomingData.type === MasterDataType.MEDICINE) {
    url = API_CONSTANTS.GET_MEDICINE_MASTER_DATA;
  } else if (incomingData.type === MasterDataType.DOCTOR) {
    url = API_CONSTANTS.GET_DOCTOR_MASTER_DATA;
  }

  if (!url) {
    console.log('Url Not exists for Upload master data');
    return;
  }

  let responseObj = await dataAccess.upload(url, payload);
  return ApiParsers.parseUploadMasterData(responseObj);
};
