import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';

// Components
import NewInput from 'components/NewInput';
import HtmlEditor from 'components/HtmlEditor';
import Button from 'components/button';
import useContentForm from 'components/useContentForm';
import validate from 'components/ValidateInfo';
import PageLoader from 'components/PageLoader';
import HeaderWithBackButton from 'components/HeaderWithBackButton';
import Dropdown from 'shared/components/Dropdown';

// API
import {
  addStatic,
  getStaticContentById,
  updateStatic,
} from 'api/static-content';

// Constants
import { APP_CONSTANTS } from 'constants/app-constants';

// Utils
import Toast from 'utils/Toast';
import Message from 'utils/Message';
import ValidationMessage from 'utils/ValidationMessage';
import DropdownOptions from 'utils/DropdownOptions';
import QuillTextEditor from 'app/shared/components/QuillTextEditor';

import TabBar from 'app/components/NewDesign/TabBar';
import useTabBarOptions from 'app/utils/useTabbarOptions';

const CreateStatic = () => {
  const history = useHistory();
  const location = useLocation();
  const editorRef = useRef(null);
  const [staticId, setStaticId] = useState();
  const [pageLoading, setPageLoading] = useState(false);
  const [htmlContent, setHTMLContent] = useState(false);

  const { handleChange, values, error, setValues } = useContentForm(validate);

  const { LanguageOptions } = useTabBarOptions();
  const [activeTab, setActiveTab] = useState({
    id: '1',
    name: 'English',
    short_name: 'en',
    display_order: 1,
    status: 'ACTIVE',
    label: '+ English',
    value: 'English',
  });
  const handleTabChange = (val) => {
    setActiveTab(val);
    // Perform any other actions on tab change
  };

  // useEffect(() => {
  //   let staticData = location.state;
  //   if (staticData && staticData.static_page_id) {
  //     setStaticId(staticData.static_page_id);
  //     setHTMLContent(staticData.content);
  //     setValues((prevState) => ({
  //       title: staticData.title,
  //       content: staticData.content,
  //       staticType: staticData.type,
  //     }));
  //   }
  // }, []);

  useEffect(() => {
    const static_page_id = location?.state?.static_page_id
      ? location?.state?.static_page_id
      : '';
    if (static_page_id) {
      setStaticId(static_page_id);
      loadStaticData(static_page_id);
    }
  }, [activeTab?.short_name]);

  const loadStaticData = async (id) => {
    if (!id) {
      return;
    }
    let lang = activeTab?.short_name ? activeTab?.short_name : '';
    if (!lang) return;

    // '41060749-282c-4178-9f48-d854dc3ba5f0'
    setPageLoading(true);
    let response = await getStaticContentById(id, lang);
    setPageLoading(false);
    if (response?.response?.code === 200) {
      let staticData = response.data;

      // let responsedata = response.data;
      setHTMLContent(staticData.content);
      setValues((prevState) => ({
        title: staticData.title,
        content: staticData.content,
        staticType: staticData.type,
      }));
    } else {
      let staticData = location.state;
      if (staticData && staticData.static_page_id) {
        // setStaticId(staticData.static_page_id);
        setHTMLContent(staticData.content);
        setValues((prevState) => ({
          title: staticData.title,
          content: staticData.content,
          staticType: staticData.type,
        }));
      }
      Toast.error(
        response?.data ? response?.data : Message.Error.COMMON_MESSAGE
      );
    }
  };

  const callAddOrUpdateAPI = async () => {
    let { title, staticType } = values;

    let data = {
      title: title ? title : '',
      static_page_id: staticId ? staticId : 0,
      update_page: staticId ? 1 : 0,
      type: staticType ? staticType : '',
    };

    let content;
    if (editorRef.current) {
      content = editorRef.current.value;
      data.content = content;
    }

    console.log('title', title, 'content', content, 'staticType', staticType);

    if (!title || !content || !staticType) {
      Toast.error(ValidationMessage.EMPTY_STATIC);
      return;
    }

    setPageLoading(true);
    // const responseObj = await addStatic(data);
    // setPageLoading(false);

    let responseObj = {};
    if (staticId) {
      let lang = activeTab?.short_name ? activeTab?.short_name : '';
      if (!lang) return;

      if (lang === 'en') {
        let temp = {
          ...data,
          publish: 1,
        };
        responseObj = await updateStatic(temp, staticId, lang);
      } else {
        let temp = {
          content: data.content,
          title: data.title,
          static_page_id: data.static_page_id,
        };
        responseObj = await updateStatic(temp, staticId, lang);
      }
    } else {
      responseObj = await addStatic(data);
    }
    setPageLoading(false);
    if (
      responseObj?.response?.code === 200 ||
      responseObj?.response?.code === 201
    ) {
      Toast.success(
        responseObj?.response?.code === 200
          ? Message.Static.UPDATED_SUCCESS
          : Message.Static.CREATED_SUCCESS
      );
      history.goBack();
    } else {
      Toast.error(
        responseObj?.data ? responseObj?.data : Message?.Error?.COMMON_MESSAGE
      );
    }
  };

  const handleCreateStatic = (e) => {
    callAddOrUpdateAPI();
  };

  return (
    <div className='blogsForm'>
      <div className='blogsForm_content'>
        <HeaderWithBackButton
          title='Add/edit Static Content'
          goTo={APP_CONSTANTS.STATIC_PAGE}
        />

        <Dropdown
          label='Type'
          placeholder='Select option'
          name='staticType'
          value={values.staticType}
          options={DropdownOptions.StaticContentType}
          handleChange={handleChange}
        />
        {staticId && (
          <TabBar
            tabs={LanguageOptions}
            initialTab={activeTab}
            onTabChange={handleTabChange}
            parentStyle={'service-tab-style'}
          />
        )}

        <NewInput
          label='Title'
          id='title'
          type='text'
          placeholder='Title'
          name='title'
          value={values.title ? values.title : ''}
          onChange={handleChange}
          errorText={error.title}
        />

        {/* <HtmlEditor
          label='RichText Editor'
          value={htmlContent}
          onChange={handleChange}
          editorRef={editorRef}
        /> */}
      <QuillTextEditor
          label='RichText Editor'
          Tooltiptext='DO NOT COPY AND PASTE FROM WORD DOCUMENT OR OTHER EDITOR. Directly write here or copy from notepad only.'
          value={htmlContent}
          quillRef={editorRef}
          setHTMLContent={setHTMLContent}
        />
        <Button
          className='mt1'
          onClick={handleCreateStatic}
          type='submit'
          name='Submit'
        >
          submit
        </Button>
      </div>
      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </div>
  );
};

export default CreateStatic;
