import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

import '../../HtmlEditor/index.scss';

const NewHtmlEditor = ({
  name,
  id,
  label,
  value,
  onChange = () => {},
  editorRef,
  limitCount = null,
  asterisk = false,
}) => {
  // const handleEditorChange = (content, editor) => {

  //   onChange(content, editor);

  // };

  // const wordcount = tinymce.activeEditor.plugins.wordcount;

  return (
    <div className='cipla-editor'>
      {label && <label>{label}{asterisk ? <span className='asterik'>*</span> : null}</label>}
      <Editor
        onEditorChange={(content, editor) => {
          onChange(content, editor);
          // setValue(name, content)
        }}
        // onBeforeAddUndo={handleBeforeAddUndo}
        tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
        onInit={(evt, editor) => (editorRef.current = editor)}
        value={value ? value : ''}
        init={{
          selector: 'textarea',
          branding: false,
          height: 500,
          menubar: true,
          plugins: [
            'wordcount',
            'advlist',
            'autolink',
            'lists',
            'link',
            'searchreplace',
            'insertdatetime',
          ],
          fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
          font_family_formats: 'Poppins=Poppins; Oswald=oswald;',
          toolbar:
            'undo redo hr removeformat | blocks fontfamily fontsize | bold italic strikethrough forecolor |alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | wordcount',
          content_style:
            '@import url("https://fonts.googleapis.com/css2?family=Oswald&family=Poppins:wght@400;500;600;700;900&display=swap");body { font-family:Poppins, Helvetica, Arial, sans-serif; font-size:10pt } blockquote { border-left: 0.2rem solid #bfd023 !important;} .mce-content-body p { margin-top: 0; margin-bottom: 0;}  ',
        }}
      />
      {/* {showHTMLError && (
        <p className='webinar-vali'>Please enter only 500 characters</p>
      )} */}
    </div>
  );
};

export default NewHtmlEditor;
