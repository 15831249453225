import Button from "app/components/button";
import "../CreateTaskContainer/index"
import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import * as QueryString from 'query-string';
import { useParams } from "react-router-dom";
import moment from 'moment';
import NewInput from "app/components/NewInput";
import Toast from "app/utils/Toast";
import PageLoader from "app/components/PageLoader";
import WebinarCalendar from "app/components/WebinarCalendar";
import { createReward, getRewardDetailsByID, updateRewardDetail } from "app/api/user-journey";
import CreatableSelect from 'react-select/creatable';

const CreateStreakRewardContainer = () => {
    const location = useLocation()
    let history = useHistory()
    const params = useParams();
    const [loader, setLoader] = useState(false)
    const [state, setState] = useState({
        reward_type: "",
        redeem_link: "",
        streak_milestone: "",
        coupon_title: "",
        description: "",
        expiry_on: "",
        allotted_point: null,
        offer_detail: "",
        brand_name: "",
    })
    const rewardTypeOptions = [
        {
            label: "COIN",
            id: "1",
            value: "COIN"
        },
        {
            label: "COUPON",
            id: "2",
            value: "COUPON"
        },

    ]
    useEffect(() => {
        if (params.id) {
            getTaskDetails(params.id)
        }
    }, [])

    const handleTextChange = (e, index) => {
        // if(e.target.name === 'coupon_code'){ 
        //     let tempCopupn = state.coupon_code
        //     tempCopupn[index] = e.target.value
        //     setState({ ...state, coupon_code: tempCopupn})
        // }else{
        setState({ ...state, [e.target.name]: e.target.value })
        // }

    }
    // const handleAddCoupon = (e) => {
    //     let tempState = state.coupon_code 
    //    if(tempState[tempState.length -1] === ''){
    //     Toast.error('Please Enter coupon code ');
    //     return
    //    }
    //     tempState.push('') 
    //     setState({ ...state, coupon_code:  tempState}) 
    // }

    // const handleRemove = (index) => { 
    //     let tempState = state.coupon_code
    //     tempState.splice(index, 1)
    //     setState({ ...state, coupon_code:  tempState}) 
    // }
    const onWebinarStartDateSelected = (date) => {
        setState({ ...state, expiry_on: date })
        // setWebinarStartDate(date);
    };
    const getTaskDetails = async (id) => {
        const taskDetail = await getRewardDetailsByID(id)
        setState({
            reward_type: taskDetail.reward_type,
            redeem_link: taskDetail.redeem_link,
            streak_milestone: taskDetail.streak_milestone,
            reward_id: taskDetail.reward_id,
            cohort_type: taskDetail.cohort_type,
            coupon_title: taskDetail.coupon_title,
            offer_detail: taskDetail.offer_detail,
            description: taskDetail.description,
            expiry_on: taskDetail.expiry_on ? moment(taskDetail.expiry_on).format('YYYY-MM-DD HH:mm:ss') : "",
            allotted_point: taskDetail.allotted_point,
            brand_name: taskDetail.brand_name
        })

    }

    const handleSubmit = async () => {
        let payload = {
            ...state,

        }
        if (!state.coupon_title || !state.expiry_on || !state.reward_type) {
            Toast.error("Please complete all fields");
            return
        }

        if (state.reward_type === 'COIN' && !state.allotted_point) {
            Toast.error("Please complete all fields");
            return
        }

        setLoader(true)
        let responseObj
        if (params.id) {
            responseObj = await updateRewardDetail(payload)
        } else {
            payload.cohort_type = QueryString.parse(location.search).key
            responseObj = await createReward(payload)
        }

        if (
            responseObj &&
            responseObj.response &&
            responseObj.response.code === 200
        ) {
            Toast.success(responseObj.response.alert[0].message);
            history.push("/userjourney/streak-rewards")

        } else {
            setLoader(false)
            Toast.error(responseObj.data && responseObj.data.msg ? responseObj.data.msg : responseObj.response.alert[0].message);
        }
    }
    // const handlechange = (data) => {
    //     let tempCopupn = state.coupon_code
    //     data.map((item,index) => {
    //         tempCopupn.push(item.value)
    //     })
    //     setState({ ...state, coupon_code:  tempCopupn}) 
    // }
    return (
        <div className="container">
            <h1 className="section-header__title">User Journey</h1>

            <div className="separator">
                <p>Create Reward</p>
            </div>
            <div className="userInput">

                <NewInput
                    label="Title"
                    name={"coupon_title"}
                    value={state.coupon_title}
                    onChange={handleTextChange}
                />

                <NewInput
                    label="Description"
                    name={"description"}
                    value={state.description}
                    onChange={handleTextChange}
                />
                <NewInput
                    label="Offer Detail"
                    name={"offer_detail"}
                    value={state.offer_detail}
                    onChange={handleTextChange}
                />
                <NewInput
                    label="Brand Name"
                    name={"brand_name"}
                    value={state.brand_name}
                    onChange={handleTextChange}
                />

                <div className="row">
                    <div className="col-md-12 mt-4">
                        <label >Reward Type</label>
                    </div>
                    <div className="col-md-12 mt-2">
                        <select
                            name="reward_type"
                            className="custom-select w-100" onChange={(e) => {
                                setState({ ...state, reward_type: e.target.value })
                            }}>
                            <option value="">Select</option>
                            {
                                rewardTypeOptions?.map((item, indx) => (
                                    <option key={indx}
                                        value={item.value}
                                        selected={state.reward_type == item.value ? true : false}
                                    >
                                        {item.label}</option>
                                ))
                            }

                        </select>
                    </div>
                </div>
                {/* {state.reward_type === "COUPON" &&
                <div className="mt-4">
                    <CreatableSelect isMulti options={[]} placeholder="Enter Coupon Code" onChange={handlechange} />
                
                </div>
                }     */}
                {state.reward_type === "COIN" &&
                    <NewInput
                        label="Coin"
                        name={"allotted_point"}
                        value={state.allotted_point}
                        onChange={handleTextChange}
                        type={"number"}
                    />}
                <NewInput
                    label="Redeem Link"
                    name={"redeem_link"}
                    value={state.redeem_link}
                    onChange={handleTextChange}
                />
                <NewInput
                    label="Streak Milestone"
                    name={"streak_milestone"}
                    value={state.streak_milestone}
                    onChange={handleTextChange}
                />
                <WebinarCalendar
                    onStartDateSelected={onWebinarStartDateSelected}
                    startDate={state.expiry_on}
                    label='Expiry Time'
                />
                <div className="userFormArea">
                    <Button
                        name={"Save"}
                        onClick={handleSubmit}
                    // isDisabled={!state.video_thumbnail || !state.endless_scroll_media || !state.title}
                    />
                    <label style={{ paddingLeft: "10px", cursor: "pointer" }}

                        onClick={() => { history.goBack() }}
                    >Cancel</label>
                </div>

            </div>
            {loader && <PageLoader pageLoading={loader} />}
        </div>


    );
}

export default CreateStreakRewardContainer