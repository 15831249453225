import {
  updateCollection,
  updateLocalisationCollection,
} from 'app/api/cms-collections';
import {
  getCmsContent,
  getCollectionDetailsByID,
  uplaodMedia,
  getCollectionDetailsLocalisationByID,
} from 'app/api/cms-content';
import ArticleModal from 'app/components/ArticleModal';
import NewInput from 'app/components/NewInput';
import Button from 'app/components/button';
import Select from 'app/components/dropdown';
import Search from 'app/components/search';
import Toast from 'app/utils/Toast';
import { useEffect, useState } from 'react';
import CustomTable from '../CreateCollection/CustomTable';
import SelectComp from '../CreateCollection/SelectComp';
import '../CreateCollection/index.scss';
import Pagination from '../CreateCollection/pagination';
// Constants
import PageLoader from 'app/components/PageLoader';
import HeaderWithBackButton from 'components/HeaderWithBackButton';
import { APP_CONSTANTS } from 'constants/app-constants';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import * as dataAccess from 'utils/ajax';

import { API_CONSTANTS } from 'app/constants/api-contants';
import { CollectionHeader } from 'constants/table-header-constants';
import DragTable from '../CreateCollection/SampleTable';
// import CustomTable from '../CreateCollection/table';
import { imageSizeValidation } from 'app/utils/Parsers';
import ValidationMessage from '../../../utils/ValidationMessage';
import TabBar from 'app/components/NewDesign/TabBar';
import useTabBarOptions from 'app/utils/useTabbarOptions';
import ReactPaginate from 'react-paginate';
const options = [
  { value: 20, label: 20 },
  { value: 30, label: 30 },
  { value: 40, label: 40 },
  { value: 50, label: 50 },
];

const EditCollection = () => {
  const params = useParams();
  const [values, setValues] = useState('');
  const [data, setData] = useState([]);
  const [selectedContent, setSelectedContent] = useState([]);
  const [newOrderData, setNewOrderData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [addCollection, setAddCollection] = useState(true);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(20);
  const [isDisabled, setIsDisabled] = useState(true);
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const { LanguageOptions } = useTabBarOptions();
  const [activeTab, setActiveTab] = useState({
    id: '1',
    name: 'English',
    short_name: 'en',
    display_order: 1,
    status: 'ACTIVE',
    value: 'English',
  });
  const [hideFields, setHideFields] = useState(false);
  const [formDetails, setFormDetails] = useState({
    collection_name: '',
    collection_type: '',
    imageArray: [
      {
        name: 'Discover Image 1',
        id: 1,
        file: null,
      },
      {
        name: 'Discover Image 2',
        id: 2,
        file: null,
      },
      {
        name: 'Discover Image 3',
        id: 3,
        file: null,
      },
      {
        name: 'Discover Image 4',
        id: 4,
        file: null,
      },
      {
        name: 'Discover Image 5',
        id: 5,
        file: null,
      },
      {
        name: 'Discover Image 6',
        id: 6,
        file: null,
      },
      {
        name: 'Discover Image 7',
        id: 7,
        file: null,
      },
    ],
  });
  let collectionDetails = localStorage.getItem('collection');
  collectionDetails = JSON.parse(collectionDetails);
  const selectOptions = [
    {
      label: 'CARD',
      value: 'CARD',
    },
    {
      label: 'STORY',
      value: 'STORY',
    },
  ];
  useEffect(() => {
    getContentDataHandler(params.id);
    // setFormDetails({ ...formDetails, 'collection_name': collectionDetails.collection_name, 'collection_type': collectionDetails.collection_type });
  }, [activeTab?.name]);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  let currentData = data.slice(indexOfFirstData, indexOfLastData);

  const handleTabChange = (val) => {
    setActiveTab(val);
    if (val?.name === 'English') {
      setHideFields(false);
    } else {
      setHideFields(true);
    }
    // Perform any other actions on tab change
  };

  async function getContentDataHandler(id) {
    const dataArr = await getCollectionDetailsByID(id);
    let newDataArr;
    if (activeTab?.name !== 'English') {
      newDataArr = await getCollectionDetailsLocalisationByID(
        id,
        activeTab?.short_name
      );
    }
    let tempImages = [];
    if (activeTab?.name !== 'English') {
      formDetails.imageArray.forEach((img, i) => {
        let temp = img;

        temp.file = newDataArr[0].images_path[i];
      });
      setFormDetails({
        ...formDetails,
        tempImages,
        collection_name: newDataArr?.[0].collection_name
          ? newDataArr?.[0].collection_name
          : '',
      });
    } else {
      formDetails.imageArray.forEach((img, i) => {
        let temp = img;

        temp.file = dataArr[0].images_path[i];
      });
      setFormDetails({
        ...formDetails,
        tempImages,
        collection_name: collectionDetails.collection_name,
        collection_type: collectionDetails.collection_type,
      });
    }
    setNewOrderData(dataArr[0].content_ids);
    setSelectedContent(dataArr);
    // setData(dataArr);
    // let count = Math.ceil(dataArr.length / dataPerPage);
    // setPageCount(count)

    const contentList = await getCmsContent();
    setData(contentList);

    let contentCount = Math.ceil(contentList.length / dataPerPage);
    setPageCount(contentCount);
  }

  function inputChangeHandler(searchVal) {
    if (searchVal.trim() === '') {
      setValues(searchVal);
      getContentDataHandler(params.id);
      return;
    }
    const filteredData = [];
    data.forEach((item) => {
      if (item.title.includes(searchVal)) {
        filteredData.push(item);
      }
    });
    setData(filteredData);
    setValues(searchVal);
  }

  function paginationHandler(page) {
    setCurrentPage(page);
  }

  function changeHandler(event) {
    const pageLength = event.target.value;
    setDataPerPage(pageLength);
    let count = Math.ceil(data.length / pageLength);
    setPageCount(count);
  }

  const handleSelect = (e, data) => {
    let selectedContentArray = selectedContent;
    if (e.target.checked) {
      selectedContentArray.push(data);
    } else {
      const findIndex = selectedContent.findIndex(
        (item) => item.id === data.id
      );
      selectedContentArray.splice(findIndex, 1);
    }
    let selectedContentId = [];
    selectedContentArray.forEach((item) => {
      selectedContentId.push(item.id);
    });
    setNewOrderData(selectedContentId);
    setSelectedContent(selectedContentArray);
    setIsDisabled(selectedContentArray.length === 0 ? true : false);
  };

  const handleMediaUpload = async (e, index) => {
    let file = e.target.files[0];
    if (
      (file.type === 'image/jpeg' ||
        file.type === 'image/jpg' ||
        file.type === 'image/png') &&
      imageSizeValidation(e)
    ) {
      Toast.error(ValidationMessage.IMAGE_SIZE);
      return;
    }
    const { filename } = await uplaodMedia('media', file, 'COLLECTION');

    let tempImages = [];
    formDetails.imageArray.forEach((img, i) => {
      if (index === i) {
        let temp = img;
        img.file = filename;
        tempImages.push(temp);
      } else {
        tempImages.push(img);
      }
    });
    setFormDetails({ ...formDetails, tempImages });
  };

  function handleInputChangeHandler(event) {
    const { name, value } = event.target;
    setFormDetails({ ...formDetails, [name]: value });
  }
  function handleSelectChangeHandler(data) {
    setFormDetails({ ...formDetails, collection_type: data.value });
  }
  const handleSubmit = async () => {
    let tags_added = [];
    let images_path = [];
    let error = {};

    selectedContent.forEach((item, index) => {
      tags_added.push(item.tags);
    });
    if (collection_type === 'CARD') {
      formDetails.imageArray.forEach((item, index) => {
        images_path.push(item.file);
        if (!item.file) {
          error.image = 'select valid image file';
        }
      });
    }

    if (!formDetails.collection_name) {
      Toast.error('Collection name should not be empty');
      return;
    }
    if (!formDetails.collection_type) {
      Toast.error('Collection type should not be empty');
      return;
    }
    let payload = {
      collection_name: formDetails.collection_name,
      collection_type: formDetails.collection_type,
      content_ids: newOrderData,
      tags_added,
      images_path,
      collection_id: params.id,
    };

    if (Object.keys(error).length === 0) {
      let responseObj;
      if (activeTab?.name === 'English') {
        responseObj = await updateCollection(payload);
      } else {
        let newPayload = {
          collection_name: formDetails.collection_name,
          images_path,
          collection_id: params.id,
        };
        responseObj = await updateLocalisationCollection(
          newPayload,
          activeTab?.short_name
        );
      }
      setLoader(false);
      if (
        responseObj &&
        responseObj.response &&
        responseObj.response.code === 200
      ) {
        Toast.success(responseObj.response.alert[0].message);
      } else {
        Toast.error(responseObj.response.alert[0].message);
      }

      history.push(APP_CONSTANTS.CMS_PANEL_COLLECTIONS);
    } else {
      Toast.error('Select all the image');
    }
  };
  const handleCancel = () => {
    setSelectedContent([]);
    // setAddCollection(false)
    history.push(APP_CONSTANTS.CMS_PANEL_COLLECTIONS);
  };

  const handleRemoveFile = async (index) => {
    let tempImages = [];
    let fileName = '';
    formDetails.imageArray.forEach((img, i) => {
      if (index === i) {
        fileName = img.file;
        console.log(img, fileName, 'img');
        let temp = img;
        img.file = null;
        tempImages.push(temp);
      } else {
        tempImages.push(img);
      }
    });
    setFormDetails({ ...formDetails, tempImages });
    let responseObj = await dataAccess.del(
      `${API_CONSTANTS.DELETE_MEDIA}${fileName}`
    );
    if (
      responseObj?.response?.code === 200 ||
      responseObj?.response?.code === 201 ||
      responseObj?.response?.code === 204
    ) {
      Toast.success('Deleted successfully');
      history.goBack();
    } else {
      Toast.error('Something went Wrong . Please Try Later');
    }
  };

  const HomePageOrder = (tempData) => {
    const HomepageData = tempData.map((data, index) => {
      return data.original.id;
    });

    setNewOrderData(HomepageData);
  };

  const menuActionObj = [
    {
      name: 'Edit',
      onClick: (e, data) => {
        history.push({
          pathname: `/edit-homepage-order/${data.id}`,
          state: {
            ...data,
          },
        });
      },
    },
  ];

  const sectionObj = {
    sectionTitle: 'HomePage-Order',
    searchObj: {
      placeholder: 'Search',
      handleSearch: (e) => {
        console.log('Handle search: ', e);
      },
    },
  };

  const handleAddContent = () => {
    setAddCollection(false);
  };

  let { collection_name, collection_type, imageArray } = formDetails;
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1); // Update currentPage for pagination
  };
  return (
    <>
      {' '}
      {showFilter && (
        <ArticleModal
          modalCloseClicked={() => setShowFilter(false)}
          filterApplyClicked={() => {}}
          ResetFilterCicked={() => {}}
          handleChange={() => {}}
          values={''}
          error={''}
        />
      )}
      {loader && <PageLoader pageLoading={loader} />}
      <div className='cms-content__container'>
        <HeaderWithBackButton
          title='Collection/Edit Collection'
          goTo={APP_CONSTANTS.CMS_PANEL_COLLECTIONS}
        />
        {addCollection ? (
          <div className='content-container__table'>
            <div className='content-container__table'>
              <div className='content-container__actionBox'>
                <div className='content-container__filterSearch'>
                  <h5>Selected Collection List</h5>
                </div>
                <Button
                  className='content-container__filter-btn mb-4'
                  onClick={() => handleAddContent()}
                >
                  Add Content
                </Button>
              </div>

              <DragTable
                tableHeader={CollectionHeader}
                sectionObj={sectionObj}
                menuAction={menuActionObj}
                data={selectedContent}
                HomePageOrder={HomePageOrder}
                hasPagination={false}
                selectedContent={selectedContent}
              />
            </div>
            <TabBar
              tabs={LanguageOptions}
              initialTab={activeTab}
              onTabChange={handleTabChange}
              parentStyle={'service-tab-style'}
            />
            <div>
              <div className='separator mt-5'>
                <p>Collection - Add Details</p>
              </div>
            </div>
            <div className='userFormArea'>
              <div className='row mt-3'>
                <NewInput
                  label='Collection Name'
                  onChange={handleInputChangeHandler}
                  name={'collection_name'}
                  value={collection_name}
                  placeholder='Name'
                />
              </div>
              {!hideFields && (
                <div className='row mt-3'>
                  <Select
                    options={selectOptions}
                    onChange={handleSelectChangeHandler}
                    placeholder={'Select Collection type'}
                    value={collection_type}
                    name={'collection_type'}
                  />
                </div>
              )}
              {collection_type === 'CARD' && (
                <div className='row'>
                  {imageArray.map((item, index) => {
                    return (
                      <div className='col-sm-3 mt-3' key={index}>
                        <p>{item.name}</p>
                        <input
                          type='file'
                          id={`image+${item.id}`}
                          style={{ display: 'none' }}
                          name='endless_scroll_media'
                          onChange={(e) => {
                            handleMediaUpload(e, index);
                          }}
                        />

                        <Button
                          onClick={() => {
                            const image = document.getElementById(
                              `image+${item.id}`
                            );
                            image.click();
                          }}
                          key={imageArray}
                          type='button'
                          name={item.file ? 'Uploaded' : 'Upload Image'}
                        />
                        {item.file !== null && (
                          <p
                            className='cancel'
                            key={imageArray}
                            onClick={() => handleRemoveFile(index)}
                          >
                            Cancel Upload
                          </p>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
              <div className='row'>
                <div className='col-sm-12 mt-5'>
                  <div className='userFormArea'>
                    <Button
                      name='Update'
                      onClick={handleSubmit}
                      className='mx-3'
                    />

                    <button
                      className='btn  typebtn-outline-secondary'
                      type='button'
                      onClick={handleCancel}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className='content-container__actionBox'>
              <div className='content-container__filterSearch'>
                <Search
                  value={values}
                  placeholder='Search'
                  className={''}
                  onChange={inputChangeHandler}
                />
                {/* <Button
                        onClick={() => setShowFilter(true)}
                        className="content-container__filter-btn">Filter</Button> */}
              </div>
            </div>
            <div className='content-container__perPage'>
              Show
              <SelectComp options={options} onChangeHandler={changeHandler} />
              entries
            </div>

            <div className='content-container__table'>
              <CustomTable
                data={currentData}
                handleSelect={handleSelect}
                selectedContent={selectedContent}
              />
            </div>
            <div className='content-container__pagination'>
              {currentData && (
                <div className='centerButton'>
                  <Button
                    onClick={() => setAddCollection(true)}
                    className='content-container__filter-btn ml-auto'
                    isDisabled={isDisabled}
                  >
                    Add as collection
                  </Button>
                </div>
              )}
              {/* <Pagination pages={pageCount} onChange={paginationHandler} /> */}
              <ReactPaginate
                breakLabel="..."
                nextLabel={<span>Next</span>}
                onPageChange={handlePageChange}
                pageCount={pageCount}
                previousLabel={<span>Previous</span>}
                marginPagesDisplayed={2}
                renderOnZeroPageCount={null}
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                disabledClassName="page-item disabled"
                activeClassName="active"
                nextLinkClassName="page-link"
                previousLinkClassName="page-link"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                forcePage={currentPage - 1}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default EditCollection;
