import CreateContentContainer from "app/container/CreateContentContainer";
import BasePage from "app/pages/BasePage";
import CollectionsContainer from '../../../container/Collections'

const CreateContent = () => {
    return (
        <BasePage>
            <CollectionsContainer />
        </BasePage>
    );
}

export default CreateContent;