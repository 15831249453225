import React from 'react';

// Pages
import BasePage from 'app/pages/BasePage'; 
import CreateWidgetContainer from 'app/container/DynamicWidgets/CreateWidget'
const CreateWidget = () => {
  return (
    <BasePage>
      <CreateWidgetContainer />
    </BasePage>
  );
};

export default CreateWidget;
