import { useHistory } from 'react-router';
import '../index.scss';
import { dataFormats } from 'app/utils/dateChecker';
import Toggle from 'react-toggle';
import React, { useState, useEffect } from 'react';
import { updateExperiencePremium} from 'app/api/exercises';
import Toast from 'app/utils/Toast';


function CustomTable({
  data,
  tableHeader, 
  onDataUpdate
}) {
  const history = useHistory();
  const [toggleStatus, setToggleStatus] = useState(false);
  useEffect(() => {
    const initialToggleStatus = {};
    data.forEach((item) => {
         initialToggleStatus[item.id] = item.is_premium; // Assuming 'id' and 'status' are fields in your API response
    });

    setToggleStatus(initialToggleStatus);

}, [data]);
const handleToggleChange=(item)=>{
  let itemId = item.id
    const currentStatus = toggleStatus[itemId];
    const newStatus = currentStatus === true ? false :true ;
    setToggleStatus((prevState) => ({
      ...prevState,
      [itemId]: newStatus,
    }));
    const payload = {
      is_premium: newStatus === true ? true : false,
      
    };
    updateExperiencePremium(payload, item.id) // Pass the payload object
            .then((response) => { 
              if (response&&response.response.code === 200) {
                
                Toast.success('Successfully Updated')
                setToggleStatus((prevState) => ({
                  ...prevState,
                  [itemId]: newStatus,
                }));
                onDataUpdate();
              } else {
                Toast.error(response?.data)
               
                console.error('API update error', response);
              }
            })
            .catch((error) => {
              
              console.error(error, 'error');
            });
}
  return (
    <table>
      <thead> 
          {tableHeader.map((item, i) => {
            return <th className='nowrap'>{item.Header}</th>;
          })} 
      </thead>
      <tbody>
      {data.map((items, index) => (
        <tr key={index}>
          <td>{items.exercise_description}</td>
          <td>{items.exercise_category}</td>
          <td><Toggle checked={toggleStatus[items.id] == true ? true : false}  onChange={() => handleToggleChange(items)} icons={false} /></td>
          
          
        </tr>
      ))} 
      {data.length === 0 && (
        <tr>
          <td colSpan='10000'>No data found</td>{' '}
        </tr>
      )}
      </tbody>
    </table>
  );
}

export default CustomTable;
