import { API_CONSTANTS } from "app/constants/api-contants";
import * as dataAccess from "utils/ajax";
import Parsers from "utils/Parsers";
import Toast from "app/utils/Toast";

export const getRecommendedList = async () => {
  let responseObj = await dataAccess.get(API_CONSTANTS.GET_RECOMMENDED_LIST);
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data;
  } else {
    return [];
  }
};

export const createRecommendedStory = async (incomingData) => {
  let formData = Parsers.ConvertObjectAsFormData(incomingData);
  let payload = {
    data: formData,
  };
  return await dataAccess.upload(API_CONSTANTS.POST_RECOMMENDED_STORY, payload);
};

export const getRecommendedStoryDetails = async (id, lang) => {
  let responseObj = await dataAccess.get(
    `${API_CONSTANTS.GET_RECOMMENDED_LIST_VERSION}/${id}?lang=${lang}`
  );
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data;
  } else {
    return [];
  }
};

export const updateRecommendedStory = async (incomingData) => {
  let formData = Parsers.ConvertObjectAsFormData(incomingData);
  let payload = {
    data: formData,
  };
  let response = dataAccess.update(
    API_CONSTANTS.POST_RECOMMENDED_STORY,
    payload
  );
  return response;
};

export const updateRecommendedLocalisationStory = async (
  incomingData,
  lang
) => {
  let formData = Parsers.ConvertObjectAsFormData(incomingData);
  let payload = {
    data: formData,
  };
  let response = dataAccess.update(
    `${API_CONSTANTS.UPDATE_RECOMMENDED_STORY}?lang=${lang}`,
    payload
  );
  return response;
};

export async function deleteRecommendedStoryById(id) {
  let responseObj = await dataAccess.del(
    `${API_CONSTANTS.GET_RECOMMENDED_LIST}/${id}`
  );
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    Toast.success(responseObj.response.alert[0].message);
  }
}
