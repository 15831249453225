import { API_CONSTANTS } from 'constants/api-contants';
import * as dataAccess from 'utils/ajax';
import GetAuthPayload from 'utils/Payload/AuthPayload';
import ApiParser from 'utils/ApiParser';
import AWS from 'aws-sdk';
import { LocalStorage } from 'app/utils/storage';

export const loginToken = async (payload, callback) => {
  let auPayload = await GetAuthPayload();
  let authPayload = {
    application_id: process.env.REACT_APP_APPLICATION_ID,
    device_name: '',
    ...auPayload,
  };

  let responseObj = await dataAccess.post(API_CONSTANTS.AUTHENTICATE, {
    data: {
      ...authPayload,
    },
  });

  if (responseObj.response && responseObj.response.code === 200) {
    if (responseObj.data && responseObj.data.access_token) {
      responseObj = await dataAccess.post(API_CONSTANTS.LOGIN, {
        data: {
          ...payload,
        },
        headers: {
          accesstoken: responseObj.data.access_token,
        },
      });
      callback(responseObj);
      return;
    }
  }
  callback({});
};

export const logoutAction = async () => {
  let responseObj = await dataAccess.get(API_CONSTANTS.LOGOUT);
  LocalStorage.remove('Language');
  return responseObj;
};

// export const getBanners = async () => {
//   let responseObj = await dataAccess.get(API_CONSTANTS.GET_BANNERS);
//   return ApiParser.parseBannersListing(responseObj);
// };

export const updatePassword = async (payload, callback) => {
  let auPayload = await GetAuthPayload();
  let authPayload = {
    application_id: process.env.REACT_APP_APPLICATION_ID,
    device_name: '',
    ...auPayload,
  };

  let responseObj = await dataAccess.post(API_CONSTANTS.AUTHENTICATE, {
    data: {
      ...authPayload,
    },
  });

  if (responseObj.response && responseObj.response.code === 200) {
    if (responseObj.data && responseObj.data.access_token) {
      responseObj = await dataAccess.post(API_CONSTANTS.UPDATE_PASSWORD, {
        data: {
          ...payload,
        },
        headers: {
          accesstoken: responseObj.data.access_token,
        },
      });

      callback(responseObj);
      return;
    }
  }
  callback({});
};

// export const updatePassword = async (incomingData) => {
//   let payload = {
//     data: {
//       ...incomingData,
//     },
//   };

//   let responseObj = await dataAccess.post(
//     API_CONSTANTS.UPDATE_PASSWORD,
//     payload
//   );

//   return ApiParser.parseUpdatePassword(responseObj);
// };

export const resetPassword = async (payload, callback) => {
  let auPayload = await GetAuthPayload();
  let authPayload = {
    application_id: process.env.REACT_APP_APPLICATION_ID,
    device_name: '',
    ...auPayload,
  };

  let responseObj = await dataAccess.post(API_CONSTANTS.AUTHENTICATE, {
    data: {
      ...authPayload,
    },
  });

  if (responseObj.response && responseObj.response.code === 200) {
    if (responseObj.data && responseObj.data.access_token) {
      responseObj = await dataAccess.post(API_CONSTANTS.RESET_PASSWORD, {
        data: {
          ...payload,
        },
        headers: {
          accesstoken: responseObj.data.access_token,
        },
      });

      callback(responseObj);
      return;
    }
  }
  callback({});
};

export const getEncryptedText = async (password) => {
  var test = new AWS.KMS({
    region: process.env.REACT_APP_REGION_ACCESS_KEY,
    accessKeyId: process.env.REACT_APP_CLIENT_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  });
  const param = {
    KeyId: process.env.REACT_APP_KEY_ID,
    Plaintext: password,
    EncryptionAlgorithm: process.env.REACT_APP_ENCRYPTION_ALGORITHM,
  };

  return new Promise((resolve, reject) => {
    test.encrypt(param, (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(Buffer.from(data.CiphertextBlob).toString('base64'));
      }
    });
  });
};
