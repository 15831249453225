import { useHistory } from "react-router"
import './index.scss'

function CustomTable({ data, tableHeader, handleDelete }) { 
     const history = useHistory()

     //handle navigation
     const handleNavigation = (item) =>{
          
          history.push({
               pathname: `/userjourney/edit/${item.id}`,
               
          });
     }
     return (
          <table>
               <tr>
                    {tableHeader.map((item,i) => {
                         return (
                              <th className='nowrap text-center'>{item.Header}</th>
                         )
                    })} 
               </tr>
               {data.map((items, indx) => (<tr key={indx}>
                    <td>{items.streak_day}</td> 
                    <td>{items.exercise_id}</td>
                    <td>{items.title}</td> 
                    <td>{items.task_name}</td>
                    <td>{items.task_type}</td>
                    <td>{items.exercise_level?items.exercise_level:'-' }</td>
                    <td>{items.content_id}</td>
                    <td>
                         <div className="btn-group" role="group" aria-label="Basic outlined example">
                              <button
                                   type="button"
                                   className="btn btn-outline-primary"
                                   onClick={() => handleNavigation(items) }
                              >
                                   Edit
                              </button>
                              <button type="button"
                                   onClick={() => handleDelete(items) }
                                   className="btn btn-outline-primary">Delete</button>
                               
                         </div> 
                         
                    </td>
               </tr>))}
               {data.length ===0 &&  <tr><td colspan="10000">No data found</td> </tr>}
          </table>
     )
}

export default CustomTable