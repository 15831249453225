import { ArrowDownImage, ArrowUpImage } from 'constants/image-constants';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { usePagination, useSortBy, useTable } from 'react-table';
import Message from 'utils/Message';
import Toast from 'utils/Toast';
import ActionMenu from '../ActionMenu';
import './index.scss';

const Table = ({
  columns,
  data,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  menuAction,
  setRowData,
  HomePageOrder,
  disabledFirstAndLast
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,

    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      pageCount: controlledPageCount,
      autoResetPage: false,
    },
    useSortBy,
    usePagination
  );

  const [homepageOrderId, sethomepageOrderId] = useState('');
  const [activityVitalsId, setactivityVitalsId] = useState('');

  const [pageData, setPageData] = useState([]);

  const createToggler = (row) => { 
    if(disabledFirstAndLast && (row.index === 0 || row.index === pageData.length-1) ) return
    sethomepageOrderId(row.original.id);
    setactivityVitalsId(row.original.id);
  };

  const handleDragEnd = async (e) => {
    
    if(disabledFirstAndLast && (e.destination.index === 0 || e.destination.index === pageData.length-1) ) return
    if (!e.destination) return;

    let tempData = [...pageData];
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setPageData(tempData);
    // const HomepageData = tempData.map((data, index) => {
    //   return {
    //     home_page_id: data.original.id,
    //     sorting_order: index + 1,
    //   };
    // });
    // setabc(tempData);

    await HomePageOrder(tempData);
  };

  const onClickOutside = () => {
    setactivityVitalsId('');
    sethomepageOrderId('');
  };

  useEffect(() => {
    fetchData({ pageIndex, pageSize });
  }, [fetchData, pageIndex, pageSize]);

  useEffect(() => {
    setPageData(page);
  }, [page]);

  // Render the UI for your table
  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <img
                            className='arrow'
                            src={ArrowDownImage}
                            alt='Desc'
                          />
                        ) : (
                          <img className='arrow' src={ArrowUpImage} alt='Asc' />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <Droppable droppableId='droppable-1'>
            {(provider) => (
              <tbody
                ref={provider.innerRef}
                {...provider.droppableProps}
                {...getTableBodyProps()}
              >
                {pageData.map((row, i) => {
                  prepareRow(row);
                  return (
                    <Draggable key={row.id} draggableId={row.id} index={i} isDragDisabled={disabledFirstAndLast && (pageData[pageData.length -1].id=== row.id || pageData[0].id=== row.id)}>
                      {(provider) => (
                        <tr
                          {...provider.draggableProps}
                          ref={provider.innerRef}
                          {...row.getRowProps()}
                        >
                          {row.cells.map((cell) => {
                            return (
                              <>
                                {cell.column.id === 'Action' ? (
                                  <td
                                    style={{ position: 'relative' }}
                                    className='pointer'
                                    onClick={() => createToggler(row)}
                                  >
                                    :
                                    <>
                                      {homepageOrderId === row.original.id &&
                                      activityVitalsId === row.original.id ? (
                                        <ActionMenu
                                          menuAction={menuAction}
                                          onClickOutside={onClickOutside}
                                          row={row.original}
                                        />
                                      ) : null}
                                    </>
                                  </td>
                                ) : cell.column.id === 'Drag' ||
                                  cell.column.id === 'Ranking' ? (
                                  <td {...provider.dragHandleProps}>▶</td>
                                ) : cell.column.id === 'SequenceOrder' ? (
                                  <td>{}</td>
                                ) : (
                                  <>
                                    <td {...cell.getCellProps()}>
                                      {cell.render('Cell')}
                                    </td>
                                  </>
                                )}
                              </>
                            );
                          })}
                        </tr>
                      )}
                    </Draggable>
                  );
                })}
              </tbody>
            )}
          </Droppable>
        </table>
      </DragDropContext>
    </>
  );
};

function SampleTable({
  tableHeader,
  names,
  menuAction,
  pagination,
  type,
  getData,
  searchVal,
  searchKey = 'title',
  hasPagination,
  filterData,
  responseCallback,
  setRowData,
  HomePageOrder,
  selectedContent,
  disabledFirstAndLast
}) {
  const columns = React.useMemo(() => tableHeader, [tableHeader]);

  // We'll start our table without any data
  const [data, setData] = React.useState([]);
  const [rawData, setRawData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);

  const fetchData = React.useCallback(({ pageSize, pageIndex }) => {
    if (getData) {
      setLoading(true);

      getData(pageIndex + 1, filterData).then((response) => {
        responseCallback && responseCallback(response);
        setLoading(false);
        let pageCount = 0;
        if (response.error === false) {
          if (response && response.tableData) {
            pageCount = response.tableData.length;
            setData(response.tableData);
            setRawData(response.tableData);
          }
          if (
            response &&
            response.pagination &&
            response.pagination.total_records
          ) {
            setPageCount(
              Math.ceil(response.pagination.total_records / pageSize)
            );
          } else {
            setPageCount(Math.ceil(pageCount / pageSize));
          }
        } else {
          if (response.message) {
            Toast.error(response.message);
          } else {
            Toast.error(Message.Error.COMMON_MESSAGE);
          }
        }
      });
    }
    if (selectedContent && selectedContent.length > 0) {
      setLoading(true);

      // getData(pageIndex + 1, filterData).then((response) => {
      responseCallback && responseCallback(selectedContent);
      setLoading(false);
      // let pageCount = 0;
      // if (response.error === false) {
      if (selectedContent) {
        // pageCount = selectedContent.length;
        setData(selectedContent);
        setRawData(selectedContent);
      }

      setPageCount(Math.ceil(selectedContent.length / pageSize));
    }
  }, []);

  useEffect(() => {
    if (searchVal && searchVal.trim().length > 0) {
      let searchString = searchVal.trim().toLowerCase();
      let newData = [...rawData];
      // We are searching. Filter the results.
      newData = newData.filter((e) => {
        if (e[searchKey]) {
          return e[searchKey].toLowerCase().match(searchString);
        }
        return false;
      });
      setData(newData);
    } else {
      setData([...rawData]);
    }
  }, [searchVal]);

  return (
    <Table
      columns={columns}
      data={data}
      fetchData={fetchData}
      loading={loading}
      pageCount={pageCount}
      menuAction={menuAction}
      hasPagination={hasPagination}
      setRowData={setRowData}
      HomePageOrder={HomePageOrder}
      disabledFirstAndLast={disabledFirstAndLast}
    />
  );
}

export default SampleTable;
