// Ajax
import * as dataAccess from 'utils/ajax';
import ApiParser from 'utils/ApiParser';

// Constants
import { API_CONSTANTS } from 'constants/api-contants';
import { LocalSessionKey } from 'constants/app-constants';
import { LocalStorage } from 'utils/storage';
import Parsers from 'utils/Parsers';

import Toast from 'app/utils/Toast';

export const getHomePage = async (start, filterPayload) => {
  let payload = {
    data: {
      page: start ? start : 1,
      limit: 10,
      ...filterPayload,
    },
  };
  let url = `${API_CONSTANTS.COMMON_HOMEPAGE}?page=HOME`;
  let responseObj = await dataAccess.get(url, payload);
  return ApiParser.parseHomeListing(responseObj);
};

export const getHomePageOrderingHome = async (type) => {
  let responseObj = await dataAccess.get(API_CONSTANTS.GET_HOMEPAGE_ORDER_HOME);

  return ApiParser.parseHomeOrderListing(responseObj);
};

export const getHomePageOrderingExplore = async (type) => {
  let responseObj = await dataAccess.get(
    API_CONSTANTS.GET_HOMEPAGE_ORDER_EXPLORE
  );

  return ApiParser.parseHomeOrderListingExplore(responseObj);
};

// export const addOrUpdateHomePageOrder = async (

// ) => {
//   let payload = {
//     data: [
//       {
//         home_page_id: homePageId,
//         sorting_order: sortingOrder,
//       },
//       {
//         home_page_id: homePageId,
//         title: title,
//         title_visibility: titleVisibility,
//       },
//       ...incomingData,
//     ],
//   };

//   return await dataAccess.post(API_CONSTANTS.POST_HOMEPAGE, payload);
// };

export const updateHomepageOrderingHome = async (incomingData) => {
  let payload = {
    data: {
      homepage_order_data: incomingData,
    },
  };
  return await dataAccess.post(
    API_CONSTANTS.UPDATE_HOMEPAGE_ORDER_HOME,
    payload
  );
};
export const updateHomepageOrderingExplore = async (incomingData) => {
  let payload = {
    data: {
      homepage_order_data: incomingData,
    },
  };
  return await dataAccess.post(
    API_CONSTANTS.UPDATE_HOMEPAGE_ORDER_EXPLORE,
    payload
  );
};

export const createHomePageSectionOrdering = async (incomingData) => {
  let payload = {
    data: incomingData,
  };
  return await dataAccess.post(
    API_CONSTANTS.CREATE_HOME_PAGE_SECTION_ORDERING,
    payload
  );
};

export const updateHomePageSectionOrdering = async (incomingData) => {
  let payload = {
    data: incomingData,
  };
  return await dataAccess.put(
    API_CONSTANTS.CREATE_HOME_PAGE_SECTION_ORDERING,
    payload
  );
};

export const addOrUpdateHomePageOrder = async (incomingData, page) => {
  let payload = {
    data: {
      home_page_update: incomingData,
    },
  };

  let url = `${API_CONSTANTS.COMMON_HOMEPAGE}?page=${page}`;
  return await dataAccess.post(url, payload);
};

export async function deleteHomePageOrdering(id) {
  let responseObj = await dataAccess.del(
    `${API_CONSTANTS.DELETE_HOME_PAGE_SECTION_ORDERING}/${id}`
  );
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    Toast.success(responseObj.response.alert[0].message);
  }
}
