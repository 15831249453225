import {
  addModule,
  deleteFaqAPI,
  getFaqListByModuleId,
  updateModule,
} from 'app/api/faq';
import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import Message from 'utils/Message';
import Toast from 'utils/Toast';
// import { getActiveUsers } from 'app/api/feed';
const useCreateFaq = (validate) => {
  const history = useHistory();
  const location = useLocation();
  const editorRef = useRef(null);
  const [moduleId, setModuleId] = useState('');
  const [moduleName, setModuleName] = useState('');
  const [moduleSequenceorder, setModuleSequenceorder] = useState('');
  const [pageLoading, setPageLoading] = useState(false);
  const [count, setCount] = useState(1);
  const [faqlistData, setfaqlistData] = useState([]);
  const [isPublishButtonDisabled, setisPublishButtonDisabled] = useState(null);
  const [pageRefresh, setpageRefresh] = useState(false);
  const [activeId, setActiveId] = useState('');

  const [activeTab, setActiveTab] = useState({
    id: '1',
    name: 'English',
    short_name: 'en',
    display_order: 1,
    status: 'ACTIVE',
    label: '+ English',
    value: 'English',
  });
  const [activeShow, setActiveShow] = useState(false);

  useEffect(() => {
    if (activeTab.name === 'English') {
      setActiveShow(true);
    } else {
      setActiveShow(false);
    }
  }, [activeTab?.name]);

  const handleTabChange = (val) => {
    setActiveTab(val);

    // Perform any other actions on tab change
  };

  useEffect(() => {
    let faqData = location.state;
    setModuleId(faqData?.module_id ? faqData?.module_id : '');
    if (faqData?.module_id) {
      loadServiceslist(faqData?.module_id);
    } else {
      addfaqSection();
    }

    return () => {
      setfaqlistData([]);
    };
  }, [pageRefresh, activeTab?.short_name]);

  const loadServiceslist = async (moduleid) => {
    if (!moduleid) return;
    setfaqlistData([]);
    setPageLoading(true);
    let response = await getFaqListByModuleId(moduleid, activeTab?.short_name);

    if (response?.response?.code === 200) {
      let temp = [];

      if (response?.data) {
        setisPublishButtonDisabled(true);
        let res = response?.data;
        setModuleName(res?.module_name);
        setModuleSequenceorder(res?.sequence_order);

        if (response?.data?.faq_list?.length > 0) {
          temp = res?.faq_list.map((item, index) => {
            return {
              id: item?.id,
              faq_index_Id: `faq_${index}`,
              question: item?.question,
              answer: item?.answer,
              sequence_order: item?.sequence_order,
              isNew: false,
              wasEdited: false,
              toggle: item?.status === 'ACTIVE' ? true : false,
            };
          });
        }
      }
      // let newtemp = temp.sort((a, b) => a.sequence_order - b.sequence_order);
      setfaqlistData(temp);
      setCount(temp.length);
      setPageLoading(false);
    } else {
      setPageLoading(false);
      Toast.error(response.data ? response.data : Message.Error.COMMON_MESSAGE);
    }
  };

  const validateRequest = async () => {
    if (activeTab.short_name === 'en') {
      let addheadingCheck = false;
      let adddisplayOrderCheck = false;
      let adddisplayOrderunique = false;
      let answerCheck = false;
      let adddisplayOrderMaxvalueCheck = false;
      faqlistData.forEach((item, index) => {
        if (item?.question === '') {
          Toast.error(`Please enter question for faq ${index + 1}`);
          addheadingCheck = true;
          return;
        }
        if (item?.answer === '') {
          Toast.error(`Please enter answer for faq ${index + 1}`);
          answerCheck = true;
          return;
        }
        if (item?.sequence_order === '' || !item?.sequence_order) {
          Toast.error(`Please select Sequence for Faq ${index + 1}`);
          adddisplayOrderCheck = true;
          return;
        }

        if (item?.sequence_order > faqlistData.length) {
          Toast.error(
            `Sequence Order Must be Under ${faqlistData.length} for faq ${
              index + 1
            }`
          );
          adddisplayOrderMaxvalueCheck = true;
          return;
        }
      });

      const unique = [
        ...new Set(faqlistData.map((item) => item.sequence_order)),
      ];

      if (unique && faqlistData && unique.length < faqlistData.length) {
        Toast.error('Sequence Order must be Unique');
        adddisplayOrderunique = true;
        return;
      }
      if (
        answerCheck ||
        addheadingCheck ||
        adddisplayOrderCheck ||
        adddisplayOrderunique ||
        adddisplayOrderMaxvalueCheck
        // addminimumoneFaq
      ) {
        setPageLoading(false);
        return;
      }
    }

    callAddOrUpdateAPI();
  };

  const addfaqSection = () => {
    setisPublishButtonDisabled(false);

    const temp = [...faqlistData];

    temp.push({
      faq_index_Id: Date.now(), // Basically its a index
      id: null,
      isNew: true,
      question: '',
      answer: '',
      sequence_order: '',
      toggle: false,
      wasEdited: false,
    });
    setCount(count + 1);

    setfaqlistData(temp);
  };

  const removefaqSection = (ind) => {
    const temp = [];
    let removedServiceTemp = [...faqlistData];
    faqlistData.forEach((item, index) => {
      if (ind !== index) {
        temp.push(item);
      }
      if (ind === index && item.id) {
        removedServiceTemp.push(parseInt(item.id));
      }
    });

    setCount(temp.length);
    if (removedServiceTemp >= 1) {
      setisPublishButtonDisabled(false);
    } else {
      setisPublishButtonDisabled(true);
    }
    setfaqlistData(temp);
  };

  const callAddOrUpdateAPI = async () => {
    // debugger;
    /* Populate Data */

    // debugger;

    let addpayloadData = [];

    faqlistData.forEach((item, index) => {
      var consData = {};
      if (item?.answer) {
        consData.answer = item.answer;
      }

      consData.publish = item.toggle;

      if (item?.sequence_order) {
        consData.sequence_order = item.sequence_order;
      }
      if (item?.question) {
        consData.question = item.question;
      }
      consData.faq_id = item?.id ? item?.id : '';

      if (activeTab.short_name !== 'en') {
        delete consData.sequence_order;
        delete consData.publish;
      }
      addpayloadData.push(consData);
    });

    let moduledata = {
      lang: activeTab?.short_name,
      module_name: moduleName,
      faq_list: addpayloadData,
      sequence_order: moduleSequenceorder ? moduleSequenceorder : 0,
    };

    let responseObj = {};

    if (moduleId) {
      responseObj = await updateModule(
        moduledata,
        moduleId,
        activeTab.short_name
      );
    } else {
      responseObj = await addModule(moduledata);
    }
    setPageLoading(false);
    if (
      responseObj?.response?.code === 200 ||
      responseObj?.response?.code === 201
    ) {
      Toast.success(
        responseObj?.response?.code === 200
          ? Message.Faq.UPDATED_SUCCESS
          : Message.Faq.CREATED_SUCCESS
      );
      history.goBack();
    } else {
      Toast.error(
        responseObj?.data ? responseObj?.data : Message?.Error?.COMMON_MESSAGE
      );
    }
  };

  const handleEditorChange = (name, value, id, editor) => {
    // const charCount = (editor) => editor.value({ format: 'text' }).length;
    // let checkcount = charCount(editor);
    const charCount = editor.ops[0].retain;
    if (charCount > 500) {
      Toast.error('Answer Count must be under 500');
      return;
    }
    

    if (value) setisPublishButtonDisabled(false);
    const valueCpy = [...faqlistData];

    valueCpy.forEach((element, index) => {
      if (`${element.faq_index_Id}` === `${id}`) {
        if (element.id !== null) {
          element.wasEdited = true;
        }
        element[name] = value;
      }

      return element;
    });
    setfaqlistData([...valueCpy]);
  };
  const deleteFaq = async (id) => {
    if (!id) {
      return;
    }
    setPageLoading(true);
    const response = await deleteFaqAPI(id);
    setPageLoading(false);
    if (response?.response?.code === 200) {
      Toast.success(
        response?.data?.message
          ? response?.data?.message
          : Message?.Faq?.DELETE_SUCCESS
      );
      setpageRefresh(!pageRefresh);
    }
    if (response?.data?.error) {
      Toast.error(
        response?.data ? response?.data : Message?.Error?.COMMON_MESSAGE
      );
    }

    setpageRefresh(!pageRefresh);
  };
  const OnhandleChange = (e) => {
    const { name, value, id } = e.target;
    if (value) setisPublishButtonDisabled(false);
    const valueCpy = [...faqlistData];

    valueCpy.forEach((element, index) => {
      if (`${element.faq_index_Id}` === `${id}`) {
        if (element.id !== null) {
          element.wasEdited = true;
        }
        if (name === 'sequence_order') {
          element[name] = value && parseInt(value);
          return;
        }

        element[name] = value;
      }

      return element;
    });
    setfaqlistData([...valueCpy]);
  };
  const handleResourseToggle = (id, itemindex) => {
    setisPublishButtonDisabled(false);
    let newData = [...faqlistData];

    if (newData && newData.length > 0) {
      newData = newData.map((item, index) => {
        if (index === itemindex) {
          if (item.id !== null) {
            item.wasEdited = true;
          }
          item.toggle = !item.toggle;
        }
        return item;
      });
      setfaqlistData(newData);
    }
  };

  const createToggler = (item) => {
    setActiveId(item.faq_index_Id);
  };
  const onClickOutside = () => {
    setActiveId('');
  };

  const menuActionObj = [
    {
      name: 'Delete',
      onClick: (e, data, index) => {
        if(data.id === null){
          removefaqSection(index);
        }else{
          deleteFaq(data?.id);
        }
      },
    },
    {
      name: 'Not now',
      onClick: (e, data, index) => {
        onClickOutside();
      },
    },
  ];

  return {
    handleEditorChange,
    isPublishButtonDisabled,
    setisPublishButtonDisabled,
    pageLoading,
    OnhandleChange,
    addfaqSection,
    removefaqSection,
    count,
    faqlistData,
    validateRequest,
    deleteFaq,
    editorRef,
    moduleName,
    setModuleName,
    handleResourseToggle,
    activeId,
    createToggler,
    onClickOutside,
    menuActionObj,
    activeTab,
    handleTabChange,
    activeShow,
    moduleId,
  };
};

export default useCreateFaq;
