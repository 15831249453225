import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import Loader from 'components/Loader';
import NewTable from 'components/NewTable';
import PageLoader from 'components/PageLoader';

// Constants
import { APP_CONSTANTS } from 'constants/app-constants';

import {
  deleteSplashScreen,
  getSplashScreen,
  publishOrUnpublishSplashScreen,
} from 'app/api/splashscreen';

// Utils
import Message from 'utils/Message';
import Toast from 'utils/Toast';

const OnBoarding = ({ tableHeader }) => {
  useEffect(() => {
    getSplashScreen();
  }, []);

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [pageLoading, setPageLoading] = useState(false);

  const [feedSummary, setFeedSummary] = useState({
    totalUser: 0,
    inActiveUser: 0,
    activeUser: 0,
  });

  const loadNames = (start, end) => {
    setLoading(true);
    getSplashScreen(start, end).then((response) => {
      setLoading(false);
      if (response && response.tableData && response.error === false) {
        setData({
          tableData: response.tableData,
        });
        // setFeedSummary(response.summary);
      }
    });
  };

  const handleResponseCallback = (response) => {
    if (response && response.tableData && response.error === false) {
      setFeedSummary(response.summary);
    }
  };

  const callDeleteAPI = async (data) => {
    setPageLoading(true);
    if (data && data.id) {
      let responseObj = await deleteSplashScreen(data.id);
      setPageLoading(false);
      if (
        responseObj &&
        responseObj.response &&
        responseObj.response.code === 200
      ) {
        loadNames();
        Toast.success(Message.User.DELETE_SUCCESS);
      }
    } else {
      Toast.error(Message.User.DELETE_FAILURE);
    }
  };

  const callPublishOrUnpublishAPI = async (data) => {
    // console.log(data, 'articledata');
    setPageLoading(true);
    if (data && data.id && data.status) {
      let responseObj = await publishOrUnpublishSplashScreen(
        data.id,
        data.status
      );
      setPageLoading(false);
      if (
        responseObj &&
        responseObj.response &&
        responseObj.response.code === 200
      ) {
        loadNames();
        let message = Message.Feed.PUBLISH_UNPUBLISH_SUCCESS.replace(
          '{{placeholder}}',
          data.status.toLowerCase() === 'active' ? 'Unpublished' : 'Published'
        );
        Toast.success(message);
      }
    } else {
      let message = Message.Feed.PUBLISH_UNPUBLISH_FAILURE.replace(
        '{{placeholder}}',
        data.status.toLowerCase() === 'active' ? 'Unpublish' : 'Publish'
      );
      Toast.error(message);
    }
  };

  const menuActionObj = [
    {
      name: 'Edit',

      onClick: (e, data) => {
        // debugger;
        console.log('Edit Clicked: ', JSON.stringify(data));

        history.push({
          pathname: `/splash-screen/${data.id}`,
          state: {
            ...data,
          },
        });
      },
    },

    {
      name: 'Publish',
      type: 'publish',
      onClick: (e, data) => {
        console.log('Publish/Unpublish Clicked: ', data);
        callPublishOrUnpublishAPI(data);
      },
    },
    {
      name: 'Delete',
      onClick: (e, data) => {
        console.log('Delete Clicked: ', data);
        let text = 'are you sure,want to delete';
        if (window.confirm(text) === true) {
          callDeleteAPI(data);
          // text = 'You pressed OK!';
        } else {
          // text = 'You canceled!';
        }
      },
    },
  ];

  const sectionObj = {
    sectionTitle: 'Splash Screen',

    buttons: [
      {
        title: 'Create SplashScreen',
        handleBtnClick: (e) => {
          history.push(APP_CONSTANTS.SPLASH_SCREEN_CREATE);
        },
      },
    ],
  };

  if (loading === true) {
    return <Loader />;
  }

  return (
    <div className='blogs'>
      <NewTable
        sectionObj={sectionObj}
        tableHeader={tableHeader}
        menuAction={menuActionObj}
        data={data}
        getData={getSplashScreen}
        // searchKey='first_name'
        responseCallback={handleResponseCallback}
      />
      {pageLoading === true && <PageLoader pageLoading={pageLoading} />}
    </div>
  );
};

export default OnBoarding;
