

import { deleteGroupApi, getGroupsDataApi, groupPublishOrUnpublishAPI } from "app/api/cmsDiscoverApi";
import { updateGroup } from "app/api/createContentAPI";
import Button from "app/components/button";
import Search from "app/components/search";
// import Pagination from "app/container/CmsContent/pagination";
import Pagination from 'app/components/ReactPagination'
import SelectComp from "app/container/CmsContent/select-tag";
import Toast from "app/utils/Toast";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import "./index.scss";

const options = [
     // { value: 5, label: 5, },
     { value: 10, label: 10, },
     { value: 15, label: 15, },
     { value: 20, label: 20, },
     { value: 25, label: 25, },
]

const sortOptions = [
     { value: 'asthma_order', label: 'Asthma Order', },
     { value: 'copd_order', label: 'COPD Order', },
     { value: 'ild_order', label: 'ILD Order', },
     { value: 'ar_order', label: 'AR Order', },
     { value: 'other_order', label: 'Other Order', },
     { value: 'updateon', label: 'Updated On', },
]

const GroupTable = () => {
     const history = useHistory();
     const location = useLocation()
     const [dataCollection, setDataCollection] = useState([]);
     const [totalDataCollection, setTotalDataCollection] = useState([]);
     const [pageCount, setPageCount] = useState(1);
     const [dataPerPage, setDataPerPage] = useState(10);
     const [currentPage, setCurrentPage] = useState(location?.state?.goto || 1);
     const [searchValue, setSearchValue] = useState('')

     const indexOfLastData = currentPage * dataPerPage;
     const indexOfFirstData = indexOfLastData - dataPerPage;
     let currentData = dataCollection.slice(indexOfFirstData, indexOfLastData)

     useEffect(() => {
          handlesGetData()
     }, []);


     useEffect(() => {
          getDataHandler(searchValue)

     }, [searchValue]);


     function inputChangeHandler(searchKey) {
          setSearchValue(searchKey)
     }

     async function handlesGetData() {
          let data = await getGroupsDataApi();

          // let data = await getCmsSelectContent();
          let updatedData =[]
          data.forEach((item) => {  
            updatedData.push(  {
                isDisabled:true,
                ...item
            })
            
       });
          setDataCollection(updatedData)
          setTotalDataCollection(updatedData)
          let count = Math.ceil(data.length / dataPerPage);
        //   setDataCollection(data);

          setPageCount(count)
          // setDataCollection(data)
          // let count = Math.ceil(data.length / dataPerPage);
          // setDataCollection(data);
          // setPageCount(count)
     }

     async function getDataHandler(search) {
          let count;
          if (search !== "") {
               // const data = await getGroupsDataApi();
               let filteredData = []
               totalDataCollection.forEach((data) => {
                    if (data?.group_title?.toLowerCase().includes(search)) {
                         filteredData.push(data);
                    }
               });
               count = Math.ceil(filteredData.length / dataPerPage);
               setDataCollection(filteredData)
               setPageCount(count)
          } else {
               // handlesGetData();
               count = Math.ceil(totalDataCollection.length / dataPerPage);
               setDataCollection(totalDataCollection)
               setPageCount(count)
          }
     }

     // Pagination
     function paginationHandler(page) {
          setCurrentPage(page);
     }
     function changeHandler(event, key) {

          if(key){
               if(event.target.value === 'asthma_order'){
                    const numAscending = [...dataCollection].sort((a, b) => a.asthma_order - b.asthma_order);

                    setDataCollection(numAscending)
               }else if (event.target.value === 'copd_order'){
                    const numAscending = [...dataCollection].sort((a, b) => a.copd_order - b.copd_order);

                    setDataCollection(numAscending)
               }else if (event.target.value === 'ild_order'){
                    const numAscending = [...dataCollection].sort((a, b) => a.ild_order - b.ild_order);

                    setDataCollection(numAscending)
               }else if (event.target.value === 'ar_order'){
                    const numAscending = [...dataCollection].sort((a, b) => a.ar_order - b.ar_order);

                    setDataCollection(numAscending)
               }else if (event.target.value === 'other_order'){
                    const numAscending = [...dataCollection].sort((a, b) => a.other_order - b.other_order);

               setDataCollection(numAscending)
               }else if(event.target.value === 'updateon'){
                    setDataCollection(totalDataCollection)
               }
               
          }else{
               const pageLength = event.target.value
               setDataPerPage(pageLength);
               let count = Math.ceil(dataCollection.length / pageLength);
               setPageCount(count)
          }



         
     }

     const handleDelete = async (id) => {
          await deleteGroupApi(id)
          handlesGetData()
     }
     const publishOrUnpublish = async (data) => {
          const resp = await groupPublishOrUnpublishAPI(data.id, data.status)
          if (resp?.data?.length != 0) {
               Toast.success("Updated Successfully")
          }
          handlesGetData()
     }

     const handleInputChangeHandler = (key,e, index)  => {
          let tempData = []
          dataCollection.forEach((val, i) => {
               if(index === i ){
               let temp = val
               val[key] =   Number(e.target.value) ?  Number(e.target.value) : ''
               tempData.push(temp)
     
               }else{
                    tempData.push(val)
     
               }
          });
          setDataCollection(tempData) 
     
   }

   const handleEdit = async (index, key, data) => {
     if(key ==='Edit'){
         let tempData = []
         dataCollection.forEach((val, i) => {
             if(index === i ){
             let temp = val
             val['isDisabled'] = false
             tempData.push(temp)
             }else{
                 tempData.push(val)
             }
         });
         setDataCollection(tempData)
     }else {
         let {asthma_order, copd_order, ild_order, ar_order, other_order,group_title, id, group_type, collection_ids } = data
         let payload ={
             asthma_order:asthma_order?asthma_order:null, 
             copd_order:copd_order?copd_order:null, 
             ild_order:ild_order?ild_order:null, 
             ar_order:ar_order?ar_order:null, 
             other_order:other_order?other_order:null,
             group_id:id, 
             group_title,
             group_type,
             collection_ids:JSON.stringify(collection_ids)
         } 
     
        const  responseObj = await updateGroup(payload)
         
     
         if (
           responseObj &&
           responseObj.response &&
           responseObj.response.code === 200
       ) {
           Toast.success(responseObj.response.alert[0].message);
           let tempData = []
           dataCollection.forEach((val, i) => {
               if(index === i ){
               let temp = val
               val['isDisabled'] = true
               tempData.push(temp)
               }else{
                   tempData.push(val)
               }
           });
           setDataCollection(tempData)
           handlesGetData();
       } else {
           Toast.error(responseObj.data && responseObj.data.error?responseObj.data.error:responseObj.response.alert[0].message);
 
      }
        
     }
    
    }

    let pageMeta = {
          total : dataCollection,
          pageSize: Number(dataPerPage),
          page: currentPage,
          pageCount: pageCount
     }
     return (
          <div className="table_container">
               <h4>Groups of Collection & Collection</h4>
               <div className="table_container__actionBox">
                    <div className="table_container__filterSearch">
                         <Search
                              value={searchValue}
                              placeholder="Search"
                              className={""}
                              onChange={inputChangeHandler}
                         />
                    </div>
                    <Button onClick={() => history.push('/cms/group/create')} >Create Group of Collection</Button>
               </div>
               <div className="table_container__items_perPage">
                    Show
                    <SelectComp
                         options={options}
                         onChangeHandler={(e) => changeHandler(e)}
                    />
                    entries

                    &nbsp;&nbsp;&nbsp;&nbsp;
                    Sort by cohort ordering
                    <SelectComp
                         options={sortOptions}
                         selected={'updateon'}
                         // selectedTitle={'Update On'}
                         onChangeHandler={(e) => changeHandler(e, 'sort')}
                    />
               </div>

               <div className="table-box">
                    <table>
                         <tr>
                              <th className='text-center'>Group Name</th>
                              <th className='text-center'>Group Type</th>
                              <th className='text-center'>Published Date</th>
                              <th className='text-center'>Updated Date</th>
                              <th className='text-center'>Status</th>
                              {/* <th>Expired On</th> */}
                              <th className='text-center'>Action</th>
                              <th >Asthma Order</th>
                              <th>COPD Order</th>
                              <th>ILD Order</th>
                              <th>A R Order</th>
                              <th>Other Order</th>
                              <th>Edit Order</th>
                         </tr>
                         {currentData.length > 0 && currentData.map((data, index) => (<tr key={data.id}>
                              <td>{data.group_title || "-"}</td>
                              <td>{data.group_type || "-"}</td>
                              <td>{data.published_date || "-"}</td>
                              <td>{data.updated_on || "-"}</td>
                              <td>{data.status || "-"}</td>
                              {/* <td>{data.expired_on || "-"}</td> */}
                              <td>
                                   <div className="btn-group" role="group" aria-label="Basic outlined example">
                                        <button
                                             type="button"
                                             className="btn btn-outline-primary"
                                             onClick={() => {
                                                  history.push({
                                                       pathname: `/cms/group/edit/${data?.id}`,
                                                       state: {
                                                            ...data,
                                                            comingFrom: currentPage
                                                       },
                                                  });
                                             }}
                                        >
                                             Edit
                                        </button>
                                        <button type="button"
                                             onClick={() => handleDelete(data.id)}
                                             className="btn btn-outline-primary">Delete</button>
                                        <button onClick={() => publishOrUnpublish(data)} type="button" className="btn btn-outline-primary">{data.status === 'Published'? 'Unpublish': 'Publish'}</button>
                                   </div>
                              </td>
                              {/* <td>{data.asthma_order || "-"}</td>
                              <td>{data.copd_order || "-"}</td>
                              <td>{data.ild_order || "-"}</td>
                              <td>{data.ar_order || "-"}</td>
                              <td>{data.other_order || "-"}</td> */}
                              <td>
                                 <input
                                    type='text'
                                    className='form-input'
                                    placeholder=''
                                    name='asthma_order'
                                    value={data.asthma_order}
                                    onChange={(e) => handleInputChangeHandler('asthma_order',e, index )}
                                    disabled={data.isDisabled}
                                />
                              </td>
                              <td>
                                 <input
                                    type='text'
                                    className='form-input'
                                    placeholder=''
                                    name='copd_order'
                                    value={data.copd_order}
                                    onChange={(e) => handleInputChangeHandler('copd_order',e, index )}
                                    disabled={data.isDisabled}
                                />
                              </td>
                              <td>
                                 <input
                                    type='text'
                                    className='form-input'
                                    placeholder=''
                                    name='ild_order'
                                    value={data.ild_order}
                                    onChange={(e) => handleInputChangeHandler('ild_order',e, index )}
                                    disabled={data.isDisabled}
                                />
                              </td>
                              <td>
                                 <input
                                    type='text'
                                    className='form-input'
                                    placeholder=''
                                    name='ar_order'
                                    value={data.ar_order}
                                    onChange={(e) => handleInputChangeHandler('ar_order',e, index )}
                                    disabled={data.isDisabled}
                                />
                              </td>
                              <td>
                                 <input
                                    type='number'
                                    className='form-input'
                                    placeholder=''
                                    name='other_order'
                                    value={data.other_order}
                                    onChange={(e) => handleInputChangeHandler('other_order',e, index)}
                                    disabled={data.isDisabled}
                                />
                              </td>
                              <td>
                                   <div className="btn-group" role="group" aria-label="Basic outlined example">
                                   <button
                                             type="button"
                                             className="btn btn-outline-primary"
                                             onClick={() => { handleEdit(index, data.isDisabled ? 'Edit': 'Save', data)}}
                                             // disabled={!data.is_selected}
                                        >
                                             {data.isDisabled ? 'Edit': 'Save'}
                                        </button>
                                       
                                   </div>
                              </td>
                         </tr>))}
                         {/* {currentData.length ===0 && <tr><p>No data</p></tr>} */}
                    </table>
               </div>

               <div className="table_container__pagination">
                    <Pagination
                         pageMeta={pageMeta}
                         initialPage={Number(pageCount)}
                         handleChange={paginationHandler}
                    />
               </div>

          </div >);
}

export default GroupTable;