/* eslint-disable react-hooks/exhaustive-deps */
/* , useState */
import React, { useEffect } from 'react';
// import { useHistory, useLocation } from 'react-router';

// Components
// import NewInput from 'components/NewInput';
// import Button from 'components/button';
// import useQuizForm from 'components/useQuizForm';
// import validate from 'components/ValidateInfo';
// import PageLoader from 'components/PageLoader';
// import HeaderWithBackButton from 'components/HeaderWithBackButton';
import QuestionCard from 'components/QuestionCard';

// // API
// import { addOrUpdateQuiz, getQuizDetail } from 'api/quiz';

// Constants
/* APP_CONSTANTS, */
import { URLType } from 'constants/app-constants';

// // Utils
// import Toast from 'utils/Toast';
// import Message from 'utils/Message';
// import ValidationMessage from 'utils/ValidationMessage';

import './index.scss';

const CreateQuiz = ({
  quizData,
  handleChange,
  values,
  error,
  updateValue,
  onHandleChange,
  addQuizSection,
  removeQuizSection,
  onHandleOptionRadioClick,
  onHandleOptionLabelChanged,
  onHandleAddOptionClick,
  onHandleRemoveOption,
}) => {
  // const location = useLocation();
  // const history = useHistory();

  // const [pageLoading, setPageLoading] = useState(false);

  // const {
  //   handleChange,
  //   values,
  //   error,
  //   updateValue,
  //   onHandleChange,
  //   addQuizSection,
  //   removeQuizSection,
  //   onHandleOptionRadioClick,
  //   onHandleOptionLabelChanged,
  //   onHandleAddOptionClick,
  //   onHandleRemoveOption,
  // } = useQuizForm(validate);

  const parseQuizData = (data) => {
    if (!data) return;
    if (!data.quiz_id) return;

    let newData = {
      quizId: data.quiz_id,
      sectionTitle: data.section_title,
      questions: [],
    };

    if (data['section_1'] && data['section_1'].length > 0) {
      /* id: Date.now(), */
      data['section_1'].forEach((item, index) => {
        let qdata = {
          // id: index,
          id: `mcq_${index}`,
          questionId: item.question_id,
          questionType: item.question_type,
          question: item.question,
          isRequired: item.is_required,
          options: [],
          answer: '',
          dateTimeAnswer: '',
        };
        if (item.question_type === URLType.QUIZ_CHECKBOX) {
          qdata.questionType = URLType.CHECKBOX;
        }
        if (qdata.questionType === URLType.FILL) {
          qdata.answer = item.cms_answer;
        } else if (qdata.questionType === URLType.DATE_TIME) {
          qdata.dateTimeAnswer = item.cms_answer;
        } else if (qdata.questionType === URLType.MCQ) {
          item.options.forEach((optData, ind) => {
            qdata.options.push({
              id: `mcq_${index}_${ind}`,
              title: optData.title,
              isSelected: optData.cms_answer,
              isChecked: false,
            });
          });
        } else if (qdata.questionType === URLType.CHECKBOX) {
          item.options.forEach((optData, ind) => {
            qdata.options.push({
              id: `mcq_${index}_${ind}`,
              title: optData.title,
              isSelected: false,
              isChecked: optData.cms_answer,
            });
          });
        }
        newData.questions.push(qdata);
      });
    }
    updateValue(newData);
  };

  // const callQuizDetailAPI = async (quizId) => {
  //   if (!quizId) return;
  //   setPageLoading(true);
  //   const responseObj = await getQuizDetail(quizId);
  //   setPageLoading(false);
  //   if (
  //     responseObj &&
  //     responseObj.response &&
  //     responseObj.response.code === 200
  //   ) {
  //     let data = responseObj.data;
  //     parseQuizData({
  //       quiz_id: quizId,
  //       ...data,
  //     });
  //   }
  // };

  useEffect(() => {
    if (quizData && quizData.quiz_id) {
      parseQuizData(quizData);
    } else {
      addQuizSection();
    }
  }, [quizData]);

  // useEffect(() => {
  //   let data = location.state;
  //   if (data && data.quiz_id) {
  //     callQuizDetailAPI(data.quiz_id);
  //     // parseQuizData(data);
  //   } else {
  //     addQuizSection();
  //   }
  // }, []);

  // const populatePayload = () => {
  //   /* Populate Data */
  //   let { quizId, sectionTitle, questions } = values;

  //   // Validations Start
  //   if (!sectionTitle) {
  //     Toast.error(ValidationMessage.EMPTY_TITLE);
  //     return;
  //   }
  //   /* Validation End */

  //   let data = {
  //     section_title: sectionTitle ? sectionTitle : '',
  //     section_1: [],
  //   };

  //   if (quizId) {
  //     data.quiz_id = quizId;
  //   }

  //   let consQuestions = [];
  //   questions.forEach((item) => {
  //     const {
  //       questionId,
  //       questionType,
  //       question,
  //       isRequired,
  //       answer,
  //       dateTimeAnswer,
  //       options,
  //     } = item;
  //     let qdata = {
  //       question_id: questionId,
  //       question_type: questionType,
  //       question: question,
  //       is_required: isRequired,
  //       options: [],
  //     };
  //     if (questionType === URLType.FILL) {
  //       qdata.answer = answer;
  //     } else if (questionType === URLType.DATE_TIME) {
  //       const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
  //       qdata.answer = dateTimeAnswer.format(dateTimeFormat);
  //     } else if (questionType === URLType.MCQ) {
  //       if (options && options.length > 0) {
  //         options.forEach((option) => {
  //           qdata.options.push({
  //             title: option.title,
  //             answer: option.isSelected,
  //           });
  //         });
  //       }
  //     } else if (questionType === URLType.CHECKBOX) {
  //       if (options && options.length > 0) {
  //         options.forEach((option) => {
  //           qdata.options.push({
  //             title: option.title,
  //             answer: option.isChecked,
  //           });
  //         });
  //       }
  //     }
  //     consQuestions.push(qdata);
  //   });

  //   data['section_1'] = consQuestions;

  //   return data;
  // };

  // const callAddOrUpdateAPI = async () => {
  //   let data = populatePayload();

  //   if (!data) return;

  //   setPageLoading(true);
  //   const responseObj = await addOrUpdateQuiz(data);
  //   setPageLoading(false);
  //   if (
  //     responseObj &&
  //     responseObj.response &&
  //     responseObj.response.code === 200
  //   ) {
  //     Toast.success(Message.Feed.CREATED_SUCCESS);
  //     history.goBack();
  //   } else {
  //     Toast.error(Message.Feed.CREATE_FAILURE);
  //   }
  // };

  // const handleCreateQuiz = (e) => {
  //   callAddOrUpdateAPI();
  // };

  const handleAddQuizSection = () => {
    addQuizSection();
  };

  const handleRemoveQuizSection = (index) => {
    removeQuizSection(index);
  };

  return (
    <div className='quiz-wrapper'>
      {values &&
        values.questions &&
        values.questions.length > 0 &&
        values.questions.map((obj, index) => {
          return (
            <QuestionCard
              ind={index}
              index={obj.id}
              questionObj={obj}
              onHandleChange={onHandleChange}
              handleRemoveCard={handleRemoveQuizSection}
              onHandleOptionRadioClick={onHandleOptionRadioClick}
              onHandleOptionLabelChanged={onHandleOptionLabelChanged}
              onHandleAddOptionClick={onHandleAddOptionClick}
              onHandleRemoveOption={onHandleRemoveOption}
              onHandleAddQuizSection={handleAddQuizSection}
              showAddQuestion={
                index === values.questions.length - 1 ? true : false
              }
            />
          );
        })}

      {/* <div className='mt1'>
        <Button
          className='ml1'
          onClick={handleAddQuizSection}
          type='submit'
          name='Add Question'
        />
      </div> */}
    </div>
  );
};

export default CreateQuiz;
