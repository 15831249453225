// Ajax
import * as dataAccess from 'utils/ajax';
import Parsers from 'utils/Parsers';

// Constants
import { API_CONSTANTS } from 'constants/api-contants';

// Get Lists of Items
export const getUserRefillHistory = async () => {
  let url = `${API_CONSTANTS.UPDATE_USER_REFILL}`;
  let responseObj = await dataAccess.get(url);
  return responseObj;
};

// Create Item
export const updateUserRefill = async (incomingData) => {
    let formData = Parsers.ConvertObjectAsFormData(incomingData)
    let payload = {
        data: formData
    }

  return await dataAccess.upload(API_CONSTANTS.UPDATE_USER_REFILL, payload);
};
 