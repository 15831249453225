import * as dataAccess from 'utils/ajax';
import ApiParser from 'utils/ApiParser';
import Parsers from 'utils/Parsers';
import { API_CONSTANTS } from 'constants/api-contants';

export const getVersionControl = async (data) => {
  let url = `${API_CONSTANTS.VERSION_CONTROL_V2}?platform=${data}`;
  let responseObj = await dataAccess.get(url);
  return responseObj;
};

export const addOrUpdateVersionControl = async (incomingData) => {
  let data = {
    ...incomingData,
  };

  //   var formData = Parsers.ConvertObjectAsFormData(data);

  let payload = {
    data: data,
  };

  return await dataAccess.put(API_CONSTANTS.VERSION_CONTROL_V2, payload);
};
