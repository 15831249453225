// Ajax
import * as dataAccess from 'utils/ajax';
import ApiParser from 'utils/ApiParser';

// Constants
import { API_CONSTANTS } from 'constants/api-contants';
import Parsers from 'app/utils/Parsers';

// Get Lists of Items
export const getAbTesting = async () => {
  let responseObj = await dataAccess.get(API_CONSTANTS.GET_AB_TESTING);
  return ApiParser.parseAbTestingListing(responseObj);
};

// Create Item
export const addAbTesting = async (incomingData) => {
  let payload = {
    data: {
      ...incomingData,
    },
  };
  return await dataAccess.post(API_CONSTANTS.POST_AB_TESTING, payload);
};

// Update Item
export const updateAbTesting = async (incomingData, id) => {
  let payload = {
    data: incomingData,
    id: id,
  };
  let response = await dataAccess.put(
    API_CONSTANTS.UPDATE_AB_TESTING.concat(`/${id}`),
    payload
  );
  return response;
};

// Get Prefill Data
export const getAbTestingDetails = async (id) => {
  let responseObj = await dataAccess.get(
    `${API_CONSTANTS.GET_AB_TESTING_ID}/${id}`
  );
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data;
  } else {
    return [];
  }
};

// Get Product Family Services Dropdown List
export const getServices = async (servicesOptions) => {
  let payload = {
    data: {
      ...servicesOptions,
    },
  };
  return await dataAccess.get(
    API_CONSTANTS.GET_SERVICES_PRODUCT_FAMILY,
    payload
  );
};

export const getSelectedProductOptions = async (
  productType,
  servicesOptions
) => {
  let payload = {
    data: {
      ...servicesOptions,
    },
  };
  return await dataAccess.get(
    API_CONSTANTS.GET_PRODUCT_BASED_OPTIONS.concat(`?type=${productType}`),
    payload
  );
};
// Get App Version List
export const getVersionControl = async () => {
  let responseObj = await dataAccess.get(API_CONSTANTS.Get_VERSION_CONTROL);
  return responseObj;
};

export const uploadABTestingData = async (incomingData) => {
  let data = {
    ...incomingData,
  };

  var formData = Parsers.ConvertObjectAsFormData(data);

  let payload = {
    data: formData,
  };

  let url = API_CONSTANTS.POST_AB_TESTING_UPLOAD_DATA;

  if (!url) {
    console.log('Url Not exists for Upload master data');
    return;
  }

  let responseObj = await dataAccess.upload(url, payload);
  return responseObj;
};
