import React from 'react'
import BasePage from '../BasePage'
import MedicineReminderContainer from 'container/MedicineToggle/MedicineReminderContainer';

const MedicineReminder = () => {
  return (
    <BasePage>
    <MedicineReminderContainer/>
    </BasePage>
  );
}

export default MedicineReminder