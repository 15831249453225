import React from 'react';

// Pages
import BasePage from 'pages/BasePage';   
import CreateLandingScreen from 'app/container/Landingpage/CreateLandingscreen';

const CreateLanding = () => {
  return (
    <BasePage>
      <CreateLandingScreen />
    </BasePage>
  );
};

export default CreateLanding;
