import React from 'react';
import Router from './app/router';
import 'bootstrap/dist/css/bootstrap.min.css';

import { ToastContainer } from 'react-toastify/dist/react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.scss';
import { MyContextProvider } from 'app/context/MyContext';

function App() {
  return (
    <>
      <MyContextProvider>
        <Router />
        <ToastContainer newestOnTop={true} />
      </MyContextProvider>
    </>
  );
}

export default App;
