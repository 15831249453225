import { useEffect, useState } from 'react';
import Search from 'app/components/search';
// Components 
import Pagination from 'app/components/ReactPagination';
import { RefillHistoryHeader } from 'constants/table-header-constants';
import PageLoader from 'app/components/PageLoader';
import CustomTable from '../CustomTable';
import { getUserRefillHistory } from 'app/api/latest/UpdateUserRefill';

const HistoryContainer = () => {
  const [pageLoading, setPageLoading] = useState(false);
  const [values, setValues] = useState('');
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const dataPerPage = 10;

  useEffect(() => {
    getContentDataHandler();
  }, []);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  let currentData = data?.slice(indexOfFirstData, indexOfLastData);

  async function getContentDataHandler(val) {
    setPageLoading(true)
    const dataArr = await getUserRefillHistory();

    if (dataArr?.data?.response) {
      setTotalData(dataArr?.data?.response);
      setData(dataArr?.data?.response);
      let count = Math.ceil(dataArr?.data?.response?.length / dataPerPage);
      setPageCount(count);
    } else {
      setTotalData([])
      setData([])
      setPageCount(1)
    }

    setPageLoading(false);
  }

  function inputChangeHandler(searchVal) {
    if (searchVal.trim() === '') {
      setValues(searchVal);
      setData(totalData);
      return;
    }
    const filteredData = [];
    totalData.forEach((item) => {
      if (item.phone_number.includes(searchVal)) {
        filteredData.push(item);
      }
    });
    setData(filteredData);
    setValues(searchVal);
  }

  function paginationHandler(page) {
    setCurrentPage(page);
  }



  let pageMeta = {
    total: data,
    pageSize: Number(dataPerPage),
    page: currentPage,
    pageCount: pageCount
  }
  return (
    <>
      <div className="cms-content__container">
        <div className='content-container__actionBox'>
          <div className='content-container__filterSearch'>
            <Search
              value={values}
              placeholder='Mobile No or CSV file'
              className={''}
              onChange={inputChangeHandler}
            />
          </div>
        </div>

        {
          currentData && currentData?.length > 0 &&
          <div className='content-container__table'>
            <CustomTable
              data={currentData}
              tableHeader={RefillHistoryHeader}
            />
          </div>
        }

        <div className="content-container__pagination">
          <Pagination
            pageMeta={pageMeta}
            initialPage={Number(pageCount)}
            handleChange={paginationHandler}
          />
        </div>
        {pageLoading === true && <PageLoader pageLoading={pageLoading} />}
      </div>
    </>
  );
};

export default HistoryContainer;
