//apis for video, self hosted and external article

import * as dataAccess from 'utils/ajax';
import ApiParser, {
  parseCollectionIDs,
  parseCollectionIDStory,
  parseTags,
} from 'utils/ApiParser';
import Parsers from 'utils/Parsers';
import { API_CONSTANTS } from 'constants/api-contants';
import Toast from 'app/utils/Toast';

export const createContentAPI = async (incomingData) => {
  let formData = Parsers.ConvertObjectAsFormData(incomingData);
  let payload = {
    data: formData,
  };
  return await dataAccess.upload(API_CONSTANTS.POST_CONTENT_CMS, payload);
};

export const getTagsApi = async () => {
  let response = await dataAccess.get(API_CONSTANTS.GET_TAGS_CMS);
  return parseTags(response);
};

export const getContentFromId = async (id, lang) => {
  let response = await dataAccess.get(
    `${API_CONSTANTS.GET_CONTENT_CMS_VERSION2}/${id}?lang=${lang}`
  );
  return response;
};

export const updateContentAPI = async (incomingData, lang, id) => {
  let formData = Parsers.ConvertObjectAsFormData(incomingData);
  let payload = {
    data: formData,
  };
  let response = dataAccess.update(
    `${API_CONSTANTS.GET_CONTENT_CMS_VERSION2}/${id}?lang=${lang}`,
    payload
  );
  return response;
};

export const createTagsAPI = async (incomingData) => {
  let responseObj = await dataAccess.post(`${API_CONSTANTS.POST_CMS_TAGS}`, {
    data: incomingData,
  });
  return responseObj;
};

export const editTags = async (incomingData) => {
  let payload = {
    data: incomingData,
  };
  let responseObj = await dataAccess.put(
    `${API_CONSTANTS.POST_CMS_TAGS}`,
    payload
  );
  console.log(responseObj);
  return responseObj;
};

export const getCollectionsAPI = async () => {
  let responseObj = await dataAccess.get(API_CONSTANTS.GET_COLLECTIONS);
  return parseCollectionIDs(responseObj);
};
export const getCollectionsAPIforStory = async () => {
  let responseObj = await dataAccess.get(API_CONSTANTS.GET_CATEGORY_CARD);
  return responseObj.data;
};
export const postStories = async (payload) => {
  let responseObj = await dataAccess.post(`${API_CONSTANTS.POST_STORY_CMS}`, {
    data: payload,
  });
  return responseObj;
};
export const editStories = async (payload) => {
  let responseObj = await dataAccess.put(`${API_CONSTANTS.POST_STORY_CMS}`, {
    data: payload,
  });
  return responseObj;
};

export const postGroup = async (incomingData) => {
  let formData = Parsers.ConvertObjectAsFormData(incomingData);
  let payload = {
    data: formData,
  };
  return await dataAccess.upload(API_CONSTANTS.POST_GROUP_CMS, payload);
};

export const updateGroup = async (incomingData) => {
  let formData = Parsers.ConvertObjectAsFormData(incomingData);
  let payload = {
    data: formData,
  };
  let response = dataAccess.update(API_CONSTANTS.POST_GROUP_CMS, payload);
  return response;
};

// Bulk upload content
export const bulkUploadContentApi = async (file) => {
  // let formData = Parsers.ConvertObjectAsFormData(file);
  // let payload = {
  //     data: formData
  // }
  let fd = new FormData();
  fd.append('file', file);
  let responseObj = await dataAccess.upload(API_CONSTANTS.CONTENT_BULK_UPLOAD, {
    data: fd,
  });
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    Toast.success('Bulk File uploaded for Content');
    return responseObj?.data;
  } else {
    Toast.error('Something went wrong!');
    return responseObj?.data;
  }
};
// Bulk upload shorts
export const bulkUploadShortsApi = async (file) => {
  // let formData = Parsers.ConvertObjectAsFormData(file);
  // let payload = {
  //     file: formData
  // }
  let fd = new FormData();
  fd.append('file', file);
  let responseObj = await dataAccess.upload(API_CONSTANTS.SHORTS_BULK_UPLOAD, {
    data: fd,
  });
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    Toast.success('Bulk File Uploaded for Shorts');
    return responseObj?.data;
  } else {
    Toast.error('Something went wrong!');
    return responseObj?.data;
  }
};

export const getStoryLocalisationDetails = async (id, lang) => {
  let responseObj = await dataAccess.get(
    `${API_CONSTANTS.GET_STORY_CMS}/${id}?lang=${lang}`
  );
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data;
  } else {
    return [];
  }
};

export const updateLocalisationStories = async (payload, lang) => {
  let responseObj = await dataAccess.put(
    `${API_CONSTANTS.UPDATE_STORY_CMS}?lang=${lang}`,
    {
      data: payload,
    }
  );
  return responseObj;
};

export const getGroupLocalisationDetails = async (id, lang) => {
  let responseObj = await dataAccess.get(
    `${API_CONSTANTS.UPDATE_GROUP_CMS_VERSION}/${id}?lang=${lang}`
  );
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data;
  } else {
    return [];
  }
};

export const updateLocalisationGroup = async (incomingData, lang) => {
  let formData = Parsers.ConvertObjectAsFormData(incomingData);
  let payload = {
    data: formData,
  };
  let response = dataAccess.update(
    `${API_CONSTANTS.UPDATE_GROUP_CMS_VERSION}?lang=${lang}`,
    payload
  );
  return response;
};
