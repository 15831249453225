import CoursesVideoContainer from "app/container/CoursesCreationContainer/CoursesVideo";
import BasePage from "app/pages/BasePage";

const CoursesVideo = () => {
    return (
        <BasePage>
            <CoursesVideoContainer />
        </BasePage>
    );
}

export default CoursesVideo;