import React from 'react';

// Pages
import BasePage from 'pages/BasePage';
import CreateTaskContainer from 'container/UserJourney/CreateTaskContainer';

const CreateUserJourneyTask = () => {
  return (
    <BasePage>
      <CreateTaskContainer />
    </BasePage>
  );
};

export default CreateUserJourneyTask;
