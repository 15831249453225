import React from 'react';

// Pages
import BasePage from 'app/pages/BasePage';   
import PremiumExerciseContainer from 'app/container/Exercises/PremiumExercises';
import { PremiumExerciseHeader } from 'constants/table-header-constants';


const PremiumExercises = () => {
  return (
    <BasePage> 
        <PremiumExerciseContainer tableHeader={PremiumExerciseHeader}/>
    </BasePage>
  );
};

export default PremiumExercises;
