import { useEffect, useState } from 'react';

// Components

import { getVersionControl } from 'app/api/versionList';
import PageLoader from 'components/PageLoader';
import Button from 'components/button';
import Dropdown from 'shared/components/Dropdown';
// API
// import { getBannerTag } from 'app/api/banner';
import { addOrUpdateVersionControl } from 'app/api/versionList';
import DropdownOptions from 'utils/DropdownOptions';

// Utils
import NewInput from 'app/components/NewInput';
import Message from 'utils/Message';
import Toast from 'utils/Toast';
import './index.scss';
// import useVersionList from './useVersionList';

const VersionList = (props) => {
  let [chars, setChars] = useState([]);

  const [appVersion, setappVersion] = useState('Android');
  const [pageLoading, setPageLoading] = useState(false);
  const [values, setValues] = useState({});

  const handleCheckboxChange = () => {
    // Toggle the checkbox state
    setappVersion(appVersion === 'Android' ? 'IOS' : 'Android');
  };
  useEffect(() => {
    async function fetchVersion() {
      let temp = {};
      updateValue(temp);
      let response = await getVersionControl(appVersion);
      const data = Object(response.data);
      if (data) {
        let newData = {
          id: data?.id,
          latestProdVersion: data?.latest_prod_version,

          optional_update_criteria: data?.optional_update_criteria
            ? data?.optional_update_criteria
            : data?.ios_optional_update_criteria,
          force_update_criteria: data?.force_update_criteria
            ? data?.force_update_criteria
            : data?.ios_force_update_criteria,
          optional_update_selected_version:
            data?.optional_update_selected_version
              ? data?.optional_update_selected_version?.toFixed(1)
              : data?.ios_optional_update_selected_version?.toFixed(1),

          force_update_selected_version: data?.force_update_selected_version
            ? data?.force_update_selected_version?.toFixed(1)
            : data?.ios_force_update_selected_version?.toFixed(1),
        };
        updateValue(newData);
        // setId(newData.id);
        const data2 = data?.available_versions
          ? data?.available_versions
          : data?.ios_available_versions;

        let newState = data2?.map((key) => {
          return {
            value: [key][0].toFixed(1),
            label: [key][0].toString(),
          };
        });

        setChars(newState);
      }
    }

    fetchVersion();
  }, [appVersion]);

  const handleEventUpdate = (e) => {
    const data = { ...values };
    data[e.target.name] = e.target.value;
    setValues(data);
  };

  const populatePayload = () => {
    let data = {};
    if (appVersion === 'Android') {
      data = {
        platform: 'android', // "android"
        force_update_selected_version: values.force_update_selected_version, // this field is enable when "platform": "android"
        force_update_criteria: values.force_update_criteria, // this field is enable when "platform": "android"
        optional_update_selected_version:
          values.optional_update_selected_version, // this field is enable when "platform": "android"
        optional_update_criteria: values.optional_update_criteria,
      };
      return data;
    } else {
      data = {
        platform: 'ios', // "ios"
        ios_force_update_selected_version: values?.force_update_selected_version
          ? values?.force_update_selected_version
          : '', // this field is enable when "platform": "ios"
        ios_force_update_criteria: values.force_update_criteria, // this field is enable when "platform": "ios"
        ios_optional_update_selected_version:
          values.optional_update_selected_version
            ? values.optional_update_selected_version
            : '', // this field is enable when "platform": "ios"
        ios_optional_update_criteria: values.optional_update_criteria,
      };
    }

    return data;
  };

  const callAddOrUpdateAPI = async () => {
    // setPageLoading(true);
    let data = populatePayload();
    debugger;
    const responseObj = await addOrUpdateVersionControl(data);
    setPageLoading(false);
    if (
      responseObj &&
      responseObj.response &&
      responseObj.response.code === 200
    ) {
      Toast.success(Message.VersionControl.CREATED_SUCCESS);
      // history.goBack();
    } else {
      Toast.error(Message.VersionControl.CREATE_FAILURE);
    }
  };

  const handleCreateVersionList = (e) => {
    callAddOrUpdateAPI();
  };
  const updateValue = (obj) => {
    setValues({
      ...obj,
    });
  };
  //   const defaultOption = DropdownOptions.VersionRange[0];
  //   console.log(defaultOption, 'defaultOption');
  return (
    <div className='blogsForm'>
      <div className='blogsForm_content'>
        <h3>In App Update</h3>
        <div className='tab-switch'>
          <label>
            <input
              type='checkbox'
              checked={appVersion === 'Android' ? false : true}
              onChange={handleCheckboxChange}
            />
            <span>Android</span>
            <span>IOS</span>
          </label>
        </div>

        <div className='tab-content'>
          {/* <h2>{appVersion === 'Android' ? 'Android' : 'IOS'}</h2> */}
          <div className='prod-version'>
            <NewInput
              id=' '
              label='Latest Prod Version'
              type='text'
              placeholder='Version 2.0 (Non-editable)'
              name=' '
              value={values?.latestProdVersion}
              // onChange={handleFormInputChange}
              isDisabled={true}
            />
          </div>
          <h6 className='optional-text'>
            Select type of update (Either Optional or Forced or Both)
          </h6>
          {/* <Dropdown
                 // Tooltiptext='Select appropriate age group'
                 id={values.id}
                 label='Latest Prod Version'
                 placeholder='Version 2.0 (Non-editable)'
                 name='SelectedVersion'
                 value={values.selected_version}
                 options={chars}
                 handleChange={handleEventUpdate}
               /> */}
          <div className='update-wrapper'>
            <h4>Force update</h4>
            <h6>Select Forced Update Version</h6>
            <Dropdown
              // Tooltiptext='Select appropriate age group'
              id={values?.id}
              label='Selected Version'
              placeholder='Selected Version'
              name='force_update_selected_version'
              value={values?.force_update_selected_version}
              options={chars}
              handleChange={handleEventUpdate}
            />

            <Dropdown
              // Tooltiptext='Select appropriate age group'
              id={values?.id}
              label='Version Range'
              placeholder='Version Range'
              name='force_update_criteria'
              value={values?.force_update_criteria}
              options={DropdownOptions?.VersionRange}
              handleChange={handleEventUpdate}
            />
          </div>
          <div className='update-wrapper'>
            <h4>Optional/Flexible</h4>
            <Dropdown
              // Tooltiptext='Select appropriate age group'
              id={values?.id}
              label='Selected Version'
              placeholder='Selected Version'
              name='optional_update_selected_version'
              value={values?.optional_update_selected_version}
              options={chars}
              handleChange={handleEventUpdate}
            />
            <Dropdown
              // Tooltiptext='Select appropriate age group'
              id={values.id}
              label='Version Range'
              placeholder='Version Range'
              name='optional_update_criteria'
              value={values?.optional_update_criteria}
              options={DropdownOptions?.VersionRange}
              handleChange={handleEventUpdate}
            />
          </div>
        </div>

        <div className='mt1 text-center'>
          <Button
            onClick={handleCreateVersionList}
            type='submit'
            name='Submit'
          />
        </div>
      </div>
      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </div>
  );
};

export default VersionList;
