import React from 'react';
import BasePage from 'pages/BasePage';
import LanguageMaster from 'app/container/LanguageMaster';

const LanguageMasterPage = () => {
  return (
    <>
      <BasePage>
        <LanguageMaster></LanguageMaster>
      </BasePage>
    </>
  );
};

export default LanguageMasterPage;
