export const customObjectOptions = [
    {
         label: "PulmoRehab",
         id: "1",
         value: 'PulmoRehab'
    }, 
    {
      label: "Exercise",
      id: "2",
      value: 'Exercise'
    }, 
    {
        label: 'SafeyPurchase',
        id: '3',
        value: 'SafeyPurchase'
      }
    
  ]