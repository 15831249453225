const Feed = {
  CREATED_SUCCESS: 'The feed has been created successfully',
  UPDATED_SUCCESS: 'The feed has been updated successfully',
  CREATE_FAILURE: 'Unable to post a feed. Please try again',
  NO_DATA_FOUND: 'No data found',
  DELETE_SUCCESS: 'The feed has been deleted successfully',
  DELETE_FAILURE: 'Unable to delete a feed. Please try again',
  PUBLISH_UNPUBLISH_SUCCESS:
    'The article has been {{placeholder}} successfully',
  PUBLISH_UNPUBLISH_FAILURE:
    'Unable to {{placeholder}} a feed. Please try again',
};

const Breathefree = {
  CREATED_SUCCESS:
    'The Breathefree Educator Video has been created successfully',
  UPDATED_SUCCESS:
    'The Breathefree Educator Video has been updated successfully',
  CREATE_FAILURE:
    'Unable to post a Breathefree Educator Video. Please try again',
  NO_DATA_FOUND: 'No data found',
  DELETE_SUCCESS:
    'The Breathefree Educator Video has been deleted successfully',
  DELETE_FAILURE:
    'Unable to delete a Breathefree Educator Video. Please try again',
  PUBLISH_UNPUBLISH_SUCCESS:
    'The Breathefree Educator Video has been {{placeholder}} successfully',
  PUBLISH_UNPUBLISH_FAILURE:
    'Unable to {{placeholder}} a feed. Please try again',
};

const Habit = {
  CREATED_SUCCESS: 'The Habit has been created successfully',
  UPDATED_SUCCESS: 'The Habit has been updated successfully',
  CREATE_FAILURE: 'Unable to post a Habit. Please try again',
  NO_DATA_FOUND: 'No data found',
  DELETE_SUCCESS: 'The Habit has been deleted successfully',
  DELETE_FAILURE: 'Unable to delete a Habit. Please try again',
  PUBLISH_UNPUBLISH_SUCCESS: 'The Habit has been {{placeholder}} successfully',
  PUBLISH_UNPUBLISH_FAILURE:
    'Unable to {{placeholder}} a habit. Please try again',
};

const SplashScreen = {
  CREATED_SUCCESS: 'The SplashScreen Video has been created successfully',
  UPDATED_SUCCESS: 'The SplashScreen  Video has been updated successfully',
  CREATE_FAILURE: 'Unable to post a SplashScreen  Video. Please try again',
  NO_DATA_FOUND: 'No data found',
  DELETE_SUCCESS: 'The SplashScreen  Video has been deleted successfully',
  DELETE_FAILURE: 'Unable to delete a SplashScreen  Video. Please try again',
  PUBLISH_UNPUBLISH_SUCCESS:
    'The SplashScreen  Video has been {{placeholder}} successfully',
  PUBLISH_UNPUBLISH_FAILURE:
    'Unable to {{placeholder}} a SplashScreen. Please try again',
};

const Celebrity = {
  CREATED_SUCCESS: 'The Celebrity Video has been created successfully',
  UPDATED_SUCCESS: 'The Celebrity  Video has been updated successfully',
  CREATE_FAILURE: 'Unable to post a Celebrity  Video. Please try again',
  NO_DATA_FOUND: 'No data found',
  DELETE_SUCCESS: 'The Celebrity  Video has been deleted successfully',
  DELETE_FAILURE: 'Unable to delete a Celebrity  Video. Please try again',
  PUBLISH_UNPUBLISH_SUCCESS:
    'The Celebrity  Video has been {{placeholder}} successfully',
  PUBLISH_UNPUBLISH_FAILURE:
    'Unable to {{placeholder}} a Celebrity. Please try again',
};

const UnderStandingDevice = {
  CREATED_SUCCESS:
    'The UnderStandingDevice Video has been created successfully',
  UPDATED_SUCCESS:
    'The UnderStandingDevice  Video has been updated successfully',
  CREATE_FAILURE:
    'Unable to post a UnderStandingDevice  Video. Please try again',
  NO_DATA_FOUND: 'No data found',
  DELETE_SUCCESS:
    'The UnderStandingDevice  Video has been deleted successfully',
  DELETE_FAILURE:
    'Unable to delete a UnderStandingDevice  Video. Please try again',
  PUBLISH_UNPUBLISH_SUCCESS:
    'The UnderStandingDevice  Video has been {{placeholder}} successfully',
  PUBLISH_UNPUBLISH_FAILURE:
    'Unable to {{placeholder}} a UnderStandingDevice. Please try again',
};

const DidYouKnow = {
  CREATED_SUCCESS: 'The DidYouKnow Video has been created successfully',
  UPDATED_SUCCESS: 'The DidYouKnow  Video has been updated successfully',
  CREATE_FAILURE: 'Unable to post a DidYouKnow  Video. Please try again',
  NO_DATA_FOUND: 'No data found',
  DELETE_SUCCESS: 'The DidYouKnow  Video has been deleted successfully',
  DELETE_FAILURE: 'Unable to delete a DidYouKnow  Video. Please try again',
  PUBLISH_UNPUBLISH_SUCCESS:
    'The DidYouKnow  Video has been {{placeholder}} successfully',
  PUBLISH_UNPUBLISH_FAILURE:
    'Unable to {{placeholder}} a DidYouKnow. Please try again',
};

const Banner = {
  CREATED_SUCCESS: 'The Banner has been created successfully',
  UPDATED_SUCCESS: 'The Banner has been updated successfully',
  CREATE_FAILURE: 'Unable to post a Banner. Please try again',
  DELETE_SUCCESS: 'The Banner has been deleted successfully',
  DELETE_FAILURE: 'Unable to delete a Banner. Please try again',
  PUBLISH_UNPUBLISH_SUCCESS:
    'The article has been {{placeholder}} successfully',
  PUBLISH_UNPUBLISH_FAILURE:
    'Unable to {{placeholder}} a Banner. Please try again',
};

const Services = {
  CREATED_SUCCESS: 'The Services has been created successfully',
  UPDATED_SUCCESS: 'The Services has been updated successfully',
  UPDATED_FAILURE: 'Unable to update a Services. Please try again',
  CREATE_FAILURE: 'Unable to post a Services. Please try again',
  DELETE_SUCCESS: 'The Services has been deleted successfully',
  DELETE_FAILURE: 'Unable to delete a Services. Please try again',
  PUBLISH_UNPUBLISH_SUCCESS:
    'The Services has been {{placeholder}} successfully',
  PUBLISH_UNPUBLISH_FAILURE:
    'Unable to {{placeholder}} a Services. Please try again',
};

const Widgets = {
  CREATED_SUCCESS: 'The Widgets has been created successfully',
  UPDATED_SUCCESS: 'The Widgets has been updated successfully',
  CREATE_FAILURE: 'Unable to post a Widgets. Please try again',
  DELETE_SUCCESS: 'The Widgets has been deleted successfully',
  DELETE_FAILURE: 'Unable to delete a Widgets. Please try again',
  PUBLISH_UNPUBLISH_SUCCESS:
    'The article has been {{placeholder}} successfully',
  PUBLISH_UNPUBLISH_FAILURE:
    'Unable to {{placeholder}} a Widgets. Please try again',
};

const Webinar = {
  CREATED_SUCCESS: 'The Webinar has been created successfully',
  UPDATED_SUCCESS: 'The Webinar has been updated successfully',
  CREATE_FAILURE: 'Unable to post a Webinar. Please try again',
  DELETE_SUCCESS: 'The Webinar has been deleted successfully',
  DELETE_FAILURE: 'Unable to delete a Webinar. Please try again',
  PUBLISH_UNPUBLISH_SUCCESS:
    'The webinar has been {{placeholder}} successfully',
  PUBLISH_UNPUBLISH_FAILURE:
    'Unable to {{placeholder}} a Webinar. Please try again',
  SPEAKER_DELETE_SUCCESS: 'The speaker has been deleted successfully',
  SPEAKER_DELETE_FAILURE: 'please try again',
  HOST_DELETE_SUCCESS: 'The host has been deleted successfully',
  HOST_DELETE_FAILURE: 'please try again',
};

const Challenges = {
  CREATED_SUCCESS: 'The Challenge has been created successfully',
  UPDATED_SUCCESS: 'The Challenge has been updated successfully',
  CREATE_FAILURE: 'Unable to post a Challenge. Please try again',
  DELETE_SUCCESS: 'The Challenge has been deleted successfully',
  DELETE_FAILURE: 'Unable to delete a Challenge. Please try again',
  PUBLISH_UNPUBLISH_SUCCESS:
    'The Challenge has been {{placeholder}} successfully',
  PUBLISH_UNPUBLISH_FAILURE:
    'Unable to {{placeholder}} a feed. Please try again',
  ERROR:
    'Please fill challenge type, title, description, points and bonus points',
  FREQUENCY_ERROR:
    'Please select days. It cannot be left empty as you selected the challenge is YES',
};

const Activities = {
  UPDATE_SUCCESS: 'The Activity point has been updated successfully',
  UPDATE_FAILURE: 'Unable to update a Activity Point. Please try again',
  ERROR: 'Please fill the activity points',
};

const Static = {
  CREATED_SUCCESS: 'The content has been created successfully',
  UPDATED_SUCCESS: 'The content has been updated successfully',
  CREATE_FAILURE: 'Unable to post a content. Please try again',
  DELETE_SUCCESS: 'The content has been deleted successfully',
  DELETE_FAILURE: 'Unable to delete a content. Please try again',
  PUBLISH_UNPUBLISH_SUCCESS:
    'The content has been {{placeholder}} successfully',
  PUBLISH_UNPUBLISH_FAILURE:
    'Unable to {{placeholder}} a content. Please try again',
};

const Faq = {
  MODULE_DELETE_SUCCESS: 'The FAQ Module has been deleted successfully',
  MODULE_DELETE_FAILURE: 'The FAQ Module has not been deleted',
  CREATED_SUCCESS: 'The FAQ has been created successfully',
  UPDATED_SUCCESS: 'The FAQ has been updated successfully',
  CREATE_FAILURE: 'Unable to post a FAQ. Please try again',
  DELETE_SUCCESS: 'The FAQ has been deleted successfully',
  DELETE_FAILURE: 'Unable to delete a FAQ. Please try again',
  PUBLISH_UNPUBLISH_SUCCESS: 'The FAQ has been {{placeholder}} successfully',
  PUBLISH_UNPUBLISH_FAILURE:
    'Unable to {{placeholder}} a feed. Please try again',
};
const Coins = {
  CONVERSION_CREATE_SUCCESS:
    'The Coins conversion has been created successfully',
  CONVERSION_UPDATE_SUCCESS:
    'The Coins conversion has been updated successfully',

  CREATED_SUCCESS: 'The Coins has been created successfully',
  UPDATED_SUCCESS: 'The Coins has been updated successfully',
  CREATE_FAILURE: 'Unable to post a Coins. Please try again',
  DELETE_SUCCESS: 'The Coins has been deleted successfully',
  DELETE_FAILURE: 'Unable to delete a Coins. Please try again',
  PUBLISH_UNPUBLISH_SUCCESS: 'The Coins has been {{placeholder}} successfully',
  PUBLISH_UNPUBLISH_FAILURE:
    'Unable to {{placeholder}} a feed. Please try again',
};

const Language = {
  CREATED_SUCCESS: 'The Language has been created successfully',
  UPDATED_SUCCESS: 'The Language has been updated successfully',
  CREATE_FAILURE: 'Unable to post a Language. Please try again',
  DELETE_SUCCESS: 'The Language has been deleted successfully',
  DELETE_FAILURE: 'Unable to delete a Language. Please try again',
};

const CouponAndOffer = {
  CREATED_SUCCESS: 'The Coupons & offers has been Created successfully',
  UPDATED_SUCCESS: 'The Coupons & offers has been Updated successfully',
  UPDATE_FAILURE:
    'Something went wrong the Coupons & offers not upadated. Please try again',
  CREATE_FAILURE:
    'Something went wrong the Coupons & offers not created. Please try again',
};

const ProductFamily = {
  CREATED_SUCCESS: 'The Product Family has been Created successfully',
  UPDATED_SUCCESS: 'The Product Family has been Upadated successfully',
};

const Courses = {
  COURSES_CREATED_SUCCESS: 'The Courses has been Created successfully',
  COURSES_UPDATED_SUCCESS: 'The Courses has been Upadated successfully',

  CHAPTER_CREATED_SUCCESS: 'The Chapter has been Created successfully',
  CHAPTER_UPDATED_SUCCESS: 'The Chapter has been Upadated successfully',
};

const User = {
  CREATED_SUCCESS: 'The user has been created successfully',
  UPDATED_SUCCESS: 'The user details has been updated successfully',
  CREATE_FAILURE: 'Unable to create a user. Please try again',
  DELETE_SUCCESS: 'The user account has been deleted successfully',
  DELETE_FAILURE: 'Unable to delete a user. Please try again',
  ACTIVE_INACTIVE_SUCCESS:
    'The user account has been made {{placeholder}} successfully',
  ACTIVE_INACTIVE_FAILURE:
    'Unable to make the user as {{placeholder}}. Please try again',
};

const video = {
  VIDEO_SUCCESS: 'Video content created Successfully',
  VIDEO_FAIL: 'Failed to create content',
};
const External = {
  EXTERNAL_SUCCESS: 'Content Created Successfully',
  EXTERNAL_FAIL: 'Unable to create content',
};

const Login = {
  LOGIN_SUCCESS: 'Logged in successfully',
  LOGIN_FAILURE:
    'Unable to login. This could be due invalid credential. Please try again',
};

const Password = {
  UPDATE_SUCCESS: 'The password has been updated successfully',
  CHECK_EMAIL: 'Check your Email. Reset password link will be there',
  UPDATE_FAILURE: 'Unable to update the password. Please try again',
};

const MasterData = {
  UPLOAD_SUCCESS:
    'The {{placeholder}} master data has been updated successfully',
  UPLOAD_FAILURE: 'Unable to update a master data. Please try again',
};

const Error = {
  COMMON_MESSAGE:
    'We’re so sorry. Looks like something went wrong. Please try again.',
};

const VersionControl = {
  CREATED_SUCCESS: 'The versionControl has been created successfully',
  UPDATED_SUCCESS: 'The versionControl has been updated successfully',
  CREATE_FAILURE: 'Unable to create a versionControl. Please try again',
  DELETE_SUCCESS: 'The versionControl has been deleted successfully',
  DELETE_FAILURE: 'Unable to delete a versionControl. Please try again',
  ACTIVE_INACTIVE_SUCCESS:
    'The versionControl account has been made {{placeholder}} successfully',
  ACTIVE_INACTIVE_FAILURE:
    'Unable to make the versionControl as {{placeholder}}. Please try again',
};

const SuccessStory = {
  CREATED_SUCCESS: 'The SuccessStory has been created successfully',
  UPDATED_SUCCESS: 'The SuccessStory has been updated successfully',
  CREATE_FAILURE: 'Unable to post a SuccessStory. Please try again',
  DELETE_SUCCESS: 'The SuccessStory has been deleted successfully',
  DELETE_FAILURE: 'Unable to delete a SuccessStory. Please try again',
};

const Message = {
  Services,
  Widgets,
  Login,
  Feed,
  Banner,
  Webinar,
  Breathefree,
  SplashScreen,
  Celebrity,
  DidYouKnow,
  UnderStandingDevice,
  Faq,
  Static,
  Challenges,
  Activities,
  Error,
  User,
  Password,
  Habit,
  MasterData,
  video,
  External,
  VersionControl,
  CouponAndOffer,
  Coins,
  ProductFamily,
  Courses,
  Language,
  SuccessStory,
};

export default Message;
