import WebinarAttachment from 'app/components/WebinarAttachment';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
// Components
import NewInput from 'components/NewInput';

// Shared Components
import RadioButton from 'shared/components/RadioButton';

// Constants
import { URLType } from 'constants/app-constants';

// Utils

const CTAWebinarComponent = ({
  urlType,
  value = null,
  onChange,
  id,
  ctaValue,
  // OnhandleWebinar,
  attachmentFile,
  onVideoThumbnail = null,
  videoThumbnail = null,
  handleURLChange,
  externalUrl,
  contentid,
  isHide,
  onWebinarRecording,
  onFileSelected,
  webinar_record_link,
  index,
}) => {
  const [type, setType] = useState('');
  const [externalUrlVal, setExternalUrl] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      url: 'https://',
    },
  });

  useEffect(() => {
    if (urlType) {
      setType(urlType);
    }
  }, [urlType]);

  useEffect(() => {
    if (externalUrl) {
      setExternalUrl(externalUrl);
    }
  }, [externalUrl]);

  const isRadioButtonSelected = (data) => {
    if (type && type.toUpperCase() === data) {
      return true;
    }
    return false;
  };

  const handleRadioButtonClick = (e) => {
    onChange &&
      onChange({
        target: {
          id: e.target.id,
          name: 'urlType',
          value: e.target.value,
        },
      });
  };

  // const handleURLChange = (e) => {
  //   console.log(e, 'yhf');
  //   setExternalUrl(e.target.value);
  //   onChange &&
  //     onChange({
  //       // target: {
  //       //   id: e.target.id,
  //       //   name: 'externalUrl',
  //       //   value: e.target.value,
  //       // },
  //     });
  // };

  let isExternalChecked = isRadioButtonSelected(URLType.EXTERNAL);
  let isInternalChecked = isRadioButtonSelected(URLType.INTERNAL);
  // let isNoCTA = isRadioButtonSelected(URLType.NO);

  return (
    <div className='radio' id={contentid}>
      <div className='radio_container'>
        {/* <RadioButton
          id={id ? id : 'article_no'}
          label='No URL'
          isChecked={isNoCTA}
          value={URLType.NO}
          onSelect={handleRadioButtonClick}
          name={`radio_${index}`}
        /> */}
        <RadioButton
          id={id ? id : 'article_external'}
          label='Add External URL'
          isChecked={isExternalChecked}
          value={URLType.EXTERNAL}
          onSelect={handleRadioButtonClick}
          name={`radio_${index}`}
        />
        <RadioButton
          id={id ? id : 'article_internal'}
          onSelect={handleRadioButtonClick}
          label='Add an webinar attachment'
          isChecked={isInternalChecked}
          value={URLType.INTERNAL}
          name={`radio_${index}`}
        />
      </div>

      {type !== URLType.NO && (
        <div>
          {type === URLType.EXTERNAL && (
            <div>
              <NewInput
                label='Webinar Record Link'
                id='externalUrl'
                name='webinar_record_link'
                // value={webinar_record_link}
                value={externalUrlVal}
                placeholder='Ex: https://www.cipla.com'
                className='form-block'
                type='url'
                onChange={handleURLChange}
                // Tooltiptext='This is a screen where the user will be taken on tap'
              />
            </div>
          )}
          {type === URLType.INTERNAL && (
            <WebinarAttachment
              onFileSelected={onWebinarRecording}
              value={value ? value : null}
              onVideoThumbnail={onVideoThumbnail ? onVideoThumbnail : null}
              videoThumbnail={videoThumbnail}
              className='attachment__selimage'
              label='add an Webinar past Video'
            />
          )}
        </div>
      )}
    </div>
  );
};

export default CTAWebinarComponent;
