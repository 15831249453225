// Components

import PageLoader from 'components/PageLoader';
import validate from 'components/ValidateInfo';
import Button from 'components/button';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'shared/components/Dropdown';

import FilePreview from 'app/components/NewDesign/FilePreview';
import NewAttachment from 'app/components/NewDesign/NewAttachment';
import WidgetCard from 'app/components/WidgetCard';
import DropdownOptions from 'app/utils/DropdownOptions';
import CTAWidgetComponent from 'components/NewDesign/CTAWidgetComponent';
import Toggle from 'react-toggle';
import './index.scss';
import useContentHomepageWidgetForm from './useContentHomepageWidgetForm';

const CreateWidget = (props) => {
  const {
    sortedList,
    pageLoading,
    show,
    OnhandleChange,
    listData,
    handleCreateServices,
    onFileSelected,
    validateRequestpreviewScreen,
    handleClose,
    toggled,
    openUp,
    isPublishButtonDisabled,
    isAddButtonDisabled,
    addWidgetSection,
  } = useContentHomepageWidgetForm({ validate });

  return (
    <div className='blogsForm'>
      <div className='blogsForm_content'>
        <h1>
          Breathefree Service Homepage Widget{' '}
          <Toggle checked={toggled} icons={false} onChange={openUp} />
        </h1>

        {listData.map((data, index) => {
          return (
            <WidgetCard
              key={data.id}
              title={data.title}
              index={index}
              type={'Widget'}
            >
              <div className='widget_box'>
                <div>
                  <NewAttachment
                    value={data?.attachment}
                    id={data.id}
                    onFileSelected={onFileSelected}
                    fileType='image'
                    label={`Add an attachment`}
                    className='widget_box_feed__attachment'
                    // className='Banner_size'
                  />
                  <Dropdown
                    id={data.id}
                    label='Display order'
                    name='displayOrder'
                    options={DropdownOptions?.WidgetDisplayOrderOptions}
                    placeholder='Enter display Order 1/2/3'
                    value={data.displayOrder}
                    handleChange={OnhandleChange}
                  />
                </div>
                <div className='widget_box_divide'></div>
                <CTAWidgetComponent
                  DropdownOptions={DropdownOptions.InternalUrl}
                  id={data.id}
                  index={data.id}
                  externalUrl={data.externalUrl}
                  ctaValue={data.title}
                  urlType={data.urlType}
                  value={data.url}
                  isHide={true}
                  onChange={OnhandleChange}
                  swappingOrder={data.swappingOrder}
                  redirection_id={
                    data.redirection_id ? data.redirection_id : null
                  }
                  // value={data.eventName ? data.eventName : null}
                  OnhandleChange={OnhandleChange}
                  urlCopy={data.urlCopy}
                  redirection_idURL={data.redirection_idURL}
                />
              </div>
            </WidgetCard>
          );
        })}

        <div
          className={`widget-button-section ${
            isAddButtonDisabled ? 'isAddButtonDisabledtrue' : ''
          }`}
        >
          <div className='section-header__right'>
            <span> {isAddButtonDisabled && ' Can add upto 2 Widgets'}</span>

            <Button
              className='addbutton mr2'
              onClick={addWidgetSection}
              type='submit'
              name='Add Widget'
              isDisabled={isAddButtonDisabled}
            />

            <Button
              onClick={validateRequestpreviewScreen}
              type='submit'
              name='Publish'
              isDisabled={isPublishButtonDisabled}
              // cl
            />
          </div>
        </div>
      </div>
      {pageLoading && <PageLoader pageLoading={pageLoading} />}
      <Modal show={show} onHide={handleClose} className='servicemodel'>
        <Modal.Body className='modal-body'>
          <div className='model-body-section'>
            <div className='model-body-section-list'>
              {sortedList.map((data, index) => {
                return (
                  <div key={index}>
                    {data?.attachmentFile ? (
                      <FilePreview data={data} />
                    ) : data?.attachment ? (
                      <div>
                        <img
                          alt='banner'
                          className={`${
                            data.fileType === 'gif'
                              ? 'file_view_image file_view_gif'
                              : 'file_view_image'
                          }`}
                          src={`${process.env.REACT_APP_S3_BUCKET_URL}/${data?.attachment}`}
                        />
                      </div>
                    ) : (
                      ''()
                    )}
                    <div className='service_top'>
                      {data?.title && (
                        <div className='service_top_title'>{data?.title}</div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className='model-footer'>
          <div className='model-footer-section'>
            Are you sure to publish the preview to live app ?
            <div className='model-button-section'>
              <Button variant='primary' onClick={handleClose}>
                Cancel
              </Button>
              <Button variant='secondary' onClick={handleCreateServices}>
                Confirm Publish
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </div>
  );
};

export default CreateWidget;
