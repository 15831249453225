
function UploadedShorts({ ativeSlides, onRemoveMedia }) {
     
     return (<>
          {ativeSlides?.slides?.length > 0 &&
               <table>

                    <thead>
                    <tr>
                         <th>Id</th>
                         <th>Media</th>
                         <th>Duration</th>
                         <th>Description</th>
                    </tr>
                    </thead>
                    {ativeSlides?.slides?.map((file) => {
                         console.log(file.media?.split(".")?.[1]) 
                         let isVideo = false
                         if(file.media?.split(".")?.[1] === 'mp4'){
                              isVideo = true
                         }
                         return (
                              <tbody key={file.slide}>
                              <tr >
                              <td>{file.slide}</td>
                              <td>
                                   
                                   {/* {file.media} */}
                                   {isVideo ? 
                                    <div className='attachment_img_wrapper'>
                                        <video className='attachment__selimage' controls>
                                        <source src={`${process.env.REACT_APP_S3_BUCKET_URL}/${file.media}`} type='video/mp4' />
                                        </video>
                                   
                                   </div>
                                   :
                                   <div className='attachment_img_wrapper'>
                                        <img src={`${process.env.REACT_APP_S3_BUCKET_URL}/${file.media}`} alt='sample' className='feed__attachment' />
                                   
                                   </div>
                    }
                              </td>
                              <td>{file.duration}</td>
                              <td>{file.description}</td>
                         </tr>
                         </tbody>)
                    })}
               </table>
          }
     </>)
}

export default UploadedShorts;