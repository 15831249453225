import { useState } from 'react';

// Constants
import AltTag from 'constants/alt-tag';
import { APP_CONSTANTS } from 'constants/app-constants';
import { AppLogoImage } from 'constants/image-constants';

// Utils
import Message from 'utils/Message';
import Toast from 'utils/Toast';

// Components
import NewInput from 'components/NewInput';
import Button from 'components/button';

// API
import { resetPassword } from 'api/onboarding';

import './index.scss';

const ForgotPasswordPage = () => {
  const [userName, setUserName] = useState('');

  const [userNameStatus, setUserNameStatus] = useState();

  const [loading, setLoading] = useState(false);

  const formValidate = async () => {
    if (loading === true) {
      return;
    }

    if (!userName || userName === undefined) {
      setUserNameStatus('Invalid email!');
    } else {
      setUserNameStatus('');
    }

    const payload = {
      email: userName,
      // password: password,
    };

    if (!userName) {
      return;
    }

    setLoading(true);
    // debugger;
    resetPassword(payload, (response) => {
      setLoading(false);
      if (response && response.response.code === 200) {
        // Password is updated successfully
        Toast.success(Message.Password.CHECK_EMAIL);
        setTimeout(() => {
          window.location.href = APP_CONSTANTS.LOGIN;
        }, 1000);
      } else {
        if (response && response.message) {
          Toast.success(Message.Password.UPDATE_FAILURE);
        } else {
          Toast.success(Message.Error.COMMON_MESSAGE);
        }
      }
    });
    // });

    // let response = await updatePassword(payload);
  };

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      formValidate();
    }
  };
  const handleUserNameChange = (e) => {
    setUserName(e.target.value);
  };
  // const handlePassWordChange = (e) => {
  //   setPassword(e.target.value);
  // };

  return (
    <div className='App'>
      <div className='loginWrapper bg-light h-100'>
        <div className='bg-white rounded shadow col-md-4 bgimage data'>
          <form noValidate>
            <img src={AppLogoImage} alt={AltTag.AppLogo} height='30' />
            <NewInput
              id='emailId'
              type='text'
              placeholder='Enter Email Id'
              name='emailId'
              value={userName}
              onKeyPress={handleKeypress}
              label='Username'
              onChange={handleUserNameChange}
              errorText={userNameStatus}
            />

            {/* <NewInput
              id='password'
              type='password'
              placeholder='Enter Password'
              name='password'
              value={password}
              onKeyPress={handleKeypress}
              label='Password'
              onChange={handlePassWordChange}
              errorText={passStatus}
            /> */}
          </form>
          <Button
            className='mt1'
            type='submit'
            onClick={formValidate}
            disabled={loading}
          >
            {loading === true ? 'Updating...' : 'Submit'}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default ForgotPasswordPage;
