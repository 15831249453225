import "./index.scss";
import Search from "app/components/search";
import { useEffect, useState } from "react";
import Button from "app/components/button";
import CustomTable from './table';
import Pagination from "./pagination";
import SelectComp from "./select-tag";
import { useHistory } from 'react-router-dom';
import ArticleModal from "app/components/ArticleModal";
import { getUserJourneyList, deleteTaskById } from "app/api/user-journey";
import RadioButton from "app/shared/components/RadioButton";
const options = [
    { value: 10, label: 10, },
    { value: 20, label: 20, },
    { value: 30, label: 30, },
    { value: 40, label: 40, },
    { value: 50, label: 50, },
]

const UserJourney = (tableHeader) => {
    const history = useHistory();

    const [values, setValues] = useState("");
    const [data, setData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [pageCount, setPageCount] = useState(1); 
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10);
    const [selectedItem, setSelectedItem] = useState("ASTHMA");

    const radioMenu = [
         {
              label: "Asthma",
              id: "1",
              value: "ASTHMA"
         },
         {
              label: "COPD",
              id: "2",
              value: "COPD"
         },
         {
            label: "AR",
            id: "3",
            value: "AR"
       },
       {
            label: "ILD",
            id: "4",
            value: "ILD"
       },
       {
            label: "Others",
            id: "5",
            value: "OTHER"
        }
    ]
    const radioButtonSelected = (item) => { 
        let tempvalue = item.value.toLowerCase()
         setSelectedItem(item.value)
         const filteredData = []

         totalData.forEach((item) => {
             
             if (item.cohort_type&&item.cohort_type.toLowerCase().includes(tempvalue)) {
                 filteredData.push(item);
             }
         });
         const numAscending = [...filteredData].sort((a, b) => a.streak_day - b.streak_day);
         setData(numAscending);

    }
    useEffect(() => {
        getContentDataHandler();
    }, []);

    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    let currentData = data.slice(indexOfFirstData, indexOfLastData)

    async function getContentDataHandler() {
        const dataArr = await getUserJourneyList()
        const filteredData = []

        dataArr.forEach((item) => { 
            if (item.cohort_type&&item.cohort_type.toLowerCase().includes(selectedItem.toLowerCase())) {
                filteredData.push(item);
            }
        });

        const numAscending = [...filteredData].sort((a, b) => a.streak_day - b.streak_day);


        setTotalData(dataArr)
        setData(numAscending);
        let count = Math.ceil(numAscending.length / dataPerPage);
        setPageCount(count)
    }

    function changeHandler(event) {
        const pageLength = event.target.value
        setDataPerPage(pageLength);
        let count = Math.ceil(data.length / pageLength);
        setPageCount(count)
    }

    function inputChangeHandler(searchVal) {
        if (searchVal.trim() === "") {
            setValues(searchVal.toLowerCase()); 
            const filteredData = []

            totalData.forEach((item) => {
                
                if (item.cohort_type&&item.cohort_type.toLowerCase().includes(selectedItem.toLowerCase())) {
                    filteredData.push(item);
                }
            });
            const numAscending = [...filteredData].sort((a, b) => a.streak_day - b.streak_day);

            setData(numAscending);
            return;
        }
        const filteredData = []
        data.forEach((item) => { 
            let tempvalue = searchVal.toLowerCase()
            if (item.title.toLowerCase().includes(tempvalue)) {
                filteredData.push(item);
            }
        });
        setData(filteredData);
        setValues(searchVal);
    };

    function paginationHandler(page) {
        setCurrentPage(page);
    }

    const handleDelete = async(data) => { 
        await deleteTaskById(data.id)
        getContentDataHandler()

   }
 
    return (<> {showFilter && (
        <ArticleModal
            modalCloseClicked={() => setShowFilter(false)}
            filterApplyClicked={() => { }}
            ResetFilterCicked={() => { }}
            handleChange={() => { }}
            values={""}
            error={""}
        />
    )}
        <div className="cms-content__container">
            <h4>User Journey/ Mandatory Tasks</h4>
            <div className="content-selector">
               <h2>Select Cohort</h2>
               {radioMenu.map((item, key) => (
                    <RadioButton
                         id={item.id}
                         label={item.label}
                         isChecked={selectedItem == item.value ? true : false}
                         value={item.value}
                         onSelect={() => { radioButtonSelected(item) }}
                         name={item.label}
                    />
               ))}
            </div>
            <div className="content-container__actionBox">
                <div className="content-container__filterSearch">
                    <Search
                        value={values}
                        placeholder="Search"
                        className={""}
                        onChange={inputChangeHandler}
                    /> 
                </div>
                <Button 
                onClick={() => history.push(`/userjourney/create/?key=${selectedItem}`)} 
                className="content-container__filter-btn">Create Task</Button>
            </div> 
            <div className="content-container__perPage">
                Show
                <SelectComp
                    options={options}
                    onChangeHandler={changeHandler}
                />
                entries
            </div>
            <div className="content-container__table">
                <CustomTable
                    data={currentData}
                    tableHeader={tableHeader.tableHeader}
                    handleDelete={handleDelete}
                />
            </div>

            <div className="content-container__pagination">
                <Pagination
                    pages={pageCount}
                    onChange={paginationHandler}
                />
            </div>
        </div>
    </>

    );
}

export default UserJourney