import "./index.scss";
import Search from "app/components/search";
import { useEffect, useState } from "react";
import Button from "app/components/button";
import CustomTable from './table';
import Pagination from "./pagination"; 
import { useHistory } from 'react-router-dom'; 
import Toast from "app/utils/Toast";
import PageLoader from "app/components/PageLoader"; 
import { getRatingList, deleteRatingListById, updateRatingStatus } from "app/api/rating";
import SelectComp from "../select-tag";
import { APP_CONSTANTS } from "app/constants/app-constants";


const options = [
    { value: 10, label: 10, },
    { value: 20, label: 20, },
    { value: 30, label: 30, },
    { value: 40, label: 40, },
    { value: 50, label: 50, },
]

const RatingList = (tableHeader) => {
  const history = useHistory();

  const [values, setValues] = useState('');
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getContentDataHandler();
  }, []);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  let currentData = data.slice(indexOfFirstData, indexOfLastData);

  async function getContentDataHandler() {
    setLoader(true);
    const dataArr = await getRatingList();
    if (dataArr && dataArr) {
      setData(dataArr);
      setTotalData(dataArr);
      let count = Math.ceil(dataArr.length / dataPerPage);
      setPageCount(count);
    }
    setLoader(false);
  }

  function inputChangeHandler(searchVal) {
    if (searchVal.trim() === '') {
      setValues(searchVal.toLowerCase());
      setData(totalData);
      return;
    }
    const filteredData = [];
    totalData.forEach((item) => {
      if (
        item.module_name.toLowerCase().includes(searchVal.toLowerCase()) ||
        item.action_event.toLowerCase().includes(searchVal.toLowerCase())
      ) {
        filteredData.push(item);
      }
    });
    setData(filteredData);
    setValues(searchVal);
  }

  function paginationHandler(page) {
    setCurrentPage(page);
  }

  const handleDelete = async (data) => {
    let text = 'Are you sure, want to delete';
    if (window.confirm(text) == true) {
      setLoader(true);
      await deleteRatingListById(data.id);
      getContentDataHandler();
    }
  };

  const handleUnpublish = async (data) => {
    let text = `Are you sure, want to ${
      data.status === 'ACTIVE' ? 'Unpublish' : 'Publish'
    }`;
    if (window.confirm(text) == true) {
      if (data) {
        setLoader(true);
        let payload = {
          status: data.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
        };

        let responseObj = await updateRatingStatus(payload, data.id);

        if (
          responseObj &&
          responseObj.response &&
          responseObj.response.code === 200
        ) {
          Toast.success(responseObj.response.alert[0].message);
          getContentDataHandler();
        } else {
          setLoader(false);
          Toast.error(
            responseObj.data
              ? responseObj.data.msg
              : responseObj.response.alert[0].message
              ? responseObj.response.alert[0].message
              : ''
          );
        }
        setLoader(false);
      }
    }
  };

  function changeHandler(event) {
    const pageLength = event.target.value;
    setDataPerPage(pageLength);
    let count = Math.ceil(data.length / pageLength);
    setPageCount(count);
  }

  const handleInputChangeHandler = (key, e, index) => {
    let tempData = [];
    data.forEach((val, i) => {
      if (index === i) {
        let temp = val;
        val[key] = Number(e.target.value) ? Number(e.target.value) : '';
        tempData.push(temp);
      } else {
        tempData.push(val);
      }
    });
    setData(tempData);
  };
  const handleEdit = async (item, key, index) => {
    if (key === 'Edit') {
      let tempData = [];
      data.forEach((val, i) => {
        if (index === i) {
          let temp = val;
          val['isDisabled'] = false;
          tempData.push(temp);
        } else {
          tempData.push(val);
        }
      });
      setData(tempData);
    }
  };

  let pageMeta = {
    total: data,
    pageSize: Number(dataPerPage),
    page: currentPage,
    pageCount: pageCount,
  };

  return (
    <>
      <div className='cms-content__container mt-3'>
        <div className='content-container__actionBox'>
          <div className='content-container__filterSearch'>
            <Search
              value={values}
              placeholder='Search'
              className={''}
              onChange={inputChangeHandler}
            />
          </div>
          <Button
            onClick={() => history.push(APP_CONSTANTS.RATING_CREATE)}
            className='content-container__filter-btn'
          >
            Create Rating
          </Button>
        </div>
        <div className='content-container__perPage'>
          Show
          <SelectComp options={options} onChangeHandler={changeHandler} />
          entries
        </div>

        <div className='content-container__table'>
          <CustomTable
            data={currentData}
            tableHeader={tableHeader.tableHeader}
            handleDelete={handleDelete}
            handleInputChangeHandler={handleInputChangeHandler}
            handleEdit={handleEdit}
            handleUnpublish={handleUnpublish}
            currentPage={currentPage}
          />
        </div>

        <div className='content-container__pagination'>
          <Pagination
            pageMeta={pageMeta}
            pages={pageCount}
            onChange={paginationHandler}
          />
        </div>
      </div>
      {loader && <PageLoader pageLoading={loader} />}
    </>
  );
}

export default RatingList