import '../index.scss';
import Search from 'app/components/search';
import { useEffect, useState } from 'react';
import Toast from 'app/utils/Toast';
import Button from 'app/components/button';
import CustomTable from './CustomTable';
import Pagination from '../pagination';
import { useHistory } from 'react-router-dom';
import RadioButton from 'app/shared/components/RadioButton';
import PageLoader from 'app/components/PageLoader';
import {
  getAdvertisementList,
  deleteAdvertisementById, 
  updateAdvertisementStatus,
} from 'app/api/exercises';

const radioMenu = [
  {
    label: 'Ads on landing page',
    id: 'LANDING_PAGE',
    value: 'LANDING_PAGE',
  },
  {
    label: 'Ads on success page',
    id: 'SUCCESS_PAGE',
    value: 'SUCCESS_PAGE',
  },
];

const AdvertisementListConatainer = (tableHeader) => {
  const history = useHistory();

  const [values, setValues] = useState('');
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [selectedItem, setSelectedItem] = useState('LANDING_PAGE');
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const dataPerPage = 10;
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getContentDataHandler(selectedItem);
  }, []);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  let currentData = data.slice(indexOfFirstData, indexOfLastData);

  async function getContentDataHandler(val) {
    const dataArr = await getAdvertisementList(val);  
    if(dataArr){
      setTotalData(dataArr);
      setData(dataArr);
      let count = Math.ceil(dataArr.length / dataPerPage);
      setPageCount(count);
    }else{
      setTotalData([])
      setData([])
      setPageCount(1)
    }
   
    setLoader(false);
  }

  const radioButtonSelected = (item) => {
    setSelectedItem(item.id);
    getContentDataHandler(item.id);
  };

  function inputChangeHandler(searchVal) {
    if (searchVal.trim() === '') {
      setValues(searchVal.toLowerCase());
      setData(totalData); 
      return;
    }
    const filteredData = [];
    totalData.forEach((item) => {
      if (item.title.toLowerCase().includes(searchVal.toLowerCase())) {
        filteredData.push(item);
      }
    });
    setData(filteredData);
    setValues(searchVal);
  }

  function paginationHandler(page) {
    setCurrentPage(page);
  }

  const handleInputChangeHandler = (key, e, index) => {
    let tempData = [];
    data.forEach((val, i) => {
      if (index === i) {
        let temp = val;
        val[key] = Number(e.target.value) ? Number(e.target.value) : '';
        tempData.push(temp);
      } else {
        tempData.push(val);
      }
    });
    setData(tempData);
  };

  const handleEdit = async (item, key, index) => {};

  const handleDelete = async (item) => {
    let text = `Are you sure, want to Delete this Advertisement`;
    if (window.confirm(text) == true) {
      if (item) {
        setLoader(true);
        await deleteAdvertisementById(item.advertisement_id);
        getContentDataHandler(selectedItem);
      }
    }
  };

  const handlePublishUnpublish = async (item) => {
    let text = `Are you sure, want to ${
      item.status === 'ACTIVE' ? 'Unpublish' : 'Publish'
    }`;
    if (window.confirm(text) == true) {
      if (item) {
        setLoader(true);
        let payload = {
          advertisement_id: item.advertisement_id,
          status: item.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
        };

        let responseObj = await updateAdvertisementStatus(payload);

        if (
          responseObj &&
          responseObj.response &&
          responseObj.response.code === 200
        ) {
          Toast.success(responseObj.response.alert[0].message);
          getContentDataHandler(selectedItem);
        } else {
          setLoader(false);
          Toast.error(
            responseObj.data
              ? responseObj.data.msg
              : responseObj.response.alert[0].message
              ? responseObj.response.alert[0].message
              : ''
          );
        }
        setLoader(false);
      }
    }
  };

  return (
    <>
      <div className='cms-content__container'>
        <h4>Advertisement</h4>
        <div className='content-selector'>
          {radioMenu.map((item, key) => (
            <RadioButton
              id={item.id}
              label={item.label}
              isChecked={selectedItem == item.value ? true : false}
              value={item.value}
              onSelect={() => {
                radioButtonSelected(item);
              }}
              name={item.label}
            />
          ))}
        </div>
        <div className='content-container__actionBox'>
          <div className='content-container__filterSearch'>
            <Search
              value={values}
              placeholder='Search'
              className={''}
              onChange={inputChangeHandler}
            />
          </div>
          <Button
            onClick={() =>
              history.push(`/advertisement/create?key=${selectedItem}`)
            }
            className='content-container__filter-btn'
          >
            New Ad
          </Button>
        </div>

        <div className='content-container__table'>
          <CustomTable
            data={currentData}
            tableHeader={tableHeader.tableHeader}
            handleInputChangeHandler={handleInputChangeHandler}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            handlePublishUnpublish={handlePublishUnpublish}
          />
        </div>

        <div className='content-container__pagination'>
          <Pagination pages={pageCount} onChange={paginationHandler} />
        </div>
      </div>
      {loader && <PageLoader pageLoading={loader} />}
    </>
  );
};

export default AdvertisementListConatainer;
