// Components

import Button from 'components/button';

import PageLoader from 'components/PageLoader';
import validate from 'components/ValidateInfo';

// Constants

// Utils

import './index.scss';

import CategoriesCard from 'app/components/NewDesign/CategoriesCard';
import TabBar from 'app/components/NewDesign/TabBar';
import NewInput from 'app/components/NewInput';
import useTabBarOptions from 'app/utils/useTabbarOptions';
import Trash from 'images/trash.svg';
import VisiblEye from 'images/visibleeye.svg';
import useCategoriesSections from './useCategoriesSections';

const LatestCategories = ({ props, type }) => {
  const {
    pageLoading,

    handleChange,

    listData,
    addServiceSection,
    removeServiceSection,
    isAddButtonDisabled,
    // handleResourseToggle,
    openUp,
    saveCategory,
    deleteSubCategory,
    viewSubCategories,
    navigateNewCategories,
    navigateNewMedPayCategories,
    selectsubCategoryAddingType,
    publishCategories,
    activeTab,
    activeShow,
    handleTabChange,
  } = useCategoriesSections({ validate });
  const { LanguageOptions } = useTabBarOptions();
  return (
    <div className='blogsForm'>
      <div className='blogsForm_content'>
        <h1>{type}</h1>
        <>
          <div className='mt-3 mb-3 ms-3'>
            <TabBar
              tabs={LanguageOptions}
              initialTab={activeTab}
              onTabChange={handleTabChange}
              parentStyle={'service-tab-style'}
            />
          </div>
        </>
        {listData &&
          listData.map((data, index) => {
            return (
              <div key={index}>
                <CategoriesCard
                  category_id={data.category_id}
                  keyValue={data.id}
                  title={data.title}
                  index={index}
                  handleRemoveCard={removeServiceSection}
                  isNew={data?.isNew}
                  toggle={data?.toggle}
                  handleResourseToggle={openUp}
                  activeShow={activeShow}
                >
                  <div className='categories_box'>
                    <div className='categories_box_title_section'>
                      <NewInput
                        label='Secondary Heading'
                        id={data.id}
                        name={'secondary_heading'}
                        value={
                          data.secondary_heading ? data.secondary_heading : ''
                        }
                        placeholder='COPD'
                        className='form-block'
                        onChange={handleChange}
                        // errorText={error.title}
                      />
                      <NewInput
                        label='Primary Heading'
                        id={data.id}
                        name={'primary_heading'}
                        value={data.primary_heading ? data.primary_heading : ''}
                        placeholder='Health Concerns'
                        className='form-block'
                        onChange={handleChange}
                        // errorText={error.title}
                      />
                      <Button
                        className='addbutton mr2'
                        onClick={() => {
                          saveCategory(data, index);
                        }}
                        type='submit'
                        isDisabled={
                          data?.primary_heading === '' ||
                          data?.secondary_heading === ''
                            ? true
                            : false
                        }
                        name={'Save'}
                        // isDisabled={isAddButtonDisabled}
                      />

                      {/* <div className='categories_box_divide'></div> */}
                    </div>
                    {activeShow && (
                      <>
                        <div className='categories_box_subcatgories_heading'>
                          <div className='categories_box_subcatgories_heading_left'>
                            <div className='categories_box_subcatgories_heading_left_key'>
                              HEADINGS
                            </div>
                            <div>
                              <Button
                                className='addbutton mr2'
                                type='submit'
                                name='Add More'
                                onClick={() => {
                                  selectsubCategoryAddingType(index);
                                }}
                                isDisabled={
                                  data.category_id === '' ||
                                  data?.subcategoriesLengthExceed
                                }
                              />
                              {data?.subcategoriesLengthExceed && (
                                <span>Can add upto 6 sub-categories</span>
                              )}
                            </div>
                          </div>

                          <div className='categories_box_subcatgories_heading_right'>
                            <div>
                              <div className='categories_box_subcatgories_heading_right_top'>
                                <div className='categories_box_subcatgories_heading_right_top_action'>
                                  CONTROLS
                                </div>
                                <div className='categories_box_subcatgories_heading_right_top_key'>
                                  CATEGORY ID
                                </div>
                              </div>
                              <div className='categories_box_subcatgories_heading_right_bottom_message'>
                                Need to click on view and add the list of
                                medicines to change the status to completed
                              </div>
                            </div>
                          </div>
                        </div>

                        {data?.createsubcategory && (
                          <div className='subcategorybuttonsection'>
                            <Button
                              className='addbutton mr2'
                              type='submit'
                              name='Add Manually'
                              onClick={() =>
                                navigateNewCategories(data?.category_id)
                              }
                            />
                            <Button
                              className='addbutton mr2'
                              type='submit'
                              name='Add from medpay (API Based)'
                              onClick={() =>
                                navigateNewMedPayCategories(data.category_id)
                              }
                            />
                          </div>
                        )}

                        {data?.subcategories.map((item, subindex) => {
                          return (
                            <div
                              className='categories_box_subcatgories_section'
                              key={subindex}
                            >
                              <div className='categories_box_subcatgories_section_left'>
                                <div>Heading {subindex + 1}</div>
                                <div>{item?.heading}</div>
                              </div>
                              <div className='categories_box_subcatgories_section_right'>
                                <div className='categories_box_subcatgories_section_right_action'>
                                  <div className='categories_box_subcatgories_section_right_action_section'>
                                    <span
                                      className='categories_box_subcatgories_section_right_action_section_viewdelete'
                                      onClick={() => {
                                        viewSubCategories(
                                          data.category_id,
                                          item.id
                                        );
                                      }}
                                    >
                                      <img
                                        className='categories_box_subcatgories_section_right_action_section_viewdelete_image'
                                        src={VisiblEye}
                                        alt='Close'
                                      />
                                      View
                                    </span>
                                    <span
                                      className={
                                        item?.mapped_products_count &&
                                        item?.mapped_products_count >= 5
                                          ? 'categories_box_subcatgories_section_right_action_section_completestatus'
                                          : 'categories_box_subcatgories_section_right_action_section_incompletestatus'
                                      }
                                    >
                                      {item?.mapped_products_count &&
                                      item?.mapped_products_count >= 5
                                        ? 'Complete'
                                        : 'Incomplete'}
                                    </span>
                                  </div>
                                  <div
                                    className='categories_box_subcatgories_section_right_action_section_viewdelete'
                                    onClick={() => {
                                      deleteSubCategory(item.id);
                                    }}
                                  >
                                    <img
                                      src={Trash}
                                      alt='Close'
                                      className='categories_box_subcatgories_section_right_action_section_viewdelete_image'
                                    />
                                    Delete
                                  </div>
                                </div>
                                <div>
                                  <div className='categories_box_subcatgories_section_right_key'>
                                    {item?.category_id}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        <div className='categoriespublishbuttondiv'>
                          <Button
                            className='addbutton mr2'
                            type='submit'
                            name='Publish'
                            onClick={() => {
                              publishCategories(
                                index,
                                data?.id,
                                data?.category_id
                              );
                            }}
                            isDisabled={
                              data?.subcategories.length === 0 ||
                              data.incompleteStatus
                            }
                          />
                        </div>
                      </>
                    )}
                  </div>
                </CategoriesCard>
              </div>
            );
          })}

        {activeShow && (
          <div
            // className='mt1 categories-button-section'
            className={`categories-button-section ${
              isAddButtonDisabled ? 'isAddButtonDisabledtrue' : ''
            }`}
          >
            <div className='section-header__right'>
              <span>
                {' '}
                {isAddButtonDisabled && ' Can add upto 6 Categories'}
              </span>

              <Button
                className='addbutton mr2'
                onClick={addServiceSection}
                type='submit'
                name='Add categories'
                isDisabled={isAddButtonDisabled}
              />
            </div>
          </div>
        )}
      </div>
      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </div>
  );
};

export default LatestCategories;
