import React, { useEffect, useState } from 'react';
import Button from 'components/button';

import { URLType } from 'constants/app-constants';

import Parsers from 'utils/Parsers';

import './index.scss';

const CmsPreview = ({ data }) => {
  let { title, description, content, attachment, cta_title, url, url_type } =
    data;
  const [fileType, setFileType] = useState();
  const [file, setFile] = useState();

  useEffect(() => {
    if (attachment) {
      if (typeof attachment === 'string') {
        setFileType(Parsers.GetURLExtension(attachment));
        setFile(attachment);
      } else if (attachment instanceof File && attachment.name) {
        setFileType(Parsers.GetURLExtension(attachment.name));
        var reader = new FileReader();
        reader.onloadend = function () {
          // console.log('RESULT', reader.result);
          setFile(reader.result);
        };
        reader.readAsDataURL(attachment);
      }
    }
  }, []);

  const onCTAClick = () => {
    if (url && url_type === URLType.EXTERNAL) {
      window.open(url, '_blank');
    }
  };

  const renderContent = () => {
    if (title) {
      return (
        <div>
          {fileType === 'mp4' && file && (
            <video className='article_video' controls>
              <source src={file} type='video/mp4' />
            </video>
          )}
          {fileType !== 'mp4' && file && (
            <img
              className={`${
                fileType === 'gif'
                  ? 'servicepreview_image servicepreview_gif'
                  : 'servicepreview_image'
              }`}
              src={file}
              alt={title}
            />
          )}

          <div className='servicepreview_top'>
            {title && <div className='servicepreview_top_title'>{title}</div>}
            {description && (
              <div className='servicepreview_top_desc'>{description}</div>
            )}
          </div>
          {content && (
            <div
              className='article_content'
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )}
          {cta_title && url && (
            <div className='article_cta'>
              <Button onClick={onCTAClick}>{cta_title}</Button>
            </div>
          )}
        </div>
      );
    }

    return null;
  };

  return <div className='article-detail'>{renderContent()}</div>;
};

export default CmsPreview;
