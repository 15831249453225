import { useState } from 'react';

const useContentSpeekerForm = (validate) => {
  const [count, setCount] = useState(1);
  const [Speekervalues, setSpeekerValues] = useState({
    speekers: [
      {
        speekerId: 1,
        attachment: '',
        speakerId: '',
        attachmentFile: null,
        displayOrder: '',
        description: '',
        name: '',
      },
    ],
    removedBanners: [],
  });

  const addSpeekerSection = () => {
    const speekers = [...Speekervalues.speekers];
    if (speekers.length < 50) {
      speekers.push({
        id: count + 1,
        name: '',
        speakerId: '',
        speekerId: count + 1,
        description: '',
        attachment: '',
        attachmentFile: null,
        displayOrder: '',
      });
      setCount(count + 1);
      setSpeekerValues({
        ...Speekervalues,
        speekers,
        // removedBanners: [],
      });
    }
  };

  const removespeakerSection = (ind) => {
    // alert(ind);
    const speekers = [];

    let removedBanners = [Speekervalues.removedBanners];
    Speekervalues.speekers.forEach((banner, index) => {
      if (ind !== index) {
        speekers.push(banner);
      }
      if (ind === index && banner.speakerId) {
        removedBanners.push(parseInt(banner.speakerId));
      }
    });

    setCount(speekers.length);
    setSpeekerValues({
      ...Speekervalues,
      speekers,
      removedBanners,
    });
  };

  const updateSpeekerValue = (obj) => {
    setSpeekerValues({
      ...obj,
    });

    setCount(obj.speekers.length);
  };

  const [error, setErrors] = useState({});

  const handleChange = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      setSpeekerValues({
        ...Speekervalues,
        [name]: value,
      });
    }
  };

  const OnhandleChange = (e) => {
    const valueCpy = { ...Speekervalues };
    valueCpy.speekers.map((element) => {
      if (`${element.id}` === `${e.target.id}`) {
        element[e.target.name] = e.target.value;
      }
      return element;
    });
    setSpeekerValues(valueCpy);
  };

  const handleSubmitting = (e) => {
    // e.preventDefault();
    setErrors(validate(Speekervalues));
  };

  return {
    handleChange,
    Speekervalues,
    handleSubmitting,
    error,
    setSpeekerValues,
    updateSpeekerValue,
    OnhandleChange,
    addSpeekerSection,
    removespeakerSection,
    count,
  };
};

export default useContentSpeekerForm;
