import React from 'react';

// Pages
import BasePage from 'pages/BasePage';   
import CreateAdTaskContainer from 'app/container/UserJourney/CreateAdTask';
const CreateAdTask = () => {
  return (
    <BasePage>
      <CreateAdTaskContainer />
    </BasePage>
  );
};

export default CreateAdTask;
