// Components

import Button from 'components/button';

import PageLoader from 'components/PageLoader';
import validate from 'components/ValidateInfo';

// Utils

import FilePreview from 'app/components/NewDesign/FilePreview';
import NewAttachment from 'app/components/NewDesign/NewAttachment';
import SmallBannerCard from 'app/components/SmallBannerCard';
import NewInput from 'components/NewInput';
import useLatestBannerWidgets from 'app/components/useLatestBannerWidgets';
import CTAWidgetComponent from 'components/NewDesign/CTAWidgetComponent';
import Modal from 'react-bootstrap/Modal';
import Toggle from 'react-toggle';
import './index.scss';
import DropdownOptions from 'utils/DropdownOptions';
import TabBarOptions from 'app/utils/TabBarOptions';
import TabBar from 'app/components/NewDesign/TabBar';
const LatestBanner = ({ props, type }) => {
  const {
    sortedBannerList,
    pageLoading,
    show,
    OnhandleChange,
    bannerlistData,
    handleCreateServices,
    addServiceSection,
    removeServiceSection,
    onFileSelected,
    validateRequestpreviewScreen,
    handleClose,
    isAddButtonDisabled,
    handleResourseToggle,
    toggled,
    openUp,
    bannerType,
    isPublishButtonDisabled,
    activeTab,
    activeShow,
    handleTabChange,
  } = useLatestBannerWidgets({ validate });
  let med = [
    {
      label: 'Medicine Details',
      value: 'medicine_details',
    },
    { label: 'Lab Test Details', value: 'lab_test_details' },
    { label: 'Medicine Category', value: 'medicine_category' },
    { label: 'Lab Test Category', value: 'lab_test_category' },
  ];
  let newArrDropdownOptions = [...DropdownOptions.InternalUrl, ...med];

  return (
    <div className='blogsForm'>
      <div className='blogsForm_content'>
        <h1>
          {type}
          {activeTab.short_name === 'en' && (
            <Toggle checked={toggled} icons={false} onChange={openUp} />
          )}
        </h1>
        <>
          <div className='mt-3 mb-3 ms-3'>
            <TabBar
              tabs={TabBarOptions.LanguageOptions}
              initialTab={activeTab}
              onTabChange={handleTabChange}
              parentStyle={'service-tab-style'}
            />
          </div>
        </>
        {bannerlistData.map((data, index) => {
          return (
            <div key={index}>
              <SmallBannerCard
                smallBannerCardkey={data?.id}
                title={data?.title}
                index={index}
                handleRemoveCard={removeServiceSection}
                isNew={data?.isNew}
                toggle={data?.toggle}
                showToggle={activeTab.short_name === 'en' ? true : false}
                handleResourseToggle={handleResourseToggle}
              >
                <div className='widget_box'>
                  <div>
                    <NewAttachment
                      value={data?.attachment}
                      id={data?.id}
                      onFileSelected={onFileSelected}
                      fileType='image'
                      label={`Add Service ${index + 1} `}
                      className='widget_box_feed__attachment'
                    />
                    {activeShow && (
                      <NewInput
                        label='Display order'
                        id={data.id}
                        placeholder='Enter display Order 1/2/3'
                        name='displayOrder'
                        value={data.displayOrder}
                        onChange={OnhandleChange}
                        type='number'
                      />
                    )}
                  </div>
                  {activeShow && (
                    <>
                      <div className='widget_box_divide'></div>
                      <CTAWidgetComponent
                        id={data?.id}
                        index={data?.id}
                        externalUrl={data?.externalUrl}
                        ctaValue={data?.title}
                        urlType={data?.urlType}
                        DropdownOptions={newArrDropdownOptions}
                        value={data?.url}
                        isHide={true}
                        onChange={OnhandleChange}
                        swappingOrder={data?.swappingOrder}
                        redirection_id={
                          data?.redirection_id ? data?.redirection_id : null
                        }
                        OnhandleChange={OnhandleChange}
                        urlCopy={data?.urlCopy}
                        redirection_idURL={data?.redirection_idURL}
                      />
                    </>
                  )}
                </div>
              </SmallBannerCard>
            </div>
          );
        })}

        <div
          className={`widget-button-section ${
            isAddButtonDisabled ? 'isAddButtonDisabledtrue' : ''
          }`}
        >
          <div className='section-header__right'>
            {activeTab.short_name === 'en' ? (
              <>
                <span>
                  {isAddButtonDisabled && bannerType?.size === 'SMALL'
                    ? ' Can add upto 5 Banners'
                    : ''}

                  {isAddButtonDisabled && bannerType?.size === 'LARGE'
                    ? ' Can add upto 8 Banners'
                    : ' '}
                </span>

                <Button
                  className='addbutton mr2'
                  onClick={addServiceSection}
                  type='submit'
                  name='Add Banners'
                  isDisabled={isAddButtonDisabled}
                />
              </>
            ) : null}

            <Button
              onClick={validateRequestpreviewScreen}
              type='submit'
              name='Publish'
              isDisabled={isPublishButtonDisabled}
            />
          </div>
        </div>
      </div>
      {pageLoading && <PageLoader pageLoading={pageLoading} />}
      <Modal show={show} onHide={handleClose} className='servicemodel'>
        <Modal.Body className='modal-body'>
          <div className='model-body-section'>
            <div className='model-body-section-list'>
              {sortedBannerList &&
                sortedBannerList.map((data, index) => {
                  return (
                    <div key={index}>
                      {}
                      {data?.attachmentFile ? (
                        <FilePreview data={data} />
                      ) : data?.attachment ? (
                        <div>
                          <img
                            alt='latestbannerimg'
                            className={`${
                              data.fileType === 'gif'
                                ? 'file_view_image file_view_gif'
                                : 'file_view_image'
                            }`}
                            src={`${
                              process?.env?.REACT_APP_S3_BUCKET_URL &&
                              process?.env?.REACT_APP_S3_BUCKET_URL
                            }/${data?.attachment}`}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                      <div className='service_top'>
                        {data?.title && (
                          <div className='service_top_title'>{data?.title}</div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className='model-footer'>
          <div className='model-footer-section'>
            Are you sure to publish the preview to live app ?
            <div className='model-button-section'>
              <Button variant='primary' onClick={handleClose}>
                Cancel
              </Button>
              <Button variant='secondary' onClick={handleCreateServices}>
                Confirm Publish
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </div>
  );
};

export default LatestBanner;
