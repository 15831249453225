// Ajax
import * as dataAccess from 'utils/ajax';
import ApiParser from 'utils/ApiParser';

// Constants
import { API_CONSTANTS } from 'constants/api-contants';
import Parsers from 'utils/Parsers';

export const getSplashScreen = async (start) => {
  let payload = {
    data: {
      page: start ? start : 1,
      limit: 10,
    },
  };
  let responseObj = await dataAccess.post(
    API_CONSTANTS.GET_SPLASH_SCREEN,
    payload
  );

  return ApiParser.parseSplashScreenListing(responseObj);
};

export const addOrUpdateSplashScreen = async (incomingData, SplashId, id) => {
  // debugger;
  let data = {
    publish: 1,
    splash_id: SplashId,
    update_splash_screen: id,
    ...incomingData,
  };

  var formData = Parsers.ConvertObjectAsFormData(data);

  let payload = {
    data: formData,
  };

  return await dataAccess.upload(API_CONSTANTS.UPDATE_SPLASH_SCREEN, payload);
};

export const deleteSplashScreen = async (SplashId) => {
  if (!SplashId) {
    return;
  }

  let payload = {
    data: {
      splash_id: SplashId,
    },
  };

  return await dataAccess.del(API_CONSTANTS.DELETE_SPLASHSCREEN, payload);
};

export const publishOrUnpublishSplashScreen = async (SplashId, status) => {
  if (!SplashId || !status) {
    return;
  }

  let data = {
    publish: status.toLowerCase() === 'inactive' ? 1 : 0,
    update_splash_screen: 1,
    splash_id: SplashId,
  };

  var formData = Parsers.ConvertObjectAsFormData(data);

  let payload = {
    data: formData,
  };
  // console.log(data, 'feeddata');

  return await dataAccess.upload(API_CONSTANTS.UPDATE_SPLASH_SCREEN, payload);
};
