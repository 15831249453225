
import { deleteContent, publishOrUnpublishAPI } from "app/api/cms-content";
import Search from "app/components/search";
import Toast from "app/utils/Toast";
import { useMemo, useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useTable, useSortBy, useGlobalFilter, usePagination } from "react-table";
import "./index.scss";

function ContentTable(props) {
     const history = useHistory();
     const [pages, setPages] = useState()
     const [data, setData] = useState([])
     const [searchVal, setSearchVal] = useState('');
     const [isSearchActive, setIsSearchActive] = useState(false);
     // let data 

     // let data = useMemo(() => {
     //      if (isSearchActive) {
     //           return props.totalData;
     //      } else {
     //           return props.content;
     //      }
     // }, [isSearchActive, props.totalData, props.content])


     useEffect(() => {
          setPages(props.currentPage)
          setData(props.content)
          setPageSize(props.dataPerPage)
     }, [props.currentPage, props.content, props.dataPerPage])

     const columns = useMemo(() => ([
          { Header: "Title", accessor: "title", Cell: ({ row }) => (<p className="table-cells-center" >{row.original.title || "-"}</p>) },

          { Header: "Deep Link URL", accessor: "deeplink_url", Cell: ({ row }) => (<p className="table-cells-center" >{row.original.deeplink_url || "-"}</p>) },

          { Header: "Tags", accessor: "tags", Cell: ({ row }) => (<p className="table-cells-center" >{row.original.tags&&row.original.tags !==null&& row.original.tags.length > 0 ? row.original.tags.map((tag) => (` ${tag},`)) : ""}</p>) },
          {
               Header: "Published Date", accessor: "created_on",
               Cell: ({ row }) => (<p className="table-cells-center" >{row.original.created_on || row.original.start_date ||  "-"}</p>)
          },
          {
               Header: "Updated Date", accessor: "updated_on",
               Cell: ({ row }) => (<p className="table-cells-center" >{row.original.updated_on || "-"}</p>)
          },

          { Header: "Content ID", accessor: "id", Cell: ({ row }) => (<p className="table-cells-center">{row.original.id || "-"}</p>) },
          
          { Header: "Quiz Id", accessor: "fk_quiz_id", Cell: ({ row }) => (
          <p className="table-cells-center">{row.original.fk_quiz_id?row.original.fk_quiz_id:'-'}</p>
          ) },

          {
               Header: "Content Type", accessor: "content_type", Cell: ({ row }) => (<p className="table-cells-center">{row.original.gateway_transaction_id || row.original.content_type || "-"}</p>)
          },
          {
               Header: "Status", accessor: "status", Cell: ({ row }) => (<p className="table-cells-center" >{row.original.status || "-"}</p>)
          },
          {
               Header: "Expired On", accessor: "end_date", Cell: ({ row }) => (<p className="table-cells-center" >{row.original.end_date || "-"}</p>)
          },
          {
               Header: "Language", accessor: "language", Cell: ({ row }) => (<p className="table-cells-center" >{row.original?.language || "-"}</p>)
          },
          {
               Header: "Actions", Cell: ({ row }) => (<div className="btn-group" role="group" aria-label="Basic outlined example">
                    <button
                         type="button"
                         className="btn btn-outline-primary"
                         onClick={() => {

                              history.push({
                                   pathname: `/cms/content/create`,

                                   state: {
                                        ...row.original,
                                        comingFrom: pages
                                   },
                              });
                         }}
                    >
                         Edit
                    </button>
                    <button type="button"
                         onClick={() => { handleDelete(row.original.id) }}
                         className="btn btn-outline-primary">Delete</button>
                    <button onClick={() => { publishOrUnpublish(row.original) }} type="button" className="btn btn-outline-primary">{row.original.status == "ACTIVE" ?
                         "Unpublish"
                         :
                         "Publish"
                    }</button>
               </div>)
          },
     ]), [pages])

     const { getTableProps,
          getTableBodyProps,
          headerGroups,
          page,
          nextPage, previousPage,
          canNextPage,
          canPreviousPage,
          prepareRow,
          setGlobalFilter,
          row,
          setPageSize
     } = useTable({
          columns,
          data
     }, useGlobalFilter, useSortBy, usePagination); 
     async function searchHandler(search) {
          setSearchVal(search);
          if (search) {
               setIsSearchActive(true)
               setGlobalFilter(search);
          } else {
               setIsSearchActive(false)
          }
          return;
     }

     

     const handleDelete = async (id) => {
          const resp = await deleteContent(id)
          if (resp?.data?.length != 0) {
               Toast.success("Successfully Deleted")
          }
          props.getContentDataHandler()
     }
     const publishOrUnpublish = async (item) => {
          //cms/discover/content/unpublish/118
          const query = {
               "ACTIVE": "unpublish",
               "INACTIVE": "PUBLISH"
          }
          const resp = await publishOrUnpublishAPI(item.id, query[item.status])
          if (resp?.data?.length != 0) {
               Toast.success("Updated Successfully")
          }
          props.getContentDataHandler()
     }

     const arrowShow = ({ isSorted, isSortedDesc }) => {
          if (isSorted) {
               if (isSortedDesc) {
                    return (<span className="my-table__arrow">🠻</span>)
               } else {
                    return (<span className="my-table__arrow">🠹</span>)
               }

          } else {
               return (<span className="my-table__arrow">
                    ⬍
               </span>)
          }
     }


     return (<div className="table">
          {/* <Search
               value={searchVal}
               placeholder="Column Search"
               onChange={searchHandler} 
          /> */}
          {props.content.length > 0 &&
               <table {...getTableProps()} className="my-table">
                    <thead>
                         {headerGroups.map((headerGroup) => (
                              <tr {...headerGroup.getHeaderGroupProps()}>
                                   {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render("Header")}
                                             <>{arrowShow(column)}</>
                                        </th>
                                   ))}
                              </tr>
                         ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                         {page.map((row) => {
                              prepareRow(row);
                              return (<tr {...row.getRowProps()}>{
                                   row.cells.map((cell) => (
                                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                   ))
                              }</tr>)
                         })}
                    </tbody>
               </table>}
          {isSearchActive ? <div className="react-table-pagination">
               <button onClick={() => previousPage()} disabled={!canPreviousPage} className="page-item page-link">
                    {'<'}
               </button>
               <button onClick={() => nextPage()} disabled={!canNextPage} className="page-item page-link ">
                    {'>'}
               </button>
          </div> : <>{props.children}</>}
     </div>)
};

export default ContentTable;