import { MasterDataType } from 'constants/app-constants';
import Parsers from 'utils/Parsers';

export const parseAuthenticate = (responseObj) => {
  let responsePayload = {
    tableData: [],
    error: false,
    message: '',
    pagination: {},
    summary: {},
  };

  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    if (responseObj.data && responseObj.data.access_token) {
      responsePayload.data = responseObj.data;
      responsePayload.error = false;
    }
  } else {
    responsePayload.error = true;
    if (
      responseObj.response &&
      responseObj.response.alert &&
      responseObj.response.alert.length > 0 &&
      responseObj.response.alert[0].message
    ) {
      responsePayload.message = responseObj.response.alert[0].message;
    }
  }

  return responsePayload;
};

export const parseLogin = (responseObj) => {
  let responsePayload = {
    tableData: [],
    error: false,
    message: '',
    pagination: {},
    summary: {},
  };

  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    if (responseObj.data && responseObj.data.access_token) {
      responsePayload.data = responseObj.data;
      responsePayload.error = false;
    }
  } else {
    responsePayload.error = true;
    if (
      responseObj.response &&
      responseObj.response.alert &&
      responseObj.response.alert.length > 0 &&
      responseObj.response.alert[0].message
    ) {
      responsePayload.message = responseObj.response.alert[0].message;
    }
  }

  return responsePayload;
};

export const parseFeedListing = (responseObj) => {
  let responsePayload = {
    tableData: [],
    error: false,
    message: '',
    pagination: {},
    summary: {},
  };

  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200 &&
    responseObj.data
  ) {
    if (responseObj.data.articles && responseObj.data.articles.length > 0) {
      responseObj.data.articles.forEach((item) => {
        responsePayload.tableData.push({
          ...item,
          last_updated_time: Parsers.ParseTableDateTime(item.last_updated_time),
          first_published_time: Parsers.ParseTableDateTime(
            item.first_published_time
          ),
          // last_updated_time: Parsers.ParseTableDateTime(item.last_updated_time),
        });
      });
    }

    responsePayload.error = false;
    responsePayload.pagination = {
      ...responseObj.data.pagination,
    };
    let { articles_status } = responseObj.data;
    responsePayload.summary = {
      totalArticle: articles_status.total_articles,
      activeArticle: articles_status.active_articles,
      inActiveArticle: articles_status.in_active_articles,
    };
  } else {
    responsePayload.error = true;
    if (
      responseObj.response &&
      responseObj.response.alert &&
      responseObj.response.alert.length > 0 &&
      responseObj.response.alert[0].message
    ) {
      responsePayload.message = responseObj.response.alert[0].message;
    }
  }

  return responsePayload;
};

export const parseWebinarListing = (responseObj) => {
  let responsePayload = {
    tableData: [],
    error: false,
    message: '',
    pagination: {},
    summary: {},
  };

  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200 &&
    responseObj.data.webinars
  ) {
    if (responseObj.data.webinars && responseObj.data.webinars.length > 0) {
      responseObj.data.webinars.forEach((item) => {
        responsePayload.tableData.push({
          ...item,
          created_on: Parsers.ParseTableDateTime(item.created_on),
        });
      });
    }

    responsePayload.error = false;
    responsePayload.pagination = {
      ...responseObj.data.pagination,
    };
  } else {
    responsePayload.error = true;
    if (
      responseObj.response &&
      responseObj.response.alert &&
      responseObj.response.alert.length > 0 &&
      responseObj.response.alert[0].message
    ) {
      responsePayload.message = responseObj.response.alert[0].message;
    }
  }

  return responsePayload;
};

export const parseHomeListing = (responseObj) => {
  let responsePayload = {
    tableData: [],
    error: false,
    message: '',
    pagination: {},
    summary: {},
  };

  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200 &&
    responseObj.data
  ) {
    if (responseObj.data && responseObj.data.length > 0) {
      responseObj.data.forEach((item) => {
        responsePayload.tableData.push({
          ...item,
          last_edited_time: Parsers.ParseTableDateTime(item.last_edited_time),

          // last_updated_time: Parsers.ParseTableDateTime(item.last_updated_time),
        });
      });
    }

    responsePayload.error = false;
    responsePayload.pagination = {
      ...responseObj.data.pagination,
    };
  } else {
    responsePayload.error = true;
    if (
      responseObj.response &&
      responseObj.response.alert &&
      responseObj.response.alert.length > 0 &&
      responseObj.response.alert[0].message
    ) {
      responsePayload.message = responseObj.response.alert[0].message;
    }
  }

  return responsePayload;
};
export const parseHomeOrderListing = (responseObj) => {
  let responsePayload = {
    tableData: [],
    error: false,
    message: '',
    pagination: {},
    summary: {},
  };

  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200 &&
    responseObj.data
  ) {
    if (responseObj.data && responseObj.data.homepage_order_data.length > 0) {
      responseObj.data.homepage_order_data.forEach((item) => {
        let titlecard= ""
        if(item.section_name){
          titlecard = item.section_name
        }else {
          titlecard = item.widget_name
        }

        responsePayload.tableData.push({
          title: titlecard, 
          ...item,
          last_edited_time: Parsers.ParseTableDateTime(item.last_edited_time),

          // last_updated_time: Parsers.ParseTableDateTime(item.last_updated_time),
        });
      });
    }

    responsePayload.error = false;
    responsePayload.pagination = {
      ...responseObj.data.pagination,
    };
  } else {
    responsePayload.error = true;
    if (
      responseObj.response &&
      responseObj.response.alert &&
      responseObj.response.alert.length > 0 &&
      responseObj.response.alert[0].message
    ) {
      responsePayload.message = responseObj.response.alert[0].message;
    }
  }

  return responsePayload;
};

export const parseHomeOrderListingExplore = (responseObj) => {
  let responsePayload = {
    tableData: [],
    error: false,
    message: '',
    pagination: {},
    summary: {},
  };

  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200 &&
    responseObj.data
  ) {
    if (responseObj.data && responseObj.data.homepage_order_data.length > 0) {
      responseObj.data.homepage_order_data.forEach((item) => {
        let titlecard= ""
        if(item.section_name){
          titlecard = item.section_name
        }else {
          titlecard = item.widget_name
        }
        responsePayload.tableData.push({
          title: titlecard, 
          ...item,
          last_edited_time: Parsers.ParseTableDateTime(item.last_edited_time),

          // last_updated_time: Parsers.ParseTableDateTime(item.last_updated_time),
        });
        console.log(responsePayload.tableData, 'responsePayload.tableData')
      });
    }

    responsePayload.error = false;
    responsePayload.pagination = {
      ...responseObj.data.pagination,
    };
  } else {
    responsePayload.error = true;
    if (
      responseObj.response &&
      responseObj.response.alert &&
      responseObj.response.alert.length > 0 &&
      responseObj.response.alert[0].message
    ) {
      responsePayload.message = responseObj.response.alert[0].message;
    }
  }

  return responsePayload;
};
export const parseServiceListing = (responseObj) => {
  let responsePayload = {
    tableData: [],
    error: false,
    message: '',
    pagination: {},
    summary: {},
  };

  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200 &&
    responseObj.data
  ) {
    if (responseObj.data && responseObj.data.length > 0) {
      responseObj.data.forEach((item) => {
        responsePayload.tableData.push({
          ...item,
          toggle: item.is_active === 'INACTIVE' ? false : true,
          status: item.is_active === 'INACTIVE' ? 'INACTIVE' : 'ACTIVE',
          updated_on: Parsers.ParseTableDateTime(item.updated_on),

          // last_updated_time: Parsers.ParseTableDateTime(item.last_updated_time),
        });
      });
    }

    responsePayload.error = false;
    responsePayload.pagination = {
      ...responseObj.data.pagination,
    };
  } else {
    responsePayload.error = true;
    if (
      responseObj.response &&
      responseObj.response.alert &&
      responseObj.response.alert.length > 0 &&
      responseObj.response.alert[0].message
    ) {
      responsePayload.message = responseObj.response.alert[0].message;
    }
  }

  return responsePayload;
};
export const parseActivityVitalsListing = (responseObj) => {
  // debugger;
  let responsePayload = {
    tableData: [],
    error: false,
    message: '',
    pagination: {},
    summary: {},
  };

  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200 &&
    responseObj.data
  ) {
    if (responseObj.data && responseObj.data.length > 0) {
      responseObj.data.forEach((item) => {
        responsePayload.tableData.push({
          ...item,
          last_updated_time: Parsers.ParseTableDateTime(item.last_updated_time),
        });
      });
    }

    responsePayload.error = false;
    responsePayload.pagination = {
      ...responseObj.data.pagination,
    };
    // let { articles_status } = responseObj.data;
    // responsePayload.summary = {
    //   totalArticle: articles_status.total_articles,
    //   activeArticle: articles_status.active_articles,
    //   inActiveArticle: articles_status.in_active_articles,
    // };
  } else {
    responsePayload.error = true;
    if (
      responseObj.response &&
      responseObj.response.alert &&
      responseObj.response.alert.length > 0 &&
      responseObj.response.alert[0].message
    ) {
      responsePayload.message = responseObj.response.alert[0].message;
    }
  }

  return responsePayload;
};

export const parseBreathefreeListing = (responseObj) => {
  let responsePayload = {
    tableData: [],
    error: false,
    message: '',
    pagination: {},
    summary: {},
  };

  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200 &&
    responseObj.data
  ) {
    if (
      responseObj.data.breathefree &&
      responseObj.data.breathefree.length > 0
    ) {
      responseObj.data.breathefree.forEach((item) => {
        responsePayload.tableData.push({
          ...item,
          last_updated_time: Parsers.ParseTableDateTime(item.last_updated_time),
        });
      });
    }

    responsePayload.error = false;
    responsePayload.pagination = {
      ...responseObj.data.pagination,
    };
    // let { articles_status } = responseObj.data;
    // responsePayload.summary = {
    //   totalArticle: articles_status.total_articles,
    //   activeArticle: articles_status.active_articles,
    //   inActiveArticle: articles_status.in_active_articles,
    // };
  } else {
    responsePayload.error = true;
    if (
      responseObj.response &&
      responseObj.response.alert &&
      responseObj.response.alert.length > 0 &&
      responseObj.response.alert[0].message
    ) {
      responsePayload.message = responseObj.response.alert[0].message;
    }
  }

  return responsePayload;
};

export const parseHabitListing = (responseObj) => {
  let responsePayload = {
    tableData: [],
    error: false,
    message: '',
    pagination: {},
    summary: {},
  };

  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200 &&
    responseObj.data
  ) {
    if (responseObj.data && responseObj.data.length > 0) {
      responseObj.data.forEach((item) => {
        responsePayload.tableData.push({
          ...item,
          // created_on: Parsers.ParseTableDateTime(item.created_on),
        });
      });
    }

    responsePayload.error = false;
    // responsePayload.pagination = {
    //   // ...responseObj.data.pagination,
    // };
  } else {
    responsePayload.error = true;
    if (
      responseObj.response &&
      responseObj.response.alert &&
      responseObj.response.alert.length > 0 &&
      responseObj.response.alert[0].message
    ) {
      responsePayload.message = responseObj.response.alert[0].message;
    }
  }

  return responsePayload;
};

export const parseHabitTipListing = (responseObj) => {
  let responsePayload = {
    tipData: [],
  };

  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200 &&
    responseObj.data
  ) {
    if (responseObj.data && responseObj.data.length > 0) {
      responseObj.data.forEach((item) => {
        responsePayload.tipData.push({
          ...item,
          // created_on: Parsers.ParseTableDateTime(item.created_on),
        });
      });
    }
  }

  return responsePayload;
};

export const parseCelebrityListing = (responseObj) => {
  let responsePayload = {
    tableData: [],
    error: false,
    message: '',
    pagination: {},
    summary: {},
  };

  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200 &&
    responseObj.data
  ) {
    if (
      responseObj.data.celebrity_video &&
      responseObj.data.celebrity_video.length > 0
    ) {
      responseObj.data.celebrity_video.forEach((item) => {
        responsePayload.tableData.push({
          ...item,
          last_updated_time: Parsers.ParseTableDateTime(item.last_updated_time),
        });
      });
    }

    responsePayload.error = false;
    responsePayload.pagination = {
      ...responseObj.data.pagination,
    };
    // let { articles_status } = responseObj.data;
    // responsePayload.summary = {
    //   totalArticle: articles_status.total_articles,
    //   activeArticle: articles_status.active_articles,
    //   inActiveArticle: articles_status.in_active_articles,
    // };
  } else {
    responsePayload.error = true;
    if (
      responseObj.response &&
      responseObj.response.alert &&
      responseObj.response.alert.length > 0 &&
      responseObj.response.alert[0].message
    ) {
      responsePayload.message = responseObj.response.alert[0].message;
    }
  }

  return responsePayload;
};

export const parseUnderstandListing = (responseObj) => {
  let responsePayload = {
    tableData: [],
    error: false,
    message: '',
    pagination: {},
    summary: {},
  };

  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200 &&
    responseObj.data
  ) {
    if (
      responseObj.data.understanding_device &&
      responseObj.data.understanding_device.length > 0
    ) {
      responseObj.data.understanding_device.forEach((item) => {
        responsePayload.tableData.push({
          ...item,
          last_updated_time: Parsers.ParseTableDateTime(item.last_updated_time),
        });
      });
    }

    responsePayload.error = false;
    responsePayload.pagination = {
      ...responseObj.data.pagination,
    };
    // let { articles_status } = responseObj.data;
    // responsePayload.summary = {
    //   totalArticle: articles_status.total_articles,
    //   activeArticle: articles_status.active_articles,
    //   inActiveArticle: articles_status.in_active_articles,
    // };
  } else {
    responsePayload.error = true;
    if (
      responseObj.response &&
      responseObj.response.alert &&
      responseObj.response.alert.length > 0 &&
      responseObj.response.alert[0].message
    ) {
      responsePayload.message = responseObj.response.alert[0].message;
    }
  }

  return responsePayload;
};

export const parseDidYouKnowListing = (responseObj) => {
  let responsePayload = {
    tableData: [],
    error: false,
    message: '',
    pagination: {},
    summary: {},
  };

  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200 &&
    responseObj.data
  ) {
    if (
      responseObj.data.did_you_know &&
      responseObj.data.did_you_know.length > 0
    ) {
      responseObj.data.did_you_know.forEach((item) => {
        responsePayload.tableData.push({
          ...item,
          last_updated_time: Parsers.ParseTableDateTime(item.last_updated_time),
        });
      });
    }

    responsePayload.error = false;
    responsePayload.pagination = {
      ...responseObj.data.pagination,
    };
    // let { articles_status } = responseObj.data;
    // responsePayload.summary = {
    //   totalArticle: articles_status.total_articles,
    //   activeArticle: articles_status.active_articles,
    //   inActiveArticle: articles_status.in_active_articles,
    // };
  } else {
    responsePayload.error = true;
    if (
      responseObj.response &&
      responseObj.response.alert &&
      responseObj.response.alert.length > 0 &&
      responseObj.response.alert[0].message
    ) {
      responsePayload.message = responseObj.response.alert[0].message;
    }
  }

  return responsePayload;
};

export const parseSplashScreenListing = (responseObj) => {
  // debugger;
  let responsePayload = {
    tableData: [],
    error: false,
    message: '',
    pagination: {},
    summary: {},
  };

  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200 &&
    responseObj.data
  ) {
    if (
      responseObj.data.splash_screen &&
      responseObj.data.splash_screen.length > 0
    ) {
      responseObj.data.splash_screen.forEach((item) => {
        responsePayload.tableData.push({
          ...item,
          first_published_time: Parsers.ParseTableDateTime(
            item.first_published_time
          ),
          last_updated_time: Parsers.ParseTableDateTime(item.last_updated_time),
        });
      });
    }

    responsePayload.error = false;
    responsePayload.pagination = {
      ...responseObj.data.pagination,
    };
  } else {
    responsePayload.error = true;
    if (
      responseObj.response &&
      responseObj.response.alert &&
      responseObj.response.alert.length > 0 &&
      responseObj.response.alert[0].message
    ) {
      responsePayload.message = responseObj.response.alert[0].message;
    }
  }

  return responsePayload;
};

export const parseFaqListing = (responseObj) => {
  let responsePayload = {
    tableData: [],
    error: false,
    message: '',
    pagination: {},
    summary: {},
  };

  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    if (
      responseObj.data &&
      responseObj.data.faq &&
      responseObj.data.faq.length > 0
    ) {
      responseObj.data.faq.forEach((item, index) => {
        responsePayload.tableData.push({
          ...item,
          index_number: Parsers.generateSerialNumber(
            responseObj.data.pagination.current_page,
            responseObj.data.faq.length,
            index
          ),
          updated_on: Parsers.ParseTableDate(item.updated_on),
        });
      });
      responsePayload.error = false;
      responsePayload.pagination = {
        ...responseObj.data.pagination,
      };
      let { total_faq_count } = responseObj.data;
      responsePayload.summary = {
        totalFaq: total_faq_count,
      };
    }
  } else {
    responsePayload.error = true;
    if (
      responseObj.response &&
      responseObj.response.alert &&
      responseObj.response.alert.length > 0 &&
      responseObj.response.alert[0].message
    ) {
      responsePayload.message = responseObj.response.alert[0].message;
    }
  }

  return responsePayload;
};

export const parseStaticContentListing = (responseObj) => {
  let responsePayload = {
    tableData: [],
    error: false,
    message: '',
    pagination: {},
    summary: {},
  };

  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    if (
      responseObj.data &&
      responseObj.data.static_page &&
      responseObj.data.static_page.length > 0
    ) {
      responseObj.data.static_page.forEach((item) => {
        responsePayload.tableData.push({
          ...item,
          last_edited: Parsers.ParseTableDateTime(item.last_edited),
        });
      });
      responsePayload.error = false;
      responsePayload.pagination = {
        ...responseObj.data.pagination,
      };
    }
  } else {
    responsePayload.error = true;
    if (
      responseObj.response &&
      responseObj.response.alert &&
      responseObj.response.alert.length > 0 &&
      responseObj.response.alert[0].message
    ) {
      responsePayload.message = responseObj.response.alert[0].message;
    }
  }

  return responsePayload;
};

export const parseUsersListing = (responseObj) => {
  let responsePayload = {
    tableData: [],
    error: false,
    message: '',
    pagination: {},
    summary: {},
  };

  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    if (
      responseObj.data &&
      responseObj.data.user_data &&
      responseObj.data.user_data.length > 0
    ) {
      responsePayload.tableData = responseObj.data.user_data;
      responsePayload.error = false;
      responsePayload.pagination = {
        ...responseObj.data.pagination,
      };
      let { user_history } = responseObj.data;
      responsePayload.summary = {
        totalUser: user_history.total_users,
        activeUser: user_history.active_users,
        inActiveUser: user_history.inactive_users,
      };
    }
  } else {
    responsePayload.error = true;
    if (
      responseObj.response &&
      responseObj.response.alert &&
      responseObj.response.alert.length > 0 &&
      responseObj.response.alert[0].message
    ) {
      responsePayload.message = responseObj.response.alert[0].message;
    }
  }

  return responsePayload;
};

export const parseChallengesListing = (responseArr) => {
  let responsePayload = {
    tableData: [],
    error: false,
    message: '',
    pagination: {},
    summary: {},
  };

  let index = 0;
  if (responseArr && responseArr.length > 0) {
    responseArr.forEach((responseObj) => {
      if (
        responseObj &&
        responseObj.response &&
        responseObj.response.code === 200
      ) {
        if (responseObj.data && responseObj.data.length > 0) {
          responseObj.data.forEach((item) => {
            responsePayload.tableData.push({
              ...item,
              id: index,
              start_date: Parsers.ParseTableDateTime(item.start_date),
              end_date: Parsers.ParseTableDateTime(item.end_date),
              last_edited: Parsers.ParseTableDateTime(item.last_edited),
            });
            index += 1;
          });

          responsePayload.error = false;
        }
      } else {
        responsePayload.error = true;
        if (
          responseObj.response &&
          responseObj.response.alert &&
          responseObj.response.alert.length > 0 &&
          responseObj.response.alert[0].message
        ) {
          responsePayload.message = responseObj.response.alert[0].message;
        }
      }
    });
  }

  return responsePayload;
};

export const parseActivitiesListing = (responseArr) => {
  let responsePayload = {
    tableData: [],
    error: false,
    message: '',
    pagination: {},
    summary: {},
  };

  let index = 0;
  if (responseArr && responseArr.length > 0) {
    responseArr.forEach((responseObj) => {
      if (
        responseObj &&
        responseObj.response &&
        responseObj.response.code === 200
      ) {
        if (responseObj.data && responseObj.data.length > 0) {
          responseObj.data.forEach((item) => {
            responsePayload.tableData.push({
              ...item,
              id: index,
              start_date: Parsers.ParseTableDateTime(item.start_date),
              end_date: Parsers.ParseTableDateTime(item.end_date),
              last_edited: Parsers.ParseTableDateTime(item.last_edited),
            });
            index += 1;
          });

          responsePayload.error = false;
        }
      } else {
        responsePayload.error = true;
        if (
          responseObj.response &&
          responseObj.response.alert &&
          responseObj.response.alert.length > 0 &&
          responseObj.response.alert[0].message
        ) {
          responsePayload.message = responseObj.response.alert[0].message;
        }
      }
    });
  }

  return responsePayload;
};

export const parseUpdatePassword = (responseObj) => {
  let responsePayload = {
    tableData: [],
    error: false,
    message: '',
    pagination: {},
    summary: {},
  };

  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    responsePayload.error = false;
    responsePayload.status = 'success';
  } else {
    responsePayload.error = true;
    if (
      responseObj.response &&
      responseObj.response.alert &&
      responseObj.response.alert.length > 0 &&
      responseObj.response.alert[0].message
    ) {
      responsePayload.message = responseObj.response.alert[0].message;
    }
  }

  return responsePayload;
};

export const parseQuizListing = (responseObj) => {
  let responsePayload = {
    tableData: [],
    error: false,
    message: '',
    pagination: {},
    summary: {},
  };

  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    if (
      responseObj.data &&
      responseObj.data.quiz_content &&
      responseObj.data.quiz_content.length > 0
    ) {
      responsePayload.tableData = responseObj.data.quiz_content;
      responsePayload.error = false;
      responsePayload.pagination = {
        ...responseObj.data.pagination,
      };
      responsePayload.summary = {
        totalQuiz: responseObj.data.total_quiz_count,
      };
    }
  } else {
    responsePayload.error = true;
    if (
      responseObj.response &&
      responseObj.response.alert &&
      responseObj.response.alert.length > 0 &&
      responseObj.response.alert[0].message
    ) {
      responsePayload.message = responseObj.response.alert[0].message;
    }
  }

  return responsePayload;
};

export const parseMasterData = (responseArr) => {
  let responsePayload = {
    tableData: [],
    error: false,
    message: '',
    pagination: {},
    summary: {},
  };

  if (responseArr && responseArr.length > 0) {
    responseArr.forEach((responseObj, index) => {
      if (
        responseObj &&
        responseObj.response &&
        responseObj.response.code === 200
      ) {
        let type = '';
        if (index === 0) {
          type = MasterDataType.MEDICINE;
        } else if (index === 1) {
          type = MasterDataType.DOCTOR;
        }
        responsePayload.tableData.push({
          ...responseObj.data,
          type: type,
          id: index,
          last_edited: Parsers.ParseTableDateTime(responseObj.data.last_edited),
        });
      }
    });
  }

  return responsePayload;
};

export const parseUploadMasterData = (responseObj) => {
  let responsePayload = {
    error: false,
    message: '',
  };

  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    responsePayload.error = false;
  } else {
    responsePayload.error = true;
    if (
      responseObj.response &&
      responseObj.response.alert &&
      responseObj.response.alert.length > 0 &&
      responseObj.response.alert[0].message
    ) {
      responsePayload.message = responseObj.response.alert[0].message;
    }
  }

  return responsePayload;
};

export const parseBannersListing = (responseObj) => {
  let responsePayload = {
    tableData: [],
    error: false,
    message: '',
    pagination: {},
    summary: {},
  };

  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    if (responseObj.data) {
      for (const type in responseObj.data) {
        let data = responseObj.data[type];
        responsePayload.tableData.push({
          type_of_banner: type,
          banner_details: [...data.banner_details],
          ...data.metadata,
          start_date: Parsers.ParseTableDateTime(data.metadata.start_date),
          end_date: Parsers.ParseTableDateTime(data.metadata.end_date),
          last_edited: Parsers.ParseTableDateTime(data.metadata.last_edited),
        });
      }
    }
  } else {
    responsePayload.error = true;
    if (
      responseObj.response &&
      responseObj.response.alert &&
      responseObj.response.alert.length > 0 &&
      responseObj.response.alert[0].message
    ) {
      responsePayload.message = responseObj.response.alert[0].message;
    }
  }

  return responsePayload;
};

const parseTC = (responseObj, type) => {
  let responsePayload = {
    data: {},
    error: false,
    message: '',
    pagination: {},
    summary: {},
  };

  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    if (responseObj.data && responseObj.data && responseObj.data.length > 0) {
      let isDataExists = false;
      responseObj.data.forEach((item) => {
        if (item.type === type) {
          isDataExists = true;
          responsePayload.data = {
            ...item,
          };
        }
      });
      if (isDataExists) {
        responsePayload.error = false;
      } else {
        responsePayload.error = true;
        responsePayload.message = 'No Content';
      }
    }
  } else {
    responsePayload.error = true;
    if (
      responseObj.response &&
      responseObj.response.alert &&
      responseObj.response.alert.length > 0 &&
      responseObj.response.alert[0].message
    ) {
      responsePayload.message = responseObj.response.alert[0].message;
    }
  }

  return responsePayload;
};

export const parseTags = (responseObject) => {
  let responsePayload = [];
  for (const item in responseObject?.data) {
    responsePayload.push({
      name: responseObject.data[item].tag_name,
      value: responseObject.data[item].formatted_tag,
      tag_type: responseObject.data[item].tag_type,
    });
  }
  return responsePayload;
};

export const parseCollectionIDs = (responseObj) => {
  let values = [];
  let arr = responseObj?.data;
  for (let index in arr) {
    values.push({
      name: String(arr[index]?.id + '-' + String(arr[index]?.collection_name)),
      label: String(arr[index]?.id + '-' + String(arr[index]?.collection_name)),
      value: String(arr[index]?.id),
      id: String(arr[index]?.id),
    });
  }
  return values;
};
export const parseCollectionIDStory = (responseObj) => {
  // let val = []
  // let arr = Object.keys(responseObj?.data?.id)
  // for (let index in arr) {
  //   val.push(responseObj?.data?.id[index])
  // }
  // // for (let index in arr) {
  // //   values.push({ name: String(arr[index]?.id), value: String(arr[index]?.id) })
  // // }

  // return val
  let arr = Object.keys(responseObj?.data);
  // for (let index in arr) {
  //   val.push(responseObj?.data?.id[index])
  // }
  // for (let index in arr) {
  //   values.push({ name: String(arr[index]?.id), value: String(arr[index]?.id) })
  // }
  return arr;
};

export const parseProductFamilyListing = (responseObj) => {
  let responsePayload = {
    tableData: [],
    error: false,
    message: '',
    pagination: {},
    summary: {},
  };

  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200 &&
    responseObj.data
  ) {
    if (responseObj.data && responseObj.data.length > 0) {
      responseObj.data.forEach((item) => {
        responsePayload.tableData.push({
          ...item,
          status: item.status === 'ACTIVE' ? 'ON' : 'OFF',
          created_on: Parsers.ParseTableDate(item.created_on),
          updated_on: Parsers.ParseTableDate(item.updated_on),
        });
      });
      responsePayload.error = false;
      responsePayload.pagination = {
        ...responseObj.data.pagination,
      };
    }
  } else {
    responsePayload.error = true;
    if (
      responseObj.response &&
      responseObj.response.alert &&
      responseObj.response.alert.length > 0 &&
      responseObj.response.alert[0].message
    ) {
      responsePayload.message = responseObj.response.alert[0].message;
    }
  }

  return responsePayload;
};

export const parseFamilyPlansListing = (responseObj) => {
  let responsePayload = {
    tableData: [],
    error: false,
    message: '',
    pagination: {},
    summary: {},
  };

  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200 &&
    responseObj.data
  ) {
    if (responseObj.data && responseObj.data.length > 0) {
      responseObj.data.forEach((item) => {
        responsePayload.tableData.push({
          ...item,
          status: item.status === 'ACTIVE' ? 'ON' : 'OFF',
          created_on: Parsers.ParseTableDate(item.created_on),
          updated_on: Parsers.ParseTableDate(item.updated_on),
        });
      });
      responsePayload.error = false;
      responsePayload.pagination = {
        ...responseObj.data.pagination,
      };
    }
  } else {
    responsePayload.error = true;
    if (
      responseObj.response &&
      responseObj.response.alert &&
      responseObj.response.alert.length > 0 &&
      responseObj.response.alert[0].message
    ) {
      responsePayload.message = responseObj.response.alert[0].message;
    }
  }

  return responsePayload;
};

export const parseRecommendationsListing = (responseObj) => {
  let responsePayload = {
    tableData: [],
    error: false,
    message: '',
    pagination: {},
    summary: {},
  };

  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200 &&
    responseObj.data
  ) {
    if (responseObj.data && responseObj.data.length > 0) {
      responseObj.data.forEach((item) => {
        responsePayload.tableData.push({
          ...item,
          status: item.status === 'ACTIVE' ? 'ON' : 'OFF',
          created_on: Parsers.ParseTableDate(item.created_on),
          updated_on: Parsers.ParseTableDate(item.updated_on),
        });
      });
      responsePayload.error = false;
      responsePayload.pagination = {
        ...responseObj.data.pagination,
      };
    }
  } else {
    responsePayload.error = true;
    if (
      responseObj.response &&
      responseObj.response.alert &&
      responseObj.response.alert.length > 0 &&
      responseObj.response.alert[0].message
    ) {
      responsePayload.message = responseObj.response.alert[0].message;
    }
  }

  return responsePayload;
};

export const parseRecommendContentListing = (responseObj) => {
  let responsePayload = {
    tableData: [],
    error: false,
    message: '',
    pagination: {},
    summary: {},
  };

  if (responseObj) {
    responseObj.forEach((item) => {
      responsePayload.tableData.push({
        ...item,
        status: item.status === 'ACTIVE' ? 'ON' : 'OFF',
        created_on: Parsers.ParseTableDate(item.created_on),
        updated_on: Parsers.ParseTableDate(item.updated_on),
        combinedTags: item ? item?.tags?.join(', ') : '',
      });
    });
    responsePayload.error = false;
    responsePayload.pagination = {
      ...responseObj.pagination,
    };
  } else {
    responsePayload.error = true;
    if (
      responseObj.response &&
      responseObj.response.alert &&
      responseObj.response.alert.length > 0 &&
      responseObj.response.alert[0].message
    ) {
      responsePayload.message = responseObj.response.alert[0].message;
    }
  }

  return responsePayload;
};

export const parseAbTestingListing = (responseObj) => {
  let responsePayload = {
    tableData: [],
    error: false,
    message: '',
    pagination: {},
    summary: {},
  };

  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200 &&
    responseObj.data
  ) {
    if (responseObj.data && responseObj.data.length > 0) {
      responseObj.data.forEach((item) => {
        responsePayload.tableData.push({
          ...item,
          status: item.status === 'ACTIVE' ? 'ON' : 'OFF',
          published_date: Parsers.ParseTableDate(item.published_date),
          updated_date: Parsers.ParseTableDate(item.updated_date),
        });
      });
      responsePayload.error = false;
      responsePayload.pagination = {
        ...responseObj.data.pagination,
      };
    }
  } else {
    responsePayload.error = true;
    if (
      responseObj.response &&
      responseObj.response.alert &&
      responseObj.response.alert.length > 0 &&
      responseObj.response.alert[0].message
    ) {
      responsePayload.message = responseObj.response.alert[0].message;
    }
  }

  return responsePayload;
};

const ApiParser = {
  // parseLogout,
  parseAuthenticate,
  parseHabitListing,
  parseLogin,
  parseFeedListing,
  parseFaqListing,
  parseStaticContentListing,
  parseUsersListing,
  parseChallengesListing,
  parseActivitiesListing,
  parseUpdatePassword,
  parseQuizListing,
  parseMasterData,
  parseHabitTipListing,
  parseUploadMasterData,
  parseBannersListing,
  parseTC,
  parseBreathefreeListing,
  parseCelebrityListing,
  parseUnderstandListing,
  parseSplashScreenListing,
  parseDidYouKnowListing,
  parseHomeListing,
  parseHomeOrderListing,
  parseHomeOrderListingExplore,
  parseActivityVitalsListing,
  parseWebinarListing,
  parseTags,
  parseCollectionIDs,
  parseCollectionIDStory,
  parseServiceListing,
  parseProductFamilyListing,
  parseFamilyPlansListing,
  parseRecommendationsListing,
  parseRecommendContentListing,
  parseAbTestingListing,
};

export default ApiParser;
