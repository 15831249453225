import "./index.scss";
import Search from "app/components/search";
import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'; 
import Button from "app/components/button";
import CustomTable from './table';
import Pagination from "./pagination";
import SelectComp from "./select-tag";
import ArticleModal from "app/components/ArticleModal";
import { getHomePageSectionList, deleteHomePageSection} from 'app/api/homepagesection'
import PageLoader from "app/components/PageLoader";
const options = [
    { value: 10, label: 10, },
    { value: 20, label: 20, },
    { value: 30, label: 30, },
    { value: 40, label: 40, },
    { value: 50, label: 50, },
]

const HomePageSections = (tableHeader) => {
    const history = useHistory();

    const [values, setValues] = useState("");
    const [data, setData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [pageCount, setPageCount] = useState(1); 
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10); 
    const [loader, setLoader] = useState(false) 
 
    useEffect(() => {
        getContentDataHandler();
    }, []);

    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    let currentData = data.slice(indexOfFirstData, indexOfLastData)

    async function getContentDataHandler() { 
        let dataArr;
        setLoader(true);
        dataArr = await getHomePageSectionList(); 
        setLoader(false);
        if(dataArr?.data?.homepage_section_data){
            setTotalData(dataArr.data.homepage_section_data)
            setData(dataArr.data.homepage_section_data);
            let count = Math.ceil(dataArr.data.homepage_section_data.length / dataPerPage);
            setPageCount(count)
        }else{
            setTotalData([])
            setData([]);
        }
       
       
        setLoader(false)
    }

    function changeHandler(event) {
        const pageLength = event.target.value
        setDataPerPage(pageLength);
        let count = Math.ceil(data.length / pageLength);
        setPageCount(count)
    }

    function inputChangeHandler(searchVal) {
        if (searchVal.trim() === "") {
            setValues(searchVal.toLowerCase()); 
            setData(totalData);
            let count = Math.ceil(totalData.length / dataPerPage);
            setPageCount(count)
            return;
        }
        const filteredData = []
        totalData.forEach((item) => { 
            let tempvalue = searchVal.toLowerCase()
            if (item.section_name.toLowerCase().includes(tempvalue)) {
                filteredData.push(item);
            }
        });
        let count = Math.ceil(filteredData.length / dataPerPage);
        setPageCount(count)
        setData(filteredData);
        setValues(searchVal);
    };

    function paginationHandler(page) {
        setCurrentPage(page);
    }

    const handleDelete = async(data) => { 
        let text = 'Are you sure, want to delete';
        if (window.confirm(text) == true) {
            setLoader(true)
            await deleteHomePageSection(data.id) 
            getContentDataHandler()
        } 
   } 
 
    return (<> {showFilter && (
        <ArticleModal
            modalCloseClicked={() => setShowFilter(false)}
            filterApplyClicked={() => { }}
            ResetFilterCicked={() => { }}
            handleChange={() => { }}
            values={""}
            error={""}
        />
    )}
        <div className="cms-content__container">
            <h4>Landing pages \ Home Page Sections</h4>
            <div className="content-container__actionBox">
                <div className="content-container__filterSearch">
                    <Search
                        value={values}
                        placeholder="Search"
                        className={""}
                        onChange={inputChangeHandler}
                    /> 
                </div>
                <Button 
                onClick={() => history.push({
                    pathname: `/home-page-sections/create`,
                    state: {
                        totalData
                      },
                })} 
                className="content-container__filter-btn">Create Sections</Button>
            </div> 
            <div className="content-container__perPage">
                Show
                <SelectComp
                    options={options}
                    onChangeHandler={changeHandler}
                />
                entries
            </div>
            <div className="content-container__table">
                <CustomTable
                    data={currentData}
                    tableHeader={tableHeader.tableHeader}
                    handleDelete={handleDelete} 
                />
            </div>

            <div className="content-container__pagination">
                <Pagination
                    pages={pageCount}
                    onChange={paginationHandler}
                />
            </div>
        </div>
        {loader && <PageLoader pageLoading={loader} />}
    </>

    );
}

export default HomePageSections