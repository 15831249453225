/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import queryString from 'query-string';

// Components
import Loader from 'components/Loader';

import { getArticleDetail } from 'api/feed';

import './index.scss';

const ArticleDetail = (props) => {
  const location = useLocation();

  const [htmlContent, setHtmlContent] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    try {
      document.body.style.backgroundColor = 'transparent';
    } catch (ex) {}

    let articleId = null;
    let accessToken = null;
    if (props && props.match && props.match.params && props.match.params.id) {
      articleId = props.match.params.id;
    }
    if (props && props.location && props.location.search) {
      const parsed = queryString.parse(location.search);
      if (parsed.accesstoken) {
        accessToken = parsed.accesstoken;
      }
    }

    if (articleId && accessToken) {
      setLoading(true);
      const responseObj = await getArticleDetail(articleId, accessToken);
      if (
        responseObj &&
        responseObj.response &&
        responseObj.response.code === 200
      ) {
        if (responseObj.data && responseObj.data.content) {
          setHtmlContent(responseObj.data.content);
        }
      }
      setLoading(false);
    }
  }, [location]);

  if (loading) {
    return (
      <div className='article-detail'>
        <Loader />
      </div>
    );
  }

  const renderContent = () => {
    if (htmlContent) {
      return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    }

    return (
      <div className='nocontent'>
        Sorry!!! Content not available for the article
      </div>
    );
  };

  return <div className='article-detail'>{renderContent()}</div>;
};

export default ArticleDetail;
