import { CourseListHeader } from "app/constants/table-header-constants";
import CoursesCreationContainer from "app/container/CoursesCreationContainer";
import CoursesList from "app/container/CoursesCreationContainer/CoursesList";
import BasePage from "app/pages/BasePage";

const CoursesCreation = () => {
    return (
        <BasePage>
            {/* <CoursesCreationContainer /> */}
            <CoursesList tableHeader={CourseListHeader}/>
        </BasePage>
    );
}

export default CoursesCreation;