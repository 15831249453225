import React from 'react';
import Dropdown from 'app/shared/components/Dropdown';
import './index.scss';

const VersionDropDown = ({
    android_version,
    versionLists,
    iosLists,
    criteria,
    VersionRange,
    handleTextChange,
    ios_version,
    ios_criteria,
    asterisk = false
}) => {

    
  return (
    <div className="row">
        <div className="col-sm-6">
            <Dropdown 
                label='Selected Android Version'
                placeholder='Selected Android Version'
                name='android_version'
                value={android_version}
                options={versionLists}
                handleChange={handleTextChange}
                asterisk={asterisk}
            /> 
        </div>
        <div className="col-sm-6"> 
            <Dropdown 
                label='Version Range'
                placeholder='Version Range'
                name='criteria'
                value={criteria}
                options={VersionRange}
                handleChange={handleTextChange}
                asterisk={asterisk}
            />
        </div>
        <div className="col-sm-6">
            <Dropdown 
                label='Selected IOS Version'
                placeholder='Selected IOS Version'
                name='ios_version'
                value={ios_version}
                options={iosLists}
                handleChange={handleTextChange}
                asterisk={asterisk}
            /> 
        </div>
        <div className="col-sm-6"> 
            <Dropdown 
                label='Version Range'
                placeholder='Version Range'
                name='ios_criteria'
                value={ios_criteria}
                options={VersionRange}
                handleChange={handleTextChange}
                asterisk={asterisk}
            />
        </div>
    </div>
  );
};

export default VersionDropDown;
