// Pages
import { NavigationHeader } from 'constants/table-header-constants';
import NavigationContainer from 'container/NavigationContainer';
import BasePage from 'pages/BasePage';

const Navigations = () => {
  return (
    <BasePage>
      <NavigationContainer tableHeader={NavigationHeader} />
      {/* <RecommendedAction tableHeader={RecommendedActionHeader}/> */}
    </BasePage>
  );
};

export default Navigations;
