import React from 'react';
import BasePage from 'pages/BasePage';
import CreateRecommendations from 'app/container/CreateRecommendations';

import {
  RecommendContentTableHeader,
  ContentOrderingTableHeader,
} from 'constants/table-header-constants';

const RecommendationsCreatePage = () => {
  return (
    <>
      <BasePage>
        <CreateRecommendations
          tableHeader={RecommendContentTableHeader}
          draggableTableHeader={ContentOrderingTableHeader}
        />
      </BasePage>
    </>
  );
};

export default RecommendationsCreatePage;
