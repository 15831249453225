import React, { useState } from 'react';

// Constants
import { AppLogoImage } from 'constants/image-constants';
import { APP_CONSTANTS } from 'constants/app-constants';
import AltTag from 'constants/alt-tag';
// Utils
import Toast from 'utils/Toast';
import Message from 'utils/Message';

// Components
import NewInput from 'components/NewInput';
import Button from 'components/button';

// API
import { updatePassword, getEncryptedText } from 'api/onboarding';

import './index.scss';

const ResetPasswordPage = () => {
  const [password, setPassword] = useState('');
  const [confirmPass, setconfirmPass] = useState('');
  const [passStatus, setPassStatus] = useState();
  const [loading, setLoading] = useState(false);

  const getQueryParamData = (variable) => {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split(/=(.*)/s);
      if (pair[0] === variable) {
        return pair[1];
      }
    }
  };
  const formValidate = async () => {
    if (loading === true) {
      return;
    }

    if (!password || password.length < 6) {
      setPassStatus('Password should be more than 6 characters');
    } else {
      setPassStatus('');
    }

    if (!confirmPass || confirmPass.length < 6) {
      setconfirmPass('Password should be more than 6 characters');
    }

    const payload = {
      email: getQueryParamData('email'),
      otp: getQueryParamData('otp'),
      password: password,
    };

    if (!password || !confirmPass) {
      return;
    }

    setLoading(true);
    getEncryptedText(password).then((encriptedText) => {
      payload.password = encriptedText;
      updatePassword(payload, (response) => {
        setLoading(false);
        if (response && response.response.code === 200) {
          Toast.success(Message.Password.UPDATE_SUCCESS);
          setTimeout(() => {
            window.location.href = APP_CONSTANTS.LOGIN;
          }, 1000);
        } else {
          Toast.error(
            response.response.alert && response.response.alert[0].message
          );
        }
      });
    });
  };

  const handleKeypress = (e) => {
    if (e.charCode === 13) {
      formValidate();
    }
  };

  const handlePassWordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPassChange = (e) => {
    setconfirmPass(e.target.value);
  };

  return (
    <div className='App'>
      <div className='loginWrapper bg-light h-100'>
        <div className='bg-white rounded shadow col-md-4 bgimage data'>
          <form noValidate>
            <img src={AppLogoImage} alt={AltTag.AppLogo} height='30' />

            <NewInput
              id='password'
              type='password'
              placeholder='Enter Password'
              name='password'
              value={password}
              onKeyPress={handleKeypress}
              label='Password'
              onChange={handlePassWordChange}
              errorText={passStatus}
            />

            <NewInput
              id='Confirmpassword'
              type='password'
              placeholder='Confirm Password'
              name='Confirmpassword'
              value={confirmPass}
              onKeyPress={handleKeypress}
              label='Confirm Password'
              onChange={handleConfirmPassChange}
              errorText={passStatus}
            />
          </form>
          <Button
            className='mt1'
            type='submit'
            onClick={formValidate}
            disabled={loading}
          >
            {loading === true ? 'Updating...' : 'Update Password'}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default ResetPasswordPage;
