import React, { useEffect, useState, useRef } from 'react'; 
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom.min'; 
// Components
import PageLoader from 'components/PageLoader';
import NewInput from 'app/components/NewInput';
import fileTest from 'app/constants/tableConvert.com_xbcjpg.csv';
import Dropdown from 'app/shared/components/Dropdown';
import WebinarCalendar from 'app/components/WebinarCalendar';
import Message from 'app/utils/Message';
import Button from 'app/components/button'; 
import{ createPlanActivation } from 'app/api/latest/PlanActivation/planactivation'
import { getFamilyPlanswithFilter } from 'api/latest/FamilyPlans/familyPlans';
// Utils
import Toast from 'app/utils/Toast';
import { file, CrossCircle } from 'constants/image-constants';
import '../index.scss';

const ActivateContainer = ({selectedTab}) => {
  const imageRef = useRef(); 
  const params = useParams();
  const [familyplans, setFamilyPlans] = useState([])
  const [pageLoading, setPageLoading] = useState(false);
  const [fileName, setFileName] = useState(null);
  let fileUrl = fileTest;
  const [state, setState] = useState({
    mobile_number: '',
    plan_id: '',
    activation_date: '',
    csv_file: '',
  });

  useEffect(() => {
    loadFamilyPlans(selectedTab);
  }, [selectedTab]);

  const loadFamilyPlans = async (val) => {
    setPageLoading(true);
    let response = await getFamilyPlanswithFilter(val);
    setPageLoading(false);
    if (response) {
       let familyplans = []
       response.tableData.map((item, i) => {
        familyplans.push({
          label:item.product_family + ' - ' + item.plan_name,
          value: item.id,
          id: item.id,
          ...item
        })
       })
       console.log(familyplans,'familyplans')
       setFamilyPlans(familyplans)
    } else {
      
      Toast.error(
        response?.error ? response?.message : Message?.Error?.COMMON_MESSAGE
      );
    }
  };

  const handleTextChange = (e) => {
    const { value, name } = e.target;
    if (e.target.name === 'mobile_number') {
      // Remove non-digit characters
      if (value.length < 110) {
        const newValue = value.replace(/\D/g, '');
        // Format the value with commas
        const formattedValue = newValue.replace(/(\d{10})(?=\d)/g, '$1,');
        setState({ ...state, [name]: formattedValue });
      } else {
        Toast.error('You are reached Maxmimum mobile numbers ');
        return false;
      }
    } else {
      setState({ ...state, [e.target.name]: e.target.value });
    }
  };

  const handleDateChange = (date) => {
    setState({ ...state, activation_date: date });
  };

  const updateImageDisplay = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const csvFile = event.target.files[0];
      if (csvFile?.name === fileName?.name) {
        Toast.error('CSV with same name already exists. ');
        return;
      } else {
        setFileName(csvFile?.name);
        setState({ ...state, csv_file: csvFile }); 
      }
    }
  };
 
  const handleSubmit = async() => {

    let mobilevalidation = state.mobile_number?.split(',') 
    if(state.mobile_number && mobilevalidation && mobilevalidation[mobilevalidation.length -1].length < 10){
      Toast.error('Please Enter 10 digit Mobile number')
      return
    }
    if(!state.plan_id){
      Toast.error('Please Select any Plan')
      return
    }
    if(!state.activation_date){
      Toast.error('Please Select Plan Activation date')
      return
    }

      setPageLoading(true)
      let payload = {
        mobile_number: state.mobile_number,
        plan_id:state.plan_id,
        activation_date:state.activation_date,
        upload_type:state.mobile_number?'text':'csv',
        csv_file : state.csv_file,
        product_type:selectedTab
      }
    
      const responseObj = await createPlanActivation(payload);
  
      if (
        responseObj &&
        responseObj.response &&
        responseObj.response.code === 201
      ) {
        setPageLoading(false)
        Toast.success(responseObj.response.alert[0].message);
        setState({
          mobile_number: '',
          plan_id: '',
          activation_date: '',
          csv_file: '',
        })
      } else { 
        setPageLoading(false)
        Toast.error(
          responseObj.data
            ? responseObj.data.error
            : responseObj.response.alert[0].message
            ? responseObj.response.alert[0].message
            : ''
        );
      } 
    
   
  };
  
  const handleRemoveFile = () => {
    setState({ ...state, csv_file: '' });
  }
  return (
    <>
      <div className='row'>
        <div className='col-sm-6'>
          <NewInput
            label='Enter Mobile No'
            name={'mobile_number'}
            value={state.mobile_number}
            onChange={handleTextChange}
            placeholder={'Enter Up to 10 Mobile Numbers'}
            disabled={state.csv_file}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-md-5 d-flex align-items-center justify-content-center my-4'>
          <h4>(OR)</h4>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-12'>
          <div className='row'>
            <div className='col-sm-12 mb-3'>
              <label>Upload CSV</label>
            </div>
            <div className='col-sm-12'>
              {state.csv_file ? (
                <div className='row'>
                  <div className='col d-flex align-items-center borderlight'>
                    {/* <div className='box'> */}
                    <img className='file-preview' src={file} alt='File' />
                    <h4>{fileName}</h4>
                    {/* </div> */}
                  </div>
                  <div className='col'>
                    <img
                      onClick={handleRemoveFile}
                      className='file-close-icon cursor-pointer'
                      src={CrossCircle}
                      alt='File'
                    />
                  </div>
                </div>
              ) : (
                <>
                  <input
                    ref={imageRef}
                    id='file'
                    type='file'
                    style={{ display: 'none' }}
                    accept='.csv'
                    onChange={updateImageDisplay}
                    onClick={(event) => (event.target.value = '')}
                  />
                  <Button
                    htmlFor='file'
                    name='Upload CSV'
                    isDisabled={state.mobile_number.length > 0}
                    onClick={(e) => {
                      if (imageRef && imageRef.current) {
                        imageRef.current.click && imageRef.current.click();
                      }
                    }}
                  />
                  <Link
                    to={fileUrl}
                    className='ms-1 button text-decoration-none'
                    download='AB_Testing_CSV'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Download CSV
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-6'>
          <Dropdown
            label='Select Plan'
            placeholder='Select Family Plan'
            name='plan_id'
            value={state.plan_id}
            options={familyplans}
            handleChange={handleTextChange} 
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-12'>
          <WebinarCalendar
            onStartDateSelected={handleDateChange}
            startDate={state.activation_date}
            label='Activitation Date'
            minDate={new Date()}
            key={state.activation_date}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-12  my-5'>
          <Button name={params.id ? 'Update' : 'Save'} onClick={handleSubmit} />
        </div>
      </div>

      {pageLoading === true && <PageLoader pageLoading={pageLoading} />}
    </>
  );
};

export default ActivateContainer;
