import Button from 'components/button';
import Search from 'app/components/search';
import './index.scss';
// import Pagination from "./pagination";
import SelectComp from './SelectComp';

import NumberInput from 'app/components/UI/NumberInput';
import { APP_CONSTANTS } from 'app/constants/app-constants';
import Pagination from '../../components/ReactPagination';
import ContentTable from './ContentTable';
import useCouponAndOffer from './useCoins';
import PageLoader from 'app/components/PageLoader';

const options = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
  { value: 40, label: 40 },
  { value: 50, label: 50 },
];

const BreathfreeCoins = () => {
  const {
    pageLoading,
    values,
    inputChangeHandler,
    changeHandler,
    conversionData,
    currentData,
    data,
    getContentDataHandler,
    currentPage,
    dataPerPage,
    pageMeta,
    pageCount,
    paginationHandler,
    history,
    handleChange,
    updateConversionData,
  } = useCouponAndOffer();
  return (
    <>
      <div className='cms-content__container'>
        <h4>Breathfree Coins</h4>

        <div className='content-container__actionBox'>
          <div className='content-container__filterSearch'>
            <NumberInput
              label='Breathfree Coins'
              id='coins'
              placeholder='100 Breathfree Coins'
              name='coins'
              value={conversionData.coins}
              onChange={handleChange}
            />
            <NumberInput
              label='Rupees'
              id='rupees'
              placeholder='1'
              name='rupees'
              value={conversionData.rupees}
              onChange={handleChange}
            />
          </div>
          <Button
            onClick={() =>
              history.push(`${APP_CONSTANTS?.BREATHFREE_CREATE_COIN}`)
            }
            className='content-container__filter-btn'
          >
            Create Coins
          </Button>
        </div>
        <div>
          <Button
            className='mt1'
            onClick={updateConversionData}
            type='submit'
            name='Submit'
            isDisabled={!conversionData?.coins || !conversionData?.rupees}
          />
        </div>
        {console.log(
          conversionData?.coins === '' || conversionData?.rupees === ''
        )}
        <div className='content-container__perPage'>
          Show
          <SelectComp options={options} onChangeHandler={changeHandler} />
          entries
        </div>
        <div className='search_and_title'>
          <div>COINS - PRODUCT FAMILY LIST </div>

          <div>
            <Search
              value={values}
              placeholder='Search'
              className={''}
              onChange={inputChangeHandler}
            />
          </div>
        </div>
        <div className='content-container__table'>
          <ContentTable
            content={currentData}
            totalData={data}
            getContentDataHandler={getContentDataHandler}
            currentPage={currentPage}
            dataPerPage={dataPerPage}
          >
            <div className='content-container__pagination'>
              <Pagination
                pageMeta={pageMeta}
                initialPage={Number(pageCount)}
                handleChange={paginationHandler}
              />
            </div>
          </ContentTable>
        </div>
        {pageLoading && <PageLoader pageLoading={pageLoading} />}
      </div>
    </>
  );
};

export default BreathfreeCoins;
