import React from 'react';

// Pages
import BasePage from 'pages/BasePage';
import Feeds from 'container/Feeds';

import { NewsFeedTableHeader } from 'constants/table-header-constants';

const NewsFeedPage = () => {
  return (
    <BasePage>
      <Feeds tableHeader={NewsFeedTableHeader} />
    </BasePage>
  );
};

export default NewsFeedPage;
