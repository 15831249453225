import Select from 'react-select';
import useAbTesting from './useAbTesting';

// Components
import Button from 'components/button';
import HeaderWithBackButton from 'components/HeaderWithBackButton';
import NewInput from 'components/NewInput';
import PageLoader from 'components/PageLoader';

// API

// Constants
import { APP_CONSTANTS } from 'constants/app-constants';
import {
  filteredOptionsData,
  moduleOptions,
  productCatalogOptions,
  usersOptions,
  variantOptions,
} from 'JsonData/ab-testing-dropdowns-list';

// Utils
import Toggle from 'react-toggle';

const CreateAbTesting = () => {
  const {
    moduleSelectedOption,
    handleModuleSelectChange,
    productCatalogSelectedOption,
    handleProductCatalogSelectChange,
    servicesSelectedOption,
    handleServicesSelectChange,
    servicesOptions,
    variantSelectedOption,
    handleVariantSelectChange,
    variantAPercent,
    handleInputVariantAPercentChange,
    optionASelectedOption,
    handleAVariantSelectChange,
    selectedProductBaseOptions,
    variantBPercent,
    handleInputVariantBPercentChange,
    optionBSelectedOption,
    handleBVariantSelectChange,
    variantCPercent,
    handleInputVariantCPercentChange,
    optionCSelectedOption,
    handleCVariantSelectChange,
    usersSelectedOption,
    handleUsersSelectChange,
    addFiltersSelectedOptions,
    handleAddFiltersMultipleSelectChange,
    variantAOptions,
    toTitleCase,
    selectedDropdownAValues,
    handleADropdownChange,
    variantBOptions,
    selectedDropdownBValues,
    handleBDropdownChange,
    variantCOptions,
    selectedDropdownCValues,
    handleCDropdownChange,
    toggleStatus,
    handleToggleChange,
    handleCreateAbTest,
    pageLoading,
  } = useAbTesting();

  return (
    <>
      <div className='py-3 px-5'>
        <HeaderWithBackButton
          title='Add/edit A/B Test'
          goTo={APP_CONSTANTS.CMS_VITALS_AB_TESTING}
        />
        <label className='mt-4 d-block w-25'>
          Module*
          <select
            className='mt-2 w-100 d-block c-select'
            name='module'
            value={moduleSelectedOption}
            onChange={handleModuleSelectChange}
          >
            <option value=''>Please select an option</option>
            {moduleOptions &&
              moduleOptions?.map((option) => (
                <option key={option.id} value={option.name}>
                  {option.name}
                </option>
              ))}
          </select>
        </label>

        <label className='mt-4 d-block w-25'>
          Product Catalog*
          <select
            className='mt-2 w-100 d-block c-select'
            name='product_catelog'
            value={productCatalogSelectedOption}
            onChange={handleProductCatalogSelectChange}
          >
            <option value=''>Please select an option</option>
            {productCatalogOptions &&
              productCatalogOptions?.map((option) => (
                <option key={option.id} value={option.name}>
                  {option.name === 'FAMILY_PLANS' && 'Family Plans'}
                  {option.name === 'COUPONS_AND_OFFERS' && 'Coupons & Offers'}
                  {option.name === 'RECOMMENDATIONS' && 'Recommendations'}
                </option>
              ))}
          </select>
        </label>

        <label className='mt-4 d-block w-100'>
          Services*
          <select
            className='mt-2 w-100 d-block c-select'
            name='services'
            value={servicesSelectedOption}
            onChange={handleServicesSelectChange}
          >
            <option value=''>Please select an option</option>
            {servicesOptions &&
              servicesOptions?.map((option) => (
                <option key={option.id} value={option.name}>
                  {option.name}
                </option>
              ))}
          </select>
        </label>

        <label className='mt-4 d-block w-25'>
          Variants*
          <select
            className='mt-2 w-100 d-block c-select'
            name='variant'
            value={Number(variantSelectedOption)}
            onChange={handleVariantSelectChange}
          >
            <option value=''>Please select an option</option>
            {variantOptions &&
              variantOptions?.map((option) => (
                <option key={option.id} value={Number(option.value)}>
                  {option.value}
                </option>
              ))}
          </select>
        </label>

        {/* Product Base Variants Start */}
        <div className=' col-12 mt-4'>
          <div className='row align-items-start'>
            {Number(variantSelectedOption) === 2 ||
            Number(variantSelectedOption) === 3 ? (
              <>
                <div className='col'>
                  <p className='c-bol'>Variant A</p>
                  <NewInput
                    id='variant_a_percent'
                    type='number'
                    name='variant_a_percent'
                    placeholder='%'
                    value={variantAPercent}
                    onChange={handleInputVariantAPercentChange}
                  />
                  <label className='mt-4 d-block w-100'>
                    {productCatalogSelectedOption === 'FAMILY_PLANS' &&
                      'Family Plans'}
                    {productCatalogSelectedOption === 'COUPONS_AND_OFFERS' &&
                      'Coupons & Offers'}
                    {productCatalogSelectedOption === 'RECOMMENDATIONS' &&
                      'Recommendations'}
                    <select
                      className='mt-2 w-100 d-block c-select'
                      name='services'
                      value={optionASelectedOption}
                      onChange={handleAVariantSelectChange}
                    >
                      <option value=''>Please select an option</option>
                      {selectedProductBaseOptions &&
                        selectedProductBaseOptions?.map((option) => (
                          <option key={option.id} value={option.name}>
                            {option.name}
                          </option>
                        ))}
                    </select>
                  </label>
                </div>

                <div className='col'>
                  <p className='c-bol'>Variant B</p>
                  <NewInput
                    id='variant_b_percent'
                    type='number'
                    name='variant_b_percent'
                    placeholder='%'
                    value={variantBPercent}
                    onChange={handleInputVariantBPercentChange}
                  />
                  <label className='mt-4 d-block w-100'>
                    {productCatalogSelectedOption === 'FAMILY_PLANS' &&
                      'Family Plans'}
                    {productCatalogSelectedOption === 'COUPONS_AND_OFFERS' &&
                      'Coupons & Offers'}
                    {productCatalogSelectedOption === 'RECOMMENDATIONS' &&
                      'Recommendations'}
                    <select
                      className='mt-2 w-100 d-block c-select'
                      name='services'
                      value={optionBSelectedOption}
                      onChange={handleBVariantSelectChange}
                    >
                      <option value=''>Please select an option</option>
                      {selectedProductBaseOptions &&
                        selectedProductBaseOptions?.map((option) => (
                          <option key={option.id} value={option.name}>
                            {option.name}
                          </option>
                        ))}
                    </select>
                  </label>
                </div>
              </>
            ) : null}
            {Number(variantSelectedOption) === 3 && (
              <>
                <div className='col'>
                  <p className='c-bol'>Variant C</p>
                  <NewInput
                    id='variant_c_percent'
                    type='number'
                    name='variant_c_percent'
                    placeholder='%'
                    value={variantCPercent}
                    onChange={handleInputVariantCPercentChange}
                  />
                  <label className='mt-4 d-block w-100'>
                    {productCatalogSelectedOption === 'FAMILY_PLANS' &&
                      'Family Plans'}
                    {productCatalogSelectedOption === 'COUPONS_AND_OFFERS' &&
                      'Coupons & Offers'}
                    {productCatalogSelectedOption === 'RECOMMENDATIONS' &&
                      'Recommendations'}
                    <select
                      className='mt-2 w-100 d-block c-select'
                      name='services'
                      value={optionCSelectedOption}
                      onChange={handleCVariantSelectChange}
                    >
                      <option value=''>Please select an option</option>
                      {selectedProductBaseOptions &&
                        selectedProductBaseOptions?.map((option) => (
                          <option key={option.id} value={option.name}>
                            {option.name}
                          </option>
                        ))}
                    </select>
                  </label>
                </div>
              </>
            )}
          </div>
        </div>
        {/* Product Base Variants End */}

        <label className='mt-4 d-block w-25'>
          Users*
          <select
            className='mt-2 w-100 d-block c-select'
            name='user_type'
            value={usersSelectedOption}
            onChange={handleUsersSelectChange}
          >
            <option value=''>Please select an option</option>
            {usersOptions &&
              usersOptions?.map((option) => (
                <option key={option.id} value={option.name}>
                  {option.name}
                </option>
              ))}
          </select>
        </label>

        {/* For Selective User Start */}
        {usersSelectedOption === 'Selective' && (
          <div>
            <label className='mt-4 d-block w-100'>
              Add Filters*
              <Select
                isMulti
                options={filteredOptionsData?.map((option) => ({
                  value: option.value,
                  label: option.label,
                }))}
                value={addFiltersSelectedOptions}
                onChange={handleAddFiltersMultipleSelectChange}
                className='mt-2'
              />
            </label>

            <div className='row mt-4'>
              {Number(variantSelectedOption) === 2 ||
              Number(variantSelectedOption) === 3 ? (
                <>
                  <div className='col'>
                    {variantAOptions.length > 0 && (
                      <div>
                        <h4 className='m-0'>Variant A</h4>

                        <NewInput
                          id='variant_data_a_percent'
                          type='number'
                          name='variant_data_a_percent'
                          placeholder='%'
                          value={variantAPercent}
                          readOnly
                        />
                        {variantAOptions?.map((filter, index) => (
                          <div key={index}>
                            <label className='mt-3'>
                              {toTitleCase(filter.label)}
                            </label>
                            <Select
                              isMulti
                              options={filter.options}
                              className='mt-2'
                              value={selectedDropdownAValues[filter.label]}
                              onChange={(selectedOption) =>
                                handleADropdownChange(
                                  filter.label,
                                  selectedOption
                                )
                              }
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <div className='col'>
                    {variantBOptions.length > 0 && (
                      <div>
                        <h4 className='m-0'>Variant B</h4>
                        <NewInput
                          id='variant_data_b_percent'
                          type='number'
                          name='variant_data_b_percent'
                          placeholder='%'
                          value={variantBPercent}
                          readOnly
                        />
                        {variantBOptions?.map((filter, index) => (
                          <div key={index}>
                            <label className='mt-3'>
                              {toTitleCase(filter.label)}
                            </label>
                            <Select
                              isMulti
                              options={filter.options}
                              className='mt-2'
                              value={selectedDropdownBValues[filter.label]}
                              onChange={(selectedOption) =>
                                handleBDropdownChange(
                                  filter.label,
                                  selectedOption
                                )
                              }
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </>
              ) : null}
              {Number(variantSelectedOption) === 3 && (
                <>
                  <div className='col'>
                    {variantCOptions.length > 0 && (
                      <div>
                        <h4 className='m-0'>Variant C</h4>
                        <NewInput
                          id='variant_data_c_percent'
                          type='number'
                          name='variant_data_c_percent'
                          placeholder='%'
                          value={variantCPercent}
                          readOnly
                        />
                        {variantCOptions?.map((filter, index) => (
                          <div key={index}>
                            <label className='mt-3'>
                              {toTitleCase(filter.label)}
                            </label>
                            <Select
                              isMulti
                              options={filter.options}
                              className='mt-2'
                              value={selectedDropdownCValues[filter.label]}
                              onChange={(selectedOption) =>
                                handleCDropdownChange(
                                  filter.label,
                                  selectedOption
                                )
                              }
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        {/* For Selective User End */}

        {/* Status Toggle Start */}
        <div className='d-block my-4'>
          <span className='me-2'>Status*</span>
          <label className='d-inline-block'>
            <div className='d-inline-flex align-items-center'>
              <div className='d-inline-flex text-danger'>OFF</div>
              <div className='d-inline-flex mx-2'>
                <Toggle
                  checked={toggleStatus}
                  icons={false}
                  onChange={handleToggleChange}
                />
              </div>
              <div className='d-inline-flex text-green'>ON</div>
            </div>
          </label>
        </div>

        <Button onClick={handleCreateAbTest} type='submit' name='Submit' />
      </div>

      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </>
  );
};

export default CreateAbTesting;
