import { useHistory } from 'react-router';
import './index.scss';

function CustomTable({
  data,
  tableHeader,
  handleInputChangeHandler,
  handleEdit,
  handleDelete,
}) {
  const history = useHistory();
  const handleNavigation = (items) => {
    if (items.exercise_type === 'VIDEO_EXERCISE') {
      history.push({
        pathname: `/breathing-exercise/edit/${items.id}`,
      });
    }
  };

  return (
    <table>
      <tr>
        {tableHeader.map((item, i) => {
          return <th className='nowrap'>{item.Header}</th>;
        })}
      </tr>
      {data.map((items, index) => (
        <tr key={index}>
          <td>{items.title}</td>
          <td>{items.id}</td>
          <td>{items.updated_on}</td>
          {/* <td></td> */}
          <td>{items.exercise_type}</td>
          <td>
            <div
              className='btn-group'
              role='group'
              aria-label='Basic outlined example'
            >
              <button
                type='button'
                className='btn btn-outline-primary'
                onClick={() => handleNavigation(items)}
                disabled={items.exercise_type !== 'VIDEO_EXERCISE'}
              >
                Edit
              </button>
              <button
                type='button'
                onClick={() => handleDelete(items)}
                disabled={items.exercise_type !== 'VIDEO_EXERCISE'}
                className='btn btn-outline-primary'
              >
                Delete
              </button>
            </div>
          </td>
          <td>
            <input
              type='text'
              className='form-input'
              placeholder=''
              name='asthma_order'
              value={items.asthma_order}
              onChange={(e) =>
                handleInputChangeHandler('asthma_order', e, index)
              }
              disabled={items.isDisabled}
            />
          </td>
          <td>
            <input
              type='text'
              className='form-input'
              placeholder=''
              name='copd_order'
              value={items.copd_order}
              onChange={(e) => handleInputChangeHandler('copd_order', e, index)}
              disabled={items.isDisabled}
            />
          </td>
          <td>
            <input
              type='text'
              className='form-input'
              placeholder=''
              name='ild_order'
              value={items.ild_order}
              onChange={(e) => handleInputChangeHandler('ild_order', e, index)}
              disabled={items.isDisabled}
            />
          </td>
          <td>
            <input
              type='text'
              className='form-input'
              placeholder=''
              name='ar_order'
              value={items.ar_order}
              onChange={(e) => handleInputChangeHandler('ar_order', e, index)}
              disabled={items.isDisabled}
            />
          </td>
          <td>
            <input
              type='number'
              className='form-input'
              placeholder=''
              name='other_order'
              value={items.other_order}
              onChange={(e) =>
                handleInputChangeHandler('other_order', e, index)
              }
              disabled={items.isDisabled}
            />
          </td>
          <td>
            <div
              className='btn-group'
              role='group'
              aria-label='Basic outlined example'
            >
              <button
                type='button'
                className='btn btn-outline-primary'
                onClick={() => {
                  handleEdit(items, items.isDisabled ? 'Edit' : 'Save', index);
                }}
              >
                {items.isDisabled ? 'Edit' : 'Save'}
              </button>
            </div>
          </td>
        </tr>
      ))}
      {data.length === 0 && (
        <tr>
          <td colspan='10000'>No data found</td>{' '}
        </tr>
      )}
    </table>
  );
}

export default CustomTable;
