import React, { useEffect, useRef, useState } from 'react';
import Toggle from 'react-toggle';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { APP_CONSTANTS } from 'app/constants/app-constants';
import NewInput from 'app/components/NewInput';
import TextArea from 'app/components/Textarea';
import NewAttachment from 'app/components/NewDesign/NewAttachment';
import Toast from 'app/utils/Toast';
import Message from 'app/utils/Message';
import {
  addUpdateChapter,
  getChapterById,
} from 'app/api/latest/Courses/courses';
import Attachment from 'app/components/Attachment';
import NewHtmlEditor from 'app/components/NewDesign/NewHtmlEdior';

const CoursesVideoContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const editorRef = useRef(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [chapterData, setChapterData] = useState([]);
  const [state, setState] = useState({
    publish_status: false,
    title: '',
    video_thumbnail: '',
    video_thumbnail_attachment: '',
    video_file: '',
    video_attachment_file: '',
    video_url: '',
    duration_minute: '',
    duration_second: '',
    text_content: '',
  });

  const course_id = location?.state?.courseId
    ? location?.state?.courseId
    : chapterData?.course_id;

    const courseTitle = location?.state?.courseTitle;

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleEditorChange = (name, value) => {
    setState({ ...state, [name]: value });
  };

  const onVideoImageSeleted = (file) => {
    setState((prevState) => ({
      ...prevState,
      video_thumbnail_attachment: file,
    }));
  };
  const onVideoSeleted = (file) => {
    setState((prevState) => ({
      ...prevState,
      video_attachment_file: file,
    }));
  };

  const params = useParams();

  const courseDuration = (value) => {
    let splitTime = value.split(':');
    return splitTime;
  };

  const getChapterDetails = async (id) => {
    setPageLoading(true);
    const response = await getChapterById(id);
    setChapterData(response?.data);
    if (response && response) {
      setState({
        ...state,
        publish_status: response.data?.publish_status === 'ACTIVE' ? true : false,
        title: response.data?.title,
        video_thumbnail: response.data?.chapter_image,
        video_thumbnail_attachment: null,
        video_file: response.data?.chapter_video,
        video_attachment_file: null,
        video_url: response.data?.chapter_url,
        duration_minute: courseDuration(response?.data?.chapter_duration)[0],
        duration_second: courseDuration(response?.data?.chapter_duration)[1],
        text_content: null,
        chapter_description: response.data?.chapter_description,
      });
    }
    setPageLoading(false);
  };

  useEffect(() => {
    if (params?.id) {
      getChapterDetails(params?.id);
    }
  }, []);

  const handleSubmit = async () => {
    if (!course_id) return;

    if (!state.title) {
      Toast.error('Title is required');
      return;
    }

    if (!state.video_thumbnail_attachment && !state.video_thumbnail) {
      Toast.error('Video Thumbnail is required');
      return;
    }

    if (!state.video_attachment_file && !state.video_file && !state.video_url) {
      Toast.error('Either Video File or Video URL is required');
      return;
    }

    if (!state.duration_minute) {
      Toast.error('Duration (Minutes) is required');
      return;
    }

    if (!state.duration_second || state.duration_second < 0 || state.duration_second > 59) {
      Toast.error('Duration (Seconds) is required and should be between 0 and 59');
      return;
    }

    if (!state.chapter_description) {
      Toast.error('Chapter content is required');
      return;
    }

    let payload = {
      status: state.publish_status,
      title: state.title,
      chapter_image: state.video_thumbnail_attachment
        ? state.video_thumbnail_attachment
        : state.video_thumbnail,
      chapter_video: state.video_attachment_file,
      chapter_url: state.video_url,
      text_content: state.text_content,
      chapter_type: 'VIDEO',
      chapter_description: state.chapter_description,
      chapter_duration: state.duration_minute
        ? `${state.duration_minute}:${state.duration_second}`
        : '',
      course_id: course_id ? course_id : '',
      chapter_id: params?.id ? params?.id : '',
    };

    delete payload.duration_minute;
    delete payload.duration_second;

    setPageLoading(true);
    let response = await addUpdateChapter(payload);

    setPageLoading(false);
    if (response?.response?.code === 200 || response?.response?.code === 201) {
      Toast.success(
        response.response.code === 200
          ? Message.Courses.CHAPTER_CREATED_SUCCESS
          : Message.Courses.CHAPTER_UPDATED_SUCCESS
      );

      history.goBack();
    } else {
      Toast.error(
        response?.data ? response?.data : Message.Error.COMMON_MESSAGE
      );
    }
  };

  return (
    <>
      <div className='section-header'>
        <h4>Course Creation &gt; {courseTitle} &gt; {state?.title ? state?.title : 'Enter Video Chapter' }</h4>
      </div>

      <section className='course-section'>
        <div className='course-section_title'>
          <h4 className={!state?.title && 'heading-fade'}>{state?.title ? state?.title : 'Enter Video Chapter' }</h4>
          <Toggle
            checked={state.publish_status}
            icons={false}
            name='ios'
            onChange={(e) => {
              setState({
                ...state,
                publish_status: !state.publish_status,
              });
            }}
          />
        </div>

        <div className='_content'>
          <div className='form-wrapper'>
            <div className='form-field'>
              <NewInput
                label='Title'
                name='title'
                value={state.title}
                onChange={handleChange}
                asterisk={true}
              />
            </div>
            <div className='form-field'>
              <NewAttachment
                label='Add Video Thumbnail'
                name='video_thumbnail'
                value={state.video_thumbnail}
                id='CI'
                onFileSelected={onVideoImageSeleted}
                fileType='image'
                className='widget_box_feed__attachment'
                asterisk={true}
              />
            </div>
            <div className='video-field-wrap'>
              <div className='form-field'>
                <Attachment
                  onFileSelected={onVideoSeleted}
                  value={state.video_file}
                  onVideoThumbnail={null}
                  videoThumbnail={null}
                  noshowThumbnail={true}
                  className='attachment__selimage'
                  asterisk={true}
                />
              </div>
              <span className='or-text'>OR</span>

              <div className='form-field'>
                <NewInput
                  label='Paste Video URL'
                  name='video_url'
                  value={state.video_url}
                  onChange={handleChange}
                  asterisk={true}
                />
              </div>
              <div className='form-field duration-field'>
                <label>Duration<span className='asterisk'>*</span></label>
                <div className='two-field'>
                  <NewInput
                    placeholder='MM'
                    name={'duration_minute'}
                    type='number'
                    value={state.duration_minute}
                    onChange={handleChange}
                  />
                  <span>:</span>
                  <NewInput
                    placeholder='SS'
                    name={'duration_second'}
                    type='number'
                    value={state.duration_second}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            <div className='form-field w-100'>
                <NewHtmlEditor
                  label='Add Text Content'
                  value={state.chapter_description}
                  onChange={(current) => {
                    handleEditorChange('chapter_description', current);
                  }}
                  editorRef={editorRef}
                  asterisk={true}
                />
              <div className='d-flex flex-row-reverse mt-4'>
                <button className='primary-btn button' onClick={handleSubmit}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CoursesVideoContainer;
