import { useHistory } from 'react-router';
// import '../index.scss';
import moment from 'moment';

function CustomTable({
  data,
  tableHeader,
  handleDelete,
  handleInputChangeHandler,
  handleEdit,
  Toggle,
  isDisabled,
  setSelectedID,
  selectedID
}) {
  const history = useHistory();

  //handle navigation
//   const handleNavigation = (item) => {
//     history.push({
//       pathname: `/dynamic-widgets/edit/${item.widget_id}`,
//       state: {
//         ...item,
//       },
//     });
//   };
//  
  return (
    <table>
      <tr>
        {tableHeader.map((item, i) => {
          return <th className='nowrap text-center'>{item.Header}</th>;
        })}
      </tr>
      {data.map((items, indx) => (
        <tr key={indx}>
          <td>
            <p>
              <span className='text-primary'>{items.med_name}</span>
            </p>
          </td>
          <td>
          {items.sku_id}
          </td>
          
          <td>
            <input
                type='number'
                className='form-input'
                placeholder=''
                name='discount'
                value={items.discount}
                onChange={(e) => handleInputChangeHandler('discount', e, indx)}
                disabled={selectedID === items.sku_id ? false :true}
                />
          </td>
         

          <td>
          <div
              className='btn-group'
              role='group'
              aria-label='Basic outlined example'
            >
              <button
                type='button'
                onClick={() => {
                  handleDelete('discount', indx);
                }}
                className='btn btn-outline-primary'
              >
                Delete
              </button>
            </div>
          </td>
          <td>
            <div
              className='btn-group'
              role='group'
              aria-label='Basic outlined example'
            >
              <button
                type='button'
                className='btn btn-outline-primary'
                onClick={() => {
                  // handleEdit(items, !isDisabled ? 'Edit' : 'Save', indx);
                  if(selectedID)  setSelectedID('')
                  else
                  setSelectedID(items.sku_id)
                }}
                // disabled={Toggle}
              >
                {selectedID === items.sku_id ? 'Save' : 'Edit'}
              </button>
            </div>
          </td>
          
        </tr>
      ))}
      {data.length === 0 && (
        <tr>
          <td colspan='10000'>No data found</td>{' '}
        </tr>
      )}
      {/* {data.length === 0 ? (
        <tr>
          <td colspan='10000'>No data found</td>{' '}
        </tr>
      )
      :
      <tr>
        <td colspan={5} style={{'textAlign':'right'}}><h5><b>Estimated Total</b></h5></td>
        <td>100</td>
      </tr>
    
    } */}
    </table>
  );
}

export default CustomTable;
