import React from 'react';
import BasePage from 'pages/BasePage';
import CreateActivities from 'container/CreateActivities';

const GamificationCreatePage = () => {
  return (
    <BasePage>
      <CreateActivities />
    </BasePage>
  );
};

export default GamificationCreatePage;
