import { useEffect, useState } from 'react';
import './index.scss';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import RatingList from './RatingList';
import { RatingListHeader } from 'app/constants/table-header-constants';
import { getNudgeList, updateNudgeList } from 'app/api/rating';
import NewInput from 'app/components/NewInput';
import Toast from 'app/utils/Toast';

const RatingPage = () => {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  const [nudge, setNudge] = useState({
    android: '',
    ios: '',
    id: '',
  });

  useEffect(() => {
    getContentDataHandler();
  }, []);

  const handleTextChange = (e) => {
    setNudge({ ...nudge, [e.target.name]: parseInt(e.target.value) });
  };

  async function getContentDataHandler() {
    setLoader(true);
    const dataArr = await getNudgeList();
    if (dataArr && dataArr) {
      setData(dataArr);
      setNudge({
        android: dataArr?.android,
        ios: dataArr?.ios,
        id: dataArr?.id,
      });
    }
    setLoader(false);
  }

  const handleSubmit = async () => {
    let nudgeData = { ...nudge };
    let payload = {
      android: nudgeData?.android,
      ios: nudgeData?.ios,
    };
    let nudgeCurrentId = nudgeData?.id;
    console.log(payload, 'checking payload for nudge');
    let responseObj;
    responseObj = await updateNudgeList(payload, nudgeCurrentId);
    if (
      responseObj &&
      responseObj.response &&
      responseObj.response.code === 200
    ) {
      Toast.success(responseObj.response.alert[0].message);
      getContentDataHandler();
    } else {
      setLoader(false);
      Toast.error(
        responseObj.data
          ? responseObj.data.msg
          : responseObj.response.alert[0].message
          ? responseObj.response.alert[0].message
          : ''
      );
    }
    setLoader(false);
  };

  return (
    <>
      <div className='blogs'>
        <section className='section-header'>
          <h1 className='section-header__title'>App Ratings</h1>
        </section>
        <div className='form-wrapper rating-form nudge-rating-form'>
          <div className='form-title'>Nudge resend in</div>
          <div className='field'>
            <NewInput
              label='Android'
              name={'android'}
              value={nudge.android}
              onChange={handleTextChange}
              type='number'
            />
            <div className='nudge-days'>Days</div>
          </div>
          <div className='field'>
            <NewInput
              label='Ios'
              name={'ios'}
              value={nudge.ios}
              onChange={handleTextChange}
              type='number'
            />
            <div className='nudge-days'>Days</div>
          </div>
          <div>
            <button className='button' onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
      <div className='table-container'>
        <RatingList tableHeader={RatingListHeader} />
      </div>
    </>
  );
};
export default RatingPage;
