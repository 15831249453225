import { useState } from "react";

function Pagination({ pages, onChange }) {

     const pagesArray = [];
     const [currentPageNo, setCurrentPageNo] = useState(1);

     for (let i = 1; i <= pages; i++) {
          pagesArray.push(i);
     };

     function passPageNoHandler(page) {
          setCurrentPageNo(page)
          onChange(page);
     }
     function nextPageNoHandler() {
          if (currentPageNo === pages) {
               return;
          }
          setCurrentPageNo((prev => {
               let page = prev + 1
               onChange(page);
               return page;
          }))
     }
     function prevPageNoHandler() {
          if (currentPageNo === 1) {
               return;
          }
          setCurrentPageNo((prev => {
               let page = prev - 1
               onChange(page);
               return page;
          }))
     };

     return (<nav aria-label="Page navigation example">
          <ul className="pagination &hover:pointer">
               <li onClick={prevPageNoHandler} className="page-item page-link cursor-pointer">
                    {'<<'}
               </li>
               {pagesArray.map((page, i) => (<li key={i}
                    onClick={() => passPageNoHandler(page)}
                    className="page-item page-link cursor-pointer"
                    id={page === currentPageNo ? "active-page" : ""}
               >
                    {page}
               </li>))}
               <li onClick={nextPageNoHandler} className="page-item page-link cursor-pointer">
                    {'>>'}
               </li>
          </ul>
     </nav>)
};

export default Pagination;