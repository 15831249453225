import React from 'react';

// Pages
import BasePage from 'pages/BasePage';
import SubCategory from 'container/LatestCategories/SubCategory';

const SubCategoriesPage = () => {
  return (
    <BasePage>
      <SubCategory />
    </BasePage>
  );
};

export default SubCategoriesPage;
