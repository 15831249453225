import { useHistory } from "react-router"
import './index.scss'
import moment from 'moment';

function CustomTable({ data, tableHeader, handleDelete,handleUnpublish }) { 
     const history = useHistory()
     const handleNavigation = (items) =>{
          
          history.push({
               pathname: `/userjourney/adtask/edit/${items.id}`,
               state: {
                    ...items,
               },
          });
     }
     return (
          <table>
               <tr>
                    {tableHeader.map((item,i) => {
                         return (
                              <th className='nowrap text-center'>{item.Header}</th>
                         )
                    })} 
               </tr>
               {data.map((items, index) => (<tr key={index}>
                    <td>{items.title}</td>
                    
                    <td>{items.cohort_tags?items.cohort_tags.toString(): '-'}</td>
                    <td>{items.streak_days?items.streak_days.toString():'-'}</td>
                    <td>{items.published_date?moment(items.published_date).format('DD-MM-YYYY'):'-'}</td> 
                    <td>{items.updated_date?moment(items.updated_date).format('DD-MM-YYYY'):"-"}</td> 
                    <td>{items.id}</td> 
                    <td>{items.ad_type}</td> 
                    <td>{items.status?items.status:"-"}</td> 
                    {/* <td>{items.expired_on?moment(items.expired_on).format('DD-MM-YYYY'):"-"}</td>  */}
                    <td>
                    <div className="btn-group" role="group" aria-label="Basic outlined example">
                              <button
                                   type="button"
                                   className="btn btn-outline-primary"
                                   onClick={() => handleNavigation(items) }
                              >
                                   Edit
                              </button>
                              <button type="button"
                                   onClick={() => handleUnpublish(items) }
                                   className="btn btn-outline-primary">{items.status ==="ACTIVE"? "Unpublish" : "Publish"}
                              </button>
                               <button type="button"
                                   onClick={() => handleDelete(items) }
                                   className="btn btn-outline-primary">Delete
                              </button>
                               
                         </div> 
                    </td>
                   
               </tr>))}
               {data.length ===0 &&  <tr><td colspan="10000">No data found</td> </tr>}
          </table>
     )
}

export default CustomTable