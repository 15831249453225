import React from 'react';

// Pages
import BasePage from 'pages/BasePage';
import User from 'container/User';

import { UsersTableHeader } from 'constants/table-header-constants';

const UserPage = () => {
  return (
    <BasePage>
      <User tableHeader={UsersTableHeader} />
    </BasePage>
  );
};

export default UserPage;
