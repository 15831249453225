import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { APP_CONSTANTS, LocalSessionKey } from 'constants/app-constants';
import { LocalStorage } from 'utils/storage';
import { handleDecodeValues } from 'app/utils/Parsers';
// import SideMenuData from 'app/JsonData/menu';
import SideMenuData from 'JsonData/SideMenuData';

const isUserLoggedIn = () => {
  const EncodedData = localStorage.getItem('EncodedValues');
  const accessToken = LocalStorage.get(LocalSessionKey.ACCESS_TOKEN);
  if (accessToken && EncodedData) {
    return true;
  }
  return false;
};

const ProtectedRoute = ({ component: Component, key: Key, ...rest }) => {
  // console.log('ddd', rest);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isUserLoggedIn()) {
          return (
            <Redirect
              to={{
                pathname: APP_CONSTANTS.LOGIN,
                state: { from: props.location },
              }}
            />
          );
        }
        if (rest.isDontHaveAccess) {
          return (
            <Redirect
              to={{
                pathname: APP_CONSTANTS.PAGE_NOT_FOUND,
                state: { from: props.location },
              }}
            />
          );
        }

        return <Component key={Key} {...props} />;
      }}
    />
  );
};

const handlePushRoute = () => {
  const intialValue = handleDecodeValues();
  // debugger;
  let pathname;
  // debugger;
  if (intialValue) {
    // console.log(intialValue, 'abc1');
    let feedData = SideMenuData.filter((val) => {
      return intialValue.includes(val.key);
    });
    pathname = feedData[0].to;
  } else {
    pathname = APP_CONSTANTS.LOGIN;
  }
  return pathname;
};

export const LogonRoute = ({ component: Component, key: Key, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isUserLoggedIn()) {
          return (
            <Redirect
              to={{
                pathname: handlePushRoute(),
                state: { from: props.location },
              }}
            />
          );
        }

        return <Component key={Key} {...props} />;
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.func,
  location: PropTypes.objectOf(PropTypes.any),
};

ProtectedRoute.defaultProps = {
  component: null,
  location: null,
};

export default ProtectedRoute;
