import React, { useState, useEffect, useCallback  } from 'react';
import CustomTable from './table';
import { getAuditLogs, searchAuditLogs } from "app/api/auditlogs";
import PageLoader from "app/components/PageLoader"; 
import Search from "app/components/search";
import ReactPaginate from 'react-paginate';
const DEBOUNCE_DELAY = 500;
const AuditLogsList = (tableHeader) => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [noDataFound, setNoDataFound] = useState(false);
  const debouncedSearch = useCallback(async (searchKey) => {
    if (searchKey) {
      setLoader(true);
      try {
        const searchResults = await searchAuditLogs(searchKey, 1, perPage);
        setData(searchResults?.data?.audit_result || []);
        setTotalData(searchResults);
        setNoDataFound(searchResults?.data?.audit_result?.length === 0);
      } catch (error) {
        console.log('Error searching audit logs:', error);
      } finally {
        setLoader(false);
      }
    } else {
      // Handle empty search term (use getAuditLogs)
      await getContentDataHandler(1);
    }
  }, [searchAuditLogs, perPage]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      debouncedSearch(searchValue);
    }, DEBOUNCE_DELAY);

    return () => clearTimeout(timeoutId);
  }, [searchValue, debouncedSearch]);
  useEffect(() => {
    getContentDataHandler();
  }, [currentPage, perPage]);

  async function getContentDataHandler() {
    setLoader(true);
    setNoDataFound(false);
    if (searchValue) {
      const searchResults = await searchAuditLogs(searchValue, currentPage + 1, perPage);
      setData(searchResults?.data?.audit_result);
      setTotalData(searchResults);
    } else {
      const dataArr = await getAuditLogs(currentPage + 1, perPage);
      if (dataArr && dataArr.data) {
        setData(dataArr.data.audit_result);
        setTotalData(dataArr);
      }
    }

    setLoader(false);
  }

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected); // Update currentPage for pagination
  };

  const handleSearchChange = (searchKey) => {
    setSearchValue(searchKey);
  };

  return (
    <div>
      <div className="content-container__filterSearch">

        <Search
         value={searchValue}
         placeholder='Search'
         className={''}
         onChange={handleSearchChange}
        />

      </div>
      <br/>
      <div className="content-container__table">
        <CustomTable data={data} tableHeader={tableHeader.tableHeader} />
      </div>
      {noDataFound && searchValue && (
        <p>No results found for your search.</p>
      )} 
      {data.length > 0 && !noDataFound && (
      <ReactPaginate
        breakLabel="..."
        nextLabel={<span>Next</span>}
        onPageChange={handlePageChange}
        pageCount={Math.ceil(totalData?.data?.pagination?.total_records / perPage)}
        previousLabel={<span>Previous</span>}
        marginPagesDisplayed={2}
        renderOnZeroPageCount={null}
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        disabledClassName="page-item disabled"
        activeClassName="active"
        nextLinkClassName="page-link"
          previousLinkClassName="page-link"
        pageClassName="page-item"
        pageLinkClassName="page-link"
      />)}
      {loader && <PageLoader pageLoading={loader} />}
    </div>
  );
};

export default AuditLogsList;