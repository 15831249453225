import React from 'react'
import BasePage from '../BasePage'
import UserLandingScreen from 'app/container/Userlanding';
import {UserLandingHeader} from 'app/constants/table-header-constants'

const UserLanding = () => {
  return (
    <BasePage>
    <UserLandingScreen tableHeader={UserLandingHeader}/>

    </BasePage>
  )
}

export default UserLanding