import { useHistory } from "react-router"
import './index.scss'
import moment from 'moment';

function CustomTable({ data, tableHeader, handleDelete }) { 
     const history = useHistory()

     //handle navigation
     const handleNavigation = (item) =>{
          
          history.push({
               pathname: `/customer-orders/details/${item.orderId}`,
               state: {
                    ...item,
                  },
          });
     }
     return (
          <table>
               <tr>
                    {tableHeader.map((item,i) => {
                         return (
                              <th className='nowrap text-center'>{item.Header}</th>
                         )
                    })} 
               </tr>
               {data.map((items, indx) => (<tr key={indx}>
                    <td>
                         <div>
                              <p><span className="text-primary cursor-pointer" onClick={() => handleNavigation(items) }>{items.orderId}</span></p>
                              <p className="text-muted">{moment(items.orderDate).format('MMM Do YYYY, LT')}</p>
                         </div>
                         
                    
                    </td> 
                    <td>
                         <div>
                              <p>{items.customenrNAme}</p>
                              <p>{items.city}</p>
                              <p>{items.pincode}</p>
                         </div>
                    </td>
                    <td>
                         {items.orderStatus}
                    </td>  
                    
                    <td>
                         <div className="btn-group" role="group" aria-label="Basic outlined example">
                              {/* <button
                                   type="button"
                                   className="btn btn-outline-primary"
                                   onClick={() => handleNavigation(items) }
                              >
                                   Edit
                              </button> */}
                              <button type="button"
                                   // onClick={() => handleDelete(items) }
                                   className="btn btn-outline-primary">Update Notes
                              </button>
                               
                         </div> 
                         
                    </td>
               </tr>))}
               {data.length ===0 &&  <tr><td colspan="10000">No data found</td> </tr>}
          </table>
     )
}

export default CustomTable