import { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import validate from './validateInfo';

// Utils
import {
  addCouponAndOffer,
  getCouponDataAndOfferById,
  getProductFamilyList,
  updateCouponAndOffer,
} from 'api/latest/LatestMedicineandDiagnosticCouponandOffer/medicineanddiagnosticCouponandoffer';
import { Messages } from 'app/constants/messages';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import Message from 'utils/Message';
import Toast from 'utils/Toast';
const useCreateCouponAndOffer = () => {
  const history = useHistory();
  const location = useLocation();
  const editorRef = useRef(null);
  const [productFamilyList, setproductFamilyList] = useState([]);

  const [htmlContent, setHTMLContent] = useState('');
  const [pageLoading, setPageLoading] = useState(false);
  // const [activate, setActivate] = useState(true);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  // const [validated, setValidated] = useState(true);
  const [toggled, setToggled] = useState(null);
  const [hideFields, setHideFields] = useState(false);
  // const { LanguageOptions } = useTabBarOptions();
  const [activeTab, setActiveTab] = useState({
    id: '1',
    name: 'English',
    short_name: 'en',
    display_order: 1,
    status: 'ACTIVE',
    label: '+ English',
    value: 'English',
  });
  const handleTabChange = (val) => {
    setActiveTab(val);
    if (val.name === 'English') {
      setHideFields(false);
    } else {
      setHideFields(true);
    }
    // Perform any other actions on tab change
  };

  const onStartDateSelected = (date) => {
    setStartDate(date);
    setValues({
      ...values,
      start_from: date,
    });
  };

  const onEndDateSelected = (date) => {
    setEndDate(date);
    setValues({
      ...values,
      end_at: date,
    });
  };

  const [values, setValues] = useState({
    code: '',
    heading: '',
    sub_heading: '',
    product_type: '',
    product_family: '',
    max_usage_per_user: 1,
    discount: null,
    min_value_required: null,
    max_discount_amount: null,
    status: 'INACTIVE',
    description: '',
    start_from: '',
    end_at: '',
    user_visibility: 'Yes'
  });

  const coupon_id = location?.state?.id ? location?.state?.id : '';
  useEffect(() => {
    getProductFamilyValues();
    if (coupon_id) {
      loadCouponData(coupon_id);
    }
  }, [coupon_id, activeTab?.short_name]);
  const getProductFamilyValues = async () => {
    setPageLoading(true);
    let response = await getProductFamilyList();
    setPageLoading(false);
    if (response && response?.response?.code === 200) {
      let temp =
        (await response?.data) &&
        response?.data.map((item, index) => {
          return {
            ...item,
            value: item.id,
            label: item.value,
          };
        });
      setproductFamilyList(temp);
    } else {
      Toast.error(
        response?.data ? response?.data : Message?.Error?.COMMON_MESSAGE
      );
    }
  };
  const loadCouponData = async (id) => {
    if (!id) {
      return;
    }
    // '41060749-282c-4178-9f48-d854dc3ba5f0'
    setPageLoading(true);
    let response = await getCouponDataAndOfferById(id, activeTab.short_name);
    setPageLoading(false);
    if (response?.response?.code === 200) {
      let responsedata = response.data;
      let userVisibility=responsedata.user_visibility === true ? "Yes" : "No";
      const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
      setValues((prevState) => ({
        code: responsedata.code ? responsedata.code : '',
        heading: responsedata.heading ? responsedata.heading : '',
        sub_heading: responsedata.sub_heading ? responsedata.sub_heading : '',
        product_type: responsedata.product_type
          ? responsedata.product_type
          : '',
        product_family: responsedata.product_family
          ? responsedata.product_family
          : '',
        max_usage_per_user: responsedata.max_usage_per_user
          ? parseInt(responsedata.max_usage_per_user)
          : 1,
          user_visibility:userVisibility?userVisibility:'',
        discount: responsedata.discount ? parseInt(responsedata.discount) : 0,
        min_value_required: responsedata.min_value_required
          ? parseInt(responsedata.min_value_required)
          : null,
        max_discount_amount: parseInt(responsedata.max_discount_amount),
        status: responsedata.status ? responsedata.status : '',
        start_from: moment(responsedata.start_from).format(dateTimeFormat),
        end_at: moment(responsedata.end_at).format(dateTimeFormat),
        description: responsedata.description,
      }));

      setToggled(responsedata.status === 'ACTIVE' ? true : false);
      setHTMLContent(responsedata.description);

      // updateValue(responsedata);
      if (responsedata?.start_from) {
        setStartDate(moment(responsedata?.start_from).format(dateTimeFormat));
      }
      if (responsedata?.end_at) {
        setEndDate(moment(responsedata?.end_at).format(dateTimeFormat));
      }
    } else {
      Toast.error(
        response?.data ? response?.data : Message.Error.COMMON_MESSAGE
      );
    }
  };

  const openUp = () => {
    confirmAlert({
      title: '',
      message: toggled
        ? 'Are you sure you want to Unpublish Coupons & Offers?'
        : 'Are you sure you want to Publish  Coupons & Offers?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => setToggled(!toggled),
        },
        {
          label: 'No',
        },
      ],
    });
  };
  const callAddOrUpdateAPI = async () => {
    let content;
    // let sendData = {};
    if (editorRef.current) {
      content = editorRef.current.value;
    }
    let sendData = { ...values, description: content,user_visibility:values.user_visibility==='Yes'? true:false };

    if (!startDate) {
      delete sendData.start_from;
    }
    if (!endDate) {
      delete sendData.end_at;
    }

    if (activeTab.name === 'English') {
      if (values.product_type !== 'AUTOMATED_VITALS') {
        sendData.product_family = null;
      }
      if (toggled) {
        sendData.status = 'ACTIVE';
      } else {
        sendData.status = 'INACTIVE';
      }
    }

    setPageLoading(true);
    let response = {};

    if (coupon_id) {
      if (activeTab.short_name === 'en') {
        response = await updateCouponAndOffer(
          sendData,
          coupon_id,
          activeTab?.short_name
        );
      } else {
        let temp = {
          heading: sendData.heading,
          description: sendData.description,
          sub_heading: sendData.sub_heading,
        };
        response = await updateCouponAndOffer(
          { ...temp, lang: activeTab.short_name },
          coupon_id,
          activeTab?.short_name
        );
      }
    } else {
      response = await addCouponAndOffer(sendData);
    }

    setPageLoading(false);
    if (response?.response?.code === 200 || response?.response?.code === 201) {
      Toast.success(
        response?.response?.code === 200
          ? Message.CouponAndOffer.UPDATED_SUCCESS
          : Message.CouponAndOffer.CREATED_SUCCESS
      );
      history.goBack();
    } else {
      Toast.error(
        response?.data ? response?.data : Messages.Error.COMMON_MESSAGE
      );
    }
  };

  const handleCreateCoupon = (e) => {
    const validationStatus = validate(values);
    if (validationStatus) {
      return;
    }
    let content;
    if (editorRef.current) {
      content = editorRef.current.value;
    }
    if (!content) {
      Toast.error('Description is Required');
      return;
    }
    callAddOrUpdateAPI();
  };

  const [error, setErrors] = useState({});

  // const updateValue = (obj) => {
  //   setValues({
  //     ...obj,
  //   });
  // };

  const resetValues = () => {
    setValues({
      id: '',
      code: '',
      heading: '',
      sub_heading: '',
      product_type: '',
      max_usage_per_user: 1,
      discount: null,
      min_value_required: null,
      max_discount_amount: null,
      status: false,
      product_family: '',
      user_visibility: 'Yes'
    });
  };

  const handleChange = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;

      if (
        name === 'max_usage_per_user' ||
        name === 'discount' ||
        name === 'min_value_required' ||
        name === 'max_discount_amount'
      ) {
        setValues({
          ...values,
          [name]: parseInt(value),
        });
        return;
      }
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const handleChangeDropdown = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;

      if (name === 'product_type' && value !== 'AUTOMATED_VITALS') {
        setValues({
          ...values,
          product_family: null,
          [name]: value,
        });
      } else {
        setValues({
          ...values,
          [name]: value,
        });
      }
    }
  };
  return {
    // updateValue,
    resetValues,
    openUp,
    toggled,
    handleChange,
    values,
    error,
    setValues,
    htmlContent,
    editorRef,
    handleCreateCoupon,
    pageLoading,
    productFamilyList,
    onStartDateSelected,
    onEndDateSelected,
    startDate,
    endDate,
    handleChangeDropdown,
    coupon_id,
    handleTabChange,
    activeTab,
    hideFields,
    setHTMLContent
    // validated,
    // activate,
  };
};

export default useCreateCouponAndOffer;
