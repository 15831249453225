import {
	createContentAPI,
	getContentFromId,
	getTagsApi,
	updateContentAPI,
} from 'app/api/createContentAPI';
import Calender from 'app/components/Calender';
import MultiSelectDropdown from 'app/components/MultiSelectDropdown';
import NewInput from 'app/components/NewInput';
import PageLoader from 'app/components/PageLoader';
import TextArea from 'app/components/Textarea';
import Button from 'app/components/button';
import Message from 'app/utils/Message';
import { imageSizeValidation, removeNullValues } from 'app/utils/Parsers';
import Toast from 'app/utils/Toast';
import { calendarValidation } from 'app/utils/dateChecker';
import { CloseBlackIcon } from 'constants/image-constants';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import ValidationMessage from '../../../utils/ValidationMessage';
import '../index.scss';
import CTAType from 'app/components/CTAType'
import DropdownOptions from "app/utils/DropdownOptions";


const Video = ({ data, activeTab }) => { 
	const [activate, setActivate] = useState(true); 
	const history = useHistory();
	const [validated, setValidated] = useState(true);
	const [loader, setLoader] = useState(false);
	const [options, setOptions] = useState(null);
	const [selectedVideo, setSelectedVideo] = useState(null);
	const [thumbnailImage, setThumbnailImage] = useState(null);
	const [state, setState] = useState({
		title: '',
		description: '',
		youtube_url: null,
		endless_scroll_media: '',
		duration: '',
		thumbnail_image: '',
		start_date: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
		end_date: '',
		tags: [],
		powered_by: '',
		hex_code: '#323739',
		url: '',
		cta_type: 'INTERNAL',
		internal_for: '',
		internal_url: '',
		profile_data: false,
		custom_object: '',
		redirection_id: '',
		detail_cta_text: '',
		endless_scroll_cta:''

	});
	const [disabledKeys, setDisabled] = useState({
		internalApp: true,
		externalRedirect: true,
		cta: true,
	})


	const updateStates = async () => {
		if (data) {
			if (!data?.id || !activeTab) {
				return;
			}
			setSelectedVideo(null);
			setThumbnailImage(null);
			setState({});
			let response = await getContentFromId(data.id, activeTab);
			let requiredResponse = response.data[0];
			let stateValues = Object.keys(state);

			let thumbnail_image = requiredResponse.thumbnail_image
				? `${process.env.REACT_APP_S3_BUCKET_URL}/${requiredResponse.thumbnail_image}`
				: null;
			setThumbnailImage(thumbnail_image);

			let endless_scroll_media = requiredResponse.endless_scroll_media
				? `${process.env.REACT_APP_S3_BUCKET_URL}/${requiredResponse.endless_scroll_media}`
				: null;
			setSelectedVideo(endless_scroll_media);

			let temporaryState = {};
			for (let index in stateValues) {
				temporaryState[stateValues[index]] =
					requiredResponse[stateValues[index]];
			}
            temporaryState['cta_type'] = requiredResponse.url_type
			if (temporaryState.youtube_url === 'null') {
				temporaryState['youtube_url'] = null;
			}
			setState(temporaryState);
		}
	};

	useEffect(() => {
		updateStates();
	}, [activeTab]);

	useEffect(() => {
		if (state.cta_type == "INTERNAL") {
			setDisabled({ externalRedirect: true, internalApp: false, cta: false })
		}
		else if (state.cta_type == "EXTERNAL") {
			setDisabled({ cta: true, internalApp: true, externalRedirect: false })
		}
		else if (state.cta_type == "EXTERNAL_PWA") {
			setDisabled({ internalApp: true, externalRedirect: false, cta: false })
		}

	}, [state.cta_type])


	const validateForm = () => {
		let val = calendarValidation(state.start_date, state.end_date);
		if (!val) {
			Toast.error('Start date is much larger than end date');
			return;
		}
		let flag = true;
		let validationArray = Object.keys(state);
		let optionalFields = [
			'youtube_url',
			'end_date',
			'description',
			'endless_scroll_media',
			'duration',
			'powered_by',
		];
		if (state.cta_type === 'NO_CTA') {
			optionalFields = [
				...optionalFields,
				'internal_for',
				'internal_url',
				'profile_data',
				'custom_object',
				'url',
				'detail_cta_text',
				'redirection_id',
			];
		}else
		if (state.cta_type !== 'INTERNAL_PWA') {
			optionalFields = [
			  ...optionalFields,
			  'internal_for',
			  'internal_url',
			  'profile_data',
			  'custom_object',
			];
		  } 
		  if (state.cta_type !== 'INTERNAL') {
			optionalFields = [...optionalFields, 'url', 'detail_cta_text'];
		  } 
		  if (
			state.url !== 'zoom_live_session' ||
			state.url !== 'infinite_scroll' ||
			state.url !== 'group_of_collection'
		  ) {
			optionalFields = [...optionalFields, 'redirection_id'];
		  }		  

		for (let index in validationArray) {
			if (
				state[validationArray[index]] === '' &&
				!optionalFields.includes(validationArray[index])
			) {
				flag = false;
				Toast.error('Please complete all fields');
				break;
			} else if (
				validationArray[index] === 'tags' &&
				state.tags?.length === 0
			) {
				flag = false;
				Toast.error('Please complete all fields');
				break;
			}
		}

		return flag;
	};

	const handleTextChange = (e) => {
		setState({ ...state, [e.target.name]: e.target.value });
	};

	const handleSubmit = async () => {
		if (activeTab === 'en') {
			let validated = validateForm();
			if (!validated) {
				return;
			}
		}

		let dataObj;
		let responseObj;

		if (data) {
			dataObj = {
				content_type: 'VIDEO',
				...state,
				tags: JSON.stringify(state.tags),
				content_id: data.id,
			};
			if (activeTab === 'en') {
				dataObj.start_date = moment(dataObj.start_date).format(
					'YYYY-MM-DD HH:mm:ss'
				);
				if (dataObj.end_date) {
					dataObj.end_date = moment(dataObj.end_date).format(
						'YYYY-MM-DD HH:mm:ss'
					);
				}

				if (
					dataObj.endless_scroll_media === '' &&
					dataObj.youtube_url === null
				) {
					Toast.error('Please complete all fields');
					return;
				}
			}
			if (dataObj.cta_type === null) {
				delete dataObj.cta_type;
			  }
			if (activeTab !== 'en') {
				delete dataObj?.start_date;
				delete dataObj?.end_date;
				delete dataObj?.tags;
			}
			setLoader(true);
			let temporaryStates = removeNullValues(dataObj);
			responseObj = await updateContentAPI(
				temporaryStates,
				activeTab,
				data?.id
			);
			setLoader(false);
		} else {
			dataObj = {
				content_type: 'VIDEO',
				...state,
				tags: JSON.stringify(state.tags),
			};
			if (dataObj.cta_type === null) {
				delete dataObj.cta_type;
			  }
			setLoader(true);
			responseObj = await createContentAPI(dataObj);
		}

		setLoader(false);
		if (
			responseObj &&
			responseObj.response &&
			responseObj.response.code === 200
		) {
			// LocalStorage.remove(LocalSessionKey.FEED_ID);
			Toast.success(Message.video.VIDEO_SUCCESS);
			history.push('/cms/content');
			if (data) {
				history.push({
					pathname: '/cms/content',
					state: { goto: data.comingFrom },
				});
				return;
			} else {
				history.push('/cms/content');
			}
		} else {
			Toast.error(Message.video.VIDEO_FAIL);
		}
	};
	const handleMediaUpload = (e) => {
		let file = e.target.files[0];
		let reader = new FileReader();
		if (file.type?.split('/')?.[0] === 'video') {
      file = e.target.files[0];
      		if((file.type === 'video/mp4' || file.type === "video/webm") && file.size >10485760){
        		Toast.error("Video size will be less than 10MB");
        		return;
      		}
			const src = URL.createObjectURL(file);
			var video = document.createElement('video');
			video.src = src;
			video.addEventListener('loadedmetadata', function () {
				setState({
					...state,
					duration: parseInt(parseFloat(video.duration)),
					[e.target.name]: file,
				});
			});
			reader.onloadend = function () {
				setSelectedVideo(reader.result);
			};
			reader.readAsDataURL(file);
			Toast.success(file?.name + ' uploaded');
		} else {
			if (imageSizeValidation(e)) {
				Toast.error(ValidationMessage.IMAGE_SIZE);
				return;
			} else {
				setState({ ...state, [e.target.name]: file });
				reader.onloadend = function () {
					setThumbnailImage(reader.result);
				};
				reader.readAsDataURL(file);
				Toast.success(file?.name + ' uploaded');
			}
		}
	};
	const loadTagsfromApi = async () => {
		const tagValues = await getTagsApi();
		setOptions(tagValues);
	};
	useEffect(() => {
		loadTagsfromApi();
	}, []);

	const cancelMedia = async (e, file) => {
		setState({ ...state, [e.target.name]: '' });
		// await deleteUploadedMedia(file)
	};

	return (
		<>
			<div className='separator'>
				<p>Video - Add Details</p>
			</div>

			<div className='userInput'>
				<NewInput
					label='Title'
					name={'title'}
					value={state.title ? state.title : ''}
					onChange={handleTextChange}
				/>
				<TextArea
					label={'Short Description'}
					name={'description'}
					value={state.description ? state.description : ''}
					onChange={handleTextChange}
				/>
				{activeTab === 'en' && (
					<div className='userFormArea'>
						{options && (
							<MultiSelectDropdown
								label='Add Tags'
								name='tags'
								value={state?.tags}
								options={options}
								onChange={handleTextChange}
								tag_type={true}
							/>
						)}
					</div>
				)}

				<div className='userFormArea'>
					<div>
						{(state.endless_scroll_media || state.youtube_url) && (
							<p
								className='cancel'
								onClick={(e) => {
									if (data) {
										cancelMedia(e, state.endless_scroll_media);
									}
									setSelectedVideo(null);
									setState({ ...state, endless_scroll_media: '',youtube_url: '' });
								}}
							>
								Cancel Upload
							</p>
						)}
					</div>
					<div className='upload-buttons'>
						<input
							type='file'
							id='videoFile'
							accept='video/*'
							style={{ display: 'none' }}
							name='endless_scroll_media'
							onChange={(e) => {
								handleMediaUpload(e);
							}}
						/>
						<div className='upload-label'>
							<label>Upload Video</label>
							<Button
								onClick={() => {
									const image = document.getElementById('videoFile');
									image.click();
								}}
								type='button'
								isDisabled={
									state.youtube_url
										? true
										: state.endless_scroll_media
											? true
											: false
								}
								name={
									state.endless_scroll_media != '' ? 'Uploaded' : 'Upload Video'
								}
							/>
						</div>
						{selectedVideo && (
							<div className='attachment_img_wrapper'>
								<video className='attachment__selimage' controls>
									<source src={selectedVideo} type='video/mp4' />
								</video>
								<div className='attachment_img_wrapper_close'>
									<img
										src={CloseBlackIcon}
										alt='Remove'
										onClick={(e) => {
											if (data) {
												cancelMedia(e, state.endless_scroll_media);
											}
											setSelectedVideo(null);
											setState({ ...state, endless_scroll_media: '' });
										}}
									/>
								</div>
							</div>
						)}
					</div>
					<div className='mt-3'>
						{state.thumbnail_image && (
							<p
								className='cancel'
								onClick={(e) => {
									if (data) {
										cancelMedia(e, state.thumbnail_image);
									}
									setThumbnailImage(null);
									setState({ ...state, thumbnail_image: '' });
								}}
							>
								Cancel Thumbnail
							</p>
						)}
					</div>
					<div className='upload-buttons'>
						<input
							type='file'
							id='thumbnailFile'
							accept='image/*'
							style={{ display: 'none' }}
							name='thumbnail_image'
							onChange={(e) => {
								handleMediaUpload(e);
							}}
						/>
						<div className='upload-label'>
							<label>Upload Thumbnail</label>
							<Button
								onClick={() => {
									const image = document.getElementById('thumbnailFile');
									image.click();
								}}
								isDisabled={state.thumbnail_image}
								name={
									state.thumbnail_image != ''
										? 'Uploaded'
										: 'Upload Thumbnail Image'
								}
								type='button'
							/>
						</div>
						{thumbnailImage && (
							<div className='attachment_img_wrapper'>
								<img
									src={thumbnailImage}
									alt='sample'
									className='feed__attachment'
								/>
								<div className='attachment_img_wrapper_close'>
									<img
										src={CloseBlackIcon}
										alt='Remove'
										onClick={(e) => {
											setState({ ...state, thumbnail_image: '' });
											setThumbnailImage(null);
										}}
									/>
								</div>
							</div>
						)}
					</div>
				</div>
				<div className='colorpicker'>
					<label>Background Fill</label>
					<input
						type='color'
						name='hex_code'
						value={state.hex_code ? state.hex_code : ''}
						onChange={handleTextChange}
					/>
					<p>{state.hex_code}</p>
				</div>
				<NewInput
					label='Duration'
					name='duration'
					disabled={true}
					value={state.duration ? state.duration : ''}
					onChange={handleTextChange}
				/>
				<NewInput
					label='Youtube URL'
					name='youtube_url'
					value={state.youtube_url ? state.youtube_url : ''}
					disabled={state.endless_scroll_media ? true : false}
					onChange={handleTextChange}
				/>

				<NewInput
					label='Powered By'
					name='powered_by'
					value={state.powered_by ? state.powered_by : ''}
					onChange={handleTextChange}
				/>
				<div className="userFormArea">
					<label>Detailed CTA Type</label>

					<CTAType
						cta_type={state.cta_type}
						handleChange={(key, data) => {
							if (key === 'cta_type') {
								setState({ ...state,  [key]: data,detail_cta_text: '', internal_for:'', internal_url:'', profile_data: false, custom_object:'', url:'' });
							  } else {
								if (key === 'url' && (data !== 'zoom_live_session' || data !== 'infinite_scroll' || data !== 'group_of_collection')) {
									setState({ ...state, [key]: data, 'redirection_id': '' })
				
								} else {
									setState({ ...state, [key]: data })
								}
							}
						}}
						internalUrl={DropdownOptions.InternalUrl}
						url={state.url}
						handleTextChange={handleTextChange}
						internal_for={state.internal_for}
						internal_url={state.internal_url}
						profile_data={state.profile_data}
						custom_object={state.custom_object}
						redirection_id={state.redirection_id}
					/>
				</div>
				{state.cta_type === 'INTERNAL' &&
					<div className="row">
						<div className="col-sm-6">
							<NewInput
								name="detail_cta_text"
								label={"Detailed CTA text"}
								value={state.detail_cta_text}
								onChange={handleTextChange}
								disabled={
									disabledKeys.cta
								}
							/>
						</div>
					</div>
				}
				<NewInput
					label='Endless scroll CTA'
					name={'endless_scroll_cta'}
					value={state?.endless_scroll_cta ? state?.endless_scroll_cta : ''}
					onChange={handleTextChange}
				/>

				{
					activeTab === 'en' && (
						<Calender
							onStartDateSelected={(e) => {
								setState({ ...state, start_date: e });
							}}
							onEndDateSelected={(e) => {
								setState({ ...state, end_date: e });
							}}
							startDate={state.start_date}
							endDate={state.end_date}
							validated={validated}
							activate={activate}
						/>
					)
				}
				<div className='userFormArea'>
					<Button
						name={data ? 'Edit Video' : 'Create Video'}
						onClick={handleSubmit}
					/>
					<label
						style={{ paddingLeft: '10px', cursor: 'pointer' }}
						onClick={() => {
							if (data) {
								history.push({
									pathname: '/cms/content',
									state: { goto: data.comingFrom },
								});
							} else {
								history.goBack();
							}
						}}
					>
						Cancel
					</label>
				</div>
			</div >
			{loader && <PageLoader pageLoading={loader} />}
		</>
	);
};

export default Video;
