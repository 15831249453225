import { useHistory } from "react-router"
import './index.scss'

function CustomTable({ data, tableHeader, handleDelete, totalData, currentPage }) { 
     const history = useHistory()

     //handle navigation
     const handleNavigation = (item) =>{
          history.push({
               pathname: `/tip-of-the-day/edit`,
               state: {
                 ...item,
                 totalData: JSON.stringify(totalData)
               },
             });
     }
     return (
          <table>
               <thead>
               <tr>
                    {tableHeader.map((item,i) => {
                         return (
                              <th className='nowrap text-center' key={i}>{item.Header}</th>
                         )
                    })} 
               </tr>
               </thead>
               <tbody>
               {data.map((items, indx) => (<tr key={indx}>
                    <td>{ (currentPage-1)*10 + indx + 1}</td> 
                    <td>{items.tip_text}</td>
                    <td>{items.tip_date}</td> 
                    <td>
                         <div className="btn-group" role="group" aria-label="Basic outlined example">
                              <button
                                   type="button"
                                   className="btn btn-outline-primary"
                                   onClick={() => handleNavigation(items) }
                              >
                                   Edit
                              </button>
                              <button type="button"
                                   onClick={() => handleDelete(items) }
                                   className="btn btn-outline-primary">Delete</button>
                               
                         </div> 
                         
                    </td>
               </tr>))}
               </tbody>
               <tbody>
               {data.length ===0 &&  <tr><td colSpan="100">No data found</td> </tr>}
               </tbody>
          </table>
     )
}

export default CustomTable