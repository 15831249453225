import CoursesBannerContainer from "app/container/CoursesBannerContainer";
import BasePage from "app/pages/BasePage";

const CoursesBanner = () => {
    return (
        <BasePage>
            <CoursesBannerContainer />
        </BasePage>
    );
}

export default CoursesBanner;