import React from 'react';

// Pages
import BasePage from 'pages/BasePage'; 
import CreateStreakRewardContainer from 'container/UserJourney/CreateStreakReward'
import CreateRecommendedActionContainer from 'container/RecommendedAction/CreateRecommendedAction'
import CreateNavigationContainer from 'app/container/NavigationContainer/CreateNavigation';
const CreateNavigation = () => {
  return (
    <BasePage>
      <CreateNavigationContainer />
    </BasePage>
  );
};

export default CreateNavigation;
