import Button from 'app/components/button';
import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import PageLoader from 'app/components/PageLoader';
import NewInput from 'app/components/NewInput';
import Search from 'app/components/search';
import { imageSizeValidation } from 'app/utils/Parsers';
import ValidationMessage from 'app/utils/ValidationMessage';
import Message from 'app/utils/Message';
import Toast from 'app/utils/Toast';
import { CloseBlackIcon } from 'app/constants/image-constants';
import { uplaodMedia } from 'app/api/widgets';
import {
  addSubCategory,
  getMedPaySubCategories,
  getSubCategoryById,
  searchMedicine,
  updateSubCategories,
} from 'app/api/latest/Categories/categoriesAPI';
import Plus from 'images/plus.png';
import CustomTable from '../SelectedMedicineTable/table';
import { CustomerOrderMedicineHeader } from 'app/constants/table-header-constants';
import HeaderWithBackButton from 'app/components/HeaderWithBackButton';
import { APP_CONSTANTS } from 'constants/app-constants';

const CustomerOrderDetails = () => {
  const location = useLocation();
  let history = useHistory();
  const params = useParams();
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState({
    // partner: '',
    // partner_id: '',
    // order_type: '',
    // payment_collection: '',
    // delivery_partner: '',
    name: '',
    mobile: '',
    alternative_mobile: '',
    email: '',
    address: '',
    landmark: '',
    pincode: '',
    city: '',
    state: '',
    doctor_name: '',
    upload_prescription: '',
    selected_medicines: [],
    productKeyword: '',
  });

  const [upload_prescription, setupload_prescription] = useState(null);

  const [productKeyword, setProductKeyword] = useState('');
  const [noProductStatus, setNoProductStatus] = useState(false);
  const [searchProductList, setSearchProductList] = useState([]);
  const [productSearchLoader, setProductSearchLoader] = useState(false);
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <div className='container-fluid'>
      <HeaderWithBackButton
        title='Customer Order \ Details'
        goTo={APP_CONSTANTS.CUSTOMER_ORDERS}
      />
      <div className='cms-content__container'>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='separator'>
              <h4 className='section-header__title'>Medicines Orders</h4>
            </div>
          </div>
          <div className='col-sm-6 my-4'>
            <h5>Customer Details</h5>
            <div className='card'>
              <div className='card-body'>
                <p>Name : Santhosh</p>
                <p>
                  Address: 4141 Prestige kew gardens, Karimmana agharah rood
                  yemalur, kariammana agharah road, bengaluru, karnataka -560037{' '}
                </p>
              </div>
            </div>
          </div>
          <div className='col-sm-6 my-4'>
            <h5>Merchant Details</h5>
            <div className='card'>
              <div className='card-body'>
                <p>Accepted By: Ramdev Medical</p>
                <p>Accepted at: 16 jan 2024, 09:58 am</p>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <div className='separator'>
              <h4 className='section-header__title'>Order Details</h4>
            </div>
          </div>
          <div className='col-sm- my-4'>
            <p>Prescription</p>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12 my-3'>
            <h5 className='text-center'>Initial Order Details</h5>
            <table>
              <tr>
                <th>Item</th>
                <th>Details</th>
                <th>Quantity</th>
                <th>Price(Rs)</th>
                <th>Total(Rs)</th>
              </tr>
              <tr>
                <td>Renewa Fingertip Pulse Oximeter</td>
                <td>box of 1 Unit</td>
                <td>1</td>
                <td>1007</td>
                <td>1007.0</td>
              </tr>
            </table>
          </div>
          <div className='col-sm-12 my-3'>
            <h5 className='text-center'>Final Order Details</h5>
            <table>
              <tr>
                <th>Item</th>
                <th>Details</th>
                <th>Quantity</th>
                <th>Price(Rs)</th>
                <th>Total(Rs)</th>
              </tr>
              <tr>
                <td>Renewa Fingertip Pulse Oximeter</td>
                <td>box of 1 Unit</td>
                <td>1</td>
                <td>1007</td>
                <td>1007.0</td>
              </tr>
            </table>
          </div>
        </div>
        <div className='row'>
          <div className='userFormArea text-center my-4'>
            <Button
              name='ACCEPT'
              className='bg-success mx-2'
              // onClick={() => {handleSubmit()}}
            />
            <Button
              className='bg-danger'
              name='REJECT'
              // onClick={() => {handleSubmit()}}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12 my-3'>
            <h5 className='text-center'>Invoice Details</h5>
            <table>
              <tr>
                <th>Item</th>
                <th>Details</th>
                <th>Quantity</th>
                <th>Price(Rs)</th>
                <th>Total(Rs)</th>
              </tr>
              <tr>
                <td>Renewa Fingertip Pulse Oximeter</td>
                <td>box of 1 Unit</td>
                <td>1</td>
                <td>460</td>
                <td>460.0</td>
              </tr>
              <tr>
                <td>Renewa Fingertip Pulse Oximeter</td>
                <td>box of 1 Unit</td>
                <td>1</td>
                <td>60</td>
                <td>60.0</td>
              </tr>
              <tr>
                <td colSpan={4} className='text-sm-end'>Sub Total(Rs)</td>
                <td>520.00</td>
              </tr>
              <tr>
                <td colSpan={4} className='text-sm-end'>Delivery(Rs)</td>
                <td>0</td>
              </tr>
              <tr>
                <td colSpan={4} className='text-sm-end'>Grand Total(Rs)</td>
                <td>520</td>
              </tr>
            </table>
          </div>
          <div className='row'>
            <div className='userFormArea text-center my-4'>
              <Button
                name='View Invoice'
                className='mx-2 bg-primary'
                // onClick={() => {handleSubmit()}}
              />
               
            </div>
          </div>
        </div>
      </div>

      {loader && <PageLoader pageLoading={loader} />}
    </div>
  );
};

export default CustomerOrderDetails;
