// Ajax
import * as dataAccess from 'utils/ajax';
import ApiParser from 'utils/ApiParser';

// Constants
import { API_CONSTANTS } from 'constants/api-contants';

// Get Lists of Items
export const getFamilyPlans = async () => {
  let url = `${API_CONSTANTS.GET_FAMILY_PLANS}`;
  let responseObj = await dataAccess.get(url);
  return ApiParser.parseFamilyPlansListing(responseObj);
};

export const getFamilyPlanswithFilter = async (value) => {
  let url = `${API_CONSTANTS.GET_FAMILY_PLANS}?product_type=${value}`;
  let responseObj = await dataAccess.get(url);
  return ApiParser.parseFamilyPlansListing(responseObj);
};

// Create Item
export const addFamilyPlans = async (incomingData) => {
  let payload = {
    data: {
      ...incomingData,
    },
  };

  return await dataAccess.post(API_CONSTANTS.POST_FAMILY_PLANS, payload);
};

// Update Item
export const updateFamilyPlans = async (incomingData, id) => {
  let payload = {
    data: incomingData,
  };
  let response = await dataAccess.put(
    API_CONSTANTS.UPDATE_FAMILY_PLANS.concat(`/${id}`),
    payload
  );
  return response;
};

// Get Prefill Data
export const getFamilyPlansDetails = async (id) => {
  let responseObj = await dataAccess.get(
    `${API_CONSTANTS.GET_FAMILY_PLANS}/${id}`
  );
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data;
  } else {
    return [];
  }
};

// Get Product Family Services Dropdown List
export const getServicesFamilyPlans = async (servicesOptions) => {
  let payload = {
    data: {
      ...servicesOptions,
    },
  };
  return await dataAccess.get(API_CONSTANTS.GET_SERVICES_FAMILY_PLANS, payload);
};
