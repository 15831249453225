import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

// Components
import HeaderWithBackButton from 'components/HeaderWithBackButton';
import PageLoader from 'components/PageLoader';
import NumberInput from 'components/UI/NumberInput';
import validate from 'components/ValidateInfo';
import Button from 'components/button';
import useContentForm from 'components/useContentForm';
import RadioButton from 'shared/components/RadioButton';
// API
// import { updateActivity } from 'api/activity';
import { updateActivityVitals } from 'api/activityvitals';

// Constants
import { APP_CONSTANTS } from 'constants/app-constants';

// Utils
import Message from 'utils/Message';
import Toast from 'utils/Toast';

const CreateActivityVitals = () => {
  const history = useHistory();
  const location = useLocation();

  const [pageLoading, setPageLoading] = useState(false);

  const { handleChange, values, setValues } = useContentForm(validate);

  useEffect(() => {
    let data = location.state; 
    if (data && data.vitalId) {
      setValues(() => ({
        sortingOrder: data.sorting_order,
        key: data.key,
        id: data.vitalId,
        visibility: data.visibility,
      }));
    }
  }, []);

  const handleRadioButtonClick = (e) => {
    setValues((prevstate) => {
      return {
        ...prevstate,
        visibility: e.target.value === 'false' ? false : true,
      };
    });
  };

  const callUpdateAPI = async () => {
    let { sortingOrder, visibility, key, id } = values;

    let data = [
      {
        id: id,
        key: key,
        sorting_order: sortingOrder,
        visibility: visibility,
      },
    ];

    setPageLoading(true);
    const responseObj = await updateActivityVitals(data);
    setPageLoading(false);
    if (
      responseObj &&
      responseObj.response &&
      responseObj.response.code === 200
    ) {
      Toast.success(Message.Activities.UPDATE_SUCCESS);
      history.goBack();
    } else {
      Toast.error(Message.Activities.UPDATE_FAILURE);
    }
  };

  const handleCreateHomePage = (e) => {
    callUpdateAPI();
  };

  return (
    <div className='blogsForm'>
      <div className='blogsForm_content'>
        <HeaderWithBackButton
          title={values.activityName ? values.activityName : 'Activity Vitals'}
          goTo={APP_CONSTANTS.ACTIVITY_VITALS}
        />

        <NumberInput
          label='Sequence order'
          id='sortingOrder'
          placeholder='Enter sequence order'
          name='sortingOrder'
          value={values.sortingOrder}
          onChange={handleChange}
        />

        <div className='homepage-title'>
          <div className='radio_container'>
            <h5 className='radio_container__title'>Title Visiblity</h5>
            <RadioButton
              id='visibility'
              label='Yes'
              isChecked={values.visibility}
              value={true}
              onSelect={handleRadioButtonClick}
            />

            <RadioButton
              id='challenge_no'
              label='No'
              isChecked={!values.visibility}
              value={false}
              onSelect={handleRadioButtonClick}
            />
          </div>
        </div>

        <Button
          className='mt1'
          onClick={handleCreateHomePage}
          type='submit'
          name='Submit'
        />
      </div>
      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </div>
  );
};

export default CreateActivityVitals;
