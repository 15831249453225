import {
  getServiceOrder,
  updateServiceOrder,
} from 'app/api/latest/Services/services';
import { useEffect, useState } from 'react';

import { URLType } from 'app/constants/app-constants';
import Message from 'utils/Message';
import Toast from 'utils/Toast';

import {
  addWidgets,
  getWidgets,
  updateWidgets,
} from 'app/api/latest/HomepagewidgetAPI';
import ValidationMessage from 'app/utils/ValidationMessage';
import { confirmAlert } from 'react-confirm-alert';
import { validateUrl } from 'app/helpers/validation';
const useContentHomepageWidgetForm = (validate) => {
  const [pageLoading, setPageLoading] = useState(false);
  const [count, setCount] = useState(1);
  const [listData, setListData] = useState([]);
  const [sortedList, setSortedList] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isPublishButtonDisabled, setisPublishButtonDisabled] = useState(true);
  const [isAddButtonDisabled, setisAddButtonDisabled] = useState(false);
  const [toggled, setToggled] = useState(null);

  const [refresh, setRefresh] = useState(false);

  const loadServiceslist = async () => {
    setPageLoading(true);
    let response = await getWidgets();

    let temp = [];

    temp = response?.tableData.map((item, index) => {
      let url = '';
      let externalUrl = '';
      if (item?.cta_type && item?.cta_type.trim() === URLType.EXTERNAL) {
        externalUrl = item?.deep_link_url ? item?.deep_link_url : '';
      }
      if (item?.cta_type && item?.cta_type.trim() === URLType.INTERNAL) {
        url = item?.deep_link_url ? item?.deep_link_url : '';
      }
      setToggled(item.is_active === 'ACTIVE' ? true : false);

      return {
        serviceId: item?.id,
        id: `widgets_${index}`,
        title: item?.service_name,
        displayOrder: item?.display_order,
        attachment: item?.image_url,
        urlType: item?.cta_type ? item?.cta_type.trim() : '',
        url: url,
        urlCopy: url,
        redirection_id: item?.redirection_id ? item?.redirection_id : '',
        redirection_idURL: item?.redirection_id ? item?.redirection_id : '',
        externalUrl: externalUrl,
        is_active: item?.is_active,
        updated_by: item?.updated_by,
        updated_on: item?.updated_on,
        isNew: false,
        wasEdited: false,
      };
    });
    let newtemp = temp.sort((a, b) => a.displayOrder - b.displayOrder);

    setPageLoading(false);
    if (response && response?.error === false) {
      setListData(newtemp);
      setCount(temp.length);
      if (newtemp.length >= 2) setisAddButtonDisabled(true);
    } else {
      if (response?.message) {
        Toast.error(response?.message);
      } else {
        Toast.error(Message.Error.COMMON_MESSAGE);
      }
    }
  };
  useEffect(() => {
    loadServiceslist();
  }, [refresh]);

  const validateRequestpreviewScreen = async () => {
    let addimageCheck = false;
    let addheadingCheck = false;
    let adddisplayOrderCheck = false;
    let adddisplayOrderunique = false;
    let adddisplayOrderMaxvalueCheck = false;
    let urlCheck = false;
    listData.forEach((item, index) => {
      if (item.isNew) {
        if (item.attachmentFile === null) {
          Toast.error(`${ValidationMessage.ATTACH} for Service ${index + 1}`);
          addimageCheck = true;
          return;
        }
      }
      if (item?.title === '') {
        Toast.error(`${ValidationMessage.TEXT_TITLE} for Widget ${index + 1}`);
        addheadingCheck = true;
        return;
      }

      if (item?.displayOrder === '') {
        Toast.error(
          `${ValidationMessage.DISPLAY_ORDER} for Widget ${index + 1}`
        );
        adddisplayOrderCheck = true;
        return;
      }

      if (item?.displayOrder > listData.length) {
        Toast.error(
          `Display Order Must be Under ${listData.length} for Widget ${
            index + 1
          }`
        );
        adddisplayOrderMaxvalueCheck = true;
        return;
      }
      if (item?.externalUrl !== '' && !validateUrl(item?.externalUrl)) {
        Toast.error(
          `${ValidationMessage.NOT_CORRECT_URL} for Widget ${index + 1}`
        );
        urlCheck = true;
        return;
      }
    });

    let makePreviewList = [...listData];
    const unique = [...new Set(listData.map((item) => item.displayOrder))];
    let newtemp = makePreviewList.sort(
      (a, b) => a.displayOrder - b.displayOrder
    );

    setSortedList(newtemp);

    if (unique && listData && unique.length < listData.length) {
      Toast.error('Display Order must be Unique');
      adddisplayOrderunique = true;
      return;
    }
    if (
      addimageCheck ||
      addheadingCheck ||
      adddisplayOrderCheck ||
      adddisplayOrderMaxvalueCheck ||
      adddisplayOrderunique ||
      urlCheck
    ) {
      setPageLoading(false);
      return;
    }
    handleShow(true);
  };
  const addWidgetSection = () => {
    setisPublishButtonDisabled(false);
    const temp = [...listData];

    temp.push({
      id: Date.now(), // Basically its a index
      serviceId: null,
      title: '',
      urlType: '',
      url: '',
      externalUrl: '',
      attachment: '',
      attachmentFile: null,
      displayOrder: '',
      redirection_id: null,
      redirection_idURL: null,
      criteria: '',
      android_version: '',
      is_active: '',
      updated_by: '',
      updated_on: '',
      status: 'INACTIVE',
      isNew: true,
      wasEdited: false,
    });
    setCount(count + 1);

    setListData(temp);

    if (count + 1 >= 2) {
      setisAddButtonDisabled(true);
    }
  };
  const OnhandleChange = (e) => {
    const { name, value, id } = e.target;
    if (value) setisPublishButtonDisabled(false);
    const valueCpy = [...listData];

    valueCpy.forEach((element, index) => {
      if (`${element.id}` === `${id}`) {
        if (element.serviceId !== null) {
          element.wasEdited = true;
        }
        if (name === 'displayOrder') {
          element[name] = value && parseInt(value);
          return;
        }

        element[name] = value;
      }

      return element;
    });
    setListData([...valueCpy]);
  };
  const toogleChangestatus = () => {
    setisPublishButtonDisabled(false);
    const valueCpy = [...listData];
    valueCpy.forEach((element, index) => {
      if (!element.isNew) element.wasEdited = true;
      element['is_active'] = !toggled;
      return element;
    });
    setListData([...valueCpy]);
  };

  const callAddOrUpdateAPI = async () => {
    let addpayloadData = [];
    let editpayloadData = [];

    listData.forEach((item, index) => {
      var consData = {};
      var newconsData = {};

      if (item.isNew) {
        consData.is_active = toggled;
        if (item?.displayOrder) {
          consData.display_order = item.displayOrder;
        }
        if (item?.title) {
          consData.service_name = item.title;
        }
        if (item.serviceId) {
          consData.id = item.serviceId;
        }

        if (item?.attachmentFile) {
          consData.image_file = item.attachmentFile;
        }
        if ((item.url || item.externalUrl) && item.urlType) {
          consData.cta_type = item.urlType;
          if (item.urlType === URLType.EXTERNAL) {
            consData.deep_link_url = item.externalUrl;
          }
          if (item.urlType === URLType.INTERNAL) {
            consData.deep_link_url = item.url;
          }
        } else {
          consData.cta_type =
            item?.urlType !== '' ? item?.urlType : URLType?.NO;
        }
        addpayloadData.push(consData);
      }
      if (item.wasEdited && !item?.isNew) {
        newconsData.is_active = toggled;
        if (item?.displayOrder) {
          newconsData.display_order = item.displayOrder;
        }
        if (item?.title) {
          newconsData.service_name = item.title;
        }
        if (item?.is_active) {
          newconsData.is_active = toggled;
        }

        if (item.serviceId) {
          newconsData.id = item.serviceId;
        }

        if (item?.attachmentFile) {
          newconsData.image_file = item.attachmentFile;
        }
        if ((item.url || item.externalUrl) && item.urlType) {
          newconsData.cta_type = item.urlType;
          if (item.urlType === URLType.EXTERNAL) {
            newconsData.deep_link_url = item.externalUrl;
          }
          if (item.urlType === URLType.INTERNAL) {
            newconsData.deep_link_url = item.url;
          }
        } else {
          newconsData.cta_type =
            item?.urlType !== '' ? item?.urlType : URLType?.NO;
        }
        editpayloadData.push(newconsData);
      }
    });
    setPageLoading(true);
    try {
      const promises = [];

      if (addpayloadData) {
        promises.push(addWidgets(addpayloadData));
      }
      if (editpayloadData) {
        promises.push(updateWidgets(editpayloadData));
      }

      // Wait for all promises to resolve (API calls to complete)
      await Promise.all(promises);

      // All other APIs have completed successfully
      setRefresh(!refresh);
      setPageLoading(false);
    } catch (error) {
      // Handle errors here if any of the API calls fail
      console.error('API call failed:', error);
      setPageLoading(false);
    }
  };

  const handleCreateServices = (e) => {
    handleClose();
    callAddOrUpdateAPI();
  };
  const onFileSelected = (file, id) => {
    let newData = [...listData];
    if (file) setisPublishButtonDisabled(false);
    if (newData && newData.length > 0 && file) {
      newData = newData.map((item) => {
        if (item.id === id && id) {
          if (item.serviceId !== null) {
            item.wasEdited = true;
          }
          item.attachmentFile = file;
        }
        return item;
      });
      setListData(newData);
    }
  };

  const openUp = () => {
    confirmAlert({
      title: '',
      message: toggled
        ? 'Are you sure you want to Unpublish Widgets?'
        : 'Are you sure you want to Publish  Widgets?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            // setisPublishButtonDisabled(false);
            setToggled(!toggled);
            toogleChangestatus();
          },
        },
        {
          label: 'No',
          // onClick: () => handleToggle,
        },
      ],
    });
  };

  const handleToggle = () => {};
  return {
    pageLoading,
    onFileSelected,
    OnhandleChange,
    count,
    listData,
    setListData,
    getServiceOrder,
    updateServiceOrder,
    handleCreateServices,
    sortedList,
    validateRequestpreviewScreen,
    handleClose,
    handleShow,
    show,
    toggled,
    openUp,
    handleToggle,
    addWidgetSection,
    isPublishButtonDisabled,
    isAddButtonDisabled,
  };
};

export default useContentHomepageWidgetForm;
