import * as dataAccess from 'utils/ajax';
import ApiParser from 'utils/ApiParser';
import { API_CONSTANTS } from 'constants/api-contants';

export const getStaticContent = async (start) => {
  let payload = {
    data: {
      page: start ? start : 1,
      limit: 10,
    },
  };
  let responseObj = await dataAccess.post(
    API_CONSTANTS.GET_STATIC_CONTENT,
    payload
  );
  return ApiParser.parseStaticContentListing(responseObj);
};

export const addStatic = async (incomingData) => {
  let payload = {
    data: {
      publish: 1,
      ...incomingData,
    },
  };

  return await dataAccess.post(API_CONSTANTS.ADD_STATIC_CONTENT, payload);
};

export const deleteStatic = async (staticId) => {
  if (!staticId) {
    return;
  }

  let payload = {
    data: {
      static_page_id: staticId,
    },
  };
  return await dataAccess.del(
    `${API_CONSTANTS.STATIC_CONTENT_VERSION3}/${staticId}`,
    payload
  );
};

export const getTC = async () => {
  let responseObj = await dataAccess.get(API_CONSTANTS.GET_TERMS_OF_USE);
  return ApiParser.parseTC(responseObj, 'terms_of_use');
};

export const getPrivacyPolicy = async () => {
  let responseObj = await dataAccess.get(API_CONSTANTS.GET_PRIVACY_POLICY);
  return ApiParser.parseTC(responseObj, 'privacy_policy');
};

export const getStaticContentById = async (id, lang) => {
  let url = `${API_CONSTANTS.STATIC_CONTENT_VERSION3}/${id}?lang=${lang}`;
  let responseObj = await dataAccess.get(url);
  return responseObj;
};

export const updateStatic = async (data, id, lang) => {
  let url = `${API_CONSTANTS.STATIC_CONTENT_VERSION3}/${id}?lang=${lang}`;
  let payload = {
    data,
  };

  return await dataAccess.put(url, payload);
};
