import CmsTagsTable from "app/container/CMSTagsContainer";
import BasePage from "app/pages/BasePage";

const TagsTable = () => {
     return (
          <BasePage>
               <CmsTagsTable />
          </BasePage>
     );
}

export default TagsTable;