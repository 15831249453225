import React, { useEffect, useState } from 'react'
import Button from "app/components/button";
import CustomTable from './table';
import { useHistory } from 'react-router-dom';
import Loader from 'components/Loader';
import { getLandingPageList, deleteLandingById ,updateLandingActiveStatus} from 'app/api/widgets';
import Toast from 'app/utils/Toast';
import Pagination from "../UserJourney/AdTaskList/pagination";
const UserLandingScreen = (tableHeader) => {
    const history = useHistory();
    const [data, setData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [pageCount, setPageCount] = useState(1);
    const [loader, setLoader] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10);
    const [toggle, setToggle] = useState(null);


    useEffect(() => {
        getContentDataHandler();
    }, [])
    function paginationHandler(page) {
        setCurrentPage(page);
    }
    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    let currentData = data.slice(indexOfFirstData, indexOfLastData);

    async function getContentDataHandler() {
        let dataArr;
        setLoader(true);
        dataArr = await getLandingPageList();
        setLoader(false);
        if (dataArr && dataArr.response && dataArr.response.code === 200) {
            if (dataArr.data) {
                setTotalData(dataArr.data);
                setData(dataArr.data);
                let count = Math.ceil(dataArr.data.length / dataPerPage);
                setPageCount(count);
            }

            // Toast.success(dataArr.response.alert[0].message);
            setLoader(false);
        } else {
            setLoader(false);
            Toast.error(
                dataArr.data && dataArr.data.msg
                    ? dataArr.data.msg
                    : dataArr.response.alert[0].message
            );
        }
    }

    const handleDelete = async (data) => {
       
        let text = 'Are you sure, want to delete';
        if (window.confirm(text) == true) {
            setLoader(true)
            await deleteLandingById(data)
            getContentDataHandler()
        }


    }
    const handleEdit = async (item, key, index) => { 
        if(key ==='Edit'){
            let tempData = []
            data.forEach((val, i) => {
                if(index === i ){
                let temp = val
                val['isDisabled'] = false
                tempData.push(temp)
                }else{
                    tempData.push(val)
                }
            });
            setData(tempData)
        } 
       }
       const handleToggle=(id,e)=>{
        
        setToggle(e.target.value);
        
       }
       

       if (loader === true) {
        return <Loader />;
      }
    return (
        <div className="cms-content__container">
            <h4>User Landing</h4>
            <div className="content-container__actionBox">
                <div className="content-container__filterSearch">
                </div>
                <Button
                    onClick={() => history.push('/user-landing/create-user-landing')}
                    className="content-container__filter-btn">Add New</Button>
            </div>

            <div className="content-container__table">
                <CustomTable
                    data={currentData}
                    tableHeader={tableHeader.tableHeader}
                    handleDelete={handleDelete}
                // handleInputChangeHandler={handleInputChangeHandler}
                handleEdit={handleEdit}
                handleToggle={handleToggle}
                toggle={toggle}
                setToggle={setToggle}
                // handleUnpublish={handleUnpublish}
                />
            </div>
            <div className="content-container__pagination">
                <Pagination
                    pages={pageCount}
                    onChange={paginationHandler}
                />
            </div>
        </div>

    )
}

export default UserLandingScreen