import React from 'react';

// Pages
import BasePage from '../BasePage';
import { HabitHeader } from 'app/constants/table-header-constants';
import HabitsList from 'app/container/HabitsList';

const Habits = () => {
  return (
    <BasePage>
      <HabitsList tableHeader={HabitHeader} />
    </BasePage>
  );
};

export default Habits;
