import ElsAdsSpaceContainer from "app/container/ElsAdsSpaceContainer";
import BasePage from "app/pages/BasePage";

const ElsAdsSpace = () => {
    return (
        <BasePage>
            <ElsAdsSpaceContainer />
        </BasePage>
    );
}

export default ElsAdsSpace;