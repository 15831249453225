import React, { useState, useEffect } from 'react'
import MultiSelectDropdown from 'app/components/MultiSelectDropdown';
import Button from 'app/components/button';
import { useHistory,useParams } from 'react-router-dom';
import TimePicker from 'react-time-picker'
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { createLandingScreen,getUserLandingByID,updateUserLandingScreen } from 'app/api/widgets';
import Toast from "app/utils/Toast";
import Loader from 'components/Loader';
import moment from 'moment';
import PageLoader from "app/components/PageLoader"; 
import DropdownOptions from "app/utils/DropdownOptions";
import DateTimePicker from 'react-datetime-picker';
const UserLandingForm = () => {
    const history = useHistory();
    const params = useParams();
    const [start_time, setStart_Time] = useState('');
    const [end_time, setEnd_Time] = useState('');
    const [loader, setLoader] = useState(false) 
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState();
    const [formattedStartDate,setFormattedStartDate]=useState(moment(new Date()).format('DD-MM-YYYY'));
    const[formattedEndDate,setFormattedEndDate]=useState(moment(new Date()).format('DD-MM-YYYY'))
    const [state, setState] = useState({
        cohort: '',
        section: '',
        // startTime:'',
        // endTime:''
    })

    useEffect(() => { 
        
        if(params.id){
            getDetailsByid(params.id)
       }
    }, [])
    const cohortOptions = [
        {
            label: 'Asthma',
            id: '1',
            value: 'ASTHMA',
        },
        {
            label: 'COPD',
            id: '2',
            value: 'COPD',
        },
        {
            label: 'AR',
            id: '3',
            value: 'AR',
        },
        {
            label: 'ILD',
            id: '4',
            value: 'ILD',
        },
        {
            label: 'Others',
            id: '5',
            value: 'OTHER',
        },
    ];
    const landingScreen = [
        {
            label: 'Homepage',
            value: 'HOMEPAGE',
        },
        {
            label: 'Exercise',
            value: 'EXERCISE',
        },
        {
            label: 'ELS',
            value: 'ELS',
        },
        {
            label: 'Services',
            value: 'SERVICES',
        },
    ];

    const onStartTimeChange = (event) => {
        if (event) {
            let eventStart=`${event}+0530`
            setStart_Time(eventStart);
        } else {
            setStart_Time('')
        }
    }
    const onEndTimeChange = (event) => {
        if (event) {
            let eventEnd=`${event}+0530`
            setEnd_Time(eventEnd);
        } else {
            setEnd_Time('')
        }
    }
    
    const handleStartDateChange = (date) => { 
        setStartDate(date); 
        const formattedDate = moment(date).format('DD-MM-YYYY'); 
        setFormattedStartDate(formattedDate)
        console.log('Start Date:', formattedDate); // Here you can use the formatted date, e.g., save it to state or send it to an API 
    }; 
    const handleEndDateChange = (date) => { 
       
            setEndDate(date); 
            const formattedDate = moment(date).format('DD-MM-YYYY'); 
            setFormattedEndDate(formattedDate)
            console.log('End Date:', formattedDate);  
        } 
     
  
    
    const handleTextChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    };
    
    const handleSubmit =async () => {
    

        // let startTime = `${start_time}+0530`;
        // let endTime = `${end_time}+0530`;
        // startTime=""
        // endTime=""
        let payload = { ...state, start_time,end_time,start_date:null,end_date:null }
        if (!state.section || !state.cohort) {
            Toast.error('Please complete all fields');
            return;
          }else{
            setLoader(true);
            let responseObj;
            if(params.id){   
                let updatePayload ={...state,start_time,end_time,start_date:null,end_date:null}
                responseObj = await updateUserLandingScreen(updatePayload,params.id)
            }else{
                responseObj = await createLandingScreen(payload);
                
            }
            
            if (
            responseObj &&
            responseObj.response &&
            responseObj.response.code === 200
          ) {
            Toast.success(responseObj.response.alert[0].message);
            history.push('/user-landing');
          } else {
            setLoader(false);
            Toast.error(
              responseObj.data
                ? responseObj.data
                : responseObj.data
                  ? responseObj.data
                  : ''
            );
          }
          
          }

    }
    const getDetailsByid= async(id)=>{
        const userLandingDetails = await getUserLandingByID(id) 
        setState({
            cohort: userLandingDetails.cohort,  
            section: userLandingDetails.section,
        })
        setStart_Time(userLandingDetails.start_time);
        setEnd_Time(userLandingDetails.end_time);
        setStartDate(userLandingDetails.start_date ? moment(userLandingDetails.start_date,'DD-MM-YYYY').toDate() : new Date()); 
        setEndDate(userLandingDetails.end_date ? moment(userLandingDetails.end_date,'DD-MM-YYYY').toDate() : new Date());  

        
    }
    if (loader === true) {
        return <Loader />;
      }
    return (
        <div className='container'>
            <div className="row">
            <div className='separator'>
                <p>User Landing on app</p>
            </div>
            </div>

            <div className='row border pb-4'>
                <p className='mt-3'>Where do you want user to land ?</p>
                {/* <div className='col-md-6'>
                    <MultiSelectDropdown
                        label='Cohort Tag'
                        name='cohort_tags'
                        value={state.cohort_tags}
                        options={cohortOptions}
                        onChange={handleTextChange}
                    tag_type={false}
                    disable={disableFields}
                    />
                </div> */}
                <div className='col-sm-6'>
                    <div className=' my-5'>
                        <label>Select Cohort </label>
                        <select
                            name='landing_type'
                            className='custom-select mx-3 w-50'
                            onChange={(e) => {
                                setState({ ...state, cohort: e.target.value });
                            }}
                        // disabled={disableFields}
                        >
                            <option value=''>Select</option>
                            {cohortOptions?.map((item, indx) => (
                                <option
                                    key={indx}
                                    value={item.value}
                                selected={state.cohort == item.value ? true : false}
                                >
                                    {item.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className=' my-5'>
                        <label>Select Screen </label>
                        <select
                            name='landing_type'
                            className='custom-select mx-3 w-50'
                            onChange={(e) => {
                                setState({ ...state, section: e.target.value });
                            }}
                        // disabled={disableFields}
                        >
                            <option value=''>Select</option>
                            {landingScreen?.map((item, indx) => (
                                <option
                                    key={indx}
                                    value={item.value}
                                selected={state.section == item.value ? true : false}
                                >
                                    {item.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                {/* <div className='col-sm-6'>
                    <div className=' my-4'>
                        <label>Start Date :</label>
                    <DateTimePicker onChange={handleStartDateChange} value={startDate} format="dd-MM-yyyy" disableClock={true} />
                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className=' my-4'>
                        <label>End Date :</label>
                    <DateTimePicker onChange={handleEndDateChange} value={endDate} format="dd-MM-yyyy" disableClock={true} />
                    </div>
                </div> */}
                <div className='col-sm-6'>
                    <div className=' my-4'>
                        <label>Start Time </label>
                        <br />

                        <TimePicker
                            label="Timer"
                            name="time"
                            disableClock={false}
                            shouldOpenClock={false}
                            onChange={onStartTimeChange}
                            value={start_time.split("+",1).join('')}
                            hourPlaceholder="hh"
                            minutePlaceholder="mm"
                            isOpen={false}
                            clockIcon={null}
                        />

                    </div>
                </div>
                <div className='col-sm-6'>
                    <div className=' my-4'>
                        <label>End Time </label>
                        <br />
                        <TimePicker
                            label="Timer"
                            name="time"
                            disableClock={false}
                            shouldOpenClock={false}
                            onChange={onEndTimeChange}
                            value={end_time.split("+",1).join('')}
                            hourPlaceholder="hh"
                            minutePlaceholder="mm"
                            isOpen={false}
                            clockIcon={null}
                        />
                    </div>
                </div>
                <div className='userFormArea'>
                    <Button name={'Save'}
                        onClick={handleSubmit}
                    />
                    <label
                        style={{ paddingLeft: '10px', cursor: 'pointer' }}
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        Cancel
                    </label>
                </div>
            </div>
        </div>
    )
}

export default UserLandingForm