import React, { useEffect, useState } from 'react';

// Components
import Checkbox from 'components/checkbox';

// Shared Components
import Dropdown from 'shared/components/Dropdown';

// Utils
import DropdownOptions from 'utils/DropdownOptions';

import './index.scss';

const FeedTypeComponent = ({ homePageValue, value, handleChange }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const onChange = (e) => {
    setShowDropdown(e.target.checked);
    handleChange &&
      handleChange({
        target: {
          name: 'homepage',
          value: e.target.checked,
        },
      });
  };

  useEffect(() => {
    if (value === true || value === false) {
      setShowDropdown(value);
    }
  }, [value]);

  return (
    <div className='feed-type'>
      <Checkbox
        id='homepage'
        label='Show feed on Home page'
        onChange={onChange}
        checked={showDropdown}
      />
      {showDropdown && (
        <Dropdown
          label='Section Name'
          placeholder='Select option'
          name='homePageSection'
          value={homePageValue}
          options={DropdownOptions.HomePageOptions}
          handleChange={handleChange}
        />
      )}
    </div>
  );
};

export default FeedTypeComponent;
