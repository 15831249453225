import React from 'react';

// Pages
import BasePage from 'pages/BasePage';

import CreateWidget from 'app/container/CreateWidget';

const WidgetsCreatePage = () => {
  return (
    <BasePage>
      {/* <Banner tableHeader={BannersTableHeader} /> */}
      <CreateWidget />
    </BasePage>
  );
};

export default WidgetsCreatePage;
