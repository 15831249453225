import {
  getRecommendedCourseAPI,
  updateCousesOrdering,
} from 'app/api/latest/Courses/courses';

import Message from 'app/utils/Message';
import { useEffect, useState } from 'react';
import Toast from 'utils/Toast';

const useRecommendedCoursesOrdering = () => {
  const [pageLoading, setPageLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedAge, setSelectedAge] = useState([]);
  const [selectedCohort, setSelectedCohort] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const filterData = () => {
    return selectedItems.filter((course) => {
      const ageMatches =
        selectedAge.length === 0 ||
        selectedAge.some((age) => course.age_range.includes(age));
      const cohortMatches =
        selectedCohort.length === 0 ||
        selectedCohort.some((cohort) => course.cohorts.includes(cohort));
      return ageMatches && cohortMatches;
    });
  };

  useEffect(() => {
    setFilteredData(filterData());
  }, [selectedAge, selectedCohort, selectedItems]);

  const handleAgeSelection = (age) => {
    setSelectedAge(
      selectedAge.includes(age)
        ? selectedAge.filter((selected) => selected !== age)
        : [...selectedAge, age]
    );
  };

  const handleCohortSelection = (cohort) => {
    setSelectedCohort(
      selectedCohort.includes(cohort)
        ? selectedCohort.filter((selected) => selected !== cohort)
        : [...selectedCohort, cohort]
    );
  };


  useEffect(() => {
    getRecommendList();
  }, []);

  const getRecommendList = async (filter) => {
    setPageLoading(true);
    let response = {};
    response = await getRecommendedCourseAPI()

    setPageLoading(false);

    let newtemp = response?.data?.map((item, index) => {
      return {
        ...item,
        sorting_order: item.sorting_order ? item.sorting_order : index + 1,
      };
    });

    setPageLoading(false);

    setSelectedItems(newtemp);
    if (response?.response?.code === 200) {
    } else {
      Toast.error(
        response?.data ? response?.data : Message?.Error?.COMMON_MESSAGE
      );
    }
  };

  const handleSelectedItemsChange = async (newSelectedItems) => {
    const temp = newSelectedItems.map((item, index) => {
      return {
        course_uuid: item.id,
        sorting_order: index + 1,
      };
    });
    const newtemp = newSelectedItems.map((item, index) => {
      return {
        ...item,
        course_uuid: item.id,
        sorting_order: index + 1,
      };
    });
    setPageLoading(true);
    const response = await updateCousesOrdering(temp);
    setPageLoading(false);
    if (response.response.code === 204) {
      Toast.success('Courses Ordering Updated Successfully');
    } else {
      Toast.error('Failed to save the ordering');
    }
    setSelectedItems(newtemp);
    getRecommendList();
  };

  return {
    selectedAge,
    selectedCohort,
    filteredData,
    handleAgeSelection,
    handleCohortSelection,
    pageLoading,
    selectedItems,
    setSelectedItems,
    handleSelectedItemsChange,
  };
};

export default useRecommendedCoursesOrdering;
