import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

// Components
import { getVersionControl } from 'app/api/versionList';
import Attachment from 'app/components/Attachment';
import BannerCard from 'components/BannerCard';
import CTAComponent from 'components/CTAComponent';
import Calender from 'components/Calender';
import HeaderWithBackButton from 'components/HeaderWithBackButton';
import MultiSelectDropdown from 'components/MultiSelectDropdown';
import NewInput from 'components/NewInput';
import PageLoader from 'components/PageLoader';
import NumberInput from 'components/UI/NumberInput';
import validate from 'components/ValidateInfo';
import Button from 'components/button';
import useContentBannerForm from 'components/useContentBannerForm';
import Dropdown from 'shared/components/Dropdown';
import ValidationMessage from 'utils/ValidationMessage';
// API
import {
  addOrUpdateBanner,
  getBannerTag,
  getEventBanner,
} from 'app/api/banner';
// Constants
import { APP_CONSTANTS, URLType } from 'constants/app-constants';

// Utils
import Message from 'utils/Message';
import Toast from 'utils/Toast';
import './index.scss';

const CreateBanner = (props) => {
  const history = useHistory();
  const location = useLocation();
  // const [data, dataSet] = useState('');
  let [chars, setChars] = useState('');
  let [tag, setTag] = useState('');
  const [activate, setActivate] = useState(true);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [validated, setValidated] = useState(true);
  const [bannerId, setBannerId] = useState();
  let [versionLists, setVersionLists] = useState([]);
  const [appVersion, setappVersion] = useState('Android');

  const [type, setType] = useState('');
  const [pageLoading, setPageLoading] = useState(false);

  const {
    values,
    error,
    OnhandleChange,
    handleChange,
    updateValue,
    setValues,
    addBannerSection,
    removeBannerSection,
    redirectId,
    count,
  } = useContentBannerForm(validate);

  const VersionRange = [
    {
      value: 'GREATER_THAN',
      label: 'Greater Than',
    },
    {
      value: 'LESS_THAN',
      label: 'Lesser Than',
    },
    {
      value: 'EQUALS',
      label: 'Equals',
    },
  ];

  useEffect(() => {
    async function fetchBanner() {
      let response = await getEventBanner(); 
      const data = Object.entries(response.data);

      let newState = Object.keys(response.data).map((key) => {
        return {
          value: [key][0],
          label: response.data[key],
        };
      });

      setChars(newState);
    }

    fetchBanner();
  }, []);

  useEffect(() => {
    async function fetchBanner() {
      let response = await getVersionControl(appVersion);

      const data = response.data.available_versions;
      let newState = data.map((key) => {
        return {
          value: [key][0].toString(),
          label: [key][0].toString(),
        };
      });
      setVersionLists(newState);
    }
    fetchBanner();
  }, []);

  const handleBannerTags = (e) => {
    const data = values.banners.map((item) => {
      if (item.id === e.target.id) {
        item.bannerTag = e.target.value;
      }
      return item;
    });

    setValues((prevstate) => ({
      ...prevstate,
      banners: data,
    }));
  };

  const onFileSelected = (file, id) => {
    let newData = [...values.banners];

    if (newData && newData.length > 0 && file) {
      newData = newData.map((item) => {
        if (item.id === id && id) {
          item.attachmentFile = file;
        }
        return item;
      });
      updateValue({
        ...values,
        banners: newData,
      });
    }
  };

  useEffect(() => {
    async function fetchBanners() {
      let response = await getBannerTag();
      let newState = Object.keys(response.data).map((key) => {
        return {
          value: [key][0],
          name: response.data[key],
        };
      });

      setTag(newState);
    }

    fetchBanners();
  }, []);

  const onFallbackBannerSelected = (file) => {
    if (file) {
      updateValue({
        ...values,
        fallbackBannerFile: file,
      });
    }
  };
  // debugger;
  useEffect(() => {
    let data = location.state;
    if (data && data.banner_type_id) {
      let newData = {
        bannerType: data.type_of_banner,
        bannerTypeId: data.banner_type_id,
        fallbackBanner: data.fallback_banner,
        startDate: data.start_date,
        endDate: data.end_date,
        status: data.status,
        banners: [],
        removedBanners: [],
        swappingOrder: data.sorting_order,
      };
      setStartDate(newData.startDate);
      setEndDate(newData.endDate);
      if (data.banner_details && data.banner_details.length > 0) {
        newData.banners = data.banner_details.map((banner, index) => { 
          let url = '';
          let externalUrl = '';
          if (banner.url_type && banner.url_type.trim() === URLType.EXTERNAL) {
            externalUrl = banner.url ? banner.url : '';
          }
          if (
            banner.url_type &&
            banner.url_type.trim() === URLType.EXTERNAL_PWA
          ) {
            externalUrl = banner.url ? banner.url : '';
          }
          if (banner.url_type && banner.url_type.trim() === URLType.INTERNAL) {
            url = banner.url ? banner.url : '';
          }
          // if (!banner.event_payload_type) {
          //   Toast.error(ValidationMessage.EVENTPAYLOADTYPE);
          //   return;
          // }
          /* id: Date.now(), */
          return {
            id: `banner_${index}`,
            bannerId: banner.banner_id,
            title: banner.title,
            displayOrder: banner.display_order,
            eventPayloadType: banner.event_payload_type,
            eventName: banner.event_name,
            bannerTag: banner.banner_tag,
            attachment: banner.attachment_path,
            urlType: banner.url_type ? banner.url_type.trim() : '',
            url: url,
            urlCopy: url,
            redirection_id: banner.redirection_id,
            redirection_idURL: banner.redirection_id,
            externalUrl: externalUrl,
            criteria: banner.criteria,
            android_version: banner.android_version?banner.android_version.toString():"",
            internal_for: banner.internal_for,
            internal_url: banner.internal_url,
            profile_data: banner.profile_data,
            custom_object: banner.custom_object,
          };
        });
      }

      updateValue(newData);
    }
  }, []);

  const callAddOrUpdateAPI = async () => {
    // debugger;
    /* Populate Data */
    let { swappingOrder, bannerType, fallbackBannerFile, removedBanners } =
      values;

    let defaultVal = '';

    let data = {
      banner_type: bannerType,
      banner_details: [],
      start_date: defaultVal,
      end_date: defaultVal,
      removedBanners,
    };
    if (swappingOrder) {
      data.sorting_order = swappingOrder;
    }

    if (fallbackBannerFile) {
      data.fallback_banner_image = fallbackBannerFile;
    }
    // debugger;
    let isPrevent;
    let isEvent;
    let version;
    console.log(values.banners, 'values.banners')
    values.banners.forEach((banner, index) => {
      let consData = {
        display_order: banner.displayOrder,
        banner_tag: banner?.bannerTag?.length > 0 ? banner.bannerTag : null,
        event_payload_type: banner.eventPayloadType,
        event_name: banner.eventName,
        update_banner: 1,
        title: `${bannerType}_${index}`,
        redirection_id: banner.redirection_id,
        criteria: banner.criteria,
        android_version: banner.android_version,
        internal_for: banner.internal_for,
        internal_url: banner.internal_url,
        profile_data: banner.profile_data,
        custom_object: banner.custom_object,
      };

      if (banner.isNew === true) {
        consData.update_banner = 0;
      } else if (banner.bannerId) {
        consData.banner_id = banner.bannerId;
        consData.update_banner = 1;
      }

      if(banner.urlType === "INTERNAL_PWA" && !banner.internal_for){
        Toast.error('Enter For');
        isPrevent = true;
      }
      if(banner.urlType === "INTERNAL_PWA" && !banner.internal_url){
        Toast.error('Enter URL');
        isPrevent = true;
      }

      if (!banner.eventPayloadType || banner?.eventPayloadType?.length === 0) {
        Toast.error(ValidationMessage.EVENTPAYLOADTYPE);
        isPrevent = true;
        return;
      }

      if (!banner.eventName || banner?.eventName?.length === 0) {
        Toast.error(ValidationMessage.EVENTNAME);
        isEvent = true;
        return;
      }

      if (banner.bannerId) {
        consData.banner_id = banner.bannerId;
        consData.redirection_id = banner.redirection_id;
      }

      if (banner.attachmentFile) {
        consData.banner_image = banner.attachmentFile;
      }
      if (
        (banner.url || banner.externalUrl || banner.internal_url) &&
        banner.urlType
      ) {
        consData.url_type = banner.urlType;
        if (
          banner.urlType === URLType.EXTERNAL ||
          banner.urlType === URLType.EXTERNAL_PWA
        ) {
          consData.url = banner.externalUrl;
        }
        if (banner.urlType === URLType.INTERNAL) {
          consData.url = banner.url;
        }
      }
      if (!banner.criteria || !banner.android_version) {
        version = true;
      }
      data.banner_details.push(consData);
    });

    if (version) {
      Toast.error('Please select Version and Version Range');
      return;
    }

    if (isPrevent) {
      return;
    }
    if (isEvent) {
      return;
    }

    if (startDate) {
      data.start_date = startDate;
    }

    if (endDate) {
      data.end_date = endDate;
    }

    setPageLoading(true);
    const responseObj = await addOrUpdateBanner(data);
    setPageLoading(false);
    if (
      responseObj &&
      responseObj.length > 0 &&
      responseObj[0] &&
      responseObj[0].response &&
      responseObj[0].response.code === 200
    ) {
      Toast.success(Message.Banner.CREATED_SUCCESS);
      history.goBack();
    } else {
      Toast.error(Message.Banner.CREATE_FAILURE);
    }
  };

  const handleEventUpdate = (e) => {
    const data = values.banners.map((item) => {
      if (item.id === e.target.id) {
        item.eventName = e.target.value;
      }
      return item;
    });

    setValues((prevstate) => ({
      ...prevstate,
      banners: data,
    }));
  };

  const handleVersionChange = (e) => {
    values.banners.map((item) => {
      if (item.id === e.target.id) {
        if (e.target.name === 'android_version') {
          item.android_version = e.target.value;
        } else {
          item.criteria = e.target.value;
        }
      }
      return item;
    });
  };

  const handlePayloadText = (e) => {
    const data = values.banners.map((item) => {
      if (item.id == e.target.id) {
        item.eventPayloadType = e.target.value;
      }
      return item;
    });

    setValues((prevstate) => ({
      ...prevstate,
      banners: data,
    }));
  };

  const handleCreateBanner = (e) => {
    callAddOrUpdateAPI();
  };

  const onStartDateSelected = (date) => {
    setStartDate(date);
  };

  const onEndDateSelected = (date) => {
    setEndDate(date);
  };

  const handleAddBannerSection = () => {
    addBannerSection();
  };

  const handleRemoveBannerSection = (index) => {
    removeBannerSection(index);
  };

  let isAddButtonDisabled = false;
  if (count >= 14) {
    isAddButtonDisabled = true;
  }
  return (
    <div className='blogsForm'>
      <div className='blogsForm_content'>
        <HeaderWithBackButton
          title='Add/edit a Banner'
          goTo={APP_CONSTANTS.BANNER_LIST}
        />

        <NumberInput
          label='Sequence order'
          id='swappingOrder'
          placeholder='Enter sequence order'
          name='swappingOrder'
          value={values.swappingOrder}
          onChange={handleChange}
        />

        <Attachment
          label='Add Fallback Banner'
          value={values.fallbackBanner}
          onFileSelected={onFallbackBannerSelected}
          fileType='image'
          className='Banner_size'
        />

        <Calender
          onStartDateSelected={onStartDateSelected}
          onEndDateSelected={onEndDateSelected}
          startDate={startDate}
          endDate={endDate}
          validated={validated}
          activate={activate}
        />

        {values.banners.map((data, index) => {
          return (
            <BannerCard
              key={data.id}
              title={data.title}
              index={index}
              handleRemoveCard={handleRemoveBannerSection}
            >
              <div>
                <Attachment
                  value={data.attachment}
                  id={data.id}
                  onFileSelected={onFileSelected}
                  fileType='image'
                  label={`Add Banner ${index + 1}`}
                  className='Banner_size'
                />
              </div>

              <CTAComponent
                id={data.id}
                index={data.id}
                externalUrl={data.externalUrl}
                ctaValue={data.ctaTitle}
                urlType={data.urlType}
                value={data.url}
                isHide={true}
                internal_for={data.internal_for}
                profile_data={data.profile_data?data.profile_data:false}
                internal_url={data.internal_url}
                custom_object={data.custom_object}
                onChange={OnhandleChange}
                swappingOrder={data.swappingOrder}
                redirection_id={
                  data.redirection_id ? data.redirection_id : null
                }
                // value={data.eventName ? data.eventName : null}
                OnhandleChange={OnhandleChange}
                urlCopy={data.urlCopy}
                redirection_idURL={data.redirection_idURL}
              />

              {/* {data.url ==="feed" && <NumberInput
              label='New field'
              id='redirectId'
              placeholder=''
              name='redirectId'
              value={redirectId}
              onChange={OnhandleChange}
            />} */}

              <NumberInput
                label='Display order'
                id={data.id}
                placeholder='Enter display Order'
                name='displayOrder'
                value={data.displayOrder}
                onChange={OnhandleChange}
              />

              <Dropdown
                Tooltiptext='Select appropriate age group'
                id={data.id}
                label='Event Name'
                placeholder='Select Name'
                name='eventName'
                value={data.eventName ? data.eventName : null}
                options={chars}
                handleChange={handleEventUpdate}
              />

              <MultiSelectDropdown
                id={data.id}
                label='Select a Banner Tag'
                name='banner Tag'
                value={data.bannerTag}
                onChange={handleBannerTags}
                // value={values.tag}
                options={tag ? tag : []}
              />

              <NewInput
                label='Event Payload Type'
                id={data.id}
                type='text'
                placeholder='Ex: Event Payload Type'
                name='eventPayloadType'
                value={data.eventPayloadType}
                onChange={handlePayloadText}
                errorText={error.title}
              />
              <Dropdown
                id={data.id}
                label='Selected Version'
                placeholder='Selected Version'
                name='android_version'
                value={data.android_version}
                options={versionLists}
                handleChange={handleVersionChange}
              />

              <Dropdown
                id={data.id}
                label='Version Range'
                placeholder='Version Range'
                name='criteria'
                value={data.criteria}
                options={VersionRange}
                handleChange={handleVersionChange}
              />
              {/* <FeedTypeComponent
                value={values.homepage}
                homePageValue={values.homePageSection}
                handleChange={handleChange}
              /> */}
            </BannerCard>
          );
        })}

        <div className='mt1'>
          <Button onClick={handleCreateBanner} type='submit' name='Submit' />
          <Button
            className='ml1'
            onClick={handleAddBannerSection}
            type='submit'
            name='Add Banner'
            disabled={isAddButtonDisabled}
          />
        </div>
      </div>
      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </div>
  );
};

export default CreateBanner;
