import React from 'react';
import BasePage from 'pages/BasePage';
import Recommendations from 'app/container/Recommendations';

import { RecommendationsTableHeader } from 'constants/table-header-constants';

const RecommendationsCreatePage = () => {
  return (
    <>
      <BasePage>
        <Recommendations tableHeader={RecommendationsTableHeader} />
      </BasePage>
    </>
  );
};

export default RecommendationsCreatePage;
