import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import Loader from 'components/Loader';
import NewTable from 'components/NewDraggableTable';
import Button from 'app/components/button';

// API
// import { getActivities } from 'api/activity';
// import { updateHomepageOrderingHome } from 'api/homepageorder';
import { getHomePage } from 'api/homepageorder';
import { addOrUpdateHomePageOrder } from 'api/homepageorder';

// // Utils
// import Toast from 'utils/Toast';
// import Message from 'utils/Message';

const HomePageOrderContainer = ({ tableHeader }) => {
  const history = useHistory();
  // const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const HomePageOrder = (tempData) => {
    const HomepageData = tempData.map((data, index) => {
      return {
        home_page_id: data.original.id,
        sorting_order: index + 1,
      };
    });
    const res = addOrUpdateHomePageOrder(HomepageData);
  };

  const menuActionObj = [
    {
      name: 'Edit',
      onClick: (e, data) => {
        data.section_title = 'Homepage Order';
        history.push({
          pathname: `/edit-homepage-order/${data.id}`,
          state: {
            ...data,
          },
        });
      },
    },
  ];

  const sectionObj = {
    sectionTitle: 'HomePage-Order',
    searchObj: {
      placeholder: 'Search',
      handleSearch: (e) => {
        console.log('Handle search: ', e);
      },
    },
  };

  if (loading === true) {
    return <Loader />;
  }
  return (
    <div className='blogs'>
      <NewTable
        sectionObj={sectionObj}
        tableHeader={tableHeader}
        menuAction={menuActionObj}
        data={data}
        HomePageOrder={HomePageOrder}
        getData={getHomePage}
        hasPagination={false}
      />
    </div>
  );
};
export default HomePageOrderContainer;
