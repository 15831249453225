import React from 'react';

// Pages
import BasePage from 'pages/BasePage';
import CreateFaq from 'container/CreateFaq';

const FaqCreatePage = () => {
  return (
    <BasePage>
      <CreateFaq />
    </BasePage>
  );
};

export default FaqCreatePage;
