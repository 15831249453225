import { API_CONSTANTS } from 'app/constants/api-contants';
import * as dataAccess from 'utils/ajax';

export const getCouponAndOffer = async () => {
  let responseObj = await dataAccess.get(API_CONSTANTS.VITALS_COUPON_OFFERS);
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data;
  } else {
    return [];
  }
};
export const addCouponAndOffer = async (incomingData) => {
  let payload = {
    data: {
      // publish: 1,
      ...incomingData,
    },
  };

  return await dataAccess.post(API_CONSTANTS.VITALS_COUPON_OFFERS, payload);
};
export const updateCouponAndOffer = async (incomingData, id) => {
  let url = `${API_CONSTANTS.VITALS_COUPON_OFFERS}/${id}`;
  let payload = {
    data: {
      ...incomingData,
    },
  };

  return await dataAccess.put(url, payload);
};

export const getCouponDataAndOfferById = async (id) => {
  let url = `${API_CONSTANTS.VITALS_COUPON_OFFERS}/${id}`;
  let responseObj = await dataAccess.get(url);
  return responseObj;
};

export const getProductFamilyList = async (id) => {
  let url = `${API_CONSTANTS.GET_SERVICES_FAMILY_PLANS}`;
  let responseObj = await dataAccess.get(url);
  return responseObj;
};
