import CmsDiscoverContainer from "app/container/CMSDiscover";
import BasePage from "app/pages/BasePage";

const CmsDiscover = () => {
     return (
          <BasePage>
               <CmsDiscoverContainer />
          </BasePage>
     );
}

export default CmsDiscover;