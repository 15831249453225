import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

// Components
import NewInput from 'components/NewInput';

import Button from 'components/button';
import MultiSelectDropdown from 'components/MultiSelectDropdown';
import useContentForm from 'components/useContentForm';
// import validate from 'components/ValidateInfo';

import HeaderWithBackButton from 'components/HeaderWithBackButton';
import PageLoader from 'components/PageLoader';
import NumberInput from 'components/UI/NumberInput';

// API
import { addOrUpdateUser } from 'api/users';

// Constants
import { APP_CONSTANTS } from 'constants/app-constants';

// Utils
import Message from 'utils/Message';
import Toast from 'utils/Toast';
import ValidationMessage from 'utils/ValidationMessage';

import './index.scss';

const CreateUser = () => {
  const history = useHistory();
  const location = useLocation();
  // const ref = useRef();

  // const [selFile, setSelectedFile] = useState({});
  const [id, setId] = useState(null);

  const [pageLoading, setPageLoading] = useState(false);

  const { handleChange, values, error, updateValue } = useContentForm();

  // const dataOne = (values) => {};

  useEffect(() => {
    let data = location.state;
    if (data && data.employee_id) {
      let newData = {
        employeeId: data.employee_id,
        firstName: data.first_name,
        lastName: data.last_name,
        profilePicture: data.profile_picture,
        email: data.email,
        mobileNumber: data.mobile,
        tag: data.permission_tag,
      };
      setId(newData.employeeId);
      updateValue(newData);
    }
  }, [location?.state]);

  const state = {
    options: [
      { name: 'Feed', value: 'FEED' },
      { name: 'Banner', value: 'BANNER' },
      { name: 'challanges', value: 'CHALLANGES' },
      { name: 'Activities', value: 'ACTIVITIES' },
      { name: 'Upload Master Details', value: 'UPLOAD_MASTER_DETAILS' },
      { name: 'Static Content', value: 'STATIC_CONTENT' },
      { name: 'Faq', value: 'FAQ' },
      { name: 'Cms Users', value: 'CMS_USERS' },
      { name: 'Splash Screen', value: 'ONBOARDING' },
      { name: 'HomePage Ordering', value: 'HOMEPAGEORDER' },
      { name: 'Vitals and Activity', value: 'ACTIVITY_VITALS' },
      { name: 'Understanding Devices', value: 'UNDERSTANDINGDEVICES' },
      { name: 'Webinar', value: 'WEBINAR' },
      { name: 'Habits', value: 'HABITS' },
      // { name: 'Celebrity Video', value: 'CELEBRITY' },
      // { name: 'Did You Know', value: 'DIDYOUKNOW' },
      // { name: 'Brethefree Educator Video', value: 'BREATEFREE' },
      // { name: 'Understanding Devices', value: 'UNDERSTANDINGDEVICES' },
      { name: 'Videos', value: 'VIDEO' },
      { name: 'DISCOVER CONTENT', value: 'DISCOVER_CONTENT' },
      { name: 'BREATHING EXERCISES', value: 'EXERCISES' },
      { name: 'USER JOURNEY', value: 'USER_JOURNEY' },
      { name: 'RECOMMENDED ACTIONS', value: 'RECOMMENDED_ACTIONS' },
      { name: 'INAPPUPDATE', value: 'INAPPUPDATE' },
      { name: 'Services', value: 'SERVICES' },
      { name: 'HOMEPAGEWIDGET', value: 'HOMEPAGEWIDGET' },
      { name: 'DIAGNOSTIC', value: 'DIAGNOSTIC' },
      { name: 'MEDICINE_DELIVERY', value: 'MEDICINE_DELIVERY' },
      { name: 'PRODUCT_CATALOG', value: 'PRODUCT_CATALOG' },
      { name: 'COINS', value: 'COINS' },
      { name: 'LATESTCOUPONANDOFFERS', value: 'LATESTCOUPONANDOFFERS' },
      { name: 'HOME', value: 'HOME' },
      { name: 'AB_TESTING', value: 'AB_TESTING' },
      { name: 'LOCALISATION', value: 'LOCALISATION' },
      { name: 'CMS_COURSES', value: 'CMS_COURSES' },
      { name: 'REFERRAL', value: 'REFERRAL' },
      { name: 'RATINGS', value: 'RATINGS' },
      { name: 'CUSTOMER_ORDERS', value: 'CUSTOMER_ORDERS' },
      { name: 'UPDATE_USER_REFILL', value: 'UPDATE_USER_REFILL' },
      { name: 'AUDIT_LOGS', value: 'AUDIT_LOGS' },
      { name: 'LCP_SESSION', value: 'LCP_SESSION' },
    ],
  };

  const populatePayload = () => {
    let { employeeId, firstName, lastName, email, mobileNumber, tag } = values;

    // Validations Start
    if (!employeeId || !firstName || !email) {
      Toast.error(ValidationMessage.FILL_REQUIRED_FIELDS);
      return;
    }

    let data = {
      update_user: id ? 1 : 0,
      employee_id: parseInt(employeeId),
      role: 'ADMIN',
      status: 'ACTIVE',
    };

    if (firstName) {
      if (APP_CONSTANTS.EDIT_USER) {
        if (firstName !== location?.state?.first_name) {
          data.first_name = firstName;
        }
      } else {
        if (firstName) {
          data.first_name = firstName;
        }
      }
    }
    if (lastName) {
      if (APP_CONSTANTS.EDIT_USER) {
        if (lastName !== location?.state?.last_name) {
          data.last_name = lastName;
        }
      } else {
        if (lastName) {
          data.last_name = lastName;
        }
      }
    }

    if (email) {
      if (APP_CONSTANTS.EDIT_USER) {
        if (email !== location?.state?.email) {
          data.email = email;
        }
      } else {
        if (email) {
          data.email = email;
        }
      }
    }

    if (mobileNumber) {
      if (APP_CONSTANTS.EDIT_USER) {
        if (mobileNumber !== location?.state?.mobile) {
          data.mobile = mobileNumber;
        }
      } else {
        if (mobileNumber) {
          data.mobile = mobileNumber;
        }
      }
    }

    if (tag) {
      if (APP_CONSTANTS.permission_tag) {
        if (tag !== location?.state?.permission_tag) {
          data.permission_tag = JSON.stringify(tag);
        }
      } else {
        if (tag) {
          data.permission_tag = JSON.stringify(tag);
        }
      }
    }

    return data;
  };

  const callAddOrUpdateAPI = async () => {
    setPageLoading(true);
    let data = populatePayload();
    //debugger;
    const responseObj = await addOrUpdateUser(data);
    setPageLoading(false);
    if (
      responseObj &&
      responseObj.response &&
      responseObj.response.code === 200
    ) {
      Toast.success(Message.User.CREATED_SUCCESS);
      history.goBack();
    } else {
      Toast.error(responseObj?.response?.alert[0].message ? responseObj?.response?.alert[0].message:  Message.User.CREATE_FAILURE);
    }
  };

  const handleCreateUser = (e) => {
    callAddOrUpdateAPI();
  };

  // const onFileSelected = (file) => {
  //   setSelectedFile(file);
  // };

  return (
    <div className='blogsForm'>
      <div>
        <HeaderWithBackButton
          title='Add/edit User'
          goTo={APP_CONSTANTS.USERS}
        />

        <NumberInput
          label='Employee ID'
          id='employeeId'
          placeholder='Enter Employee ID'
          name='employeeId'
          value={values.employeeId}
          onChange={handleChange}
          errorText={error.employeeId}
        />

        <NewInput
          label='First Name'
          id='firstName'
          type='text'
          placeholder='Enter First Name'
          name='firstName'
          value={values.firstName}
          onChange={handleChange}
          errorText={error.firstName}
        />

        <NewInput
          label='Last Name'
          id='lastName'
          type='text'
          placeholder='Enter Last Name'
          name='lastName'
          value={values.lastName}
          onChange={handleChange}
          errorText={error.lastName}
        />

        {/* <NewInput
          label='User Role'
          id='role'
          type='text'
          placeholder='Enter User Role'
          name='role'
          value={values.role}
          onChange={handleChange}
          errorText={error.role}
        /> */}

        <MultiSelectDropdown
          label='Select Module'
          name='tag'
          value={values.tag}
          onChange={handleChange}
          options={state.options}
        />

        {/* <Attachment onFileSelected={onFileSelected} value={values.attachment} /> */}

        <NewInput
          label='Email ID'
          id='email'
          type='text'
          placeholder='Enter email id'
          name='email'
          value={values.email}
          onChange={handleChange}
          errorText={error.email}
        />

        <NewInput
          label='Mobile Number'
          id='mobileNumber'
          type='text'
          placeholder='Enter mobile number'
          name='mobileNumber'
          value={values.mobileNumber}
          onChange={handleChange}
          errorText={error.mobileNumber}
        />

        <Button
          className='mt1'
          onClick={handleCreateUser}
          type='submit'
          name='Submit'
        />
      </div>
      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </div>
  );
};

export default CreateUser;
