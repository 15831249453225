import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

// Components
import NewInput from 'components/NewInput';
import Button from 'components/button';
import useContentForm from 'components/useContentForm';
import validate from 'components/ValidateInfo';
import PageLoader from 'components/PageLoader';
import HeaderWithBackButton from 'components/HeaderWithBackButton';

// API
import { updateActivity } from 'api/activity';

// Constants
import { APP_CONSTANTS } from 'constants/app-constants';

// Utils
import Toast from 'utils/Toast';
import Message from 'utils/Message';

const CreateActivities = () => {
  const history = useHistory();
  const location = useLocation();

  const [pageLoading, setPageLoading] = useState(false);

  const { handleChange, values, setValues } = useContentForm(validate);

  useEffect(() => {
    let data = location.state;
    if (data && data.activity_id) {
      setValues(() => ({
        activityId: data.activity_id,
        activityName: data.activity_name,
        activityType: data.activity_type,
        points: data.alloted_points,
        serviceType: data.service_type,
      }));
    }
  }, []);

  const callUpdateAPI = async () => {
    let { activityId, points, serviceType } = values;

    if (!points) {
      Toast.error(Message.Activities.ERROR);
      return;
    }

    let data = {
      activity_id: activityId,
      alloted_point: points,
      service_type: serviceType,
    };

    setPageLoading(true);
    const responseObj = await updateActivity(data);
    setPageLoading(false);
    if (
      responseObj &&
      responseObj.response &&
      responseObj.response.code === 200
    ) {
      Toast.success(Message.Activities.UPDATE_SUCCESS);
      history.goBack();
    } else {
      Toast.error(Message.Activities.UPDATE_FAILURE);
    }
  };

  const handleCreateActivity = (e) => {
    callUpdateAPI();
  };

  return (
    <div className='blogsForm'>
      <div className='blogsForm_content'>
        <HeaderWithBackButton
          title={
            values.activityName ? values.activityName : 'Update activity points'
          }
          goTo={APP_CONSTANTS.ACTIVITIES}
        />

        <NewInput
          label='Activity Point'
          id='points'
          type='number'
          placeholder='Enter point'
          name='points'
          value={values.points}
          onChange={handleChange}
        />

        <Button
          className='mt1'
          onClick={handleCreateActivity}
          type='submit'
          name='Submit'
        />
      </div>
      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </div>
  );
};

export default CreateActivities;
