import {
  addWidget,
  deleteBanner,
  getWidgetDetailsById,
  getWidgetType,
  updateDynamicWidgetLocalisation,
  updateWidget,
  updateWidgetStatus,
} from 'app/api/widgets';
import TabBar from 'app/components/NewDesign/TabBar';
import NewInput from 'app/components/NewInput';
import PageLoader from 'app/components/PageLoader';
import Button from 'app/components/button';
import { DynamicWidgetBannerHeader,DynamicWidgetTestimonialHeader } from 'app/constants/table-header-constants';
import RadioButton from 'app/shared/components/RadioButton';
import Toast from 'app/utils/Toast';
import useTabBarOptions from 'app/utils/useTabbarOptions';
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import WidgetForm from '../CrreateComponents/WidgetForm';
import CustomTable from './table';
import WidgetTestimonialForm from '../CrreateComponents/WidgetTestimonialForm';
const CreateWidgetContainer = () => {
  let history = useHistory(); 
  const { LanguageOptions } = useTabBarOptions();
  const params = useParams();
  const [loader, setLoader] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');
  const [selectedItemValue, setSelectedItemValue] = useState('');
  const [data, setData] = useState([]);
  const [addNew, setAddNew] = useState(false);
  const [widgetTypes, setWidgetTypes] = useState([]);
  const [cohortOptions, setCohortOptions] = useState([]);
  const [ageOptions, setAgeOptions] = useState([]);
  const [widgetDetails, setWidgetDetails] = useState({});
  const [show, setShow] = useState(false);
  const [isEdit, setIsedit] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [singleBanner, setSingleBanner] = useState(null);
  const [state, setState] = useState({
    widget_name: '',
    widget_type: '',
    widget_style:'',
    slider_style:'AUTO'
  });
  const [activeTab, setActiveTab] = useState({
    id: '1',
    name: 'English',
    short_name: 'en',
    display_order: 1,
    status: 'ACTIVE',
    value: 'English',
  });
  const [disableFields, setDisableFields] = useState(false);

  const handleClose = () => {
    setIsedit(false);
    setShow(false);
  };

  useEffect(() => {
    getDropdown();
  }, [activeTab.short_name, params.id]);

  const getDropdown = async () => {
    // let widgetDetails = location.state;
    setLoader(true);

    const dataArr = await getWidgetType();
    let tempWidgetArray = [];
    let tempAgeArray = [];
    let tempCohortArray = [];
    dataArr.data.widget_types.forEach((item, i) => {
      tempWidgetArray.push({
        id: item.id,
        label: item.name,
        value: item.id,
        widget_value: item.value,
      });
    });
    dataArr.data.age_tags.forEach((item, i) => {
      tempAgeArray.push({
        name: item,
        value: item,
      });
    });
    dataArr.data.cohort_tags.forEach((item, i) => {
      tempCohortArray.push({
        name: item,
        value: item,
      });
    });

    setWidgetTypes(tempWidgetArray);
    setCohortOptions(tempCohortArray);
    setAgeOptions(tempAgeArray);

    if (params?.id) {
      const resp = await getWidgetDetailsById(params?.id, activeTab.short_name);
      let widgetDetails = resp?.[0] ? resp[0] : resp; 
      if (activeTab?.name === 'English') {
        const findWidgetTypeIndex = tempWidgetArray.findIndex(
          (item) => item.label === widgetDetails.widget_type
        );
        if (widgetDetails) {
          setWidgetDetails(widgetDetails);
          if (widgetDetails?.banner_details) {
            let updatedData = [];
            widgetDetails?.banner_details.forEach((item) => {
              updatedData.push({
                isDisabled: true,
                ...item,
              });
            });
            setData(updatedData);
          }

          setState({
            widget_name: widgetDetails?.widget_name,
            widget_type: tempWidgetArray[findWidgetTypeIndex].id,
            widget_style:widgetDetails?.widget_style,
            slider_style:widgetDetails?.slider_style
          });
          setAddNew(true);
        }
        setSelectedItem(tempWidgetArray[findWidgetTypeIndex].id);
        setSelectedItemValue(tempWidgetArray[findWidgetTypeIndex].widget_value);
      } else {
        widgetDetails = resp; 
        setState((prevState) => ({
          ...prevState,
          widget_name: widgetDetails.widget_name
            ? widgetDetails.widget_name
            : null,
        }));
      }
    }
    setLoader(false);
  };

  const radioButtonSelected = async (item) => {
    setSelectedItem(item.value);
    setSelectedItemValue(item.widget_value);
    setAddNew(false);
    setState({ ...state, widget_type: item.value });
  };

  const handleTextChange = (e, index) => {
    if (e.target.name === 'widget_name') {
      if (e.target.value.length <= 34) {
        setState({ ...state, [e.target.name]: e.target.value });
      }
    } else {
      setState({ ...state, [e.target.name]: e.target.value });
    }
  };

  const handleAddBanner = () => {
    // setAddNew(true)
    setShow(true);
    // setIsedit(true)
  };

  const handleSubmit = async (key, singleBannerDetails) => {
    let updatedData = [];

    data.forEach((item, i) => { 
      updatedData.push({
        ...item,
      });
      delete updatedData[i].isDisabled;
    });

    let payload;
    if (key === 'singleWidget') {
      delete singleBannerDetails['secondaryCTA'];
      let copysingleBannerDetails = singleBannerDetails;
      if (copysingleBannerDetails.thumbnail_image_collection) {
        copysingleBannerDetails.thumbnail_image_collection.map((item) => {
          delete item.isNew;
          return item;
        });
      }
      if(selectedItemValue !== 'big_banner_advanced'){
        copysingleBannerDetails.thumbnail_image_collection = []
      }
      
 
      let custom_objectWidgetdata = [];
      custom_objectWidgetdata.push(copysingleBannerDetails);
      payload = {
        widget_name: singleBannerDetails.banner_name,
        widget_type: state.widget_type,
        banner_details: custom_objectWidgetdata,
      };
     
    } else {
      if(selectedItemValue !== 'big_banner_advanced'){
        updatedData[0].thumbnail_image_collection = []
      }

      const { slider_style, ...restState } = state;
      payload = {
        ...restState,
        widget_style:state.widget_style?state.widget_style:null,
        banner_details: updatedData,
      };
      if (selectedItemValue === 'big_banner') {
        payload.slider_style = state.slider_style ? state.slider_style : null;
      }
    } 
    if(!payload.widget_name){
      Toast.error('Widget name not provided.');
      return;
    } 
    payload.banner_details = JSON.stringify(payload.banner_details);
    setLoader(true);
    let responseObj;

    if (params.id) {
      payload.widget_id = params.id;
      if (activeTab?.name === 'English') {
        responseObj = await updateWidget(payload);
      } else {
        let newPayload = {
          widget_name: payload?.widget_name,
        };
        if (payload?.widget_name) {
          responseObj = await updateDynamicWidgetLocalisation(
            newPayload,
            params.id,
            activeTab?.short_name
          );
        } else {
          setLoader(false);
          Toast.error('Widget name not provided.');
          return;
        }
      }
    } else {
      setLoader(false);
      responseObj = await addWidget(payload);
    }

    if (
      responseObj &&
      responseObj.response &&
      responseObj.response.code === 200
    ) {
      Toast.success(responseObj.response.alert[0].message);
      history.push('/dynamic-widgets');
      setShow(false);
    } else {
      setLoader(false);
      Toast.error(
        responseObj.data && responseObj.data.msg
          ? responseObj.data.msg
          : responseObj.response.alert[0].message
      );
    }
  };

  const handleDelete = async (item, ind) => {
    if (activeTab.name === 'English') {
      let text = 'Are you sure, want to delete';
      if (window.confirm(text) === true) {
        if (item.banner_id) {
          setLoader(true);
          await deleteBanner(item.banner_id);
          const index = data.findIndex((x) => x.banner_id === item.banner_id);
          if (index > -1) {
            data.splice(index, 1);
            if (params?.id) {
              history.push({
                pathname: `/dynamic-widgets/edit/${params.id}`,
              });
            } else {
              history.push({
                pathname: `/dynamic-widgets/create`,
              });
            }
            setData(data);
          }
          setLoader(false);
        } else {
          data.splice(ind, 1);
          setData(data);
          if (params?.id) {
            history.push({
              pathname: `/dynamic-widgets/edit/${params.id}`,
            });
          } else {
            history.push({
              pathname: `/dynamic-widgets/create`,
            });
          }
        }
      }
    }
  };

  const handlePublishUnpublish = async (item) => {
    if (item && item.banner_id && activeTab.name === 'English') {
      setLoader(true);
      let payload = {
        banner_status: item.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
        banner_id: item.banner_id,
      };
      await updateWidgetStatus(payload);
      const index = data.findIndex((x) => x.banner_id === item.banner_id);

      if (index > -1) {
        // only splice array when item is found
        data[index].status = item.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
        // data.splice(index, 1); // 2nd parameter means remove one item only

        if (params?.id) {
          history.push({
            pathname: `/dynamic-widgets/edit/${params.id}`,
          });
        } else {
          history.push({
            pathname: `/dynamic-widgets/create`,
          });
        }
        setData(data);
      }
      setLoader(false);
    }
  };

  const handleAddBannerDetails = (bannerDetail) => {
    if (
      !bannerDetail.banner_name ||
      bannerDetail.cohort_tags.length === 0 ||
      bannerDetail.age_tags.length === 0
    ) {
      Toast.error('Enter all the details');
      return;
    }
    // if(selectedItemValue=='testimonial_collection' && bannerDetail.testimonial_type == 'VIDEO_AND_IMAGE' && (!bannerDetail.video_url || !bannerDetail.bottom_image || !bannerDetail.testimonial_type) ){
    //   Toast.error('Please Add video and bottom');
    //   return;
    // }
    let temp = data;
    if (currentIndex > -1) {
      temp[currentIndex] = bannerDetail;
    } else {
      temp.push(bannerDetail);
    }

    setData(temp);
    setShow(false);
  };

  const handleNavigation = (banner, index) => {
    // if (activeTab?.name === 'English') {
      setCurrentIndex(index);
      setSingleBanner(banner);
      setShow(true);
      setIsedit(true);
    // }
  };

  const handleSingleWidget = (bannerDetails) => {
    handleSubmit('singleWidget', bannerDetails);
  };

  const handleInputChangeHandler = (key, e, index) => {
    let tempData = [];
    data.forEach((val, i) => {
      if (index === i) {
        let temp = val;
        val[key] = Number(e.target.value) ? Number(e.target.value) : '';
        tempData.push(temp);
      } else {
        tempData.push(val);
      }
    });
    setData(tempData);
  };
  const handleEdit = async (item, key, index) => {
    if (activeTab.name === 'English') {
      if (key === 'Edit') {
        let tempData = [];
        data.forEach((val, i) => {
          if (index === i) {
            let temp = val;
            val['isDisabled'] = false;
            tempData.push(temp);
          } else {
            tempData.push(val);
          }
        });
        setData(tempData);
      } else {
        let tempData = [];
        data.forEach((val, i) => {
          if (index === i) {
            let temp = val;
            val['isDisabled'] = true;
            tempData.push(temp);
          } else {
            tempData.push(val);
          }
        });
        setData(tempData);
      }
    }
  };
  const handleTabChange = (val) => {
    setActiveTab(val);
    if (val?.name === 'English') {
      setDisableFields(false);
    } else {
      setDisableFields(true);
    }
  };

  //   const getLocalisationDetails = async () => {

  //   }
  const formatType = [
    {
        label: 'Type 1: Scroll',
        value: 'SCROLL',
    },
    {
        label: 'Type 2: Tiles',
        value: 'TILES',
    },  
];
const slidingBehavior = [
  {
      label: 'Auto-Slider',
      value: 'AUTO',
  },
  {
      label: 'Manual-Slider',
      value: 'MANUAL',
  },  
];
  return (
    <div className='container-fluid'>
      <h1 className='section-header__title'>
      Landing pages \ Home Page Widgets \ Create Widget
      </h1>
      <div className='content-container'>
        <div className='content-selector'>
          <label className='head-label'>Select Widget Type</label>
          {widgetTypes.map((item, key) => (
            <RadioButton
              disabled={params.id}
              id={item.id}
              label={item.label}
              isChecked={selectedItemValue === item.widget_value ? true : false}
              value={item.value}
              onSelect={() => {
                radioButtonSelected(item);
              }}
              name={item.label}
              isDisabled={params.id}
            />
          ))}
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size='xl'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Body>
        {
            selectedItemValue === "testimonial_collection" ? (
              <WidgetTestimonialForm
              handleSubmitForm={handleAddBannerDetails}
              cohortOptions={cohortOptions}
              ageOptions={ageOptions}
              widgetDetails={widgetDetails}
              handleBack={handleClose}
              selectedItem={selectedItemValue}
              bannerId={singleBanner?.banner_id}
              isModal={show}
              isEdit={isEdit}
              key={isEdit}
              singleBanner={singleBanner}
              />
            ):
            (

          <WidgetForm
            handleSubmitForm={handleAddBannerDetails}
            cohortOptions={cohortOptions}
            ageOptions={ageOptions}
            widgetDetails={widgetDetails}
            handleBack={handleClose}
            selectedItem={selectedItemValue}
            bannerId={singleBanner?.banner_id}
            isModal={show}
            isEdit={isEdit}
            key={isEdit}
            singleBanner={singleBanner}
          />)}
        </Modal.Body>
      </Modal>
      {(selectedItemValue === 'single_banner' ||
        selectedItemValue === 'big_banner_advanced' || selectedItemValue === 'very_large_banner' || selectedItemValue === 'sticky_payment_widget') && (
        <>
          <div className='separator'>
            <p>Add Banner Details</p>
          </div> 
          <WidgetForm
            handleSubmitForm={handleSingleWidget}
            cohortOptions={cohortOptions}
            ageOptions={ageOptions}
            widgetDetails={widgetDetails}
            handleBack={() => history.goBack()}
            selectedItem={selectedItemValue}
            bannerId={
              widgetDetails.banner_details &&
              widgetDetails.banner_details[0].banner_id
            }
            isEdit={params.id? true : false}
          />
        </>
      )
    
    }

      {selectedItemValue &&
        selectedItemValue !== 'single_banner' &&
        selectedItemValue !== 'big_banner_advanced' &&  
        selectedItemValue !== 'very_large_banner' &&
        selectedItemValue !== 'sticky_payment_widget'&&(
          <>
            <div className='separator'>
              <p>Add Banner Details</p>
            </div>
            {params.id ? (
              <TabBar
                tabs={LanguageOptions}
                initialTab={activeTab}
                onTabChange={handleTabChange}
                parentStyle={'service-tab-style'}
              />
            ) : null}
            <div className='row'>
              <div className='col-md-6'>
                <div className='userInput'>
                  <NewInput
                    label='Widget Name'
                    name={'widget_name'}
                    value={state.widget_name ? state.widget_name : ''}
                    onChange={handleTextChange}
                    max={34}
                  />
                </div>
              </div>
              {
                selectedItemValue == 'small_services_banner' && (
                  <div className='col-md-6'>
                    <div className=' my-5'>
                        <label>Format Style </label>
                        <select
                            name='landing_type'
                            className='custom-select mx-3 w-50'
                            onChange={(e) => {
                                setState({ ...state, widget_style: e.target.value });
                            }}
                        disabled={disableFields}
                        >
                            <option value=''>Select</option>
                            {formatType?.map((item, indx) => (
                                <option
                                    key={indx}
                                    value={item.value}
                                selected={state.widget_style == item.value ? true : false}
                                >
                                    {item.label}
                                </option>
                            ))}
                        </select>
                    </div>
                  </div>
                )
              }
               {
                selectedItemValue == 'big_banner' && (
                  <div className='col-md-6'>
                    <div className=' my-5'>
                        <label>Sliding Behavior </label>
                        <select
                            name='landing_type'
                            className='custom-select mx-3 w-50'
                            onChange={(e) => {
                                setState({ ...state, slider_style: e.target.value });
                            }}
                        disabled={disableFields}
                        >
                            <option value=''>Select</option>
                            {slidingBehavior?.map((item, indx) => (
                                <option
                                    key={indx}
                                    value={item.value}
                                selected={state.slider_style == item.value }
                                >
                                    {item.label}
                                </option>
                            ))}
                        </select>
                    </div>
                  </div>
                )
              }
            </div>
           

            <div className='userInput mt-5'>
              <div className='content-container__table'>
                <CustomTable
                  data={data ? data : []}
                  tableHeader={DynamicWidgetBannerHeader}
                  tableHeaderTestimonial={DynamicWidgetTestimonialHeader}
                  handleDelete={handleDelete}
                  handlePublishUnpublish={handlePublishUnpublish}
                  handleNavigation={handleNavigation}
                  handleInputChangeHandler={handleInputChangeHandler}
                  handleEdit={handleEdit}
                  selectedItemValue={selectedItemValue}
                />
              </div>

              {selectedItemValue === 'auto_play_video' ? (
                <div className='userFormArea text-center'>
                  {data.length === 0 && (
                    <Button
                      name={'Add a banner'}
                      onClick={handleAddBanner}
                      isDisabled={disableFields}
                    />
                  )}
                </div>
              ) : (
                <div className='userFormArea text-center'>
                  <Button
                    name={`${selectedItemValue==='testimonial_collection'?'Add a testimonial':'Add a banner'}`}
                    onClick={handleAddBanner}
                    isDisabled={disableFields}
                  />
                </div>
              )}
            </div>
            <div className='row'>
              <div className='userFormArea'>
                <Button
                  name={params.id ? 'Update' : 'Save'}
                  onClick={() => {
                    handleSubmit();
                  }}
                />
                <label
                  style={{ paddingLeft: '10px', cursor: 'pointer' }}
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Cancel
                </label>
              </div>
            </div>
          </>
        )}

      {loader && <PageLoader pageLoading={loader} />}
    </div>
  );
};

export default CreateWidgetContainer;
