import React from 'react';

// Pages
import BasePage from 'pages/BasePage';  
import CreateExercisesContainer from '../../../container/Exercises/CreateExercises'
const CreateExercises = () => {
  return (
    <BasePage>
      <CreateExercisesContainer /> 
    </BasePage>
  );
};

export default CreateExercises;
