import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import Loader from 'components/Loader';
// import NewTable from 'components/NewDraggableTable';
import Button from 'app/components/button';

import {
  updateHomepageOrderingHome,
  updateHomepageOrderingExplore,
  deleteHomePageOrdering,
} from 'api/homepageorder';
// import { getHomePage } from 'api/homepageorder';
import RadioButton from 'app/shared/components/RadioButton';
import HomePageSection from './HomePageSection';
import ExploreSection from './ExploreSection';

const HomePageOrderingContainer = ({ tableHeader }) => {
  const history = useHistory();
  // const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [selectedItem, setSelectedItem] = useState('HOME');
  const radioMenu = [
    {
      label: 'Explore Section',
      id: '1',
      value: 'EXPLORE',
    },
    {
      label: 'Home Page',
      id: '2',
      value: 'HOME',
    },
  ];

  const HomePageOrder = (tempData) => {
    const HomepageData = tempData.map((data, index) => {
      return {
        order_id: data.original.id,
        sorting_order: index + 1,
      };
    });
    if (selectedItem === 'HOME') {
      updateHomepageOrderingHome(HomepageData);
    } else {
      updateHomepageOrderingExplore(HomepageData);
    }
  };

  const radioButtonSelected = (item) => {
    setSelectedItem(item.value);
  };

  const callDeleteAPI = async (data) => {
    setLoading(true);
    if (data && data.id) {
      await deleteHomePageOrdering(data.id);
      setLoading(false);
    }
  };

  if (loading === true) {
    return <Loader />;
  }
  return (
    <div className='blogs'>
      <h4>Landing pages \ Home Page Ordering</h4>
      <div className='content-container__actionBox mb-5'>
        <div className='content-container__filterSearch'>
          <div className='content-selector'>
            {radioMenu.map((item, key) => (
              <RadioButton
                id={item.id}
                label={item.label}
                isChecked={selectedItem == item.value ? true : false}
                value={item.value}
                onSelect={() => {
                  radioButtonSelected(item);
                }}
                name={item.label}
              />
            ))}
          </div>
        </div>
        <Button
          onClick={() =>
            history.push(`/homepage-ordering/create/?key=${selectedItem}`)
          }
          className='content-container__filter-btn'
        >
          Add Section/Widget
        </Button>
      </div>
      {selectedItem === 'HOME' && (
        <HomePageSection
          tableHeader={tableHeader}
          data={data}
          HomePageOrder={HomePageOrder}
          hasPagination={false}
          callDeleteAPI={callDeleteAPI}
        />
      )}
      {selectedItem === 'EXPLORE' && (
        <ExploreSection
          tableHeader={tableHeader}
          data={data}
          HomePageOrder={HomePageOrder}
          hasPagination={false}
          callDeleteAPI={callDeleteAPI}
        />
      )}
    </div>
  );
};
export default HomePageOrderingContainer;
