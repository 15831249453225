export const moduleOptions = [
  {
    id: 1,
    name: 'Product Catalog',
  },
];

export const productCatalogOptions = [
  {
    id: 1,
    name: 'FAMILY_PLANS',
  },
  {
    id: 2,
    name: 'COUPONS_AND_OFFERS',
  },
  {
    id: 3,
    name: 'RECOMMENDATIONS',
  },
];

export const variantOptions = [
  {
    id: 1,
    value: 2,
  },
  {
    id: 2,
    value: 3,
  },
];

export const usersOptions = [
  {
    id: 1,
    name: 'Random',
  },
  {
    id: 2,
    name: 'Selective',
  },
];

export const filteredOptionsData = [
  {
    id: 1,
    value: 'app_version',
    label: 'App Version',
  },
  {
    id: 2,
    value: 'cohort_name',
    label: 'Cohort Name',
  },
  {
    id: 3,
    value: 'final_source',
    label: 'Final Source',
  },
  {
    id: 4,
    value: 'age_range',
    label: 'Age Range',
  },
  {
    id: 5,
    value: 'gender',
    label: 'Gender',
  },
  {
    id: 6,
    value: 'location',
    label: 'Location',
  },
];

export const cohortOptions = [
  {
    id: 1,
    label: 'Asthma',
    value: 'Asthma',
  },
  {
    id: 2,
    label: 'COPD',
    value: 'COPD',
  },
  {
    id: 3,
    label: 'ILD',
    value: 'ILD',
  },
  {
    id: 3,
    label: 'Allergic Rhinitis',
    value: 'Allergic Rhinitis',
  },
  {
    id: 3,
    label: 'Others',
    value: 'Others',
  },
];

export const finalSourceOptions = [
  {
    id: 1,
    label: 'Educator',
    value: 'Educator',
  },
  {
    id: 2,
    label: 'Non-educator',
    value: 'Non-educator',
  },
];

export const ageRangeOptions = [
  {
    id: 1,
    label: '0 - 8',
    value: '0 - 8',
  },
  {
    id: 2,
    label: '9 - 17',
    value: '9 - 17',
  },
  {
    id: 3,
    label: '18 - 24',
    value: '18 - 24',
  },
  {
    id: 4,
    label: '25 - 60',
    value: '25 - 60',
  },
  {
    id: 5,
    label: '61 & more',
    value: '61 & more',
  },
];

export const genderOptions = [
  {
    id: 1,
    label: 'Male',
    value: 'Male',
  },
  {
    id: 2,
    label: 'Female',
    value: 'Female',
  },
  {
    id: 3,
    label: 'Others',
    value: 'Others',
  },
];

export const locationOptions = [
  {
    id: 1,
    label: 'Chhattisgarh',
    value: 'Chhattisgarh',
  },
  {
    id: 2,
    label: 'Madhya Pradesh',
    value: 'Madhya Pradesh',
  },
  {
    id: 3,
    label: 'Uttar Pradesh',
    value: 'Uttar Pradesh',
  },
  {
    id: 4,
    label: 'Uttarakhand',
    value: 'Uttarakhand',
  },
  {
    id: 5,
    label: 'Bihar',
    value: 'Bihar',
  },
  {
    id: 6,
    label: 'Jharkhand',
    value: 'Jharkhand',
  },
  {
    id: 7,
    label: 'Odisha',
    value: 'Odisha',
  },
  {
    id: 8,
    label: 'West Bengal',
    value: 'West Bengal',
  },
  {
    id: 9,
    label: 'Haryana',
    value: 'Haryana',
  },
  {
    id: 10,
    label: 'Himachal Pradesh',
    value: 'Himachal Pradesh',
  },
  {
    id: 11,
    label: 'Punjab',
    value: 'Punjab',
  },
  {
    id: 12,
    label: 'Rajasthan',
    value: 'Rajasthan',
  },
  {
    id: 13,
    label: 'Chandigarh',
    value: 'Chandigarh',
  },
  {
    id: 14,
    label: 'Delhi',
    value: 'Delhi',
  },
  {
    id: 15,
    label: 'Jammu and Kashmir',
    value: 'Jammu and Kashmir',
  },
  {
    id: 16,
    label: 'Ladakh',
    value: 'Ladakh',
  },
  {
    id: 17,
    label: 'Andhra Pradesh',
    value: 'Andhra Pradesh',
  },
  {
    id: 18,
    label: 'Karnataka',
    value: 'Karnataka',
  },
  {
    id: 19,
    label: 'Kerala',
    value: 'Kerala',
  },
  {
    id: 20,
    label: 'Tamil Nadu',
    value: 'Tamil Nadu',
  },
  {
    id: 21,
    label: 'Telangana',
    value: 'Telangana',
  },
  {
    id: 22,
    label: 'Andaman and Nicobar Islands',
    value: 'Andaman and Nicobar Islands',
  },
  {
    id: 23,
    label: 'Lakshadweep',
    value: 'Lakshadweep',
  },
  {
    id: 24,
    label: 'Puducherry',
    value: 'Puducherry',
  },
  {
    id: 25,
    label: 'Goa',
    value: 'Goa',
  },
  {
    id: 26,
    label: 'Gujarat',
    value: 'Gujarat',
  },
  {
    id: 27,
    label: 'Maharashtra',
    value: 'Maharashtra',
  },
  {
    id: 28,
    label: 'Dadra and Nagar Haveli and Daman and Diu',
    value: 'Dadra and Nagar Haveli and Daman and Diu',
  },
  {
    id: 29,
    label: 'Arunachal Pradesh',
    value: 'Arunachal Pradesh',
  },
  {
    id: 30,
    label: 'Assam',
    value: 'Assam',
  },
  {
    id: 31,
    label: 'Manipur',
    value: 'Manipur',
  },
  {
    id: 32,
    label: 'Meghalaya',
    value: 'Meghalaya',
  },
  {
    id: 33,
    label: 'Mizoram',
    value: 'Mizoram',
  },
  {
    id: 34,
    label: 'Nagaland',
    value: 'Nagaland',
  },
  {
    id: 35,
    label: 'Sikkim',
    value: 'Sikkim',
  },
  {
    id: 36,
    label: 'Tripura',
    value: 'Tripura',
  },
];
