// // Pages
import BasePage from 'pages/BasePage';

import { SplashScreenHeader } from 'constants/table-header-constants';
import OnBoarding from 'container/OnBoarding';

const OnBoardingScreen = () => {
  return (
    <BasePage>
      <OnBoarding tableHeader={SplashScreenHeader} />
    </BasePage>
  );
};

export default OnBoardingScreen;
