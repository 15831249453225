import React, {useState, useEffect } from 'react'
import CustomTable from '../MedicineReminderTable/table'
import {MedicineReminder} from 'app/constants/table-header-constants'
import {getAdReminder,updateAdReminder} from 'app/api/latest/Categories/categoriesAPI'
import Toast from 'app/utils/Toast';
import Message from 'app/utils/Message';

const MedicineReminderContainer = () => {
  const [pageLoading, setPageLoading] = useState(false);
    const [data,setData]=useState([]);
    const[toggle,setToggle]=useState(null);
    const [objData,setObjData]=useState([])
    useEffect(()=>{
        getAdReminderData()
    },[])

    const getAdReminderData = async()=>{
        setPageLoading(true);
        let response = await getAdReminder();
        setPageLoading(false);
        if (response?.response?.code === 200){
            let newData=[{
                id: 1,
                CommType: "Push Notification",
                toggle: response?.data?.remaining_medicine_check.push_notification,
                var_name:"push_notification"
            },
            {
                id: 2,
                CommType: "PN pop up",
                toggle: response?.data?.remaining_medicine_check.push_notification_popup,
                var_name:"push_notification_popup"
    
            },
            {
                id: 3,
                CommType: "Task pop up",
                toggle: response?.data?.remaining_medicine_check.task_popup,
                var_name:"task_popup"
    
            },
            {
                id: 4,
                CommType: "Reminder page recommendation widget",
                toggle: response?.data?.remaining_medicine_check.recommendation_widget,
                var_name:"recommendation_widget"
    
            }]
            // newData=response?.data?.remaining_medicine_check;
            setObjData(newData)
        }else {
            Toast.error(
                response.data.error ? response.data.error : Message.Error.COMMON_MESSAGE
            );
        }
    }
    
    const onchange= async(e,type,name)=>{
        const newData=objData.map((item)=>{
            if(item.CommType === type){
                return {...item,toggle:e.target.checked}
            }
            return item
        })
        setObjData(newData);
        let formData = new FormData();
        formData.append("reminder_id",1);
        formData.append("push_notification_check", newData[0].toggle);
        formData.append("push_notification_popup_check",newData[1].toggle);
        formData.append("task_popup_check", newData[2].toggle);
        formData.append("recommendation_widget_check", newData[3].toggle);
        setPageLoading(true);
        const response = await updateAdReminder(formData);
        setPageLoading(false);
        if (response?.response?.code === 201) {
            Toast.success(response?.data?.message);
            getAdReminderData();
        } else {
            Toast.error(
                response?.data?.error
                    ? response?.data?.error
                    : Message.Error.COMMON_MESSAGE
            );
        }
        // console.log(e.target.checked,name)
        // let formData = new FormData();
        // if("push_notification" == name){
        //     formData.append("push_notification",e.target.checked);
        // }
        // formData.append(,e.target.checked);
        // let response = await updateAdReminder();
        // console.log(response)
    }

    
    return (
        <div>
            <CustomTable 
            data={objData}
            tableHeader={MedicineReminder}
            onchange={onchange}
            />
        </div>
    )
}

export default MedicineReminderContainer