import { useState } from 'react';
import { URLType } from 'constants/app-constants';

const useWebinarForm = (validate) => {
  const [count, setCount] = useState(1);
  const [values, setValues] = useState(
    {
      attachmentFile: null,
      title: '',
      attachment: '',
      description: '',
      webinarLink: '',
      videoThumbnail: '',
      StartDate: '',
      webinar_record_link: '',
      webinarRecording: null,
      url: null,
      externalUrl: '',
      urlType: URLType.NO,
      EndDate: '',
      tags: [],
      webinarId: '',
      webinarUpdate: '',
      id: 1,
      meeting_id: '',
      passcode: '',
    }
    // removedBanners: [],
  );

  const addBannerSection = () => {
    const webinars = [...values.webinars];
    if (webinars.length < 50) {
      webinars.push({
        // id: Date.now(), // Basically its a index
        webinarId: '',
        urlType: '',
        // isNew: true,
        title: '',
        webinar_record_link: '',
        webinarRecording: null,
        id: count + 1,
        url: null,
        externalUrl: '',
        attachmentFile: null,
      });
      setCount(count + 1);
      setValues({
        ...values,
        webinars,
      });
    }
  };

  const removeBannerSection = (ind) => {
    const webinars = [];
    let removedBanners = [...values.removedBanners];
    values.webinars.forEach((webinar, index) => {
      if (ind !== index) {
        webinars.push(webinar);
      }
      if (ind === index && webinar.webinarId) {
        removedBanners.push(parseInt(webinar.webinarId));
      }
    });

    setCount(webinars.length);
    setValues({
      ...values,
      webinars,
      removedBanners,
    });
  };

  const updateValue = (obj) => {
    setValues({
      ...obj,
    });

    setCount(obj.webinars);
  };

  const [error, setErrors] = useState({});

  const handleChange = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  // const OnhandleChange = (e) => {
  //   const valueCpy = { ...values };
  //   // debugger;
  //   valueCpy.webinars.map((element) => {
  //     if (`${element.webinarId}` === `${e.target.webinarId}`) {
  //       element[e.target.name] = e.target.value;
  //     }
  //     return element;
  //   });
  //   setValues(valueCpy);
  // };

  const handleSubmitting = (e) => {
    // e.preventDefault();
    setErrors(validate(values));
  };

  return {
    handleChange,
    values,
    handleSubmitting,
    error,
    setValues,
    updateValue,
    // OnhandleChange,
    addBannerSection,
    removeBannerSection,
    count,
  };
};

export default useWebinarForm;
