import React from 'react';

// Components
import Search from 'components/search';
import Button from 'components/button';
import Select from 'app/components/dropdown';
import './index.scss';

const SectionHeader = ({ sectionObj, searchVal, handleSearch, isHide }) => {
  if (!sectionObj) return null;

  const { sectionTitle, searchObj, buttons, dropObj } = sectionObj;

  const onSearch = (val) => {
    // console.log('Search: ', e);
    // if (!searchObj) return;
    // if (!searchObj.handleSearch) return;
    // const { handleSearch } = searchObj;
    handleSearch && handleSearch(val);
  };

  return (
    <section className='section-header'>
      <h1 className='section-header__title'>{sectionTitle}</h1>
      <div className='section-header__right'>
        {searchObj && (
          <Search
            placeholder={searchObj.placeholder}
            onChange={onSearch}
            value={searchVal}
          />
        )}

        {dropObj && (
          <Select
            options={dropObj.options}
            onChange={dropObj.onChange}
            placeholder={dropObj.placeholder}
            value={dropObj.value}
          />
        )}

        {buttons &&
          buttons.map((btnObj, index) => {
            return (
              <Button
                key={`${index}_${btnObj.title}`}
                onClick={(e) => {
                  btnObj.handleBtnClick && btnObj.handleBtnClick(e);
                }}
              >
                {btnObj.title}
                {btnObj.children && btnObj.children()}
              </Button>
            );
          })}
      </div>
    </section>
  );
};

export default SectionHeader;
