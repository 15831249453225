import React from 'react';

// Pages
import BasePage from 'pages/BasePage';
import WebinarList from 'app/container/WebinarList';

import { WebinarHeader } from 'constants/table-header-constants';

const Webinar = () => {
  return (
    <BasePage>
      <WebinarList tableHeader={WebinarHeader} />
    </BasePage>
  );
};

export default Webinar;
