import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import Loader from 'components/Loader';
import ArticleComponent from 'components/ArticleComponent';
import NewTable from 'components/NewTable';
import PageLoader from 'components/PageLoader';
import ArticleModal from 'components/ArticleModal';
import useContentForm from 'components/useContentForm';
import validate from 'components/ValidateInfo';

// API
import {
  getFeed,
  publishOrUnpublishFeed,
  deleteFeed,
  getActiveUsers,
} from 'api/feed';

// Constants
import { APP_CONSTANTS } from 'constants/app-constants';

// Utils
import Toast from 'utils/Toast';
import Message from 'utils/Message';

import './index.scss';

const Feeds = ({ tableHeader }) => {
  const history = useHistory();
  const [data, setData] = useState({});
  const [value, setValue] = useState('');
  const [select, setSelect] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [feedSummary, setFeedSummary] = useState({
    totalArticle: 0,
    inActiveArticle: 0,
    activeArticle: 0,
  });

  useEffect(() => {
    callActiveUser();
  }, []);

  const onChange = (e) => {
    setValue(e.value);
    console.log('hi', e.value);
    const data = {
      filters: {
        user_name: e.value,
      },
    };
    loadNames(0, data);
    setFilterData({
      filters: {
        user_name: e.value,
      },
    });
  };

  const [showFilter, setShowFilter] = useState(false);
  const [filterData, setFilterData] = useState({});

  const { handleChange, values, error, resetValues } = useContentForm(validate);

  const loadNames = (start, filterPayload) => {
    setLoading(true);
    getFeed(start, filterPayload).then((response) => {
      if (response && response.tableData && response.error === false) {
        console.log(response.tableData, 'responsedata');
        setData({
          tableData: response.tableData,
        });
        setFeedSummary(response.summary);
      } else {
        if (response.message) {
          Toast.error(response.message);
        } else {
          Toast.error(Message.Error.COMMON_MESSAGE);
        }
      }
      setLoading(false);
    });
  };

  const handleResponseCallback = (response) => {
    if (response && response.tableData && response.error === false) {
      setFeedSummary(response.summary);
    }
  };

  const filterApplyClicked = async (data) => {
    setShowFilter(false);
    setFilterData(data);
    loadNames(0, data);
  };

  const ResetFilterCicked = async (data) => {
    setShowFilter(false);
    setFilterData(data);
    resetValues();
    loadNames(0);
  };

  const callActiveUser = async (ActiveUser) => {
    // setPageLoading(true);
    let responseObj = await getActiveUsers(ActiveUser);
    setSelect(responseObj);
  };

  const callDeleteAPI = async (data) => {
    setPageLoading(true);
    if (data && data.article_id) {
      let responseObj = await deleteFeed(data.article_id);
      setPageLoading(false);
      if (
        responseObj &&
        responseObj.response &&
        responseObj.response.code === 200
      ) {
        loadNames();
        Toast.success(Message.Feed.DELETE_SUCCESS);
      }
    } else {
      Toast.error(Message.Feed.DELETE_FAILURE);
    }
  };

  const callPublishOrUnpublishAPI = async (data) => {
    // console.log(data, 'articledata');
    setPageLoading(true);
    if (data && data.article_id && data.status) {
      let responseObj = await publishOrUnpublishFeed(
        data.article_id,
        data.status
      );
      setPageLoading(false);
      if (
        responseObj &&
        responseObj.response &&
        responseObj.response.code === 200
      ) {
        loadNames();
        let message = Message.Feed.PUBLISH_UNPUBLISH_SUCCESS.replace(
          '{{placeholder}}',
          data.status.toLowerCase() === 'active' ? 'Unpublished' : 'Published'
        );
        Toast.success(message);
      }
    } else {
      let message = Message.Feed.PUBLISH_UNPUBLISH_FAILURE.replace(
        '{{placeholder}}',
        data.status.toLowerCase() === 'active' ? 'Unpublish' : 'Publish'
      );
      Toast.error(message);
    }
  };

  // const menuAction = (type, data) => {
  //   console.log(`Selected ${type} ${data.article_id}`);
  //   switch (type) {
  //     case EventType.DELETE:
  //       callDeleteAPI(data);
  //       break;
  //     case EventType.PUBLISH_UNPUBLISH:
  //       callPublishOrUnpublishAPI(data);
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const menuActionObj = [
    {
      name: 'Edit',
      onClick: (e, data) => {
        console.log('Edit Clicked: ', data);
        history.push({
          pathname: `/feed/edit/${data.article_id}`,
          state: {
            ...data,
          },
        });
      },
    },
    {
      name: 'Delete',
      onClick: (e, data) => {
        console.log('Delete Clicked: ', data);
        let text = 'are you sure,want to delete';
        if (window.confirm(text) == true) {
          callDeleteAPI(data);
          // text = 'You pressed OK!';
        } else {
          // text = 'You canceled!';
        }
      },
    },
    {
      name: 'Publish',
      type: 'publish',
      onClick: (e, data) => {
        console.log('Publish/Unpublish Clicked: ', data);
        callPublishOrUnpublishAPI(data);
      },
    },
  ];

  if (loading === true) {
    return <Loader />;
  }

  const sectionObj = {
    sectionTitle: 'Feed List',
    // searchObj: {
    //   placeholder: 'Search',
    //   handleSearch: (e) => {
    //     console.log('Handle search: ', e);
    //   },
    // },
    dropObj: {
      placeholder: 'Active users',
      options: select,
      onChange: onChange,
      value: value,
    },
    buttons: [
      {
        title: 'Filter',
        children: () => {
          return <i className='fa fa-filter mlp5' />;
        },
        handleBtnClick: (e) => {
          // debugger;
          setShowFilter(true);
        },
      },
      {
        title: 'Create a feed',
        handleBtnClick: (e) => {
          history.push(APP_CONSTANTS.CREATE_FEED);
        },
      },
    ],
  };

  const handleModalCloseClicked = () => {
    setShowFilter(false);
  };

  // if (data && data.tableData) {
  return (
    <div className='blogs'>
      <div className='blogs_container'>
        <ArticleComponent number={feedSummary.totalArticle} text='Total feed' />
        <ArticleComponent
          number={feedSummary.activeArticle}
          text='Active feed'
        />
        <ArticleComponent
          number={feedSummary.inActiveArticle}
          text='Inactive feed'
        />
      </div>

      <NewTable
        tableHeader={tableHeader}
        data={data}
        menuAction={menuActionObj}
        sectionObj={sectionObj}
        getData={getFeed}
        isHide={true}
        searchKey='title'
        filterData={filterData}
        responseCallback={handleResponseCallback}
        // pageCount={pageCount}
      />
      {pageLoading === true && <PageLoader pageLoading={pageLoading} />}
      {showFilter && (
        <ArticleModal
          modalCloseClicked={handleModalCloseClicked}
          filterApplyClicked={filterApplyClicked}
          ResetFilterCicked={ResetFilterCicked}
          handleChange={handleChange}
          values={values}
          error={error}
        />
      )}
    </div>
  );
  // }

  // return null;
};

export default Feeds;
