import { API_CONSTANTS } from 'app/constants/api-contants';
import * as dataAccess from 'utils/ajax';
import Parsers from 'utils/Parsers';
import Toast from 'app/utils/Toast';

export const getExercisesList = async () => {
     let responseObj = await dataAccess.get(API_CONSTANTS.GET_EXERCISES_LIST);
     if (
          responseObj &&
          responseObj.response &&
          responseObj.response.code === 200
     ) {
          return responseObj.data;
     } else {
          return [];
     }
};

export const updateExperiencePremium = async (incomingData, id) => {
     let formData = Parsers.ConvertObjectAsFormData(incomingData);
     let payload = {
       data: formData,
     };
     let response = dataAccess.update(
       `${API_CONSTANTS.GET_EXERCISES_LIST}/${id}`,
       payload
     );
     return response;
   };

export const createExercises = async (incomingData) => {
    let formData = Parsers.ConvertObjectAsFormData(incomingData)
    let payload = {
        data: formData
    }
    return await dataAccess.upload(API_CONSTANTS.POST_EXERCISES, payload)
}

export const getExercisesDetails = async (id) => {
    let responseObj = await dataAccess.get(`${API_CONSTANTS.GET_EXERCISES_LIST}/${id}`);
    if (
         responseObj &&
         responseObj.response &&
         responseObj.response.code === 200
    ) {
         return responseObj.data;
    } else {
         return [];
    }
};

export const updateExercises = async (incomingData) => {
    let formData = Parsers.ConvertObjectAsFormData(incomingData)
    let payload = {
        data: formData
    }
    let response = dataAccess.update(API_CONSTANTS.POST_EXERCISES, payload)
    return response
}

export async function deleteExercisesById(id) {
    let responseObj = await dataAccess.del(`${API_CONSTANTS.GET_EXERCISES_LIST}/${id}`);
    if (
         responseObj &&
         responseObj.response &&
         responseObj.response.code === 200
    ) {
         Toast.success(responseObj.response.alert[0].message)
    }
}
export const getAdvertisementList = async (data) => {
     // let responseObj = await dataAccess.get(API_CONSTANTS.GET_ADVERTISEMENT_LIST);

     let url = `${API_CONSTANTS.GET_ADVERTISEMENT_LIST}?advertisement_type=${data}`;
     let responseObj = await dataAccess.get(url);
     if (
          responseObj &&
          responseObj.response &&
          responseObj.response.code === 200
     ) {
          return responseObj.data;
     } else {
          return [];
     }
};

export const getAdvertisementDetails = async (id) => {
     let responseObj = await dataAccess.get(`${API_CONSTANTS.GET_ADVERTISEMENT_DETAILS_BY_ID}/${id}`);
     if (
          responseObj &&
          responseObj.response &&
          responseObj.response.code === 200
     ) {
          return responseObj.data;
     } else {
          return [];
     }
 };

 export const createAdvertisement = async (incomingData) => { 
     let payload = {
         data: incomingData
     }
     return await dataAccess.post(API_CONSTANTS.CREATE_ADVERTISEMENT, payload)
 }

 export const updateAdvertisement = async (incomingData) => { 
     let payload = {
         data: incomingData
     }
     let response = dataAccess.put(API_CONSTANTS.UPDATE_ADVERTISEMENT, payload)
     return response
 }

export const updateAdvertisementStatus = async (incomingData) => {
     // let formData = Parsers.ConvertObjectAsFormData(incomingData)
     let payload = {
         data: incomingData
     }
     let response = dataAccess.put(API_CONSTANTS.UPDATE_STATUS_ADVERTISEMENT, payload)
     return response
 }

 export async function deleteAdvertisementById(id) {
     let responseObj = await dataAccess.del(`${API_CONSTANTS.DELETE_ADVERTISEMENT}/${id}`);
     if (
          responseObj &&
          responseObj.response &&
          responseObj.response.code === 200
     ) {
          Toast.success(responseObj.response.alert[0].message)
     }
 }
 
 