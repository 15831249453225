/* eslint-disable no-useless-escape */
import Toast from 'utils/Toast';

export default function validateInfo(values) {
  let validationStatus = false;
  //   let errors = {};

  console.log('validateInfo values', values);

  if (!values.product_family) {
    Toast.error('Product Family is Required');
    validationStatus = true;
    return validationStatus;
  }

  if (!values.discount) {
    Toast.error('Discount is Required');
    validationStatus = true;
    return validationStatus;
  }
  if (!values.orders_above) {
    Toast.error('Order Above is Required');
    validationStatus = true;
    return validationStatus;
  }
  if (!values.maximum_discount_amount) {
    Toast.error('Maxium Discount is Required');
    validationStatus = true;
    return validationStatus;
  }

  return validationStatus;
}
