import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Components
import HeaderWithBackButton from 'components/HeaderWithBackButton';
import NewInput from 'components/NewInput';
import PageLoader from 'components/PageLoader';
import Button from 'components/button';

// API
import {
  addFamilyPlan,
  getProductFamilyDetails,
  getServicesProductFamily,
  updateProductFamilyPlan,
} from 'api/latest/ProductCatalog/productCatalog';

// Constants
import { APP_CONSTANTS } from 'constants/app-constants';

// Utils
import NewAttachment from 'app/components/NewDesign/NewAttachment';
import Toggle from 'react-toggle';
import Toast from 'utils/Toast';
import ValidationMessage from 'utils/ValidationMessage';
import Message from 'app/utils/Message';
import TabBar from 'app/components/NewDesign/TabBar';
import TabBarOptions from 'app/utils/TabBarOptions';
import { removeNullValues } from 'app/utils/Parsers';

const CreateProductFamily = () => {
  const history = useHistory();
  const params = useParams();
  const [pageLoading, setPageLoading] = useState(false);
  const [toggleStatus, setToggleStatus] = useState(false);
  const [servicesOptions, setServicesOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState('');
  // const [activeTab, setActiveTab] = useState('English');
  // const [hideFields, setHideFields] = useState(false);
  const [createFamilyForm, setCreateFamilyForm] = useState({
    familyName: '',
    internalFamilyName: '',
    description: '',
    serviceName: '',
    status: '',
    plan_benefit_image: '',
    attachmentFile: null,
  });
  const [hideFields, setHideFields] = useState(false);
  // const { LanguageOptions } = useTabBarOptions();
  const [activeTab, setActiveTab] = useState({
    id: '1',
    name: 'English',
    short_name: 'en',
    display_order: 1,
    status: 'ACTIVE',
    label: '+ English',
    value: 'English',
  });

  const getProductFamilyDetailsById = async (id, lang) => {
    setPageLoading(true);
    setCreateFamilyForm({
      familyName: '',
      internalFamilyName: '',
      description: '',
      serviceName: '',
      status: '',
      plan_benefit_image: '',
      attachmentFile: null,
    });
    let responseObj = await getProductFamilyDetails(id, lang);
    setToggleStatus(responseObj?.status === 'ACTIVE' ? true : false);
    setSelectedOption(responseObj?.service_name);
    setCreateFamilyForm({
      familyName: responseObj.family_name,
      internalFamilyName: responseObj.internal_family_name,
      description: responseObj.description,
      serviceName: responseObj.service_name,
      status: responseObj.status,
      plan_benefit_image: responseObj?.plan_benefit_image
        ? responseObj?.plan_benefit_image
        : '',
    });

    setPageLoading(false);
  }; 
  const serviceOptionsData = async () => {
    const response = await getServicesProductFamily(); 
    setServicesOptions(response.data);
  };

  useEffect(() => {
    if (params.id) {
      getProductFamilyDetailsById(params.id, activeTab.short_name);
    }
  }, [params.id, activeTab.short_name]);

  useEffect(() => {
    serviceOptionsData();
  }, []);

  const handleTabChange = (val) => {
    setActiveTab(val);
    if (val.short_name === 'English') {
      setHideFields(false);
    } else {
      setHideFields(true);
    }
    // Perform any other actions on tab change
  };

  const handleToggleChange = () => {
    setToggleStatus(!toggleStatus);
  };

  const handleFormInputChange = (e) => {
    e.preventDefault();
    const { value, name } = e.target;
    setCreateFamilyForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSelectChange = (e) => { 
    e.preventDefault();
    setSelectedOption(e.target.value);
  };

  const handleCreateFamilyPlan = async (e) => {
    e.preventDefault();
    // const { familyName, attachmentFile, plan_benefit_image } = createFamilyForm;
    let {
      familyName,
      attachmentFile,
      description,
      internalFamilyName,
      plan_benefit_image = '',
    } = createFamilyForm;

    if (!familyName) {
      Toast.error(ValidationMessage.FAMILY_NAME);
      setPageLoading(false);
      return;
    }
    if (familyName.length > 60) {
      Toast.error(ValidationMessage.FAMILY_NAME_LENGTH);
      setPageLoading(false);
      return;
    }
    if (description.length > 150) {
      Toast.error(ValidationMessage.TEXT_DESCRIPTION);
      setPageLoading(false);
      return;
    }
    if (activeTab.short_name === 'en') {
      if (selectedOption === '') {
        Toast.error(ValidationMessage.SERVICE);
        setPageLoading(false);
        return;
      }
    }
    if (params.id) {
      if (plan_benefit_image === '' && attachmentFile === null) {
        Toast.error(ValidationMessage.PLAN_BENEFIT_IMAGE);
        setPageLoading(false);
        return;
      }
    } else {
      if (attachmentFile === null) {
        Toast.error(ValidationMessage.PLAN_BENEFIT_IMAGE);
        setPageLoading(false);
        return;
      }
    }

    const selectedService = servicesOptions.find(
      (option) => option.name === selectedOption
    );
    const selectedServiceId = selectedService?.id;

    let payload = {
      id: params.id,
      family_name: familyName,
      internal_family_name: internalFamilyName,
      description: description,
      service: selectedServiceId,
      status: toggleStatus ? 'ACTIVE' : 'INACTIVE',
    };

    payload.plan_benefit_image = attachmentFile
      ? attachmentFile
      : plan_benefit_image;

    setPageLoading(true);
    let response = {};
    if (params.id) {
      payload.id = params.id;

      let temporaryStates = removeNullValues(payload);
      response = await updateProductFamilyPlan(
        temporaryStates,
        payload.id,
        activeTab.short_name
      );
    } else {
      response = await addFamilyPlan(payload);
    }
    setPageLoading(false);
    if (response?.response?.code === 200 || response?.response?.code === 201) {
      Toast.success(
        response.response.code === 200
          ? Message.ProductFamily.UPDATED_SUCCESS
          : Message.ProductFamily.CREATED_SUCCESS
      );

      history.goBack();
    } else {
      Toast.error(
        response?.data ? response?.data : Message.Error.COMMON_MESSAGE
      );
    }
  };

  const onFileSelected = (file) => {
    setCreateFamilyForm((prevState) => ({
      ...prevState,
      attachmentFile: file,
    }));
  };

  return (
    <>
      <div className='py-3 px-5'>
        <HeaderWithBackButton
          title='Add/edit Product Family'
          goTo={APP_CONSTANTS.CMS_VITALS_PRODUCT_FAMILY}
        />
        {params?.id ? (
          <TabBar
            tabs={TabBarOptions.LanguageOptions}
            initialTab={activeTab}
            onTabChange={handleTabChange}
            parentStyle={'service-tab-style'}
          />
        ) : null}
        <NewInput
          id='familyName'
          label='Family Name*'
          type='text'
          placeholder='Family Name'
          name='familyName'
          value={createFamilyForm.familyName ? createFamilyForm.familyName : ''}
          onChange={handleFormInputChange}
        />

        {activeTab.short_name === 'en' ? (
          <NewInput
            id='internalFamilyName'
            label='Internal Family Name'
            type='text'
            placeholder='Internal Family Name'
            name='internalFamilyName'
            value={
              createFamilyForm.internalFamilyName
                ? createFamilyForm.internalFamilyName
                : ''
            }
            onChange={handleFormInputChange}
          />
        ) : null}

        <NewInput
          id='description'
          label='Description'
          type='text'
          placeholder='Description'
          name='description'
          value={
            createFamilyForm.description ? createFamilyForm.description : ''
          }
          onChange={handleFormInputChange}
        />

        {activeTab.short_name === 'en' ? (
          <label className='mt-4 d-block w-100'>
            Services*
            <select
              style={{
                height: '3rem',
                border: '1px solid #bdbdbd',
                padding: '0 0.75rem',
                borderRadius: '0.25rem',
              }}
              className='mt-2 w-100 d-block'
              name='serviceName'
              value={selectedOption}
              onChange={handleSelectChange}
            >
              <option value=''>Please select an option</option>
              {servicesOptions?.map((option) => (
                <option key={option.id} value={option.name}>
                  {option.name}
                </option>
              ))}
            </select>
          </label>
        ) : null}
        
        <label className='mt-4 d-block w-100'>Add Plan Benefits*</label> 
        <NewAttachment
          value={
            createFamilyForm?.plan_benefit_image !== ''
              ? createFamilyForm?.plan_benefit_image
              : ''
          }
          id={''}
          onFileSelected={onFileSelected}
          fileType='image'
          label={`Add an attachment`}
          className='feed_attachment'
        /> 

        {activeTab.short_name === 'en' ? (
          <div className='d-block my-4'>
            <span className='me-2 d-inline-block'>Status*</span>
            <label className='d-inline-block mt-2'>
              <div className='d-inline-flex align-items-center'>
                <div className='d-inline-flex text-danger'>OFF</div>
                <div className='d-inline-flex mx-2'>
                  <Toggle
                    checked={toggleStatus}
                    icons={false}
                    onChange={handleToggleChange}
                  />
                </div>
                <div className='d-inline-flex text-green'>ON</div>
              </div>
            </label>
          </div>
        ) : null}
        <Button onClick={handleCreateFamilyPlan} type='submit' name='Submit' />
      </div>

      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </>
  );
};

export default CreateProductFamily;
