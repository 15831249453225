import { API_CONSTANTS } from 'app/constants/api-contants';
import ApiParser from 'app/utils/ApiParser';
import Parsers from 'app/utils/Parsers';
import * as dataAccess from 'utils/ajax';

export const getCoursesList = async () => {
  let responseObj = await dataAccess.get(API_CONSTANTS.COURSES_LIST);
    return responseObj;
};

export const getCourseById = async (id) => {
  // let url = `${API_CONSTANTS.COURSES_DETAILS}/${id}`;
  // let responseObj = await dataAccess.get(url);
  // return responseObj;

  let url = `${API_CONSTANTS.COURSES_DETAILS}/${id}`;
  let responseObj = await dataAccess.get(url);
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data;
  } else {
    return [];
  }
};

export const addUpdateCourse = async (incomingData) => {
  // var formData = Parsers.ConvertObjectAsFormData(incomingData);

  let payload = {
    data: incomingData,
  };

  return await dataAccess.upload(API_CONSTANTS.COURSES, payload);
};

export const getChapterList = async (id) => {
  let url = `${API_CONSTANTS.COURSES_CHAPTER_VIDEO}`;
  let responseObj = await dataAccess.get(url);
  return responseObj;
};

export const getChapterById = async (id) => {
  let url = `${API_CONSTANTS.COURSES_CHAPTER_VIDEO}/${id}`;
  let responseObj = await dataAccess.get(url);
  return responseObj;
};

export const addUpdateChapter = async (incomingData) => {
  var formData = Parsers.ConvertObjectAsFormData(incomingData);
  let payload = {
    data: formData,
  };

  return await dataAccess.upload(API_CONSTANTS.COURSES_CHAPTER_VIDEO, payload);
};

export const getTagsLists = async (id) => {
  // let url = `${API_CONSTANTS.COURSES_TAG_LIST}`;
  // let responseObj = await dataAccess.get(url);
  // return responseObj;

  let responseObj = await dataAccess.get(API_CONSTANTS.COURSES_TAG_LIST);
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data;
  } else {
    return [];
  }
};

//---------courses update quiz------------
export async function updateCoursesQuiz(incomingData) {
  let payload = {
    data: incomingData,
  };
  let url = `${API_CONSTANTS.COURSES_QUIZ}`;
  return await dataAccess.upload(url, payload);
}

/////Get Quiz API////////
export const getCourseQiuzApi = async (id) => {
  let response = await dataAccess.get(`${API_CONSTANTS.COURSES_QUIZ}/${id}`);
  return response;
};
////////////////////////

export const updateCourseStatus = async (incomingData, id) => {
  let payload = {
    data: {
      ...incomingData,
    },
  };
  let url = `${API_CONSTANTS.UPDATE_COURSES_STATUS}/${id}`;
  return await dataAccess.put(url, payload);
};

// Banner API

export const getLatestCoursesBanner = async (data) => {
  let url = `${API_CONSTANTS.GET_LATEST_BANNERS_VERSION3}?page=${data.page}`;
  let responseObj = await dataAccess.get(url);
  return responseObj;
};

// Courses Ordering API

export const updateCousesOrdering = async (incomingData) => {
  let payload = {
    data: {
      course_update: incomingData,
    },
  };
  return await dataAccess.post(API_CONSTANTS.COURSES_REORDER, payload);
};

// Recommended Course API Components

export const getCoursesByCohortAPI = async (cohort) => {
  let url = `${API_CONSTANTS.COURSES_COHORT}/${cohort}`;
  let responseObj = await dataAccess.get(url);
  return responseObj;
};
export const getCoursesByCohortDetailsValuesAPI = async () => {
  let url = `${API_CONSTANTS.COURSES_COHORT}`;
  let responseObj = await dataAccess.get(url);
  return responseObj;
};

export const submitCoursesByCohortAPI = async (incomingData) => {
  let payload = {
    data: {
      start_course: incomingData,
    },
  };

  return await dataAccess.post(API_CONSTANTS.UPDATE_COURSES_START, payload);
};

export const getRecommendedCourseAPI = async () => {
  let url = `${API_CONSTANTS.COURSES_RECOMMENDED_GET}`;
  let responseObj = await dataAccess.get(url);
  return responseObj;
};

export const getRecommendedCourseReorderAPI = async () => {
  let url = `${API_CONSTANTS.COURSES_COHORT}`;
  let responseObj = await dataAccess.get(url);
  return responseObj;
};


export const getStartCourseList = async () => {
  let responseObj = await dataAccess.get(API_CONSTANTS.COURSES_START);
    return responseObj;
};

export const updateChapterOrdering = async (incomingData) => {
  let payload = {
    data: {
      course_update: incomingData,
    },
  };
  return await dataAccess.post(API_CONSTANTS.COURSE_CHAPTER_REORDER, payload);
};

export const courseLandingPageOrder = async () => {
  let url = `${API_CONSTANTS.COMMON_HOMEPAGE}?page=COURSE`;
  let responseObj = await dataAccess.get(url);
  return responseObj;
};

export const addOrUpdateCoursePageOrder = async (incomingData) => {
  let url = `${API_CONSTANTS.COMMON_HOMEPAGE}?page=COURSE`;
  let payload = {
    data: {
      home_page_update: incomingData,
    },
  };
  return await dataAccess.post(url, payload);
};