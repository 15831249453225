import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const CustomDragabbleTable = ({
  data,
  draggableTableHeader,
  onSelectedItemsChange,
  isSubmitClicked,
}) => {
  const handleDragEnd = (result) => {
    if (!result.destination) {
      return; // No valid drop destination, do nothing
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    // Create a new array with the item moved to the new position
    const updatedData = Array.from(data);
    const [movedItem] = updatedData.splice(sourceIndex, 1);
    updatedData.splice(destinationIndex, 0, movedItem);
    onSelectedItemsChange(updatedData);
  };

  return (
    <div className='mt-4'>
      <label className='mb-2'>Content Ordering</label>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId='droppable' direction='vertical'>
          {(provided) => (
            <table ref={provided.innerRef} {...provided.droppableProps}>
              <thead>
                {draggableTableHeader.map((item, i) => (
                  <th key={i} className='nowrap text-center'>
                    {item.Header}
                  </th>
                ))}
              </thead>
              {
                <tbody>
                  {data.map((items, idx) => (
                    <Draggable
                      key={items.id.toString()}
                      draggableId={items.id.toString()}
                      index={idx}
                    >
                      {(provided) => (
                        <tr
                          key={items.id.toString()} // Use item's ID as the key here
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          {/* Render table row content as before */}
                          <td>▶</td>
                          <td>{items.title}</td>
                          <td>{items.id}</td>
                        </tr>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </tbody>
              }
            </table>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default CustomDragabbleTable;
