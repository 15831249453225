// Ajax
import * as dataAccess from 'utils/ajax';

// Constants
import { API_CONSTANTS } from 'constants/api-contants';

import Parsers from 'utils/Parsers';

export const getSuccessStory = async (lang) => {
  let url = `${API_CONSTANTS.SUCCESS_STORY}`;
  let responseObj = await dataAccess.get(url);
  return responseObj;
};

export const addSuccessStory = async (incomingData) => {
  let payloads = [];
  if (incomingData && incomingData.length > 0) {
    incomingData.forEach((item) => {
      var formData = Parsers.ConvertObjectAsFormData(item);

      payloads.push({
        data: formData,
      });
    });
  }

  if (payloads.length < 1) {
    return;
  }
  return await dataAccess.uploadAll(API_CONSTANTS.SUCCESS_STORY, payloads);
};

export const updateSuccessStory = async (incomingData) => {
  let payloads = [];
  if (incomingData && incomingData.length > 0) {
    incomingData.forEach((item) => {
      var formData = Parsers.ConvertObjectAsFormData(item);

      payloads.push({
        data: formData,
        item,
      });
    });
  }

  if (payloads.length < 1) {
    return;
  }

  return await dataAccess.updateAll(API_CONSTANTS.SUCCESS_STORY, payloads);
};

export const deleteSuccessStory = async (id) => {
  let url = `${API_CONSTANTS.SUCCESS_STORY}/${id}`;
  return await dataAccess.del(url);
};
