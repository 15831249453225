import '../index.scss';
import Search from 'app/components/search';
import { useEffect, useState } from 'react';
import Toast from 'app/utils/Toast';
import Button from 'app/components/button';
import CustomTable from './CustomTable';
import Pagination from '../pagination';
import { useHistory } from 'react-router-dom';
import RadioButton from 'app/shared/components/RadioButton';
import PageLoader from 'app/components/PageLoader';
import {
    getExercisesList,
  } from 'app/api/exercises';
  


const PremiumExerciseContainer = (tableHeader) => {
  const history = useHistory();

  const [values, setValues] = useState('');
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [selectedItem, setSelectedItem] = useState('LANDING_PAGE');
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const dataPerPage = 10;
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getContentDataHandler();
  }, []);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  let currentData = data.slice(indexOfFirstData, indexOfLastData);

  async function getContentDataHandler() {
    const dataArr = await getExercisesList();
    let premiumExerciseData=dataArr.filter((item)=>item.exercise_type =='ANIMATED_EXERCISE');
    let updatedData = [];
    premiumExerciseData.forEach((item) => {
      updatedData.push({
        ...item,
      });
    });
    setTotalData(updatedData);
    setData(updatedData);
    let count = Math.ceil(updatedData.length / dataPerPage);
    setPageCount(count);
  }



  function paginationHandler(page) {
    setCurrentPage(page);
  }

  
  

  

 
  return (
    <>
      <div className='cms-content__container'>
        <h4> Exercises Premium or Not</h4>
        
       

        <div className='content-container__table'>
          <CustomTable
            data={currentData}
            tableHeader={tableHeader.tableHeader}
            onDataUpdate={getContentDataHandler}
            // handleInputChangeHandler={handleInputChangeHandler}
            // handleEdit={handleEdit}
            // handleDelete={handleDelete}
            // handlePublishUnpublish={handlePublishUnpublish}
          />
        </div>

        <div className='content-container__pagination'>
          <Pagination pages={pageCount} onChange={paginationHandler} />
        </div>
      </div>
      {loader && <PageLoader pageLoading={loader} />}
    </>
  );
};

export default PremiumExerciseContainer;
