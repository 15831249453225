import { Button } from 'react-bootstrap';
import './index.scss'

const FileUploadBtn = ({ label, onFileHandler, name }) => {

     return (<Button className='upload-btn'>
          <input type='file' name={name ? name : 'file'} id='upload' onChange={onFileHandler} />
          <label htmlFor='upload'>{label}</label>
     </Button>)
};

export default FileUploadBtn;