import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import DateTime from 'shared/components/DateTime';
import moment from 'moment';

const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';

const WebinarCalendar = ({ startDate, onStartDateSelected, label, minDate=null }) => {
  const onStartDateChange = (event) => {
    if (!onStartDateSelected) return;
    if (event) {
      onStartDateSelected(event.format(dateTimeFormat));
    } else {
      onStartDateSelected('');
    }
  };

  var yesterday = moment().subtract(1, 'day');
  var valid = function (current) {
    return current.isAfter(yesterday);
  };

  return (
    <div className='calender'>
      <Form.Group>
        <Row>
          <Col>
            <Form.Label>{label}</Form.Label>
            <DateTime
              dateFormat='DD-MM-yyyy'
              onChange={onStartDateChange}
              isValidDate={valid}
              value={startDate}
              minDate={minDate}
            />
          </Col>
        </Row>
      </Form.Group>
    </div>
  );
};

export default WebinarCalendar;
