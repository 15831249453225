import React from 'react';

// Pages
import BasePage from 'pages/BasePage';

import LatestBanner from 'app/container/LatestBanner';

const DiagnosticsLargeBannersPage = () => {
  return (
    <BasePage>
      <LatestBanner type={`diagnostics Banners (Large)`} />
    </BasePage>
  );
};

export default DiagnosticsLargeBannersPage;
