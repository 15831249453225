import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import Toast from 'app/utils/Toast';
import Dropdown from 'app/shared/components/Dropdown';
import PageLoader from 'app/components/PageLoader';
import Button from 'app/components/button';
import { getWidgetList, getStaticWidgetList } from 'app/api/widgets';
import { getVersionControl } from 'app/api/versionList';
import { getHomePageSectionList } from 'app/api/homepagesection';
import moment from 'moment'; 
import VersionDropDown from 'app/components/VersionDropDown';
import Select from "react-select";
const HomePageOrderContainer = ({handleWidgetSession, currentItem, sectionList = []}) => {
  const location = useLocation();
  let history = useHistory();
  const params = useParams();
  const [loader, setLoader] = useState(false);
  const [state, setState] = useState({
    section_id: '',
    section_name:'',
    widget_id: '',
    widget_name:'',
    type: '',
    android_version: '',
    criteria: '',
    ios_criteria: '',
    ios_version: '',
    last_edited_time:'',
    last_updated_by:'',
    id:''
  });
  const [widgetLists, setWidgetLists] = useState([]);
  const [sectionLists, setSectionLists] = useState([]);
  let [versionLists, setVersionLists] = useState([]);
  let [iosversionLists, setIosVersionLists] = useState([]);
  const VersionRange = [
    {
      value: 'GREATER_THAN',
      label: 'Greater Than',
    },
    {
      value: 'LESS_THAN',
      label: 'Lesser Than',
    },
    {
      value: 'EQUALS',
      label: 'Equals',
    },
  ];

  useEffect(() => {
    getContentDataHandler();
    if (params.id) {
      updateStateValue();
    }  
    if(currentItem&& (currentItem.section_id || currentItem.widget_id)){
      setState(currentItem)
    }else{
      setState({
        section_id: '',
        section_name:'',
        widget_id: '',
        widget_name:'',
        type: '',
        android_version: '',
        criteria: '',
        ios_criteria: '',
        ios_version: '',
        last_edited_time:'',
        last_updated_by:'',
        id:''
      })
    }
  }, []);

  useEffect(() => {
    async function fetchVersionControl() {
      let response = await getVersionControl('Android');

      const data = response.data.available_versions;
      let newState = data.map((key) => {
        return {
          value: [key][0].toString(),
          label: [key][0].toString(),
        };
      });
      setVersionLists(newState);

      let iosResponse = await getVersionControl('IOS');

      const iosdata = iosResponse.data.ios_available_versions;
      let newiosState = iosdata.map((key) => {
        return {
          value: [key][0].toString(),
          label: [key][0].toString(),
        };
      });
      setIosVersionLists(newiosState);
    }
    fetchVersionControl();
  }, []);

  async function getContentDataHandler() {
    let dataArr;
    let sectionDataArr;
    setLoader(true);
    dataArr = await getWidgetList();
    sectionDataArr = await getHomePageSectionList();
    let widgetListOptions = [];
    dataArr.data.map((item) => {
      widgetListOptions.push({
        label: item.widget_name,
        id: item.widget_id,
        value: item.widget_id,
      });
    });
    let staticWidget = await getStaticWidgetList();
    staticWidget.data.map((item, i) => {
      widgetListOptions.push({
        name: item.widget_name,
        label: item.widget_name,
        id: item.widget_id,
        value: item.widget_id,
        tag_type: 'Static Widget',
      });
    });

    setWidgetLists(widgetListOptions);
    let sectionListOptions = [];
    sectionDataArr.data.homepage_section_data.map((item) => {
      sectionListOptions.push({
        label: item.section_name,
        id: item.id,
        value: item.id,
      });
    });
    setSectionLists(sectionListOptions);
    if (dataArr && dataArr.response && dataArr.response.code === 200) { 
      setLoader(false);
    } else {
      setLoader(false);
      Toast.error(
        dataArr.data && dataArr.data.msg
          ? dataArr.data.msg
          : dataArr.response.alert[0].message
      );
    }
    setLoader(false);
  }

  const updateStateValue = () => {
    let widgetListArray = [];
    let homePageDetails = location.state;
    if (homePageDetails & homePageDetails.widget_mapped) {
      homePageDetails.widget_mapped.map((item) => {
        widgetListArray.push(item.id.toString());
      });
      setState({
        name: location.state.section_name,
        widget_list: widgetListArray,
      });
    }
    if (homePageDetails && homePageDetails.id) {
      let updatedState = {};
      if (homePageDetails.section_id) {
        updatedState.section_id = homePageDetails.section_id; 
      } else {
        updatedState.widget_id = homePageDetails.widget_id; 
      }
      updatedState.type = homePageDetails.type;
      updatedState.android_version = homePageDetails.android_version.toString();
      updatedState.criteria = homePageDetails.criteria;
      updatedState.ios_version = homePageDetails.ios_version
        ? homePageDetails.ios_version.toString()
        : '';
      updatedState.ios_criteria = homePageDetails.ios_criteria
        ? homePageDetails.ios_criteria
        : '';
      setState(updatedState);
    }
  };

  const handleTextChange = (e) => {  
    if (e.target.name === 'widget_id') {

      const selectedWidget  = widgetLists.find(
        (data) => Number(data.value) === Number(e.target.value)
      ); 
      setState({ ...state, [e.target.name]: e.target.value, widget_name: selectedWidget?.label,last_edited_time: moment(new Date()).format('DD-MM-YYYY'), id:(sectionList.length+1).toString()});
    } else if(e.target.name === 'section_id'){
      const selectedWidget  = sectionLists.find(
        (data) => Number(data.value) === Number(e.target.value)
      );
      setState({ ...state, [e.target.name]: e.target.value, section_name:selectedWidget?.label, last_edited_time: moment(new Date()).format('DD-MM-YYYY'), id:(sectionList.length+1).toString() });
    } else { 
      setState({ ...state, [e.target.name]: e.target.value });
    }
  };
  const handleTextChangeForSection = (selectedOption, actionMeta) => {
    if (actionMeta.name === 'widget_id') {
      const matchingItem = widgetLists.find(
        (data) => Number(data.value) === Number(selectedOption.value)
      ); 
    setState({...state, [actionMeta.name]: selectedOption ? selectedOption.value:'',widget_name: matchingItem?.label,last_edited_time: moment(new Date()).format('DD-MM-YYYY'), id:(sectionList.length+1).toString()})  
    }else if(actionMeta.name === 'section_id'){
      const matchingItem = sectionLists.find(
        (data) => Number(data.value) === Number(selectedOption.value)
      ); 
    setState({...state, [actionMeta.name]: selectedOption ? selectedOption.value:'',section_name: matchingItem?.label,last_edited_time: moment(new Date()).format('DD-MM-YYYY'), id:(sectionList.length+1).toString()})  
    }
};
  const handleSubmit = async () => {
    
    if(!state.widget_id && !state.section_id){
      Toast.error('Please Select widget or section')
      return
    }
    if(!state.android_version || !state.criteria){
      Toast.error('Please Select android version and android criteria')
      return
    }
    if(!state.ios_version || !state.ios_criteria){
      Toast.error('Please Select IOs version and IOs criteria')
      return
    } 
    handleWidgetSession(state)
   
  };
  const widgetLookup = Object.fromEntries(widgetLists.map(widget => [widget.value, widget]));
  const sectionLookup = Object.fromEntries(sectionLists.map(section => [section.value, section]));
  return (
    <div className='container'>
      <h1 className='section-header__title'>Landing pages \ Home Page Ordering</h1>
      <div className='cms-content__container'>
        <div className='row mt-3'>
          <div className='col-md-4'>
            {/* <Dropdown
              label='Widgets'
              placeholder='Selected widget'
              name='widget_id'
              value={state.widget_id}
              options={widgetLists}
              handleChange={handleTextChange}
              disabled={state.section_id}
              type={'group'}
              asterisk={true}
            />  */}
            <label className='mb-2' id="aria-label" htmlFor="aria-example-input">
                         Widgets
                </label>
               <Select options={widgetLists}
                        label='Widgets'
                        textFieldProps={{
                          label: 'Label',
                          InputLabelProps: {
                            shrink: true,
                          },
                        }}
                        placeholder='Selected Widget'
                        name='widget_id'
                        value={widgetLists.find(option => option.value === state.widget_id)}
                        onChange={handleTextChangeForSection}
                        isDisabled={state.section_id}
                        />
          </div>
          <div className='col-md-1 d-flex align-items-center justify-content-center'>
            <h4>(OR)</h4>
          </div>
          <div className='col-md-4'>
            {/* <Dropdown
              label='Sections'
              placeholder='Selected section'
              name='section_id'
              value={state.section_id}
              options={sectionLists}
              handleChange={handleTextChange}
              disabled={state.widget_id}
              asterisk={true}
            />  */}
            <label className='mb-2' id="aria-label" htmlFor="aria-example-input">
                         Sections
                  </label>
                        <Select options={sectionLists}
                        label='Sections'
                        placeholder='Selected section'
                        name='section_id'
                        value={sectionLists.find(option => option.value === state.section_id)}
                        onChange={handleTextChangeForSection}
                        isDisabled={state.widget_id}
                        />
          </div>
        </div>
        <VersionDropDown
          android_version={state.android_version}
          criteria={state.criteria}
          versionLists={versionLists}
          iosLists={iosversionLists}
          VersionRange={VersionRange}
          handleTextChange={handleTextChange}
          ios_version={state.ios_version}
          ios_criteria={state.ios_criteria}
          asterisk={true}
        />
        
        <div className='row'>
          <div className='userFormArea mt-5'>
            <Button
              name={params.id ? 'Update' : 'Save'}
              onClick={handleSubmit}
            />
            <label
              style={{ paddingLeft: '10px', cursor: 'pointer' }}
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </label>
          </div>
        </div>
      </div>
      {loader && <PageLoader pageLoading={loader} />}
    </div>
  );
};

export default HomePageOrderContainer;
