import MultiSelectDropdown from 'app/components/MultiSelectDropdown';
import NewAttachment from 'app/components/NewDesign/NewAttachment';
import NewInput from 'app/components/NewInput';
import PageLoader from 'app/components/PageLoader';
import TextArea from 'app/components/Textarea';
import { CoursesChapterTableHeader } from 'app/constants/table-header-constants';
import useLanguagesForm from 'app/container/LanguageMaster/useLanguagesForm';
import DropdownOptions from 'app/utils/DropdownOptions';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Toggle from 'react-toggle';
import CustomDragabbleTable from './CustomDragabbleTable';
import './index.scss';
import useCreateCourses from './useCreateCourses';

const CoursesCreateContainer = () => {
  const {
    handleDragEnd,
    selectedItems,
    isSubmitClicked,
    handleResourseToggle,
  } = useLanguagesForm();

  const {
    selectedAge,
    selectedCohort,
    pageLoading,
    state,
    setState,
    handleVideoBtn,
    handleTextBtn,
    handleQuizBtn,
    handleChange,
    handleCohortTags,
    handleAgeOptions,
    onCourseImageSeleted,
    onAuthorImageSeleted,
    handleSubmit,
    tagData,
    courseDetailsData,
    params,
    handleSelectedItemsChange
  } = useCreateCourses();

  return (
    <>
      <div className='section-header'>
        <h4>Course Creation &gt; {state?.title ? state?.title : 'Enter course title' }</h4>
      </div>
      <section className='course-section'>
        <div className='course-section_title'>
          <h4 className={!state?.title && 'heading-fade'}>{state?.title ? state?.title : 'Enter course title' }</h4>
          <Toggle
            checked={state?.publish_status}
            icons={false}
            name='publish_status'
            onChange={(e) => {
              setState({ ...state, publish_status: !state.publish_status });
            }}
          />
        </div>
        <div className='_content'>
          <div className='form-wrapper'>
            <div className='form-field'>
              <NewInput
                label='Title'
                name='title'
                value={state?.title}
                onChange={handleChange}
                asterisk={true}
              />
            </div>
            <div className='form-field'>
              <NewAttachment
                label='Add Course Image'
                name='course_image'
                value={state?.course_image}
                id='CI'
                onFileSelected={onCourseImageSeleted}
                fileType='image'
                className='widget_box_feed__attachment'
                asterisk={true}
              />
            </div>
            <div className='form-field'>
              <NewInput
                label='Author'
                name='author'
                value={state?.author}
                onChange={handleChange}
                asterisk={true}
              />
            </div>
            <div className='form-field'>
              <TextArea
                label='About the course'
                name='course_description'
                value={state?.course_description}
                onChange={handleChange}
                asterisk={true}
              />
            </div>
            <div className='form-field'>
              <NewInput
                label='Author’s Expertise'
                name='author_expertise'
                value={state?.author_expertise}
                onChange={handleChange}
                asterisk={true}
              />
            </div>
            <div className='form-field'>
              <TextArea
                label='About the author'
                name='author_description'
                value={state?.author_description}
                onChange={handleChange}
                asterisk={true}
              />
            </div>
            <div className='form-field'>
              <NewAttachment
                label='Add Author’s Picture'
                name='author_image'
                value={state?.author_image}
                id='CI'
                onFileSelected={onAuthorImageSeleted}
                fileType='image'
                className='widget_box_feed__attachment'
                asterisk={true}
              />
            </div>
          </div>

          <hr className='divider-hr' />
          <div className='form-wrapper quarter-field'>
            <div className='form-field '>
              <label>Cohorts<span className='asterisk'>*</span></label>
              <ul className='multiselect-btn'>
                {DropdownOptions?.CohortOption?.map((item, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => handleCohortTags(item)}
                      className={
                        selectedCohort && selectedCohort.includes(item)
                          ? 'active'
                          : ''
                      }
                    >
                      {item}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className='form-field'>
              <label>Age groups<span className='asterisk'>*</span></label>
              <ul className='multiselect-btn'>
                {DropdownOptions.AgeOption?.map((item, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => handleAgeOptions(item)}
                      className={
                        selectedAge && selectedAge.includes(item)
                          ? 'active'
                          : ''
                      }
                    >
                      {item}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className='form-field duration-filed'>
              <label>Duration<span className='asterisk'>*</span></label>
              <div className='two-field'>
                <NewInput
                  placeholder='MM'
                  name={'duration_minute'}
                  type='number'
                  value={state?.duration_minute}
                  onChange={handleChange}
                />
                <span>:</span>
                <NewInput
                  placeholder='SS'
                  name={'duration_second'}
                  type='number'
                  value={state?.duration_second}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='form-field'>
              <label>Recommend course</label>
              <Toggle
                checked={state?.recommended_course}
                icons={false}
                name='recommended_course'
                onChange={(e) => {
                  setState({ ...state, recommended_course: !state.recommended_course });
                }}
              />
            </div>
          </div>

          <hr className='divider-hr' />
          <div className='frontend-search'>
            <MultiSelectDropdown
              id={'1'}
              label='Frontend Tags (Categories)'
              name='tags'
              value={state?.tags}
              options={tagData}
              onChange={handleChange}
              asterisk={true}
            />
            <div className='d-flex flex-row-reverse mt-4'>
              <button className='primary-btn button ' onClick={handleSubmit}>
                Save
              </button>
            </div>
          </div>
          {params?.id && (
          <>
          <hr className='divider-hr' />
          <div className='chapter-wrap'>
            <div className='chapter-title'>
              <label>Chapters</label>
              <ul>
                <li>
                  <button onClick={handleVideoBtn}>+Video Chapter</button>
                </li>
                <li>
                  <button onClick={handleTextBtn}>+Text Chapter</button>
                </li>
                <li>
                  <button onClick={handleQuizBtn}>+Quiz</button>
                </li>
              </ul>
            </div>
              <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId='droppable' direction='vertical'>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <CustomDragabbleTable
                      data={courseDetailsData}
                      draggableTableHeader={CoursesChapterTableHeader}
                      isSubmitClicked={isSubmitClicked}
                      onSelectedItemsChange={handleSelectedItemsChange}
                      handleResourseToggle={handleResourseToggle}
                    />
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          </>
          )}
        </div>
      </section>
      {/* {pageLoading && <PageLoader pageLoading={pageLoading}></PageLoader>} */}
    </>
  );
};

export default CoursesCreateContainer;
