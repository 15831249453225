import {
  ArrowDownImage,
  ArrowUpImage,
  MenuIcon,
} from 'constants/image-constants';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { usePagination, useSortBy, useTable } from 'react-table';

import './newindex.scss';
import Toggle from 'react-toggle';

const Table = ({
  columns,
  data,
  pageCount: controlledPageCount,
  menuAction,
  handleResourseToggle,
  HomePageOrder,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy,
      usePagination
    );

  const [pageData, setPageData] = useState([]);

  const handleDragEnd = async (e) => {
    if (!e.destination) return;

    let tempData = [...pageData];
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);

    setPageData(tempData);

    await HomePageOrder(tempData);
  };

  useEffect(() => {
    setPageData(page);
  }, [page]);

  // Render the UI for your table
  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className='newdesigntable'>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <img
                              className='arrow'
                              src={ArrowDownImage}
                              alt='Desc'
                            />
                          ) : (
                            <img
                              className='arrow'
                              src={ArrowUpImage}
                              alt='Asc'
                            />
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <Droppable droppableId='droppable-1'>
              {(provider) => (
                <tbody
                  ref={provider.innerRef}
                  {...provider.droppableProps}
                  {...getTableBodyProps()}
                >
                  {pageData.map((row, i) => {
                    prepareRow(row);

                    return (
                      <Draggable key={row.id} draggableId={row.id} index={i}>
                        {(provider) => (
                          <tr
                            {...provider.draggableProps}
                            ref={provider.innerRef}
                            {...row.getRowProps()}
                          >
                            {row.cells.map((cell) => {
                              return (
                                <>
                                  {cell.column.id === 'Action' ? (
                                    <td
                                      style={{ position: 'relative' }}
                                      className='pointer'
                                      // onClick={() => createToggler(row)}
                                    >
                                      <>
                                        <Toggle
                                          // dfaultChecked={first}
                                          checked={row.original.toggle}
                                          icons={false}
                                          onChange={(e) => {
                                            e.preventDefault();
                                            handleResourseToggle(
                                              row.original,
                                              !row.original.toggle
                                            );
                                          }}
                                        />
                                      </>
                                    </td>
                                  ) : cell.column.id === 'Drag' ||
                                    cell.column.id === 'Ranking' ? (
                                    <td {...provider.dragHandleProps}>
                                      {row.original.display_order + ' '}
                                      <img
                                        src={MenuIcon}
                                        alt='Drag'
                                        width='10'
                                        height='10'
                                      />
                                    </td>
                                  ) : cell.column.id === 'SequenceOrder' ? (
                                    <td>{}</td>
                                  ) : (
                                    <>
                                      <td {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                      </td>
                                    </>
                                  )}
                                </>
                              );
                            })}
                          </tr>
                        )}
                      </Draggable>
                    );
                  })}
                </tbody>
              )}
            </Droppable>
          </table>
        </div>
      </DragDropContext>
    </>
  );
};

function SampleTable({
  tableHeader,
  names,
  handleResourseToggle,
  menuAction,
  pagination,
  type,
  getData,
  searchVal,
  searchKey = 'title',
  hasPagination,
  filterData,
  responseCallback,
  setRowData,
  HomePageOrder,
  selectedContent,
}) {
  const columns = React.useMemo(() => tableHeader, [tableHeader]);

  // We'll start our table without any data

  return (
    <Table
      columns={columns}
      data={getData}
      // fetchData={getData}
      loading={false}
      pageCount={0}
      menuAction={menuAction}
      handleResourseToggle={handleResourseToggle}
      hasPagination={hasPagination}
      setRowData={setRowData}
      HomePageOrder={HomePageOrder}
    />
  );
}

export default SampleTable;
