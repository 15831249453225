/* eslint-disable no-useless-escape */
import Toast from 'utils/Toast';

export default function validateInfo(values) {
  let validationStatus = false;
  //   let errors = {};

  if (!values.heading) {
    Toast.error('Coupon Name is Required');
    validationStatus = true;
    return validationStatus;
  }

  if (values.sub_heading) {
    if (values.sub_heading.length > 50) {
      Toast.error('Sub Text  limit is 50 Required');
      validationStatus = true;
      return validationStatus;
    }
  }

  // if (!values.code) {
  //   Toast.error('Coupon Code is Required');
  //   validationStatus = true;
  //   return validationStatus;
  // }
  // if (values.code.length > 15) {
  //   Toast.error('Coupon Code  limit is 15 Required');
  //   validationStatus = true;
  //   return validationStatus;
  // }
  // if (!values.product_type) {
  //   Toast.error('Product Type is Required');
  //   validationStatus = true;
  //   return validationStatus;
  // }

  // if (values?.product_type === 'AUTOMATED_VITALS') {
  //   if (!values.product_family) {
  //     Toast.error('Product family is Required');
  //     validationStatus = true;
  //     return validationStatus;
  //   }
  // }
  // if (!values.max_usage_per_user) {
  //   Toast.error('Maximum Usage is Required');
  //   validationStatus = true;
  //   return validationStatus;
  // }

  // if (!values.discount) {
  //   Toast.error('Discount is Required');
  //   validationStatus = true;
  //   return validationStatus;
  // }
  // if (!values.min_value_required) {
  //   Toast.error('Order Above is Required');
  //   validationStatus = true;
  //   return validationStatus;
  // }
  // if (!values.max_discount_amount) {
  //   Toast.error('Maxium Discount is Required');
  //   validationStatus = true;
  //   return validationStatus;
  // }

  // if (!values.start_from) {
  //   Toast.error('Start Date is Required');
  //   validationStatus = true;
  //   return validationStatus;
  // }
  // if (!values.end_at) {
  //   Toast.error('End Date is Required');
  //   validationStatus = true;
  //   return validationStatus;
  // }
  // if (!values.description) {
  //   Toast.error('Description is Required');
  //   validationStatus = true;
  //   return validationStatus;
  // }

  return validationStatus;
}
