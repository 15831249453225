// Ajax
import * as dataAccess from 'utils/ajax';
import ApiParser from 'utils/ApiParser';

// Constants
import { API_CONSTANTS } from 'constants/api-contants';
import Parsers from 'app/utils/Parsers';

// Get Lists of Items
export const getProductFamilyPlans = async () => {
  let responseObj = await dataAccess.get(API_CONSTANTS.GET_PRODUCT_FAMILY);
  return ApiParser.parseProductFamilyListing(responseObj);
};

// Create Item
export const addFamilyPlan = async (incomingData) => {
  var formData = Parsers.ConvertObjectAsFormData(incomingData);

  let payload = {
    data: formData,
  };

  return await dataAccess.upload(API_CONSTANTS.UPDATE_PRODUCT_FAMILY, payload);
};

// Update Item
export const updateProductFamilyPlan = async (incomingData, id, lang) => {
  var formData = Parsers.ConvertObjectAsFormData(incomingData);
  let payload = {
    data: formData,
  };
  let url = `${API_CONSTANTS.GET_PRODUCT_FAMILY_VERSION2}/${id}?lang=${lang}`;
  let response = await dataAccess.update(url, payload);
  return response;
};

// Get Prefill Data
export const getProductFamilyDetails = async (id, lang) => {
  let url = `${API_CONSTANTS.GET_PRODUCT_FAMILY_VERSION2}/${id}?lang=${lang}`;
  let responseObj = await dataAccess.get(url);
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data;
  } else {
    return [];
  }
};

// Get Service Dropdown List
export const getServicesProductFamily = async (servicesOptions) => {
  let payload = {
    data: {
      ...servicesOptions,
    },
  };
  return await dataAccess.get(
    API_CONSTANTS.GET_SERVICES_PRODUCT_FAMILY,
    payload
  );
};

export const createPlanActivity = async (incomingData) => {
  var formData = Parsers.ConvertObjectAsFormData(incomingData);

  let payload = {
    data: formData,
  };

  return await dataAccess.upload(API_CONSTANTS.UPDATE_PRODUCT_FAMILY, payload);
};


export const getProductPlanActivationHistory = async () => {
  let responseObj = await dataAccess.get(API_CONSTANTS.GET_PRODUCT_FAMILY);
  return ApiParser.parseProductFamilyListing(responseObj);
};