const LanguageOptions = [
  {
    id: '1',
    name: 'English',
    short_name: 'en',
    display_order: 1,
    status: 'ACTIVE',
    label: '+ English',
    value: 'English',
  },
  {
    id: '2',
    name: 'Hindi',
    short_name: 'hi',
    display_order: 2,
    status: 'ACTIVE',
    label: '+ Hindi',
    value: 'Hindi',
  },
  {
    id: '3',
    name: 'Marathi',
    short_name: 'mr',
    display_order: 3,
    status: 'ACTIVE',
    label: '+ Marathi',
    value: 'Marathi',
  },
  {
    id: '4',
    name: 'Bengali',
    short_name: 'bn',
    display_order: 4,
    status: 'ACTIVE',
    label: '+ Bengali',
    value: 'Bengali',
  },
  {
    id: '5',
    name: 'Gujarati',
    short_name: 'gu',
    display_order: 5,
    status: 'ACTIVE',
    label: '+ Gujarati',
    value: 'Gujarati',
  },
  {
    id: '6',
    name: 'Tamil',
    short_name: 'ta',
    display_order: 6,
    status: 'INACTIVE',
    label: '+ Tamil',
    value: 'Tamil',
  },
  {
    id: '7',
    name: 'Telegu',
    short_name: 'te',
    display_order: 7,
    status: 'ACTIVE',
    label: '+ Telegu',
    value: 'Telegu',
  },
  {
    id: '8',
    name: 'Malayalam',
    short_name: 'ml',
    display_order: 8,
    status: 'ACTIVE',
    label: '+ Malayalam',
    value: 'Malayalam',
  },
  {
    id: '9',
    name: 'Kannada',
    short_name: 'kn',
    display_order: 9,
    status: 'INACTIVE',
    label: '+ Kannada',
    value: 'Kannada',
  },
];
//1- Hindi, 2- Marathi, 3- Bengali, 4- Gujarati, 5- Tamil, 6- Telegu, 7- Malayalam, 8- Kannada
const TabBarOptions = {
  LanguageOptions,
};

export default TabBarOptions;
