import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import Loader from 'components/Loader';
import NewTable from 'components/NewTable';

// API
import { getActivities } from 'api/activity';

// // Utils
// import Toast from 'utils/Toast';
// import Message from 'utils/Message';

const Activity = ({ tableHeader }) => {
  const history = useHistory();
  // const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  // const loadNames = (start, end, increase) => {
  //   setLoading(true);
  //   getActivities(start, end).then((response) => {
  //     setLoading(false);
  //     if (response && response.tableData && response.error === false) {
  //       setData({
  //         tableData: response.tableData,
  //       });
  //     }
  //   });
  // };

  const menuActionObj = [
    {
      name: 'Edit',
      onClick: (e, data) => {
        history.push({
          pathname: `/activities/edit/${data.activity_id}`,
          state: {
            ...data,
          },
        });
      },
    },
  ];

  const sectionObj = {
    sectionTitle: 'Activities',
    searchObj: {
      placeholder: 'Search',
      handleSearch: (e) => {},
    },
  };

  if (loading === true) {
    return <Loader />;
  }

  return (
    <div className='blogs'>
      <NewTable
        sectionObj={sectionObj}
        tableHeader={tableHeader}
        menuAction={menuActionObj}
        data={data}
        getData={getActivities}
        searchKey='activity_name'
        hasPagination={false}
      />
    </div>
  );
};
export default Activity;
