// TabBar.js

import React, { useEffect, useState } from "react";
import "./index.scss";

const TabBar = ({ tabs, initialTab, onTabChange, parentStyle }) => {
  const [activeTab, setActiveTab] = useState('');

  useEffect(() => {
    setActiveTab(initialTab?.name);
  }, [initialTab?.name]);

  const handleTabClick = (tab) => {
    setActiveTab(tab?.name);
    if (onTabChange) {
      onTabChange(tab);
    }
  };
  return (
    <div className={`${parentStyle}  TabBar`}>
      {tabs &&
        tabs?.map((tab) => (
          <div
            key={tab?.name}
            className={`tab-item ${
              activeTab === tab?.name ? "active" : "inactive"
            }`}
            onClick={() => handleTabClick(tab)}
          >
            {tab?.name === "English"
              ? `${tab?.name} (Default)`
              : activeTab === tab?.name
              ? tab?.name
              : `+ ${tab?.name}`}
          </div>
        ))}
    </div>
  );
};

export default TabBar;
