import React from 'react';
import './index.scss';

const TextArea = ({
  placeholder,
  rows,
  type,
  value,
  id,
  name,
  Tooltiptext,
  onChange,
  label,
  asterisk = false,
}) => {
  return (
    <div className='txtarea'>
      {label && (
        <label className='tooltip-on-hover'>
          {label}
          {asterisk && <span>*</span>}
        </label>
      )}
      <textarea
        id={id}
        rows={rows}
        placeholder={placeholder}
        value={value}
        type={type}
        onChange={onChange}
        name={name}
      />
      <div className='tooltip1'>{Tooltiptext}</div>
    </div>
  );
};

export default TextArea;
