import { useEffect, useState } from 'react';

import Message from 'utils/Message';
import Toast from 'utils/Toast';

import { addReffere, getReffere } from 'api/latest/ReferralsandRewards';
const useRefferee = () => {
  const [pageLoading, setPageLoading] = useState(false);

  const [refresh, setRefresh] = useState(false);
  const [values, setValues] = useState({
    refreeStatus: false,
    condition: '',
    signup_coins: null,
  });

  const getReffereeDetails = async () => {
    setPageLoading(true);
    let responseObj = await getReffere();
    setPageLoading(false);

    if (responseObj?.response.code === 200) {
      setValues({
        condition: responseObj?.data?.condition,
        signup_coins: responseObj?.data?.signup_coins,
        refreeStatus: responseObj?.data?.status === 'ACTIVE' ? true : false,
      });
    } else {
      if (responseObj?.message) {
        Toast.error(responseObj?.message);
      } else {
        Toast.error(Message.Error.COMMON_MESSAGE);
      }
    }
  };

  useEffect(() => {
    getReffereeDetails();
  }, [refresh]);

  const handleChangeRefree = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      setValues({
        ...values,
        [name]: value && parseInt(value),
      });
    }
  };
  const handleChangeRefreeToggle = () => {
    setValues({
      ...values,
      refreeStatus: !values.refreeStatus,
    });
  };

  const callAddOrUpdateRefree = async () => {
    let payload = {
      condition: values.condition,
      signup_coins: values.signup_coins,
      status: values.refreeStatus,
    };
    setPageLoading(true);
    let responseObj = await addReffere(payload);
    setPageLoading(false);
    if (responseObj?.response?.code === 200) {
      Toast.success(responseObj?.data?.message);
      setRefresh(!refresh);
    } else {
      Toast.error(
        responseObj?.response?.error
          ? responseObj?.response?.error
          : 'Something went wrong .'
      );
    }
  };

  return {
    values,
    pageLoading,
    handleChangeRefree,
    callAddOrUpdateRefree,
    handleChangeRefreeToggle,
  };
};

export default useRefferee;
