import Button from "app/components/button";
import "../index"
import { useState, useEffect } from "react"; 
import { useHistory, useLocation } from "react-router"; 
import NewInput from "app/components/NewInput"; 
import Toast from "app/utils/Toast";
import PageLoader from "app/components/PageLoader"; 
import WebinarCalendar from "app/components/WebinarCalendar";
import { createTipOfTheDay, updateTipOfTheDay} from "app/api/tipoftheday";
import RadioButton from "app/shared/components/RadioButton";
import DropdownOptions from "app/utils/DropdownOptions";
import moment from 'moment';
import CTAType from 'app/components/CTAType'

const CreateTipContainer = () => {
    let history = useHistory() 
    const location = useLocation();
    const [loader, setLoader] = useState(false)  
    const [selectedItem, setSelectedItem] = useState("ASTHMA");
    const [state, setState] = useState({
        tip_text: "",  
        cohort_type: "ASTHMA",
        tip_date: "", 
        cta_type: "INTERNAL", 
        url: "",
        internal_for: "",
        internal_url: "",
        profile_data: false,
        custom_object: "",
        redirection_id: "",

    })

    const [internalUrl, setInternalURL] = useState([]);
    const radioMenu = [
        {
             label: "Asthma",
             id: "1",
             value: "ASTHMA"
        },
        {
             label: "COPD",
             id: "2",
             value: "COPD"
        },
        {
           label: "AR",
           id: "3",
           value: "AR"
      },
      {
           label: "ILD",
           id: "4",
           value: "ILD"
      },
      {
           label: "Others",
           id: "5",
           value: "OTHER"
       }
   ]
    
    useEffect(() => { 
        let data = location.state;
        if(data){
            setSelectedItem(data.cohort_type)
            let newData = {
                tip_text:data.tip_text,  
                cohort_type: data.cohort_type,
                tip_date: data.tip_date, 
                cta_type: data.cta_type, 
                url: data.url,
                internal_for: data.internal_for,
                internal_url: data.internal_url,
                profile_data: data.profile_data,
                custom_object: data.custom_object,
                redirection_id: data.redirection_id,
              };
              setState(newData)
        }
        updateStates();
    }, [])


    const updateStates = () => {
        let internalUrl = []
        DropdownOptions.InternalUrl.map((item, i) => {
            if (item.value !== 'feed' && item.value !== 'content_detail' && item.value !== 'quiz' && item.value !== 'vital_activity' && item.value !== 'webinar_details' && item.value !== 'individual_quiz') {
                internalUrl.push(item)
            }
        }) 

        setInternalURL(internalUrl)
        
    }

    
    const handleTextChange = (e, index) => {
        if(e.target.name === 'tip_text'){
            if(e.target.value.length <= 150){
                setState({ ...state, [e.target.name]: e.target.value })
            }
            
        }else{
            setState({ ...state, [e.target.name]: e.target.value })
        }
          
      
    }

    const radioButtonSelected = (item) => { 
         setSelectedItem(item.value)
         setState({ ...state, cohort_type: item.value })
    }

    const onWebinarStartDateSelected = (date) => { 
        setState({ ...state, tip_date: date })
      };
   
    const handleSubmit = async() => {

        let totalData = location.state;
        let duplicate = false

        JSON.parse(totalData.totalData).forEach((item) => {
            
            if (item.cohort_type.toLowerCase().includes(state.cohort_type.toLowerCase()) && item.tip_date.includes(moment(state.tip_date).format('YYYY-MM-DD')) ) {
                if(location.state && location.state.id  !== item.id){  
                    duplicate = true
                }
            }
        });

        if(duplicate){
            Toast.error('Already tip for the day is created for the same day and same cohort');
        }else{

            let payload = {
                ...state, 
            }   
           payload.tip_date = moment(payload.tip_date).format('YYYY-MM-DD')
            setLoader(true)
            let responseObj
            if(location.state && location.state.id){   
                payload.id = location.state.id
                responseObj = await updateTipOfTheDay(payload)
            }else{
                responseObj = await createTipOfTheDay(payload)
            }
             
            if (
                responseObj &&
                responseObj.response &&
                responseObj.response.code === 200
            ) { 
                Toast.success(responseObj.response.alert[0].message);
                history.push("/tip-of-the-day") 
        
            } else {
                setLoader(false)
                Toast.error(responseObj.data && responseObj.data.msg?responseObj.data.msg:responseObj.response.alert[0].message);
            } 
        }

        
    }
    
    return (
        <div className="container">
            <h1 className="section-header__title">Create Tip</h1>
             
            <div className="userInput">
            <div className="content-selector">
               <h2>Select Cohort</h2>
               {radioMenu.map((item, key) => (
                    <RadioButton
                         id={item.id}
                         label={item.label}
                         isChecked={selectedItem == item.value ? true : false}
                         value={item.value}
                         onSelect={() => { radioButtonSelected(item) }}
                         name={item.label}
                    />
               ))}
            </div>
                <NewInput
                    label="Tip Text"
                    name={"tip_text"}
                    value={state.tip_text}
                    onChange={handleTextChange}
                    max={150}
                    showCharacterLength={true}
                />
                <div className="userFormArea">
                    <label>Detailed CTA Type</label>

                    <CTAType
                        cta_type={state.cta_type}
                        handleChange={(key, data) => {
                            if (key === 'url' && (data !== 'zoom_live_session' || data !== 'infinite_scroll' || data !== 'group_of_collection')) {
                                setState({ ...state, [key]: data, 'redirection_id': "" })

                            } else {
                                setState({ ...state, [key]: data })
                            }
                        }}
                        internalUrl={DropdownOptions.InternalUrl}
                        url={state.url}
                        handleTextChange={handleTextChange}
                        internal_for={state.internal_for}
                        internal_url={state.internal_url}
                        profile_data={state.profile_data}
                        custom_object={state.custom_object}
                        redirection_id={state.redirection_id}
                    />
                </div>
                <WebinarCalendar
                    onStartDateSelected={onWebinarStartDateSelected}
                    startDate={state.tip_date}
                    label='Tip Date'
                />
                 <div className="userFormArea">
                    <Button
                        name={"Save"}
                        onClick={handleSubmit}
                        isDisabled={!state.tip_text || !state.tip_date}
                    />
                    <label style={{ paddingLeft: "10px", cursor: "pointer" }}

                        onClick={  () => { history.goBack() }  }
                    >Cancel</label>
                </div>
                 
            </div>
            {loader && <PageLoader pageLoading={loader} />}
        </div>


    );
}

export default CreateTipContainer