import React from 'react';

// Components
import NewInput from 'components/NewInput';
import Button from 'components/button';

import { CloseBlackIcon } from 'constants/image-constants';

import './index.scss';

const QuizCheckbox = ({
  id,
  label,
  checked,
  onChange,
  handleOptionLabelChanged,
  handleAddOption,
  handleRemoveOption,
  isLast,
  showCloseMark,
}) => {
  const onOptionChange = (e) => {
    onChange && onChange(e);
  };

  const handleLabelChange = (e) => {
    handleOptionLabelChanged && handleOptionLabelChanged(e);
  };

  const addOptionClick = (e) => {
    handleAddOption && handleAddOption(e);
  };

  const removeOptionClick = (e) => {
    handleRemoveOption && handleRemoveOption(e);
  };

  return (
    <div className='check'>
      <div className='form-check'>
        <input
          id={id}
          className='form-check-input'
          type='checkbox'
          value=''
          onClick={onOptionChange}
          checked={checked}
        />

        <NewInput
          id={id}
          type='text'
          placeholder='Ex: Option goes here'
          name='title'
          value={label}
          onChange={handleLabelChange}
        />
      </div>
      {showCloseMark && (
        <img
          id={id}
          className='radio-btn_remove'
          src={CloseBlackIcon}
          alt='Remove'
          onClick={removeOptionClick}
        />
      )}
      {isLast === true && (
        <Button
          className='radio-btn_addoption'
          onClick={addOptionClick}
          type='submit'
          name='Add option'
        />
      )}
    </div>
  );
};

export default QuizCheckbox;
