import React from 'react';

// Pages
import BasePage from 'pages/BasePage';

import CreateBreatheFree from 'app/container/CreateBreathefree';

const BreatheFreeUserPage = () => {
  return (
    <BasePage>
      <CreateBreatheFree />
    </BasePage>
  );
};

export default BreatheFreeUserPage;
