import BasePage from 'pages/BasePage';
import RewardsAndReferral from 'app/container/RewardsAndReferral';

const RewardsMasterPage = () => {
  return (
    <>
      <BasePage>
        <RewardsAndReferral></RewardsAndReferral>
      </BasePage>
    </>
  );
};

export default RewardsMasterPage;
