import {
  addOrUpdateLanguageOrderAndStatus,
  getLanguages,
} from 'app/api/latest/Localisation/MasterLanguages/masterLanguages';
import { MyContext } from 'app/context/MyContext';

import Message from 'app/utils/Message';

import { useContext, useEffect, useState } from 'react';

import Toast from 'utils/Toast';

const useLanguagesForm = () => {
  const [pageLoading, setPageLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const { setlanguageFunc } = useContext(MyContext);

  useEffect(() => {
    getLanguagesList();
  }, []);

  const getLanguagesList = async (filter) => {
    setPageLoading(true);
    let response = {};
    response = await getLanguages();

    let newtemp = response?.data?.map((item, index) => {
      return {
        ...item,
        toggle: item.status === 'ACTIVE' ? true : false,
      };
    });

    let ressponsetempLanguages = [...newtemp];
    let ressponsetempLanguagesm = [...ressponsetempLanguages.slice(1)];
    setPageLoading(false);

    setSelectedItems(ressponsetempLanguagesm);

    if (response?.response?.code === 200) {
    } else {
      Toast.error(
        response?.data ? response?.data : Message?.Error?.COMMON_MESSAGE
      );
    }
  };

  const handleLanguageSubmit = async () => {
    setPageLoading(true);
    let response = {};

    let newtemp = selectedItems?.map((item, index) => {
      return {
        // ...item
        id: item.id,
        display_order: item.display_order,
        status: item.status,
      };
    });
    response = await addOrUpdateLanguageOrderAndStatus(newtemp);
    setPageLoading(false);
    if (response?.response?.code === 200 || response?.response?.code === 201) {
      Toast.success(Message?.Language?.UPDATED_SUCCESS);
      let newtempp = response?.data?.map((item, index) => {
        return {
          ...item,
          toggle: item?.status === 'ACTIVE' ? true : false,
          label: `+ ${item.name}`,
        };
      });
      setlanguageFunc(newtempp);
    } else {
      Toast.error(
        response?.data ? response?.data : Message?.Error?.COMMON_MESSAGE
      );
    }
  };

  const handleSelectedItemsChange = (newSelectedItems) => {
    const temp = newSelectedItems.map((item, index) => {
      return {
        ...item,
        display_order: index + 2,
      };
    });
    setSelectedItems(temp);
  };

  const handleResourseToggle = (id, itemindex) => {
    let newData = [...selectedItems];

    if (newData && newData.length > 0) {
      newData = newData.map((item, index) => {
        if (index === itemindex) {
          if (item.id !== null) {
            item.wasEdited = true;
          }
          item.toggle = !item.toggle;
          item.status = !item.toggle ? 'INACTIVE' : 'ACTIVE';
        }
        return item;
      });
      setSelectedItems(newData);
    }
  };

  return {
    handleResourseToggle,
    pageLoading,
    selectedItems,
    setSelectedItems,
    handleSelectedItemsChange,
    handleLanguageSubmit,
  };
};

export default useLanguagesForm;
