import RadioButton from 'app/shared/components/RadioButton';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import GroupTable from './Tables.js/Group';
import './index.scss';
// import PreferencesTable from "./Tables.js/Preferences";
import StoriesTable from './Tables.js/Stories';

const CmsDiscoverContainer = () => {
  const location = useLocation();
  const history = useHistory();
  const [selectedItem, setSelectedItem] = useState(
    location.state?.selected || '2'
  );

  const radioMenu = [
    // {
    //      label: "Preferences",
    //      id: "1",
    //      value: "1"
    // },
    {
      label: 'Stories',
      id: '2',
      value: '2',
    },
    {
      label: 'Groups of Collection & Collection',
      id: '3',
      value: '3',
    },
  ];
  const radioButtonSelected = (item) => {
    setSelectedItem(item.value);
  };
  return (
    <div className='discover__container'>
      <div className='content-selector'>
        <h2>Discover</h2>
        {radioMenu.map((item, key) => (
          <RadioButton
            id={item.id}
            label={item.label}
            isChecked={selectedItem == item.value ? true : false}
            value={item.value}
            onSelect={() => {
              history.replace();
              radioButtonSelected(item);
            }}
            name={item.label}
          />
        ))}
      </div>
      <div className='tables__discover'>
        {/* {selectedItem == "1" && <PreferencesTable />} */}
        {selectedItem == '2' && <StoriesTable />}
        {selectedItem == '3' && <GroupTable />}
      </div>
    </div>
  );
};

export default CmsDiscoverContainer;
