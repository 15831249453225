import React from 'react';

// Pages
import BasePage from 'pages/BasePage';

// Container
import Gamification from 'container/Gamification';

import { GamificationTableHeader } from 'constants/table-header-constants';

const FAQPage = () => {
  return (
    <BasePage>
      <Gamification tableHeader={GamificationTableHeader} />
    </BasePage>
  );
};

export default FAQPage;
