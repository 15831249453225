import { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import Loader from 'components/Loader';
import NewTable from 'components/NewTable';
import PageLoader from 'components/PageLoader';

// Constants
import { APP_CONSTANTS } from 'constants/app-constants';

// Utils
import Message from 'utils/Message';
import Toast from 'utils/Toast';

// API
import { deleteModule, getModule, updateModuleStatus } from 'api/faq';

const FAQ = ({ tableHeader }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [pageLoading, setPageLoading] = useState(false);

  const loadNames = (start, end, increase) => {
    setLoading(true);
    getModule(start, end).then((response) => {
      setLoading(false);
      if (response && response.tableData && response.error === false) {
        // console.log(response.tableData, 'tableData');
        setData({
          tableData: response.tableData,
        });
        // setFeedSummary(response.summary);
      }
    });
  };

  // const handleResponseCallback = (response) => {
  //   if (response && response.tableData && response.error === false) {
  //     // setFeedSummary(response.summary);
  //   }
  // };

  const callDeleteModule = async (data) => {
    setPageLoading(true);
    if (data && data.module_id) {
      let responseObj = await deleteModule(data.module_id);
      setPageLoading(false);
      if (responseObj?.response?.code === 200) {
        loadNames();
        Toast.success(Message.Faq.MODULE_DELETE_SUCCESS);
      } else {
        Toast.error(
          responseObj?.data ? responseObj?.data : Message?.Error?.COMMON_MESSAGE
        );
      }
    }
  };

  const callPublishOrUnpublishModuleAPI = async (data) => {
    setPageLoading(true);

    if (data && data?.module_id) {
      let payload = {};
      payload.publish = data?.status === 'ACTIVE' ? false : true;
      let responseObj = await updateModuleStatus(payload, data?.module_id);
      setPageLoading(false);
      if (responseObj?.response?.code === 200) {
        loadNames();
        Toast.success(Message.Faq.PUBLISH_UNPUBLISH_SUCCESS);
      } else {
        Toast.error(
          responseObj?.data ? responseObj?.data : Message?.Error?.COMMON_MESSAGE
        );
      }
    }
  };

  const menuActionObj = [
    {
      name: 'Edit',
      onClick: (e, data) => {
        history.push({
          pathname: `/faq/edit/${data?.module_id}`,
          state: {
            ...data,
          },
        });
      },
    },
    {
      name: 'Delete',
      onClick: (e, data) => {
        callDeleteModule(data);
      },
    },
    {
      name: 'Publish',
      type: 'publish',
      onClick: (e, data) => {
        callPublishOrUnpublishModuleAPI(data);
      },
    },
  ];

  const sectionObj = {
    sectionTitle: 'Module List',
    searchObj: {
      placeholder: 'Search',
      handleSearch: (e) => {
        console.log('Handle search: ', e);
      },
    },
    buttons: [
      {
        title: 'Create Module',
        handleBtnClick: (e) => {
          history.push(APP_CONSTANTS.CREATE_FAQ);
        },
      },
    ],
  };

  if (loading === true) {
    return <Loader />;
  }

  return (
    <div className='blogs'>
      <NewTable
        sectionObj={sectionObj}
        tableHeader={tableHeader}
        menuAction={menuActionObj}
        data={data}
        getData={getModule}
        searchKey='module_name'
        responseCallback={() => {}}
      />
      {pageLoading === true && <PageLoader pageLoading={pageLoading} />}
    </div>
  );
};

export default FAQ;
