// Components
import HeaderWithBackButton from 'components/HeaderWithBackButton';
import PageLoader from 'components/PageLoader';
import NumberInput from 'components/UI/NumberInput';
import validate from 'components/ValidateInfo';
import Button from 'components/button';
import Toggle from 'react-toggle';
import Dropdown from 'shared/components/Dropdown';
import './index.scss';
// API

// Constants
import BasePage from 'app/pages/BasePage';
import { APP_CONSTANTS } from 'constants/app-constants';
import useCreateCouponAndOffer from './useCreateCoins';

const CreateCoins = () => {
  const {
    handleChange,
    values,
    handleCreateFAQ,
    pageLoading,
    productFamilyList,
    toggleStatus,
    handleToggleChange,
  } = useCreateCouponAndOffer(validate);

  return (
    <BasePage>
      <div className='blogsForm'>
        <div className='blogsForm_content'>
          <HeaderWithBackButton
            title='Breathfree Coins'
            goTo={APP_CONSTANTS.BREATHFREE_COIN}
          />

          <Dropdown
            id={'product_family'}
            label='Product Family*'
            name='product_family'
            options={productFamilyList}
            placeholder='Select One'
            value={values.product_family}
            handleChange={handleChange}
          />
          <div className='cdropdown'> Discount Details*</div>
          <div className='discount_details_box'>
            <NumberInput
              label='Add Discount %'
              id='discount'
              placeholder='40%'
              name='discount'
              value={values.discount}
              onChange={handleChange}
            />
            <NumberInput
              label='Orders Above'
              id='orders_above'
              placeholder='Rs. 159'
              name='orders_above'
              value={values.orders_above}
              onChange={handleChange}
            />
            <NumberInput
              label='Max Discount Amount'
              id='maximum_discount_amount'
              placeholder='Rs. 80'
              name='maximum_discount_amount'
              value={values.maximum_discount_amount}
              onChange={handleChange}
            />
            <div className='d-block my-4'>
              <span className='me-2 d-inline-block'>Status*</span>
              <label className='d-inline-block mt-2'>
                <div className='d-inline-flex align-items-center'>
                  <div className='d-inline-flex text-danger'>OFF</div>
                  <div className='d-inline-flex mx-2'>
                    <Toggle
                      checked={toggleStatus}
                      icons={false}
                      onChange={handleToggleChange}
                    />
                  </div>
                  <div className='d-inline-flex text-green'>ON</div>
                </div>
              </label>
            </div>
          </div>

          <Button
            className='mt1'
            onClick={handleCreateFAQ}
            type='submit'
            name='Add'
          />
        </div>
        {pageLoading && <PageLoader pageLoading={pageLoading} />}
      </div>
    </BasePage>
  );
};

export default CreateCoins;
