import { API_CONSTANTS } from "app/constants/api-contants";
import * as dataAccess from "utils/ajax";
import Parsers from "utils/Parsers";
import Toast from "app/utils/Toast";
import ApiParser from "utils/ApiParser";

export const callLCPEducatorMapping = async () => {
  let responseObj = await dataAccess.get(
    API_CONSTANTS.GET_LCP_EDUCATOR_MAPPING
  );
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {

    return responseObj.data;
  } else {
    return [];
  }
};
