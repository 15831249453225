import React from 'react';

// Pages
import BasePage from 'pages/BasePage'; 
import CustomerOrderDetails from 'app/container/CustomerOrdersContainer/CustomerOrderDetails';

const CustomerDetails = () => {
  return (
    <BasePage>
      <CustomerOrderDetails />
    </BasePage>
  );
};

export default CustomerDetails;
