// Ajax
import * as dataAccess from 'utils/ajax';
import ApiParser from 'utils/ApiParser';

// Constants
import { API_CONSTANTS } from 'constants/api-contants';

import Parsers from 'utils/Parsers';

export const getPageOrder = async (page) => {
  let url = `${API_CONSTANTS.ORDER_PAGE}/?page=${page}`;
  let responseObj = await dataAccess.get(url);
  return ApiParser.parseHomeListing(responseObj);
};
export const updatePageOrder = async (incomingData) => {
  let payload = {
    data: {
      home_page_update: incomingData,
    },
  };
  return await dataAccess.post(API_CONSTANTS.POST_MEDICINE_HOMEPAGE, payload);
};
export const addFirstResouseCreatingBanner = async (incomingData) => {
  let data = {
    update_article: 0,
    article_id: 0,
    publish: 1,
    ...incomingData,
  };

  var formData = Parsers.ConvertObjectAsFormData(data);

  let payload = {
    data: formData,
  };

  return await dataAccess.upload(API_CONSTANTS.UPDATE_ARTICLE, payload);
};
export const getLatestBanner = async (data, lang) => {
  let url = `${API_CONSTANTS.GET_LATEST_BANNERS_VERSION3}?page=${data.page}&placement=${data.placement}&size=${data.size}&lang=${lang}`;

  let responseObj = await dataAccess.get(url);

  return responseObj;
  // return responseObj;
};

export const bannerPublishorUnpublish = async (banner_id, banner_status) => {
  var formData = Parsers.ConvertObjectAsFormData({
    banner_status: banner_status,
  });
  // var formData = Parsers.ConvertObjectAsFormData(item);

  let payload = {
    data: formData,
  };

  let url = `${API_CONSTANTS.GET_LATEST_BANNERS}/${banner_id}`;

  if (!url) {
    return {};
  }

  return await dataAccess.update(url, payload);
};

export const addBanners = async (incomingData, bannerId) => {
  let payloads = [];

  let initialval = bannerId !== null ? 0 : 1;
  if (incomingData && incomingData.length > 0) {
    for (let index = initialval; index < incomingData.length; index++) {
      const element = incomingData[index];
      var formData = Parsers.ConvertObjectAsFormData(element);

      payloads.push({
        data: formData,
      });
    }
  }
  if (payloads.length < 1) {
    return;
  }
  return await dataAccess.uploadAll(API_CONSTANTS.GET_LATEST_BANNERS, payloads);
};

export const updateBanners = async (incomingData, lang) => {
  console.log('');
  let payloads = [];
  if (incomingData && incomingData.length > 0) {
    incomingData.forEach((item) => {
      var formData = Parsers.ConvertObjectAsFormData(item);

      payloads.push({
        data: formData,
        item,
      });
    });
  }

  if (payloads.length < 1) {
    return;
  }
  let url = `${API_CONSTANTS.GET_LATEST_BANNERS_VERSION3}`;
  return await dataAccess.updateAllBannersResouces(url, payloads, lang);
};

export const addFirstBanner = async (incomingData) => {
  let data = {
    ...incomingData,
  };

  var formData = Parsers.ConvertObjectAsFormData(data);

  let payload = {
    data: formData,
  };

  const value = await dataAccess.upload(
    API_CONSTANTS.GET_LATEST_BANNERS,
    payload
  );

  return value.data.banner_id;
};
