// Pages
import FAQ from 'container/FAQ';
import BasePage from 'pages/BasePage';

import { FAQTableHeader } from 'constants/table-header-constants';

const FAQPage = () => {
  return (
    <BasePage>
      <FAQ tableHeader={FAQTableHeader} />
      {/* <CreateFaq /> */}
    </BasePage>
  );
};

export default FAQPage;
