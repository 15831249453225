import "./index.scss"
import NewInput from "app/components/NewInput";
import Button from "app/components/button";
import { useCreateForm } from "app/components/useCreateForm";
import { createTagsAPI, editTags } from "app/api/createContentAPI";
import Toast from "app/utils/Toast";
import { useHistory, useLocation } from "react-router";
import { useEffect } from "react";
const CreateTagsContainer = () => {
    const location = useLocation()
    const history = useHistory()
    const tagType = [
        {
            name: "ONBOARDING",
            label: "ONBOARDING"
        },
        {
            name: "PLATFORM_MADE",
            label: "PLATFORM MADE"
        }
    ]
    const submitDone = async () => {
        let responseObj;
        if (location.state) {
            responseObj = await editTags({ ...inputs, "tag_id": location.state.id })
        }
        else {
            responseObj = await createTagsAPI(inputs)
        }
        if (
            responseObj &&
            responseObj.response &&
            responseObj.response.code === 200
        ) {

            if (location.state) {
                Toast.success("Tags Edited successfully")
                history.push({
                    pathname: "/cms/tags",
                    state: { goto: location.state.comingFrom }
                })
            }
            else {
                Toast.success("Tags Created")
                history.push("/cms/tags")
            }
        } else {
            Toast.error("Unable To perform Tags operation")
        }

    }
    const { inputs, setInputs, handleInputChange, handleSubmit } = useCreateForm(submitDone)
    useEffect(() => {
        if (location.state) {
            setInputs({ "tag_name": location.state.tag_name, "tag_type": location.state?.tag_type })
        }

    }, [])
    return (
        <>
            <div className="container">
                <h1 className="section-header__title">Tags & Categories / Create Tag</h1>
                {/* <div className="content-container mg-top">
                    <div>
                        <Button
                            name="Bulk Upload"
                        />
                    </div>
                </div> */}
            </div>
            <div className="separator">
                <p>Tags -  Add Details</p>
            </div>
            <div className="userInput">
                <NewInput
                    label="Tag Name"
                    name={"tag_name"}
                    value={inputs.tag_name}
                    onChange={handleInputChange}
                />
            </div>
            <div className="dropdown-area" style={{ marginTop: "10px" }}>
                <label>Tag Type: {" "}</label>
                <select
                    name="tag_type"
                    className="custom-select" onChange={(e) => {
                        handleInputChange(e)
                    }}>
                    <option value="">Select</option>
                    {
                        tagType?.map((item, indx) => (
                            <option key={indx} value={item?.name}
                                selected={inputs.tag_type == item?.name ? true : false}
                            >{item?.label}

                            </option>
                        ))
                    }

                </select>
            </div>
            <div className="userFormArea">
                <Button
                    name={location?.state ? "Edit Tags" : "Create Tags"}
                    onClick={() => {
                        handleSubmit()
                    }}
                />
                <label style={{ paddingLeft: "10px", cursor: "pointer" }}
                    onClick={
                        () => {
                            history.goBack()
                        }
                    }
                >Cancel</label>
            </div>
        </>

    );
}

export default CreateTagsContainer;