import { useState } from 'react';

const useContentWebinarForm = (validate) => {
  const [count, setCount] = useState(1);
  const [hostValues, sethostsValues] = useState({
    hosts: [
      {
        hostId: 1,
        attachment: '',
        attachmentFile: null,
        displayOrder: '',
        name: '',
        speakerId: '',
        // attachmentFile: '',
        designation: '',
      },
    ],
    removedBanners: [],
  });

  const addWebinarSection = () => {
    const hosts = [...hostValues.hosts];
    if (hosts.length < 50) {
      hosts.push({
        id: count + 1,
        hostId: count + 1,
        speakerId: '',
        // isNew: true,
        // hostId: '',
        name: '',
        designation: '',
        attachment: '',
        attachmentFile: null,
        displayOrder: '',
      });
      setCount(count + 1);
      sethostsValues({
        ...hostValues,
        hosts,
        // removedBanners: [],
      });
    }
  };

  const removeHostSection = (ind) => {
    const hosts = [];
    let removedBanners = [hostValues.removedBanners];
    hostValues.hosts.forEach((banner, index) => {
      if (ind !== index) {
        hosts.push(banner);
      }
      if (ind === index && banner.hostId) {
        removedBanners.push(parseInt(banner.hostId));
      }
    });

    setCount(hosts.length);
    sethostsValues({
      ...hostValues,
      hosts,
      removedBanners,
    });
  };

  const updatehostValue = (obj) => {
    sethostsValues({
      ...obj,
    });

    setCount(obj.hosts.length);
  };

  const [error, setErrors] = useState({});

  const handleChange = (e) => {
    console.log('handleChange====>', e.target);
    if (e && e.target) {
      const { name, value } = e.target;
      sethostsValues({
        ...hostValues,
        [name]: value,
      });
    }
  };

  const OnhandleChange = (e) => {
    const valueCpy = { ...hostValues };
    valueCpy.hosts.map((element) => {
      if (`${element.id}` === `${e.target.id}`) {
        element[e.target.name] = e.target.value;
      }
      return element;
    });
    sethostsValues(valueCpy);
  };

  const handleSubmitting = (e) => {
    // e.preventDefault();
    setErrors(validate(hostValues));
  };

  return {
    handleChange,
    hostValues,
    handleSubmitting,
    error,
    sethostsValues,
    updatehostValue,
    OnhandleChange,
    addWebinarSection,
    removeHostSection,
    count,
  };
};

export default useContentWebinarForm;
