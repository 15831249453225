// MyContext.js
import { LocalStorage } from 'app/utils/storage';
import { createContext, useEffect, useState } from 'react';

const MyContext = createContext();

const MyContextProvider = ({ children }) => {
  const [languages, setLanguages] = useState([]);
  let locallangtemp = LocalStorage.get('Language', languages);
  useEffect(() => {
    if (locallangtemp) {
      setLanguages(JSON.parse(locallangtemp));
    }
  }, [locallangtemp]);

  const setlanguageFunc = (temp) => {
    LocalStorage.set('Language', temp);
    setLanguages(temp);
  };

  return (
    <MyContext.Provider value={{ languages, setlanguageFunc }}>
      {children}
    </MyContext.Provider>
  );
};

export { MyContext, MyContextProvider };
