// Components
import NewTable from 'components/NewDraggableTable';

// import { getActivities } from 'api/activity';

import {
  addOrUpdateDiagnosticPageOrder,
  getDiagnosticPageOrder,
} from 'app/api/latest/Diagonostics/diagonostics';

import { useHistory } from 'react-router-dom';

const DiagonosticLandingPageOrdering = ({ tableHeader }) => {
  const data = {};

  const history = useHistory();

  const DiagonosticPageOrder = async (tempData) => {
    const HomepageData = tempData.map((data, index) => {
      return {
        home_page_id: data.original.id,
        sorting_order: index + 1,
      };
    });
    await addOrUpdateDiagnosticPageOrder(HomepageData);
  };
  const sectionObj = {
    sectionTitle: 'Diagonostic-Order',
  };
  const menuActionObj = [
    {
      name: 'Edit',
      onClick: (e, data) => {
        data.section_title = sectionObj.sectionTitle;
        history.push({
          pathname: `/edit-diagonostic-order/${data.id}`,
          state: {
            ...data,
          },
        });
      },
    },
  ];

  return (
    <div className='blogs'>
      <NewTable
        sectionObj={sectionObj}
        tableHeader={tableHeader}
        menuAction={menuActionObj}
        data={data}
        HomePageOrder={DiagonosticPageOrder}
        getData={getDiagnosticPageOrder}
        hasPagination={false}
      />
    </div>
  );
};
export default DiagonosticLandingPageOrdering;
