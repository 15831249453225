import React, { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
// Components
import NewInput from 'components/NewInput';
import NumberInput from 'components/UI/NumberInput';
// Shared Components
import Dropdown from 'shared/components/Dropdown';
import RadioButton from 'shared/components/RadioButton';

// Constants
import { URLType } from 'constants/app-constants';

// Utils
import DropdownOptions from 'utils/DropdownOptions';

import './index.scss';
import { customObjectOptions } from 'app/json-data/dropdown-constant';

const CTAComponent = ({
  urlType,
  value,
  onChange,
  id,
  OnhandleChange,
  redirection_id,
  ctaValue,
  externalUrl,
  contentid,
  swappingOrder,
  isHide,
  index,
  urlCopy,
  redirection_idURL,
  internal_for,
  internal_url,
  profile_data,
  custom_object,
}) => {
  const [type, setType] = useState('');
  const [externalUrlVal, setExternalUrl] = useState();
  const [drop, setDrop] = useState('');
  const [isOpen, setOpen] = useState(true);
  const [internalUrl, setInternalURL] = useState([]);

  const [newValue, setNewValue] = useState('');
  const [selectedInternalUrl, setSelectedInternalUrl] = useState(null);
  const radioMenu = [
    {
         label: "True",
         id: "1",
         value: true
    },
    {
         label: "False",
         id: "2",
         value: false
    },
    
  ]
  // const customObjectOptions = [
  //   {
  //        label: "PulmoRehab",
  //        id: "1",
  //        value: 'PulmoRehab'
  //   }, 
  //   {
  //     label: "Exercise",
  //     id: "2",
  //     value: 'Exercise'
  //   }, 
    
  // ]
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      url: 'https://',
    },
  });

  useEffect(() => {
    (value === 'feed' ||
      value === 'webinar_details' ||
      value === 'quiz' ||
      value === 'breathing_exercise' ||
      value === 'vital_activity' || value === 'infinite_scroll' || 
      value === 'zoom_live_session') &&
      setDrop(true);
    // let internalUrl = [];
    // DropdownOptions.InternalUrl.map((item, i) => {
    //   // if (
    //   //   item.value !== 'feed' &&
    //   //   item.value !== 'content_detail' &&
    //   //   item.value !== 'quiz' &&
    //   //   item.value !== 'vital_activity'
    //   // ) {
    //     internalUrl.push(item);
    //   // }
    // }); 
    let internalUrl = DropdownOptions.InternalUrl.map(item => ({
      value: item.value,
      label: item.label
    }));
    setInternalURL(internalUrl);
    const initialSelected = internalUrl.find(option => option.value === value);
    setSelectedInternalUrl(initialSelected || null);

  }, [value]);

  useEffect(() => {
    if (urlType) {
      setType(urlType);
    }
  }, [urlType]);

  useEffect(() => {
    if (externalUrl) {
      setExternalUrl(externalUrl);
    }
  }, [externalUrl]);

  const isRadioButtonSelected = (data) => {
    if (type && type.toUpperCase() === data) {
      return true;
    }
    return false;
  };

  const handleRadioButtonClick = (e) => {
    onChange &&
      onChange({
        target: {
          id: e.target.id,
          name: 'urlType',
          value: e.target.value,
        },
      });
  };


  const handleInterURLChange = (e) => {
    setSelectedInternalUrl(e);
    
    const newValue = e ? e.value : '';
    onChange(e);
    onChange({
      target: {
        id: id,
        name: 'url',
        value: newValue,
      },
    });
    // if (e.target.value === 'feed' || e.target.value === 'webinar_details') {infinite_scroll
    if (
      newValue === 'feed' ||
      newValue === 'webinar_details' ||
      newValue === 'quiz' ||
      newValue === 'breathing_exercise' ||
      newValue === 'vital_activity' ||
      newValue === 'infinite_scroll' ||
      newValue === 'group_of_collection' ||
      newValue === 'individual_quiz' || 
      newValue === 'zoom_live_session'
    ) {
      setDrop(newValue);
    } else {
      setDrop('');
      // redirection_id('');
    }
    if (newValue === urlCopy) {
      onChange({
        target: {
          id: e.target.id,
          name: 'redirection_id',
          value: redirection_idURL,
        },
      });
    } else {
      onChange({
        target: {
          id: e.target.id,
          name: 'redirection_id',
          value: null,
        },
      });
    }

  };

  const handleCustoObjectChange = (e) => {
    onChange &&
      onChange({
        target: {
          id: e.target.id,
          name: e.target.name,
          value: e.target.value,
        },
      });
  };

  const handleURLChange = (e) => {
    setExternalUrl(e.target.value);
    onChange &&
      onChange({
        target: {
          id: e.target.id,
          name: 'externalUrl',
          value: e.target.value,
        },
      });
  };

  const handleInternalePWAChange = (e) => {
    onChange &&
      onChange({
        target: {
          id: e.target.id,
          name: e.target.name,
          value: e.target.value,
        },
      });
  };

  const radioButtonSelected = (item, id) => { 
    onChange &&
    onChange({
      target: {
        id: id,
        name: 'profile_data',
        value: item.value,
      },
    });

}

  let isExternalChecked = isRadioButtonSelected(URLType.EXTERNAL);
  let isExternalPWAChecked = isRadioButtonSelected(URLType.EXTERNAL_PWA);
  let isInternalChecked = isRadioButtonSelected(URLType.INTERNAL);
  let isInternalPWAChecked = isRadioButtonSelected(URLType.INTERNAL_PWA);
  let isNoCTA = isRadioButtonSelected(URLType.NO);
  return (
    <div className='row'>
      <div className='col-md-12'>
        <div className='radio' id={contentid}>
          <div className='radio_container'>
            <RadioButton
              id={id ? id : 'article_no'}
              label='No CTA'
              isChecked={isNoCTA}
              value={URLType.NO}
              onSelect={handleRadioButtonClick}
              name={`radio_${index}`}
            />
            <RadioButton
              id={id ? id : 'article_external'}
              label='Add External URL'
              isChecked={isExternalChecked}
              value={URLType.EXTERNAL}
              onSelect={handleRadioButtonClick}
              name={`radio_${index}`}
            />
            <RadioButton
              id={id ? id : 'EXTERNAL_PWA'}
              label='External Redirect(PWA)'
              isChecked={isExternalPWAChecked}
              value={URLType.EXTERNAL_PWA}
              onSelect={handleRadioButtonClick}
              name={`radio_${index}`}
            />
            <RadioButton
              id={id ? id : 'article_internal'}
              onSelect={handleRadioButtonClick}
              label='Add Internal URL'
              isChecked={isInternalChecked}
              value={URLType.INTERNAL}
              name={`radio_${index}`}
            />
             <RadioButton
              id={id ? id : 'internal_pwa'}
              onSelect={handleRadioButtonClick}
              label='Internal PWA'
              isChecked={isInternalPWAChecked}
              value={URLType.INTERNAL_PWA}
              name={`radio_${index}`}
            />
          </div>
          {!isHide && type !== URLType.NO && (
            <NewInput
              label='CTA Caption'
              id={id ? id : 'ctaTitle'}
              type='text'
              placeholder='Ex: Go to Medicine Reminder'
              name='ctaTitle'
              value={ctaValue}
              onChange={onChange}
            />
          )}
          {type !== URLType.NO && type !== URLType.INTERNAL_PWA && (
            <>
            <div className='radio_url'>
              {(type === URLType.EXTERNAL || type === URLType.EXTERNAL_PWA) && (
                <div>
                  <NewInput
                    label='CTA URL'
                    id={id}
                    name={'externalUrl'}
                    value={externalUrlVal}
                    placeholder='Ex: https://www.cipla.com'
                    className='form-block'
                    type='url'
                    onChange={handleURLChange}
                    Tooltiptext='This is a screen where the user will be taken on tap'
                  />
                </div>
              )}
              {type === URLType.INTERNAL && (
                // <Dropdown
                //   id={id}
                //   label='App Screen Name'
                //   name='url'
                //   options={internalUrl}
                //   placeholder='Select screen'
                //   value={value}
                //   handleChange={handleInterURLChange}
                //   // handleChange={onChange}
                // />
                <div>
                  <label htmlFor={id}>App Screen Name</label>
                  <Select
                    id={id}
                    name='url'
                    options={internalUrl}
                    placeholder='Select screen'
                    value={selectedInternalUrl}
                    onChange={handleInterURLChange}
                    isClearable
                    isSearchable
                  />
                </div>
              )}
              {drop ? (
                <NumberInput
                  label='Redirect Id'
                  id={id}
                  placeholder='enter value '
                  name='redirection_id'
                  value={redirection_id}
                  onChange={OnhandleChange}
                  // onChange={onChange}
                />
              ) : null}
            </div>
            
              </>
            )}
             
            <div>
              {type === URLType.INTERNAL_PWA && (
                <>
                <NewInput
                    label='For'
                    id={id}
                    name={'internal_for'}
                    value={internal_for}
                    placeholder='Enter for'
                    className='form-block'
                    type='text'
                    onChange={handleInternalePWAChange}
                  />
                  <NewInput
                    label='URL'
                    id={id}
                    name={'internal_url'}
                    value={internal_url}
                    placeholder='Enter URL'
                    className='form-block'
                    type='text'
                    onChange={handleInternalePWAChange}
                  />
                  <div className='radio_container'>
                    <div className="content-selector">
                      <h6>Profile Data</h6>
                      {radioMenu.map((item, key) => (
                          <RadioButton
                              key={key}
                              id={id}
                              label={item.label}
                              isChecked={profile_data === item.value ? true : false}
                              value={item.value}
                              onSelect={() => { radioButtonSelected(item,id) }}
                              // name={item.label}
                              name={`radio_${index}`}
                          />
                      ))}
                    </div>
                  </div>
                  <Dropdown
                    id={id}
                    label='Custom Object'
                    name='custom_object'
                    options={customObjectOptions}
                    placeholder='Select custom object'
                    value={custom_object}
                    handleChange={handleCustoObjectChange}
                    // handleChange={onChange}
                  />
                 
                </>
              )}
              </div>
          </div>
        </div>
    </div>
  );
};

export default CTAComponent;
