// Ajax
import * as dataAccess from 'utils/ajax';
import ApiParser from 'utils/ApiParser';

// Constants
import { API_CONSTANTS } from 'constants/api-contants';
import { LocalSessionKey } from 'constants/app-constants';
import { LocalStorage } from 'utils/storage';
import Parsers from 'utils/Parsers';

export const getFeed = async (start, filterPayload) => {
  let payload = {
    data: {
      page: start ? start : 1,
      limit: 10,
      ...filterPayload,
    },
  };

  let responseObj = await dataAccess.post(API_CONSTANTS.GET_ARTICLE, payload);
  return ApiParser.parseFeedListing(responseObj);
};

export const addOrUpdateFeed = async (incomingData) => {
  let data = {
    update_article: 0,
    article_id: 0,
    publish: 1,
    ...incomingData,
  };

  var formData = Parsers.ConvertObjectAsFormData(data);

  let payload = {
    data: formData,
  };

  return await dataAccess.upload(API_CONSTANTS.UPDATE_ARTICLE, payload);
};

export const deleteFeed = async (articleId) => {
  if (!articleId) {
    return;
  }

  let payload = {
    data: {
      article_id: articleId,
    },
  };
  return await dataAccess.del(API_CONSTANTS.DELETE_ARTICLE, payload);
};

export const publishOrUnpublishFeed = async (articleId, status) => {
  if (!articleId || !status) {
    return;
  }

  let data = {
    publish: status.toLowerCase() === 'inactive' ? 1 : 0,
    update_article: 1,
    article_id: articleId,
  };

  var formData = Parsers.ConvertObjectAsFormData(data);

  let payload = {
    data: formData,
  };
  // console.log(data, 'feeddata');

  return await dataAccess.upload(API_CONSTANTS.UPDATE_ARTICLE, payload);
};

export const getArticleId = async () => {
  let articleId = LocalStorage.get(LocalSessionKey.FEED_ID);
  if (articleId) {
    return articleId;
  }

  // Get the article id if not exists
  let responseObj = await dataAccess.post(API_CONSTANTS.UPDATE_ARTICLE);

  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    if (responseObj.data && responseObj.data.article_id) {
      articleId = responseObj.data.article_id;
    }
  }
  return articleId;
};

export const addMedia = async (articleId, attachment) => {
  let feedId = await getArticleId();
  if (!feedId) {
    feedId = articleId;
  }

  if (!feedId || !attachment) {
    return;
  }

  let data = {
    article_id: feedId,
    file: attachment,
  };

  var formData = Parsers.ConvertObjectAsFormData(data);

  let payload = {
    data: formData,
  };

  return await dataAccess.upload(API_CONSTANTS.ADD_MEDIA, payload);
};

export const getArticleDetail = async (articleId, accessToken) => {
  if (!articleId || !accessToken) {
    return;
  }

  let payload = {
    headers: {
      accesstoken: accessToken,
    },
    data: {
      article_id: parseInt(articleId),
    },
  };

  return await dataAccess.post(API_CONSTANTS.GET_ARTICLE_DETAIL, payload);
};

export const getActiveUsers = async () => {
  let ActiveUser = [];
  let responseObj = await dataAccess.get(API_CONSTANTS.ACTIVE_USER);
  console.log(responseObj, 'responseObj');
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    if (responseObj.data) {
      ActiveUser = responseObj.data;
    }
  }

  return ActiveUser;
};
