import React from 'react';
import useLanguagesForm from './useLanguagesForm';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import CustomDragabbleTable from './CustomDragabbleTable';
import { ContentOrderingTableHeader } from 'app/constants/table-header-constants';
import Button from 'app/components/button';
import PageLoader from 'app/components/PageLoader';

const LanguageMaster = () => {
  const {
    pageLoading,
    handleDragEnd,
    selectedItems,
    isSubmitClicked,
    handleSelectedItemsChange,
    handleLanguageSubmit,
    handleResourseToggle,
  } = useLanguagesForm();

  return (
    <div>
      <h1>Master Language List</h1>
      <div className='mt-5 mb-5'>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId='droppable' direction='vertical'>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <CustomDragabbleTable
                  data={selectedItems}
                  draggableTableHeader={ContentOrderingTableHeader}
                  isSubmitClicked={isSubmitClicked}
                  onSelectedItemsChange={handleSelectedItemsChange}
                  handleResourseToggle={handleResourseToggle}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div>
        <p>
          <b className='text-danger'>
            *Disclaimer: Please select only those languages for localisation for
            which we have a source file available on the mobile side.
          </b>
        </p>
      </div>
      <div>
        <Button onClick={handleLanguageSubmit} type='submit' name='Submit' />
        {/* <Button
          onClick={handleLanguageSubmitCLear}
          type='submit'
          name='Submit'
        /> */}
      </div>

      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </div>
  );
};

export default LanguageMaster;
