import CreateContentContainer from "app/container/CreateContentContainer";
import BasePage from "app/pages/BasePage";
import CreateCollection from "app/container/Collections/CreateCollection";
import EditCollectionContainer from "app/container/Collections/EditCollection";

const EditCollection = (props) => {
    console.log(props, 'propsprops')
    return (
        <BasePage>
            <EditCollectionContainer />
        </BasePage>
    );
}

export default EditCollection;