
import CreateStoryContainer from "app/container/CreateStoryContainer";
import BasePage from "app/pages/BasePage";

const CreateStories = () => {
    return (
        <>
            <BasePage>
                <CreateStoryContainer />
            </BasePage>
        </>
    );
}

export default CreateStories;