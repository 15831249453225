import React from 'react';

// Pages
import BasePage from 'pages/BasePage'; 
import CreateStreakRewardContainer from 'container/UserJourney/CreateStreakReward'
const CreateStreakReward = () => {
  return (
    <BasePage>
      <CreateStreakRewardContainer />
    </BasePage>
  );
};

export default CreateStreakReward;
