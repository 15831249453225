import React, { useState } from 'react';

// Container
import SectionHeader from 'container/SectionHeader';

// Components
import Table from 'components/table';

const NewTable = ({
  tableHeader,
  menuAction,
  ShareLink,
  sectionObj,
  pagination,
  type,
  getData,
  setRowData,
  isHide,
  searchKey,
  hasPagination,
  filterData,
  responseCallback,
}) => {
  const [currentPage] = useState(0);
  const [pageLimit] = useState(4);
  const [searchVal, setSearchVal] = useState('');

  const onSearch = (val) => {
    setSearchVal(val);
  };

  return (
    <div>
      <SectionHeader
        sectionObj={sectionObj}
        searchVal={searchVal}
        isHide={isHide}
        handleSearch={onSearch}
      />

      <Table
        tableHeader={tableHeader}
        currentPage={currentPage}
        pageLimit={pageLimit}
        menuAction={menuAction}
        pagination={pagination}
        type={type}
        setRowData={setRowData}
        getData={getData}
        searchVal={searchVal}
        ShareLink={ShareLink}
        searchKey={searchKey}
        hasPagination={hasPagination}
        filterData={filterData}
        responseCallback={responseCallback}
      />
    </div>
  );
};
export default NewTable;
