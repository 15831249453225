// Ajax
import * as dataAccess from 'utils/ajax';
import ApiParser from 'utils/ApiParser';

// Constants
import { API_CONSTANTS } from 'constants/api-contants';

// Get Lists of Items
export const getRecommendations = async () => {
  let responseObj = await dataAccess.get(API_CONSTANTS.GET_RECOMMENDATIONS);
  return ApiParser.parseRecommendationsListing(responseObj);
};

// Create Item
export const addRecommendations = async (incomingData) => {
  let payload = {
    data: {
      ...incomingData,
    },
  };
  return await dataAccess.post(API_CONSTANTS.POST_RECOMMENDATIONS, payload);
};

// Update Item
export const updateRecommendations = async (incomingData, id, lang) => {
  let payload = {
    data: incomingData,
    id: id,
  };
  let response = await dataAccess.put(
    `${API_CONSTANTS.GET_RECOMMENDATIONS_VERSION2}/${id}?lang=${lang}`,
    payload
  );
  return response;
};

// Get Prefill Data
export const getRecommendationsDetails = async (id, lang) => {
  let responseObj = await dataAccess.get(
    `${API_CONSTANTS.GET_RECOMMENDATIONS_VERSION2}/${id}?lang=${lang}`
  );
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data;
  } else {
    return [];
  }
};

// Get Vitals Dropdown List
export const getVitals = async (vitalsOptions) => {
  let payload = {
    data: {
      ...vitalsOptions,
    },
  };
  return await dataAccess.get(API_CONSTANTS.GET_VITALS, payload);
};

// Get Age Range Dropdown List
export const getAgeRange = async (ageRangeOptions) => {
  let payload = {
    data: {
      ...ageRangeOptions,
    },
  };
  return await dataAccess.get(API_CONSTANTS.GET_AGE_RANGES, payload);
};

// Get Vital Range Dropdown List
export const getVitalRange = async (vitalName, ageRange, vitalRangeOptions) => {
  let payload = {
    data: {
      ...vitalRangeOptions,
    },
  };
  return await dataAccess.get(
    API_CONSTANTS.GET_VITAL_RANGES.concat(
      `?vital_name=${vitalName}&age_range=${ageRange ? ageRange : ''}`
    ),
    payload
  );
};

// Get Cohort Dropdown List
export const getCohort = async (cohortOptions) => {
  let payload = {
    data: {
      ...cohortOptions,
    },
  };
  return await dataAccess.get(API_CONSTANTS.GET_COHORT, payload);
};

// Get Calculated Risk Level
export const getRiskLevel = async (
  vitalName,
  ageRange,
  vitalRange,
  riskLevel
) => {
  let payload = {
    data: {
      ...riskLevel,
    },
  };
  return await dataAccess.get(
    API_CONSTANTS.GET_RISK_LEVEL.concat(
      `?vital_name=${vitalName}&age_range=${ageRange}&vital_range=${vitalRange}`
    ),
    payload
  );
};

// Get CMS Content Table List
export const getCmsContent = async () => {
  let responseObj = await dataAccess.get(API_CONSTANTS.POST_CONTENT_CMS);
  const flatData = responseObj.data.flat();
  return ApiParser.parseRecommendContentListing(flatData);
};
