import "./index.scss";
import Search from "app/components/search";
import { useEffect, useState } from "react";
import Button from "app/components/button";
import CustomTable from './table';
import Pagination from "./pagination"; 
import { useHistory } from 'react-router-dom';
import PageLoader from "app/components/PageLoader"; 
import {getUserJourneyMilestoneList, deleteMilestoneById, updateUserJourneyMilestoneStatus } from "app/api/user-journey"
import SelectComp from "../select-tag";
import Toast from "app/utils/Toast";

const options = [
    { value: 10, label: 10, },
    { value: 20, label: 20, },
    { value: 30, label: 30, },
    { value: 40, label: 40, },
    { value: 50, label: 50, },
]

const MilestoneList = (tableHeader) => {
    const history = useHistory();

    const [values, setValues] = useState("");
    const [data, setData] = useState([]);
    const [totalData, setTotalData] = useState([]); 
    const [pageCount, setPageCount] = useState(1); 
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10); 
    const [loader, setLoader] = useState(false) 
 
    
    
    useEffect(() => {
        getContentDataHandler();
    }, []);

    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    let currentData = data.slice(indexOfFirstData, indexOfLastData)

    async function getContentDataHandler() {
        const dataArr = await getUserJourneyMilestoneList()
        let updatedData =[]
        dataArr.forEach((item) => {  
            updatedData.push(  {
                isDisabled:true,
                ...item
            })
            
       });
        setData(updatedData);
        setTotalData(updatedData)
        let count = Math.ceil(updatedData.length / dataPerPage);
        setPageCount(count)
        setLoader(false)
    }

    function inputChangeHandler(searchVal) {
        if (searchVal.trim() === "") {
            setValues(searchVal.toLowerCase());
            // getContentDataHandler()
            setData(totalData)
            return;
        }
        const filteredData = []
        totalData.forEach((item) => {
            
            if (item.title.toLowerCase().includes(searchVal.toLowerCase())) {
                filteredData.push(item);
            }
        });
        setData(filteredData);
        setValues(searchVal);
    };

    function paginationHandler(page) {
        setCurrentPage(page);
    }
 
    const handleDelete = async(data) => { 
        let text = 'Are you sure, want to delete';
        if (window.confirm(text) == true) {
            setLoader(true)
            await deleteMilestoneById(data.id)
            getContentDataHandler()
        }  

   }

   function changeHandler(event) {
        const pageLength = event.target.value
        setDataPerPage(pageLength);
        let count = Math.ceil(data.length / pageLength);
        setPageCount(count)
    }

   const handleInputChangeHandler = (key,e, index)  => {
        let tempData = []
        data.forEach((val, i) => {
            if(index === i ){
            let temp = val
            val[key] =   Number(e.target.value) ?  Number(e.target.value) : ''
            tempData.push(temp)

            }else{
                tempData.push(val)

            }
        });
        setData(tempData) 

    }
    const handleEdit = async (item, key, index) => { 
        if(key ==='Edit'){
            let tempData = []
            data.forEach((val, i) => {
                if(index === i ){
                let temp = val
                val['isDisabled'] = false
                tempData.push(temp)
                }else{
                    tempData.push(val)
                }
            });
            setData(tempData)
        } 
       
       }

    const handlePublishUnpublish = async(item) => {
        
     let text = `Are you sure, want to ${item.status === "ACTIVE"? "Unpublish":"Publish"}`;
    if (window.confirm(text) == true) {
        if(item){
            setLoader(true) 
            let payload = { 
                id : item.id,
                status: item.status === "ACTIVE"? "INACTIVE": "ACTIVE", 
            } 
          
            let responseObj = await updateUserJourneyMilestoneStatus(payload)
    
            if (
                responseObj &&
                responseObj.response &&
                responseObj.response.code === 200
            ) {
                Toast.success(responseObj.response.alert[0].message);
                getContentDataHandler()
    
            } else {
                setLoader(false)
                Toast.error(responseObj.data?responseObj.data.msg: responseObj.response.alert[0].message?responseObj.response.alert[0].message : '');
            }
            setLoader(false)
        }
    }
    
    }

    return (<> 
        <div className="cms-content__container">
            <h4>Milestones</h4>
             
            <div className="content-container__actionBox">
                <div className="content-container__filterSearch">
                    <Search
                        value={values}
                        placeholder="Search"
                        className={""}
                        onChange={inputChangeHandler}
                    /> 
                </div>
                <Button 
                onClick={() => history.push('/userjourney/milestone/create')} 
                className="content-container__filter-btn">Create Milestone</Button>
            </div>
            <div className="content-container__perPage">
                Show
                <SelectComp
                    options={options}
                    onChangeHandler={changeHandler}
                />
                entries
            </div>

            <div className="content-container__table">
                <CustomTable
                    data={currentData}
                    tableHeader={tableHeader.tableHeader}
                    handleDelete={handleDelete}
                    handleInputChangeHandler={handleInputChangeHandler}
                    handleEdit={handleEdit}
                    handlePublishUnpublish={handlePublishUnpublish}
                />
            </div>

            <div className="content-container__pagination">
                <Pagination
                    pages={pageCount}
                    onChange={paginationHandler}
                />
            </div>
        </div>
        {loader && <PageLoader pageLoading={loader} />}
    </>

    );
}

export default MilestoneList