import "./index.scss";
import Search from "app/components/search";
import { useEffect, useState } from "react";
import Button from "app/components/button";
import Toast from "app/utils/Toast";
import CustomTable from './table';
import Pagination from "./pagination";
import SelectComp from "./select-tag";
import { useHistory } from 'react-router-dom';
import ArticleModal from "app/components/ArticleModal";
import { getWidgetList, deleteWidget, updateWidgetStatus } from 'app/api/widgets'
import PageLoader from "app/components/PageLoader";
const options = [
    { value: 10, label: 10, },
    { value: 20, label: 20, },
    { value: 30, label: 30, },
    { value: 40, label: 40, },
    { value: 50, label: 50, },
]

const CustomerOrdersContainer = (tableHeader) => {
    const history = useHistory();

    const [values, setValues] = useState("");
    const [data, setData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [pageCount, setPageCount] = useState(1); 
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10); 
    const [loader, setLoader] = useState(false) 
 
    useEffect(() => {
        getContentDataHandler();
    }, []);

    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    let currentData = data.slice(indexOfFirstData, indexOfLastData)

    async function getContentDataHandler() { 
        let dataArr;
        setLoader(true);
        // dataArr = await getWidgetList(); 
        dataArr=[
            {
                orderId:'110964',
                orderDate: new Date(),
                customenrNAme:'Satyajit Helwade',
                city:'Aurangabad',
                pincode:'431005',
                orderStatus:'Package Delivered'

            },
            {
                orderId:'110119',
                orderDate: new Date(),
                customenrNAme:'Santosh',
                city:'Bangaluru',
                pincode:'560037',
                orderStatus:'Partner pending'

            }
        ]

        setTotalData(dataArr)
        setData(dataArr);
        setLoader(false); 
        let count = Math.ceil(dataArr.length / dataPerPage)
        setPageCount(count)
        // if (
        //     dataArr &&
        //     dataArr.response &&
        //     dataArr.response.code === 200
        // ) {
        //     if(dataArr.data){
        //         setTotalData(dataArr.data)
        //         setData(dataArr.data);
        //         let count = Math.ceil(dataArr.data.length / dataPerPage);
        //         setPageCount(count)
        //     }
        //     setLoader(false)

        // } else {
        //     setLoader(false)
        //     Toast.error(dataArr.data && dataArr.data.msg ? dataArr.data.msg : dataArr.response.alert[0].message);
        // }
       
    }

    function changeHandler(event) {
        const pageLength = event.target.value
        setDataPerPage(pageLength);
        let count = Math.ceil(data.length / pageLength);
        setPageCount(count)
    }

    function inputChangeHandler(searchVal) {
        if (searchVal.trim() === "") {
            setValues(searchVal.toLowerCase()); 
            setData(totalData);
            let count = Math.ceil(totalData.length / dataPerPage);
            setPageCount(count)
            return;
        }
        const filteredData = []
        totalData.forEach((item) => { 
            let tempvalue = searchVal.toLowerCase()
            if (item.customenrNAme.toLowerCase().includes(tempvalue)) {
                filteredData.push(item);
            }
        });
        let count = Math.ceil(filteredData.length / dataPerPage);
        setPageCount(count)

        setData(filteredData);
        setValues(searchVal);
    };

    function paginationHandler(page) {
        setCurrentPage(page);
    }

    const handleDelete = async(data) => { 
        let text = 'Are you sure, want to delete';
        if (window.confirm(text) == true) {
            setLoader(true)
            await deleteWidget(data.widget_id)
            getContentDataHandler()
        } 
   }

   const handlePublishUnpublish = async(data) => {
    if(data){
        setLoader(true)
        let payload = {
            widget_status: data.status === "ACTIVE"? "INACTIVE": "ACTIVE",
            widget_id: data.widget_id
        } 
        await updateWidgetStatus(payload)
        getContentDataHandler()
    }

   }
    return (<> {showFilter && (
        <ArticleModal
            modalCloseClicked={() => setShowFilter(false)}
            filterApplyClicked={() => { }}
            ResetFilterCicked={() => { }}
            handleChange={() => { }}
            values={""}
            error={""}
        />
    )}
        <div className="cms-content__container">
            <h4>Customer Orders</h4>
            <div className="content-container__actionBox">
                <div className="content-container__filterSearch">
                    <Search
                        value={values}
                        placeholder="Search"
                        className={""}
                        onChange={inputChangeHandler}
                    /> 
                </div>
                <Button 
                onClick={() => history.push({
                    pathname: `/customer-orders/create`,
                })} 
                className="content-container__filter-btn">Create Order</Button>
            </div> 
            <div className="content-container__perPage">
                Show
                <SelectComp
                    options={options}
                    onChangeHandler={changeHandler}
                />
                entries
            </div>
            <div className="content-container__table">
                <CustomTable
                    data={currentData}
                    tableHeader={tableHeader.tableHeader}
                    handleDelete={handleDelete}
                    handlePublishUnpublish={handlePublishUnpublish}
                />
            </div>

            <div className="content-container__pagination">
                <Pagination
                    pages={pageCount}
                    onChange={paginationHandler}
                />
            </div>
        </div>
        {loader && <PageLoader pageLoading={loader} />}
    </>

    );
}

export default CustomerOrdersContainer