import RadioButton from "app/shared/components/RadioButton"; 
import Button from "app/components/button";
import "./index.scss"
import { useState, useEffect } from "react"; 
import { useHistory, useLocation } from "react-router";
import { useParams } from "react-router-dom"; 
import NewInput from "app/components/NewInput"; 
import Toast from "app/utils/Toast";
import PageLoader from "app/components/PageLoader"; 
import  {imageSizeValidation}  from 'utils/Parsers' 
import * as QueryString from 'query-string';
import TabBar from 'app/components/NewDesign/TabBar';
import { getExercisesList} from "app/api/exercises";
import { getCmsContent } from "app/api/cms-content";
import { createTask, getTaskDetailsByID, updateTaskDetail,getLocalisationDetailsforExercise,updateTaskLocalisation } from "app/api/user-journey";
import { CloseBlackIcon } from 'app/constants/image-constants';
import useTabBarOptions from 'app/utils/useTabbarOptions';
const CreateTaskContainer = () => {
    const location = useLocation()
    const params = useParams();
    const [loader, setLoader] = useState(false)
    const [animatedExercise, setAnimatedExercise] = useState(false)
    const [exerciseList, setExerciseList] = useState([]);
    const [contenntList, setContenntList] = useState([]);
    const [thumbnailImage, setThumbnailImage] = useState(null);

    const [selectedItem, setSelectedItem] = useState("")
    const [edit, setEdit] = useState(false)
    const [activeTab, setActiveTab] = useState({
        id: '1',
        name: 'English',
        short_name: 'en',
        display_order: 1,
        status: 'ACTIVE',
        value: 'English',
      });
      const [disableFields, setDisableFields] = useState(false);
      const { LanguageOptions } = useTabBarOptions();
    const [state, setState] = useState({
        title: "",
        sub_title: "",  
        image_url: "", 
        exercise_id:"", 
        content_id:"",
        streak_day:"",
        exercise_enum: "",
        exercise_level: "",

    })
    let history = useHistory() 
    useEffect(() => {

       if(params.id){
            getTaskDetails(params.id)
       }else{
        setSelectedItem('Exercise')
        getDropdownData('Exercise')

       }
        
    }, [])
    useEffect(() => {
        if (params.id) {
            updatedStateValue()
          }
    }, [activeTab?.name])
    
    
const updatedStateValue =async()=>{
    const resp = await getLocalisationDetailsforExercise(params.id, activeTab?.short_name);
    if (resp && resp.response && resp.response.code === 200){
        let homePageDetails = resp.data;
        if(homePageDetails){
            if (activeTab?.name === 'English') {
                setState({
                    title: homePageDetails.title,
                    sub_title: homePageDetails.sub_title,  
                    image_url: homePageDetails.image_url, 
                    exercise_id:homePageDetails.exercise_id?homePageDetails.exercise_id:"", 
                    content_id:homePageDetails.content_id?homePageDetails.content_id:"",
                    streak_day:homePageDetails.streak_day,
                    task_id:homePageDetails.task_id,
                    cohort_type:homePageDetails.cohort_type,
                    exercise_enum: homePageDetails.exercise_enum,
                    exercise_level: homePageDetails.exercise_level
                })
                
            }else{
                setState((prev) => ({
                    ...prev,
                    title: homePageDetails.title ? homePageDetails.title: '',
                    sub_title: homePageDetails.sub_title ? homePageDetails.sub_title: '',
                  }));
            }
        }
    }
}
    const getDropdownData = async (type) => { 
        if(type === 'Exercise' ){
            const dataArr = await getExercisesList()
                let temp= []
                dataArr.map((item,i) => {
                    temp.push({
                        label:item.id + '-' +item.title,
                        value:item.id,
                        ...item
                    })
                    setExerciseList(temp)
                })

        }else{
            const dataArr = await getCmsContent()
            let temp= []
            dataArr.map((item,i) => {
                temp.push({
                    label:item.id + '-' +item.title,
                    value:item.id,
                    ...item
                })
                setContenntList(temp)
            })
        }

    }

    const getTaskDetails = async(id) => {
        const taskDetail = await getTaskDetailsByID(id) 
        setState({
            title: taskDetail.title,
            sub_title: taskDetail.sub_title,  
            image_url: taskDetail.image_url, 
            exercise_id:taskDetail.exercise_id?taskDetail.exercise_id:"", 
            content_id:taskDetail.content_id?taskDetail.content_id:"",
            streak_day:taskDetail.streak_day,
            task_id:taskDetail.task_id,
            cohort_type:taskDetail.cohort_type,
            exercise_enum: taskDetail.exercise_enum,
            exercise_level: taskDetail.exercise_level
        })

        if(taskDetail.image_url){
            let image_url = `${process.env.REACT_APP_S3_BUCKET_URL}/${taskDetail.image_url}`;
            setThumbnailImage(image_url)
        }
       

        if(taskDetail.exercise_type === "SYSTEM_EXERCISE"){
            setAnimatedExercise(true)
        }

        if(taskDetail.content_id){
            // radioButtonSelected({
            //     label:"Content", value:"Content"
            // })
            setSelectedItem('Content')
            getDropdownData('Content')


        } else{
            setSelectedItem('Exercise')
            getDropdownData('Exercise')

        }

    }

    const radioMenu = [
        {
            label: "Exercise",
            id: "Exercise",
            value: "Exercise"
        },
        {
            label: "Content",
            id: "Content",
            value: "Content"
        },
        
    ]

    const exerciseEnumOptions = [
        {
            label: "Get Fitter Lungs",
            id: "get_fitter_lungs",
            value: "get_fitter_lungs"
        },
        {
            label: "Relax Your Lung",
            id: "relax_your_lung",
            value: "relax_your_lung"
        },
        {
            label: "Cleanse Your Lungs",
            id: "cleanse_your_lungs",
            value: "cleanse_your_lungs"
        },
        {
            label: "Strengthen Your Lungs",
            id: "strengthen_your_lungs",
            value: "strengthen_your_lungs"
        },
        
    ]

    const exerciseLevelOptions = [
        {
            label: "BEGINNER",
            id: "BEGINNER",
            value: "BEGINNER"
        },
        {
            label: "INTERMEDIATE",
            id: "INTERMEDIATE",
            value: "INTERMEDIATE"
        },
        {
            label: "ADVANCED",
            id: "ADVANCED",
            value: "ADVANCED"
        }
        
    ]
    const radioButtonSelected = async(item) => { 
        setSelectedItem(item.value)
        if(item.value === 'Content'){
            const dataArr = await getCmsContent()
            let temp= []
            dataArr.map((item,i) => {
                temp.push({
                    label:item.id + '-' +item.title,
                    value:item.id,
                    ...item
                })
                setContenntList(temp)
            })
            setState({ ...state, exercise_id: "" })

        }else{
            // if(selectedItem === 'Exercise'){
                const dataArr = await getExercisesList()
                    let temp= []
                    dataArr.map((items,i) => {
                        temp.push({
                            label:items.id + '-' +items.title,
                            value:items.id,
                            ...items
                        })
                        setExerciseList(temp)
                    })
    
            // }
            // getDropdownData()
            setState({ ...state, content_id: "" })
        }
    }

    const handleTextChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleMediaUpload = (e) => {
        let file = e.target.files[0] 
        if ((file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') && imageSizeValidation(e)) {
            // Toast.error(ValidationMessage.IMAGE_SIZE)
            return
        }else{
            let reader = new FileReader();
            reader.onloadend = function () {
              setThumbnailImage(reader.result);
            };
            reader.readAsDataURL(file);
            setState({ ...state, [e.target.name]: file })
            Toast.success(file?.name + " uploaded")
        }
        
    }
    const handleSubmit = async () => {
   
        let payload = {
            ...state,  
        }  
        setLoader(true)
        let responseObj
        if(params.id){ 
            payload.id = params.id;
            if (activeTab?.name === 'English') {
            responseObj = await updateTaskDetail(payload)
            }else{
                let localisationPayload=payload
                  responseObj = await updateTaskLocalisation(
                    localisationPayload,
                    activeTab?.short_name
                  );
            }
        }else{
            payload.cohort_type=QueryString.parse(location.search).key
            responseObj = await createTask(payload)
        }
         
        if (
            responseObj &&
            responseObj.response &&
            responseObj.response.code === 200
        ) { 
            Toast.success(responseObj.response.alert[0].message);
            history.push("/userjourney") 
    
        } else {
            setLoader(false)
            Toast.error(responseObj.data && responseObj.data.msg?responseObj.data.msg:responseObj.response.alert[0].message);
        }
    
       }
       const handleTabChange = (val) => {
        setActiveTab(val);
        if (val?.name === 'English') {
          setDisableFields(false);
        } else {
          setDisableFields(true);
        }
      };
    return (
        <div className="container">
            <h1 className="section-header__title">User Journey</h1>
            <div className="mt-5">
            {params.id ? (
          <div className='col-md-4 d-flex'>
            <b>Select Language</b>
          </div>
        ) : null}
        {params.id ? (
          <TabBar
            tabs={LanguageOptions}
            initialTab={activeTab}
            onTabChange={handleTabChange}
            parentStyle={'service-tab-style'}
          />
        ) : null}
        </div>

            <div className="content-container">
                <div className="content-selector">
                    <label className="head-label">Select Type</label>
                    {radioMenu.map((item, key) => (
                        <RadioButton
                            disabled={edit}
                            id={item.id}
                            label={item.label}
                            isChecked={selectedItem == item.value ? true : false}
                            value={item.value}
                            onSelect={() => { radioButtonSelected(item) }}
                            name={item.label}
                        />
                    ))}

                </div>
            </div>
            <div className="separator">
                <p>{selectedItem === 'Exercise' ? 'Add Exercise' : 'Add Content' }</p>
            </div>
            <div className="userInput">
                <div className="userFormArea horizontal-align">
                    <div className="dropdown-area">
                       {selectedItem === 'Exercise' ?  
                        <div className="row">
                            <div className="col-md-4">
                                <label >Select Exercise</label> 
                            </div>
                            <div className="col-md-8">
                                <select
                                name="exercise_id" 
                                className="custom-select w-100" onChange={(e) => {
                                    const findIndex = exerciseList.findIndex((item) => item.value === Number(e.target.value));
                                    if(exerciseList[findIndex].exercise_type === "ANIMATED_EXERCISE"){
                                        setAnimatedExercise(true)
                                        setState({ ...state, exercise_id: e.target.value })
                                    }else{
                                        setAnimatedExercise(false)
                                        setState({ ...state, exercise_id: e.target.value, exercise_enum: "", exercise_level: "" })
                                    }
                                    // const temp = exerciseList.find(({ value }) => value === e.target.value); 
                                    
                                }}
                                disabled={disableFields}
                                >
                                    <option value="">Select</option>
                                    {
                                        exerciseList?.map((item, indx) => (
                                            <option key={indx}
                                                value={item.value}
                                                item={item}
                                                selected={state.exercise_id == item.value ? true : false}
                                            >
                                                {item.label}</option>
                                        ))
                                    }

                                </select>
                            </div>
                        </div>
                        :
                        <div className="row">
                            <div className="col-md-4">
                                <label >Select Content ID</label> 
                            </div>
                            <div className="col-md-8">
                                <select
                                name="content_id" 
                                className="custom-select w-100" onChange={(e) => {
                                    setState({ ...state, content_id: Number(e.target.value) })
                                }}
                                disabled={disableFields}
                                >
                                    <option value="">Select</option>
                                    {
                                        contenntList?.map((item, indx) => (
                                            <option key={indx}
                                                value={item.value}
                                                selected={state.content_id == item.value ? true : false}
                                            >
                                                {item.label}</option>
                                        ))
                                    }

                                </select>
                            </div>
                        </div>
                        }
                    </div>  
                </div>
                {animatedExercise && 
                <div className="userFormArea horizontal-align">
                    <div className="dropdown-area">
                        <div className="row">
                            <div className="col-md-4">
                                <label>Exercise Type</label> 
                            </div>
                            <div className="col-md-8">
                                <select
                                name="exercise_id" 
                                className="custom-select w-100" onChange={(e) => {
                                    setState({ ...state, exercise_enum: e.target.value })
                                }}
                                disabled={disableFields}
                                >
                                    <option value="">Select</option>
                                    {
                                        exerciseEnumOptions?.map((item, indx) => (
                                            <option key={indx}
                                                value={item.value}
                                                selected={state.exercise_enum == item.value ? true : false}
                                            >
                                                {item.label}</option>
                                        ))
                                    }

                                </select>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-4">
                                <label>Exercise Level</label> 
                            </div>
                            <div className="col-md-8">
                                <select
                                name="exercise_id" 
                                className="custom-select w-100" onChange={(e) => {
                                    setState({ ...state, exercise_level: e.target.value })
                                }}
                                disabled={disableFields}
                                >
                                    <option value="">Select</option>
                                    {
                                        exerciseLevelOptions?.map((item, indx) => (
                                            <option key={indx}
                                                value={item.value}
                                                selected={state.exercise_level == item.value ? true : false}
                                            >
                                                {item.label}</option>
                                        ))
                                    }

                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                }
                <div className="userFormArea horizontal-align">
                    <div className="col">
                        <div>
                            {
                                state.image_url && <p className="cancel" onClick={(e) => {
                                    setThumbnailImage(null); 
                                    setState({ ...state, image_url: "" })
                                }}>Cancel Thumbnail</p>
                            }
                        </div>
                        <div className="row">
                            <div className="col-md-2">
                                <label>Thumbnail</label>
                            </div>
                            <div className="col-auto">
                                <input type="file" id="image_url"
                                    style={{ display: "none" }}
                                    name='image_url'
                                    onChange={(e) => {
                                        handleMediaUpload(e)
                                    }}
                                    disabled={disableFields}
                                />

                                <div className="upload-label">
                                    
                                    <Button
                                        onClick={() => {
                                            const image = document.getElementById("image_url")
                                            image.click()
                                        }}
                                        isDisabled={state.image_url}
                                        type='button'
                                        name={state.image_url != "" ? "Uploaded" : 'Upload Thumbnail Image'}

                                    />
                                </div>
                            </div>
                            <div className="col-auto">
                                {thumbnailImage && (
                                    <div className='attachment_img_wrapper'>
                                        <img src={thumbnailImage} alt='sample' className='feed__attachment' />
                                        <div className='attachment_img_wrapper_close'>
                                            <img src={CloseBlackIcon} alt='Remove' onClick={(e) => {
                                                setThumbnailImage(null); 
                                                setState({ ...state, image_url: "" })
                                            }}/>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div> 
                    </div>
                  
                </div>
                <NewInput
                    label="Task Title"
                    name={"title"}
                    value={state.title}
                    onChange={handleTextChange}
                />
                <NewInput
                    label="Task Sub Title"
                    name={"sub_title"}
                    value={state.sub_title}
                    onChange={handleTextChange}
                />
                 <NewInput
                    label="Streak Day"
                    name={"streak_day"}
                    value={state.streak_day}
                    onChange={handleTextChange}
                    disabled={disableFields}
                />
                 
                 <div className="userFormArea">
                    <Button
                        name={"Save"}
                        onClick={handleSubmit}
                        // isDisabled={!state.video_thumbnail || !state.endless_scroll_media || !state.title}
                    />
                    <label style={{ paddingLeft: "10px", cursor: "pointer" }}

                        onClick={  () => { history.goBack() }  }
                    >Cancel</label>
                </div>
            </div>
            {loader && <PageLoader pageLoading={loader} />}
        </div>


    );
}

export default CreateTaskContainer