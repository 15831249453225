import CoursesCreateContainer from "app/container/CoursesCreationContainer/CoursesCreate";
import BasePage from "app/pages/BasePage";

const CoursesCreate = () => {
    return (
        <BasePage>
            <CoursesCreateContainer />
        </BasePage>
    );
}

export default CoursesCreate;