import * as dataAccess from 'utils/ajax';
import ApiParser from 'utils/ApiParser';
import Parsers from 'utils/Parsers';
import { API_CONSTANTS } from 'constants/api-contants';

export const getDidYouKnowVideo = async (start) => {
  let payload = {
    data: {
      page: start ? start : 1,
      limit: 10,
    },
  };
  let responseObj = await dataAccess.post(
    API_CONSTANTS.GET_DID_YOU_KNOW,
    payload
  );
  return ApiParser.parseDidYouKnowListing(responseObj);
};

export const addOrUpdateDidYouKnowVideo = async (incomingData) => {
  let data = {
    update_video: incomingData?.video_id ? 1 : 0,
    publish: 1,
    ...incomingData,
  };

  var formData = Parsers.ConvertObjectAsFormData(data);

  let payload = {
    data: formData,
  };

  return await dataAccess.upload(API_CONSTANTS.UPDATE_DID_YOU_KNOW, payload);
};

export const deleteDidYouKnowVideo = async (DidYouKnowVideoId) => {
  if (!DidYouKnowVideoId) {
    return;
  }

  let payload = {
    data: {
      did_you_know_id: DidYouKnowVideoId,
    },
  };

  return await dataAccess.del(API_CONSTANTS.DELETE_DID_YOU_KNOW, payload);
};

export const publishOrUnpublishDidYouKnow = async (
  DidYouKnowVideoId,
  status
) => {
  if (!DidYouKnowVideoId || !status) {
    return;
  }

  let data = {
    publish: status.toLowerCase() === 'inactive' ? 1 : 0,
    video_id: DidYouKnowVideoId,
    update_video: 1,
  };

  var formData = Parsers.ConvertObjectAsFormData(data);

  let payload = {
    data: formData,
  };
  // console.log(data, 'feeddata');

  return await dataAccess.upload(API_CONSTANTS.UPDATE_DID_YOU_KNOW, payload);
};
