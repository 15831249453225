import { useState } from "react"

export const useCreateForm = (callback) => {
    const [inputs, setInputs] = useState({})

    const handleSubmit = () => {
        callback()

    }

    const handleInputChange = (e) => {
        setInputs({ ...inputs, [e.target.name]: e.target.value })
    }
    return {
        handleInputChange,
        handleSubmit,
        setInputs,
        inputs
    }
}

