import { useHistory } from 'react-router-dom';

import { getHomePageOrderingHome } from 'api/homepageorder';

import NewTable from 'components/NewDraggableTable';

const HomePageSection = ({
  tableHeader,
  data,
  HomePageOrder,
  callDeleteAPI,
}) => {
  const history = useHistory();

  const menuActionObj = [
    {
      name: 'Edit',
      onClick: (e, data) => {
        data.section_title = 'Homepage Order';
        history.push({
          pathname: `/homepage-ordering/edit/${data.id}`,
          state: {
            ...data,
          },
        });
      },
    },
    {
      name: 'Delete',
      onClick: (e, data) => {
        let text = 'are you sure,want to delete';
        if (window.confirm(text) == true) {
          callDeleteAPI(data);
        }
      },
    },
  ];

  return (
    <div className='blogs'>
      <NewTable
        // sectionObj={sectionObj}
        tableHeader={tableHeader}
        menuAction={menuActionObj}
        data={data}
        HomePageOrder={HomePageOrder}
        getData={getHomePageOrderingHome}
        hasPagination={false}
        disabledFirstAndLast={true}
      />
    </div>
  );
};
export default HomePageSection;
