import { useState } from 'react';

const useQuizForm = (validate) => {
  const [count, setCount] = useState(1);
  const [quizValues, setValues] = useState({
    sectionTitle: '',
    errorTitle: '',
    startDate: '',
    endDate: '',
    questions: [],
  });

  const addQuizSection = () => {
    const questions = [...quizValues.questions];
    const len = questions.length;
    questions.push({
      // id: Date.now(), // Basically its a index
      id: `mcq_${len}`,
      questionId: '',
      questionType: 'mcq',
      question: '',
      isNew: true,
      isRequired: false,
      answer: '',
      dateTimeAnswer: '',
      mcqSelected: '',
      answerKey: false,
      options: [
        {
          id: `mcq_${len}_0`,
          title: 'Option 1',
          isSelected: false,
          isChecked: false,
        },
        {
          id: `mcq_${len}_1`,
          title: 'Option 2',
          isSelected: false,
          isChecked: false,
        },
      ],
    });
    setCount(count + 1);
    setValues({
      ...quizValues,
      questions,
    });
  };

  const removeQuizSection = (id) => {
    const questions = [...quizValues.questions];

    let newQuestions = [];
    questions.forEach((element) => {
      if (`${element.id}` !== `${id}`) {
        newQuestions.push(element);
      }
    });

    setCount(newQuestions.length);
    setValues({
      ...quizValues,
      questions: newQuestions,
    });
  };

  const updateQuizValue = (obj) => {
    setValues({
      ...obj,
    });
    setCount(obj.questions.length);
  };

  const [quizError, setErrors] = useState({});

  const handleChange = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      setValues({
        ...quizValues,
        [name]: value,
      });
    }
  };

  const onHandleChange = (e) => {
    const valueCpy = { ...quizValues };
    valueCpy.questions.map((element) => {
      if (`${element.id}` === `${e.target.id}`) {
        element[e.target.name] = e.target.value;
      }
      return element;
    });
    setValues(valueCpy);
  };

  const onHandleOptionRadioClick = (e) => {
    const questions = [...quizValues.questions];
    if (e.target.choice === 'multiple_choice') {
      questions.map((question) => {
        question.options.map((element) => {
          if (`${element.id}` === `${e.target.id}`) {
            element.isChecked = !element.isChecked;
          }
          return element;
        });
        return question;
      });
    } else {
      /* 'single_choice' */
      questions.map((question) => {
        if (`${question.id}` === `${e.target.qid}`) {
          question.options.map((element) => {
            if (`${element.id}` === `${e.target.id}`) {
              element.isSelected = true;
            } else {
              element.isSelected = false;
            }
            return element;
          });
        }
        return question;
      });
    }

    setValues({
      ...quizValues,
      questions: questions,
    });
  };

  const onHandleOptionLabelChanged = (e) => {
    // debugger;
    const questions = [...quizValues.questions];
    questions.map((question) => {
      question.options.map((element) => {
        if (`${element.id}` === `${e.target.id}`) {
          element[e.target.name] = e.target.value;
        }
        return element;
      });
      return question;
    });

    setValues({
      ...quizValues,
      questions: questions,
    });
  };

  const onHandleAddOptionClick = (qid) => {
    let questions = [...quizValues.questions];
    questions = questions.map((element) => {
      if (`${element.id}` === `${qid}`) {
        element.options.push({
          id: `mcq_${qid}_${element.options.length}`,
          title: `Option ${element.options.length + 1}`,
          isSelected: false,
          isChecked: false,
        });
      }
      return element;
    });
    setValues({
      ...quizValues,
      questions,
    });
  };

  const onHandleRemoveOption = (optionId) => {
    let questions = [...quizValues.questions];
    questions = questions.map((element) => {
      element.options.map((option, index) => {
        if (`${option.id}` === `${optionId}`) {
          element.options.splice(index, 1);
          return true;
        }
        return option;
      });

      return element;
    });
    setValues({
      ...quizValues,
      questions,
    });
  };

  const handleSubmitting = (e) => {
    setErrors(validate(quizValues));
  };

  return {
    handleChange,
    quizValues,
    handleSubmitting,
    quizError,
    setValues,
    updateQuizValue,
    onHandleChange,
    addQuizSection,
    removeQuizSection,
    onHandleOptionRadioClick,
    onHandleOptionLabelChanged,
    onHandleAddOptionClick,
    onHandleRemoveOption,
  };
};

export default useQuizForm;
