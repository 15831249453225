// Ajax
import * as dataAccess from 'utils/ajax';
import ApiParser from 'utils/ApiParser';
// Constants
import { API_CONSTANTS } from 'constants/api-contants';
 
export const getActivityVitals = async (start, filterPayload) => {
  let payload = {
    data: {
      page: start ? start : 1,
      limit: 10,
      ...filterPayload,
    },
  };

  let responseObj = await dataAccess.get(
    API_CONSTANTS.GET_ACTIVITY_VITALS,
    payload
  );
   responseObj.data.map((item,index) => {
    responseObj.data[index].vitalId = item.id 
    responseObj.data[index].id = item.key+item.id 
  }) 
  return ApiParser.parseActivityVitalsListing(responseObj);
};

export const updateActivityVitals = async (incomingData, id, key) => {
  let payload = {
    data: {
      edit_data: incomingData,
    },
  };

  return await dataAccess.post(API_CONSTANTS.POST_ACTIVITY_VITALS, payload);
};

// export const addOrUpdateHomePageOrder = async (incomingData) => {
//   let payload = {
//     data: {
//       home_page_update: incomingData,
//     },
//   };

//   return await dataAccess.post(API_CONSTANTS.POST_HOMEPAGE, payload);
// };
