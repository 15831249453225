import React from 'react'
import BasePage from '../BasePage'
import MedicineDiscountContainer from 'app/container/MedicineDiscount/MedicineDiscountContainer/index'


const MedicineDiscount = () => {
    
  return (
    <BasePage>
        <MedicineDiscountContainer/>
    </BasePage>
  )
}

export default MedicineDiscount