import React from 'react';
import { CloseBlackIcon } from 'constants/image-constants';

import './index.scss';

const BannerCard = ({ id, index, children, handleRemoveCard }) => {
  const onRemoveCard = () => {
    handleRemoveCard && handleRemoveCard(index);
  };

  return (
    <div key={id} className='Banner-container'>
      <div className='Banner-container__section-one'>
        <div className='card__header'>
          <p className='Banner-container__section'>{`Banner ${index + 1}`}</p>
          <img src={CloseBlackIcon} alt='Close' onClick={onRemoveCard} />
        </div>
        <div className='Banner-container__Banner'>
          <div className='Banner-container__Image-Container'>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default BannerCard;
