import BasePage from "app/pages/BasePage";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
const BulkUploadSummary = () => {
    const location = useLocation();
    const history = useHistory()
    useEffect(() => {
        if (!location.state) {
            history.goBack()
        }

    }, [])
    return (
        <>
            <BasePage>
                <div className="container">
                    <h1 className="section-header__title">Bulk Upload Summary</h1>
                </div>
                <table>
                    <tr>
                        <th>Title</th>
                        <th>Status</th>
                        <th>Info</th>
                    </tr>
                    {location?.state?.map((item, index) => (
                        <tr key={index}>
                            <td>{item?.title}</td>
                            <td>{item["upload status"]}</td>
                            <td>{item["info"]}</td>
                        </tr>
                    ))}
                </table>

            </BasePage>

        </>
    );
}

export default BulkUploadSummary;