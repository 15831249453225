import { useHistory } from 'react-router';
import './index.scss';

function CustomTable({ data, tableHeader, startIndex }) {
  const history = useHistory();

  return (
    <table>
      <tr>
        {tableHeader.map((item, i) => {
          return <th className='nowrap text-center'>{item.Header}</th>;
        })}
      </tr>
      {data &&
        data.map((items, idx) => (
          <tr key={idx}>
            <td>{startIndex + idx + 1}</td>
            <td>{items.internal_name}</td>
            <td>{items.vital}</td>
            <td>{items.risk_level}</td>
            <td>{items.cohorts.join(', ')}</td>
            <td>{items.recommended_contents_count}</td>
            <td>{items.created_on}</td>
            <td>{items.updated_on}</td>
            <td>{items.status}</td>
            <td
              className='text-primary text-decoration-underline cursor-pointer'
              onClick={() => {
                history.push({
                  pathname: `/vitals/create-recommendations/edit/${items.id}`,
                });
              }}
            >
              Manage Recommendations
            </td>
          </tr>
        ))}
      {data.length === 0 && (
        <tr>
          <td colspan='10000'>No data found</td>{' '}
        </tr>
      )}
    </table>
  );
}

export default CustomTable;
