import React from 'react';

// Pages
import BasePage from 'pages/BasePage';  

import { MilestoneListHeader } from 'constants/table-header-constants'; 
import MilestoneList from 'app/container/UserJourney/MilestoneList';

const Milestone = () => {
  return (
    <BasePage>
      <MilestoneList  tableHeader={MilestoneListHeader}/>
    </BasePage>
  );
};

export default Milestone;
