import './index.scss';

const MedDropdownCard = ({
  id,
  index,
  children,
  handleRemoveCard = {},
  isNew = false,
  type = '',
}) => {
  return (
    <div key={id} className='Widget-container'>
      <div className='Widget-container__section-one'>
        <div className='card__header'>
          <p className='Widget-container__section'>{`${type}`}</p>
        </div>
        <div className='Widget-container__Widget'>
          <div className='Widget-container__Image-Container'>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default MedDropdownCard;
