import React from 'react';
import Toggle from 'react-toggle';
import useStartRecommendedCourse from './useStartRecommendedCourse';
import PageLoader from 'app/components/PageLoader';
import Button from 'app/components/button';
const StartRecommendedCourse = () => {
  const {
    pageLoading,
    publishStartCourses,
    startListData,
    startSelectedValue,
  } = useStartRecommendedCourse();

  return (
    <>
      <div>
        <table className='dashed-border'>
          <thead>
            <tr>
              <th>Courses title</th>
              <th>&lt; 18</th>
              <th>18 - 55</th>
              <th>55+</th>
            </tr>
          </thead>
          <tbody>
            {startListData &&
              Object.entries(startListData).map(
                ([cohortName, cohortItems, i]) => (
                  <>
                    <tr key={i}>
                      <td colSpan={4} className='td-white'>
                        <strong>{cohortName}</strong>
                      </td>
                    </tr>
                    {cohortItems.map((item, index) => (
                      <tr key={`${cohortName}-${index}`}>
                        <td>
                          <select
                            className='custom-select start-course-select'
                            onChange={(e) => {
                              e.preventDefault();
                              startSelectedValue(e.target.value, item?.id);
                            }}
                          >
                            <option value=''>Select Course</option>
                            {item?.dropdown?.map((ele, index) => (
                              <option
                                key={index}
                                selected={item?.course_uuid === ele.id}
                                value={ele?.id}
                              >
                                {ele?.title}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          {item.age_range === '<18' ? (
                            <Toggle checked={true} icons={false} />
                          ) : (
                            ' '
                          )}
                        </td>
                        <td>
                          {item.age_range === '18-55' ? (
                            <Toggle checked={true} icons={false} />
                          ) : (
                            ' '
                          )}
                        </td>
                        <td>
                          {item.age_range === '55+' ? (
                            <Toggle checked={true} icons={false} />
                          ) : (
                            ' '
                          )}
                        </td>
                      </tr>
                    ))}
                  </>
                )
              )}
          </tbody>
        </table>
        <div className='text-right mt2'>
          <Button onClick={publishStartCourses} type='submit' name='submit' />
        </div>
        {pageLoading && <PageLoader pageLoading={pageLoading} />}
      </div>
    </>
  );
};

export default StartRecommendedCourse;
