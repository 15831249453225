import NewInput from "app/components/NewInput";
import RadioButton from "app/shared/components/RadioButton";
import { useState, useEffect } from "react";
import Select, { components } from "react-select";
import {
  SortableContainer,
  SortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import Dropdown from "app/shared/components/Dropdown";
import {
  getCollectionsAPI,
  postGroup,
  updateGroup,
  updateLocalisationGroup,
  getGroupLocalisationDetails,
} from "app/api/createContentAPI";
import MultiSelectDropdown from "app/components/MultiSelectDropdown";
import { useHistory, useLocation, useParams } from "react-router";
import Button from "app/components/button";
import Toast from "app/utils/Toast";
import { deleteUploadedMedia, uplaodMedia } from "app/api/cms-content";

import { imageSizeValidation } from "app/utils/Parsers";
import ValidationMessage from "../../utils/ValidationMessage";
import TabBar from "app/components/NewDesign/TabBar";
import TabBarOptions from "app/utils/TabBarOptions";

function arrayMove(array, from, to) {
  array = array.slice();
  array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
  return array;
}

const SortableMultiValue = SortableElement((props) => {
  // this prevents the menu from being opened/closed when the user clicks
  // on a value to begin dragging it. ideally, detecting a click (instead of
  // a drag) would still focus the control and toggle the menu, but that
  // requires some magic with refs that are out of scope for this example
  const onMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});

const SortableMultiValueLabel = sortableHandle((props) => (
  <components.MultiValueLabel {...props} />
));

const SortableSelect = SortableContainer(Select);

const CreateGroupContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState({
    id: "1",
    name: "English",
    short_name: "en",
    display_order: 1,
    status: "ACTIVE",
    value: "English",
  });
  const [hideFields, setHideFields] = useState(false);
  const [state, setState] = useState({
    group_title: "",
    asthma_order: null,
    copd_order: null,
    ild_order: null,
    ar_order: null,
    other_order: null,
    group_type: "COLLECTION",
    collection_ids: [],
    image_url: "",
  });
  const [collectionOptions, setCollectionOptions] = useState([]);

  const onChange = (selectedOptions) =>
    setState({ ...state, collection_ids: selectedOptions });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(state.collection_ids, oldIndex, newIndex);
    setState({ ...state, collection_ids: newValue });
  };

  const uniqueCheck = () => {
    let uniqueArr = [];
    let stateArr = [
      "asthma_order",
      "copd_order",
      "ild_order",
      "ar_order",
      "other_order",
    ];
    let flag = true;
    for (let index in stateArr) {
      // key will be stateArr[index]
      if (uniqueArr.includes(state[stateArr[index]])) {
        flag = false;
        break;
      } else {
        uniqueArr.push(state[stateArr[index]]);
      }
    }
    return flag;
  };

  const groupType = [
    {
      label: "Group Of Collection",
      value: "GROUP_OF_COLLECTION",
    },
    {
      label: "Collection",
      value: "COLLECTION",
    },
  ];
  const inputListForEnglish = [
    {
      name: "group_title",
      label: "Group Title",
    },
    {
      name: "asthma_order",
      label: "Asthma Order",
    },
    {
      name: "copd_order",
      label: "Copd Order",
    },
    {
      name: "ild_order",
      label: "Ild Order",
    },
    {
      name: "ar_order",
      label: "AR Order",
    },
    {
      name: "other_order",
      label: "Other Order",
    },
  ];

  const inputListForOtherLanguage = [
    {
      name: "group_title",
      label: "Group Title",
    },
  ];
  const fetchCollections = async () => {
    let res = await getCollectionsAPI();
    setCollectionOptions(res);
  };
  useEffect(() => {
    fetchCollections();
  }, []);
  const getDetailsById = async (groupId, lang) => {
    let resp = await getGroupLocalisationDetails(groupId, lang);
    if (resp?.length > 0) {
      const stArr = Object.keys(state);
      let tempObj = {};
      if (activeTab?.name === "English") {
        for (let index in stArr) {
          tempObj = {
            ...tempObj,
            [stArr[index]]: resp?.[0]?.[stArr[index]] || "",
          };
        }
        let tempCollectionId = [];
        tempObj.collection_ids.map((groupId, i) => {
          const findIndex = collectionOptions.findIndex(
            (item) => Number(item.id) === groupId
          );
          if (findIndex !== -1) {
            tempCollectionId.push({
              label:
                collectionOptions[findIndex].id +
                "-" +
                collectionOptions[findIndex].collection_name,
              value: collectionOptions[findIndex].id,
              ...collectionOptions[findIndex],
            });
          }
        });
        tempObj.collection_ids = tempCollectionId;
        setState(tempObj);
      } else {
        setState((prevState) => ({
          ...prevState,
          group_title: resp?.[0]?.["group_title"] || "",
        }));
      }
    }
  };
  useEffect(() => {
    if (id) {
      getDetailsById(id, activeTab?.short_name);
    }
  }, [collectionOptions, activeTab?.short_name, id]);

  const handleTabChange = (val) => {
    setActiveTab(val);
    if (val?.name === "English") {
      setHideFields(false);
    } else {
      setHideFields(true);
    }
  };

  const handleTextChange = (e) => {
    if (e.target.name !== "group_title") {
      setState({
        ...state,
        [e.target.name]: Number(e.target.value) ? Number(e.target.value) : "",
      });
    } else {
      setState({ ...state, [e.target.name]: e.target.value });
    }
  };
  const handleSubmit = async () => {
    // let valid = uniqueCheck()
    // if (!valid) {
    //     Toast.error("Order fields should have unqiue value")
    //     return
    // }
    let dataObj;
    let resp;

    let collectionid = [];
    state.collection_ids.map((item, index) => {
      collectionid.push(item.id);
    });
    if (location.state) {
      dataObj = {
        ...state,
        collection_ids: JSON.stringify(collectionid),
        group_id: Number(id),
      };
      if (activeTab?.name === "English") {
        resp = await updateGroup(dataObj);
      } else {
        if (dataObj?.group_title !== "") {
          const payload = {
            group_title: dataObj?.group_title,
            group_id: Number(id),
          };
          resp = await updateLocalisationGroup(payload, activeTab?.short_name);
        } else {
          Toast.error("group_title Key is missing");
        }
      }
    } else {
      dataObj = { ...state, collection_ids: JSON.stringify(collectionid) };
      resp = await postGroup(dataObj);
    }

    if (resp?.response?.code == 200) {
      Toast.success(resp.response.alert[0].message);
      if (location.state?.comingFrom) {
        history.push({
          pathname: "/cms/discover",
          state: { goto: location.state?.comingFrom, selected: "3" },
        });
      } else {
        history.push({
          pathname: "/cms/discover",
          state: { selected: "3" },
        });
      }
    } else {
      Toast.error(
        resp.data && resp.data.error
          ? resp.data.error
          : resp.response.alert[0].message
      );
    }
  };

  const handleMediaChange = async (e) => {
    const file = e.target.files[0];
    if (
      (file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png") &&
      imageSizeValidation(e)
    ) {
      Toast.error(ValidationMessage.IMAGE_SIZE);
      return;
    } else {
      setState({ ...state, image_url: file });
    }
    // let resp = await uplaodMedia("media", file, "GROUP_OF_COLLECTIONS");
  };

  const cancelMedia = async (e) => {
    // let resp = await deleteUploadedMedia(state.image_url)
    setState({ ...state, image_url: "" });
  };
  return (
    <>
      <div className="container">
        <h1 className="section-header__title">Discover/ Create Group</h1>
      </div>
      <div className="separator">
        <p>Group - Add Details</p>
      </div>
      {id ? (
        <TabBar
          tabs={TabBarOptions.LanguageOptions}
          initialTab={activeTab}
          onTabChange={handleTabChange}
          parentStyle={"service-tab-style"}
        />
      ) : null}
      <div className="userInput">
        {hideFields
          ? inputListForOtherLanguage.map((item, index) => (
              <NewInput
                label={item.label}
                name={item.name}
                value={state[item.name]}
                onChange={handleTextChange}
              />
            ))
          : inputListForEnglish.map((item, index) => (
              <NewInput
                label={item.label}
                name={item.name}
                value={state[item.name]}
                onChange={handleTextChange}
              />
            ))}
      </div>
      {!hideFields ? (
        <div className="userFormArea">
          <label>Group Type</label>
          {groupType.map((item, key) => (
            <RadioButton
              id={item.value}
              label={item.label}
              isChecked={state.group_type == item.value ? true : false}
              value={item.value}
              onSelect={() => {
                setState({
                  ...state,
                  group_type: item.value,
                  collection_ids: [],
                });
              }}
              name={item.label}
            />
          ))}
        </div>
      ) : null}
      {state.group_type == "COLLECTION" && !hideFields && (
        <select
          name="collection_ids"
          className="custom-select"
          onChange={(e) => {
            let collectionid = [];
            collectionid.push({
              id: e.target.value,
            });

            setState({ ...state, collection_ids: collectionid });
          }}
        >
          <option value="">Select</option>
          {collectionOptions?.map((item, indx) => (
            <option
              key={indx}
              value={item.value}
              selected={item.value == state.collection_ids[0]?.id ? true : false}
            >
              {item?.name}
            </option>
          ))}
        </select>
      )}
      {state.group_type == "GROUP_OF_COLLECTION" && !hideFields && (
        <>
          <input
            type="file"
            id="imageUpload"
            style={{ display: "none" }}
            onChange={(e) => {
              handleMediaChange(e);
            }}
          />
          <label className="label-title-input">{"Image Upload"} : </label>
          <Button
            name={state.image_url ? "Uploaded" : "Upload"}
            isDisabled={state.image_url}
            onClick={() => {
              const m = document.getElementById("imageUpload");
              m.click();
            }}
          />
          <label
            className="label-title-input"
            role="button"
            style={{ paddingLeft: "10px" }}
            onClick={() => {
              if (state.image_url != "") {
                cancelMedia();
              }
            }}
          >
            {state.image_url != "" ? "Cancel Upload" : ""}
          </label>

          <div className="userFormArea">
            {/* <MultiSelectDropdown
                            label='Collection IDs'
                            name='tags'
                            value={state.collection_ids.map(String)}
                            options={collectionOptions}
                            onChange={(e) => {
                                setState({ ...state, collection_ids: e.target.value })
                            }}
                        /> */}
            <SortableSelect
              useDragHandle
              // react-sortable-hoc props:
              axis="xy"
              onSortEnd={onSortEnd}
              distance={4}
              // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
              getHelperDimensions={({ node }) => node.getBoundingClientRect()}
              // react-select props:
              isMulti
              options={collectionOptions}
              value={state.collection_ids}
              onChange={onChange}
              components={{
                MultiValue: SortableMultiValue,
                MultiValueLabel: SortableMultiValueLabel,
              }}
              closeMenuOnSelect={false}
            />
          </div>
        </>
      )}
      <div className="userFormArea">
        <Button
          name={
            state.group_type == "GROUP_OF_COLLECTION"
              ? location?.state
                ? "edit group"
                : "Create group"
              : location?.state
              ? "edit Collection"
              : "Create Collection"
          }
          onClick={handleSubmit}
        />
        <label
          style={{ paddingLeft: "10px", cursor: "pointer" }}
          onClick={() => {
            if (location.state?.comingFrom) {
              history.push({
                pathname: "/cms/discover",
                state: { goto: location.state?.comingFrom, selected: "3" },
              });
            } else {
              history.push({
                pathname: "/cms/discover",
                state: { selected: "3" },
              });
            }
          }}
        >
          Cancel
        </label>
      </div>
    </>
  );
};

export default CreateGroupContainer;
