import React from 'react';

// Pages
import BasePage from 'pages/BasePage'; 
import Exercises from 'container/Exercises'
import { ExercisesHeader } from 'constants/table-header-constants';

const ExercisesList = () => {
  return (
    <BasePage>
      <Exercises tableHeader={ExercisesHeader}/>
    </BasePage>
  );
};

export default ExercisesList;
