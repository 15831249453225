import React from 'react';

// Pages
import BasePage from 'pages/BasePage';
import CreateUser from 'container/CreateUser';

const CreateUsersPage = () => {
  return (
    <BasePage>
      <CreateUser />
    </BasePage>
  );
};

export default CreateUsersPage;
