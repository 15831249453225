import { API_CONSTANTS } from 'app/constants/api-contants';
import Toast from 'app/utils/Toast';
import * as dataAccess from 'utils/ajax';

export const getTagsDataApi = async () => {
     let responseObj = await dataAccess.get(API_CONSTANTS.GET_TAGS_CMS);
     if (
          responseObj &&
          responseObj.response &&
          responseObj.response.code === 200
     ) {
          return responseObj.data;
     } else {
          return [];
     }
}

export const tagPublishOrUnpublishAPI = async (id, type) => {
     let responseObj;
     if (type == "unpublish") {
          responseObj = await dataAccess.get(`${API_CONSTANTS.UNPUBLISH_CMS_TAG}/${id}`);
     }
     else {
          responseObj = await dataAccess.get(`${API_CONSTANTS.PUBLISH_CMS_TAG}/${id}`);
     }
     if (
          responseObj &&
          responseObj.response &&
          responseObj.response.code === 200
     ) {
          return responseObj.data;
     } else {
          return [];
     }
}

export async function deleteTagApi(id) {
     let responseObj = await dataAccess.del(`${API_CONSTANTS.DELETE_TAG}/${id}`);
     if (
          responseObj &&
          responseObj.response &&
          responseObj.response.code === 200
     ) {
          Toast.success("Deleted")
     }
}