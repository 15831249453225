import {
  getSuccessStory,
  addSuccessStory,
  updateSuccessStory,
  deleteSuccessStory,
} from 'app/api/latest/ReferralsandRewards/successtories';

import ValidationMessage from 'app/utils/ValidationMessage';
import { useEffect, useState } from 'react';

import Message from 'utils/Message';
import Toast from 'utils/Toast';

const useSuccessStory = () => {
  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(1);
  const [storylistData, setServiceListData] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isPublishButtonDisabled, setisPublishButtonDisabled] = useState(true);
  const [isAddButtonDisabled, setisAddButtonDisabled] = useState(true);
  const [activeId, setActiveId] = useState('');
  const createToggler = (item) => {
    setActiveId(item?.id);
  };
  const onClickOutside = () => {
    setActiveId('');
  };

  const menuActionObj = [
    {
      name: 'Delete',
      onClick: (e, data) => {
        deleteRefferelStory(data?.storyId);
      },
    },
    {
      name: 'Not now',
      onClick: (e, data, index) => {
        onClickOutside();
      },
    },
  ];

  useEffect(() => {
    loadStorylist();
  }, []);

  const loadStorylist = async () => {
    setPageLoading(true);
    let response = await getSuccessStory();
    setPageLoading(false);
    if (response?.response?.code === 200) {
      let temp = [];
      if (response?.data?.length > 0) {
        temp = response?.data?.map((item, index) => {
          return {
            storyId: item?.id,
            id: `story_${index}`,
            displayOrder: item?.display_order ? item?.display_order : index,
            attachment: item?.banner_image,
            status: item?.status === 'ACTIVE' ? true : false,
            isNew: false,
            wasEdited: false,
          };
        });
      }

      // let newtemp = temp?.sort((a, b) => a.displayOrder - b.displayOrder);

      setServiceListData(temp);

      if (temp.length >= 10) {
        setisAddButtonDisabled(true);
      } else {
        setisAddButtonDisabled(false);
      }
      setCount(temp.length);

      setLoading(false);
    } else {
      Toast.error(
        response.message ? response.message : Message.Error.COMMON_MESSAGE
      );
    }
  };

  const addStorySection = () => {
    setisPublishButtonDisabled(false);

    const temp = [...storylistData];

    temp.push({
      id: Date.now(), // Basically its a index
      storyId: null,
      isNew: true,
      title: '',
      attachment: '',
      attachmentFile: null,
      displayOrder: '',
      status: false,
      wasEdited: false,
    });
    setCount(count + 1);

    setServiceListData(temp);

    if (count + 1 >= 10) {
      setisAddButtonDisabled(true);
    }
  };

  const OnhandleChange = (e) => {
    const { name, value, id } = e.target;
    if (value) setisPublishButtonDisabled(false);
    const valueCpy = [...storylistData];

    valueCpy.forEach((element, index) => {
      if (`${element.id}` === `${id}`) {
        if (element.storyId !== null) {
          element.wasEdited = true;
        }
        if (name === 'displayOrder') {
          element[name] = value && parseInt(value);
          return;
        }

        element[name] = value;
      }

      return element;
    });
    setServiceListData([...valueCpy]);
  };
  const storyToogleChangestatus = (itemindex) => {
    setisPublishButtonDisabled(false);
    let newData = [...storylistData];
    newData[itemindex].wasEdited = true;
    newData[itemindex].status = !newData[itemindex].status;
    setServiceListData(newData);
  };

  const validateRequestpreviewScreen = async () => {
    let addimageCheck = false;

    let adddisplayOrderCheck = false;
    let adddisplayOrderunique = false;
    let adddisplayOrderMaxvalueCheck = false;

    storylistData?.forEach((item, index) => {
      if (item?.isNew) {
        if (item?.attachmentFile === null) {
          Toast.error(`${ValidationMessage.ATTACH} for Service ${index + 1}`);
          addimageCheck = true;
          return;
        }
      }

      if (item?.wasEdited && item?.attachmentFile === null) {
        Toast.error(`${ValidationMessage.ATTACH} for Service ${index + 1}`);
        addimageCheck = true;
        return;
      }
      if (item?.displayOrder === '' || !item?.displayOrder) {
        Toast.error(
          `${ValidationMessage.DISPLAY_ORDER} for Service ${index + 1}`
        );
        adddisplayOrderCheck = true;
        return;
      }

      if (item?.displayOrder > storylistData.length) {
        Toast.error(
          `Display Order Must be Under ${storylistData.length} for Service ${
            index + 1
          }`
        );
        adddisplayOrderMaxvalueCheck = true;
        return;
      }
    });

    if (
      addimageCheck ||
      adddisplayOrderCheck ||
      adddisplayOrderunique ||
      adddisplayOrderMaxvalueCheck
    ) {
      setPageLoading(false);
      return;
    }

    callAddOrUpdateAPI();
    // handleShow(true);
  };

  const callAddOrUpdateAPI = async () => {
    let addpayloadData = [];
    let editpayloadData = [];

    storylistData.forEach((item, index) => {
      var consData = {};
      var newconsData = {};

      if (item.isNew) {
        if (item?.displayOrder) {
          consData.display_order = item.displayOrder;
        }

        if (item.storyId) {
          consData.id = item.storyId;
        }

        if (item?.attachmentFile) {
          consData.story_image = item.attachmentFile;
        }
        consData.status = item.status;
        addpayloadData.push(consData);
      }
      if (item.wasEdited && !item?.isNew) {
        if (item?.displayOrder) {
          newconsData.display_order = item.displayOrder;
        }

        if (item.storyId) {
          newconsData.id = item.storyId;
        }

        newconsData.story_image = item.attachmentFile
          ? item.attachmentFile
          : item.attachment;
        newconsData.status = item.status;

        editpayloadData.push(newconsData);
      }
    });

    try {
      const promises = [];
      if (addpayloadData) {
        promises.push(addSuccessStory(addpayloadData));
      }
      if (editpayloadData) {
        promises.push(updateSuccessStory(editpayloadData));
      }

      // Wait for all promises to resolve (API calls to complete)
      await Promise.all(promises);

      // All other APIs have completed successfully
      await loadStorylist();
      setisPublishButtonDisabled(true);
      setPageLoading(false);
    } catch (error) {
      // Handle errors here if any of the API calls fail
      console.error('API call failed:', error);
      setPageLoading(false);
    }
  };
  const handleCreateServices = (e) => {
    handleClose();
    callAddOrUpdateAPI();
  };
  const onFileSelected = (file, id) => {
    let newData = [...storylistData];
    // if (file)

    setisPublishButtonDisabled(false);
    // if (newData && newData.length > 0 && file)
    if (newData && newData.length > 0) {
      newData = newData.map((item) => {
        if (item.id === id && id) {
          if (item.storyId !== null) {
            item.wasEdited = true;
          }
          item.attachmentFile = file;
        }
        return item;
      });
      setServiceListData(newData);
    }
  };

  const deleteRefferelStory = async (id) => {
    if (!id) {
      return;
    }
    setPageLoading(true);
    const response = await deleteSuccessStory(id);
    setPageLoading(false);
    if (response?.response?.code === 202) {
      Toast.success(
        response?.data?.message
          ? response?.data?.message
          : Message?.SuccessStory?.DELETE_SUCCESS
      );

      await loadStorylist();
    }
    if (response?.data?.error) {
      Toast.error(
        response?.data ? response?.data : Message?.SuccessStory?.DELETE_FAILURE
      );
    }
  };
  return {
    isPublishButtonDisabled,
    loading,
    pageLoading,
    onFileSelected,
    isAddButtonDisabled,
    OnhandleChange,
    addStorySection,
    count,
    storylistData,
    setServiceListData,

    handleCreateServices,

    validateRequestpreviewScreen,
    handleClose,
    handleShow,
    show,
    storyToogleChangestatus,
    createToggler,
    activeId,
    menuActionObj,
    onClickOutside,
  };
};

export default useSuccessStory;
