import RadioButton from "app/shared/components/RadioButton";
import Button from "app/components/button";
import "../index.scss"
import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useParams } from "react-router-dom";
import NewInput from "app/components/NewInput";
import Toast from "app/utils/Toast";
import PageLoader from "app/components/PageLoader";
import  {imageSizeValidation}  from 'utils/Parsers'
import DropdownOptions from "app/utils/DropdownOptions";
import Dropdown from 'app/shared/components/Dropdown';
import ValidationMessage from 'utils/ValidationMessage';
import { createNavigation, updateNavigation, getNavigationDetails } from "app/api/navigations";
import { CloseBlackIcon } from 'app/constants/image-constants';
import CTAType  from 'app/components/CTAType'
import { customObjectOptions } from "app/json-data/dropdown-constant";
const CreateNavigationContainer = () => {
    const location = useLocation()
    const [loader, setLoader] = useState(false)
    const params = useParams();
    const [internalUrl, setInternalURL] = useState([]);
    const [thumbnailImage, setThumbnailImage] = useState(null);
    const [state, setState] = useState({
        title: "",
        url_type:"INTERNAL",
        url:"",
        thumbnail_image:"",
        navigation_point:"",
        cohort_type: "",
        meeting_id:"",
        passcode:"",
        internal_for: "",
        internal_url: "",
        profile_data: false,
        custom_object: "",
        webinar_id: "",

    })
    let history = useHistory()
    useEffect(() => {
        let internalUrl =[]
        DropdownOptions.InternalUrl.map((item, i) =>{
            if(item.value !== 'feed' && item.value !== 'content_detail' && item.value !== 'quiz' && item.value !== 'vital_activity'  && item.value !== 'webinar_details' && item.value !== 'individual_quiz'){
              internalUrl.push(item)
            }
            })
            let newItem ={
                label:"Community Videos",
                value:"community_videos"
            }
            internalUrl.push(newItem)
            setInternalURL(internalUrl)
            if(params.id){
                getNavigationDetailByID(params.id)
           }
    }, [])

    const getNavigationDetailByID = async(id) => {
        const details = await getNavigationDetails(id)
        setState({
            title: details.title,
            url_type: details.url_type,
            url:details.url,
            navigation_point:details.navigation_point,
            thumbnail_image:details.thumbnail_image,
            cohort_type: details.cohort_type,
            meeting_id: details.meeting_id? details.meeting_id: "",
            passcode: details.passcode? details.passcode: "",
            internal_for: details.internal_for,
            internal_url: details.internal_url,
            profile_data: details.profile_data,
            custom_object: details.custom_object,
            webinar_id: details.webinar_id? details.webinar_id:""
        })
        if(details.thumbnail_image){
            let thumbnail_image = `${process.env.REACT_APP_S3_BUCKET_URL}/${details.thumbnail_image}`;
            setThumbnailImage(thumbnail_image)
        }

    }

    const navigationPointOptions = [
        {
            label: "Strip Navigation",
            value: "strip_nav_bar"
        },
        {
            label: "Quick Links",
            value: "quick_links"
        },
        {
            label: "Community Videos",
            value: "community_videos"
        },
        {
            label:'Assessment',
            value:'assessment'
        },
        {
            label:'Assessment Popup',
            value:'assessment_popup'
        },
        {
            label:'Pulmorehab',
            value:'pulmo_rehab'
        }

    ]

    const cohortTypeOptions = [
        {
            label: "ASTHMA",
            value: "ASTHMA"
        },
        {
            label: "COPD",
            value: "COPD"
        },
        {
            label: "AR",
            value: "AR"
        },
        {
            label:'ILD',
            value:'ILD'
        },
        {
            label:'OTHER',
            value:'OTHER'
        }

    ]

    const genderRadioMenu = [
        {
             label: "True",
             id: "1",
             value: true
        },
        {
             label: "False",
             id: "2",
             value: false
        },

      ]
    //   const customObjectOptions = [
    //     {
    //          label: "PulmoRehab",
    //          id: "1",
    //          value: 'PulmoRehab'
    //     },
    //     {
    //         label: "Exercise",
    //         id: "2",
    //         value: 'Exercise'
    //       },

    //   ]

    const handleTextChange = (e) => {
        if(e.target.name === 'redirection_id'){
            setState({ ...state, 'webinar_id': e.target.value })
        }else{
            setState({ ...state, [e.target.name]: e.target.value })
        }

    }

    const handleMediaUpload = (e) => {
        let file = e.target.files[0]
        if ((file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') && imageSizeValidation(e)) {
            Toast.error(ValidationMessage.IMAGE_SIZE)
            return
        }else{
            let reader = new FileReader();
            reader.onloadend = function () {
              setThumbnailImage(reader.result);
            };
            reader.readAsDataURL(file);
            setState({ ...state, [e.target.name]: file })
            Toast.success(file?.name + " uploaded")
        }

    }


    const handleSubmit = async() => {
        let payload ={...state}

        if(state.navigation_point === "pulmo_rehab"){
            payload.url_type = "INTERNAL_PWA"
        }
        if(!state.title  || !state.navigation_point ){
            Toast.error("Please complete all fields")
                return
        }else{
            if((state.navigation_point !== 'assessment' && state.navigation_point !== 'assessment_popup' && state.navigation_point !== 'pulmo_rehab')){
                if(!state.url_type){
                    Toast.error("Please select redirect type and url")
                    return
                }

            }
            if((state.navigation_point === 'assessment' || state.navigation_point === 'assessment_popup') && !state.cohort_type){
                Toast.error("Please select cohort type")
                return
            }

            if(state.navigation_point !== 'strip_nav_bar' && !state.thumbnail_image){
                Toast.error("Please upload thumbnail image")
                return
            }

            if(state.navigation_point === 'pulmo_rehab' && (!state.internal_for || !state.internal_url)){
                Toast.error("Please enter for and url")
                return
            }

            setLoader(true)
            let responseObj
            if(params.id){
                payload.webinar_id = params.id
                responseObj = await updateNavigation(payload)
            }else{
                responseObj = await createNavigation(payload)
            }

            if (
                responseObj &&
                responseObj.response &&
                responseObj.response.code === 200
            ) {
                Toast.success(responseObj.response.alert[0].message);
                history.push("/navigation")

            } else {
                setLoader(false)
                Toast.error(responseObj.data?responseObj.data.msg: responseObj.response.alert[0].message?responseObj.response.alert[0].message : '');
            }
        }

    }
    return (
        <div className="container">
            <h1 className="section-header__title">Navigation</h1>

            <div className="separator">
                <p>Add Action</p>
            </div>
            <div className="userInput">
                <NewInput
                    label="Action Title"
                    name={"title"}
                    value={state.title}
                    onChange={handleTextChange}
                />

                <div className="dropdown-area my-4">
                        <label>Navigation Point </label>
                        <select
                            name="navigation_point"

                            className="custom-select mx-3" onChange={(e) => {
                                setState({ ...state, navigation_point: e.target.value, cohort_type: ""

                                })
                            }}>
                            <option value="">Select</option>
                            {
                                navigationPointOptions?.map((item, indx) => (
                                    <option key={indx}
                                        value={item.value}
                                        selected={state.navigation_point == item.value ? true : false}
                                    >
                                        {item.label}</option>
                                ))
                            }

                        </select>
                    </div>
                {(state.navigation_point === 'assessment' || state.navigation_point === 'assessment_popup' )?
                <>
                <div className="dropdown-area my-4">
                        <label>Cohort Type </label>
                        <select
                            name="navigation_point"

                            className="custom-select mx-3" onChange={(e) => {
                                setState({ ...state, cohort_type: e.target.value })
                            }}>
                            <option value="">Select</option>
                            {
                                cohortTypeOptions?.map((item, indx) => (
                                    <option key={indx}
                                        value={item.value}
                                        selected={state.cohort_type == item.value ? true : false}
                                    >
                                        {item.label}</option>
                                ))
                            }

                        </select>
                    </div>

                </>
                :
                <>
                {state.navigation_point !== 'pulmo_rehab' &&
                <>
                <div className="userFormArea">
                    <label>Redirect Type</label>
                 <CTAType
                        cta_type = {state.url_type}
                        handleChange={(key, data) => {
                            if(key === 'cta_type'){
                                if(!data ){
                                    setState({ ...state, url_type: data, url:'' });
                                  }else{
                                    setState({ ...state, url_type: data });
                                  } 
                            } else {
                                if (key === 'url' && (data !== 'zoom_live_session' || data !== 'infinite_scroll' || data !== 'group_of_collection')) {
                                    setState({ ...state, [key]: data, 'redirection_id': "" })
    
                                } else {
                                    setState({ ...state, [key]: data })
                                }
                            }
                        }}

                        internalUrl={internalUrl}
                        internal_for={state.internal_for}
                        internal_url={state.internal_url}
                        profile_data={state.profile_data}
                        custom_object={state.custom_object}
                        url={state.url}
                        redirection_id={state.webinar_id}
                        handleTextChange={handleTextChange}
                    />
                    </div>
                {/* <div className="userFormArea">
                    <label>Redirect Type</label>
                    {radioMenu.map((item, index) => (
                        <RadioButton
                            id={item.id}
                            label={item.label}
                            isChecked={state.url_type == item.value ? true : false}
                            value={item.value}
                            onSelect={() => { setState({ ...state, url_type: item.value }) }}
                            name={item.label}
                            style={{ marginTop: '-15px'}}
                        />
                    ))}

                </div> */}
                {/* <div className="userFormArea horizontal-align">
                    {state.url_type === 'INTERNAL' ?
                    <div className="dropdown-area">
                        <label>Internal App</label>
                        <select
                            name="url"

                            className="custom-select" onChange={(e) => {
                                setState({ ...state, url: e.target.value })
                            }}>
                            <option value="">Select</option>
                            {console.log(internalUrl, state.url)}
                            {
                                internalUrl?.map((item, indx) => (
                                    <option key={indx}
                                        value={item.value}
                                        selected={state.url === item.value}
                                    >
                                        {item.label}</option>
                                ))
                            }

                        </select>
                    </div>
                    :
                    <div className="input-container">
                        <NewInput
                            label="External Redirect Link"
                            name="url"
                            onChange={handleTextChange}
                            value={state.url}

                        />

                    </div>
                    }

                </div> */}
                </>
                }
                </>

                }
                {state.navigation_point === 'pulmo_rehab' &&
                <>
                <NewInput
                    label='For'
                    name={'internal_for'}
                    value={state.internal_for}
                    placeholder='Enter for'
                    className='form-block'
                    type='text'
                    onChange={(e) => { setState({ ...state, 'internal_for': e.target.value }) }}
                  />
                  <NewInput
                    label='URL'
                    name={'internal_url'}
                    value={state.internal_url}
                    placeholder='Enter URL'
                    className='form-block'
                    type='text'
                    onChange={(e) => { setState({ ...state, 'internal_url': e.target.value }) }}
                  />
                  <div className='radio_container'>
                    <div className="content-selector customradio">
                      <h6>Profile Data</h6>
                      {genderRadioMenu.map((item, key) => (
                          <RadioButton
                              key={key}
                              label={item.label}
                              isChecked={state.profile_data === item.value ? true : false}
                              value={item.value}
                              onSelect={() => { setState({ ...state, 'profile_data': item.value }) }}
                              name={item.label}
                          />
                      ))}
                    </div>
                  </div>
                  <Dropdown
                    label='Custom Object'
                    name='custom_object'
                    options={customObjectOptions}
                    placeholder='Select custom object'
                    value={state.custom_object}
                    handleChange={(e) => { setState({ ...state, 'custom_object': e.target.value })}}
                    // handleChange={onChange}
                  />

                </>
                // <>
                //  <div className="input-container">
                //     <NewInput
                //         label="Meeting Id"
                //         name="meeting_id"
                //         onChange={handleTextChange}
                //         value={state.meeting_id}

                //     />

                // </div>
                // <div className="input-container">
                //     <NewInput
                //         label="Password"
                //         name="passcode"
                //         onChange={handleTextChange}
                //         value={state.passcode}

                //     />

                // </div>
                // </>
                }

                <div className="userFormArea horizontal-align">
                    <div className="col">
                        <div>
                            {
                                state.thumbnail_image && <p className="cancel" onClick={(e) => {
                                    setThumbnailImage(null);
                                    setState({ ...state, thumbnail_image: "" })
                                }}>Cancel Thumbnail</p>
                            }
                        </div>
                        <div className="row">
                            <div className="col-md-2">
                                <label>Thumbnail</label>
                            </div>
                            <div className="col-auto">
                                <input type="file" id="thumbnail_image"
                                    style={{ display: "none" }}
                                    name='thumbnail_image'
                                    key = {state.thumbnail_image}
                                    onChange={(e) => {
                                        handleMediaUpload(e)
                                    }}
                                />

                                <div className="upload-label">

                                    <Button
                                        onClick={() => {
                                            const image = document.getElementById("thumbnail_image")
                                            image.click()
                                        }}
                                        isDisabled={state.thumbnail_image}
                                        type='button'
                                        name={state.thumbnail_image != "" ? "Uploaded" : 'Upload Thumbnail Image'}

                                    />
                                </div>
                            </div>
                            <div className="col-auto">
                                {thumbnailImage && (
                                    <div className='attachment_img_wrapper'>
                                        <img src={thumbnailImage} alt='sample' className='feed__attachment' />
                                        <div className='attachment_img_wrapper_close'>
                                            <img src={CloseBlackIcon} alt='Remove' onClick={(e) => {
                                                setThumbnailImage(null);
                                                setState({ ...state, thumbnail_image: "" })
                                            }}/>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                </div>


                 <div className="userFormArea">
                    <Button
                        name={"Save"}
                        onClick={handleSubmit}
                    />
                    <label style={{ paddingLeft: "10px", cursor: "pointer" }}

                        onClick={  () => { history.goBack() }  }
                    >Cancel</label>
                </div>
            </div>
            {loader && <PageLoader pageLoading={loader} />}
        </div>


    );
}

export default CreateNavigationContainer