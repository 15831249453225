import React from 'react';

// Pages
import BasePage from 'pages/BasePage';

// Container
import Activity from 'container/Activity';

import { ActivityTableHeader } from 'constants/table-header-constants';

const FAQPage = () => {
  return (
    <BasePage>
      <Activity tableHeader={ActivityTableHeader} />
    </BasePage>
  );
};

export default FAQPage;
