// Components
import DropdownOptions from 'app/utils/DropdownOptions';
import HeaderWithBackButton from 'components/HeaderWithBackButton';
import HtmlEditor from 'components/HtmlEditor';
import NewInput from 'components/NewInput';
import PageLoader from 'components/PageLoader';
import NumberInput from 'components/UI/NumberInput';
import validate from 'components/ValidateInfo';
import Button from 'components/button';
import Dropdown from 'shared/components/Dropdown';
// API

// Constants
import Calender from 'app/components/Calender';
import TabBar from 'app/components/NewDesign/TabBar';
import BasePage from 'app/pages/BasePage';
import useTabBarOptions from 'app/utils/useTabbarOptions';
import { APP_CONSTANTS } from 'constants/app-constants';
import { useState } from 'react';
import Toggle from 'react-toggle';
import useCreateCouponAndOffer from './useCreateCouponAndOffer';
import QuillTextEditor from 'app/shared/components/QuillTextEditor';

const CreateCouponAndOffer = () => {
  const {
    handleChange,
    values,
    error,
    htmlContent,
    editorRef,
    handleCreateCoupon,
    pageLoading,
    onStartDateSelected,
    onEndDateSelected,
    startDate,
    endDate,
    validated,
    activate,
    toggled,
    openUp,
    productFamilyList,
    handleChangeDropdown,
    coupon_id,
    handleTabChange,
    activeTab,
    hideFields,
    setHTMLContent
  } = useCreateCouponAndOffer(validate);

  const { LanguageOptions } = useTabBarOptions();
  return (
    <BasePage>
      <div className='blogsForm'>
        <div className='blogsForm_content'>
          <HeaderWithBackButton
            title='Coupon & Offer'
            goTo={APP_CONSTANTS.LATEST_COUPON_OFFER}
          />
          {coupon_id && (
            <TabBar
              tabs={LanguageOptions}
              initialTab={activeTab}
              onTabChange={handleTabChange}
              parentStyle={'service-tab-style'}
            />
          )}

          <NewInput
            label='Coupon Name*'
            id='heading'
            type='text'
            placeholder='10% OFF ON Automated Vitals'
            name='heading'
            value={values.heading}
            onChange={handleChange}
            errorText={error.heading}
          />
          <NewInput
            label='Sub Text'
            id='sub_heading'
            type='text'
            placeholder='10% OFF ON Automated Vitals'
            name='sub_heading'
            value={values.sub_heading}
            onChange={handleChange}
            errorText={error.sub_heading}
          />
          <NewInput
            label='Coupon Code*'
            id='code'
            type='text'
            placeholder='FBVTRY100'
            name='code'
            value={values.code}
            onChange={handleChange}
            errorText={error.code}
            isFieldHidden={hideFields}
          />
          <Dropdown
            id={'product_type'}
            label='Product Type*'
            name='product_type'
            options={DropdownOptions?.ServiceType}
            placeholder='Select Product Type'
            value={values.product_type}
            handleChange={handleChangeDropdown}
            isFieldHidden={hideFields}
          />
          {values?.product_type === 'AUTOMATED_VITALS' && (
            <Dropdown
              id={'product_family'}
              label='Product Family*'
              name='product_family'
              options={productFamilyList}
              placeholder='Select Product Family'
              value={values.product_family}
              handleChange={handleChangeDropdown}
            />
          )}
          {/* <Dropdown
            id={'max_usage_per_user'}
            label='Max Usage per user*'
            name='max_usage_per_user'
            options={DropdownOptions?.MaxUagePerPerson}
            placeholder='(Default)'
            value={values.max_usage_per_user}
            handleChange={handleChange}
          /> */}
          <NumberInput
                  label='Max Usage per user*'
                  id='max_usage_per_user'
                  placeholder='1'
                  name='max_usage_per_user'
                  value={values.max_usage_per_user}
                  onChange={handleChange}
                />
                <Dropdown
                  id={'user_visibility'}
                  label='Do you want to show this in the app?*'
                  name='user_visibility'
                  options={DropdownOptions?.EnableForInternalUser}
                  value={values.user_visibility}
                  handleChange={handleChangeDropdown}
                  // selectedValue={DropdownOptions?.EnableForInternalUser[1].value}
                  // selectedValue={defaultOption}
                  // isFieldHidden={hideFields}
                />
          <div className='cdropdown'> Discount Details*</div>
          <div className='discount_details_box'>
            <NumberInput
              label='Add Discount %*'
              id='discount'
              placeholder='40%'
              name='discount'
              value={values.discount}
              onChange={handleChange}
            />
            <NumberInput
              label='Minimum Value Required'
              id='min_value_required'
              placeholder='Rs. 159'
              name='min_value_required'
              value={values.min_value_required}
              onChange={handleChange}
            />
            <NumberInput
              label='Max Discount Amount'
              id='max_discount_amount'
              placeholder='Rs. 80'
              name='max_discount_amount'
              value={values.max_discount_amount}
              onChange={handleChange}
            />
          </div>
           <QuillTextEditor
          label='Coupon Details'
          Tooltiptext='DO NOT COPY AND PASTE FROM WORD DOCUMENT OR OTHER EDITOR. Directly write here or copy from notepad only.'
          value={htmlContent}
          quillRef={editorRef}
          setHTMLContent={setHTMLContent}
        />
          {!hideFields && (
            <Calender
              onStartDateSelected={onStartDateSelected}
              onEndDateSelected={onEndDateSelected}
              startDate={startDate}
              endDate={endDate}
              validated={validated}
              activate={activate}
              mandatory={true}
            />
          )}
          {/* <div>
            Status <Toggle checked={toggled} icons={false} onChange={openUp} />
          </div> */}

          {!hideFields && (
            <div className='d-block my-4'>
              <span className='me-2 d-inline-block'>Status*</span>
              <label className='d-inline-block mt-2'>
                <div className='d-inline-flex align-items-center'>
                  <div className='d-inline-flex text-danger'>OFF</div>
                  <div className='d-inline-flex mx-2'>
                    <Toggle checked={toggled} icons={false} onChange={openUp} />
                  </div>
                  <div className='d-inline-flex text-green'>ON</div>
                </div>
              </label>
            </div>
          )}

          <Button
            className='mt1'
            onClick={handleCreateCoupon}
            variant='Save'
            name='Submit'
          />
        </div>
        {pageLoading && <PageLoader pageLoading={pageLoading} />}
      </div>
    </BasePage>
  );
};

export default CreateCouponAndOffer;
