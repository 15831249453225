import { useHistory } from "react-router"
import './index.scss'

import { deleteCollection } from "app/api/cms-collections";

function CustomTable({ data, handleDelete }) {
     const history = useHistory()
     const handleNavigation = (items) =>{
          let obj = JSON.stringify(items)
          localStorage.setItem('collection', obj)

          history.push({
               pathname: `/cms/collection/edit/${items.id}`,
               state: {
                    ...items,
               },
          });
     }

     return (
          <table>
               <tr>
                    <th className='nowrap'>Collection Name</th>
                    <th className='nowrap'>Tags Added</th>
                    <th className='nowrap'>No of Content Added</th>
                    <th className='nowrap'>Published Date</th>
                    <th className='nowrap'>Update Date</th>
                    <th className='nowrap'>Collection ID</th>
                    <th className='nowrap'>Collection Type</th>
                    <th className='nowrap'>Status</th>
                    {/* <th className='nowrap'>Expired On</th> */}
                    <th className='nowrap'>Action</th>
               </tr>
               {data.map((items, indx) => (<tr key={indx}>
                    <td>{items.collection_name}</td>
                    <td>
                         {items.tags_added.length > 0 ? items.tags_added.map((tag, i) => (` ${tag}${i !==items.tags_added.length-1 ? ',':''}`)) : ""}
                    </td>
                    <td>{items.content_ids.length> 0 ? items.content_ids.length: ''}</td>
                    <td>{items.published_date}</td>
                    <td>{items.updated_on}</td>
                    <td>{items.id}</td>
                    <td>{items.collection_type}</td>
                    <td>{items.status}</td>
                    {/* <td>{items?.expire_on ? items.expire_on: "-"}</td> */}
                    <td>
                         <div className="btn-group" role="group" aria-label="Basic outlined example">
                              <button type="button" className="btn btn-outline-primary"
                                   onClick={() => handleNavigation(items) }
                              >Edit
                              </button>
                              <button type="button"
                                    onClick={() => { handleDelete(items.id) }} className="btn btn-outline-primary">Delete
                              </button>
                         </div>
                    </td>
               </tr>))}
          </table>
     )
}

export default CustomTable