import { useState, useRef } from 'react';
import { callLCPEducatorMapping } from "app/api/lcpeducatormapping";
import Button from "app/components/button";
import Loader from "app/components/Loader";
import Toast from "app/utils/Toast";

const LcpSessionContainer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const isSyncingRef = useRef(false);

  const CallGetEducatorMapping = async () => {
    // If already syncing, immediately return and do nothing
    if (isSyncingRef.current) {
      return;
    }

    try {
      // Set flags to prevent multiple calls
      setIsLoading(true);
      isSyncingRef.current = true;

      const dataArr = await callLCPEducatorMapping();
      Toast.success(dataArr[1]);
    } catch (error) {
      Toast.error("Failed to sync data");
    } finally {
      // Reset flags after sync completes
      setIsLoading(false);
      isSyncingRef.current = false;
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="blogsForm_content" style={{ width: "100%" }}>
        <div className="d-flex justify-content-between">
          <h3>LCP Session</h3>
        </div>
        
        <div>
          <Button 
            onClick={CallGetEducatorMapping} 
            name={
              isLoading ? (
                <div className="flex items-center gap-2">
                  <Loader className="h-4 w-4 animate-spin" />
                  Syncing...
                </div>
              ) : "Sync Data"
            }
            disabled={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default LcpSessionContainer;