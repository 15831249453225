function SelectComp({
  options,
  onChangeHandler,
  name,
  selected,
  selectedTitle,
  disabled,
  selectedItem,
}) {
  return (
    <select
      disabled={disabled ? true : false}
      name={name ? name : ''}
      onChange={onChangeHandler}
      className='custom-select'
    >
      {selected ? (
        <option selected value={0}>
          {selectedTitle ? selectedTitle : 'select'}
        </option>
      ) : (
        ''
      )}
      {options.map((option, i) => (
        <option key={i} value={option.value} selected={selectedItem - 1 == i}>
          {option.label}
        </option>
      ))}
    </select>
  );
}
export default SelectComp;
