import React, { useEffect, useRef } from 'react';
import './index.scss';

const ActionMenu = ({ onClickOutside, row, menuAction }) => {
  const dropdownWrapperRef = useRef(null);
  const imageRef = useRef();

  const handleClickOutside = (event) => {
    if (
      dropdownWrapperRef.current &&
      !dropdownWrapperRef.current.contains(event.target)
    ) {
      onClickOutside();
    }
  };
  const handleHideDropdown = (event) => {
    if (event.key === 'Escape') {
      onClickOutside();
    }
  };
  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', (e) => {
      handleClickOutside(e);
    });
    document.addEventListener('keydown', (e) => {
      handleHideDropdown(e);
    });
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', (e) => {
        handleClickOutside(e);
      });
      document.removeEventListener('click', (e) => {
        handleClickOutside(e);
      });
    };
  }, [dropdownWrapperRef]);

  const updateImageDisplay = (event, menu) => {
    if (event.target.files && event.target.files[0]) {
      const imageFile = event.target.files[0];
      menu.onClick(event, {
        ...row,
        attachment: imageFile,
      });

      setTimeout(() => {
        onClickOutside && onClickOutside();
      }, 50);
    }
  };

  const renderMenuName = (menu) => {
    if (menu.type === 'publish') {
      return row?.status?.toLowerCase() === 'active' ? 'Unpublish' : 'Publish';
    } else if (menu.type === 'active') {
      return row?.status?.toLowerCase() === 'active' ? 'Inactive' : 'Active';
    }

    return menu.name;
  };

  const renderPicker = (menu) => {
    return (
      <>
        <input
          ref={imageRef}
          type='file'
          accept={menu.fileType ? menu.fileType : ''}
          id='file'
          style={{ display: 'none' }}
          onChange={(e) => updateImageDisplay(e, menu)}
        />
        <p
          htmlFor='file'
          onClick={(e) => {
            if (imageRef && imageRef.current) {
              imageRef.current.click && imageRef.current.click();
            }
          }}
          className='Action-text'
        >
          {menu.name}
        </p>
      </>
    );
  };

  return (
    <div ref={dropdownWrapperRef} className='Action'>
      {menuAction &&
        menuAction.length > 0 &&
        menuAction.map((menu) => {
          if (menu.type === 'upload') {
            return renderPicker(menu);
          }
          return (
            <p
              onClick={(e) => {
                setTimeout(() => {
                  onClickOutside && onClickOutside();
                }, 50);
                menu.onClick(e, row);
              }}
              className='Action-text'
            >
              {renderMenuName(menu)}
            </p>
          );
        })}
    </div>
  );
};

export default ActionMenu;
