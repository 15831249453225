import NewInput from "app/components/NewInput";
import Button from "app/components/button";
import "./index.scss";
import { deleteUploadedMedia, uplaodMedia } from "app/api/cms-content";
import RadioButton from "app/shared/components/RadioButton";
import Dropdown from "app/shared/components/Dropdown";
import { useEffect, useState } from "react";
import {
  editStories,
  getCollectionsAPI,
  getCollectionsAPIforStory,
  postStories,
  getStoryLocalisationDetails,
  updateLocalisationStories,
} from "app/api/createContentAPI";
import Toast from "app/utils/Toast";
import { useHistory, useLocation, useParams } from "react-router";

import { imageSizeValidation } from "app/utils/Parsers";
import ValidationMessage from "../../utils/ValidationMessage";
import { CloseBlackIcon } from "constants/image-constants";
import TabBar from "app/components/NewDesign/TabBar";
import useTabBarOptions from "app/utils/useTabbarOptions";

const CreateStoryContainer = () => {
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const { LanguageOptions } = useTabBarOptions();
  const [collectionOptions, setCollectionOptions] = useState([]);
  const [radioSelected, setRadioSelected] = useState("COLLECTION");
  const [dropdown, setDropdown] = useState(null);
  const [activeTab, setActiveTab] = useState({
    id: "1",
    name: "English",
    short_name: "en",
    display_order: 1,
    status: "ACTIVE",
    value: "English",
  });
  const [hideFields, setHideFields] = useState(false);
  const radioButtonMenu = [
    {
      label: "Collection",
      value: "COLLECTION",
    },
    {
      label: "Quiz",
      value: "QUIZ",
    },
  ];

  const [storyInput, setStoryInput] = useState({
    story_title_one: {
      label: "Story Title 1",
      imageLabel: "Story Icon 1",
      image_id: "upload_one",
      image: "",
      text: "",
    },
    story_title_two: {
      label: "Story Title 2",
      imageLabel: "Story Icon 2",
      image_id: "upload_two",
      image: "",
      text: "",
    },
    story_title_three: {
      label: "Story Title 3",
      imageLabel: "Story Icon 3",
      image_id: "upload_three",
      image: "",
      text: "",
    },
    story_title_four: {
      label: "Story Title 4",
      imageLabel: "Story Icon 4",
      image_id: "upload_four",
      image: "",
      text: "",
    },
    story_title_five: {
      label: "Story Title 5",
      imageLabel: "Story Icon 5",
      image_id: "upload_five",
      image: "",
      text: "",
    },
    story_title_six: {
      label: "Story Title 6",
      imageLabel: "Story Icon six",
      image_id: "upload_six",
      image: "",
      text: "",
    },
    story_title_seven: {
      label: "Story Title 7",
      imageLabel: "Story Icon seven",
      image_id: "upload_seven",
      image: "",
      text: "",
    },
  });
  useEffect(() => {}, [storyInput]);

  const setStoryInputById = (key, subKey, value) => {
    setStoryInput({
      ...storyInput,
      [key]: { ...storyInput[key], [subKey]: value },
    });
  };

  const handleTabChange = (val) => {
    setActiveTab(val);
    if (val?.name === "English") {
      setHideFields(false);
    } else {
      setHideFields(true);
    }
  };

  const handleMediaChange = async (e, key, subKey) => {
    const file = e.target.files[0];
    if (
      (file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png") &&
      imageSizeValidation(e)
    ) {
      Toast.error(ValidationMessage.IMAGE_SIZE);
      return;
    }

    let resp = await uplaodMedia("media", file, "STORY");
    setStoryInputById(key, subKey, resp?.filename);
  };
  const fetchCollections = async () => {
    let res = await getCollectionsAPIforStory();
    setCollectionOptions(res);
  };

  const getStoryDetails = async (storyId, lang) => {
    let resp = await getStoryLocalisationDetails(storyId, lang);
    if (resp?.length > 0) {
      if (activeTab?.name === "English") {
        setRadioSelected(resp?.[0].story_type);
        setDropdown(Number(resp?.[0].collection_id));
      }
      let tempObj = { ...storyInput };
      let storyArray = Object.keys(storyInput);
      let apiArray = resp?.[0]["story_title"];
      storyArray.forEach(function (key, index) {
        tempObj = {
          ...tempObj,
          [key]: {
            ...tempObj[key],
            text: apiArray[index]["title"] ? apiArray[index]["title"] : "",
            image: apiArray[index]["image"] ? apiArray[index]["image"] : "",
          },
        };
      });
      setStoryInput(tempObj);
    }
  };

  useEffect(() => {
    fetchCollections();
    if (id) {
      getStoryDetails(id, activeTab?.short_name);
    }
  }, [activeTab?.short_name, id]);

  const handleSubmit = async () => {
    const tempArray = [];
    let tempObj;
    let storyArray = Object.keys(storyInput);
    for (let index in storyArray) {
      if (
        storyInput[storyArray[index]].text != "" &&
        storyInput[storyArray[index]].iamge != ""
      ) {
        tempObj = {
          title: storyInput[storyArray[index]].text,
          image: storyInput[storyArray[index]].image
            ? storyInput[storyArray[index]].image
            : "",
        };
        tempArray.push(tempObj);
      }
    }
    if (tempArray.length < 7) {
      Toast.error("Please complete all fields");
      return;
    }
    for (let ind in tempArray) {
      if (tempArray[ind]["title"] == "" || tempArray[ind]["image"] == "") {
        Toast.error("Please complete all fields");
        return;
      }
    }

    let data;
    let resp;
    if (id) {
      data = {
        story_type: radioSelected,
        collection_id: dropdown,
        story_title: tempArray,
        story_id: Number(id),
      };
      if (activeTab?.name === "English") {
        resp = await editStories(data);
      } else {
        resp = await updateLocalisationStories(data, activeTab?.short_name);
      }
    } else {
      data = {
        story_type: radioSelected,
        collection_id: dropdown,
        story_title: tempArray,
      };
      resp = await postStories(data);
    }

    if (resp.response?.code == 200) {
      Toast.success("Story created successfully");
      if (location.state?.comingFrom) {
        history.push({
          pathname: "/cms/discover",
          state: { goto: location.state?.comingFrom, selected: "2" },
        });
      } else {
        history.push({
          pathname: "/cms/discover",
        });
      }
    } else {
      Toast.error("Unable to create story");
    }
  };
  const cancelMedia = async (e, filename) => {
    // let resp = await deleteUploadedMedia(filename)
  };

  return (
    <>
      <div className="container">
        <h1 className="section-header__title">Discover/ Create Stories</h1>
      </div>
      <div className="separator">
        <p>Stories - Add Details</p>
      </div>
      {id ? (
        <TabBar
          tabs={LanguageOptions}
          initialTab={activeTab}
          onTabChange={handleTabChange}
          parentStyle={"service-tab-style"}
        />
      ) : null}
      <div className="userInput">
        {Object.keys(storyInput).map((item, index) => (
          <div key={index}>
            <NewInput
              label={storyInput[item].label}
              name={item}
              value={storyInput[item].text}
              onChange={(e) => {
                setStoryInputById(item, "text", e.target.value);
              }}
            />
            <div className="label-button">
              <input
                type="file"
                id={storyInput[item].image_id}
                style={{ display: "none" }}
                onChange={(e) => {
                  handleMediaChange(e, item, "image");
                }}
              />
              <label className="label-title-input">
                {storyInput[item].imageLabel} :{" "}
              </label>
              <Button
                isDisabled={storyInput[item].image}
                name={storyInput[item].image ? "Uploaded" : "Upload"}
                onClick={() => {
                  const m = document.getElementById(storyInput[item].image_id);
                  m.click();
                }}
              />
              <label
                role={"button"}
                className="label-title-input"
                style={{ paddingLeft: "10px" }}
                onClick={(e) => {
                  if (storyInput[item].image != "") {
                    cancelMedia(e, storyInput[item].image);
                    setStoryInputById(item, "image", "");
                  }
                }}
              >
                {storyInput[item].image != "" ? "Cancel Upload" : ""}
              </label>

              {storyInput[item].image && (
                <div className="d-inline-block">
                  <div className="attachment_img_wrapper">
                    <img
                      src={`${process.env.REACT_APP_S3_BUCKET_URL}/${storyInput[item].image}`}
                      alt="sample"
                      className="feed__attachment"
                    />
                    <div className="attachment_img_wrapper_close">
                      <img
                        src={CloseBlackIcon}
                        alt="Remove"
                        onClick={(e) => {
                          cancelMedia(e, storyInput[item].image);
                          setStoryInputById(item, "image", "");
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
        {!hideFields && (
          <div className="userFormArea">
            {radioButtonMenu.map((item, key) => (
              <RadioButton
                id={item.value}
                label={item.label}
                isChecked={radioSelected == item.value ? true : false}
                value={item.value}
                onSelect={() => {
                  setRadioSelected(item.value);
                }}
                name={item.label}
              />
            ))}
          </div>
        )}

        {!hideFields && radioSelected == "COLLECTION" && (
          // <Dropdown
          //     options={collectionOptions}
          //     label={"Collection"}
          //     name="collection_id"
          //     value={dropdown}
          //     handleChange={(e) => {
          //         console.log(e.target.value)
          //         setDropdown(String(e.target.value))
          //     }}
          // />
          <select
            name="collection_id"
            className="custom-select"
            onChange={(e) => {
              setDropdown(Number(e.target.value));
            }}
          >
            <option value="">Select</option>
            {collectionOptions?.map((item, indx) => (
              <option
                key={indx}
                value={item?.id}
                selected={dropdown == item?.id ? true : false}
              >
                {item?.id + "-" + item?.collection_name}
              </option>
            ))}
          </select>
        )}
      </div>
      <div className="userFormArea">
        <Button
          name={location.state ? "Edit Stories" : "Create Stories"}
          onClick={() => {
            handleSubmit();
          }}
        />
        <label
          style={{ paddingLeft: "10px", cursor: "pointer" }}
          onClick={() => {
            if (location.state?.comingFrom) {
              history.push({
                pathname: "/cms/discover",
                state: { goto: location.state?.comingFrom, selected: "2" },
              });
            } else {
              history.push({
                pathname: "/cms/discover",
              });
            }
          }}
        >
          Cancel
        </label>
      </div>
    </>
  );
};

export default CreateStoryContainer;
