import CreateGroupContainer from "app/container/CreateGroupContainer";
import BasePage from "app/pages/BasePage";
const CreateGroups = () => {
    return (
        <BasePage>
            <CreateGroupContainer />
        </BasePage>
    );
}

export default CreateGroups;