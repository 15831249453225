// Pages
import HomePageOrderingContainer from 'app/container/HomePageOrdering';
import BasePage from 'pages/BasePage';

import { HomePageHeader } from 'constants/table-header-constants';
import HomePageOrderContainer from 'app/container/HomePageOrder';

const HomePageOrdering = () => {
  return (
    <BasePage>
      <HomePageOrderingContainer tableHeader={HomePageHeader} />
    </BasePage>
  );
};

export default HomePageOrdering;
