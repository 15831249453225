import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import './index.scss';
import Toggle from 'react-toggle';

function CustomTable({ data, tableHeader, currentPage, handleUnpublish }) {
  const history = useHistory();

  const [toggleStates, setToggleStates] = useState({});

  useEffect(() => {
    const initialToggleStates = {};
    data.forEach((item) => {
      initialToggleStates[item.id] = item.publish_status === 'ACTIVE';
    });
    setToggleStates(initialToggleStates);
  }, [data]);

  const handleNavigation = (items) => {
    history.push({
      pathname: `/courses/create/edit/${items.id}`,
      state: {
        ...items,
      },
    });
  };

  const handleToggleChange = (data, isChecked) => {
    setToggleStates((prevState) => ({
      ...prevState,
      [data.id]: isChecked,
    }));

    handleUnpublish(data, isChecked);
  };

  const joinCommaCohortAge = (item) => {
    return item.join(', ');
  };

  return (
    <table className='left-aligne-table'>
      <tr>
        {tableHeader.map((item, i) => {
          return <th key={item.Header}>{item.Header}</th>;
        })}
      </tr>
      {data.map((items, index) => (
        <tr key={index}>
          <td>{items.title ? items.title.toString() : '-'}</td>
          <td>{items?.total_chapters ? items?.total_chapters : '-'}</td>
          <td>{items.cohorts ? joinCommaCohortAge(items.cohorts) : '-'}</td>
          <td>{items.age_range ? joinCommaCohortAge(items.age_range) : '-'}</td>
          <td>
            {items.tags && (
              <ul className='badge-list'>
                {items.tags.map((ele, index) => {
                  return <li key={index}>{ele}</li>;
                })}
              </ul>
            )}
          </td>
          <td>
            <Toggle
              checked={toggleStates[items.id] || false}
              icons={false}
              name={items.id}
              onChange={() =>
                handleToggleChange(items, !toggleStates[items.id])
              }
            />
          </td>
          <td>
            <div
              className='btn-group'
              role='group'
              aria-label='Basic outlined example'
            >
              <button
                type='button'
                className='btn btn-outline-primary'
                onClick={() => handleNavigation(items)}
              >
                Edit
              </button>
            </div>
          </td>
        </tr>
      ))}
      {data.length === 0 && (
        <tr>
          <td colspan='10000'>No data found</td>{' '}
        </tr>
      )}
    </table>
  );
}

export default CustomTable;
