import { CoursesOrderingTableHeader } from 'app/constants/table-header-constants';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import CustomDragabbleTable from './CustomDragabbleTable';
import useCoursesOrdering from './useCoursesOrdering';
import PageLoader from 'app/components/PageLoader';

const CoursesOrderingPageContainer = () => {
  const {
    pageLoading,
    handleDragEnd,
    selectedItems,
    isSubmitClicked,
    handleSelectedItemsChange,
  } = useCoursesOrdering();

  return (
    <div className=''>
      <h3>Courses Landing Page ordering</h3>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId='droppable' direction='vertical'>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <CustomDragabbleTable
                data={selectedItems}
                draggableTableHeader={CoursesOrderingTableHeader}
                isSubmitClicked={isSubmitClicked}
                onSelectedItemsChange={handleSelectedItemsChange}
              />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </div>
  );
};

export default CoursesOrderingPageContainer;
