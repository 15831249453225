import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import './index.scss';
import { useEffect, useState } from 'react';

const CategoriesCard = ({
  activeShow = false,
  category_id = '',
  keyValue,
  index,
  children,
  toggle = null,
  handleResourseToggle,
}) => {
  const [first, setfirst] = useState(null);
  useEffect(() => {
    setfirst(toggle);
  }, [toggle]);
  return (
    <div keyValue={keyValue} className='CategoriesCard-container'>
      <div className='CategoriesCard-container__section-one'>
        <div className='card__header'>
          <p className='CategoriesCard-container__section'>
            {`Categories ${index + 1}`}
          </p>
          <span>
            {' '}
            {activeShow && (
              <Toggle
                checked={first}
                icons={false}
                onChange={(e) => {
                  e.preventDefault();
                  handleResourseToggle(index, keyValue, category_id);
                }}
              />
            )}
          </span>
        </div>
        <div className='CategoriesCard-container__CategoriesCard'>
          <div className='CategoriesCard-container__Image-Container'>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriesCard;
