import React, { useState, useEffect, useRef } from 'react';
import NewInput from 'app/components/NewInput';
import MultiSelectDropdown from 'app/components/MultiSelectDropdown';
import Button from 'app/components/button';
import { useHistory, useParams, useLocation } from 'react-router-dom'; 
import 'react-time-picker/dist/TimePicker.css';
import Toggle from 'react-toggle';
import 'react-clock/dist/Clock.css';
import Modal from 'react-bootstrap/Modal';
import {
  createLandingPageScreen, 
  updateLandingPageScreen,
  getWidgetType,
  uplaodMedia,
  deleteLandingPageSectionById
} from 'app/api/widgets';  
import Toast from 'app/utils/Toast';
import Loader from 'components/Loader';
import ValidationMessage from 'app/utils/ValidationMessage';
import { imageSizeValidation } from 'app/utils/Parsers';
import { CloseBlackIcon } from 'app/constants/image-constants';

// Constants
import { APP_CONSTANTS } from 'constants/app-constants'; 
import HeaderWithBackButton from 'app/components/HeaderWithBackButton';
import NewHtmlEditor from 'app/components/NewDesign/NewHtmlEdior';
import FaqActionMenu from 'app/container/CreateFaq/FaqActionMenu';
import NumberInput from 'components/UI/NumberInput';
import DraggableTable from './Draggabletable';
import HomePageOrderContainer from './HomePageOrder';
import NewQuillTextEditor from 'app/shared/components/NewQuillTextEditor';
import Dropdown from 'shared/components/Dropdown';
import { getModule } from 'api/faq';
const CreateLandingScreen = () => {
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const editorRef = useRef(null); 
  const [loader, setLoader] = useState(false);
  const [cohortOptions, setCohortOptions] = useState([]);
  const [ageOptions, setAgeOptions] = useState([]);
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [programfeature, setProgramfeature] = useState(null);
  const [count, setCount] = useState(1); 
  const [show, setShow] = useState(false);
  const [currentItem, setCurrentItem] = useState({})
  const [currentIndex, setCurrentIndex] = useState(null)
  const [faqlistData, setfaqlistData] = useState([
    {
      faq_index_Id: Date.now(), // Basically its a index
      id: null,
      isNew: true,
      question: '',
      answer: '',
      sequence_order: '',
      toggle: false,
      wasEdited: false,
    },
  ]);
  const [isPublishButtonDisabled, setisPublishButtonDisabled] = useState(null);

  const [activeShow, setActiveShow] = useState(true);
  const [activeId, setActiveId] = useState('');
  const [sectionList, setSectionList] = useState([])
  const [faqModules, setFaqModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState('');
  const [state, setState] = useState({
    page_name: '',
    plan_name: '',
    plan_sub_details: '',
    discounted_price: '',
    selling_price: '',
    discount: '',
    payment_link: '',
    cohort_tags: [],
    age_tags: [],
    thumbnail_image: '',
    program_features: '',
    visibility: true,
    
  });
  

  useEffect(() => { 
    if (params.id) {
      getDetailsByid(params.id);
    }
  }, []);
  
  const handleTextChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    getDropdown();
    if (params.id) {
      getDetailsByid(params.id);
    }
  }, []);

useEffect(() => {
  const fetchFaqModules = async () => {
    getModule(1,100).then((response)=>{
      const moduleOptions = response?.tableData.map(module => ({
        value: module.module_id.toString(),
        label: `${module.module_id} - ${module.module_name}`
      }));
  
      setFaqModules(moduleOptions);
    })
  };
  fetchFaqModules();
}, []);
const handleModuleChange = (e) => {
  setSelectedModule(e.target.value);
};
  const getDropdown = async () => {
    // let widgetDetails = location.state;
    setLoader(true);

    const dataArr = await getWidgetType(); 
    let tempAgeArray = [];
    let tempCohortArray = [];
     
    dataArr.data.age_tags.forEach((item, i) => {
      tempAgeArray.push({
        name: item,
        value: item,
      });
    });
    dataArr.data.cohort_tags.forEach((item, i) => {
      tempCohortArray.push({
        name: item,
        value: item,
      });
    });

    // setWidgetTypes(tempWidgetArray);
    setCohortOptions(tempCohortArray);
    setAgeOptions(tempAgeArray);

    setLoader(false);
  };

  const handleSubmit = async () => {
    let {page_name, cohort_tags, age_tags,visibility  } = state
    let section_list = []
    sectionList.map((item, i) => {
      let tempobject = {
        android_version: Number(item.android_version),
        criteria:item.criteria,
        ios_version:Number(item.ios_version),
        ios_criteria:item.ios_criteria,
        sorting_order: i+1
      }
      
      if(item.section_id){
        tempobject.section_id = item.section_id
      }else if(item.widget_id){
        tempobject.widget_id = item.widget_id
      }
      if(params.id){
        tempobject.status= item.status
        if(item.editid){
          tempobject.id=item.editid 
        }
        
      }
      section_list.push(tempobject)

    })

    let payload = { 
      page_name, 
      cohort_tags, 
      age_tags,
      section_list, 
      faq_id: parseInt(selectedModule) ? parseInt(selectedModule):null
    } 
    if (!state.page_name || section_list.length ===0 || cohort_tags.length ===0 || age_tags.length ===0) {
      Toast.error('Please complete required fields');
      return;
    } else {
      setLoader(true);
      let responseObj;
      if (params.id) {
        payload.landing_page_id = Number(params.id)
        payload.status= visibility?'ACTIVE':'INACTIVE';
        responseObj = await updateLandingPageScreen(payload, params.id);
      } else {
        responseObj = await createLandingPageScreen(payload);
      } 

      if (
        responseObj &&
        responseObj.response &&
        responseObj.response.code === 200
      ) {
        Toast.success(responseObj.response.alert[0].message);
        history.push('/landing-page');
      } else {
        setLoader(false);
        Toast.error(
          responseObj.data && responseObj.data.msg
            ? responseObj.data.msg
            : responseObj.response.alert[0].message
        );
      }
    }
  };
  const getDetailsByid = async (id) => {
    let landingpagedetails = location.state 
    setState({
       ...state,
       page_name: landingpagedetails.page_name, 
       cohort_tags: landingpagedetails.cohort_tags,
       age_tags: landingpagedetails.age_tags, 
       status:landingpagedetails.status
    })
    let tempSectionlist = []
    landingpagedetails.section_list.map((item,i) => {
      tempSectionlist.push({
        android_version: item.android_version?item.android_version.toString():'',
        criteria:item.criteria?item.criteria:'',
        ios_version:item.ios_version?item.ios_version.toString():'',
        ios_criteria:item.ios_criteria?item.ios_criteria:'',
        sorting_order:  item.sorting_order?item.sorting_order:'',
        status:item.status?item.status:'',
        widget_id:item.widget_id?item.widget_id:'',
        section_id:item.section_id?item.section_id:'',
        section_name:item.section_name?item.section_name:'',
        widget_name:item.widget_name?item.widget_name:'',
        id:(i+1).toString(),
        editid:item.id?item.id:'',
        last_edited_time:item.last_edited_time?item.last_edited_time:'',
        last_updated_by:item.last_updated_by?item.last_updated_by:''

      })
    })
    setSectionList(tempSectionlist);
    setSelectedModule(landingpagedetails.faq_id.toString())
    // const userLandingDetails = await getUserLandingByID(id);
    // setState({
    //   cohort: userLandingDetails.cohort,
    //   section: userLandingDetails.section,
    // });
    // setStart_Time(userLandingDetails.start_time);
    // setEnd_Time(userLandingDetails.end_time);
  };

  const cancelMedia = async (e, file) => {
    setState({ ...state, [e.target.name]: '' });
  };

  const handleMediaUpload = async (e, index) => {
    if (!e) return;
    let file;
    let reader = new FileReader();
    if (e?.target?.name === 'thumbnail_image') {
      file = e.target.files[0];
      if (
        (file.type === 'image/jpeg' ||
          file.type === 'image/jpg' ||
          file.type === 'image/png') &&
        imageSizeValidation(e)
      ) {
        Toast.error(ValidationMessage.IMAGE_SIZE);
        return;
      } else {
        let resp = await uplaodMedia('image', file);

        if (e.target.name === 'thumbnail_image') {
          setState({ ...state, [e.target.name]: resp?.filename });
          let reader = new FileReader();
          reader.onloadend = function () {
            setThumbnailImage(reader.result);
          };
          reader.readAsDataURL(file);
        }

        Toast.success(file?.name + ' uploaded');
      }
    } else if (e?.target?.name === 'program_features') {
      file = e.target.files[0];
      if (
        (file.type === 'image/jpeg' ||
          file.type === 'image/jpg' ||
          file.type === 'image/png') &&
        file.size >= 100000
      ) {
        Toast.error(ValidationMessage.IMAGE_SIZE);
        return;
      } else {
        let resp = await uplaodMedia('image', file);
        if (e.target.name === 'program_features') {
          setState({ ...state, [e.target.name]: resp?.filename });
          let reader = new FileReader();
          reader.onloadend = function () {
            setProgramfeature(reader.result);
          };
          reader.readAsDataURL(file);
        }
        Toast.success(file?.name + ' uploaded');
      }
    }
  };

  const addfaqSection = () => {
    setisPublishButtonDisabled(false);
    const temp = [...faqlistData];

    temp.push({
      faq_index_Id: Date.now(), // Basically its a index
      id: null,
      isNew: true,
      question: '',
      answer: '',
      sequence_order: '',
      toggle: false,
      wasEdited: false,
    });
    setCount(count + 1);

    setfaqlistData(temp);
  };

  const removefaqSection = (ind) => {
    const temp = [];
    let removedServiceTemp = [...faqlistData];
    faqlistData.forEach((item, index) => {
      if (ind !== index) {
        temp.push(item);
      }
      if (ind === index && item.id) {
        removedServiceTemp.push(parseInt(item.id));
      }
    });

    setCount(temp.length);
    if (removedServiceTemp >= 1) {
      setisPublishButtonDisabled(false);
    } else {
      setisPublishButtonDisabled(true);
    }
    setfaqlistData(temp);
  };
 
  const onClickOutside = () => {
    setActiveId('');
  };

  const menuActionObj = [
    {
      name: 'Delete',
      onClick: (e, data) => { 
        // deleteFaq(data?.id);
      },
    },
    {
      name: 'Not now',
      onClick: (e, data, index) => {
        onClickOutside();
      },
    },
  ];

  const OnhandleChange = (e) => {
    const { name, value, id } = e.target;
    if (value) setisPublishButtonDisabled(false);
    const valueCpy = [...faqlistData];

    valueCpy.forEach((element, index) => {
      if (`${element.faq_index_Id}` === `${id}`) {
        if (element.id !== null) {
          element.wasEdited = true;
        }
        if (name === 'sequence_order') {
          element[name] = value && parseInt(value);
          return;
        }

        element[name] = value;
      }

      return element;
    });
    setfaqlistData([...valueCpy]);
  };

  const handleEditorChange = (name, value, id, editor) => {
    // const charCount = (editor) => editor.getContent({ format: 'text' }).length;
    // let checkcount = charCount(editor);
    const charCount = editor.ops[0].retain;
    if (charCount > 500) {
      Toast.error('Answer Count must be under 500');
      return;
    }

    if (value) setisPublishButtonDisabled(false);
    const valueCpy = [...faqlistData];

    valueCpy.forEach((element, index) => {
      if (`${element.faq_index_Id}` === `${id}`) {
        if (element.id !== null) {
          element.wasEdited = true;
        }
        element[name] = value;
      }

      return element;
    });
    setfaqlistData([...valueCpy]);
  };

  const handleResourseToggle = (id, itemindex) => {
    setisPublishButtonDisabled(false);
    let newData = [...faqlistData];

    if (newData && newData.length > 0) {
      newData = newData.map((item, index) => {
        if (index === itemindex) {
          if (item.id !== null) {
            item.wasEdited = true;
          }
          item.toggle = !item.toggle;
        }
        return item;
      });
      setfaqlistData(newData);
    }
  };

  const handleAddSectionORWidget = () => {
    setShow(true);
    setCurrentItem({})
  };

  const handleClose = () => { 
    setShow(false);
    setCurrentItem({})
  };

  const handleWidgetSession = (item) => {
    setShow(false); 
  let  temp = [...sectionList] 
  if(currentIndex){ 
    temp[currentIndex]=item 
  }else{
    temp.push(item) 
  }
    setSectionList(temp)
    setCurrentIndex(null)
  }
  const handleNavigation = (item, index) => {
    setShow(true); 
    setCurrentItem(item)
    setCurrentIndex(index)
  }
  const handleChangeOrdering = (data) => {
    setSectionList(data)
  }

  const handleDelete = async (deleteid) => {
    let text = 'Are you sure, want to delete';
    if (window.confirm(text) == true) { 
      setLoader(true);
      await deleteLandingPageSectionById(deleteid); 
      let sectionlistcopy = [...sectionList]
      const findIndex = sectionlistcopy.findIndex(
        (item) => Number(item.editid) === deleteid
      );
      sectionlistcopy.splice(findIndex, 1);
      setSectionList(sectionlistcopy)
      let landingpagedetails = location.state  
      landingpagedetails.section_list = sectionlistcopy
      history.push({
        pathname: `/landing-page/edit/${params.id}`,
        state: {
          ...landingpagedetails,
        },
      }); 
      setLoader(false);
    }
  };
  const handleClearModule = () => {
    setSelectedModule(null);
  };
  if (loader === true) {
    return <Loader />;
  }
  return (
    <div className='container'>
      <HeaderWithBackButton
        title=' Landing Pages \ Landing Pages Listing Screen \ Create Screen'
        goTo={APP_CONSTANTS.LANDING_PAGE}
      />
      {/* <div className='row'>
        <h1 className='section-header__title'>
          Landing Pages \ Landing Pages Listing Screen \ Create Screen
        </h1>
      </div> */}
      <div className='row'>
        <div className='col-sm-12 '>
          <div className='userInput'>
            <NewInput
              label='Page Name'
              name={'page_name'}
              value={state.page_name}
              onChange={handleTextChange}
              asterisk={true}
            />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6'>
          <MultiSelectDropdown
            label='Cohort Tag'
            name='cohort_tags'
            value={state.cohort_tags}
            options={cohortOptions}
            onChange={handleTextChange}
            tag_type={false}
            asterisk={true}
            // disable={disableFields}
          />
        </div>
        <div className='col-md-6'>
          <MultiSelectDropdown
            label='Age Tag'
            name='age_tags'
            value={state.age_tags}
            options={ageOptions}
            onChange={handleTextChange}
            tag_type={false}
            asterisk={true}
            // disable={disableFields}
          />
        </div>
      </div>
      <div className='row '>
        <div className='col-sm-12'>
          <div className='separator'>
            <p>Plan Details</p>
          </div>
        </div>
      </div>
      <div className='border p-3'>
        <div className='row'>
          <div className='mt-4'>
            {state.thumbnail_image && (
              <p
                className='cancel'
                onClick={(e) => {
                  if (state.thumbnail_image) {
                    cancelMedia(e, state.thumbnail_image);
                  }
                  setThumbnailImage(null);
                  setState({ ...state, thumbnail_image: '' });
                }}
              >
                Cancel Thumbnail
              </p>
            )}
          </div>
          <div className='upload-buttons '>
            <input
              type='file'
              id='thumbnailFile'
              style={{ display: 'none' }}
              name='thumbnail_image'
              onChange={(e) => {
                handleMediaUpload(e);
              }}
            />
            <div className='upload-label'>
              <label className='mb-2'>Thumbnail </label>
              <Button
                onClick={() => {
                  const image = document.getElementById('thumbnailFile');
                  image.click();
                }}
                isDisabled={state.thumbnail_image}
                type='button'
                name={
                  state.thumbnail_image != ''
                    ? 'Uploaded'
                    : 'Upload Thumbnail Image'
                }
              />
            </div>
            {thumbnailImage && (
              <div className='attachment_img_wrapper'>
                <img
                  src={thumbnailImage}
                  alt='sample'
                  className='feed__attachment'
                />
                <div className='attachment_img_wrapper_close'>
                  <img
                    src={CloseBlackIcon}
                    alt='Remove'
                    onClick={(e) => {
                      if (state.thumbnail_image) {
                        cancelMedia(e, state.thumbnail_image);
                      }
                      setThumbnailImage(null);
                      setState({ ...state, thumbnail_image: '' });
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='row pb-4'>
          <div className='col-sm-12 '>
            <div className='userInput'>
              <NewInput
                label='Plan Name'
                name={'plan_name'}
                value={state.plan_name}
                onChange={handleTextChange}
              />
            </div>
          </div>
          <div className='col-sm-12 '>
            <div className='userInput'>
              <NewInput
                label='Plan sub details'
                name={'plan_sub_details'}
                value={state.plan_sub_details}
                onChange={handleTextChange}
              />
            </div>
          </div>
          <div className='col-sm-12 '>
            <div className='userInput'>
              <NewInput
                label='Discounted Price'
                name={'discounted_price'}
                value={state.discounted_price}
                onChange={handleTextChange}
              />
            </div>
          </div>
          <div className='col-sm-12 '>
            <div className='userInput'>
              <NewInput
                label='Selling Price'
                name={'selling_price'}
                value={state.selling_price}
                onChange={handleTextChange}
              />
            </div>
          </div>
          <div className='col-sm-12 '>
            <div className='userInput'>
              <NewInput
                label='Discount %'
                name={'discount'}
                value={state.discount}
                onChange={handleTextChange}
              />
            </div>
          </div>
          <div className='col-sm-12 '>
            <div className='userInput'>
              <NewInput
                label='Payment Link'
                name={'payment_link'}
                value={state.payment_link}
                onChange={handleTextChange}
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='mt-4'>
            {state.program_features && (
              <p
                className='cancel'
                onClick={(e) => {
                  if (state.program_features) {
                    cancelMedia(e, state.program_features);
                  }
                  setProgramfeature(null);
                  setState({ ...state, program_features: '' });
                }}
              >
                Cancel Image
              </p>
            )}
          </div>
          <div className='upload-buttons '>
            <input
              type='file'
              id='program_features'
              style={{ display: 'none' }}
              name='program_features'
              onChange={(e) => {
                handleMediaUpload(e);
              }}
            />
            <div className='upload-label'>
              <label className='mb-2'>Program Features </label>
              <Button
                onClick={() => {
                  const image = document.getElementById('program_features');
                  image.click();
                }}
                isDisabled={state.program_features}
                type='button'
                name={
                  state.program_features != '' ? 'Uploaded' : 'Upload Image'
                }
              />
            </div>
            {programfeature && (
              <div className='attachment_img_wrapper'>
                <img
                  src={programfeature}
                  alt='sample'
                  className='feed__attachment'
                />
                <div className='attachment_img_wrapper_close'>
                  <img
                    src={CloseBlackIcon}
                    alt='Remove'
                    onClick={(e) => {
                      if (state.program_features) {
                        cancelMedia(e, state.program_features);
                      }
                      setProgramfeature(null);
                      setState({ ...state, program_features: '' });
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='row'>
          <div className='d-block my-4'>
            <span className='me-2 d-inline-block'>Visibility</span>
            <label className='d-inline-block mt-2 mx-4'>
              <div className='d-inline-flex align-items-center'>
                <div className='d-inline-flex text-danger'>Inactive</div>
                <div className='d-inline-flex mx-2'>
                  <Toggle
                    checked={state.visibility}
                    icons={false}
                    name='visibility'
                    onChange={(e) => {
                      setState({ ...state, visibility: !state.visibility });
                    }}
                  />
                </div>
                <div className='d-inline-flex text-green'>Active</div>
              </div>
            </label>
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-sm-12'>
          <div className='separator'>
            <p>FAQs</p>
          </div>
        </div>
      </div>
      <div className='row d-flex align-items-end'>
        <div className='col-sm-11 '>
       
      
        <Dropdown
          id="faq_module"
          label="Select FAQ Module (Optional)"
          name="faq_module"
          options={faqModules}
          value={selectedModule}
          handleChange={handleModuleChange}
        />
      
      
    
        </div>
        <div className='col-sm-1 '>
      {selectedModule && (
        <div className='mb-2'>
          <img className='cursor-pointer' onClick={handleClearModule} src={CloseBlackIcon}/>
        </div>
      )}
      </div>
      </div>
      <div className='row'>
        <div className='col-sm-12'>
          <div className='separator'>
            <p>List of Sections</p>
          </div>
        </div>
      </div>
      <div className='row  pb-4'>
        <div className='col-sm-12 '>
          <div className='border p-3'>
            <div className='content-container__actionBox'>
              <div className='content-container__filterSearch'>
                {/* <h4>List of Sections</h4> */}
              </div>
              <Button
                onClick={() => {
                  handleAddSectionORWidget();
                }}
                className='content-container__filter-btn'
              >
                Add Section / Widget
              </Button>
            </div>
            <div className='row'>
              <div className='col-sm-12 my-3'>
                <DraggableTable sectionList={sectionList} handleNavigation={handleNavigation} changeOrdering={handleChangeOrdering} handleDelete={(deleteid) => handleDelete(deleteid)}/>
              </div>
            </div>
          </div>
        </div>
        <div className='col-sm-12'>
           <div className='userFormArea '>
              <Button name={'Save'} onClick={handleSubmit} />
              <label
                style={{ paddingLeft: '10px', cursor: 'pointer' }}
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancel
              </label>
            </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          size='xl'
          aria-labelledby='contained-modal-title-vcenter'
          centered
        >
          <Modal.Body>
            <HomePageOrderContainer handleWidgetSession={handleWidgetSession} currentItem={currentItem} sectionList={sectionList}/>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default CreateLandingScreen;
