import {
    createContentAPI,
    getContentFromId,
    getTagsApi,
    updateContentAPI,
} from 'app/api/createContentAPI';
import Calender from 'app/components/Calender';
import MultiSelectDropdown from 'app/components/MultiSelectDropdown';
import NewInput from 'app/components/NewInput';
import PageLoader from 'app/components/PageLoader';
import TextArea from 'app/components/Textarea';
import Button from 'app/components/button';
import Message from 'app/utils/Message';
import Toast from 'app/utils/Toast';
import { calendarValidation } from 'app/utils/dateChecker';
import { CloseBlackIcon } from 'constants/image-constants';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
    imageSizeValidation,
    checkIfUrl,
    urlParser,
    removeNullValues,
} from '../../../utils/Parsers';
import ValidationMessage from '../../../utils/ValidationMessage';

import '../index.scss';


const External = ({ data, activeTab }) => {
    const [activate, setActivate] = useState(true);

    const [validated, setValidated] = useState(true);
    const [loader, setLoader] = useState(false);
    const [options, setOptions] = useState(null);
    const [selImage, setSelImage] = useState(null);
    const [selVideo, setSelVideo] = useState(null);
    const [thumbnailImage, setThumbnailImage] = useState(null);

    const history = useHistory();
    const [state, setState] = useState({
        title: '',
        description: '',
        powered_by: '',
        url: '',
        endless_scroll_cta: '',
        endless_scroll_media: '',
        thumbnail_image: '',
        start_date: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        end_date: '',
        tags: [],
        hex_code: '#323739',
    });

    const [disabledKeys, setDisabled] = useState({
        internalApp: true,
        externalRedirect: true,
        cta: true,
    })

    const validateForm = () => {
        let val = calendarValidation(state.start_date, state.end_date);
        if (!val) {
            Toast.error('Start date is much larger than end date');
            return;
        }
        let flag = true;
        let validationArray = Object.keys(state);
        let optionalFields = ['end_date', 'description', 'powered_by'];

        for (let index in validationArray) {
            if (
                state[validationArray[index]] === '' &&
                !optionalFields.includes(validationArray[index])
            ) {
                flag = false;
                Toast.error('Please complete all fields');
                break;
            } else if (
                validationArray[index] === 'tags' &&
                state.tags?.length === 0
            ) {
                flag = false;
                Toast.error('Please complete all fields');
                break;
            }
        }

        return flag;
    };

    const updateStates = async () => {
        if (data) {
            if (!data?.id || !activeTab) {
                return;
            }
            setSelImage(null);
            setSelVideo(null);
            setState({
                title: '',
                description: '',
                powered_by: '',
                url: '',
                endless_scroll_cta: '',
                endless_scroll_media: '',
                thumbnail_image: '',
                start_date: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
                end_date: '',
                tags: [],
                hex_code: '#323739',
            });
            let response = await getContentFromId(data.id, activeTab);
            let requiredResponse = response.data[0];

            let stateValues = Object.keys(state);

            let thumbnail_image = requiredResponse?.thumbnail_image
                ? `${process.env.REACT_APP_S3_BUCKET_URL}/${requiredResponse?.thumbnail_image}`
                : null;
            setThumbnailImage(thumbnail_image);

            let endless_scroll_media = requiredResponse?.endless_scroll_media
                ? `${process.env.REACT_APP_S3_BUCKET_URL}/${requiredResponse?.endless_scroll_media}`
                : null;
            let filetype = requiredResponse?.endless_scroll_media
                ? requiredResponse?.endless_scroll_media?.split('.')
                : null;

            if (filetype) {
                if (
                    filetype[1] === 'jpeg' ||
                    filetype[1] === 'jpg' ||
                    filetype[1] === 'png'
                ) {
                    setSelImage(endless_scroll_media);
                } else {
                    setSelVideo(endless_scroll_media);
                }
            }

            let temporaryState = {};
            for (let index in stateValues) {
                temporaryState[stateValues[index]] =
                    requiredResponse[stateValues[index]];
            }

            setState(temporaryState);
        }
    };
    useEffect(() => {
        updateStates();
    }, [activeTab]);

    const handleTextChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const handleMediaUpload = (e) => {
        let file = e.target.files[0];
        if (
            (file.type === 'image/jpeg' ||
                file.type === 'image/jpg' ||
                file.type === 'image/png') &&
            imageSizeValidation(e)
        ) {
            Toast.error(ValidationMessage.IMAGE_SIZE);
            return;
        } else {
            setState({ ...state, [e.target.name]: file });
            if (e.target.name === 'endless_scroll_media') {
                if (file.type === 'video/mp4') {
                    let reader = new FileReader();
                    reader.onloadend = function () {
                        setSelVideo(reader.result);
                    };
                    reader.readAsDataURL(file);
                } else {
                    let reader = new FileReader();
                    reader.onloadend = function () {
                        setSelImage(reader.result);
                    };
                    reader.readAsDataURL(file);
                }
            } else if (e.target.name === 'thumbnail_image') {
                let reader = new FileReader();
                reader.onloadend = function () {
                    setThumbnailImage(reader.result);
                };
                reader.readAsDataURL(file);
            }

            Toast.success(file?.name + ' uploaded');
        }
    };

    const handleSubmit = async () => {
        if (activeTab === 'en') {
            let validated = validateForm();
            if (!validated) {
                return;
            }
        }
        let dataPayload;
        let responseObj;
        if (data.id) {
            dataPayload = {
                ...state,
                content_type: 'EXTERNAL_ARTICLE',
                tags: JSON.stringify(state.tags),
                content_id: data.id,
            };
            if (activeTab === 'en') {
                dataPayload.start_date = moment(dataPayload?.start_date).format(
                    'YYYY-MM-DD HH:mm:ss'
                );
                dataPayload.end_date = moment(dataPayload?.end_date).format(
                    'YYYY-MM-DD HH:mm:ss'
                );
                if (
                    (dataPayload?.endless_scroll_media === '' &&
                        dataPayload?.youtube_url === null) ||
                    dataPayload?.thumbnail_image === ''
                ) {
                    Toast.error('Please complete all fields');
                    return;
                }
            }
            if (activeTab !== 'en') {
                delete dataPayload?.start_date;
                delete dataPayload?.end_date;
                delete dataPayload?.tags;
            }
            setLoader(true);
            let temporaryStates = removeNullValues(dataPayload);

            responseObj = await updateContentAPI(
                temporaryStates,
                activeTab,
                data?.id
            );
            setLoader(false);
        } else {
            dataPayload = {
                ...state,
                content_type: 'EXTERNAL_ARTICLE',
                tags: JSON.stringify(state.tags),
            };

            setLoader(true);
            responseObj = await createContentAPI(dataPayload);
        }
        setLoader(false);
        if (
            responseObj &&
            responseObj.response &&
            responseObj.response.code === 200
        ) {
            // LocalStorage.remove(LocalSessionKey.FEED_ID);
            Toast.success(Message.External.EXTERNAL_SUCCESS);
            if (data) {
                history.push({
                    pathname: '/cms/content',
                    state: { goto: data.comingFrom },
                });
                return;
            } else {
                history.push('/cms/content');
            }
        } else {
            Toast.error(Message.External.EXTERNAL_FAIL);
        }
    };
    const loadTagsfromApi = async () => {
        const tagValues = await getTagsApi();
        setOptions(tagValues);
    };
    useEffect(() => {
        loadTagsfromApi();
    }, []);

    const cancelMedia = async (e, file) => {
        setState({ ...state, [e.target.name]: '' });
    };

    return (
        <>
            <div className='separator'>
                <p>External Article - Add Details</p>
            </div>

            <div className='userInput'>
                <NewInput
                    label='Title'
                    name={'title'}
                    value={state.title ? state.title : ''}
                    onChange={handleTextChange}
                />
                <TextArea
                    label={'Short Description'}
                    value={state.description ? state.description : ''}
                    name={'description'}
                    onChange={handleTextChange}
                />
                <NewInput
                    label='Powered By'
                    value={state.powered_by ? state.powered_by : ''}
                    name='powered_by'
                    onChange={handleTextChange}
                />
                <NewInput
                    label='Article Link'
                    value={state?.url ? state?.url : ''}
                    name='url'
                    onChange={handleTextChange}
                />
                <NewInput
                    label='Endless Scroll CTA'
                    value={state?.endless_scroll_cta ? state?.endless_scroll_cta : ''}
                    name={'endless_scroll_cta'}
                    onChange={handleTextChange}
                />
                <div className='userFormArea'>
                    <div>
                        {state?.endless_scroll_media && (
                            <p
                                className='cancel'
                                onClick={(e) => {
                                    setSelVideo(null);
                                    setSelImage(null);
                                    setState({ ...state, endless_scroll_media: null });
                                }}
                            >
                                Cancel Upload
                            </p>
                        )}
                    </div>
                    <div className='upload-buttons'>
                        <input
                            type='file'
                            id='imageFile'
                            name='endless_scroll_media'
                            style={{ display: 'none' }}
                            accept='image/*'
                            // key={state.endless_scroll_media}
                            onChange={(e) => {
                                handleMediaUpload(e);
                            }}
                        />
                        <div className='upload-label'>
                            <label>Endless scroll Image</label>
                            <Button
                                onClick={() => {
                                    const image = document.getElementById('imageFile');
                                    image.click();
                                }}
                                type='button'
                                isDisabled={
                                    (state.endless_scroll_media !== '' &&
                                        state.endless_scroll_media?.type?.split('/')?.[0] ===
                                        'video') ||
                                        selVideo
                                        ? true
                                        : false
                                }
                                name={
                                    state.endless_scroll_media !== '' &&
                                        state.endless_scroll_media?.type?.split('/')?.[0] === 'image'
                                        ? 'Uploaded'
                                        : 'Upload Image'
                                }
                            />
                        </div>

                        <div className='divider'>or</div>
                        <input
                            type='file'
                            id='videoFile'
                            style={{ display: 'none' }}
                            name='endless_scroll_media'
                            accept='video/*'
                            key={
                                state?.endless_scroll_media ? state?.endless_scroll_media : null
                            }
                            onChange={(e) => {
                                handleMediaUpload(e);
                            }}
                        />
                        <div className='upload-label'>
                            <label>Endless scroll Video</label>
                            <Button
                                onClick={() => {
                                    const image = document.getElementById('videoFile');
                                    image.click();
                                }}
                                type='button'
                                isDisabled={
                                    (state.endless_scroll_media !== '' &&
                                        state.endless_scroll_media?.type?.split('/')?.[0] ===
                                        'image') ||
                                        selImage
                                        ? true
                                        : false
                                }
                                name={
                                    state.endless_scroll_media !== '' &&
                                        state.endless_scroll_media?.type?.split('/')?.[0] === 'video'
                                        ? 'Uploaded'
                                        : 'Upload Video'
                                }
                            />
                        </div>

                        {selImage && (
                            <div className='attachment_img_wrapper'>
                                <img
                                    src={selImage ? selImage : null}
                                    alt='sample'
                                    className='feed__attachment'
                                />
                                <div className='attachment_img_wrapper_close'>
                                    <img
                                        src={CloseBlackIcon}
                                        alt='Remove'
                                        onClick={(e) => {
                                            setSelVideo(null);
                                            setSelImage(null);
                                            setState({ ...state, endless_scroll_media: '' });
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        {selVideo && (
                            <div className='attachment_img_wrapper'>
                                <video className='attachment__selimage' controls>
                                    <source src={selVideo ? selVideo : null} type='video/mp4' />
                                </video>
                                <div className='attachment_img_wrapper_close'>
                                    <img
                                        src={CloseBlackIcon}
                                        alt='Remove'
                                        onClick={(e) => {
                                            if (data) {
                                                cancelMedia(e, state.endless_scroll_media);
                                            }
                                            setSelVideo(null);
                                            setSelImage(null);
                                            setState({ ...state, endless_scroll_media: '' });
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className='mt-4'>
                        {state.thumbnail_image && (
                            <p
                                className='cancel'
                                onClick={(e) => {
                                    if (data) {
                                        cancelMedia(e, state.thumbnail_image);
                                    }
                                    setThumbnailImage(null);
                                    setState({ ...state, thumbnail_image: '' });
                                }}
                            >
                                Cancel Thumbnail
                            </p>
                        )}
                    </div>
                    <div className='upload-buttons '>
                        <input
                            type='file'
                            id='thumbnailFile'
                            style={{ display: 'none' }}
                            name='thumbnail_image'
                            onChange={(e) => {
                                handleMediaUpload(e);
                            }}
                        />
                        <div className='upload-label'>
                            <label>Thumbnail</label>
                            <Button
                                onClick={() => {
                                    const image = document.getElementById('thumbnailFile');
                                    image.click();
                                }}
                                isDisabled={state.thumbnail_image}
                                type='button'
                                name={
                                    state.thumbnail_image !== ''
                                        ? 'Uploaded'
                                        : 'Upload Thumbnail Image'
                                }
                            />
                        </div>
                        {thumbnailImage && (
                            <div className='attachment_img_wrapper'>
                                <img
                                    src={thumbnailImage}
                                    alt='sample'
                                    className='feed__attachment'
                                />
                                <div className='attachment_img_wrapper_close'>
                                    <img
                                        src={CloseBlackIcon}
                                        alt='Remove'
                                        onClick={(e) => {
                                            if (data) {
                                                cancelMedia(e, state.thumbnail_image);
                                            }
                                            setThumbnailImage(null);
                                            setState({ ...state, thumbnail_image: '' });
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className='colorpicker'>
                    <label>Background Fill</label>
                    <input
                        type='color'
                        name='hex_code'
                        value={state.hex_code ? state.hex_code : '#323739'}
                        onChange={handleTextChange}
                    />
                    <p>{state.hex_code}</p>
                </div>
                {activeTab === 'en' && (
                    <div className='userFormArea'>
                        {options && (
                            <MultiSelectDropdown
                                label='Add Tags'
                                name='tags'
                                value={state.tags}
                                options={options}
                                onChange={handleTextChange}
                                tag_type={true}
                            />
                        )}
                    </div>
                )} 
                {activeTab === 'en' && (
                    <Calender
                        onStartDateSelected={(e) => {
                            setState({ ...state, start_date: e });
                        }}
                        onEndDateSelected={(e) => {
                            setState({ ...state, end_date: e });
                        }}
                        startDate={state?.start_date && state?.start_date}
                        endDate={state?.end_date && state?.end_date}
                        validated={validated}
                        activate={activate}
                    />
                )}

                <div className='userFormArea'>
                    <Button
                        name={data ? 'Edit External Article' : 'Create External Article'}
                        onClick={handleSubmit}
                    />
                    <label
                        style={{ paddingLeft: '10px', cursor: 'pointer' }}
                        onClick={() => {
                            if (data) {
                                history.push({
                                    pathname: '/cms/content',
                                    state: { goto: data.comingFrom },
                                });
                            } else {
                                history.goBack();
                            }
                        }}
                    >
                        Cancel
                    </label>
                </div>
            </div>
            {loader && <PageLoader pageLoading={loader} />}
        </>
    );
};

export default External;
