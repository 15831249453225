import { useEffect, useState } from 'react';

import Message from 'utils/Message';
import Toast from 'utils/Toast';

import {
  addImageFile,
  addRefferals,
  getRefferals,
} from 'api/latest/ReferralsandRewards';
const useRewardsandRefree = (validate) => {
  const [referrerStatus, setRreferrerStatus] = useState(false);

  const [pageLoading, setPageLoading] = useState(false);
  const [count, setCount] = useState(1);
  //   const [serviceData, setRulesData] = useState([]);

  const [isPublishButtonDisabled, setisPublishButtonDisabled] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [rulesData, setRulesData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [successfullReferralData, setSuccessfullReferralData] = useState([]);

  const handleCheckboxChange = () => {
    setRreferrerStatus(!referrerStatus);
  };

  const [values, setValues] = useState({
    refereeStatus: false,
    condition: '',
    signup_coins: null,
    referrer: null,
    referee: null,
    work_image: null,
  });

  const getReffrerDetails = async () => {
    setPageLoading(true);
    let response = await getRefferals();
    setPageLoading(false);
    if (response && response?.response.code === 200) {
      setValues({
        referrer: response?.data?.other_services?.Referrer,
        referee: response?.data?.other_services?.Referee,
        work_image: response?.data?.work_image?.image,
      });
      let serviceTemp = response?.data?.service_type.map((item, index) => {
        return {
          ...item,
          toggle: item.status === 'ACTIVE' ? true : false,
        };
      });
      let tempreferral_rule = response?.data?.referral_rule.map(
        (item, index) => {
          return {
            ...item,
            toggle: item.status === 'ACTIVE' ? true : false,
          };
        }
      );
      let temp_successfull_referral = response?.data?.successfull_referral.map(
        (item, index) => {
          return {
            ...item,
            toggle: item.status === 'ACTIVE' ? true : false,
          };
        }
      );

      setServiceData(serviceTemp);
      setRulesData(tempreferral_rule);
      setSuccessfullReferralData(temp_successfull_referral);
    } else {
      if (response?.message) {
        Toast.error(response?.message);
      } else {
        Toast.error(Message.Error.COMMON_MESSAGE);
      }
    }
  };

  useEffect(() => {
    getReffrerDetails();
  }, [refresh]);

  const addNewrules = () => {
    setisPublishButtonDisabled(false);
    const temp = [...rulesData];
    temp.push({
      id: '', // Basically its a index
      primary_text: '',
      secondary_text: '',
      status: false,
    });

    setRulesData(temp);
  };

  const addNewSuccessfullReferral = () => {
    const temp = [...successfullReferralData];
    temp.push({
      id: '', // Basically its a index
      no_of_successful_ref: null,
      value_of_successful_ref: null,
      status: false,
    });

    setSuccessfullReferralData(temp);
  };

  const handleChangeOthers = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      setValues({
        ...values,
        [name]: value && parseInt(value),
      });
    }
  };

  const referelhandleChange = (e) => {
    const { name, value, id } = e.target;
    if (value) setisPublishButtonDisabled(false);
    const valueCpy = [...successfullReferralData];
    valueCpy[id][name] = value && parseInt(value);
    setSuccessfullReferralData([...valueCpy]);
  };
  const servicehandleChange = (e) => {
    const { name, value, id } = e.target;
    if (value) setisPublishButtonDisabled(false);
    const valueCpy = [...serviceData];
    valueCpy[id][name] = value && parseInt(value);
    setServiceData([...valueCpy]);
  };
  const ruleshandleChange = (e) => {
    const { name, value, id } = e.target;
    if (value) setisPublishButtonDisabled(false);
    const valueCpy = [...rulesData];
    valueCpy[id][name] = value && value;
    setRulesData([...valueCpy]);
  };

  const serviceToogleChangestatus = (itemindex) => {
    setisPublishButtonDisabled(false);
    let newData = [...serviceData];
    newData[itemindex].toggle = !newData[itemindex].toggle;
    setServiceData(newData);
  };
  const reffrelsToogleChangestatus = (itemindex) => {
    setisPublishButtonDisabled(false);
    let newData = [...successfullReferralData];
    newData[itemindex].toggle = !newData[itemindex].toggle;
    setSuccessfullReferralData(newData);
  };

  const rulesToogleChangestatus = (itemindex) => {
    setisPublishButtonDisabled(false);
    let newData = [...rulesData];
    newData[itemindex].toggle = !newData[itemindex].toggle;
    setRulesData(newData);
  };

  const callAddOrUpdateRefferer = async () => {
    let serviceTemp = serviceData.map((item, index) => {
      return {
        ...item,
        status: item.toggle ? item.toggle : false,
      };
    });
    let tempreferral_rule = rulesData.map((item, index) => {
      return {
        ...item,
        status: item.toggle ? item.toggle : false,
      };
    });
    let temp_successfull_referral = successfullReferralData.map(
      (item, index) => {
        return {
          ...item,
          status: item.toggle ? item.toggle : false,
        };
      }
    );

    let payload = {
      service_type: serviceTemp,
      successful_referral: temp_successfull_referral,
      referral_rule: tempreferral_rule,
      other_services: {
        referrer: values.referrer,
        referee: values.referee,
      },
    };
    setPageLoading(true);
    let responseObj = await addRefferals(payload);
    setPageLoading(false);
    if (responseObj?.response?.code === 200) {
      Toast.success(responseObj?.data?.message);
      setRefresh(!refresh);
    } else {
      Toast.error(
        responseObj?.response?.error
          ? responseObj?.response?.error
          : 'Something went wrong .'
      );
    }
  };

  const onFileSelected = async (file) => {
    if (!file) {
      return;
    }
    let data = { artwork_image: file };
    // debugger;
    setPageLoading(true);
    let responseObj = await addImageFile(data);
    setPageLoading(false);
    if (responseObj?.response?.code === 200) {
      Toast.success('Image Upload Successfully !');
      setValues({
        ...values,
        work_image: responseObj.data,
      });
    } else {
      Toast.error(
        responseObj?.response?.error
          ? responseObj?.response?.error
          : 'Image Upload Failed !'
      );
    }
  };

  return {
    values,
    pageLoading,
    onFileSelected,
    rulesData,
    serviceData,
    successfullReferralData,
    isPublishButtonDisabled,
    addNewrules,
    addNewSuccessfullReferral,
    handleChangeOthers,
    referelhandleChange,
    ruleshandleChange,
    servicehandleChange,
    reffrelsToogleChangestatus,
    serviceToogleChangestatus,
    rulesToogleChangestatus,
    callAddOrUpdateRefferer,
    handleCheckboxChange,
    referrerStatus,
  };
};

export default useRewardsandRefree;
