import {
  addUpdateCourse,
  getCourseById,
  getTagsLists,
  updateChapterOrdering,
} from 'app/api/latest/Courses/courses';
import { APP_CONSTANTS } from 'app/constants/app-constants';
import Toast from 'app/utils/Toast';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const useCreateCourses = () => {
  const history = useHistory();
  const params = useParams();
  const [pageLoading, setPageLoading] = useState(false);
  const [state, setState] = useState({
    title: '',
    course_image: '',
    author: '',
    course_description: '',
    author_expertise: '',
    author_description: '',
    author_image: '',
    cohorts: [],
    age_range: [],
    duration_minute: '',
    duration_second: '',
    recommended_course: false,
    tags: [],
    course_duration: '',
    publish_status: false,
  });
  const [selectedCohort, setSelectedCohort] = useState([]);
  const [selectedAge, setSelectedAge] = useState([]);
  const [tagData, setTagData] = useState([]);
  const [courseDetailsData, setCourseDetailsData] = useState([]);

  useEffect(() => {
    if (params?.id) {
      getCourseDetails();
    }
    getTagsList();
  }, [params?.id]);

  const courseDuration = (value) => {
    let splitTime = value.split(':');
    return splitTime;
  };

  const getCourseDetails = async () => {
    setPageLoading(true);
    const response = await getCourseById(params?.id);
    setCourseDetailsData(response?.chapter_quiz_data);

    if (response && response) {
      setState({
        title: response?.course_detail?.title,
        course_image: response?.course_detail?.course_image,
        author: response?.course_detail?.author,
        course_description: response?.course_detail?.course_description,
        author_expertise: response?.course_detail?.author_expertise,
        author_description: response?.course_detail?.author_description,
        author_image: response?.course_detail?.author_image,
        duration_minute: courseDuration(
          response?.course_detail?.course_duration
        )[0],
        duration_second: courseDuration(
          response?.course_detail?.course_duration
        )[1],
        recommended_course: response?.course_detail?.recommended_course,
        tags: response?.course_detail?.tags,
        publish_status: response?.course_detail?.publish_status.toString().toLowerCase() === 'active' ? true : false,
      });
      setSelectedCohort(response?.course_detail?.cohorts);
      setSelectedAge(response?.course_detail?.age_range);
    }
    setPageLoading(false);
  };

  const getTagsList = async () => {
    setPageLoading(true);
    const response = await getTagsLists();
    setPageLoading(false);
    if (response && response) {
      const formatted = formatTagOptions(response);
      setTagData(formatted);
    }
    setPageLoading(false);
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleVideoBtn = () => {
    history.push({
      pathname: APP_CONSTANTS.COURSES_VIDEO,
      state: {
        courseId: params?.id ? params?.id : '',
        courseTitle: state?.title ? state?.title : '', 
      },
    });
  };
  const handleTextBtn = () => {
    history.push({
      pathname: APP_CONSTANTS.COURSES_TEXT,
      state: {
        courseId: params?.id ? params?.id : '',
        courseTitle: state?.title ? state?.title : '',
      },
    });
  };
  const handleQuizBtn = () => {
    history.push({
      pathname: APP_CONSTANTS.COURSES_QUIZ,
      state: {
        courseId: params?.id ? params?.id : '',
        courseTitle: state?.title ? state?.title : '',
      },
    });
  };

  const formatTagOptions = (options) => {
    const formatted = options.map((option) => ({
      value: option.course_tags,
      name: option.course_tags,
    }));
    return formatted;
  };

  // handle Cohorts Tags
  const handleCohortTags = (value) => {
    if (selectedCohort.includes(value)) {
      setSelectedCohort(selectedCohort.filter((item) => item !== value));
    } else {
      setSelectedCohort([...selectedCohort, value]);
    }
  };

  // handle age Options
  const handleAgeOptions = (value) => {
    if (selectedAge.includes(value)) {
      setSelectedAge(selectedAge.filter((item) => item !== value));
    } else {
      setSelectedAge([...selectedAge, value]);
    }
  };

  const onCourseImageSeleted = (file) => {
    setState((prevState) => ({
      ...prevState,
      course_image: file,
    }));
  };

  const onAuthorImageSeleted = (file) => {
    setState((prevState) => ({
      ...prevState,
      author_image: file,
    }));
  };

  const handleSubmit = async () => {
    let formData = new FormData();

    formData.append('course_id', params?.id ? params?.id : '');

    formData.append('title', state.title ? state.title : '');
    formData.append(
      'course_image',
      state.course_image ? state.course_image : ''
    );
    formData.append('author', state.author ? state.author : '');
    formData.append(
      'course_description',
      state.course_description ? state.course_description : ''
    );
    formData.append(
      'author_expertise',
      state.author_expertise ? state.author_expertise : ''
    );
    formData.append(
      'author_description',
      state.author_description ? state.author_description : ''
    );
    formData.append(
      'author_image',
      state.author_image ? state.author_image : ''
    );
    formData.append(
      'cohorts',
      selectedCohort ? JSON.stringify(selectedCohort) : []
    );
    formData.append(
      'age_range',
      selectedAge ? JSON.stringify(selectedAge) : []
    );
    formData.append(
      'recommended_course',
      state.recommended_course ? state.recommended_course : ''
    );
    formData.append('tags', state.tags ? JSON.stringify(state.tags) : '');

    formData.append(
      'course_duration',
      state.duration_minute && state.duration_minute
        ? `${state.duration_minute}:${state.duration_second}`
        : null
    );
    formData.append(
      'publish_status',
      state.publish_status ? state.publish_status : false
    );

    if (!state.title) {
      Toast.error('Title is required')
      return;
    }
    
    if (!state.course_image) {
      Toast.error('Course Image is required')
      return;
    }

    if (!state.author) {
      Toast.error('Author is required')
      return;
    }

    if (!state.course_description) {
      Toast.error('Course Description is required')
      return;
    }

    if (!state.author_expertise) {
      Toast.error('Author Expertise is required')
      return;
    }

    if (!state.author_description) {
      Toast.error('Author Description is required')
      return;
    }

    if (!state.author_image) {
      Toast.error('Author Image is required')
      return;
    }

    if (selectedCohort.length === 0) {
      Toast.error('At least one Cohort must be selected')
      return;
    }

    if (selectedAge.length === 0) {
      Toast.error('At least one Age Group must be selected')
      return;
    }

    if (!state.duration_minute) {
      Toast.error('Duration (Minutes) is required')
      return;
    }

    if (!state.duration_second || state.duration_second < 0 || state.duration_second > 59) {
      Toast.error('Duration (Seconds) is required and should be between 0 and 59');
      return;
    }

    if (state.tags.length === 0) {
      Toast.error('At least one Tag must be selected')
      return;
    }

    setPageLoading(true);
    let responseObj;

    responseObj = await addUpdateCourse(formData);
    if (
      responseObj?.response?.code === 200 ||
      responseObj?.response?.code === 201
    ) {
      Toast.success(responseObj.response.alert[0].message);
      history.push('/courses/creation');
    } else {
      setPageLoading(false);
      Toast.error(
        responseObj.data
          ? responseObj.data.msg
          : responseObj.response.alert[0].message
          ? responseObj.response.alert[0].message
          : ''
      );
    }
  };

  const handleSelectedItemsChange = async (newSelectedItems) => {
    const temp = newSelectedItems.map((item, index) => {
      return {
        course_uuid: params?.id,
        chapter_uuid:
          item?.chapter_type.toString().toLowerCase() !== 'quiz' ? item.id : '',
        quiz_uuid:
          item?.chapter_type.toString().toLowerCase() === 'quiz' ? item.id : '',
        sorting_order: index + 1,
      };
    });
    const response = await updateChapterOrdering(temp);

    if (response.response.code === 200) {
      Toast.success('Courses Ordering Updated Successfully');
    } else {
      Toast.error('Failed to save the ordering');
    }
    // courseDetailsData(newtemp);
    // setCourseDetailsData(newtemp)
    getCourseDetails();
  };

  return {
    selectedAge,
    selectedCohort,
    pageLoading,
    state,
    setState,
    handleVideoBtn,
    handleTextBtn,
    handleQuizBtn,
    handleChange,
    handleCohortTags,
    handleAgeOptions,
    onCourseImageSeleted,
    onAuthorImageSeleted,
    handleSubmit,
    tagData,
    courseDetailsData,
    params,
    handleSelectedItemsChange,
  };
};

export default useCreateCourses;
