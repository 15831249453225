import React from 'react';
import Dropdown from 'react-dropdown';
import './index.scss';
const Select = ({ onChange, value, placeholder, options }) => {
  // const [select, setSelect] = useState([]);

  // const handleDropdownChange =

  // useEffect(() => {
  //   // console.log(value && value.length && value.length > 0, 'asd');
  //   const data = [];
  //   value &&
  //     value.length &&
  //     value.length > 0 &&
  //     value.map((item) => {
  //       options.map((itemTwo) => {
  //         if (item.toLowerCase() === itemTwo.name.toLowerCase()) {
  //           data.push(itemTwo);
  //         }
  //       });
  //     });
  //   setSelect(data);
  // }, [value]);

  // const defaultOption = options[0];
  return (
    <div className='dropdown'>
      <Dropdown
        options={options}
        // selectedValues={value}
        // onChange={(e) => {
        //   onChange &&
        //     onChange({
        //       target: {
        //         name: 'age',
        //         value: e.value.toUpperCase(),
        //       },
        //     });
        //   setSelect(e.target.name);
        // }}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
      />
    </div>
  );
};

export default Select;
