import * as dataAccess from 'utils/ajax';
import ApiParser from 'utils/ApiParser';
import Parsers from 'utils/Parsers';
import { API_CONSTANTS } from 'constants/api-contants';

export const getCelebrityVideo = async (start) => {
  let payload = {
    data: {
      page: start ? start : 1,
      limit: 10,
    },
  };
  let responseObj = await dataAccess.post(API_CONSTANTS.GET_CELEBRITY, payload);
  return ApiParser.parseCelebrityListing(responseObj);
};

export const addOrUpdateCelebrity = async (incomingData) => {
  let data = {
    update_celebrity_video: incomingData?.celebrity_video_id ? 1 : 0,
    publish: 1,
    ...incomingData,
  };

  var formData = Parsers.ConvertObjectAsFormData(data);

  let payload = {
    data: formData,
  };

  return await dataAccess.upload(API_CONSTANTS.UPDATE_CELEBRITY, payload);
};

export const deleteCelebrity = async (CelebrityVideoId) => {
  if (!CelebrityVideoId) {
    return;
  }

  let payload = {
    data: {
      celebrity_video_id: CelebrityVideoId,
    },
  };

  return await dataAccess.del(API_CONSTANTS.DELETE_CELEBRITY, payload);
};

export const publishOrUnpublishCelebrity = async (CelebrityVideoId, status) => {
  if (!CelebrityVideoId || !status) {
    return;
  }

  let data = {
    publish: status.toLowerCase() === 'inactive' ? 1 : 0,
    celebrity_video_id: CelebrityVideoId,
    update_celebrity_video: 1,
  };

  var formData = Parsers.ConvertObjectAsFormData(data);

  let payload = {
    data: formData,
  };
  // console.log(data, 'feeddata');

  return await dataAccess.upload(API_CONSTANTS.UPDATE_CELEBRITY, payload);
};
