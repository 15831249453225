import UserLandingForm from 'app/container/Userlanding/userlandingForm'
import BasePage from 'app/pages/BasePage'
import React from 'react'

const CreateNewLanding = () => {
    return (
        <BasePage>
            <UserLandingForm />
        </BasePage>
    )
}

export default CreateNewLanding
