import React, { useEffect, useState } from 'react'; 
import Button from 'app/components/button'; 
import './index.scss';
import ActivateContainer from './Activate';
import HistoryContainer from './Histroy';
import RadioButton from 'app/shared/components/RadioButton';

const PlanActivationContainer = ({ tableHeader }) => {  
  const [selectedItem, setSelectedItem] = useState('activate');
  const [selectedTab, setSelectedTab] = useState('BREATHING_EXERCISE');
  const radioMenu = [
    {
      label: 'BREATHING EXERCISE',
      id: '1',
      value: 'BREATHING_EXERCISE',
    },
    {
      label: 'AUTOMATED VITALS',
      id: '2',
      value: 'AUTOMATED_VITALS',
    },
  ];
  useEffect(() => {}, []);
  const radioButtonSelected = (item) => {
    setSelectedTab(item.value);
  };

  return (
    <>
      <div className='cms-content__container'>
      <h4>Face Based {selectedTab} backend plan activation home</h4> 
        <div className='mb-5'>
          <div className='row'>
              <div className='content-container__filterSearch mb-5'>
              <div className='content-selector'>
                {radioMenu.map((item, key) => (
                  <RadioButton
                    id={item.id}
                    label={item.label}
                    isChecked={selectedTab === item.value ? true : false}
                    value={item.value}
                    onSelect={() => {
                      radioButtonSelected(item);
                    }}
                    name={item.label}
                  />
                ))}
              </div>
            </div>
          </div>
         
          <div className='row'>
            <div className='col-sm-12 activate-tab'>
              <Button
                className={selectedItem === 'activate' ? 'active-btn' : ''}
                onClick={() => {
                  setSelectedItem('activate');
                }}
              >
                Activate
              </Button>
              <Button
                className={selectedItem === 'history' ? 'active-btn' : ''}
                onClick={() => {
                  setSelectedItem('history');
                }}
              >
                History
              </Button>
            </div>
          </div>

          <div className='row'>
            <div className='col-sm-12 my-5'>
              {selectedItem === 'activate' && <ActivateContainer selectedTab={selectedTab} />}
              {selectedItem === 'history' && <HistoryContainer selectedTab={selectedTab} />}
            </div>
          </div>
        </div> 
      </div>
    </>
  );
};

export default PlanActivationContainer;
