// Components
import NewTable from 'components/NewDraggableTable';
import { useHistory } from 'react-router-dom';

// API
// import { getActivities } from 'api/activity';
import {
  addOrUpdateMedicinePageOrder,
  getMedicinePageOrder,
} from 'app/api/latest/MedicineDelivery/medicinedelivery';

// // Utils
// import Toast from 'utils/Toast';
// import Message from 'utils/Message';

const MedicineLandingPageOrdering = ({ tableHeader }) => {
  const data = {};
  const history = useHistory();
  const MedicinePageOrder = async (tempData) => {
    const MedicineData = tempData.map((data, index) => {
      return {
        home_page_id: data.original.id,
        sorting_order: index + 1,
      };
    });
    await addOrUpdateMedicinePageOrder(MedicineData);
  };

  const sectionObj = {
    sectionTitle: 'Medicine Delivery Order',
  };
  const menuActionObj = [
    {
      name: 'Edit',
      onClick: (e, data) => {
        data.section_title = sectionObj.sectionTitle;
        history.push({
          pathname: `/edit-medicine-order/${data.id}`,
          state: {
            ...data,
          },
        });
      },
    },
  ];
  return (
    <div className='blogs'>
      <NewTable
        sectionObj={sectionObj}
        tableHeader={tableHeader}
        menuAction={menuActionObj}
        data={data}
        HomePageOrder={MedicinePageOrder}
        getData={getMedicinePageOrder}
        hasPagination={false}
      />
    </div>
  );
};
export default MedicineLandingPageOrdering;
