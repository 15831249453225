import React from 'react';

// Pages
import BasePage from 'app/pages/BasePage'; 
import CreateStaticWidgets from 'app/container/StaticWidgets/CreateStaticWidgets';
const CreateStaticWidget = () => {
  return (
    <BasePage>
      <CreateStaticWidgets />
    </BasePage>
  );
};

export default CreateStaticWidget;
