import React from 'react';

// Pages
import BasePage from 'pages/BasePage';  
import { LandingScreenHeader } from 'constants/table-header-constants';
import ListingScreens from 'app/container/Landingpage/Listingscreens';

const LandingPages = () => {
  return (
    <BasePage>
      <ListingScreens tableHeader={LandingScreenHeader}/>
    </BasePage>
  );
};

export default LandingPages;
