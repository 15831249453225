import Button from 'app/components/button';
import '../index.scss';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import NewInput from 'app/components/NewInput';
import Toast from 'app/utils/Toast';
import PageLoader from 'app/components/PageLoader';
import { imageSizeValidation } from 'utils/Parsers';
import ValidationMessage from 'utils/ValidationMessage';
import {
  getRatingDropdownList,
  createRating,
  updateRatingDetails,
  getRatingDetailsById,
} from 'app/api/rating';
import { CloseBlackIcon } from 'app/constants/image-constants';
import Toggle from 'react-toggle';
const RatingCreate = () => {
  const [loader, setLoader] = useState(false);
  const params = useParams();
  const [imageUrl, setImageUrl] = useState(null);
  const [drowdownData, setDrowdownData] = useState([]);
  const [hitCounts, setHitCounts] = useState([]);
  const [state, setState] = useState({
    module_name: '',
    action_event: '',
    condition: '',
    hits_count: '',
    other_action_event: '',
    other_event_hits_count: '',
    timeline: '',
    no_of_days: '',
    nudge_delay: '',
    title: '',
    image_url: '',
    fe_option1: '',
    fe_option2: '',
    fe_option3: '',
    fe_option4: '',
    android: false,
    ios: false,
    status: false,
  });

  let history = useHistory();

  useEffect(() => {
    if (params?.id) {
      getRatingDetails();
    }
    getDropdownData();

    let nudgeOptions = [];
    for (let index = 0; index < 30; index++) {
      const element = { value: index + 1, label: index + 1 };
      nudgeOptions.push(element);
    }
    setHitCounts(nudgeOptions);
  }, []);

  const getDropdownData = async () => {
    setLoader(true);
    const response = await getRatingDropdownList();
    if (response && response) {
      setDrowdownData(response);
    }
    setLoader(false);
  };

  const getRatingDetails = async () => {
    setLoader(true);
    const response = await getRatingDetailsById(params?.id);
    if (response && response) {
      setState({
        module_name: response?.module_name,
        action_event: response?.action_event,
        condition: response?.condition,
        hits_count: response?.hits_count,
        other_action_event: response?.other_action_event,
        other_event_hits_count: response?.other_event_hits_count,
        timeline: response?.timeline,
        no_of_days: response?.no_of_days,
        nudge_delay: response?.nudge_delay,
        title: response?.title,
        image_url: response?.image_url,
        fe_option1: response?.fe_option1,
        fe_option2: response?.fe_option2,
        fe_option3: response?.fe_option3,
        fe_option4: response?.fe_option4,
        android: response?.android,
        ios: response?.ios,
        status: response?.status.toLowerCase() === 'active' ? true : false,
      });
      if (response?.image_url) {
        let thumbnail_image = `${process.env.REACT_APP_S3_BUCKET_URL}/${response?.image_url}`;
        setImageUrl(thumbnail_image);
      }
    }
    setLoader(false);
  };

  const handleTextChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleMediaUpload = (e) => {
    let file = e.target.files[0];
    if (
      (file.type === 'image/jpeg' ||
        file.type === 'image/jpg' ||
        file.type === 'image/png') &&
      imageSizeValidation(e)
    ) {
      Toast.error(ValidationMessage.IMAGE_SIZE);
      return;
    } else {
      let reader = new FileReader();
      reader.onloadend = function () {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(file);
      setState({ ...state, [e.target.name]: file });
      Toast.success(file?.name + ' uploaded');
    }
  };

  const handleSubmit = async () => {
    let payload = {
      ...state,
      status: state.status === true ? 'ACTIVE' : 'INACTIVE',
      nudge_delay: parseInt(state.nudge_delay),
    };
    if (payload.timeline === 'NO_TIMELINE') {
      delete payload.no_of_days;
    }

    if (payload.condition === 'NONE') {
      delete payload.other_action_event;
      delete payload.other_event_hits_count;
    }

    if (payload.condition === 'OR') {
      delete payload.other_event_hits_count;
    } 
    if (payload.timeline === 'NO_TIMELINE') {
      delete payload.no_of_days;
    }

    if (payload.condition === 'NONE') {
      delete payload.other_action_event;
      delete payload.other_event_hits_count;
    }

    if (payload.condition === 'OR') {
      delete payload.other_event_hits_count;
    }
    if (!state.module_name) {
      Toast.error('Module name is required');
    }
    if (!state.title) {
      Toast.error('Please complete all fields');
      return;
    }

    if (!state.action_event) {
      Toast.error('Action event is required');
      return;
    }

    if (!state.condition) {
      Toast.error('Condition is required');
      return;
    }

    if (!state.hits_count) {
      Toast.error('Hits/Counts is required');
      return;
    }

    if (['and', 'or'].includes(state.condition.toLowerCase()) && !state.other_action_event) {
      Toast.error('Other action event is required');
      return;
    }

    if (state.condition.toLowerCase() === 'and' && !state.other_event_hits_count) {
      Toast.error('Other event hits count is required');
      return;
    }

    if (!state.timeline) {
      Toast.error('Timeline is required');
      return;
    }

    if (state.timeline === 'AFTER_X_CONSECUTIVE_DAYS') {
      if (!state.no_of_days || state.no_of_days < 1 || state.no_of_days > 99) {
        Toast.error('Number of days must be a number between 1 and 99');
        return;
      }
    }

    if (!state.nudge_delay || state.nudge_delay < 1 || state.nudge_delay > 60) {
      Toast.error('Nudge delay must be a number between 1 and 60');
      return;
    }

    if (!state.title || state.title.length > 100) {
      Toast.error('Title is required and must not exceed 100 characters');
      return;
    }

    if (!state.image_url) {
      Toast.error('Image is required');
      return;
    }

    if (!state.fe_option1 || state.fe_option1.length > 50) {
      Toast.error('FE option 1 is required and must not exceed 50 characters');
      return;
    }

    if (!state.fe_option2 || state.fe_option2.length > 50) {
      Toast.error('FE option 2 is required and must not exceed 50 characters');
      return;
    }

    if (!state.fe_option3 || state.fe_option3.length > 50) {
      Toast.error('FE option 3 is required and must not exceed 50 characters');
      return;
    }

    if (!state.fe_option4 || state.fe_option4.length > 50) {
      Toast.error('FE option 4 is required and must not exceed 50 characters');
      return;
    }

    if (state.android === undefined) {
      Toast.error('Android selection is required');
      return;
    }

    if (state.ios === undefined) {
      Toast.error('iOS selection is required');
      return;
    }

    if (state.status === undefined) {
      Toast.error('Status selection is required');
      return;
    }

    setLoader(true);
    let responseObj;
    if (params?.id) {
      payload.id = params?.id;
      responseObj = await updateRatingDetails(payload, payload.id);
    } else {
      responseObj = await createRating(payload);
    }

    if (
      responseObj &&
      responseObj.response &&
      (responseObj.response.code === 200 || responseObj.response.code === 201)
    ) {
      Toast.success(responseObj.response.alert[0].message);
      history.push('/rating');
    } else {
      setLoader(false);
      Toast.error(
        responseObj.data
          ? responseObj.data.msg
          : responseObj.response.alert[0].message
          ? responseObj.response.alert[0].message
          : ''
      );
    }
  };
  return (
    <div className='container'>
      <h1 className='section-header__title'>App Ratings &gt; Create new</h1>

      <div className='separator'>
        <p>Create Rating</p>
      </div>
      <div className='create-rating-form'>
        <div className='form-field'>
          <NewInput
            label='Module'
            name={'module_name'}
            value={state.module_name}
            onChange={handleTextChange}
            asterisk={true}
          />
        </div>
        <div className='two-field'>
          <div>
            <label>Action Based Event<span className='asterisk'>*</span></label>
            <select
              name='action_event'
              className='custom-select'
              onChange={(e) => {
                setState({ ...state, action_event: e.target.value });
              }}
            >
              <option value=''>Select event</option>
              {drowdownData?.action_based_event_types?.map((item, indx) => (
                <option
                  key={state.action_event + indx}
                  value={item.value}
                  selected={state.action_event == item.value ? true : false}
                >
                  {item.key}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>Condition<span className='asterisk'>*</span></label>
            <select
              name='condition'
              className='custom-select'
              onChange={(e) => {
                setState({ ...state, condition: e.target.value });
              }}
            >
              <option value=''>Select Condition</option>
              {drowdownData?.condition_types?.map((item, indx) => (
                <option
                  key={state.condition + indx}
                  value={item.value}
                  selected={state.condition == item.value ? true : false}
                >
                  {item.key}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='form-field'>
          <label>Hits/Counts<span className='asterisk'>*</span></label>
          <select
            name='hits_count'
            className='custom-select'
            onChange={(e) => {
              setState({ ...state, hits_count: e.target.value });
            }}
          >
            <option value=''>Select Hits/Counts</option>
            {hitCounts?.map((item, indx) => (
              <option
                key={state.hits_count + indx}
                value={item.value}
                selected={state.hits_count == item.value ? true : false}
              >
                {item.label}
              </option>
            ))}
          </select>
        </div>
        {['and', 'or'].includes(state?.condition?.toLowerCase()) && (
          <div className='form-field'>
            <label>Action Based Event<span className='asterisk'>*</span></label>
            <select
              name='other_action_event'
              className='custom-select'
              onChange={(e) => {
                setState({ ...state, other_action_event: e.target.value });
              }}
            >
              <option value=''>Select event</option>
              {drowdownData?.action_based_event_types
                ?.filter((f) => f.value !== state.action_event)
                .map((item, indx) => (
                  <option
                    key={state.other_action_event + indx}
                    value={item.value}
                    selected={
                      state.other_action_event == item.value ? true : false
                    }
                  >
                    {item.key}
                  </option>
                ))}
            </select>
          </div>
        )}
        {state?.condition?.toLowerCase() === 'and' && (
          <div className='form-field'>
            <label>Hits/Counts<span className='asterisk'>*</span></label>
            <select
              name='other_event_hits_count'
              className='custom-select'
              onChange={(e) => {
                setState({ ...state, other_event_hits_count: e.target.value });
              }}
            >
              <option value=''>Select Hits/Counts</option>
              {hitCounts?.map((item, indx) => (
                <option
                  key={state.other_event_hits_count + indx}
                  value={item.value}
                  selected={
                    state.other_event_hits_count == item.value ? true : false
                  }
                >
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        )}

        <div className='form-field'>
          <label>Timeline<span className='asterisk'>*</span></label>
          <select
            name='timeline'
            className='custom-select'
            onChange={(e) => {
              setState({ ...state, timeline: e.target.value });
            }}
          >
            <option value=''>Select Timeline</option>
            {drowdownData?.timeline_types?.map((item, indx) => (
              <option
                key={state.timeline + indx}
                value={item.value}
                selected={state.timeline == item.value ? true : false}
              >
                {item.key}
              </option>
            ))}
          </select>
        </div>
        {state?.timeline === 'AFTER_X_CONSECUTIVE_DAYS' && (
          <div className='form-field'>
            <NewInput
              label='Day'
              name={'no_of_days'}
              value={state.no_of_days}
              onChange={handleTextChange}
              asterisk={true}
            />
          </div>
        )}

        <div className='form-field'>
          <NewInput
            label='Nudge Delay'
            type='number'
            name={'nudge_delay'}
            value={state.nudge_delay}
            onChange={handleTextChange}
            asterisk={true}
          />
        </div>
        <div className='form-field'>
          <NewInput
            label='Title'
            name={'title'}
            value={state.title}
            onChange={handleTextChange}
            asterisk={true}
          />
        </div>
        <div className='form-field'>
          <label>Attach Image<span className='asterisk'>*</span></label>
          <div className='attach-row'>
            <div className='attach-uploader'>
              <input
                type='file'
                id='imageUrl'
                style={{ display: 'none' }}
                name='image_url'
                key={state.image_url}
                onChange={(e) => {
                  handleMediaUpload(e);
                }}
              />

              <div className='upload-label'>
                <Button
                  onClick={() => {
                    const image = document.getElementById('imageUrl');
                    image.click();
                  }}
                  isDisabled={state.image_url}
                  type='button'
                  name={
                    state.image_url != ''
                      ? 'Uploaded'
                      : 'Upload Thumbnail Image'
                  }
                />
              </div>
            </div>
            <div className='thumbnail-cancel'>
              {state.image_url && (
                <p
                  className='cancel'
                  onClick={(e) => {
                    setImageUrl(null);
                    setState({ ...state, image_url: '' });
                  }}
                >
                  Cancel Thumbnail
                </p>
              )}
            </div>
            <div className='attach-preview'>
              {imageUrl && (
                <div className='attachment_img_wrapper'>
                  <img
                    src={imageUrl}
                    alt='sample'
                    className='feed__attachment'
                  />
                  <div className='attachment_img_wrapper_close'>
                    <img
                      src={CloseBlackIcon}
                      alt='Remove'
                      onClick={(e) => {
                        setImageUrl(null);
                        setState({ ...state, image_url: '' });
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='form-field'>
          <label>FE Selection options if Ratings is 4<span className='asterisk'>*</span></label>
          <div className='four-field'>
            <NewInput
              name={'fe_option1'}
              value={state.fe_option1}
              onChange={handleTextChange}
            />
            <NewInput
              name={'fe_option2'}
              value={state.fe_option2}
              onChange={handleTextChange}
            />
            <NewInput
              name={'fe_option3'}
              value={state.fe_option3}
              onChange={handleTextChange}
            />
            <NewInput
              name={'fe_option4'}
              value={state.fe_option4}
              onChange={handleTextChange}
            />
          </div>
        </div>
        <div className='form-field'>
          <label>Android<span className='asterisk'>*</span></label>
          <div className='toggle-with-label'>
            <span className='text-danger'>NO</span>
            <Toggle
              checked={state.android}
              icons={false}
              name='android'
              onChange={(e) => {
                setState({ ...state, android: !state.android });
              }}
            />
            <span className='text-success'>Yes</span>
          </div>
        </div>
        <div className='form-field'>
          <label>Ios<span className='asterisk'>*</span></label>
          <div className='toggle-with-label'>
            <span className='text-danger'>NO</span>
            <Toggle
              checked={state.ios}
              icons={false}
              name='ios'
              onChange={(e) => {
                setState({ ...state, ios: !state.ios });
              }}
            />
            <span className='text-success'>Yes</span>
          </div>
        </div>
        <div className='form-field'>
          <label>Status<span className='asterisk'>*</span></label>
          <div className='toggle-with-label'>
            <span className='text-danger'>Off</span>
            <Toggle
              checked={state.status}
              icons={false}
              name='status'
              onChange={(e) => {
                setState({ ...state, status: !state.status });
              }}
            />
            <span className='text-success'>On</span>
          </div>
        </div>
      </div>

      <div className='userFormArea'>
        <Button name={params?.id ? 'Save': 'Submit'} onClick={handleSubmit} />
        <label
          style={{ paddingLeft: '10px', cursor: 'pointer' }}
          onClick={() => {
            history.goBack();
          }}
        >
          Cancel
        </label>
      </div>
      {loader && <PageLoader pageLoading={loader} />}
    </div>
  );
};

export default RatingCreate;
