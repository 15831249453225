import '../index.scss';
import { dataFormats } from 'app/utils/dateChecker';
import { file } from 'constants/image-constants';

function CustomTable({ data, tableHeader }) {
  const handleFileName = (data) => {
    let splittingData = data.split('/');

    return splittingData[splittingData.length - 1];
  };

  const handleDownloadLink = (data) => {
    let downloadLink = `${process.env.REACT_APP_S3_BUCKET_URL}/${data}`;
    return downloadLink;
  };

  return (
    <table>
      <thead>
        {tableHeader.map((item, i) => {
          return <th className='nowrap'>{item.Header}</th>;
        })}
      </thead>
      <tbody>
        {data.map((items, index) => (
          <tr key={index}>
            <td>
              {items?.upload_type === 'csv' ? (
                <>
                  <img className='file-preview-xs mx-2' src={file} alt='File' />
                  <a
                    href={handleDownloadLink(items.upload_content)}
                    target='_blank'
                    rel='noreferrer'
                  > 
                    {handleFileName(items.upload_content)}
                  </a>
                </>
              ) : (
                <>{items.upload_content ? items.upload_content : 'NA'}</>
              )}
            </td>
            <td>{items.family_plan_name ? items.family_plan_name : 'NA'}</td>
            <td>
              {items.created_on
                ? dataFormats(items.created_on, 'DD MMM YYYY')
                : '-'}
            </td>
            <td>
              {items.activate_on
                ? dataFormats(items.activate_on, 'DD MMM YYYY')
                : '-'}
            </td>
          </tr>
        ))}
        {data.length === 0 && (
          <tr>
            <td colSpan='10000'>No data found</td>{' '}
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default CustomTable;
