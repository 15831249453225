import React from 'react';

// Pages
import BasePage from 'pages/BasePage';
import Static from 'container/Static';

import { StaticPageTableHeader } from 'constants/table-header-constants';

const StaticPage = () => {
  return (
    <BasePage>
      <Static tableHeader={StaticPageTableHeader} />
    </BasePage>
  );
};

export default StaticPage;
