import React from 'react';

const ArticleComponent = ({ number, text }) => {
  return (
    <div className='blogs_box'>
      <h2 className='blogs_number'>{number}</h2>
      <p className='blogs_text'>{text}</p>
    </div>
  );
};

export default ArticleComponent;
