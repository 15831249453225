import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import Toast from 'utils/Toast';
import './index.scss';

// Utils
import Toggle from 'react-toggle';

// API
import { updateFamilyPlans } from 'api/latest/FamilyPlans/familyPlans';

function CustomTable({ data, tableHeader, startIndex, toggleState }) {
  const history = useHistory();
  const [toggleStatus, setToggleStatus] = useState({});

  useEffect(() => {
    const initialToggleStatus = {};
    data.forEach((item) => {
      initialToggleStatus[item.id] = item.status; // Assuming 'id' and 'status' are fields in your API response
    });

    setToggleStatus(initialToggleStatus);
  }, [data]);

  const handleToggleChange = (item) => {
    let itemId = item.id
    const currentStatus = toggleStatus[itemId];
    const newStatus = currentStatus === 'ON' ? 'OFF' : 'ON';
    // Construct a payload with both 'id' and 'status' fields
    const payload = {
      id: itemId,
      status: newStatus === 'ON' ? 'ACTIVE' : 'INACTIVE',
      user_experience:item.user_experience,
      service_value:item.service_value
    };
    // Call your updateFamilyPlans function to update the status
    updateFamilyPlans(payload, itemId) // Pass the payload object
      .then((response) => { 
        if (response&&response.response.code === 200) {
          // If the update is successful, update the local state
          Toast.success('Successfully Updated')
          setToggleStatus((prevState) => ({
            ...prevState,
            [itemId]: newStatus,
          }));
        } else {
          Toast.error(response?.data)
          // Handle API update error
          console.error('API update error', response);
        }
      })
      .catch((error) => {
        // Handle fetch error
        console.error(error, 'error');
      });
  };

  return (
    <table>
      <tr>
        {tableHeader.map((item, i) => {
          return <th className='nowrap text-center'>{item.Header}</th>;
        })}
      </tr>
      {data
        ? data.map((items, idx) => (
            <tr key={idx}>
              <td>{startIndex + idx + 1}</td>
              <td className='text-capitalize'>{items.product_family}</td>
              <td className='text-capitalize'>{items.plan_name}</td>
              <td className='text-capitalize'>{items.internal_plan_name}</td>
              <td className='text-capitalize'>{items.description}</td>
              <td>{items.created_on}</td>
              <td>{items.updated_on}</td>
              <td>{items.razorpay_plan_id}</td>
              <td className='text-capitalize'>{items.paid ? 'yes' : 'no'}</td>
              <td>
                <div className='d-inline-flex align-items-center'>
                  <div className='d-inline-flex text-danger'>OFF</div>
                  <div className='d-inline-flex mx-2'>
                    <Toggle
                      checked={toggleStatus[items.id] === 'ON'}
                      icons={false}
                      onChange={() => handleToggleChange(items)}
                    />
                  </div>
                  <div className='d-inline-flex text-green'>ON</div>
                </div>
              </td>
              <td
                className='text-primary text-decoration-underline cursor-pointer'
                onClick={() => {
                  history.push({
                    pathname: `/vitals/create-family-plans/edit/${items.id}`,
                  });
                }}
              >
                Manage Plan
              </td>
            </tr>
          ))
        : ''}
      {data.length === 0 ? (
        <tr>
          <td colspan='10000'>No data found</td>{' '}
        </tr>
      ) : (
        ''
      )}
    </table>
  );
}

export default CustomTable;
