import React from 'react';

// Pages
import BasePage from 'pages/BasePage';
import MasterData from 'container/MasterData';

import { MastersTableHeader } from 'constants/table-header-constants';

const StaticPage = () => {
  return (
    <BasePage>
      <MasterData tableHeader={MastersTableHeader} />
    </BasePage>
  );
};

export default StaticPage;
