import React from 'react';

// // Pages
import BasePage from 'pages/BasePage';
import CreateHabit from 'app/container/CreateHabit';

const HabitCreatePage = () => {
  return (
    <BasePage>
      <CreateHabit />
    </BasePage>
  );
};

export default HabitCreatePage;
