import NewAttachment from 'app/components/NewDesign/NewAttachment';
import NewInput from 'app/components/NewInput';
import WidgetCard from 'app/components/WidgetCard';
import Button from 'app/components/button';
import Toggle from 'react-toggle';
import useRewardsandRefree from './useRewardsandRefree';
import PageLoader from 'app/components/PageLoader';
const Referrer = (props) => {
  const {
    pageLoading,
    values,
    referelhandleChange,
    ruleshandleChange,
    servicehandleChange,
    addNewrules,
    addNewSuccessfullReferral,
    rulesData,
    serviceData,
    successfullReferralData,
    callAddOrUpdateRefferer,
    handleChangeOthers,
    reffrelsToogleChangestatus,
    serviceToogleChangestatus,
    rulesToogleChangestatus,
    onFileSelected,
  } = useRewardsandRefree();
  return (
    <div className='referrer-content'>
      <WidgetCard
        // key={data.id}
        title={'Define Services'}
        type={'Define Services'}
      >
        <div className='widget_box'>
          <ul className='form-list'>
            <li className='list-title'>
              <strong>Services</strong>
              <strong>Minimum transaction amount</strong>
            </li>
            {serviceData?.map((item, index) => {
              return (
                <>
                  <li>
                    <label>
                      <input
                        type='checkbox'
                        checked={index === 0 ? true : item?.toggle}
                        disabled={index === 0 ? true : false}
                        onChange={(e) => {
                          serviceToogleChangestatus(index);
                        }}
                      />

                      {item?.service_name}
                    </label>
                    <NewInput
                      label=''
                      value={item?.service_amt}
                      placeholder='200'
                      type='number'
                      id={index}
                      name={'service_amt'}
                      onChange={servicehandleChange}
                    />
                  </li>
                </>
              );
            })}
          </ul>
        </div>
        <div></div>
      </WidgetCard>

      <WidgetCard
        // key={data.id}
        title={'Successful Referrals'}
        type={'Successful Referrals'}
        isCreate={true}
        createFunc={addNewSuccessfullReferral}
        data={successfullReferralData}
      >
        <div className='widget_box'>
          <ul className='form-list'>
            <li className='list-title'>
              <strong>No. of successful referrals</strong>
              <strong>Rewards</strong>
            </li>
            {successfullReferralData?.map((item, index) => {
              return (
                <>
                  <li>
                    <NewInput
                      label=''
                      placeholder='5'
                      type='number'
                      value={item?.no_of_successful_ref}
                      id={index}
                      name={'no_of_successful_ref'}
                      onChange={referelhandleChange}
                    />
                    <NewInput
                      label=''
                      placeholder='200'
                      type='number'
                      value={item?.value_of_successful_ref}
                      id={index}
                      name={'value_of_successful_ref'}
                      onChange={referelhandleChange}
                    />
                    {index > 0 && (
                      <Toggle
                        checked={item?.toggle}
                        icons={false}
                        onChange={(e) => {
                          e.preventDefault();
                          reffrelsToogleChangestatus(index);
                        }}
                      />
                    )}
                  </li>
                </>
              );
            })}
          </ul>
        </div>
        <div></div>
      </WidgetCard>

      <WidgetCard
        // key={data.id}
        title={'Image Attachment'}
        type={'Image Attachment'}
      >
        <div className='widget_box'>
          <NewAttachment
            value={values?.work_image ? values?.work_image : null}
            id={''}
            name={'work_image'}
            onFileSelected={onFileSelected}
            fileType='image'
            label={`Add an attachment`}
            className='feed_attachment'
            sizevalidationUpto={300}
          />
        </div>
      </WidgetCard>

      <WidgetCard
        // key={data.id}
        title={'Every Successful Referral Reward'}
        type={'Every Successful Referral Reward'}
      >
        <div className='widget_box'>
          <div>
            <div>
              <div className='input-group mb-2'>
                <div className='input-group-prepend'>
                  <div className='input-group-text'>Referrer</div>
                </div>
                <NewInput
                  label=''
                  placeholder=' '
                  type='number'
                  value={values?.referrer}
                  name={'referrer'}
                  onChange={handleChangeOthers}
                />
              </div>
            </div>
            <div>
              <div className='input-group mb-2'>
                <div className='input-group-prepend'>
                  <div className='input-group-text'>Referee</div>
                </div>
                <NewInput
                  label=''
                  placeholder=' '
                  type='number'
                  value={values?.referee}
                  name={'referee'}
                  onChange={handleChangeOthers}
                />
              </div>
            </div>
          </div>
        </div>
      </WidgetCard>

      <WidgetCard
        // key={data.id}
        title={'Rules'}
        type={'Rules'}
        isCreate={true}
        data={rulesData}
        createFunc={addNewrules}
      >
        {rulesData?.map((item, index) => {
          return (
            <>
              <div className='widget_box mt-3'>
                <div>
                  <div className='rules-head'>
                    <h6>Rule {index + 1} </h6>{' '}
                    <Toggle
                      checked={item?.toggle}
                      icons={false}
                      onChange={(e) => {
                        e.preventDefault();
                        rulesToogleChangestatus(index);
                      }}
                    />
                  </div>
                  <NewInput
                    label='Primary text'
                    placeholder=' '
                    type='text'
                    id={index}
                    value={item?.primary_text}
                    name={'primary_text'}
                    onChange={ruleshandleChange}
                    max={120}
                  />
                  <NewInput
                    label='Secondary text'
                    placeholder=' '
                    type='text'
                    id={index}
                    value={item?.secondary_text}
                    name={'secondary_text'}
                    onChange={ruleshandleChange}
                    max={100}
                  />
                </div>
              </div>
            </>
          );
        })}
      </WidgetCard>
      <div className='text-right mt2'>
        <Button
          onClick={callAddOrUpdateRefferer}
          type='submit'
          name='Submit'
          // isDisabled={isPublishButtonDisabled}
          // cl
        />
      </div>
      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </div>
  );
};

export default Referrer;
