import { useEffect, useState } from "react";
// Components
import PageLoader from 'components/PageLoader';

// Utils

import TabBar from 'app/components/NewDesign/TabBar';
import NewTableDesign from 'components/NewDesign/NewDraggableTable';
import './index.scss';

import WidgetCard from 'app/components/WidgetCard';

import Button from 'components/button';
import useContentServicesForm from 'components/useContentServicesForm';
import Modal from 'react-bootstrap/Modal';

// Utils
import NewAttachment from 'app/components/NewDesign/NewAttachment';

import DropdownOptions from 'app/utils/DropdownOptions';
import useTabBarOptions from 'app/utils/useTabbarOptions';
import Loader from 'components/Loader';
import CTAWidgetComponent from 'components/NewDesign/CTAWidgetComponent';
import FilePreview from 'components/NewDesign/FilePreview';
import NewInput from 'components/NewInput';
import './index.scss';

import { getVersionControl } from 'app/api/versionList';

const CreateService = ({ tableHeader, onPreview }) => {
  const {
    loading,
    pageLoading,
    show,
    OnhandleChange,
    handleCreateServices,
    addServiceSection,
    servicelistData,
    removeServiceSection,
    onFileSelected,
    validateRequestpreviewScreen,
    handleClose,
    isAddButtonDisabled,
    servicesortedList,
    isPublishButtonDisabled,
    // activeTab = '',
    ServicePageOrder,
    openUp,
    tablelist,
    callServiePublishOrUnpublishAPI,
    activeTab,
    handleTabChange,
  } = useContentServicesForm();
  const { LanguageOptions } = useTabBarOptions();

  const menuActionObj = [
    {
      name: 'Publish',
      type: 'publish',
      onClick: (e, data) => {
        callServiePublishOrUnpublishAPI(data);
      },
    },
  ];
  let [versionLists, setVersionLists] = useState([]);
  let [iosversionLists, setIosVersionLists] = useState([]);

  useEffect(() => {
    async function fetchVersionControl() {
        let androidResponse = await getVersionControl('Android');

        const data = androidResponse.data.available_versions;
        let newState = data.map((key) => {
            return {
                value: [key][0].toString(),
                label: [key][0].toString(),
            };
        });
        setVersionLists(newState);

        let iosResponse = await getVersionControl('IOS');

        const iosdata = iosResponse.data.ios_available_versions;
        let newiosState = iosdata.map((key) => {
            return {
                value: [key][0].toString(),
                label: [key][0].toString(),
            };
        });
        setIosVersionLists(newiosState);
    }
    fetchVersionControl();
}, []);

  if (loading === true) {
    return <Loader />;
  }

  const sectionObj = {
    sectionTitle: 'Breathfree Service Bottom Navigation',
  };

  return (
    <div className='service'>
      {pageLoading === true && <PageLoader pageLoading={pageLoading} />}
      <div>
        <div>
          <NewTableDesign
            sectionObj={sectionObj}
            tableHeader={tableHeader}
            menuAction={menuActionObj}
            handleResourseToggle={openUp}
            HomePageOrder={ServicePageOrder}
            getData={tablelist}
            hasPagination={false}
          />
        </div>
        <>
          <div className='mt-3 ms-5'>
            {/* <h1>Custom TabBar Example</h1> */}
            <TabBar
              tabs={LanguageOptions}
              initialTab={activeTab}
              onTabChange={handleTabChange}
              parentStyle={'service-tab-style'}
            />
          </div>
          {/* <ListingMaintainingServices
            localise={activeTab}
          ></ListingMaintainingServices> */}

          <div>
            {pageLoading === true && <PageLoader pageLoading={pageLoading} />}
            <div>
              {servicelistData.map((data, index) => { 
                return (
                  <>
                    <div key={index}>
                      <WidgetCard
                        widgetcardkey={index}
                        title={data.title}
                        index={index}
                        handleRemoveCard={removeServiceSection}
                        isNew={data?.isNew}
                        type={'Service'}
                        closeSection={true}
                      >
                        {/* <img src={data.attachment} /> */}
                        <div className='widget_box'>
                          <div>
                            <NewAttachment
                              value={data.attachment}
                              id={data.id}
                              onFileSelected={onFileSelected}
                              fileType='image'
                              label={`Add Service ${index + 1} `}
                              className='widget_box_feed__attachment'
                            />

                            {activeTab?.name === 'English' && (
                              <NewInput
                                label='Display order'
                                id={data.id}
                                placeholder='Enter display Order 1/2/3'
                                name='displayOrder'
                                value={data.displayOrder}
                                onChange={OnhandleChange}
                                type='number'
                              />
                            )}
                          </div>

                          {activeTab?.name === 'English' && (
                            <>
                              <div className='widget_box_divide'></div>
                              <CTAWidgetComponent
                                showPWA={true}
                                version={true}
                                DropdownOptions={DropdownOptions.InternalUrl}
                                id={data.id}
                                index={index}
                                externalUrl={data.externalUrl}
                                ctaValue={data.title}
                                urlType={data.urlType}
                                value={data.url}
                                isHide={true}
                                onChange={OnhandleChange}
                                swappingOrder={data.swappingOrder}
                                redirection_id={ data.redirection_id  }
                                OnhandleChange={OnhandleChange}
                                urlCopy={data.urlCopy}
                                redirection_idURL={data.redirection_idURL}
                                profile_data={data.profile_data}
                                internal_for={data.internal_for}
                                internal_url={data.internal_url}
                                custom_object={data.custom_object}
                                detail_cta_text={data.detail_cta_text}
                                androidversionLists={versionLists}
                                iosversionLists={iosversionLists}
                                android_version={data?.android_version?data.android_version.toString():''}
                                criteria={data?.criteria?data.criteria:''}
                                ios_version={data?.ios_version?data.ios_version.toString():''}
                                ios_criteria={data?.ios_criteria?data.ios_criteria:''}

                              />
                            </>
                          )}
                        </div>
                      </WidgetCard>
                    </div>
                  </>
                );
              })}
              <div
                className={`widget-button-section ${
                  isAddButtonDisabled ? 'isAddButtonDisabledtrue' : ''
                }`}
              >
                <div className='section-header__right d-flex align-items-center'>
                  {activeTab?.name === 'English' && (
                    <>
                      {' '}
                      <span className="mx-3"> 
                        {' '}
                        {isAddButtonDisabled && ' Can add upto 10 Widgets'}
                      </span>
                      <Button
                        className='addbutton mr2 ml-2'
                        onClick={addServiceSection}
                        type='submit'
                        name='Add Service'
                        isDisabled
                      />
                    </>
                  )}
                  <Button
                    onClick={validateRequestpreviewScreen}
                    type='submit'
                    name='Publish'
                    isDisabled={isPublishButtonDisabled}
                    // cl
                  />
                </div>
              </div>
            </div>
            {pageLoading && <PageLoader pageLoading={pageLoading} />}

            <Modal show={show} onHide={handleClose} className='servicemodel'>
              <Modal.Body className='modal-body'>
                <div className='model-body-section'>
                  <div className='model-body-section-list'>
                    {servicesortedList?.map((data, index) => {
                      return (
                        <div key={index}>
                          {}
                          {data?.attachmentFile ? (
                            <FilePreview data={data} />
                          ) : data?.attachment ? (
                            <div>
                              <img
                                alt='serviceImg'
                                className={`${
                                  data?.fileType === 'gif'
                                    ? 'file_view_image file_view_gif'
                                    : 'file_view_image'
                                }`}
                                src={`${process.env.REACT_APP_S3_BUCKET_URL}/${data?.attachment}`}
                              />
                            </div>
                          ) : (
                            ''
                          )}
                          <div className='service_top'>
                            {data?.title && (
                              <div className='service_top_title'>
                                {data?.title}
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer className='model-footer'>
                <div className='model-footer-section'>
                  Are you sure to publish the preview to live app ?
                  <div className='model-button-section'>
                    <Button variant='primary' onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button variant='secondary' onClick={handleCreateServices}>
                      Confirm Publish
                    </Button>
                  </div>
                </div>
              </Modal.Footer>
            </Modal>
          </div>
        </>
      </div>
    </div>
  );
};

export default CreateService;
