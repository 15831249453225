import CmsContentContainer from "app/container/CmsContent";
import BasePage from "app/pages/BasePage";

const CmsContent = () => {
    return (
        <BasePage>
            <CmsContentContainer />
        </BasePage>
    );
}

export default CmsContent;