// Components

import Button from 'components/button';
import PageLoader from 'components/PageLoader';
import validate from 'components/ValidateInfo';

// Utils

import FilePreview from 'app/components/NewDesign/FilePreview';
import NewAttachment from 'app/components/NewDesign/NewAttachment';
import SmallBannerCard from 'app/components/SmallBannerCard';
import CTAWidgetComponent from 'components/NewDesign/CTAWidgetComponent';
import NewInput from 'components/NewInput';
import Modal from 'react-bootstrap/Modal';
import DropdownOptions from 'utils/DropdownOptions';
import './index.scss';
import useElsAdsSpaceContainer from './useElsAdsSpaceContainer';
const ElsAdsSpaceContainer = ({ props, type }) => {
  const {
    sortedBannerList,
    pageLoading,
    show,
    OnhandleChange,
    bannerlistData,
    handleCreateServices,
    addServiceSection,
    removeServiceSection,
    onFileSelected,
    validateRequestpreviewScreen,
    handleClose,
    isAddButtonDisabled,
    handleResourseToggle,
    isPublishButtonDisabled,
    activeTab,
    handleAgeOptions,
    handleCohortTags,
    selectedCohort,
    selectedAge,
    elsAgeGroup
  } = useElsAdsSpaceContainer({ validate });

  return (
    <div className='blogsForm'>
      <div className='blogsForm_content'>
        <h1>ELS Advertisement Space</h1>
        <>
          <div className='mt-3 mb-3 ms-3'></div>
        </>
        {bannerlistData.map((data, index) => {
          return (
            <div key={index}>
              <SmallBannerCard
                smallBannerCardkey={data?.id}
                title={data?.title}
                index={index}
                handleRemoveCard={removeServiceSection}
                isNew={data?.isNew}
                toggle={data?.toggle}
                showToggle={activeTab.short_name === 'en' ? true : false}
                handleResourseToggle={handleResourseToggle}
                cardName='Ad'
              >
                <div className='widget_box'>
                  <div>
                    <NewAttachment
                      value={data?.attachment}
                      id={data?.id}
                      onFileSelected={onFileSelected}
                      fileType='image'
                      label={`Add Attachment ${index + 1} `}
                      className='widget_box_feed__attachment'
                      sizevalidationUpto={100}
                    />
                    <div className='d-flex'>
                      <div>
                        <label>Cohorts</label>
                        <ul className='multiselect-btn'>
                          {DropdownOptions?.CohortOption?.map(
                            (item, indexx) => {
                              return (
                                <li
                                  key={indexx}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleCohortTags(item, data?.carouselId);
                                  }}
                                  className={
                                    selectedCohort?.[data?.carouselId] &&
                                    selectedCohort?.[data?.carouselId].includes(
                                      item
                                    )
                                      ? 'active'
                                      : ''
                                  }
                                >
                                  {item}
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                      <div>
                        <label>Age groups</label>
                        <ul className='multiselect-btn'>
                        {Object.keys(elsAgeGroup).map((key, i) => {
                             const value = elsAgeGroup[key];
                            return (
                              <li
                                key={i}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleAgeOptions(key, data?.carouselId);
                                }}
                                className={
                                  selectedAge?.[data?.carouselId] &&
                                  selectedAge?.[data?.carouselId]?.includes(
                                    key
                                  )
                                    ? 'active'
                                    : ''
                                }
                              >
                                {value}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div className='widget_box_divide'></div>
                  <div>
                    <CTAWidgetComponent
                      id={data?.id}
                      index={data?.id}
                      externalUrl={data?.externalUrl}
                      ctaValue={data?.title}
                      urlType={data?.urlType}
                      DropdownOptions={DropdownOptions.InternalUrl}
                      value={data?.url}
                      isHide={true}
                      onChange={OnhandleChange}
                      swappingOrder={data?.swappingOrder}
                      redirection_id={
                        data?.redirection_id ? data?.redirection_id : null
                      }
                      OnhandleChange={OnhandleChange}
                      urlCopy={data?.urlCopy}
                      redirection_idURL={data?.redirection_idURL}
                    />
                    <NewInput
                      label='Display order'
                      id={data.id}
                      placeholder='Enter display Order 1/2/3'
                      name='displayOrder'
                      value={data.displayOrder}
                      onChange={OnhandleChange}
                      type='number'
                    />
                  </div>
                </div>
              </SmallBannerCard>
            </div>
          );
        })}

        <div className={`widget-button-section`}>
          <div className='section-header__right'>
            <Button
              className='addbutton mr2'
              onClick={addServiceSection}
              type='submit'
              name='Add Banners'
              isDisabled={isAddButtonDisabled}
            />

            <Button
              onClick={validateRequestpreviewScreen}
              type='submit'
              name='Publish'
              isDisabled={isPublishButtonDisabled}
            />
          </div>
        </div>
      </div>
      {pageLoading && <PageLoader pageLoading={pageLoading} />}
      <Modal show={show} onHide={handleClose} className='servicemodel'>
        <Modal.Body className='modal-body'>
          <div className='model-body-section'>
            <div className='model-body-section-list'>
              {sortedBannerList &&
                sortedBannerList.map((data, index) => {
                  return (
                    <div key={index}>
                      {}
                      {data?.attachmentFile ? (
                        <FilePreview data={data} />
                      ) : data?.attachment ? (
                        <div>
                          <img
                            alt='latestbannerimg'
                            className={`${
                              data.fileType === 'gif'
                                ? 'file_view_image file_view_gif'
                                : 'file_view_image'
                            }`}
                            src={`${
                              process?.env?.REACT_APP_S3_BUCKET_URL &&
                              process?.env?.REACT_APP_S3_BUCKET_URL
                            }/${data?.attachment}`}
                          />
                        </div>
                      ) : (
                        ''
                      )}
                      <div className='service_top'>
                        {data?.title && (
                          <div className='service_top_title'>{data?.title}</div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className='model-footer'>
          <div className='model-footer-section'>
            Are you sure to publish the preview to live app ?
            <div className='model-button-section'>
              <Button variant='primary' onClick={handleClose}>
                Cancel
              </Button>
              <Button variant='secondary' onClick={handleCreateServices}>
                Confirm Publish
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </div>
  );
};

export default ElsAdsSpaceContainer;
