import RadioButton from 'app/shared/components/RadioButton';
import Button from 'app/components/button';
import '../index.scss';
import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import NewInput from 'app/components/NewInput';
import Toast from 'app/utils/Toast';
import PageLoader from 'app/components/PageLoader';
import { imageSizeValidation } from 'utils/Parsers';
import DropdownOptions from 'app/utils/DropdownOptions';
import Dropdown from 'app/shared/components/Dropdown';
import ValidationMessage from 'utils/ValidationMessage';
import MultiSelectDropdown from 'app/components/MultiSelectDropdown';
import {
  getUserJourneyAdTaskTypeList,
  createUserJourneyAdTask,
  updateUserJourneyAdTask,
  getUserJourneyAdTaskTypeDetailsByID,
  updateUserJourneyAdTaskTypeDetailsByID,
} from 'app/api/user-journey';
import { CloseBlackIcon } from 'app/constants/image-constants';
import CTAType from 'app/components/CTAType';
import TabBar from 'app/components/NewDesign/TabBar';
import useTabBarOptions from 'app/utils/useTabbarOptions';
import { uplaodMedia } from 'app/api/widgets';
const CreateAdTaskContainer = () => {
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const params = useParams();
  const [internalUrl, setInternalURL] = useState([]);
  const [taskTypeOptions, setTaskTypeOptions] = useState([]);
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [gifImage, setGifImage] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const [state, setState] = useState({
    title: '',
    sub_title: '',
    description: '',
    cta: '',
    ad_type: '',
    task_type: '',
    streak_days: [],
    cohort_tags: [],
    url_type: 'INTERNAL',
    url: '',
    thumbnail_image: '',
    cohort_type: '',
    internal_for: '',
    internal_url: '',
    profile_data: false,
    custom_object: '',
    redirection_id: 0,
    internal_for: '',
    internal_url: '',
    custom_object: '',
    detail_cta_text: '',
    url_type_secondary: '',
    redirection_id_secondary: null,
    internal_for_secondary: '',
    internal_url_secondary: '',
    url_secondary: '',
    detail_cta_text_secondary: '',
    profile_data_secondary: false,
    custom_object_secondary: '',
    secondaryCTA: false,
    crown_text: '',
    // endless_scroll_media: '',
    youtube_url: '',
    video_url: '',
    gif_image: '',
    cta_secondary: '',
  });
  const cohortOptions = [
    {
      name: 'Asthma',
      id: '1',
      value: 'ASTHMA',
    },
    {
      name: 'COPD',
      id: '2',
      value: 'COPD',
    },
    {
      name: 'AR',
      id: '3',
      value: 'AR',
    },
    {
      name: 'ILD',
      id: '4',
      value: 'ILD',
    },
    {
      name: 'Others',
      id: '5',
      value: 'OTHER',
    },
  ];
  const { LanguageOptions } = useTabBarOptions();
  const [activeTab, setActiveTab] = useState({
    id: '1',
    name: 'English',
    short_name: 'en',
    display_order: 1,
    status: 'ACTIVE',
    value: 'English',
  });

  const [disableFields, setDisableFields] = useState(false);

  let history = useHistory();
  useEffect(() => {
    let internalUrl = [];
    DropdownOptions.InternalUrl.map((item, i) => {
      if (
        item.value !== 'feed' &&
        item.value !== 'content_detail' &&
        item.value !== 'quiz' &&
        item.value !== 'vital_activity' &&
        item.value !== 'webinar_details' &&
        item.value !== 'individual_quiz'
      ) {
        internalUrl.push(item);
      }
    });
    internalUrl.push({
      value: 'community_videos',
      label: 'Community Videos',
    });

    setInternalURL(internalUrl);

    getAdTaskTypelists();
  }, [activeTab?.name, params.id]);

  const getAdTaskTypelists = async () => {
    let taskTypeList = await getUserJourneyAdTaskTypeList();
    let taskOptions = [];
    taskTypeList.map((item) => {
      taskOptions.push({
        label: item,
        value: item,
      });
    });

    setTaskTypeOptions(taskOptions);
    if (params.id) {
      getAdTaskDetails();
    }
  };

  const getAdTaskDetails = async () => {
    let adtaskDetails = '';

    // const details = await getNavigationDetails(id)

    if (activeTab?.name === 'English') {
      adtaskDetails = location.state;
      setState({
        title: adtaskDetails.title,
        sub_title: adtaskDetails.sub_title,
        description: adtaskDetails.description,
        cta: adtaskDetails.cta,
        ad_type: adtaskDetails.ad_type,
        task_type: adtaskDetails.task_type,
        url_type: adtaskDetails.url_type,
        url: adtaskDetails.url,
        thumbnail_image: adtaskDetails.thumbnail_path,
        cohort_tags: adtaskDetails.cohort_tags,
        internal_for: adtaskDetails.internal_for,
        internal_url: adtaskDetails.internal_url,
        profile_data: adtaskDetails.profile_data,
        custom_object: adtaskDetails.custom_object,
        redirection_id: adtaskDetails.redirection_id
          ? adtaskDetails.redirection_id
          : '',
        streak_days: adtaskDetails.streak_days,
        crown_text: adtaskDetails.crown_text,
        secondaryCTA: adtaskDetails.url_type_secondary ? true : false,
        url_secondary: adtaskDetails.url_secondary,
        url_type_secondary: adtaskDetails.url_type_secondary
          ? adtaskDetails.url_type_secondary
          : '',
        redirection_id_secondary: adtaskDetails.redirection_id_secondary,
        profile_data_secondary: adtaskDetails.profile_data_secondary,
        internal_url_secondary: adtaskDetails.internal_url_secondary,
        internal_for_secondary: adtaskDetails.internal_for_secondary,
        video_url: adtaskDetails.video_url,
        gif_image: adtaskDetails.gif_image,
        cta_secondary: adtaskDetails.cta_secondary,
      });
      if (adtaskDetails.thumbnail_path) {
        let thumbnail_image = `${process.env.REACT_APP_S3_BUCKET_URL}/${adtaskDetails.thumbnail_path}`;
        setThumbnailImage(thumbnail_image);
      }
      if (adtaskDetails.gif_image) {
        let gif_image = `${process.env.REACT_APP_S3_BUCKET_URL}/${adtaskDetails.gif_image}`;
        setGifImage(gif_image);
      }
      if (adtaskDetails.video_url) {
        let video_url = `${process.env.REACT_APP_S3_BUCKET_URL}/${adtaskDetails.video_url}`;
        setSelectedVideo(video_url);
      }
    } else {
      const resp = await getUserJourneyAdTaskTypeDetailsByID(
        params.id,
        activeTab?.short_name
      );
      setState((prevState) => ({
        ...prevState,
        title: resp.title ? resp.title : '',
        sub_title: resp.sub_title ? resp.sub_title : '',
        description: resp.description ? resp.description : '',
        cta: resp.cta ? resp.cta : '',
        thumbnail_image: resp.thumbnail_path ? resp.thumbnail_path : '',
        video_url: resp.video_url ? resp.video_url : '',
        gif_image: resp.gif_image ? resp.gif_image : '',
        cta_secondary: resp.cta_secondary ? resp.cta_secondary : '',
      }));
      if (resp.video_url) {
        let video_url = `${process.env.REACT_APP_S3_BUCKET_URL}/${resp.video_url}`;
        setSelectedVideo(video_url);
      } else {
        selectedVideo('');
      }
      if (resp.gif_image) {
        let gif_image = `${process.env.REACT_APP_S3_BUCKET_URL}/${resp.gif_image}`;
        setGifImage(gif_image);
      } else {
        setGifImage('');
      }
      if (resp.thumbnail_path) {
        let thumbnail_image = `${process.env.REACT_APP_S3_BUCKET_URL}/${resp.thumbnail_path}`;
        setThumbnailImage(thumbnail_image);
      } else {
        setThumbnailImage('');
      }
    }
  };
  const adTypeOptions = [
    {
      label: 'BUY NOW 1',
      value: 'BUYNOW1',
    },
    {
      label: 'BUY NOW 2',
      value: 'BUYNOW2',
    },
    {
      label: 'PREMIUM',
      value: 'PREMIUM',
    },
    {
      label: 'LCP AD',
      value: 'LCP_AD',
    },
  ];

  const streakTypeOptions = [
    {
      name: '1',
      value: '1',
    },
    {
      name: '2',
      value: '2',
    },
    {
      name: '3',
      value: '3',
    },
    {
      name: '4',
      value: '4',
    },
    {
      name: '5',
      value: '5',
    },
    {
      name: '6',
      value: '6',
    },
    {
      name: '7',
      value: '7',
    },
    {
      name: '8',
      value: '8',
    },
    {
      name: '9',
      value: '9',
    },
    {
      name: '10',
      value: '10',
    },
    {
      name: '11',
      value: '11',
    },
    {
      name: '12',
      value: '12',
    },
    {
      name: '13',
      value: '13',
    },
    {
      name: '14',
      value: '14',
    },
    {
      name: '15',
      value: '15',
    },
    {
      name: '16',
      value: '16',
    },
    {
      name: '17',
      value: '17',
    },
    {
      name: '18',
      value: '18',
    },
    {
      name: '19',
      value: '19',
    },
    {
      name: '20',
      value: '20',
    },
    {
      name: '21',
      value: '21',
    },
    {
      name: '22',
      value: '22',
    },
    {
      name: '23',
      value: '23',
    },
    {
      name: '24',
      value: '24',
    },
    {
      name: '25',
      value: '25',
    },
    {
      name: '26',
      value: '26',
    },
    {
      name: '27',
      value: '27',
    },
    {
      name: '28',
      value: '28',
    },
    {
      name: '29',
      value: '29',
    },
    {
      name: '30',
      value: '30',
    },
    {
      name: '31',
      value: '31',
    },
  ];

  const handleTextChange = (e) => {
    if (e.target.name === 'title') {
      if (e.target.value.length <= 34) {
        setState({ ...state, [e.target.name]: e.target.value });
      }
    } else if (e.target.name === 'sub_title') {
      if (e.target.value.length <= 30) {
        setState({ ...state, [e.target.name]: e.target.value });
      }
    } else {
      setState({ ...state, [e.target.name]: e.target.value });
    }
  };

  const handleMediaUpload = async (e) => {
    let file = e.target.files[0];
    let reader = new FileReader();
    if (e.target.files[0].type?.split('/')?.[0] === 'video') {
      file = e.target.files[0];
      const src = URL.createObjectURL(file);
      var video = document.createElement('video');
      video.src = src;
      let resp = await uplaodMedia('image', file);
      setState({
        ...state,
        [e.target.name]: resp?.filename,
        // video_url: resp?.filename,
      });
      video.addEventListener('loadedmetadata', function () {
        setState({
          ...state,
          [e.target.name]: resp?.filename,
        });
      });
      reader.onloadend = function () {
        setSelectedVideo(reader.result);
      };
      reader.readAsDataURL(file);
      Toast.success(file?.name + ' uploaded');
    } else if (e?.target?.name === 'gif_image') {
      file = e.target.files[0];
      if (file.type === 'image/gif' && imageSizeValidation(e)) {
        Toast.error(ValidationMessage.IMAGE_SIZE);
        return;
      } else {
        let resp = await uplaodMedia('image', file);

        if (e.target.name === 'gif_image') {
          setState({ ...state, [e.target.name]: resp?.filename });
          let reader = new FileReader();
          reader.onloadend = function () {
            setGifImage(reader.result);
          };
          reader.readAsDataURL(file);
        }

        Toast.success(file?.name + ' uploaded');
      }
    } else if (
      (file.type === 'image/jpeg' ||
        file.type === 'image/jpg' ||
        file.type === 'image/png') &&
      imageSizeValidation(e)
    ) {
      Toast.error(ValidationMessage.IMAGE_SIZE);
      return;
    } else {
      let reader = new FileReader();
      reader.onloadend = function () {
        setThumbnailImage(reader.result);
      };
      reader.readAsDataURL(file);
      setState({ ...state, [e.target.name]: file });
      Toast.success(file?.name + ' uploaded');
    }
  };
  const cancelMedia = async (e, file) => {
    console.log(e, 'name');
    setState({ ...state, [e.target.name]: '' });
    // await deleteUploadedMedia(file)
  };
  const handleSubmit = async () => {
    let payload = { ...state };

    let thumbnail_image = state.thumbnail_image;
    delete payload.thumbnail_image;

    if (state.ad_type === 'LCP_AD') {
      payload.cta = state.cta;
      payload.cta_secondary = state.cta_secondary;
    }

    if (!state.title) {
      Toast.error('Please complete all fields');
      return;
    } else if (
      state.ad_type === 'LCP_AD' &&
      (!state.crown_text || !state.streak_days)
    ) {
      Toast.error('Please complete all fields');
      return;
    } else if (
      state.ad_type === 'LCP_AD' &&
      (!state.video_url || !state.gif_image)
    ) {
      Toast.error('Please add Video and gif');
      return;
    } else {
      setLoader(true);
      let responseObj;
      if (params.id) {
        payload.id = params.id;

        if (activeTab?.name === 'English') {
          responseObj = await updateUserJourneyAdTask(payload, thumbnail_image);
        } else {
          let languagePayload = {
            title: payload?.title ? payload?.title : '',
            sub_title: payload?.sub_title ? payload?.sub_title : '',
            cta: payload?.cta ? payload?.cta : '',
            description: payload?.description ? payload?.description : '',
            thumbnail_image: thumbnail_image
              ? thumbnail_image
              : payload?.thumbnail_image
              ? payload?.thumbnail_image
              : '',
            lang: activeTab?.short_name,
            video_url: payload?.video_url ? payload?.video_url : '',
            gif_image: payload?.gif_image ? payload?.gif_image : '',
          };
          responseObj = await updateUserJourneyAdTaskTypeDetailsByID(
            languagePayload,
            params?.id
          );
        }
      } else {
        responseObj = await createUserJourneyAdTask(payload, thumbnail_image);
      }

      if (
        responseObj &&
        responseObj.response &&
        responseObj.response.code === 200
      ) {
        Toast.success(responseObj.response.alert[0].message);
        history.push('/userjourney/adtasks');
      } else {
        setLoader(false);
        Toast.error(
          responseObj.data
            ? responseObj.data.msg
            : responseObj.response.alert[0].message
            ? responseObj.response.alert[0].message
            : ''
        );
      }
    }
  };

  const handleTabChange = (val) => {
    setActiveTab(val);
    if (val?.name === 'English') {
      setDisableFields(false);
    } else {
      setDisableFields(true);
    }
  };
  const handleSecondaryInputChange = (key, value) => {
    if(!value ){
      setState({ ...state, [key]: value, url:'' });
    }else{
      setState({ ...state, [key]: value });
    } 
  };
  const handleSecondaryCTA = () => {
    if (state.secondaryCTA) {
      setState({
        ...state,
        secondaryCTA: !state.secondaryCTA,
        url_type_secondary: '',
        redirection_id_secondary: null,
        internal_for_secondary: '',
        internal_url_secondary: '',
        url_secondary: '',
        cta_secondary: '',
        profile_data_secondary: false,
        custom_object_secondary: '',
      });
    } else {
      setState({ ...state, secondaryCTA: !state.secondaryCTA });
    }
  };
  const handleCTATextChange = (e) => {
    console.log(e.target.name == 'detail_cta_text');
    if (e.target.name == 'detail_cta_text') {
      setState({ ...state, cta: e.target.value });
    } else if (e.target.name == 'detail_cta_text_secondary') {
      setState({ ...state, cta_secondary: e.target.value });
    } else {
      setState({ ...state, [e.target.name]: e.target.value });
    }
  };
  return (
    <div className='container'>
      {/* <h1 className="section-header__title">Ad Tasks</h1> */}
      {params.id ? (
        <TabBar
          tabs={LanguageOptions}
          initialTab={activeTab}
          onTabChange={handleTabChange}
          parentStyle={'service-tab-style'}
        />
      ) : null}

      <div className='separator'>
        <p>Add Task</p>
      </div>
      <div className='row'>
        <div className='col-sm-6'>
          <div className=' my-5'>
            <label>Task Type </label>
            <select
              name='task_type'
              className='custom-select mx-3 w-50'
              onChange={(e) => {
                setState({ ...state, task_type: e.target.value });
              }}
              disabled={disableFields}
            >
              <option value=''>Select</option>
              {taskTypeOptions?.map((item, indx) => (
                <option
                  key={state.task_type + indx}
                  value={item.value}
                  selected={state.task_type == item.value ? true : false}
                >
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='col-sm-6'>
          <div className=' my-5'>
            <label>Ad Type </label>
            <select
              name='ad_type'
              className='custom-select mx-3 w-50'
              onChange={(e) => {
                setState({ ...state, ad_type: e.target.value });
              }}
              disabled={disableFields || params.id}
            >
              <option value=''>Select</option>
              {adTypeOptions?.map((item, indx) => (
                <option
                  key={indx}
                  value={item.value}
                  selected={state.ad_type == item.value ? true : false}
                >
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='col-sm-6'>
          <MultiSelectDropdown
            label='Streak Days'
            name='streak_days'
            value={state.streak_days}
            options={streakTypeOptions}
            onChange={handleTextChange}
            tag_type={false}
            disable={disableFields}
          />
        </div>
        <div className='col-md-6'>
          <MultiSelectDropdown
            label='Cohort Tag'
            name='cohort_tags'
            value={state.cohort_tags}
            options={cohortOptions}
            onChange={handleTextChange}
            tag_type={false}
            disable={disableFields}
          />
        </div>
        <div className='col-sm-6'>
          <NewInput
            label='Title'
            name={'title'}
            value={state.title}
            onChange={handleTextChange}
          />
        </div>
        <div className='col-sm-6'>
          <NewInput
            label='Sub Title'
            name={'sub_title'}
            value={state.sub_title}
            onChange={handleTextChange}
          />
        </div>
        {state.ad_type === 'LCP_AD' && (
          <div className='col-sm-6'>
            <NewInput
              label='Crown Text'
              name={'crown_text'}
              value={state.crown_text}
              onChange={handleTextChange}
            />
          </div>
        )}

        <div className='col-sm-6'>
          <NewInput
            label='Description'
            name={'description'}
            value={state.description}
            onChange={handleTextChange}
          />
        </div>
        {state.ad_type !== 'LCP_AD' && (
          <div className='col-sm-6'>
            <NewInput
              label='CTA'
              name={'cta'}
              value={state.cta}
              onChange={handleTextChange}
            />
          </div>
        )}
      </div>
      <div className={`userInput ${disableFields ? 'disbale-ctaType' : ''}`}>
        {state.ad_type === 'LCP_AD' ? (
          <div className='my-4'>
            <h5>Primary CTA</h5>
            <CTAType
              cta_type={state.url_type}
              handleChange={(key, val) => {
                if (key === 'cta_type') {
                  handleSecondaryInputChange('url_type', val);
                } else if (key === 'cta_type_secondary') {
                  handleSecondaryInputChange('url_type_secondary', val);
                } else {
                  if (
                    key === 'url' &&
                    (val !== 'zoom_live_session' ||
                      val !== 'infinite_scroll' ||
                      val !== 'group_of_collection')
                  ) {
                    setState({ ...state, [key]: val, redirection_id: '' });
                  } else if (
                    key === 'url_secondary' &&
                    (val !== 'zoom_live_session' ||
                      val !== 'infinite_scroll' ||
                      val !== 'group_of_collection')
                  ) {
                    setState({
                      ...state,
                      [key]: val,
                      redirection_id_secondary: '',
                    });
                  } else {
                    setState({ ...state, [key]: val });
                  }
                }
              }}
              internalUrl={DropdownOptions.InternalUrl}
              url={state.url}
              handleTextChange={(e) => {
                console.log('change', e);
                handleCTATextChange(e);
              }}
              internal_for={state.internal_for}
              internal_url={state.internal_url}
              profile_data={state.profile_data}
              custom_object={state.custom_object}
              redirection_id={state.redirection_id}
              secondaryCTA={state.secondaryCTA}
              cta_type_secondary={state.url_type_secondary}
              url_secondary={state.url_secondary}
              internal_for_secondary={state.internal_for_secondary}
              internal_url_secondary={state.internal_url_secondary}
              profile_data_secondary={state.profile_data_secondary}
              custom_object_secondary={state.custom_object_secondary}
              redirection_id_secondary={state.redirection_id_secondary}
              ctaText={true}
              detail_cta_text={state.cta}
              detail_cta_text_secondary={state.cta_secondary}
            />

            <div className='row'>
              <div className='col d-flex justify-content-start my-3'>
                <Button
                  type='button'
                  onClick={() => {
                    handleSecondaryCTA();
                  }}
                >
                  {state.secondaryCTA
                    ? 'Remove Secondary CTA'
                    : 'Add Secondary CTA'}
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className='userFormArea'>
            <label>Redirect Type</label>
            <CTAType
              cta_type={state.url_type}
              handleChange={(key, data) => {
                if (key === 'cta_type') {
                  if(!data ){
                    setState({ ...state, [key]: data, url:'' });
                  }else{
                    setState({ ...state, [key]: data });
                  } 
                } else {
                  if (
                    key === 'url' &&
                    (data !== 'zoom_live_session' ||
                      data !== 'infinite_scroll' ||
                      data !== 'group_of_collection')
                  ) {
                    setState({ ...state, [key]: data, redirection_id: '' });
                  } else {
                    setState({ ...state, [key]: data });
                  }
                }
              }}
              internalUrl={internalUrl}
              internal_for={state.internal_for}
              internal_url={state.internal_url}
              profile_data={state.profile_data}
              custom_object={state.custom_object}
              url={state.url}
              redirection_id={state.redirection_id}
              handleTextChange={handleTextChange}
              isDisabled={disableFields}
            />
          </div>
        )}

        {state.ad_type === 'LCP_AD' ? (
          <div className='userFormArea'>
            <div>
              {state.video_url && (
                <p
                  className='cancel'
                  onClick={(e) => {
                    cancelMedia(e, state.video_url);

                    setSelectedVideo(null);
                    setState({ ...state, video_url: '' });
                  }}
                >
                  Cancel Upload
                </p>
              )}
            </div>
            <div className='upload-buttons'>
              <input
                type='file'
                id='videoFile'
                accept='video/*'
                style={{ display: 'none' }}
                name='video_url'
                onChange={(e) => {
                  handleMediaUpload(e);
                }}
              />
              <div className='upload-label'>
                <label>Upload Video</label>
                <Button
                  onClick={() => {
                    const image = document.getElementById('videoFile');
                    image.click();
                  }}
                  type='button'
                  isDisabled={state.video_url ? true : false}
                  name={state.video_url ? 'Uploaded' : 'Upload Video'}
                />
              </div>
              {selectedVideo && (
                <div className='attachment_img_wrapper'>
                  <video className='attachment__selimage' controls>
                    <source src={selectedVideo} type='video/mp4' />
                  </video>
                  <div className='attachment_img_wrapper_close'>
                    <img
                      src={CloseBlackIcon}
                      alt='Remove'
                      onClick={(e) => {
                        cancelMedia(e, state.video_url);
                        setSelectedVideo(null);
                        setState({ ...state, video_url: '' });
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          ''
        )}
        {state.ad_type === 'LCP_AD' ? (
          ''
        ) : (
          <div className='userFormArea horizontal-align'>
            <div className='col'>
              <div>
                {state.thumbnail_image && (
                  <p
                    className='cancel'
                    onClick={(e) => {
                      setThumbnailImage(null);
                      setState({ ...state, thumbnail_image: '' });
                    }}
                  >
                    Cancel Thumbnail
                  </p>
                )}
              </div>
              <div className='row'>
                <div className='col-md-2'>
                  <label>Thumbnail</label>
                </div>
                <div className='col-auto'>
                  <input
                    type='file'
                    id='thumbnail_image'
                    style={{ display: 'none' }}
                    name='thumbnail_image'
                    key={state.thumbnail_image}
                    onChange={(e) => {
                      handleMediaUpload(e);
                    }}
                  />

                  <div className='upload-label'>
                    <Button
                      onClick={() => {
                        const image =
                          document.getElementById('thumbnail_image');
                        image.click();
                      }}
                      isDisabled={state.thumbnail_image}
                      type='button'
                      name={
                        state.thumbnail_image != ''
                          ? 'Uploaded'
                          : 'Upload Thumbnail Image'
                      }
                    />
                  </div>
                </div>
                <div className='col-auto'>
                  {thumbnailImage && (
                    <div className='attachment_img_wrapper'>
                      <img
                        src={thumbnailImage}
                        alt='sample'
                        className='feed__attachment'
                      />
                      <div className='attachment_img_wrapper_close'>
                        <img
                          src={CloseBlackIcon}
                          alt='Remove'
                          onClick={(e) => {
                            setThumbnailImage(null);
                            setState({ ...state, thumbnail_image: '' });
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {state.ad_type === 'LCP_AD' && (
          <div className='userFormArea '>
            <div>
              {state.gif_image && (
                <p
                  className='cancel'
                  onClick={(e) => {
                    setGifImage(null);
                    setState({ ...state, gif_image: '' });
                  }}
                >
                  Cancel gif
                </p>
              )}
            </div>

            <div className='upload-buttons'>
              <input
                accept='image/gif'
                type='file'
                id='gif_image'
                style={{ display: 'none' }}
                name='gif_image'
                key={state.gif_image}
                onChange={(e) => {
                  handleMediaUpload(e);
                }}
              />

              <div className='upload-label'>
                <label>Upload gif</label>
                <Button
                  onClick={() => {
                    const image = document.getElementById('gif_image');
                    image.click();
                  }}
                  isDisabled={state.gif_image}
                  type='button'
                  name={state.gif_image ? 'Uploaded' : 'Upload Gif Image'}
                />
              </div>

              <div className='col-auto'>
                {gifImage && (
                  <div className='attachment_img_wrapper'>
                    <img
                      src={gifImage}
                      alt='sample'
                      className='feed__attachment'
                    />
                    <div className='attachment_img_wrapper_close'>
                      <img
                        src={CloseBlackIcon}
                        alt='Remove'
                        onClick={(e) => {
                          setGifImage(null);
                          setState({ ...state, gif_image: '' });
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {/* {
          state.ad_type === 'LCP_AD' &&
          <NewInput
            label='Youtube URL'
            name='youtube_url'
            value={state.youtube_url ? state.youtube_url : ''}
            disabled={state.video_url ? true : false}
            onChange={handleTextChange}
          />} */}
        <div className='userFormArea'>
          <Button name={'Save'} onClick={handleSubmit} />
          <label
            style={{ paddingLeft: '10px', cursor: 'pointer' }}
            onClick={() => {
              history.goBack();
            }}
          >
            Cancel
          </label>
        </div>
      </div>
      {loader && <PageLoader pageLoading={loader} />}
    </div>
  );
};

export default CreateAdTaskContainer;
