import React from 'react';
import BasePage from 'pages/BasePage';
import CreateAbTesting from 'app/container/CreateAbTesting';

const AbTestingCreatePage = () => {
  return (
    <>
      <BasePage>
        <CreateAbTesting />
      </BasePage>
    </>
  );
};

export default AbTestingCreatePage;
