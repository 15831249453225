import { useState } from 'react';
import RecommendedCourseList from './RecommendedCourseList';
import StartRecommendedCourse from './StartRecommendedCourse';
import './index.scss';
const RecommendCoursesContainer = () => {
  const [CourseStatus, setCourseStatus] = useState('Start');
  const [submitState, setsubmitState] = useState(true);
  const handleCheckboxChange = () => {
    // Toggle the checkbox state
    setsubmitState(true);
    setCourseStatus(CourseStatus === 'Start' ? 'Recommended ' : 'Start');
  };
  return (
    <div className=''>
      <h3>Recommended Courses</h3>
      <div className='tab-switch'>
        <label>
          <input
            type='checkbox'
            checked={CourseStatus === 'Start' ? false : true}
            onChange={handleCheckboxChange}
          />
          <span>Start Courses</span>
          <span>Recommended Courses </span>
        </label>
      </div>
      <div className='tab-content'>
        {CourseStatus === 'Start' ? (
          <StartRecommendedCourse />
        ) : (
          <RecommendedCourseList />
        )}
      </div>
    </div>
  );
};

export default RecommendCoursesContainer;
