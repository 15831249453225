import CmsDiscoverContainer from "app/container/CMSDiscover";
import BasePage from "app/pages/BasePage";
import CmsDiscoverHomeWidgetContainer from "app/container/CMSDiscoverHomeWidget";

const CmsDiscoverHomeWidget = () => {
     return (
          <BasePage>
               <CmsDiscoverHomeWidgetContainer />
          </BasePage>
     );
}

export default CmsDiscoverHomeWidget;