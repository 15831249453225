import RatingPage from "app/container/Rating";
import BasePage from "app/pages/BasePage";

const Rating = () => {
    return (
        <BasePage>
            <RatingPage />
        </BasePage>
    );
}

export default Rating;