import { useHistory } from 'react-router';
import '../index.scss';
import moment from 'moment';

function CustomTable({
  data,
  tableHeader,
  handleDelete,
  handleInputChangeHandler,
}) {
  const history = useHistory();

  //handle navigation
  //   const handleNavigation = (item) => {
  //     history.push({
  //       pathname: `/dynamic-widgets/edit/${item.widget_id}`,
  //       state: {
  //         ...item,
  //       },
  //     });
  //   }; 
  const handleTEstimatedotal = (val) => {
    let sum = val.reduce(function(prev, current) {
      return prev + +current.total_amount
    }, 0);
    return sum
  }
  return (
    <table>
      <tr>
        {tableHeader.map((item, i) => {
          return <th className='nowrap text-center'>{item.Header}</th>;
        })}
      </tr>
      {data.map((items, indx) => (
        <tr key={indx}>
          <td>
            <p>
              <span className='text-primary'>{items.medicine_name}</span>
            </p>
          </td>
          <td>
            <p>{items.packfrom ? items.packfrom : 'NA'}</p>
          </td>
          <td>
            <p>{items.packqty ? items.packqty : 'NA'}</p>
          </td>
          <td>
            <input
              type='number'
              className='form-input'
              placeholder=''
              name='qty'
              min={1}
              max={100}
              value={items.qty}
              onChange={(e) => handleInputChangeHandler('qty', e, indx)}
            />
          </td>
          <td>
            <p>{items.mrp ? items.mrp : 0}</p>
          </td>
          <td>
            <p>{items.total_amount ? items.total_amount : 0}</p>
          </td>

          <td>
            <div
              className='btn-group'
              role='group'
              aria-label='Basic outlined example'
            >
              <button
                type='button'
                onClick={() => {
                  handleDelete('ranking', indx);
                }}
                className='btn btn-outline-primary'
              >
                Delete
              </button>
            </div>
          </td>
        </tr>
      ))}

      {data.length === 0 ? (
        <tr>
          <td colspan='10000'>No data found</td>{' '}
        </tr>
      ) : (
        <tr>
          <td colspan={5} style={{ textAlign: 'right' }}>
            <h5>
              <b>Estimated Total</b>
            </h5>
          </td>
          <td>{handleTEstimatedotal(data)}</td>
        </tr>
      )}
    </table>
  );
}

export default CustomTable;
