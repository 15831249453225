import { useState } from 'react';
import { URLType } from 'constants/app-constants';
import validate from 'components/ValidateInfo';

const useContentForm = () => {
  const [values, setValues] = useState({
    title: '',
    description: '',
    content: '',
    attachment: null,
    attachmentFile: null,
    startDate: '',
    endDate: '',
    tag: [],
    url: '',
    urlType: URLType.NO,
    day: '',
    age: '',
    ctaTitle: '',
    question: '',
    answer: '',
    points: '',
    bonusPoints: '',
    externalUrl: '',
    challengeType: '',
    hasChallenge: '',
    tipId: '',
    Image: '',
    email: '',
    mobile: '',
    quizEnabled: false,
    quizId: '',
    published: '',
    HabitId: '',
    isQuiz: '',
    staticType: '',
    role: '',
    sequenceOrder: '',
    sortingOrder: '',
    criteria: '',
    android_version: ''
  });
  const [error, setErrors] = useState({});

  const updateValue = (obj) => {
    setValues({
      ...obj,
    });
  };

  const resetValues = () => {
    setValues({
      title: '',
      description: '',
      content: '',
      attachment: null,
      startDate: '',
      endDate: '',
      tag: [],
      attachmentFile: null,
      url: '',
      urlType: '',
      day: '',
      age: '',
      ctaTitle: '',
      question: '',
      answer: '',
      points: '',
      bonusPoints: '',
      externalUrl: '',
      challengeType: '',
      hasChallenge: '',
      email: '',
      mobile: '',
      quizEnabled: false,
      quizId: '',
      published: '',
      isQuiz: '',
      staticType: '',
      criteria: '',
      android_version: ''
    });
  };

  const handleChange = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const handleSubmitting = (e) => {
    // e.preventDefault();
    setErrors(validate(values));
  };

  return {
    handleChange,
    values,
    handleSubmitting,
    error,
    setValues,
    updateValue,
    resetValues,
  };
};

export default useContentForm;
