import { useState } from 'react';

const useContentBannerForm = (validate) => {
  const [count, setCount] = useState(1);
  const [values, setValues] = useState({
    swappingOrder: '',
    bannerType: '',
    startDate: '',
    endDate: '',
    points: '',
    fallbackBanner: null,
    fallbackBannerFile: null,
    banners: [
      {
        // id: Date.now(), // Basically its a index
        id: 'banner_0',
        bannerId: '',
        title: 'section 1',
        urlType: '',
        redirection_id: null,
        redirection_idURL: null,
        url: '',
        externalUrl: '',
        attachment: '',
        attachmentFile: null,
        displayOrder: '',
        eventPayloadType: '',
        eventName: '',
        criteria: '',
        android_version: '',
        internal_for:'',
        internal_url:'',
        profile_data:false,
        custom_object:''
      },
    ],
    removedBanners: [],
  });

  const addBannerSection = () => {
    const banners = [...values.banners];
    if (banners.length < 14) {
      banners.push({
        id: Date.now(), // Basically its a index
        bannerId: '',
        isNew: true,
        title: '',
        urlType: '',
        url: '',
        externalUrl: '',
        attachment: '',
        attachmentFile: null,
        displayOrder: '',
        redirection_id: null,
        redirection_idURL: null,
        bannerTag: '',
        criteria: '',
        android_version: '',
        internal_for:'',
        internal_url:'',
        profile_data:false,
        custom_object:''
      });
      setCount(count + 1);
      setValues({
        ...values,
        banners,
      });
    }
  };

  const removeBannerSection = (ind) => {
    const banners = [];
    let removedBanners = [...values.removedBanners];
    values.banners.forEach((banner, index) => {
      if (ind !== index) {
        banners.push(banner);
      }
      if (ind === index && banner.bannerId) {
        removedBanners.push(parseInt(banner.bannerId));
      }
    });

    setCount(banners.length);
    setValues({
      ...values,
      banners,
      removedBanners,
    });
  };

  const updateValue = (obj) => {
    setValues({
      ...obj,
    });

    setCount(obj.banners.length);
  };

  const [error, setErrors] = useState({});

  const handleChange = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      setValues({
        ...values,
        [name]: value,
      });
    }
  };

  const OnhandleChange = (e) => {
    const valueCpy = { ...values };
    valueCpy.banners.map((element) => {
      if (`${element.id}` === `${e.target.id}`) { 
        element[e.target.name] = e.target.value;
      }
      return element;
    });
    setValues(valueCpy);
  };

  const handleSubmitting = (e) => {
    // e.preventDefault();
    setErrors(validate(values));
  };

  return {
    handleChange,
    values,
    handleSubmitting,
    error,
    setValues,
    updateValue,
    OnhandleChange,
    addBannerSection,
    removeBannerSection,
    count,
  };
};

export default useContentBannerForm;
