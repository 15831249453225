import React from 'react';

// Pages
import BasePage from 'pages/BasePage';

import CreateUnderstand from 'app/container/CreateUnderstand';

const UnderstandUserPage = () => {
  return (
    <BasePage>
      <CreateUnderstand />
    </BasePage>
  );
};

export default UnderstandUserPage;
