import { useHistory } from "react-router"
import './index.scss'
import moment from 'moment';

function CustomTable({ data, tableHeader, handleDelete }) { 
     const history = useHistory()

     //handle navigation
     const handleNavigation = (item) =>{
          
          history.push({
               pathname: `/home-page-sections/edit/${item.id}`,
               state: {
                    ...item,
                  },
          });
     }

     const handlewidget_mapped = (items) => {
          let mapped_widget = []
          if(items){
               mapped_widget=  items.map(function(item) {
                    return item['name'];
                  }); 
          }
       
             return mapped_widget
     
     }
     return (
          <table>
               <tr>
                    {tableHeader.map((item,i) => {
                         return (
                              <th className='nowrap text-center'>{item.Header}</th>
                         )
                    })} 
               </tr>
               {data.map((items, indx) => (<tr key={indx}>
                    <td>{items.section_name}</td> 
                    {/* <td>{items.type?items.type:'-'}</td> */}
                    <td>{items.published_date?moment(items.published_date).format('DD-MM-YYYY'):'-'}</td> 
                    <td>{items.updated_on?moment(items.updated_on).format('DD-MM-YYYY'):'-'}</td>  
                    <td>{items.id}</td>
                    <td>{items.widget_mapped?handlewidget_mapped(items.widget_mapped).toString():'-' }</td>
                    <td>{items.status?items.status:'-'}</td>
                     
                    <td>
                         <div className="btn-group" role="group" aria-label="Basic outlined example">
                              
                              <button
                                   type="button"
                                   className="btn btn-outline-primary"
                                   onClick={() => handleNavigation(items) }
                              >
                                   Edit
                              </button>
                              <button
                                   type="button"
                                   className="btn btn-outline-primary"
                                   onClick={() => handleDelete(items) }
                              >
                                   Delete
                              </button>
                             
                         </div> 
                         
                    </td>
               </tr>))}
               {data.length ===0 &&  <tr><td colspan="10000">No data found</td> </tr>}
          </table>
     )
}

export default CustomTable