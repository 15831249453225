import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './index.scss'
import { addMedia } from 'app/api/feed';
import { useCallback, useMemo } from 'react';

const QuillTextEditor = ({ label, value, quillRef, Tooltiptext,setHTMLContent }) => {
    const Font = ReactQuill.Quill.import('formats/font');
    Font.whitelist = ['poppins', 'oswald']; // allow ONLY these fonts and the default
    ReactQuill.Quill.register(Font, true);

    const imageHandler = useCallback(() => {
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = async () => {
            const file = input.files[0];

            let responseObj = await addMedia(1, file);
            if (
                responseObj &&
                responseObj.response &&
                responseObj.response.code === 200
            ) {
                if (responseObj.data && responseObj.data.length > 0) {
                    const quillEditor = quillRef.current.getEditor();

                    const range = quillEditor.getSelection(true);
                    quillEditor.insertEmbed(range.index, "image", responseObj.data[0], "user");
                }
            }
        };
    }, []);

    const modules = useMemo(() => (
        {
            toolbar: {
                container: [
                    ['bold', 'italic', 'underline', 'strike'],
                    ['blockquote', 'code-block'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    [{ 'script': 'sub' }, { 'script': 'super' }],
                    [{ 'indent': '-1' }, { 'indent': '+1' }],
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                    [{ 'font': ['poppins', 'oswald'] }],
                    [{ 'align': [] }],
                    ['clean'],
                    ['link', 'image']
                ],
                handlers: {
                    image: imageHandler
                },

            },
        }
    ), [imageHandler]);

    const handleChange=(content,delta,source,editor)=>{
        setHTMLContent(editor.getHTML());
    };
    return (
        <div className='cipla-editor'>
            {label && <label className='tooltip-on-hover'>{label}</label>}
            <ReactQuill ref={quillRef} theme="snow" value={value} modules={modules} onChange={handleChange} />
            <div className='tooltip1'>{Tooltiptext}</div>
        </div>
    );
}

export default QuillTextEditor;