// Ajax
import * as dataAccess from 'utils/ajax';

// Constants
import { API_CONSTANTS } from 'constants/api-contants';

export const getLatestCategories = async (data, lang) => {
  let url = '';
  if (lang === 'en') {
    url = `${API_CONSTANTS.GET_LATEST_CATEGORIES}?source=${data.source}&publish=${data.publish}`;
  } else {
    url = `${API_CONSTANTS.GET_LATEST_CATEGORIES_VERSION2}?source=${data.source}&publish=${data.publish}&lang=${lang}`;
  }

  let responseObj = await dataAccess.get(url);
  return responseObj;
};

export const bannerPublishorUnpublish = async (banner_id, banner_status) => {
  let payload = {
    data: {
      banner_status,
    },
  };

  let url = `${API_CONSTANTS.GET_LATEST_BANNERS}/${banner_id}`;
  if (!url) {
    return {};
  }

  return await dataAccess.put(url, payload);
};

export const makeCategory = async (incomingData) => {
  let payload = {
    data: incomingData,
  };
  const response = await dataAccess.post(
    API_CONSTANTS.GET_LATEST_CATEGORIES,
    payload
  );

  return response;
};
export const updateCategory = async (incomingData, id, lang) => {
  let payload = {
    data: incomingData,
  };

  let url = `${API_CONSTANTS.GET_LATEST_CATEGORIES_VERSION2}/${id}?lang=${lang}`;

  const response = await dataAccess.put(url, payload);

  return response;
};
export const deleteCategoryAPI = async (id) => {
  let responseObj = await dataAccess.del(
    `${API_CONSTANTS.GET_LATEST_SUBCATEGORIES}/${id}`
  );
  return responseObj;
};

// SUb Ctegories

export const getSubCategoryById = async (id) => {
  let url = `${API_CONSTANTS.GET_LATEST_SUBCATEGORIES}/${id}`;
  let responseObj = await dataAccess.get(url);
  return responseObj;
};

export const searchMedicine = async (keyword, type, postal_code) => {
  let url = '';
  if (postal_code === '' || postal_code === undefined) {
    url = `${API_CONSTANTS.GET_MEDICINE_SEARCH}?query=${keyword}&type=${type}&autocomplete=1&include_suggestions=false`;
  } else {
    url = `${API_CONSTANTS.GET_MEDICINE_SEARCH}?query=${keyword}&postal_code=${postal_code}&type=${type}&autocomplete=1&include_suggestions=false`;
  }

  let responseObj = await dataAccess.get(url);

  return responseObj;
};

export const addSubCategory = async (incomingData) => {
  let payload = {
    data: incomingData,
  };

  return await dataAccess.upload(
    API_CONSTANTS.GET_LATEST_SUBCATEGORIES_PRODUCT_MAPPING,
    payload
  );
};
export const updateSubCategories = async (incomingData, id) => {
  let payload = {
    data: incomingData,
  };
  let url = `${API_CONSTANTS.GET_LATEST_SUBCATEGORIES}/${id}`;
  let responseObj = await dataAccess.update(url, payload);
  return responseObj;
};

export const getMedPaySubCategories = async (source, type) => {
  let url = `${API_CONSTANTS.GET_MEDPAY_CATEGORIES}?source=${source}&type=${type}`;
  let responseObj = await dataAccess.get(url);
  return responseObj;
};
export const getMedPaySubCategoriesforScearchScreen = async (source) => {
  let url = `${API_CONSTANTS.GET_LATEST_SUBCATEGORIES}/publish?source=${source}`;
  let responseObj = await dataAccess.get(url);
  return responseObj;
};

// Search Page

export const saveSearchAPI = async (incomingData) => {
  let url = `${API_CONSTANTS.GET_LATEST_SUBCATEGORIES}/publish`;
  let payload = {
    data: incomingData,
  };
  const response = await dataAccess.post(url, payload);
  return response;
};

export const getSearchPageTogglePublish = async (source) => {
  let url = `${API_CONSTANTS.GET_LATEST_SUBCATEGORIES}/search-page-toggle?product_type=${source}`;
  let responseObj = await dataAccess.get(url);
  return responseObj;
};

export const searchPageTogglePublish = async (incomingData) => {
  let url = `${API_CONSTANTS.GET_LATEST_SUBCATEGORIES}/search-page-toggle`;
  let payload = {
    data: incomingData,
  };
  const response = await dataAccess.post(url, payload);
  return response;
};


export const getMedicineDiscountData = async(data) => {
  let url = `${API_CONSTANTS.MEDICINE_DISCOUNT}`;
  let payload = {
      data: data,
    };
    const responseObj = await dataAccess.update( url,  payload  );
  return responseObj;

}

export const medicineDetail = async () => { 
  let url = `${API_CONSTANTS.MEDICINE_DISCOUNT}`;

  let responseObj = await dataAccess.get(url);
  return responseObj;
};

export const getAdReminder = async () => { 
  let url = `${API_CONSTANTS.AD_REMINDER_FOR_MEDICINE}`;

  let responseObj = await dataAccess.get(url);
  return responseObj;
};
export const updateAdReminder = async(data) => {
  let url = `${API_CONSTANTS.AD_REMINDER_FOR_MEDICINE}`;
  let payload = {
      data: data,
    };
    const responseObj = await dataAccess.update( url,  payload  );
    console.log(responseObj)
  return responseObj;

}