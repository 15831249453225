import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
// import './index.scss'
import moment from 'moment';
import Toast from 'app/utils/Toast';

function CustomTable({
  data,
  tableHeader,
  handleDelete, 
  handleUnpublish
}) {
  const history = useHistory();
  const handleNavigation = (items) => {
    history.push({
      pathname: `/landing-page/edit/${items.landing_page_id}`,
      state: {
        ...items,
      },
    });
  };

  return (
    <table>
      <tr>
        {tableHeader.map((item, i) => {
          return <th className='nowrap text-center'>{item.Header}</th>;
        })}
      </tr>
      {data.map((items, index) => (
        <tr key={index}>
          <td>{items.landing_page_id ? items.landing_page_id : ''}</td>
          <td>{items.page_name ? items.page_name : ''}</td>
          <td>{items.status ? items.status : ''}</td>
          <td>{items.last_edited_time ? moment(items?.last_edited_time).format('DD-MM-YYYY') : ''}</td>
          <td>{items.last_updated_by ? items.last_updated_by : ''}</td>
          <td>{items.deep_link ? items.deep_link : '-'}</td>

          <td>
            <div
              class='btn-group'
              role='group'
              aria-label='Basic outlined example'
            >
              <button
                type='button'
                className='btn btn-outline-primary'
                onClick={() => handleNavigation(items)}
              >
                Edit
              </button>
              <button type="button"
                    onClick={() => handleUnpublish(items) }
                    className="btn btn-outline-primary">{items.status ==="ACTIVE"? "Unpublish" : "Publish"}
              </button>
              <button
                type='button'
                onClick={() => handleDelete(items.landing_page_id)}
                className='btn btn-outline-primary ml-2'
              >
                Delete
              </button>
            </div>
          </td>
        </tr>
      ))}
      {data.length === 0 && (
        <tr>
          <td colspan='10000'>No data found</td>{' '}
        </tr>
      )}
    </table>
  );
}

export default CustomTable;
