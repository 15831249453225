import { API_CONSTANTS } from 'app/constants/api-contants';
import Toast from 'app/utils/Toast';
import * as dataAccess from 'utils/ajax';

export const getStoriesDataApi = async () => {
     let responseObj = await dataAccess.get(API_CONSTANTS.GET_STORIES);
     if (
          responseObj &&
          responseObj.response &&
          responseObj.response.code === 200
     ) {
          return responseObj.data;
     } else {
          return [];
     }
}

export const storyPublishOrUnpublishAPI = async (id, type) => {
     let responseObj;
     if (type == "unpublish") {
          responseObj = await dataAccess.get(`${API_CONSTANTS.UNPUBLISH_STORY}/${id}`);
     }
     else {
          responseObj = await dataAccess.get(`${API_CONSTANTS.PUBLISH_STORY}/${id}`);
     }
     if (
          responseObj &&
          responseObj.response &&
          responseObj.response.code === 200
     ) {
          return responseObj.data;
     } else {
          return [];
     }
}

export const discoverHomeWidgetPublishOrUnpublishAPI = async (id, type) => {
     let responseObj;
     if (type == "unpublish") {
          responseObj = await dataAccess.get(`${API_CONSTANTS.UNPUBLISH_DISCOVER_HOME_WIDGET}/${id}`);
     }
     else {
          responseObj = await dataAccess.get(`${API_CONSTANTS.PUBLISH_DISCOVER_HOME_WIDGET}/${id}`);
     }
     if (
          responseObj &&
          responseObj.response &&
          responseObj.response.code === 200
     ) {
          return responseObj;
     } else {
          return responseObj
     }
}

export async function deleteStoryApi(id) {
     let responseObj = await dataAccess.del(`${API_CONSTANTS.DELETE_STORY}/${id}`);
     if (
          responseObj &&
          responseObj.response &&
          responseObj.response.code === 200
     ) {
          Toast.success("Deleted")
     }
}

export async function deleteDiscoverHomeWidget(id) {
     let responseObj = await dataAccess.del(`${API_CONSTANTS.DELETE_DISCOVER_HOME_WIDGET}/${id}`);
     if (
          responseObj &&
          responseObj.response &&
          responseObj.response.code === 200
     ) {
          Toast.success("Deleted")
     }
}

// Groups
export const getGroupsDataApi = async () => {
     let responseObj = await dataAccess.get(API_CONSTANTS.GET_GROUP);
     if (
          responseObj &&
          responseObj.response &&
          responseObj.response.code === 200
     ) {
          return responseObj.data;
     } else {
          return [];
     }
}

export const getSelectGroupsDataApi = async () => {
     let responseObj = await dataAccess.get(API_CONSTANTS.SELECT_DISCOVER_GROUPS);
     if (
          responseObj &&
          responseObj.response &&
          responseObj.response.code === 200
     ) {
          return responseObj.data;
     } else {
          return [];
     }
}


export async function deleteGroupApi(id) {
     let responseObj = await dataAccess.del(`${API_CONSTANTS.DELETE_GROUP}/${id}`);
     if (
          responseObj &&
          responseObj.response &&
          responseObj.response.code === 200
     ) {
          Toast.success("Deleted")
     }
}

export const groupPublishOrUnpublishAPI = async (id, type) => {
     let responseObj;
     console.log(type)
     if (type == "Unpublished") {
          responseObj = await dataAccess.get(`${API_CONSTANTS.PUBLISH_GROUP}/${id}`);
     }
     else {
          responseObj = await dataAccess.get(`${API_CONSTANTS.UNPUBLISH_GROUP}/${id}`);
     }
     if (
          responseObj &&
          responseObj.response &&
          responseObj.response.code === 200
     ) {
          return responseObj.data;
     } else {
          return [];
     }
}

export async function reorderStories(submittedData) {
     let payload = {
          data: submittedData
     }
     let responseObj = await dataAccess.post(`${API_CONSTANTS.REORDER_STORY}`, payload);
     return responseObj;
}