import {
  deleteUploadedMedia,
  postShorts,
  updateShorts,
  uplaodMedia,
} from 'app/api/cms-content';
import { getContentFromId, getTagsApi } from 'app/api/createContentAPI';
import Calender from 'app/components/Calender';
import MultiSelectDropdown from 'app/components/MultiSelectDropdown';
import NewInput from 'app/components/NewInput';
import PageLoader from 'app/components/PageLoader';
import TextArea from 'app/components/Textarea';
import Button from 'app/components/button';
import Dropdown from 'app/shared/components/Dropdown';
import RadioButton from 'app/shared/components/RadioButton';
import DropdownOptions from 'app/utils/DropdownOptions';
import {
  checkIfUrl,
  imageSizeValidation,
  removeNullValues,
  urlParser,
} from 'app/utils/Parsers';
import Toast from 'app/utils/Toast';
import { calendarValidation } from 'app/utils/dateChecker';
import { CloseBlackIcon } from 'constants/image-constants';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import ValidationMessage from '../../../utils/ValidationMessage';
import SelectComp from '../select-tag';
import SlideDetails from './components/Slide-details';
import UploadedShorts from './components/uploaded-shorts';
import './index.scss';
import { customObjectOptions } from 'app/json-data/dropdown-constant';
const slidesCount = [
  { value: 1, label: 1 },
  { value: 2, label: 2 },
  { value: 3, label: 3 },
  { value: 4, label: 4 },
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
];
const radioMenu = [
  {
    label: 'No CTA',
    id: '0',
    value: null,
  },
  {
    label: 'Internal Redirect',
    id: '1',
    value: 'INTERNAL',
  },
  {
    label: 'Internal PWA',
    id: '2',
    value: 'INTERNAL_PWA',
  },
  {
    label: 'External Redirect(PWA)',
    id: '3',
    value: 'EXTERNAL_PWA',
  },
  {
    label: 'External Redirect(Outside App)',
    id: '4',
    value: 'EXTERNAL',
  },
];

const genderRadioMenu = [
  {
    label: 'True',
    id: '1',
    value: true,
  },
  {
    label: 'False',
    id: '2',
    value: false,
  },
];
// const customObjectOptions = [
//   {
//     label: 'PulmoRehab',
//     id: '1',
//     value: 'PulmoRehab',
//   },
//   {
//     label: 'Exercise',
//     id: '2',
//     value: 'Exercise',
//   },
// ];

function Shorts({ data, activeTab }) {
  const history = useHistory();

  const [loader, setLoader] = useState(false);
  const [isEditMode, setIsEditMode] = useState(data ? true : false);
  const [selectedItem, setSelectedItem] = useState('0');
  const [tags, setTags] = useState([]);
  const [activate, setActivate] = useState(true);
  const [validated, setValidated] = useState(true);
  const [internalUrl, setInternalURL] = useState([]);
  const [removedFiles, setRemovedFiles] = useState([]);
  const [thumbnailImage, setThumbnailImage] = useState(null);

  const [slideDetails, setSlideDetails] = useState({
    number_of_slides: '',
    slides: [],
  });
  const [inputObj, setInputObj] = useState({
    content_type: 'SHORTS',
    title: '',
    description: '',
    tags: [],
    thumbnail_image: null,
    shorts: slideDetails,
    url_type: 'INTERNAL',
    url: null,
    endless_scroll_cta: null,
    start_date: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    end_date: null,
    hex_code: '#323739',
    internal_url: '',
    internal_for: '',
    profile_data: false,
    custom_object: '',
    redirection_id: '',
  });

  useEffect(() => {
    getTagsHandler();
  }, []);

  // If edit
  const updateStates = async () => {
    if (data) {
      if (!data?.id || !activeTab) {
        return;
      }
      setThumbnailImage(null);
      setInputObj({
        content_type: 'SHORTS',
        title: '',
        description: '',
        tags: [],
        thumbnail_image: null,
        shorts: slideDetails,
        url_type: 'INTERNAL',
        url: null,
        endless_scroll_cta: null,
        start_date: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        end_date: null,
        hex_code: '#323739',
        internal_url: '',
        internal_for: '',
        profile_data: false,
        custom_object: '',
        redirection_id: '',
      });
      let response = await getContentFromId(data.id, activeTab);
      let requiredResponse = response.data[0];

      let stateValues = Object.keys(inputObj);

      let thumbnail_image = requiredResponse.thumbnail_image
        ? `${process.env.REACT_APP_S3_BUCKET_URL}/${requiredResponse.thumbnail_image}`
        : null;
      setThumbnailImage(thumbnail_image);

      let temporaryState = {};
      for (let index in stateValues) {
        temporaryState[stateValues[index]] =
          requiredResponse[stateValues[index]];
        if (requiredResponse.url_type === null) {
          setSelectedItem('0');
        } else if (
          requiredResponse.url_type &&
          requiredResponse.url_type === 'INTERNAL'
        ) {
          setSelectedItem('1');
        } else if (
          requiredResponse.url_type &&
          requiredResponse.url_type === 'INTERNAL_PWA'
        ) {
          setSelectedItem('2');
        } else if (
          requiredResponse.url_type &&
          requiredResponse.url_type === 'EXTERNAL_PWA'
        ) {
          setSelectedItem('3');
        } else {
          setSelectedItem('4');
        }
      }

      setInputObj(temporaryState);
      setSlideDetails(temporaryState.shorts);
    }
    let internalUrl = [];
    DropdownOptions.InternalUrl.map((item, i) => {
      if (
        item.value !== 'feed' &&
        item.value !== 'content_detail' &&
        item.value !== 'quiz' &&
        item.value !== 'vital_activity' &&
        item.value !== 'webinar_details' &&
        item.value !== 'individual_quiz'
      ) {
        internalUrl.push(item);
      }
    });
    setInternalURL(internalUrl);
  };

  useEffect(() => {
    updateStates();
  }, [activeTab]);
  async function getTagsHandler() {
    const tagsData = await getTagsApi();
    setTags(tagsData);
  }

  function inputChangeHandler(event) {
    const { name, value } = event.target;
    setInputObj({ ...inputObj, [name]: value });
  }

  const handleChange = (key, value) => {
    setInputObj({ ...inputObj, [key]: value });
  };

  const radioButtonSelected = (item) => {
    setSelectedItem(item.id);
  };
  const handleMediaUpload = async (e) => {
    let file = e.target.files[0];
    if (
      (file.type === 'image/jpeg' ||
        file.type === 'image/jpg' ||
        file.type === 'image/png') &&
      imageSizeValidation(e)
    ) {
      Toast.error(ValidationMessage.IMAGE_SIZE);
      return;
    }

    if (file.type.includes('image')) {
      const { filename } = await uplaodMedia('media', file, 'SHORTS');
      setInputObj({ ...inputObj, [e.target.name]: filename });
      let reader = new FileReader();
      reader.onloadend = function () {
        setThumbnailImage(reader.result);
      };
      reader.readAsDataURL(file);
      Toast.success(file?.name + ' uploaded');
    } else {
      Toast.error('Please select image file!');
    }
  };

  async function loopThroughFileNameHandler(files) {
    setRemovedFiles(files);
    // if (files && files.length > 0) {
    //      files.map(async (slide) => {
    //           await deleteUploadedMedia(slide.media);
    //      });
    // }
  }

  async function handleRemoveMedia() {
    console.log(removedFiles, 'sdjfksdjfs');
    // setRemovedFiles(files)
    if (removedFiles && removedFiles.length > 0) {
      removedFiles.map(async (slide) => {
        await deleteUploadedMedia(slide.media);
      });
    }
  }
  async function removeUploadedMediaHandler(fileName) {
    await deleteUploadedMedia(fileName);
  }

  const validateForm = () => {
    let val = calendarValidation(inputObj.start_date, inputObj.end_date);
    if (!val) {
      Toast.error('Start date is much larger than end date');
      return;
    }
    let flag = true;
    let validationArray = Object.keys(inputObj);
    let optionalFields = [
      'url',
      'endless_scroll_cta',
      'end_date',
      'description',
      'tags',
      'redirection_id',
    ];

    if (inputObj.url_type !== 'INTERNAL_PWA') {
      optionalFields = [
        ...optionalFields,
        'internal_for',
        'internal_url',
        'profile_data',
        'custom_object',
      ];
    }

    for (let index in validationArray) {
      if (
        inputObj[validationArray[index]] === '' &&
        !optionalFields.includes(validationArray[index])
      ) {
        flag = false;
        Toast.error('Please complete all fields');
        break;
      }
      // else if (validationArray[index] == "tags" && inputObj.tags?.length == 0) {
      //      flag = false
      //      Toast.error("Please complete all fields")
      //      break
      // }
      else if (
        validationArray[index] === 'start_date' &&
        inputObj.start_date == null
      ) {
        flag = false;
        Toast.error('Please complete all fields');
        break;
      }
    }

    return flag;
  };
  // Submit Handler
  async function submitHandler() {
    // inputObj.cta_type = selectedItem == "1" ? "INTERNAL" : "EXTERNAL";
    if (selectedItem === '0') {
      inputObj.url_type = null;
      inputObj.cta_type = null;
    } else if (selectedItem === '1') {
      inputObj.url_type = 'INTERNAL';
      inputObj.cta_type = 'INTERNAL';
    } else if (selectedItem === '2') {
      inputObj.url_type = 'INTERNAL_PWA';
      inputObj.cta_type = 'INTERNAL_PWA';
    } else if (selectedItem === '3') {
      inputObj.url_type = 'EXTERNAL_PWA';
      inputObj.cta_type = 'EXTERNAL_PWA';
    } else if (selectedItem === '4') {
      inputObj.url_type = 'EXTERNAL';
      inputObj.cta_type = 'EXTERNAL';
    }
    inputObj.shorts = slideDetails;
    if (activeTab === 'en') {
      let validated = validateForm();
      if (!validated) {
        return;
      }

      if (slideDetails.slides.length === '0') {
        Toast.error('All fields are required!');
        return;
      }

      inputObj.start_date = moment(inputObj?.start_date).format(
        'YYYY-MM-DD HH:mm:ss'
      );
      inputObj.end_date = inputObj.end_date
        ? moment(inputObj.end_date).format('YYYY-MM-DD HH:mm:ss')
        : '';
    }

    let responseObj;
    inputObj.url = checkIfUrl(inputObj?.url)
      ? urlParser(inputObj?.url)
      : inputObj?.url;
    if (inputObj?.tags && inputObj?.tags.length === 0) {
      inputObj.tags = null;
    }

    // if(removedFiles.length > 0){
    await handleRemoveMedia();
    // }
    if (activeTab !== 'en') {
      if (!inputObj?.thumbnail_image) {
        Toast.error('Please Add Thumbnail Image');
        return;
      }
      delete inputObj?.start_date;
      delete inputObj?.end_date;
      delete inputObj?.tags;
    }
    setLoader(true);
    if (data?.id) {
      inputObj.content_id = data.id;

      let temporaryStates = removeNullValues(inputObj);

      responseObj = await updateShorts(temporaryStates, activeTab, data.id);

      setLoader(false);
    } else {
      responseObj = await postShorts(inputObj);
      setLoader(false);
    }
    setLoader(false);
    if (
      responseObj &&
      responseObj.response &&
      responseObj.response.code === 200
    ) {
      if (data) {
        Toast.success('Updated Shorts');
        history.push({
          pathname: `/cms/content`,
          state: {
            goto: data?.comingFrom,
          },
        });
      } else {
        Toast.success('Shorts Added');
        history.push('/cms/content');
      }
    } else {
      Toast.error(
        responseObj.data.error
          ? responseObj.data.error
          : 'Something went wrong!!'
      );
    }
  }

  return (
    <>
      {loader && <PageLoader pageLoading={loader} />}
      <div className='cms-shorts__container'>
        <div className='shorts-con__separator separator'>
          <p>Shorts - Add Details</p>
        </div>

        <div className='shorts-con__inputs'>
          <NewInput
            label='Title'
            onChange={inputChangeHandler}
            name={'title'}
            value={inputObj?.title ? inputObj?.title : ''}
          />
          <TextArea
            label={'Short Description'}
            onChange={inputChangeHandler}
            name={'description'}
            value={inputObj?.description ? inputObj?.description : ''}
          />
          {activeTab === 'en' && (
            <MultiSelectDropdown
              label='Add Tags'
              name='tags'
              value={inputObj?.tags}
              options={tags}
              onChange={inputChangeHandler}
              tag_type={true}
            />
          )}

          <div className='shorts-con__thumbnail-box d-flex'>
            <label>Upload Thumbnail Image</label>
            <input
              type='file'
              id='thumbnailFile'
              style={{ display: 'none' }}
              name='thumbnail_image'
              onChange={(e) => {
                handleMediaUpload(e);
              }}
            />
            <Button
              onClick={() => {
                const image = document.getElementById('thumbnailFile');
                image.click();
              }}
              type='button'
              isDisabled={inputObj?.thumbnail_image !== null}
              name={
                inputObj?.thumbnail_image !== null
                  ? 'Uploaded'
                  : 'Upload Thumbnail Image'
              }
            />
            {console.log(inputObj, 'inputObj')}
            {inputObj.thumbnail_image && (
              <p
                className='cancel'
                onClick={() => {
                  removeUploadedMediaHandler(inputObj.thumbnail_image);
                  setInputObj({ ...inputObj, thumbnail_image: null });
                  setThumbnailImage(null);
                }}
              >
                Cancel Thumbnail
              </p>
            )}
          </div>
          <div className='d-flex'>
            {thumbnailImage && (
              <div className='attachment_img_wrapper'>
                <img
                  src={thumbnailImage}
                  alt='sample'
                  className='feed__attachment'
                />
                <div className='attachment_img_wrapper_close'>
                  <img
                    src={CloseBlackIcon}
                    alt='Remove'
                    onClick={() => {
                      removeUploadedMediaHandler(inputObj.thumbnail_image);
                      setInputObj({ ...inputObj, thumbnail_image: null });
                      setThumbnailImage(null);
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          <div className='colorpicker'>
            <label>Background Fill</label>
            <input
              type='color'
              name='hex_code'
              value={inputObj.hex_code ? inputObj.hex_code : ''}
              onChange={inputChangeHandler}
            />
            <p>{inputObj.hex_code}</p>
          </div>
          {isEditMode ? (
            <div className='addedShorts-container'>
              <UploadedShorts ativeSlides={slideDetails} />
              <Button
                onClick={() => {
                  loopThroughFileNameHandler(slideDetails.slides);
                  setIsEditMode(false);
                }}
              >
                Delete All and Start new Shorts
              </Button>
            </div>
          ) : (
            <>
              <div className='shorts-con__main-box'>
                <div className='shorts-con__select-box'>
                  <label className='tooltip-on-hover'>Number of Slides</label>
                  <SelectComp
                    options={slidesCount}
                    selected={true}
                    selectedTitle={'select slides'}
                    name={'shorts'}
                    onChangeHandler={inputChangeHandler}
                  />
                </div>
              </div>
              <div className='shorts-con__slide-details'>
                <label className='tooltip-on-hover'>Slide Details</label>
                <SlideDetails
                  slideNo={inputObj.shorts}
                  onPassFiles={(files) => {
                    setSlideDetails({
                      number_of_slides: inputObj.shorts,
                      slides: files,
                    });
                  }}
                />
              </div>
            </>
          )}
          <div className='shorts-con__endless-scroll'>
            <label className='head-label'>Endless ScrollCTA Type</label>
            {/* <CTAType
                              cta_type = {state.cta_type}
                              handleChange={(key, data) => {
                                   console.log(key, data, 'key, data')
                                   setState({ ...state, [key]: data }) }}
                              internalUrl={internalUrl}
                              url={state.url}
                              handleTextChange={handleTextChange}
                              /> */}
            {radioMenu.map((item, key) => (
              <RadioButton
                id={item.id}
                label={item.label}
                isChecked={selectedItem === item.id ? true : false}
                value={item.value}
                onSelect={() => {
                  radioButtonSelected(item);
                }}
                name={item.label}
              />
            ))}
            <div className='my-3'>
              {selectedItem === '1' && (
                <div className='dropdown-area'>
                  <label className='tooltip-on-hover pr-3'>Internal APP</label>
                  <SelectComp
                    options={internalUrl}
                    selected={true}
                    selectedTitle={inputObj.url}
                    name={'url'}
                    onChangeHandler={inputChangeHandler}
                  />
                </div>
              )}

              {(selectedItem === '3' || selectedItem === '4') && (
                <div className='shorts-con__link-inputs'>
                  <NewInput
                    value={inputObj.url ? inputObj.url : ''}
                    placeholder={'Link'}
                    onChange={inputChangeHandler}
                    name={'url'}
                    label={'External Redirect Link'}
                  />
                </div>
              )}
              {(inputObj.url === 'zoom_live_session' ||
                inputObj === 'infinite_scroll' ||
                inputObj.url === 'breathing_exercise'
              ) && (
                <div className='input-container'>
                  <NewInput
                    label='Redirection Id'
                    name='redirection_id'
                    onChange={inputChangeHandler}
                    value={
                      inputObj.redirection_id ? inputObj.redirection_id : ''
                    }
                  />
                </div>
              )}
              {selectedItem === '3' ||
              selectedItem === '1' ||
              selectedItem === '4' ? (
                <div className='input-container'>
                  <NewInput
                    value={
                      inputObj.endless_scroll_cta
                        ? inputObj.endless_scroll_cta
                        : ''
                    }
                    placeholder={'Watch More'}
                    onChange={inputChangeHandler}
                    name={'endless_scroll_cta'}
                    label={'Endless Scroll CTA Text'}
                  />
                </div>
              ) : null}
            </div>

            {selectedItem === '2' && (
              <>
                <NewInput
                  label='For'
                  name={'internal_for'}
                  value={inputObj ? inputObj.internal_for : ''.internal_for}
                  placeholder='Enter for'
                  className='form-block'
                  type='text'
                  onChange={inputChangeHandler}
                />
                <NewInput
                  label='URL'
                  name={'internal_url'}
                  value={inputObj.internal_url ? inputObj.internal_url : ''}
                  placeholder='Enter URL'
                  className='form-block'
                  type='text'
                  onChange={inputChangeHandler}
                />
                <div className='radio_container'>
                  <div className='content-selector customradio'>
                    <h6>Profile Data</h6>
                    {genderRadioMenu.map((item, key) => (
                      <RadioButton
                        key={key}
                        label={item.label}
                        isChecked={
                          inputObj.profile_data === item.value ? true : false
                        }
                        value={item.value}
                        onSelect={() => {
                          handleChange('profile_data', item.value);
                        }}
                        name={item.label}
                      />
                    ))}
                  </div>
                </div>
                <Dropdown
                  label='Custom Object'
                  name='custom_object'
                  options={customObjectOptions}
                  placeholder='Select custom object'
                  value={inputObj.custom_object ? inputObj.custom_object : null}
                  handleChange={inputChangeHandler}
                  // handleChange={onChange}
                />
              </>
            )}
            <div className=''>
              {activeTab === 'en' && (
                <Calender
                  onStartDateSelected={(e) => {
                    setInputObj({ ...inputObj, start_date: e });
                  }}
                  onEndDateSelected={(e) => {
                    setInputObj({ ...inputObj, end_date: e });
                  }}
                  startDate={inputObj?.start_date && inputObj?.start_date}
                  endDate={inputObj?.end_date && inputObj?.end_date}
                  validated={validated}
                  activate={activate}
                />
              )}
            </div>
          </div>
        </div>
        <div className='shorts-con__actions'>
          <Button onClick={submitHandler}>
            {data ? 'Edit' : 'Create'} Shorts
          </Button>
          <label
            onClick={() => {
              history.push('/cms/content');
            }}
          >
            Cancel
          </label>
        </div>
      </div>
    </>
  );
}

export default Shorts;
