import CoursesOrderingPageContainer from "app/container/CoursesOrderingPageContainer";
import BasePage from "app/pages/BasePage";

const CoursesPageOrdering = () => {
    return (
        <BasePage>
            <CoursesOrderingPageContainer />
        </BasePage>
    );
}

export default CoursesPageOrdering;