/* eslint-disable no-useless-escape */

export default function validateInfo(values) {
  let errors = {};

  if (!values.Title) {
    errors.Title = 'Titlee Required';
  } else {
    errors.Title = ' ';
  }

  if (!values.Description) {
    errors.Description = 'Description Required';
  } else {
    errors.Description = ' ';
  }

  // const urlRegex =
  //   /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

  const mail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  if (!values.url) {
    errors.mail = 'please enter mail';
  } else if (mail.test(values.mail)) {
    errors.mail = 'Its Not Mail Id';
  } else {
    errors.mail = '';
  }

  const urlRegex =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

  if (!values.url) {
    errors.url = 'Please enter url';
  } else if (urlRegex.test(values.url)) {
    errors.url = 'Is Not Valid url';
  } else {
    errors.url = '';
  }

  return errors;
}
