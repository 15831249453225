import { useEffect, useState } from 'react';

import {
  deleteCategoryAPI,
  getLatestCategories,
  makeCategory,
  updateCategory,
} from 'app/api/latest/Categories/categoriesAPI';
import Parsers from 'app/utils/Parsers';
import Message from 'utils/Message';
import Toast from 'utils/Toast';
import { APP_CONSTANTS } from 'app/constants/app-constants';
import { confirmAlert } from 'react-confirm-alert';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const useCategoriesSections = (validate) => {
  const [activeTab, setActiveTab] = useState({
    id: '1',
    name: 'English',
    short_name: 'en',
    display_order: 1,
    status: 'ACTIVE',
    label: '+ English',
    value: 'English',
  });
  const [activeShow, setActiveShow] = useState(false);

  useEffect(() => {
    if (activeTab.name === 'English') {
      setActiveShow(true);
    } else {
      setActiveShow(false);
    }
  }, [activeTab]);

  const handleTabChange = (val) => {
    setActiveTab(val);

    // Perform any other actions on tab change
  };
  const [pageLoading, setPageLoading] = useState(false);
  const [count, setCount] = useState(1);
  const [listData, setListData] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isAddButtonDisabled, setisAddButtonDisabled] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const [bannerType, setBannerType] = useState({
    linkName: '',
    heading: '',
    source: '',
    publish: null,
  });
  const [pageRefresh, setpageRefresh] = useState(false);
  useEffect(() => {
    if (location?.pathname === APP_CONSTANTS.MEDICINECATEGORIES) {
      let payload = {
        linkName: APP_CONSTANTS.MEDICINECATEGORIES,
        heading: 'Medicine Delivery',
        source: 'MEDICINE',
        publish: 1,
      };
      setBannerType(payload);
      loadCategorieslist(payload);
    }
    if (location?.pathname === APP_CONSTANTS.DIAGONOSTICCATEGORIES) {
      let payload = {
        linkName: APP_CONSTANTS.DIAGONOSTICCATEGORIES,
        heading: 'Diagnostic',
        source: 'DIAGNOSTIC',
        publish: 1,
      };
      setBannerType(payload);
      loadCategorieslist(payload);
    }
  }, [location?.pathname, pageRefresh, activeTab?.name]);

  const loadCategorieslist = async (payload) => {
    setPageLoading(true);
    let lang = activeTab?.short_name ? activeTab?.short_name : '';
    if (!lang) return;
    let response = await getLatestCategories(payload, lang);
    setPageLoading(false);
    let temp = [];
    if (response && response.response && response.response.code === 200) {
      temp =
        response?.data &&
        response?.data?.map((item, index) => {
          let incompleteStatus = false;
          item?.categories &&
            item?.categories.forEach((categoryitem, index) => {
              if (categoryitem?.mapped_products_count < 5)
                incompleteStatus = true;
            });

          return {
            categoriesIndexOrer: index + 1,
            category_id: item?.id,
            id: `categories_${index}`,
            primary_heading: item?.primary_heading ? item?.primary_heading : '',
            secondary_heading: item?.secondary_heading
              ? item?.secondary_heading
              : '',
            display_order: item?.display_order,
            subcategories: item?.categories ? item?.categories : [],
            subcategoriesLengthExceed:
              item?.categories && item?.categories.length >= 6 ? true : false,
            incompleteStatus: incompleteStatus,
            updated_by: item?.updated_by,
            updated_on: Parsers.ParseTableDateTime(item?.updated_on),
            toggle: item?.publish === 'ACTIVE' ? true : false,
            publish: item?.publish,
            createsubcategory: false,
          };
        });

      let newtemp = temp.sort(
        (a, b) => a.categoriesIndexOrer - b.categoriesIndexOrer
      );

      setListData(newtemp);

      if (newtemp.length >= 6) setisAddButtonDisabled(true);
      setCount(newtemp.length);
      setPageLoading(false);
    } else {
      setPageLoading(false);
      Toast.error(
        response.data.error ? response.data.error : Message.Error.COMMON_MESSAGE
      );
    }
  };

  const addServiceSection = () => {
    const temp = listData && listData?.length > 0 ? [...listData] : [];
    temp.push({
      categoriesIndexOrer:
        listData && listData?.length ? listData && listData?.length : null,
      category_id: '',
      id: Date.now(), // Basically its a index
      primary_heading: '',
      secondary_heading: '',
      display_order: '',
      subcategories: [],
      subcategoriesLengthExceed: false,
      updated_on: '',
      updated_by: '',
      publish: 'INACTIVE',
      toggle: false,
      createsubcategory: false,
    });
    setCount(count + 1);
    setListData(temp);
    if (count + 1 >= 6) {
      setisAddButtonDisabled(true);
    }
  };
  const removeServiceSection = () => {};

  const handleChange = (e) => {
    const { name, value, id } = e.target;
    const valueCpy = [...listData];

    valueCpy.map((element, index) => {
      if (`${element.id}` === `${id}`) {
        element[name] = value;
      }

      return element;
    });
    setListData([...valueCpy]);
  };

  const handleResourseToggle = async (itemindex, category_id) => {
    let newData = [...listData];

    if (newData && newData.length > 0) {
      newData = newData.map((item, index) => {
        if (index === itemindex) {
          item.toggle = !item.toggle;
          item.publish = item.toggle ? 'ACTIVE' : 'INACTIVE';
        }
        return item;
      });
      setListData(newData);
    }
  };
  const publishChangesActiveAndToggleOn = async (itemindex, category_id) => {
    let newData = [...listData];
    let newsavedata = {};

    if (newData && newData.length > 0) {
      newData = newData.map((item, index) => {
        if (index === itemindex) {
          newsavedata = {
            publish: item.publish,
          };
        }
        return item;
      });
      setListData(newData);
    }

    if (!category_id) {
      return;
    }
    setPageLoading(true);
    const response = await updateCategory(
      newsavedata,
      category_id,
      activeTab.short_name
    );
    setPageLoading(false);
    if (response?.response?.code === 200 || response?.response?.code === 201) {
      Toast.success(response?.data?.message);
    } else {
      Toast.error(
        response?.data?.error
          ? response?.data?.error
          : Message?.Error?.COMMON_MESSAGE
      );
    }
    setpageRefresh(!pageRefresh);
  };
  const saveCategory = async (data, index) => {
    if (data?.category_id === '') {
      let postsavedata = {
        category_data: {
          secondary_heading: data.secondary_heading,
          primary_heading: data.primary_heading,
          source: bannerType.source,
          page: bannerType.source,
        },
      };
      setPageLoading(true);
      const response = await makeCategory(postsavedata);
      setPageLoading(false);
      if (
        response?.response?.code === 201 ||
        response?.response?.code === 200
      ) {
        Toast.success(
          response?.data?.message
            ? response?.data?.message
            : 'Category Added Successfully'
        );

        const valueCpy = [...listData];
        valueCpy.map((element, newindex) => {
          if (newindex === index) {
            element.category_id = response?.data[0]?.id;
          }
          return element;
        });
        setListData([...valueCpy]);
        setpageRefresh(!pageRefresh);
        return;
      } else {
        Toast.error(
          response?.data?.error
            ? response?.data?.error
            : Message?.Error?.COMMON_MESSAGE
        );
      }
    } else {
      let newsavedata = {
        category_data: {
          secondary_heading: data.secondary_heading,
          primary_heading: data.primary_heading,
          source: bannerType.source,
          publish: data.publish,
          page: bannerType.source,
        },
      };
      setPageLoading(true);
      let id = data?.category_id ? data?.category_id : '';
      let response = {};
      console.log('id', id);
      if (id) {
        if (activeTab?.short_name === 'en') {
          response = await updateCategory(
            newsavedata,
            id,
            activeTab.short_name
          );
        } else {
          let newsavedataLocalise = {
            category_data: {
              primary_heading: newsavedata?.category_data?.primary_heading,
              secondary_heading: newsavedata?.category_data?.secondary_heading,
            },
          };

          response = await updateCategory(
            newsavedataLocalise,
            id,
            activeTab.short_name
          );
        }
      }

      setPageLoading(false);
      if (
        response?.response?.code === 201 ||
        response?.response?.code === 200
      ) {
        Toast.success(response?.data?.message);
        setpageRefresh(!pageRefresh);
      } else {
        Toast.error(
          response?.data?.error
            ? response?.data?.error
            : Message?.Error?.COMMON_MESSAGE
        );
      }
    }
  };
  const deleteSubCategory = async (subCategory_id) => {
    if (!subCategory_id) {
      return;
    }
    setPageLoading(true);
    const response = await deleteCategoryAPI(subCategory_id);
    setPageLoading(false);
    if (
      response &&
      response?.response &&
      (response?.response?.code === 201 ||
        response?.response?.code === 200 ||
        response?.response?.code === 204)
    ) {
      Toast.success(
        response?.data?.message
          ? response?.data?.message
          : 'Sub-Category deleted successfully'
      );
    } else {
      Toast.error(
        response?.data?.error
          ? response?.data?.error
          : Message?.Error?.COMMON_MESSAGE
      );
    }
    setpageRefresh(!pageRefresh);
  };

  const publishCategories = (itemindex, myid, categoryid) => {
    let newData = [...listData];
    let subcategoryCompleteStatus = false;

    if (newData && newData.length > 0) {
      newData = newData.forEach((item, index) => {
        if (index === itemindex) {
          if (item?.subcategories.length === 0 || item?.incompleteStatus) {
            subcategoryCompleteStatus = true;
          }
        }
      });
    }
    if (subcategoryCompleteStatus) {
      Toast.error(`Category must be in Complete State `);
      return;
    }

    publishChangesActiveAndToggleOn(itemindex, categoryid);
  };

  const openUp = (itemindex, categoryid) => {
    confirmAlert({
      title: '',
      message: 'Are you sure you want to Publish/Unpublish Categories?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            // setToggled(!toggled);
            handleResourseToggle(itemindex, categoryid);
          },
        },
        {
          label: 'No',
        },
      ],
    });
  };
  const navigateNewMedPayCategories = (category_id) => {
    history.push({
      pathname: APP_CONSTANTS.MEDPAYSUBCATEGORIES,
      state: {
        category_id,
        bannertype: bannerType,
      },
    });
  };
  const navigateNewCategories = (category_id) => {
    history.push({
      pathname: APP_CONSTANTS.SELFSUBCATEGORIES,
      state: {
        category_id,
        bannertype: bannerType,
      },
    });
  };
  const viewSubCategories = (category_id, sub_id) => {
    history.push({
      pathname: APP_CONSTANTS.SELFSUBCATEGORIES,
      state: {
        category_id,
        sub_id,
        bannertype: bannerType,
      },
    });
  };
  const selectsubCategoryAddingType = async (index) => {
    let tempData = [];
    await listData.forEach((val, i) => {
      if (index === i) {
        let temp = val;
        val['createsubcategory'] = !val.createsubcategory;
        tempData.push(temp);
      } else {
        tempData.push(val);
      }
    });
    setListData(tempData);
  };
  console.log('listData', listData);

  return {
    pageLoading,
    handleChange,
    addServiceSection,
    removeServiceSection,
    count,
    listData,
    setListData,
    handleClose,
    handleShow,
    show,
    // handleResourseToggle,
    isAddButtonDisabled,
    openUp,
    bannerType,
    saveCategory,
    deleteSubCategory,
    viewSubCategories,
    navigateNewCategories,
    navigateNewMedPayCategories,
    selectsubCategoryAddingType,
    publishCategories,
    activeTab,
    activeShow,
    handleTabChange,
  };
};

export default useCategoriesSections;
