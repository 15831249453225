import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import './index.scss';

function ContentTable(props) {
  const history = useHistory();
  const [pages, setPages] = useState();
  const [data, setData] = useState([]);
  // const [searchVal, setSearchVal] = useState('');
  const isSearchActive = false;
  // let data

  // let data = useMemo(() => {
  //      if (isSearchActive) {
  //           return props.totalData;
  //      } else {
  //           return props.content;
  //      }
  // }, [isSearchActive, props.totalData, props.content])

  useEffect(() => {
    setPages(props.currentPage);
    setData(props.content);
    setPageSize(props.dataPerPage);
  }, [props.currentPage, props.content, props.dataPerPage]);
  const columns = useMemo(
    () => [
      {
        Header: 'Coins PF ID',
        accessor: 'coins_id',
        Cell: ({ row }) => (
          <p className='table-cells-center'>{row.original.coins_id || '-'}</p>
        ),
      },

      {
        Header: 'Product Family',
        accessor: 'product_family_name',
        Cell: ({ row }) => (
          <p className='table-cells-center'>
            {row.original.product_family_name || '-'}
          </p>
        ),
      },
      {
        Header: 'Discount',
        accessor: 'discount',
        Cell: ({ row }) => (
          <p className='table-cells-center'>{row.original.discount || '-'}</p>
        ),
      },
      {
        Header: 'Higher limit',
        accessor: 'higher_limit',
        Cell: ({ row }) => (
          <p className='table-cells-center'>
            {row.original.higher_limit || '-'}
          </p>
        ),
      },
      {
        Header: 'Maximum Discount Amount',
        accessor: 'maximum_discount_amount',
        Cell: ({ row }) => (
          <p className='table-cells-center'>
            {row.original.maximum_discount_amount || '-'}
          </p>
        ),
      },
      {
        Header: 'Published Date',
        accessor: 'published_date',
        Cell: ({ row }) => (
          <p className='table-cells-center'>
            {row.original.published_date || '-'}
          </p>
        ),
      },
      {
        Header: 'Updated Date',
        accessor: 'updated_on_date',
        Cell: ({ row }) => (
          <p className='table-cells-center'>
            {row.original.updated_on_date || '-'}
          </p>
        ),
      },

      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ row }) => (
          <p className='table-cells-center'>
            {' '}
            {row.original.status === 'ACTIVE' ? 'ON' : 'OFF'}
          </p>
        ),
      },

      {
        Header: 'Components',
        Cell: ({ row }) => (
          <button
            type='button'
            className='text-primary text-decoration-underline cursor-pointer border-0 bg-transparent'
            onClick={() => {
              history.push({
                pathname: `/coins/create-coins/edit/${row.original.id}`,
                state: {
                  ...row.original,
                },
              });
            }}
          >
            Manage Coins
          </button>
        ),
      },
    ],
    [pages]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  // async function searchHandler(search) {
  //   setSearchVal(search);
  //   if (search) {
  //     setIsSearchActive(true);
  //     setGlobalFilter(search);
  //   } else {
  //     setIsSearchActive(false);
  //   }
  //   return;
  // }

  const arrowShow = ({ isSorted, isSortedDesc }) => {
    if (isSorted) {
      if (isSortedDesc) {
        return <span className='my-table__arrow'>🠻</span>;
      } else {
        return <span className='my-table__arrow'>🠹</span>;
      }
    } else {
      return <span className='my-table__arrow'>⬍</span>;
    }
  };

  return (
    <div className='table'>
      {/* <Search
               value={searchVal}
               placeholder="Column Search"
               onChange={searchHandler} 
          /> */}
      {props.content.length > 0 && (
        <table {...getTableProps()} className='my-table'>
          <thead>
            {headerGroups.map((headerGroup) => {
              return (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    return (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render('Header')}
                        <>{arrowShow(column)}</>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);

              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      {isSearchActive ? (
        <div className='react-table-pagination'>
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className='page-item page-link'
          >
            {'<'}
          </button>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className='page-item page-link '
          >
            {'>'}
          </button>
        </div>
      ) : (
        <>{props.children}</>
      )}
    </div>
  );
}

export default ContentTable;
