import RatingCreate from 'app/container/Rating/RatingCreate';
import BasePage from 'app/pages/BasePage';

const CreateRating = () => {
  return (
    <BasePage>
      <RatingCreate />
    </BasePage>
  );
};

export default CreateRating;
