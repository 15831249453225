/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';

// Components
import NewInput from 'components/NewInput';
import Attachment from 'components/Attachment';
import Button from 'components/button';
import useContentForm from 'components/useContentForm';
import validate from 'components/ValidateInfo';
import PageLoader from 'components/PageLoader';
import HeaderWithBackButton from 'components/HeaderWithBackButton';
import NumberInput from 'components/UI/NumberInput';
import QuillTextEditor from 'app/shared/components/QuillTextEditor';

// API
import { addOrUpdateCelebrity } from 'app/api/celebrity';

// Constants
import { APP_CONSTANTS } from 'constants/app-constants';
// Utils
import Toast from 'utils/Toast';
import Message from 'utils/Message';
import ValidationMessage from 'utils/ValidationMessage';

const CreateCelebrityVideo = ({ onPreview, ...props }) => {
  const history = useHistory();
  const location = useLocation();
  const editorRef = useRef(null);
  const [selFile, setSelectedFile] = useState(null);
  const [videoThumbnail, setVideoThumbnail] = useState({});
  const [celebrityId, setCelebrityId] = useState();
  const [pageLoading, setPageLoading] = useState(false);
  const [htmlContent, setHTMLContent] = useState('');

  const { handleChange, values, error, updateValue } = useContentForm(validate);

  useEffect(() => {
    let CelebrityVideo = location.state;
    console.log(CelebrityVideo, 'CelebrityVideo');
    if (CelebrityVideo && CelebrityVideo.celebrity_video_id) {
      setCelebrityId(CelebrityVideo.celebrity_video_id);
      setHTMLContent(CelebrityVideo.content);
      setSelectedFile(CelebrityVideo.attachment_path);
      let newData = {
        title: CelebrityVideo.title,
        attachment: CelebrityVideo.attachment_path,
        content: CelebrityVideo.content,
        // startDate: breathefreeData.start_date,
        // endDate: breathefreeData.end_date,
        videoThumbnail: CelebrityVideo.video_thumbnail,
        sequenceOrder: CelebrityVideo.sorting_order,
        celebrity_video_id: CelebrityVideo.celebrity_video_id,
      };

      updateValue(newData);
    }
  }, []);

  const populatePayload = () => {
    /* Populate Data */
    let {
      title,
      homepage,
      homePageSection,
      sequenceOrder,
      celebrity_video_id,
      attachment,
    } = values;

    // Validations Start
    if (!title) {
      Toast.error(ValidationMessage.EMPTY_TITLE);
      return;
    }

    if (!sequenceOrder) {
      Toast.error(ValidationMessage.EMPTY_SEQUENCE_ORDER);
      return;
    }

    if (!selFile) {
      Toast.error(ValidationMessage.EMPTY_ATTACHMENT);
      return;
    }

    if (homepage === true && !homePageSection) {
      Toast.error(
        'Please select the Home page section as you wanted to post the feed on Home screen.'
      );
      return;
    }

    let defaultVal = '';

    let data = {
      title: title ? title : '',
      content: defaultVal,
      celebrity_video_id: celebrity_video_id,
    };

    if (selFile) {
      data.attachment = selFile;
    } else if (selFile === null) {
      /*  || selFile === undefined */
      // data.attachment = {};
      data.remove_attachment = 0;
    }

    let content;
    if (editorRef.current) {
      content = editorRef.current.value;
      if (content) {
        data.content = content;
      }
    }

    if (sequenceOrder) {
      data.sorting_order = sequenceOrder;
    }

    if (videoThumbnail) {
      data.video_thumbnail = videoThumbnail;
    }

    // if (educator_video_id) {
    //   data.educator_video_id = educator_video_id;
    // }

    return data;
  };

  const callAddOrUpdateAPI = async () => {
    let data = populatePayload();
    if (!data) return;
    // debugger;
    setPageLoading(true);
    const responseObj = await addOrUpdateCelebrity(data);
    setPageLoading(false);
    if (
      responseObj &&
      responseObj.response &&
      responseObj.response.code === 200
    ) {
      // LocalStorage.remove(LocalSessionKey.FEED_ID);
      if (celebrityId) {
        Toast.success(Message.Celebrity.UPDATED_SUCCESS);
      } else {
        Toast.success(Message.Celebrity.CREATED_SUCCESS);
      }
      history.goBack();
    } else {
      Toast.error(Message.Celebrity.CREATE_FAILURE);
    }
  };

  const handleCreateCelebrity = (e) => {
    callAddOrUpdateAPI();
  };

  const onFileSelected = (file) => {
    setSelectedFile(file);
    if (file === null || file === undefined) {
      setVideoThumbnail(null);
    }
  };

  const onVideoThumbnail = (thumbnail) => {
    setVideoThumbnail(thumbnail);
  };

  return (
    <div className='blogsForm'>
      {console.log('selFileselFileselFile', selFile)}
      <div className='blogsForm_content'>
        <HeaderWithBackButton
          title='Add/edit a Video'
          goTo={APP_CONSTANTS.CELEBRITY}
        />

        <NewInput
          label='Title'
          id='title'
          type='text'
          placeholder='Ex: Celebrity Video'
          name='title'
          value={values.title}
          onChange={handleChange}
          errorText={error.title}
        />

        <NumberInput
          label='Sequence order'
          id='sequenceOrder'
          placeholder='Enter sequence order'
          name='sequenceOrder'
          value={values.sequenceOrder}
          onChange={handleChange}
        />

        <QuillTextEditor
          label='RichText Editor'
          Tooltiptext='DO NOT COPY AND PASTE FROM WORD DOCUMENT OR OTHER EDITOR. Directly write here or copy from notepad only.'
          value={htmlContent}
          quillRef={editorRef}
          setHTMLContent={setHTMLContent}
        />

        <Attachment
          onFileSelected={onFileSelected}
          value={values.attachment}
          onVideoThumbnail={onVideoThumbnail}
          videoThumbnail={values.videoThumbnail}
          className='attachment__selimage'
        />

        <Button
          onClick={handleCreateCelebrity}
          type='submit'
          name='Post a Video'
        />
      </div>
      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </div>
  );
};

export default CreateCelebrityVideo;
