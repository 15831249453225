import { useHistory } from "react-router"
import './index.scss'
import moment from 'moment';

function CustomTable({ data, tableHeader, handleDelete,handleUnpublish, currentPage }) { 
     const history = useHistory()
     const handleNavigation = (items) =>{
          
          history.push({
               pathname: `/rating/edit/${items.id}`,
               state: {
                    ...items,
               },
          });
     }
     return (
          <table>
               <tr>
                    {tableHeader.map((item,i) => {
                         return (
                              <th key={item.Header} className='nowrap text-center'>{item.Header}</th>
                         )
                    })} 
               </tr>
               {data.map((items, index) => (<tr key={index}>
                    <td>{ (currentPage-1)*10 + index + 1}</td>  
                    <td>{items.module_name?items.module_name.toString(): '-'}</td>
                    <td>{items.action_event?items.action_event.toString():'-'}</td>
                    <td>{items?.android ? 'Yes': 'No'}</td>
                    <td>{items?.ios ? 'Yes': 'No'}</td>
                    <td>{items.created_on?moment(items.created_on).format('DD-MM-YYYY'):"-"}</td>
                    <td>{items.updated_on?moment(items.updated_on).format('DD-MM-YYYY'):"-"}</td>
                    <td>{items.status.toString() === 'ACTIVE' ? 'ON': 'OFF'}</td>
                    <td>
                    <div className="btn-group" role="group" aria-label="Basic outlined example">
                              <button
                                   type="button"
                                   className="btn btn-outline-primary"
                                   onClick={() => handleNavigation(items) }
                              >
                                   Edit
                              </button>
                              <button type="button"
                                   onClick={() => handleUnpublish(items) }
                                   className="btn btn-outline-primary">{items.status ==="ACTIVE"? "Unpublish" : "Publish"}
                              </button>
                               <button type="button"
                                   onClick={() => handleDelete(items) }
                                   className="btn btn-outline-primary">Delete
                              </button>
                               
                         </div> 
                    </td>
                   
               </tr>))}
               {data.length ===0 &&  <tr><td colspan="10000">No data found</td> </tr>}
          </table>
     )
}

export default CustomTable