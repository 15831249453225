import Parsers from 'app/utils/Parsers';
import { API_CONSTANTS } from 'constants/api-contants';
import * as dataAccess from 'utils/ajax';

export const getRefferals = async (start) => {
  let payload = {
    data: {
      page: start ? start : 1,
      limit: 10,
    },
  };
  let responseObj = await dataAccess.get(API_CONSTANTS.GET_REFFERAL, payload);

  return responseObj;
};

export const addImageFile = async (incomingData) => {
  var formData = Parsers.ConvertObjectAsFormData(incomingData);
  let payload = {
    data: formData,
  };

  let responseObj = await dataAccess.upload(
    API_CONSTANTS.REFFREL_IMAGE_UPLOAD,
    payload
  );

  return responseObj;
};

export const addRefferals = async (incomingData) => {
  let payload = {
    data: {
      ...incomingData,
    },
  };

  return await dataAccess.post(API_CONSTANTS.ADD_REFFERAL, payload);
};

export const getReffere = async () => {
  let responseObj = await dataAccess.get(API_CONSTANTS.GET_REFFERE);
  return responseObj;
};

export const addReffere = async (incomingData) => {
  let payload = {
    data: {
      ...incomingData,
    },
  };

  return await dataAccess.post(API_CONSTANTS.ADD_REFFERE, payload);
};
