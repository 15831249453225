import { useEffect, useState } from 'react';

// Components
import NewInput from 'components/NewInput';
import NumberInput from 'components/UI/NumberInput';
// Shared Components
import Dropdown from 'shared/components/Dropdown';
import RadioButton from 'shared/components/RadioButton';

// Constants
import { URLType } from 'constants/app-constants';

// Utils

import './index.scss';
import VersionDropDown from 'app/components/VersionDropDown';
import { customObjectOptions } from 'app/json-data/dropdown-constant';
import Select from "react-select";
const CTAWidgetComponent = ({
  urlType,
  value,
  onChange,
  id,
  OnhandleChange,
  redirection_id,
  ctaValue,
  externalUrl,
  contentid,
  swappingOrder,
  isHide,
  index,
  urlCopy,
  redirection_idURL,
  DropdownOptions,
  internal_for = '',
  internal_url = '',
  profile_data = false,
  custom_object = '',
  detail_cta_text = '',
  showPWA = false,
  version = false,
  android_version = '',
  criteria = '',
  ios_version = '',
  ios_criteria = '',
  androidversionLists=[],
  iosversionLists=[],
  headding=true,
  closeSection=false
}) => {
  const [type, setType] = useState('');
  const [externalUrlVal, setExternalUrl] = useState();
  const [drop, setDrop] = useState('');
   
  // const [isOpen, setOpen] = useState(true);
  const genderRadioMenu = [
    {
      label: "True",
      id: "1",
      value: true
    },
    {
      label: "False",
      id: "2",
      value: false
    },

  ]
  const VersionRange = [
    {
      value: 'GREATER_THAN',
      label: 'Greater Than',
    },
    {
      value: 'LESS_THAN',
      label: 'Lesser Than',
    },
    {
      value: 'EQUALS',
      label: 'Equals',
    },
  ];
  // const customObjectOptions = [
  //   {
  //     label: "PulmoRehab",
  //     id: "1",
  //     value: 'PulmoRehab'
  //   },
  //   {
  //     label: "Exercise",
  //     id: "2",
  //     value: 'Exercise'
  //   },

  // ]

  useEffect(() => {
    (value === 'feed' ||
      value === 'webinar_details' ||
      value === 'quiz' ||
      value === 'breathing_exercise' ||
      value === 'vital_activity' || value === 'infinite_scroll' ||
      value === 'zoom_live_session') &&
      setDrop(true);

  }, [value]);

  useEffect(() => {
    if (urlType) {
      setType(urlType);
    }
  }, [urlType]);

  useEffect(() => {
    if (externalUrl) {
      setExternalUrl(externalUrl);
    }
  }, [externalUrl]);

  const isRadioButtonSelected = (data) => {
    if (type && type.toUpperCase() === data) {
      return true;
    }
    return false;
  };

  const handleRadioButtonClick = (e) => {
    onChange &&
      onChange({
        target: {
          id: e.target.id,
          name: 'urlType',
          value: e.target.value,
        },
      });
  };

  const handleInterURLChange = (e) => {
    const newValue = e ? e.value : '';
    onChange({
      target: {
        id: id,
        name: 'url',
        value: newValue,
      },
    });
    if (
      newValue === 'feed' ||
      newValue === 'webinar_details' ||
      newValue === 'quiz' ||
      newValue === 'breathing_exercise' ||
      newValue === 'vital_activity' ||
      newValue === 'infinite_scroll' ||
      newValue === 'group_of_collection' ||
      newValue === 'individual_quiz' ||
      newValue === 'zoom_live_session'
    ) {
      setDrop(newValue);
    } else {
      setDrop('');
      // redirection_id('');
    }
   
   
    if (newValue === urlCopy) {
      onChange({
        target: {
          id: e.target.id,
          name: 'redirection_id',
          value: redirection_idURL,
        },
      });
    } else {
      onChange({
        target: {
          id: e.target.id,
          name: 'redirection_id',
          value: null,
        },
      });
    }
    onChange(e);
  };

  const handleCustoObjectChange = (e) => {
    onChange &&
      onChange({
        target: {
          id: e.target.id,
          name: e.target.name,
          value: e.target.value,
        },
      });
  };


  const handleURLChange = (e) => {
    setExternalUrl(e.target.value);
    onChange &&
      onChange({
        target: {
          id: e.target.id,
          name: 'externalUrl',
          value: e.target.value,
        },
      });
  };

  const handleInternalePWAChange = (e) => {
    onChange &&
      onChange({
        target: {
          id: e.target.id,
          name: e.target.name,
          value: e.target.value,
        },
      });
  };

  const radioButtonSelected = (item, id) => {
    onChange &&
      onChange({
        target: {
          id: id,
          name: 'profile_data',
          value: item.value,
        },
      });

  }

  let isExternalChecked = isRadioButtonSelected(URLType.EXTERNAL);
  let isExternalPWAChecked = isRadioButtonSelected(URLType.EXTERNAL_PWA);
  let isInternalChecked = isRadioButtonSelected(URLType.INTERNAL);
  let isInternalPWAChecked = isRadioButtonSelected(URLType.INTERNAL_PWA);
  let isNoCTA = isRadioButtonSelected(URLType.NO);
  return (
    <div className='row'>
      <div className='col-md-12'>
        <div className='radio' id={contentid}>
          <div className='radio_container'>
            <div className='row'>
              <div className='col-auto mb-3'>
                <RadioButton
                  id={id ? id : 'article_internal'}
                  onSelect={handleRadioButtonClick}
                  label='Add Internal URL'
                  isChecked={isInternalChecked}
                  value={URLType.INTERNAL}
                  name={`radio_${index}`}
                />
              </div>
              {showPWA &&
                <div className='col-auto mb-3'>
                  <RadioButton
                    id={id ? id : 'article_internal'}
                    onSelect={handleRadioButtonClick}
                    label='Add Internal PWA'
                    isChecked={isInternalPWAChecked}
                    value={URLType.INTERNAL_PWA}
                    name={`radio_${index}`}
                  />
                </div>
              }
              <div className='col-auto mb-3'>
                <RadioButton
                  id={id ? id : 'article_external'}
                  label='Add External URL'
                  isChecked={isExternalChecked}
                  value={URLType.EXTERNAL}
                  onSelect={handleRadioButtonClick}
                  name={`radio_${index}`}
                />
              </div>
              {showPWA &&
                <div className='col-auto mb-3'>
                  <RadioButton
                    id={id ? id : 'article_external'}
                    label='Add External Redirect(PWA)'
                    isChecked={isExternalPWAChecked}
                    value={URLType.EXTERNAL_PWA}
                    onSelect={handleRadioButtonClick}
                    name={`radio_${index}`}
                  />
                </div>
              }
              <div className='col-auto mb-3'>
                <RadioButton
                  id={id ? id : 'article_no'}
                  label='No CTA'
                  isChecked={isNoCTA}
                  value={URLType.NO}
                  onSelect={handleRadioButtonClick}
                  name={`radio_${index}`}
                />
              </div>
            </div>
          </div>

          {!isHide && type !== URLType.NO && (
            <NewInput
              label='CTA Caption'
              id={id ? id : 'ctaTitle'}
              type='text'
              placeholder='Ex: Go to Medicine Reminder'
              name='ctaTitle'
              value={ctaValue}
              onChange={onChange}
            />
          )}
          {type !== URLType.NO && (
            <div className='radio_url'>
              {(type === URLType.EXTERNAL || type === URLType.EXTERNAL_PWA) && (
                <div>
                  <NewInput
                    label='CTA URL'
                    id={id}
                    name={'externalUrl'}
                    value={externalUrlVal}
                    placeholder='Ex: https://www.cipla.com'
                    className='form-block'
                    type='url'
                    onChange={handleURLChange}
                    Tooltiptext='This is a screen where the user will be taken on tap'
                  />
                </div>
              )}
              {type === URLType.INTERNAL && (
                <>
                  <Select
            options={DropdownOptions}
            value={DropdownOptions.find(option => option.value === value)}
            onChange={handleInterURLChange}
            isDisabled={false}
            placeholder="Search and select internal URL..."
            isClearable
            isSearchable
          />

                  {(value === 'zoom_live_session' ||
                    value === 'infinite_scroll' ||
                    value === 'group_of_collection' || value === 'landing_page') && showPWA && (

                      <>
                        <NewInput
                          label='Redirect Id'
                          id={id}
                          placeholder='enter value '
                          name='redirection_id'
                          value={redirection_id}
                          type={'number'}
                          onChange={(e) => {
                            onChange &&
                              onChange({
                                target: {
                                  id: id,
                                  name: e.target.name,
                                  value: e.target.value,
                                },
                              });
                          }}
                        />
                        <NewInput
                          id={id}
                          name="detail_cta_text"
                          label={"Detailed CTA text"}
                          value={detail_cta_text}
                          onChange={(e) => {
                            onChange &&
                              onChange({
                                target: {
                                  id: id,
                                  name: 'detail_cta_text',
                                  value: e.target.value,
                                },
                              });
                          }}
                        />
                      </>
                    )}

                </>

              )}

             
            </div>
          )}
          {type === URLType.INTERNAL_PWA && (
            <>
              <NewInput
                id={id}
                label='For'
                name={'internal_for'}
                value={internal_for}
                placeholder='Enter for'
                className='form-block'
                type='text'
                onChange={handleInternalePWAChange}
              />
              <NewInput
                id={id}
                label='URL'
                name={'internal_url'}
                value={internal_url}
                placeholder='Enter URL'
                className='form-block'
                type='text'
                onChange={handleInternalePWAChange}
              />
              <div className='radio_container'>
                <div className="content-selector customradio">
                  <h6>Profile Data </h6>
                  {genderRadioMenu.map((item, key) => {
                    return (
                      <div key={id + '_' + key}>
                        <RadioButton
                          key={id + '_' + profile_data}
                          label={item.label}
                          isChecked={profile_data == item.value}
                          value={item.value}
                          onSelect={() => { radioButtonSelected(item, id) }}
                          name={`radio_${index + '_' + key}`}
                        />
                      </div>
                    )

                  })}
                  {/* {genderRadioMenu.map((item, key) => (
                          <RadioButton
                              key={key}
                              label={item.label}
                              isChecked={profile_data === item.value ? true : false}
                              value={item.value}
                              onSelect={() => { radioButtonSelected(item,id) }}
                              name={item.label}
                          />
                      ))} */}
                </div>
              </div>
              <Dropdown
                id={id}
                label='Custom Object'
                name='custom_object'
                options={customObjectOptions}
                placeholder='Select custom object'
                value={custom_object}
                handleChange={handleCustoObjectChange}
              // handleChange={onChange}
              />

            </>
          )}
          {headding && 
          <div>
            <NewInput
              label='Heading'
              id={id}
              name={'externalUrl'}
              value={ctaValue}
              placeholder='Give the heading of banner here'
              className='form-block'
              type='url'
              onChange={(e) => {
                onChange &&
                  onChange({
                    target: {
                      id: e.target.id,
                      name: 'title',
                      value: e.target.value,
                    },
                  });
              }}
            />
          </div>
          }
          {version &&
            <div>
              <VersionDropDown
                versionLists={androidversionLists}
                iosLists={iosversionLists}
                VersionRange={VersionRange}
                handleTextChange={(e) => {
                  onChange &&
                    onChange({
                      target: {
                        id: id,
                        name: e.target.name,
                        value: e.target.value,
                      },
                    });
                }}
                android_version={android_version}
                criteria={criteria}
                ios_version={ios_version}
                ios_criteria={ios_criteria}
              />
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default CTAWidgetComponent;
