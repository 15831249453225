import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import './index.scss';

const SmallBannerCard = ({
  smallBannerCardkey,
  index,
  children,
  isNew,
  toggle = null,
  showToggle = false,
  handleResourseToggle,
  cardName = null,
}) => {
  return (
    <div key={index} className='SmallBanner-container'>
      <div className='SmallBanner-container__section-one'>
        <div className='card__header'>
          <p className='SmallBanner-container__section'>
            {cardName ? `${cardName} ${index + 1}` : `Banner ${index + 1}`}
          </p>
          <span>
            {showToggle && (
              <Toggle
                defaultChecked={toggle}
                icons={false}
                onChange={() => handleResourseToggle(smallBannerCardkey, index)}
              />
            )}
          </span>
        </div>
        <div className='SmallBanner-container__SmallBanner'>
          <div className='SmallBanner-container__Image-Container'>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmallBannerCard;
