// Ajax
import * as dataAccess from 'utils/ajax';
import ApiParser from 'utils/ApiParser';

// Constants
import { API_CONSTANTS } from 'constants/api-contants';

import Parsers from 'utils/Parsers';

export const getServiceOrder = async (lang) => {
  let url = `${API_CONSTANTS.GET_SERVICES_VERSION2}?lang=${lang}`;
  let responseObj = await dataAccess.get(url);
  return ApiParser.parseServiceListing(responseObj);
  // return responseObj;
};

export const updateServiceOrder = async (incomingData) => {
  let payload = {
    data: incomingData,
  };

  return await dataAccess.post(API_CONSTANTS.POST_SERVICES_ORDER, payload);
};

export const servicePublishorUnpublish = async (item) => {
  const { id, is_active } = item;
  let data = {
    id,
    is_active,
  };
  var formData = Parsers.ConvertObjectAsFormData(data);
  let payload = {
    data: formData,
  };

  let url = `${API_CONSTANTS.GET_SERVICES_VERSION2}/${id}`;
  if (!url) {
    return {};
  }

  return await dataAccess.update(url, payload);
};

export const addServices = async (incomingData) => {
  let payloads = [];
  if (incomingData && incomingData.length > 0) {
    incomingData.forEach((item) => {
      var formData = Parsers.ConvertObjectAsFormData(item);

      payloads.push({
        data: formData,
      });
    });
  }

  if (payloads.length < 1) {
    return;
  }
  return await dataAccess.uploadAll(API_CONSTANTS.GET_SERVICES, payloads);
};

export const updateServices = async (short_name, incomingData) => {
  let payloads = [];
  if (incomingData && incomingData.length > 0) {
    incomingData.forEach((item) => {
      var formData = Parsers.ConvertObjectAsFormData(item);

      payloads.push({
        data: formData,
        item,
      });
    });
  }

  if (payloads.length < 1) {
    return;
  }

  return await dataAccess.updateAllBanner(
    API_CONSTANTS.GET_SERVICES_VERSION2,
    payloads,
    short_name
  );
};

export const deleteServices = async (id) => { 
  let responseObj = await dataAccess.del(
    `${API_CONSTANTS.GET_SERVICES_VERSION2}/${id}`
  );
  if (
    responseObj &&
    responseObj.response &&
    responseObj.response.code === 200
  ) {
    return responseObj.data;
  } else {
    return [];
  }
};
