import * as dataAccess from 'utils/ajax';
import ApiParser from 'utils/ApiParser';
import Parsers from 'utils/Parsers';
import { API_CONSTANTS } from 'constants/api-contants';
import { ServiceType } from 'constants/app-constants';

export const getChallenges = async () => {
  let urls = [
    `${API_CONSTANTS.GET_CHALLENGES}`,
    `${API_CONSTANTS.GET_CONTENT_CHALLENGES}`,
  ];

  let responseObj = await dataAccess.getAll(urls);
  return ApiParser.parseChallengesListing(responseObj);
};

export const addOrUpdateChallenges = async (incomingData) => {
  let data = {
    publish: 1,
    ...incomingData,
  };

  var formData = Parsers.ConvertObjectAsFormData(data);

  let payload = {
    data: formData,
  };

  let url = '';

  if (incomingData.service_type) {
    if (incomingData.service_type.toUpperCase() === ServiceType.PATIENT_DAIRY) {
      url = API_CONSTANTS.UPDATE_GAMIFICATION;
    } else if (
      incomingData.service_type.toUpperCase() === ServiceType.CONTENT
    ) {
      url = API_CONSTANTS.UPDATE_CONTENT_GAMIFICATION;
    }
  }

  if (!url) {
    return {};
  }

  return await dataAccess.upload(url, payload);
};

export const deleteChallenges = async (challengeId) => {
  if (!challengeId) {
    return;
  }

  let payload = {
    data: {
      challenge_id: challengeId,
    },
  };
  return await dataAccess.del(API_CONSTANTS.DELETE_GAMIFICATION, payload);
};
