// Pages
import BasePage from 'pages/BasePage';

import CreateCelebrityVideo from 'app/container/CreateCelebrityVideo';

const CelebrityUserPage = () => {
  return (
    <BasePage>
      <CreateCelebrityVideo />
    </BasePage>
  );
};

export default CelebrityUserPage;
