import React from 'react';

// Pages
import BasePage from 'pages/BasePage'; 
import DynamicWidgetList from 'app/container/DynamicWidgets';
import { CustomerOrderHeader } from 'constants/table-header-constants';
import CustomerOrdersContainer from 'app/container/CustomerOrdersContainer';

const CustomerOrders = () => {
  return (
    <BasePage>
      <CustomerOrdersContainer tableHeader={CustomerOrderHeader} />
    </BasePage>
  );
};

export default CustomerOrders;
