import React from 'react';

// Pages
import BasePage from '../BasePage';
import VersionList from 'app/container/VersionList';

const VersionControl = () => {
  return (
    <BasePage>
      <VersionList />
    </BasePage>
  );
};

export default VersionControl;
