import RadioButton from "app/shared/components/RadioButton"; 

import { useParams } from "react-router-dom";
import Button from "app/components/button";
import "../index.scss"
import { useState, useEffect } from "react"; 
import { useHistory, useLocation } from "react-router"; 
import NewInput from "app/components/NewInput"; 
import Toast from "app/utils/Toast";
import PageLoader from "app/components/PageLoader"; 
import  {imageSizeValidation}  from 'utils/Parsers' 
import ValidationMessage from 'utils/ValidationMessage'; 
import { createExercises, getExercisesDetails, updateExercises } from 'app/api/exercises'
import { CloseBlackIcon } from 'constants/image-constants';
import CTAType from 'app/components/CTAType'
import DropdownOptions from "app/utils/DropdownOptions";


const CreateExercisesContainer = () => {
    const location = useLocation()
    const params = useParams();
    const [loader, setLoader] = useState(false) 
    const [videoDur, setVideoDur] = useState(0) 
    const [selVideo, setSelVideo] = useState(null);
    const [thumbnailImage, setThumbnailImage] = useState(null);
    const [state, setState] = useState({
        title: "", 
        video_thumbnail: "", 
        endless_scroll_media:"",  
        asthma_order:'',
        copd_order:'',
        ar_order:'',
        ild_order:'',
        other_order:'',
        url: "",
        cta_type: "INTERNAL",
        internal_for: "",
        internal_url: "",
        profile_data: false,
        custom_object: "",
        redirection_id: "",
        detail_cta_text: "",
    })

    const [disabledKeys, setDisabled] = useState({
        internalApp: true,
        externalRedirect: true,
        cta: true,
    })

    const [internalUrl, setInternalURL] = useState([]);

    let history = useHistory() 

    useEffect(() => {
        if (state.cta_type == "INTERNAL") {
            setDisabled({ externalRedirect: true, internalApp: false, cta: false })
        }
        else if (state.cta_type == "EXTERNAL") {
            setDisabled({ cta: true, internalApp: true, externalRedirect: false })
        }
        else if (state.cta_type == "EXTERNAL_PWA") {
            setDisabled({ internalApp: true, externalRedirect: false, cta: false })
        }

    }, [state.cta_type])
        
    useEffect(() => { 
        if(params.id){
            getExercisesDetailsById(params.id)
        }
        let internalUrl = []
        DropdownOptions.InternalUrl.map((item, i) => {
            if (item.value !== 'feed' && item.value !== 'content_detail' && item.value !== 'quiz' && item.value !== 'vital_activity' && item.value !== 'webinar_details' && item.value !== 'individual_quiz') {
                internalUrl.push(item)
            }
        })

        setInternalURL(internalUrl)
    }, []);

    const getExercisesDetailsById = async(id) => {
        setLoader(true)
        let responseObj = await getExercisesDetails(id) 
        setState({ 
            title:responseObj.title,
            video_thumbnail:responseObj.video_thumbnail,
            endless_scroll_media:responseObj.endless_scroll_media,
            asthma_order:responseObj.asthma_order,
            copd_order:responseObj.copd_order,
            ar_order:responseObj.ar_order,
            ild_order:responseObj.ild_order,
            other_order:responseObj.other_order,
            url: responseObj?.url? responseObj?.url: '',
            cta_type:responseObj?.url_type? responseObj?.url_type: 'INTERNAL',
            internal_for: responseObj?.internal_for? responseObj?.internal_for: '',
            internal_url:responseObj?.internal_url? responseObj?.internal_url: '',
            profile_data: responseObj?.profile_data? responseObj?.profile_data: false,
            custom_object: responseObj?.custom_object? responseObj?.custom_object: '',
            redirection_id: responseObj?.redirection_id? responseObj?.redirection_id: '',
            detail_cta_text: responseObj?.detail_cta_text? responseObj?.detail_cta_text: '',
        })
        if(responseObj.video_thumbnail){
            let video_thumbnail = `${process.env.REACT_APP_S3_BUCKET_URL}/${responseObj.video_thumbnail}`;
            setThumbnailImage(video_thumbnail)
        }
        if(responseObj.endless_scroll_media){
            let endless_scroll_media = `${process.env.REACT_APP_S3_BUCKET_URL}/${responseObj.endless_scroll_media}`;
            setSelVideo(endless_scroll_media)
        }
        setVideoDur(responseObj.video_duration)
        setLoader(false)


    }
    const handleTextChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    const handleMediaUpload = (e) => {
        let file = e.target.files[0] 
        getSecondsOfVideo(file)
        if ((file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') && imageSizeValidation(e)) {
            Toast.error(ValidationMessage.IMAGE_SIZE)
            return
        }else{
            if(e.target.name === 'endless_scroll_media'){
                if(file.type === 'video/mp4'){
                    let reader = new FileReader();
                    reader.onloadend = function () {
                        setSelVideo(reader.result);
                    };
                    reader.readAsDataURL(file);
                }
               
            }else if(e.target.name === 'video_thumbnail'){
                let reader = new FileReader();
                reader.onloadend = function () {
                  setThumbnailImage(reader.result);
                };
                reader.readAsDataURL(file);
            }

            setState({ ...state, [e.target.name]: file })
           
            Toast.success(file?.name + " uploaded")
        }
        
    }

    function getSecondsOfVideo(videoFile) {
        let file = videoFile
        if (file.type?.split("/")?.[0] == "video") {
             const src = URL.createObjectURL(file)
             var video = document.createElement('video');
             video.src = src
            
             video.addEventListener("loadedmetadata", function () { 
                  setVideoDur(parseInt(parseFloat(video.duration)))
             });
        }
   }

   const handleSubmit = async () => {
   
    let payload = {
        ...state,
        video_duration:videoDur
    } 
    setLoader(true)
    let responseObj
    if(params.id){
        payload.id=params.id
        responseObj = await updateExercises(payload)
    }else{
        responseObj = await createExercises(payload)
    }
     
    if (
        responseObj &&
        responseObj.response &&
        responseObj.response.code === 200
    ) { 
        Toast.success(responseObj.response.alert[0].message);
        history.push("/breathing-exercise") 

    } else {
        setLoader(false)
        Toast.error(responseObj.data && responseObj.data.msg?responseObj.data.msg:responseObj.response.alert[0].message);
    }

   }
 

    return (
        <div className="container">
            <h1 className="section-header__title">Breathing Exercises</h1>
            
            <div className="separator">
                <p>Create Video Exercise</p>
            </div>
            <div className="userInput">
                <NewInput
                    label="Action Title"
                    name={"title"}
                    value={state.title}
                    onChange={handleTextChange}
                />
                <div className="mt-4">

                    {
                        state.endless_scroll_media && <p className="cancel" onClick={(e) => {
                            setSelVideo(null); 
                            setState({ ...state, endless_scroll_media: "" })
                        }}>Cancel Upload</p>
                    }
                   
                </div>
               
                <div className="upload-buttons "> 
                    <input type="file" id="videoFile"
                        accept="video/*"
                        style={{ display: "none" }}
                        name='endless_scroll_media'
                        onChange={(e) => {
                            handleMediaUpload(e)
                        }}
                    />
                    
                    <div className="upload-label">
                        <label>Upload Video</label>
                        <Button
                            onClick={() => {
                                const image = document.getElementById("videoFile")
                                image.click()
                            }}
                            type='button'
                            isDisabled={state.endless_scroll_media}
                            name={state.endless_scroll_media != "" ? "Uploaded" : "Upload Video"}
                        />
                    </div>
                    <div className="d-flex">
                        {selVideo && (
                            <div className='attachment_img_wrapper'>
                                    <video className='attachment__selimage' controls>
                                    <source src={selVideo} type='video/mp4' />
                                </video>
                            <div className='attachment_img_wrapper_close'>
                                <img src={CloseBlackIcon} alt='Remove' onClick={(e) => {
                                    setSelVideo(null); 
                                    setState({ ...state, endless_scroll_media: "" })
                                }}/>
                            </div>
                        </div>
                        )}
                    </div>
                </div>
               
                <div className="mt-3">
                {
                        state.video_thumbnail && <p className="cancel" onClick={(e) => {
                            setThumbnailImage(null); 
                            setState({ ...state, video_thumbnail: "" })
                        }}>Cancel Thumbnail</p>
                    }
                </div>
               
                <div className="upload-buttons "> 
                   
                    <input type="file" id="thumbnailFile"
                        accept="image/*"
                        style={{ display: "none" }}
                        name='video_thumbnail'
                        onChange={(e) => {
                            handleMediaUpload(e)
                        }}
                    />
                    <div className="upload-label">
                        <label>Upload Thumbnail</label>
                        <Button
                        onClick={() => {
                            const image = document.getElementById("thumbnailFile")
                            image.click()
                        }}
                        isDisabled={state.video_thumbnail}
                        name={state.video_thumbnail != "" ? "Uploaded" : "Upload Thumbnail Image"}
                        type='button'

                    />

                    </div>
                    <div className="d-flex">
                        {thumbnailImage && (
                            <div className='attachment_img_wrapper'>
                                 <img src={thumbnailImage} alt='sample' className='feed__attachment' />
                                <div className='attachment_img_wrapper_close'>
                                    <img src={CloseBlackIcon} alt='Remove' onClick={(e) => {
                                        setThumbnailImage(null); 
                                        setState({ ...state, video_thumbnail: "" })
                                    }}/>
                                </div>
                            </div>
                        )}
                    </div>

                </div>
                <NewInput
                    label="Total Time"
                    name={"totalTime"}
                    isDisabled={true}
                    value={videoDur?videoDur + ' secs' : ''}
                    onChange={handleTextChange}
                />
                <div className="userFormArea">
                    <label>Detailed CTA Type</label>

                    <CTAType
                        cta_type={state.cta_type}
                        handleChange={(key, data) => {
                            if(key === 'cta_type' && data !== 'INTERNAL'){
                                setState({ ...state, 'detail_cta_text': '', [key]: data })
                            }else if(key === 'url' && (data === 'zoom_live_session' || data === 'infinite_scroll' || data === 'group_of_collection')){
                               

                                setState({ ...state, [key]: data })
                            }else{ 
                                setState({ ...state, [key]: data, 'redirection_id': '' })
                            }
                        }}
                        internalUrl={DropdownOptions.InternalUrl}
                        url={state.url}
                        handleTextChange={handleTextChange}
                        internal_for={state.internal_for}
                        internal_url={state.internal_url}
                        profile_data={state.profile_data}
                        custom_object={state.custom_object}
                        redirection_id={state.redirection_id}
                    />
                </div>
                {state.cta_type === 'INTERNAL' &&
                    <div className="row">
                        <div className="col-sm-6">
                            <NewInput
                                name="detail_cta_text"
                                label={"Detailed CTA text"}
                                value={state.detail_cta_text}
                                onChange={handleTextChange}
                                disabled={
                                    disabledKeys.cta
                                }
                            />
                        </div>
                    </div>
                }
                <NewInput
                    label={'Asthama Order'}
                    name={'asthma_order'}
                    value={state.asthma_order}
                    onChange={handleTextChange}
                />
                 <NewInput
                    label={'COPD Order'}
                    name={'copd_order'}
                    value={state.copd_order}
                    onChange={handleTextChange}
                />
                <NewInput
                    label={'ILD Order'}
                    name={'ild_order'}
                    value={state.ild_order}
                    onChange={handleTextChange}
                />
                 <NewInput
                    label={'AR Order'}
                    name={'ar_order'}
                    value={state.ar_order}
                    onChange={handleTextChange}
                />
                <NewInput
                    label={'Other Order'}
                    name={'other_order'}
                    value={state.other_order}
                    onChange={handleTextChange}
                />
                <div className="userFormArea">
                    <Button
                        name={"Save"}
                        onClick={handleSubmit}
                        isDisabled={!state.video_thumbnail || !state.endless_scroll_media || !state.title}
                    />
                    <label style={{ paddingLeft: "10px", cursor: "pointer" }}

                        onClick={  () => { history.goBack() }  }
                    >Cancel</label>
                </div>
            </div>
            {loader && <PageLoader pageLoading={loader} />}
        </div>


    );
}

export default CreateExercisesContainer