import * as dataAccess from "utils/ajax";
import ApiParser from "utils/ApiParser";
import Parsers from "utils/Parsers";
import { LocalSessionKey } from "constants/app-constants";
import { LocalStorage } from "utils/storage";
import { API_CONSTANTS } from "constants/api-contants";

export const getHabit = async (start) => {
  let responseObj = await dataAccess.get(API_CONSTANTS.GET_HABITS);
  responseObj.data.map((item, index) => {
    responseObj.data[index].id = item.habit_id;
  });
  return ApiParser.parseHabitListing(responseObj);
};

export const getHabitTip = async (habitId) => {
  let payload = {
    data: {
      habit_id: habitId,
    },
  };
  let responseObj = await dataAccess.post(API_CONSTANTS.GET_HABITSTIP, payload);
  return ApiParser.parseHabitTipListing(responseObj);
};

export const addOrUpdateHabit = async (incomingData) => {
  let data = {
    update_habit: incomingData?.habit_id ? 1 : 0,
    publish: 1,
    ...incomingData,
  };

  var formData = Parsers.ConvertObjectAsFormData(data);

  let payload = {
    data: formData,
  };

  return await dataAccess.upload(API_CONSTANTS.UPDATE_HABIT, payload);
};

// debugger;
export const addOrUpdateHabitTip = async (incomingData) => {
  let payload = {
    data: {
      ...incomingData,
      // update_tip: incomingData.habit_id ? 1 : 0,
      // tip_data: [...incomingData],
    },
  };

  return await dataAccess.post(API_CONSTANTS.UPDATE_HABITTIP, payload);
};

export const addOrUpdateHabitTips = async (incomingData) => {
  let payload = {
    data: {
      ...incomingData,
      // update_tip: incomingData.habit_id ? 1 : 0,
      // tip_data: [...incomingData],
    },
  };

  return await dataAccess.post(API_CONSTANTS.UPDATE_HABITTIP, payload);
};

export const publishOrUnpublishHabit = async (habit_id, status) => {
  if (!habit_id || !status) {
    return;
  }

  let data = {
    publish: status.toLowerCase() === "inactive" ? 1 : 0,
    update_habit: 1,
    habit_id: habit_id,
  };

  var formData = Parsers.ConvertObjectAsFormData(data);

  let payload = {
    data: formData,
  };
  return await dataAccess.upload(API_CONSTANTS.UPDATE_HABIT, payload);
};

export const addOrUpdateHabitDragDrop = async (incomingData) => {
  let payload = {
    data: {
      habit_sorting: incomingData,
    },
  };

  return await dataAccess.post(API_CONSTANTS.ADD_DRAG_DROP, payload);
};

export const getHabitTipDetails = async (habitId, lang) => {
  let responseObj = await dataAccess.get(
    `${API_CONSTANTS.GET_HABITSTIP_DETAILS}/${habitId}?lang=${lang}`
  );
  return responseObj;
};

export const updateHabitLocalisationDetails = async (incomingData, id) => {
  let payload = {
    data: incomingData,
  };
  return await dataAccess.put(
    `${API_CONSTANTS.UPDATE_HABIT_VERSION}/${id}`,
    payload
  );
};

export const updateTipLocalisationDetails = async (incomingData) => {
  let payload = {
    data: incomingData,
  };

  return await dataAccess.put(API_CONSTANTS.UPDATE_HABITTIP_VERSION, payload);
};
