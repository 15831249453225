import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import '../index.scss';
import NewInput from 'app/components/NewInput';
import Toast from 'app/utils/Toast';
import Calender from 'app/components/Calender';
import Button from 'app/components/button';
import { imageSizeValidation } from 'app/utils/Parsers';
import MultiSelectDropdown from 'app/components/MultiSelectDropdown';
import ValidationMessage from 'app/utils/ValidationMessage';
import { CloseBlackIcon } from 'app/constants/image-constants';
import Loader from 'components/Loader';
import moment from 'moment';
import CTAType from 'app/components/CTAType';
import DropdownOptions from 'app/utils/DropdownOptions';
import { getVersionControl } from 'app/api/versionList';
import {
  uplaodMedia,
  getWidgetBannerDetailsById,
  updateWidgetBannerDetailsById,
} from 'app/api/widgets';
import useTabBarOptions from 'app/utils/useTabbarOptions';
import TabBar from 'app/components/NewDesign/TabBar';
import VersionDropDown from 'app/components/VersionDropDown';
import WidgetCard from 'app/components/WidgetCard';
import NewAttachment from 'app/components/NewDesign/NewAttachment';

const WidgetTestimonialForm = ({
  ageOptions,
  cohortOptions,
  handleSubmitForm,
  widgetDetails,
  handleBack,
  selectedItem,
  bannerId,
  isModal = false,
  isEdit = true,
  singleBanner,
}) => {

    console.log(singleBanner,"singleBanner")
  const history = useHistory();
  const params = useParams();
  const { LanguageOptions } = useTabBarOptions();
  const initialState = {
    banner_name: '',
    sub_title: '',
    cohort_tags: [],
    age_tags: [],
    thumbnail_image: '',
    url_type: '',
    url: '',
    start_date: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    end_date: '',
    internal_for: '',
    internal_url: null,
    profile_data: false,
    custom_object: '',
    redirection_id: '',
    android_version: '',
    criteria: '',
    ios_version: '',
    ios_criteria: '',
    detail_cta_text: '',
    url_secondary: '',
    url_type_secondary: '',
    redirection_id_secondary: null,
    internal_for_secondary: '',
    internal_url_secondary: '',
    detail_cta_text_secondary: '',
    profile_data_secondary: false,
    custom_object_secondary: '',
    secondaryCTA: false,
    thumbnail_image_collection: [
      {
        display_order: '',
        image: '',
        status: 'ACTIVE',
        isNew: true,
      },
    ],
    video_url: '',
    duration: null,
    testimonial_type:'IMAGE_ONLY',
    bottom_image:'',
    redirect_api_url:null
  };
  const [state, setState] = useState(initialState);
  const [activeTab, setActiveTab] = useState({
    id: '1',
    name: 'English',
    short_name: 'en',
    display_order: 1,
    status: 'ACTIVE',
    value: 'English',
  });
  const [disableFields, setDisableFields] = useState(false);
  const [thumbnailImage, setThumbnailImage] = useState(null);
  const [bottomImage, setBottomImage] = useState(null);

  const [ctaimage, setctaimage] = useState(null);
  const [internalUrl, setInternalURL] = useState([]);
  let [versionLists, setVersionLists] = useState([]);
  let [iosversionLists, setIosVersionLists] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [loader, setLoader] = useState(false);
  const [disabledKeys, setDisabled] = useState({
    internalApp: true,
    externalRedirect: true,
    cta: true,
  });

  const VersionRange = [
    {
      value: 'GREATER_THAN',
      label: 'Greater Than',
    },
    {
      value: 'LESS_THAN',
      label: 'Lesser Than',
    },
    {
      value: 'EQUALS',
      label: 'Equals',
    },
  ];
  useEffect(() => {
    updateStates(bannerId, activeTab?.short_name);
  }, [activeTab?.short_name]);
  useEffect(() => {
    async function fetchVersionControl() {
      let response = await getVersionControl('Android');

      const data = response.data.available_versions;
      let newState = data.map((key) => {
        return {
          value: [key][0].toString(),
          label: [key][0].toString(),
        };
      });
      setVersionLists(newState);
      let iosResponse = await getVersionControl('IOS');

      const iosdata = iosResponse.data.ios_available_versions;
      let newiosState = iosdata.map((key) => {
        return {
          value: [key][0].toString(),
          label: [key][0].toString(),
        };
      });
      setIosVersionLists(newiosState);
    }
    fetchVersionControl();
  }, []);

  useEffect(() => {
    if (state.url_type == 'INTERNAL') {
      setDisabled({ externalRedirect: true, internalApp: false, cta: false });
    } else if (state.url_type == 'EXTERNAL') {
      setDisabled({ cta: true, internalApp: true, externalRedirect: false });
    } else if (state.url_type == 'EXTERNAL_PWA') {
      setDisabled({ internalApp: true, externalRedirect: false, cta: false });
    }
  }, [state.url_type]);

  const updateStates = async (id, lang) => {
    let internalUrl = [];
    DropdownOptions.InternalUrl.map((item, i) => {
      if (
        item.value !== 'feed' &&
        item.value !== 'content_detail' &&
        item.value !== 'quiz' &&
        item.value !== 'vital_activity' &&
        item.value !== 'webinar_details' &&
        item.value !== 'individual_quiz'
      ) {
        internalUrl.push(item);
      }
    });
    internalUrl.push({
      value: 'community_videos',
      label: 'Community Videos',
    });
    setInternalURL(internalUrl);

    if (params.id && widgetDetails && !isModal) {
      let tempData = {
        banner_name: widgetDetails.widget_name,
        banner_id: widgetDetails.banner_details[0].banner_id,
        sub_title: widgetDetails.banner_details[0].sub_title,
        cohort_tags: widgetDetails.banner_details[0].cohort_tags,
        age_tags: widgetDetails.banner_details[0].age_tags,
        thumbnail_image: widgetDetails.banner_details[0].thumbnail_image,
        url_type: widgetDetails.banner_details[0].url_type,
        url: widgetDetails.banner_details[0].url,
        start_date: widgetDetails.start_date
          ? moment.utc(widgetDetails.start_date).format('YYYY-MM-DD HH:mm:ss')
          : moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        end_date: widgetDetails.end_date
          ? moment.utc(widgetDetails.end_date).format('YYYY-MM-DD HH:mm:ss')
          : '',
        internal_for: widgetDetails.banner_details[0].internal_for,
        internal_url: widgetDetails.banner_details[0].internal_url,
        profile_data: widgetDetails.banner_details[0].profile_data,
        custom_object: widgetDetails.banner_details[0].custom_object,
        redirection_id: widgetDetails.banner_details[0].redirection_id,
        detail_cta_text: widgetDetails.banner_details[0].detail_cta_text,
        ar_order: widgetDetails.banner_details[0].ar_order,
        asthma_order: widgetDetails.banner_details[0].asthma_order,
        copd_order: widgetDetails.banner_details[0].copd_order,
        ild_order: widgetDetails.banner_details[0].ild_order,
        other_order: widgetDetails.banner_details[0].other_order,
        status: widgetDetails.banner_details[0].status,
        updated_on: widgetDetails.banner_details[0].updated_on,
        android_version: widgetDetails.banner_details[0].android_version
          ? widgetDetails.banner_details[0].android_version.toString()
          : '',
        criteria: widgetDetails.banner_details[0].criteria
          ? widgetDetails.banner_details[0].criteria
          : '',
        ios_version: widgetDetails.banner_details[0].ios_version
          ? widgetDetails.banner_details[0].ios_version.toString()
          : '',
        ios_criteria: widgetDetails.banner_details[0].ios_criteria
          ? widgetDetails.banner_details[0].ios_criteria
          : '',
        cta_image: widgetDetails.banner_details[0].cta_image
          ? widgetDetails.banner_details[0].cta_image
          : '',
          redirect_api_url:widgetDetails.banner_details[0].redirect_api_url
      };

      setState(tempData);
      if (widgetDetails.banner_details[0].thumbnail_image) {
        let image_url = `${process.env.REACT_APP_S3_BUCKET_URL}/${widgetDetails.banner_details[0].thumbnail_image}`;
        setThumbnailImage(image_url);
      }
      if (widgetDetails.banner_details[0].cta_image) {
        let image_url = `${process.env.REACT_APP_S3_BUCKET_URL}/${widgetDetails.banner_details[0].cta_image}`;
        setctaimage(image_url);
      }
    }
    if (bannerId) {
      const singleBanner = await getWidgetBannerDetailsById(id, lang);
      let videourl;
      if (activeTab.name === 'English') {
        let tempData = {
          banner_name: singleBanner.banner_title,
          sub_title: singleBanner.banner_sub_title,
          cohort_tags: singleBanner.cohort_tags,
          age_tags: singleBanner.age_tags,
          thumbnail_image: singleBanner.thumbnail_image,
          url_type: singleBanner.url_type,
          url: singleBanner.url,
          start_date: singleBanner.start_date
            ? moment.utc(singleBanner.start_date).format('YYYY-MM-DD HH:mm:ss')
            : moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          end_date: singleBanner.end_date
            ? moment.utc(singleBanner.end_date).format('YYYY-MM-DD HH:mm:ss')
            : '',
          internal_for: singleBanner.internal_for,
          internal_url: singleBanner.internal_url,
          profile_data: singleBanner.profile_data,
          custom_object: singleBanner.custom_object,
          redirection_id: singleBanner.redirection_id,
          detail_cta_text: singleBanner.detail_cta_text,
          banner_id: singleBanner.id,
          ar_order: singleBanner.ar_order,
          asthma_order: singleBanner.asthma_order,
          copd_order: singleBanner.copd_order,
          ild_order: singleBanner.ild_order,
          other_order: singleBanner.other_order,
          status: singleBanner.status,
          updated_on: singleBanner.updated_on,
          android_version: singleBanner.android_version
            ? singleBanner.android_version.toString()
            : '',
          criteria: singleBanner.criteria ? singleBanner.criteria : '',
          ios_version: singleBanner.ios_version
            ? singleBanner.ios_version.toString()
            : '',
          ios_criteria: singleBanner.ios_criteria
            ? singleBanner.ios_criteria
            : '',
          thumbnail_image_collection: singleBanner
            ? singleBanner.thumbnail_image_collection
            : [],
          url_secondary: singleBanner.url_secondary
            ? singleBanner.url_secondary
            : '',
          url_type_secondary: singleBanner.url_type_secondary
            ? singleBanner.url_type_secondary
            : '',
          redirection_id_secondary: singleBanner.redirection_id_secondary
            ? singleBanner.redirection_id_secondary
            : '',
          internal_for_secondary: singleBanner.internal_for_secondary
            ? singleBanner.internal_for_secondary
            : '',
          internal_url_secondary: singleBanner.internal_url_secondary
            ? singleBanner.internal_url_secondary
            : '',
          detail_cta_text_secondary: singleBanner.detail_cta_text_secondary
            ? singleBanner.detail_cta_text_secondary
            : '',
          profile_data_secondary: singleBanner.profile_data_secondary
            ? singleBanner.profile_data_secondary
            : '',
          custom_object_secondary: singleBanner.custom_object_secondary
            ? singleBanner.custom_object_secondary
            : '',
          secondaryCTA: singleBanner.url_type_secondary ? true : false,
          cta_image: singleBanner.cta_image ? singleBanner.cta_image : '',
          bottom_image:singleBanner.bottom_image?singleBanner.bottom_image:'',
          testimonial_type:singleBanner.testimonial_type?singleBanner.testimonial_type:'',
          redirect_api_url:singleBanner.redirect_api_url
        };
        setState(tempData);
      } else {
        setState((prevState) => ({
          ...prevState,
          banner_name: singleBanner.banner_title
            ? singleBanner.banner_title
            : '',
          thumbnail_image: singleBanner.thumbnail_image
            ? singleBanner.thumbnail_image
            : '',
          detail_cta_text: singleBanner.detail_cta_text
            ? singleBanner.detail_cta_text
            : '',
          sub_title: singleBanner.banner_sub_title
            ? singleBanner.banner_sub_title
            : '',
          android_version: singleBanner.android_version
            ? singleBanner.android_version.toString()
            : '',
          criteria: singleBanner.criteria ? singleBanner.criteria : '',
          ios_version: singleBanner.ios_version
            ? singleBanner.ios_version.toString()
            : '',
          ios_criteria: singleBanner.ios_criteria
            ? singleBanner.ios_criteria
            : '',
          thumbnail_image_collection: singleBanner
            ? singleBanner.thumbnail_image_collection
            : [],
          secondaryCTA: singleBanner.url_type_secondary ? true : false,
          url_secondary: singleBanner.url_secondary
            ? singleBanner.url_secondary
            : '',
          url_type_secondary: singleBanner.url_type_secondary
            ? singleBanner.url_type_secondary
            : '',
          redirection_id_secondary: singleBanner.redirection_id_secondary
            ? singleBanner.redirection_id_secondary
            : '',
          internal_for_secondary: singleBanner.internal_for_secondary
            ? singleBanner.internal_for_secondary
            : '',
          internal_url_secondary: singleBanner.internal_url_secondary
            ? singleBanner.internal_url_secondary
            : '',
          detail_cta_text_secondary: singleBanner.detail_cta_text_secondary
            ? singleBanner.detail_cta_text_secondary
            : '',
          profile_data_secondary: singleBanner.profile_data_secondary
            ? singleBanner.profile_data_secondary
            : '',
          custom_object_secondary: singleBanner.custom_object_secondary
            ? singleBanner.custom_object_secondary
            : '',
          cta_image: singleBanner.cta_image ? singleBanner.cta_image : '',
          testimonial_type:singleBanner.testimonial_type?singleBanner.testimonial_type:'',
          bottom_image:singleBanner.bottom_image?singleBanner.bottom_image:'',
          redirect_api_url:singleBanner.redirect_api_url
        }));
      }
      videourl = '';
      if (singleBanner?.video_url) {
        videourl = `${process.env.REACT_APP_S3_BUCKET_URL}/${singleBanner.video_url}`;
      }
      setSelectedVideo(videourl);

      if (singleBanner.thumbnail_image) {
        let image_url = `${process.env.REACT_APP_S3_BUCKET_URL}/${singleBanner.thumbnail_image}`;
        setThumbnailImage(image_url);
      } else {
        setThumbnailImage(null);
      }
      if (singleBanner.bottom_image) {
        let image_url = `${process.env.REACT_APP_S3_BUCKET_URL}/${singleBanner.bottom_image}`;
        setBottomImage(image_url);
      } else {
        setBottomImage(null);
      }
      if (singleBanner.cta_image) {
        let image_url = `${process.env.REACT_APP_S3_BUCKET_URL}/${singleBanner.cta_image}`;
        setctaimage(image_url);
      } else {
        setctaimage(null);
      }
    }
    if (isEdit && !bannerId) {
      let tempData = {
        banner_name: singleBanner.banner_name,
        cohort_tags: singleBanner.cohort_tags,
        age_tags: singleBanner.age_tags,
        thumbnail_image: singleBanner.thumbnail_image,
        url_type: singleBanner.url_type,
        url: singleBanner.url,
        start_date: singleBanner.start_date
          ? moment.utc(singleBanner.start_date).format('YYYY-MM-DD HH:mm:ss')
          : moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        end_date: singleBanner.end_date
          ? moment.utc(singleBanner.end_date).format('YYYY-MM-DD HH:mm:ss')
          : '',
        internal_for: singleBanner.internal_for,
        internal_url: singleBanner.internal_url,
        profile_data: singleBanner.profile_data,
        custom_object: singleBanner.custom_object,
        redirection_id: singleBanner.redirection_id,
        detail_cta_text: singleBanner.detail_cta_text,
        ar_order: singleBanner.ar_order,
        asthma_order: singleBanner.asthma_order,
        copd_order: singleBanner.copd_order,
        ild_order: singleBanner.ild_order,
        other_order: singleBanner.other_order,
        status: singleBanner.status,
        updated_on: singleBanner.updated_on,
        android_version: singleBanner.android_version
          ? singleBanner.android_version.toString()
          : '',
        criteria: singleBanner.criteria ? singleBanner.criteria : '',
        ios_version: singleBanner.ios_version
          ? singleBanner.ios_version.toString()
          : '',
        ios_criteria: singleBanner.ios_criteria
          ? singleBanner.ios_criteria
          : '',
        cta_image: singleBanner.cta_image ? singleBanner.cta_image : '',
        bottom_image:singleBanner.bottom_image,
        redirect_api_url:singleBanner.redirect_api_url
      };

      setState(tempData);
    }

    if (!isEdit) {
      setState(initialState);
    }
  };

  const handleTextChange = (e, index) => {
    if (e.target.name === 'banner_name') {
      if (e.target.value.length <= 34) {
        setState({ ...state, [e.target.name]: e.target.value });
      }
    } else if (e.target.name === 'sub_title') {
      if (e.target.value.length <= 30) {
        setState({ ...state, [e.target.name]: e.target.value });
      }
    } else {
      setState({ ...state, [e.target.name]: e.target.value });
    }
  };

  const cancelMedia = async (e, file) => {
    setState({ ...state, [e.target.name]: '' });
  };

  const handleMediaUpload = async (e, index) => {
    if (!e) return;
    let file;
    let reader = new FileReader();
    if (e?.target?.name === 'thumbnail_image') {
      file = e.target.files[0];
      if (
        (file.type === 'image/jpeg' ||
          file.type === 'image/jpg' ||
          file.type === 'image/png') &&
        imageSizeValidation(e)
      ) {
        Toast.error(ValidationMessage.IMAGE_SIZE);
        return;
      } else {
        let resp = await uplaodMedia('image', file);

        if (e.target.name === 'thumbnail_image') {
          setState({ ...state, [e.target.name]: resp?.filename });
          let reader = new FileReader();
          reader.onloadend = function () {
            setThumbnailImage(reader.result);
          };
          reader.readAsDataURL(file);
        }

        Toast.success(file?.name + ' uploaded');
      }
    }else if (e?.target?.name === 'bottom_image') {
        file = e.target.files[0];
        if (
          (file.type === 'image/jpeg' ||
            file.type === 'image/jpg' ||
            file.type === 'image/png') &&
          imageSizeValidation(e)
        ) {
          Toast.error(ValidationMessage.IMAGE_SIZE);
          return;
        } else {
          let resp = await uplaodMedia('image', file);
  
          if (e.target.name === 'bottom_image') {
            setState({ ...state, [e.target.name]: resp?.filename });
            let reader = new FileReader();
            reader.onloadend = function () {
                setBottomImage(reader.result);
            };
            reader.readAsDataURL(file);
          }
  
          Toast.success(file?.name + ' uploaded');
        }
      } else if (e?.target?.name === 'cta_image') {
      file = e.target.files[0];
      if (
        (file.type === 'image/jpeg' ||
          file.type === 'image/jpg' ||
          file.type === 'image/png') &&
        imageSizeValidation(e)
      ) {
        Toast.error(ValidationMessage.IMAGE_SIZE);
        return;
      } else {
        let resp = await uplaodMedia('image', file);

        if (e.target.name === 'cta_image') {
          setState({ ...state, [e.target.name]: resp?.filename });
          let reader = new FileReader();
          reader.onloadend = function () {
            setctaimage(reader.result);
          };
          reader.readAsDataURL(file);
        }

        Toast.success(file?.name + ' uploaded');
      }
    } else if (e?.target?.files[0]?.type?.split('/')?.[0] === 'video') {
      file = e.target.files[0];
      		if((file.type === 'video/mp4' || file.type === "video/webm") && file.size >10485760){
        		Toast.error("Video size will be less than 10MB");
        		return;
      		}
      setLoader(true);
      file = e.target.files[0];
      const src = URL.createObjectURL(file);
      var video = document.createElement('video');
      video.src = src;
      let resp = await uplaodMedia('image', file);
      setState({
        ...state,
        duration: parseInt(parseFloat(video.duration)),
        [e.target.name]: resp?.filename,
      });

      video.addEventListener('loadedmetadata', function () {
        setState({
          ...state,
          duration: parseInt(parseFloat(video.duration)),
          [e.target.name]: resp?.filename,
        });
      });
      reader.onloadend = function () {
        setSelectedVideo(reader.result);
      };
      reader.readAsDataURL(file);
      Toast.success(file?.name + ' uploaded');
      setLoader(false);
    } else {
      file = e;
      if (
        (file.type === 'image/jpeg' ||
          file.type === 'image/jpg' ||
          file.type === 'image/png') &&
        file.size >= 100000
      ) {
        Toast.error(ValidationMessage.IMAGE_SIZE);
        return;
      } else {
        let resp = await uplaodMedia('image', file);
        const valueCpy = [...state.thumbnail_image_collection];
        valueCpy[index]['image'] = resp?.filename;

        setState({ ...state, thumbnail_image_collection: [...valueCpy] });

        Toast.success(file?.name + ' uploaded');
      }
    }
  }; 

  const handleSubmit = () => {
    if(!state.banner_name){
      Toast.error('Enter the banner name')
      return 
    }
    if(state.cohort_tags.length ===0){
      Toast.error('Please select the Cohort Tag')
      return 
    }
    if(state.age_tags.length ===0){
      Toast.error('Please select the Age tag')
      return 
    }
    
    // if((selectedItem === 'auto_play_video' || selectedItem === 'short_cut_banner' || selectedItem === 'vertical_content_banner' || selectedItem === 'single_banner' || selectedItem === 'medium_square_banner') && !state.url_type ){
    //   Toast.error('Select the Primary CTA type')
    //   return 
    // }
    
    if(state.url_type === 'INTERNAL' && !state.url){
      Toast.error('Select the internal app redirection')
      return 
    }
    
    if(state.url_type === 'INTERNAL_PWA' && !state.internal_for){ 
        Toast.error('Enter the internal for')
        return   
    }
    if(state.url_type === 'INTERNAL_PWA' && (!state.internal_url && !state.redirect_api_url)){
      Toast.error('Enter the internal URL')
      return 
    }
    if(state.url_type === 'INTERNAL_PWA' && !state.custom_object){
      Toast.error('Select the custom object')
      return 
    }
    if(state.secondaryCTA && !state.url_type_secondary){
      Toast.error('Select the  secondary CTA type')
      return 
    } 
    if(state.url_type_secondary === 'INTERNAL' && !state.url_secondary){
      Toast.error('Select the internal app redirection')
      return 
    }
    
    if(!state.android_version){
      Toast.error('Select the android version')
      return 
    }
    if(!state.criteria){
      Toast.error('Select the android Version Range')
      return 
    }
    if(!state.ios_version){
      Toast.error('Select the iOS version')
      return 
    }
    if(!state.ios_criteria){
      Toast.error('Select the iOS Version Range')
      return 
    }

    if (activeTab?.name === 'English') {
      handleSubmitForm(state);
    } else {
      updateLocalisationData();
    }
  };
  const updateLocalisationData = async () => {
    if (
      state?.banner_name &&
      (state?.thumbnail_image ||
        selectedItem === 'auto_play_video' ||
        selectedItem === 'big_banner_advanced')
    ) {
      let imagecollection = [];

      if (selectedItem === 'big_banner_advanced') {
        imagecollection = state.thumbnail_image_collection;
      }
      const payload = {
        banner_title: state?.banner_name,
        detail_cta_text: state?.detail_cta_text,
        thumbnail_image: state?.thumbnail_image,
        banner_sub_title: state?.sub_title,
        android_version: state?.android_version,
        criteria: state?.criteria,
        ios_version: state?.ios_version,
        ios_criteria: state?.ios_criteria,
        url: state?.url,
        url_type: state?.url_type,
        redirection_id: state.redirection_id,
        internal_for: state.internal_for,
        internal_url: state.internal_url ? state.internal_url : null,
        profile_data: state.profile_data,
        custom_object: state.custom_object,
        url_secondary: state?.url_secondary,
        url_type_secondary: state?.url_type_secondary,
        redirection_id_secondary: state?.redirection_id_secondary,
        internal_for_secondary: state?.internal_for_secondary,
        internal_url_secondary: state?.internal_url_secondary,
        detail_cta_text_secondary: state?.detail_cta_text_secondary,
        profile_data_secondary: state?.profile_data_secondary,
        custom_object_secondary: state?.custom_object_secondary,
        thumbnail_image_collection: imagecollection,
        video_url: state.video_url ? state.video_url : '',
        cta_image:state.cta_image,
        testimonial_type:state.testimonial_type ? state.testimonial_type:'',
        bottom_image:state.bottom_image ? state.bottom_image:'',
        redirect_api_url:state.redirect_api_url ? state.redirect_api_url : null

      };

      const resp = await updateWidgetBannerDetailsById(
        payload,
        bannerId,
        activeTab?.short_name
      );
      if (resp && resp.response && resp.response.code === 200) {
        Toast.success(resp.response.alert[0].message);
        if (selectedItem != 'single_banner') {
          handleBack();
        } else {
          history.push('/dynamic-widgets');
        }
      } else {
        console.log(resp, 'res');
        Toast.error(resp.response.alert[0].message);
      }
    } else if(selectedItem == "testimonial_collection" ){
      if(!state.video_url || !state.bottom_image){
      Toast.error('Enter video and Bottom Image ');

      }
    } else{
      Toast.error('Enter all the details');
    }
  };
  const handleTabChange = (val) => {
    setActiveTab(val);
    if (val?.name === 'English') {
      setDisableFields(false);
    } else {
      setDisableFields(true);
    }
  };

  const handleSecondaryCTA = () => {
    if (state.secondaryCTA) {
      setState({
        ...state,
        secondaryCTA: !state.secondaryCTA,
        url_type_secondary: '',
        redirection_id_secondary: null,
        internal_for_secondary: '',
        internal_url_secondary: '',
        url_secondary: '',
        detail_cta_text_secondary: '',
        profile_data_secondary: false,
        custom_object_secondary: '',
      });
    } else {
      setState({ ...state, secondaryCTA: !state.secondaryCTA });
    }
  };

  const removeServiceSection = (index) => {
    const valueCpy = [...state.thumbnail_image_collection];

    valueCpy.splice(index, 1);

    setState({ ...state, thumbnail_image_collection: [...valueCpy] });
  };

  const handlePublishUnpublishBanner = async (index) => {
    const valueCpy = [...state.thumbnail_image_collection];
    let text = `Are you sure, want to ${
      valueCpy[index].status === 'ACTIVE' ? 'Unpublish' : 'Publish'
    } banner`;
    valueCpy[index].status =
      state.thumbnail_image_collection[index].status === 'ACTIVE'
        ? 'INACTIVE'
        : 'ACTIVE';

    if (window.confirm(text) == true) {
      // setLoader(true);
      // let payload = {
      //   advertisement_id: state.advertisement_id,
      //   thumbnail_image_collection: valueCpy,
      // };
      // let responseObj = await updateAdvertisementStatus(payload);
      // if (
      //   responseObj &&
      //   responseObj.response &&
      //   responseObj.response.code === 200
      // ) {
      //   Toast.success(responseObj.response.alert[0].message);
      // } else {
      //   setLoader(false);
      //   Toast.error(
      //     responseObj.data
      //       ? responseObj.data.msg
      //       : responseObj.response.alert[0].message
      //       ? responseObj.response.alert[0].message
      //       : ''
      //   );
      // }
      // setLoader(false);
    }
  };

  const OnhandleChange = (e, index) => {
    const { value } = e.target;
    const valueCpy = [...state.thumbnail_image_collection];
    const duplicateOrder = valueCpy.find(
      (option) => option.display_order === value
    );
    if (value && duplicateOrder) {
      Toast.error(
        `same display order already added. can't add duplicate display order `
      );
      return;
    } else {
      valueCpy[index]['display_order'] = value;
      setState({ ...state, thumbnail_image_collection: [...valueCpy] });
    }
  };

  const handleNewBanner = () => {
    const valueCpy = [...state.thumbnail_image_collection];
    valueCpy.push({
      image: '',
      display_order: '',
      status: 'ACTIVE',
      isNew: true,
    });
    setState({ ...state, thumbnail_image_collection: [...valueCpy] });
  }; 
  console.log(selectedItem,"item")

  const testimonialType = [
    {
        label: 'Type 1: Video + Image',
        value: 'VIDEO_AND_IMAGE',
    },
    {
        label: 'Type 2: Image',
        value: 'IMAGE_ONLY',
    },
    
];
  return (
    <>
      <div className='cms-content__container'>
        {bannerId ? (
          <TabBar
            tabs={LanguageOptions}
            initialTab={activeTab}
            onTabChange={handleTabChange}
            parentStyle={'service-tab-style'}
          />
        ) : null}
        <div className='row'>
          <div className='col-md-6'>
            <div className='userInput'>
              <NewInput
                label='Testimonial Name/Title'
                name={'banner_name'}
                value={state.banner_name}
                onChange={handleTextChange}
                max={34}
              />
            </div>
          </div>
          {/* {selectedItem != 'big_banner' &&
            selectedItem != 'single_banner' &&
            selectedItem != 'small_services_banner' &&
            selectedItem != 'short_cut_banner' &&
            selectedItem != 'medium_square_banner' &&
            selectedItem != 'big_banner_advanced' &&
            selectedItem != 'auto_play_video' && (
              <div className='col-md-6'>
                <div className='userInput'>
                  <NewInput
                    label='Banner Sub Title'
                    name={'sub_title'}
                    value={state.sub_title}
                    onChange={handleTextChange}
                    // isDisabled={disableFields}
                    max={30}
                  />
                </div>
              </div>
            )} */}
        </div>

        <div className='row'>
          <div className='col-md-4'>
            <MultiSelectDropdown
              label='Cohort Tag'
              name='cohort_tags'
              value={state.cohort_tags}
              options={cohortOptions}
              onChange={handleTextChange}
              tag_type={false}
              disable={disableFields}
            />
          </div>
          <div className='col-md-4'>
            <MultiSelectDropdown
              label='Age Tag'
              name='age_tags'
              value={state.age_tags}
              options={ageOptions}
              onChange={handleTextChange}
              tag_type={false}
              disable={disableFields}
            />
          </div>
        </div>
        <div className='row'>
            <div className='col-md-6'>
            <div className=' my-5'>
                        <label>Testimonial Type </label>
                        <select
                            name='testimonial_type'
                            className='custom-select mx-3 w-50'
                            onChange={(e) => {
                                setState({ ...state, testimonial_type: e.target.value });
                            }}
                        disabled={disableFields}
                        >
                            <option value=''>Select</option>
                            {testimonialType?.map((item, indx) => (
                                <option
                                    key={indx}
                                    value={item.value}
                                selected={state.testimonial_type == item.value ? true : false}
                                >
                                    {item.label}
                                </option>
                            ))}
                        </select>
                    </div>
            </div>
        </div>
        {state.testimonial_type == 'VIDEO_AND_IMAGE' ?(

        <>
            <div className='row'>
              <div className='mt-1'>
                {state.bottom_image && (
                  <p
                    className='cancel'
                    onClick={(e) => {
                      if (state.bottom_image) {
                        cancelMedia(e, state.bottom_image);
                      }
                      setBottomImage(null);
                      setState({ ...state, bottom_image: '' });
                    }}
                  >
                    Cancel Bottom Image
                  </p>
                )}
              </div>
              <div className='upload-buttons '>
                <input
                  type='file'
                  id='bottomImageFile'
                  style={{ display: 'none' }}
                  name='bottom_image'
                  onChange={(e) => {
                    handleMediaUpload(e);
                  }}
                />
                <div className='upload-label'>
                  <label>Bottom Image</label>
                  <Button
                    onClick={() => {
                      const image = document.getElementById('bottomImageFile');
                      image.click();
                    }}
                    isDisabled={state.bottom_image}
                    type='button'
                    name={
                      state.bottom_image != ''
                        ? 'Uploaded'
                        : 'Upload Bottom Image'
                    }
                  />
                </div>
                {bottomImage && (
                  <div className='attachment_img_wrapper'>
                    <img
                      src={bottomImage}
                      alt='sample'
                      className='feed__attachment'
                    />
                    <div className='attachment_img_wrapper_close'>
                      <img
                        src={CloseBlackIcon}
                        alt='Remove'
                        onClick={(e) => {
                          if (state.bottom_image) {
                            cancelMedia(e, state.bottom_image);
                          }
                          setBottomImage(null);
                          setState({ ...state, bottom_image: '' });
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          

            <div className='row'>
              <div className='mt-1'>
                {state.thumbnail_image && (
                  <p
                    className='cancel'
                    onClick={(e) => {
                      if (state.thumbnail_image) {
                        cancelMedia(e, state.thumbnail_image);
                      }
                      setThumbnailImage(null);
                      setState({ ...state, thumbnail_image: '' });
                    }}
                  >
                    Cancel Thumbnail
                  </p>
                )}
              </div>
              <div className='upload-buttons '>
                <input
                  type='file'
                  id='thumbnailFile'
                  style={{ display: 'none' }}
                  name='thumbnail_image'
                  onChange={(e) => {
                    handleMediaUpload(e);
                  }}
                />
                <div className='upload-label'>
                  <label>Thumbnail</label>
                  <Button
                    onClick={() => {
                      const image = document.getElementById('thumbnailFile');
                      image.click();
                    }}
                    isDisabled={state.thumbnail_image}
                    type='button'
                    name={
                      state.thumbnail_image != ''
                        ? 'Uploaded'
                        : 'Upload Thumbnail Image'
                    }
                  />
                </div>
                {thumbnailImage && (
                  <div className='attachment_img_wrapper'>
                    <img
                      src={thumbnailImage}
                      alt='sample'
                      className='feed__attachment'
                    />
                    <div className='attachment_img_wrapper_close'>
                      <img
                        src={CloseBlackIcon}
                        alt='Remove'
                        onClick={(e) => {
                          if (state.thumbnail_image) {
                            cancelMedia(e, state.thumbnail_image);
                          }
                          setThumbnailImage(null);
                          setState({ ...state, thumbnail_image: '' });
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          


          <div className='upload-buttons'>
            <input
              type='file'
              id='videoFile'
              accept='video/*'
              style={{ display: 'none' }}
              name='video_url'
              onChange={(e) => {
                handleMediaUpload(e);
              }}
            />
            <div className='upload-label'>
              <label>Upload Video</label>
              <Button
                onClick={() => {
                  const image = document.getElementById('videoFile');
                  image.click();
                }}
                type='button'
                isDisabled={
                  state.youtube_url ? true : selectedVideo ? true : false
                }
                name={!selectedVideo ? 'Upload Video' : 'Uploaded'}
              />
            </div>
            {selectedVideo && (
              <div className='attachment_img_wrapper'>
                <video
                  className='attachment__selimage'
                  controls
                  key={selectedVideo}
                >
                  <source
                    src={selectedVideo}
                    type='video/mp4'
                    key={selectedVideo}
                  />
                </video>
                <div className='attachment_img_wrapper_close'>
                  <img
                    src={CloseBlackIcon}
                    alt='Remove'
                    onClick={(e) => {
                      // if (data) {
                      //   cancelMedia(e, state.video_url);
                      // }
                      setSelectedVideo(null);
                      setState({ ...state, video_url: '' });
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          </>
       ):(
        <div className='row'>
              <div className='mt-4'>
                {state.thumbnail_image && (
                  <p
                    className='cancel'
                    onClick={(e) => {
                      if (state.thumbnail_image) {
                        cancelMedia(e, state.thumbnail_image);
                      }
                      setThumbnailImage(null);
                      setState({ ...state, thumbnail_image: '' });
                    }}
                  >
                    Cancel Thumbnail
                  </p>
                )}
              </div>
              <div className='upload-buttons '>
                <input
                  type='file'
                  id='thumbnailFile'
                  style={{ display: 'none' }}
                  name='thumbnail_image'
                  onChange={(e) => {
                    handleMediaUpload(e);
                  }}
                />
                <div className='upload-label'>
                  <label>Thumbnail</label>
                  <Button
                    onClick={() => {
                      const image = document.getElementById('thumbnailFile');
                      image.click();
                    }}
                    isDisabled={state.thumbnail_image}
                    type='button'
                    name={
                      state.thumbnail_image != ''
                        ? 'Uploaded'
                        : 'Upload Thumbnail Image'
                    }
                  />
                </div>
                {thumbnailImage && (
                  <div className='attachment_img_wrapper'>
                    <img
                      src={thumbnailImage}
                      alt='sample'
                      className='feed__attachment'
                    />
                    <div className='attachment_img_wrapper_close'>
                      <img
                        src={CloseBlackIcon}
                        alt='Remove'
                        onClick={(e) => {
                          if (state.thumbnail_image) {
                            cancelMedia(e, state.thumbnail_image);
                          }
                          setThumbnailImage(null);
                          setState({ ...state, thumbnail_image: '' });
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
       )}
        <div className='row'>
          <div className='userFormArea'>
            <h5>Detailed CTA Type</h5>

            <CTAType
              cta_type={state.url_type}
              handleChange={(key, data) => {
                if (key === 'cta_type') {
                  if(!data ){
                    setState({ ...state, url_type: data, url:'' });
                  }else{
                    setState({ ...state, url_type: data });
                  } 
                } else if (key === 'cta_type_secondary') {
                  if(!data ){
                    setState({ ...state, url_type_secondary: data, url_secondary:'' });
                  }else{
                    setState({ ...state, url_type_secondary: data });
                  }  
                } else {
                  if (
                    key === 'url' &&
                    (data !== 'zoom_live_session' ||
                      data !== 'infinite_scroll' ||
                      data !== 'group_of_collection')
                  ) {
                    setState({ ...state, [key]: data, redirection_id: '' });
                  } else if (
                    key === 'url_secondary' &&
                    (data !== 'zoom_live_session' ||
                      data !== 'infinite_scroll' ||
                      data !== 'group_of_collection')
                  ) {
                    setState({
                      ...state,
                      [key]: data,
                      redirection_id_secondary: '',
                    });
                  } else {
                    setState({ ...state, [key]: data });
                  }
                }
              }}
              internalUrl={internalUrl}
              url={state.url}
              handleTextChange={handleTextChange}
              internal_for={state.internal_for}
              internal_url={state.internal_url}
              profile_data={state.profile_data}
              custom_object={state.custom_object}
              redirection_id={state.redirection_id}
              isDisabled={disableFields}
              secondaryCTA={state.secondaryCTA}
              cta_type_secondary={state.url_type_secondary}
              url_secondary={state.url_secondary}
              internal_for_secondary={state.internal_for_secondary}
              internal_url_secondary={state.internal_url_secondary}
              profile_data_secondary={state.profile_data_secondary}
              custom_object_secondary={state.custom_object_secondary}
              redirection_id_secondary={state.redirection_id_secondary}
              ctaText={true}
              detail_cta_text={state.detail_cta_text}
              detail_cta_text_secondary={state.detail_cta_text_secondary}
              redirect_api_url={state.redirect_api_url}
            />
            {(selectedItem === 'big_banner_advanced' ||
              selectedItem === 'auto_play_video') && (
              <div className='row'>
                <div className='col d-flex justify-content-start my-3'>
                  <Button
                    type='button'
                    onClick={() => {
                      handleSecondaryCTA();
                    }}
                  >
                    {state.secondaryCTA
                      ? 'Remove Secondary CTA'
                      : 'Add Secondary CTA'}
                  </Button>
                </div>
              </div>
            )}
          </div>
          
          {/* <div className='input-container'>
            {state.url_type === 'INTERNAL' && (
              <NewInput
                name='detail_cta_text'
                label={'Detailed CTA text'}
                value={state.detail_cta_text}
                onChange={handleTextChange}
                disabled={disabledKeys.cta}
              />
            )}
          </div> */}
        </div>
         <VersionDropDown
          android_version={state.android_version}
          criteria={state.criteria}
          versionLists={versionLists}
          iosLists={iosversionLists}
          VersionRange={VersionRange}
          handleTextChange={handleTextChange}
          ios_version={state.ios_version}
          ios_criteria={state.ios_criteria}
        />
        {/*
        {!disableFields && (
          <div className='row'>
            <Calender
              onStartDateSelected={(e) => {
                setState({ ...state, start_date: e });
              }}
              onEndDateSelected={(e) => {
                setState({ ...state, end_date: e });
              }}
              startDate={state.start_date}
              endDate={state.end_date}
              validated={true}
              activate={true}
              key={state.end_date}
            />
          </div>
        )} */}
        {selectedItem === 'big_banner_advanced' && (
          <>
            {state.thumbnail_image_collection &&
              state.thumbnail_image_collection.map((data, index) => {
                return (
                  <div key={index}>
                    <WidgetCard
                      widgetcardkey={index}
                      index={index}
                      handleRemoveCard={removeServiceSection}
                      type={'Banner'}
                      closeSection={
                        state.thumbnail_image_collection.length > 1 &&
                        data.isNew
                          ? true
                          : false
                      }
                      handleResourseToggle={() =>
                        handlePublishUnpublishBanner(index)
                      }
                      isEdit={params.id ? false : false}
                      isNew={
                        state.thumbnail_image_collection.length > 1 &&
                        data.isNew
                          ? true
                          : false
                      }
                      status={data.status}
                    >
                      <div className=''>
                        <div>
                          <NewAttachment
                            value={data.image}
                            id={index}
                            onFileSelected={(e) => {
                              handleMediaUpload(e, index);
                            }}
                            fileType='image'
                            label={`Add Banner ${index + 1} `}
                            className='widget_box_feed__attachment'
                          />

                          <NewInput
                            label='Display order'
                            id={'display_order'}
                            placeholder='Enter display Order 1/2/3'
                            name='display_order'
                            value={data.display_order}
                            onChange={(e) => OnhandleChange(e, index)}
                            type='number'
                          />
                        </div>
                      </div>
                    </WidgetCard>
                  </div>
                );
              })}
            {state.thumbnail_image_collection &&
              state.thumbnail_image_collection.length < 5 && (
                <div className='row'>
                  <div className='col d-flex justify-content-start my-3'>
                    <Button
                      type='button'
                      onClick={() => {
                        handleNewBanner();
                      }}
                    >
                      Add Banner
                    </Button>
                  </div>
                </div>
              )}
          </>
        )}

        <div className='row'>
          <div className='userFormArea'>
            <Button
              name={params.id ? 'Update' : 'Save'}
              onClick={handleSubmit}
            />
            <label
              style={{ paddingLeft: '10px', cursor: 'pointer' }}
              onClick={() => {
                setState(initialState);
                handleBack();
              }}
            >
              Cancel
            </label>
          </div>
        </div>
        {loader && <Loader />}
      </div>
    </>
  );
};

export default WidgetTestimonialForm;
