// Pages
import BreathfreeCoins from 'app/container/BreathfreeCoins';
import BasePage from 'pages/BasePage';

const CoinsPage = () => {
  return (
    <BasePage>
      <BreathfreeCoins />
    </BasePage>
  );
};

export default CoinsPage;
