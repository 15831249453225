// Ajax
import * as dataAccess from 'utils/ajax';
import ApiParser from 'utils/ApiParser';

// Constants
import { API_CONSTANTS } from 'constants/api-contants';
import { LocalSessionKey } from 'constants/app-constants';
import { LocalStorage } from 'utils/storage';
import Parsers from 'utils/Parsers';

export const getWebinar = async (start, filterPayload) => {
  let payload = {
    data: {
      page: start ? start : 1,
      limit: 100,
      ...filterPayload,
    },
  };

  let responseObj = await dataAccess.post(API_CONSTANTS.GET_WEBINAR, payload);
  return ApiParser.parseWebinarListing(responseObj);
};

export const addOrUpdateWebinar = async (incomingData, webinar_id) => {
  let data;

  let payloads = [];
  if (incomingData) {
    data = {
      webinar_update: webinar_id ? 1 : 0,
      // webinar_update: incomingData?.webinar_id ? 1 : 0,
      ...incomingData,
    };
    if (webinar_id) {
      data.webinar_id = webinar_id;
    }
    var formData = Parsers.ConvertObjectAsFormData(data);
    payloads.push({
      data: formData,
    });
  }

  if (payloads.length < 1) {
    return;
  }

  return await dataAccess.uploadAll(API_CONSTANTS.UPDATE_WEBINAR, payloads);
};

// debugger;
export const addOrUpdateRoles = async (incomingData, webinarId) => {
  let data;

  let payloads = [];
  if (incomingData) {
    data = {
      ...incomingData,
    };
    var formData = Parsers.ConvertObjectAsFormData(data);
    payloads.push({
      data: formData,
    });
  }

  if (payloads.length < 1) {
    return;
  }

  return await dataAccess.uploadAll(API_CONSTANTS.UPDATE_ROLES, payloads);
};

export const publishOrUnpublishWebinar = async (webinarId, status) => {
  if (!webinarId || !status) {
    return;
  }

  let data = {
    publish: status.toLowerCase() === 'inactive' ? 1 : 0,
    webinar_update: 1,
    webinar_id: webinarId,
  };

  var formData = Parsers.ConvertObjectAsFormData(data);

  let payload = {
    data: formData,
  };

  return await dataAccess.upload(API_CONSTANTS.UPDATE_WEBINAR, payload);
};

// export const addOrUpdateWebinar = async (incomingData) => {
//   let data;

//   let payloads = [];

//   incomingData.forEach((webinar) => {
//     data = {
//       // publish: 1,
//       ...incomingData,
//       webinar_update: 0,
//       // webinar_update: webinar.webinar_update,
//       webinars: JSON.stringify(webinar),
//     };
//     console.log(data, 'sdfjkdataedit');

//     var formData = Parsers.ConvertObjectAsFormData(data);
//     payloads.push({
//       data: formData,
//     });
//   });

//   if (payloads.length < 1) {
//     return;
//   }

//   return await dataAccess.uploadAll(API_CONSTANTS.UPDATE_WEBINAR, payloads);
// };

export const deleteWebinar = async (webinarId) => {
  if (!webinarId) {
    return;
  }

  let payload = {
    data: {
      webinar_id: webinarId,
    },
  };
  return await dataAccess.post(API_CONSTANTS.DELETE_WEBINAR, payload);
};

export const deleteWebinarSpeaker = async (webinar_id, speaker_id) => {
  if (!webinar_id) {
    return;
  }

  let payload = {
    data: {
      webinar_id: webinar_id,
      speaker_id: speaker_id,
    },
  };
  return await dataAccess.post(API_CONSTANTS.DELETE_SPEAKER, payload);
};

export const deleteWebinarHost = async (webinar_id, host_id) => {
  // console.log('546', webinar_id);
  // debugger;
  if (!webinar_id) {
    return;
  }

  let payload = {
    data: {
      webinar_id: webinar_id,
      speaker_id: host_id,
    },
  };
  return await dataAccess.post(API_CONSTANTS.DELETE_SPEAKER, payload);
};

export const getWebinarById = async (id, lang) => {
  let url = `${API_CONSTANTS.GET_WEBINAR_VERSION2}/${id}?lang=${lang}`;
  let responseObj = await dataAccess.get(url);
  return responseObj;
};

export const updateWebinar = async (incomingData, webinar_id) => {
  let formData = Parsers.ConvertObjectAsFormData(incomingData)
  let payload = {
      data: formData
  }
  return await dataAccess.update(`${API_CONSTANTS.UPDATE_WEBINAR_VERSION}/${webinar_id}`, payload);
};