// Components
import HeaderWithBackButton from 'components/HeaderWithBackButton';
import NewInput from 'components/NewInput';
import PageLoader from 'components/PageLoader';
import NumberInput from 'components/UI/NumberInput';
import validate from 'components/ValidateInfo';
import Button from 'components/button';
import 'react-toggle/style.css';
// API

// Constants
import { APP_CONSTANTS } from 'constants/app-constants';
import './index.scss';

// Utils

import NewHtmlEditor from 'app/components/NewDesign/NewHtmlEdior';
import TabBar from 'app/components/NewDesign/TabBar';
import { CloseBlackIcon } from 'app/constants/image-constants';
import useTabBarOptions from 'app/utils/useTabbarOptions';
import Toggle from 'react-toggle';
import FaqActionMenu from './FaqActionMenu';
import useCreateFaq from './useCreateFaq';
import NewQuillTextEditor from 'app/shared/components/NewQuillTextEditor';

const CreateFaq = () => {
  const { LanguageOptions } = useTabBarOptions();
  const {
    isPublishButtonDisabled,
    setisPublishButtonDisabled,
    pageLoading,
    OnhandleChange,
    addfaqSection,
    faqlistData,
    editorRef,
    moduleName,
    validateRequest,
    setModuleName,
    handleEditorChange,
    activeId,
    createToggler,
    onClickOutside,
    menuActionObj,
    // openUp,
    handleResourseToggle,
    // toggledstatus,
    activeTab,
    activeShow,
    handleTabChange,
    moduleId,
  } = useCreateFaq({ validate });

  return (
    <>
      <div className='blogsFormfaq'>
        <div className='blogsForm_content'>
          <div className='d-flex justify-content-between'>
            <HeaderWithBackButton
              title='Add/edit Module FAQ'
              goTo={APP_CONSTANTS.FAQ}
            />
            {/* ----------------- Toggle functionality------------- */}
            {/* <div className='d-block my-4'>
              <span className='me-2 d-inline-block'>Status*</span>
              <label className='d-inline-block mt-2'>
                <div className='d-inline-flex align-items-center'>
                  <div className='d-inline-flex text-danger'>OFF</div>
                  <div className='d-inline-flex mx-2'>
                    <Toggle
                      checked={toggledstatus}
                      icons={false}
                      onChange={(e) => {
                        e.preventDefault();
                        openUp();
                      }}
                    />
                  </div>
                  <div className='d-inline-flex text-green'>ON</div>
                </div>
              </label>
            </div> */}
          </div>

          {activeShow && (
            <NewInput
              label='Module Name'
              id='modulename'
              type='text'
              placeholder='Automated Vitals'
              name='modulename'
              value={moduleName}
              onChange={(e) => {
                setisPublishButtonDisabled(false);
                setModuleName(e.target.value);
              }}
              // isFieldHidden={activeShow}
              // errorText={error.question}
            />
          )}
        </div>
      </div>

      <>
        {moduleId && (
          <div className='mt-3 mb-3 ms-4'>
            <TabBar
              tabs={LanguageOptions}
              initialTab={activeTab}
              onTabChange={handleTabChange}
              parentStyle={'service-tab-style'}
            />
          </div>
        )}
      </>

      {faqlistData &&
        faqlistData.map((data, index) => {
          return (
            <div className='faq_list mb-2'>
              <div className='faq_index'>
                <div>FAQ {`${index + 1}`}</div>
                <div className='d-flex justify-content-ceter'>
                  {activeShow && (
                    <div
                      className='pointer position-relative'
                      onClick={() => createToggler(data)}
                    >
                      <>
                        <img
                          className='delete_icon'
                          src={CloseBlackIcon}
                          alt='Close'
                        />
                        {activeId === data.faq_index_Id ? (
                          <FaqActionMenu
                            menuAction={menuActionObj}
                            onClickOutside={onClickOutside}
                            row={data}
                            index={index}
                          />
                        ) : null}
                      </>
                    </div>
                  )}
                </div>
              </div>
              <div className='faq_list_question_box'>
                <NewInput
                  label='Question'
                  id={data.faq_index_Id}
                  type='text'
                  placeholder='Enter a question'
                  name='question'
                  value={data.question}
                  onChange={OnhandleChange}
                  maxlength={150}
                  // errorText={error.question}
                />
                <div>
                  <NewQuillTextEditor
          label='Answer'
          onChange={(current, editor) => {
            handleEditorChange(
              'answer',
              current,
              data.faq_index_Id,
              editor
            );
          }}
          Tooltiptext='DO NOT COPY AND PASTE FROM WORD DOCUMENT OR OTHER EDITOR. Directly write here or copy from notepad only.'
          value={data.answer}
          quillRef={editorRef}
        />
                </div>
                {activeShow && (
                  <NumberInput
                    label='Sequence order'
                    id={data.faq_index_Id}
                    placeholder='Enter sequence order'
                    name='sequence_order'
                    value={data.sequence_order}
                    onChange={OnhandleChange}
                    // isFieldHidden={activeShow}
                  />
                )}
                {/* ---------- Toggle functionality------------------ */}

                {activeShow && (
                  <div className='d-block my-4'>
                    <span className='me-2 d-inline-block'>Status*</span>
                    <label className='d-inline-block mt-2'>
                      <div className='d-inline-flex align-items-center'>
                        <div className='d-inline-flex text-danger'>OFF</div>
                        <div className='d-inline-flex mx-2'>
                          <Toggle
                            checked={data.toggle}
                            icons={false}
                            onChange={(e) => {
                              e.preventDefault();
                              handleResourseToggle(data.faq_index_Id, index);
                            }}
                          />
                        </div>
                        <div className='d-inline-flex text-green'>ON</div>
                      </div>
                    </label>
                  </div>
                )}
              </div>
            </div>
          );
        })}

      <div className='blogsFormfaqbutton'>
        <div>
          {activeShow && (
            <Button
              className='addbutton mr2'
              onClick={addfaqSection}
              type='submit'
              name='Create FAQ'
            />
          )}
          <Button
            className='mt1'
            onClick={validateRequest}
            type='submit'
            name='Submit'
            isDisabled={
              isPublishButtonDisabled ||
              !faqlistData.length ||
              moduleName === ''
            }
          />
        </div>
      </div>
      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </>
  );
};

export default CreateFaq;
