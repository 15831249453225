import React from 'react';

// Pages
import BasePage from 'pages/BasePage'; 
import CreateOrderContainer from 'app/container/CustomerOrdersContainer/CreateOrderContainer';

const CreateCustomerOrders = () => {
  return (
    <BasePage>
      <CreateOrderContainer />
    </BasePage>
  );
};

export default CreateCustomerOrders;
