import { API_CONSTANTS } from 'app/constants/api-contants';
import * as dataAccess from 'utils/ajax';

export const getCouponAndOffer = async () => {
  return await dataAccess.get(API_CONSTANTS.COMMON_BASE_URL_COUPON);
};
export const addCouponAndOffer = async (incomingData) => {
  let payload = {
    data: {
      // publish: 1,
      ...incomingData,
    },
  };

  return await dataAccess.post(API_CONSTANTS.COMMON_BASE_URL_COUPON, payload);
};
export const updateCouponAndOffer = async (incomingData, id, lang) => {
  let url = '';
  if (lang === 'en') {
    url = `${API_CONSTANTS.COMMON_BASE_URL_COUPON}/${id}`;
  } else {
    url = `${API_CONSTANTS.COMMON_BASE_URL_COUPON_VERSION2}/i18n/${id}`;
  }

  let payload = {
    data: {
      ...incomingData,
    },
  };

  return await dataAccess.put(url, payload);
};

export const getCouponDataAndOfferById = async (id, lang) => {
  let url = '';
  if (lang === 'en') {
    url = `${API_CONSTANTS.COMMON_BASE_URL_COUPON}/${id}`;
  } else {
    url = `${API_CONSTANTS.COMMON_BASE_URL_COUPON_VERSION2}/${id}?lang=${lang}`;
  }

  let responseObj = await dataAccess.get(url);
  return responseObj;
};

export const getProductFamilyList = async (id) => {
  let url = `${API_CONSTANTS.GET_SERVICES_FAMILY_PLANS}`;
  let responseObj = await dataAccess.get(url);
  return responseObj;
};
