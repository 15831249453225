import {
  addOrUpdateCoursePageOrder,
  courseLandingPageOrder,
} from 'app/api/latest/Courses/courses';
import Message from 'app/utils/Message';
import { useEffect, useState } from 'react';
import Toast from 'utils/Toast';

const useCoursesOrdering = () => {
  const [pageLoading, setPageLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    getCoursesOrderingList();
  }, []);

  const getCoursesOrderingList = async () => {
    setPageLoading(true);
    let response = {};
    response = await courseLandingPageOrder();
    setPageLoading(false);

    if(response && response?.data){
      setSelectedItems(response?.data);
    }

    if (response?.response?.code === 200) {
    } else {
      Toast.error(
        response?.data ? response?.data : Message?.Error?.COMMON_MESSAGE
      );
    }
  };

  const handleSelectedItemsChange = async (tempData) => {

    const courseData = tempData.map((data, index) => {
      return {
        home_page_id: data.id,
        sorting_order: index + 1,
      };
    });
    const response =  await addOrUpdateCoursePageOrder(courseData);

    if (response.response.code === 200) {
      Toast.success('Courses Ordering Updated Successfully');
      getCoursesOrderingList();
    } else {
      Toast.error('Failed to save the ordering');
    }
  };

  return {
    pageLoading,
    selectedItems,
    setSelectedItems,
    handleSelectedItemsChange,
  };
};

export default useCoursesOrdering;
