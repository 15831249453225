import { getCouponAndOffer } from 'api/latest/LatestMedicineandDiagnosticCouponandOffer/medicineanddiagnosticCouponandoffer';
import { useEffect, useState } from 'react';
import Parsers from 'utils/Parsers';
import { useHistory, useLocation } from 'react-router-dom';
import Toast from 'utils/Toast';
import Message from 'app/utils/Message';

const useCommonCouponAndOffer = () => {
  const history = useHistory();
  const location = useLocation();
  const [values, setValues] = useState('');
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(location?.state?.goto || 1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [selectedProductTypes, setSelectedProductTypes] = useState([]);
  const [productTypes, setProductTypes] = useState([]);

  useEffect(() => {
    getContentDataHandler();
  }, []);

  useEffect(() => {
    filterData();
  }, [data, selectedProductTypes, values]);

  const getContentDataHandler = async () => {
    setPageLoading(true);
    let response = await getCouponAndOffer();
    setPageLoading(false);
    if (response?.response?.code === 200) {
      let dataArr = response.data;
      let updatedData = dataArr.map((item, index) => ({
        id: item.id,
        coupon_id: index + 1,
        coupon_code: item.code,
        coupon_name: item.heading,
        product_type: item.product_type,
        discount: item.discount,
        start_from: Parsers.ParseTableDateTime(item.start_from),
        end_at: Parsers.ParseTableDateTime(item.end_at),
        published_date: Parsers.ParseTableDateTime(item.published_date),
        updated_on: Parsers.ParseTableDateTime(item.updated_on),
        created_on: Parsers.ParseTableDateTime(item.created_on),
        description: item.details,
        status: item.status,
      }));

      setData(updatedData);
      setFilteredData(updatedData);
      
      const types = [...new Set(updatedData.map((item) => item.product_type))];
      setProductTypes(types.map(type => ({ value: type, label: type })));
    } else {
      Toast.error(
        response?.data ? response?.data : Message.Error.COMMON_MESSAGE
      );
    }
  };

  const filterData = () => {
    let filtered = data;

    if (selectedProductTypes.length > 0) {
      filtered = filtered.filter((item) =>
        selectedProductTypes.some((type) => type.value === item.product_type)
      );
    }

    if (values.trim() !== '') {
      filtered = filtered.filter(
        (item) =>
          item.coupon_name.toLowerCase().includes(values.toLowerCase()) ||
          item.coupon_code.toLowerCase().includes(values.toLowerCase())
      );
    }

    setFilteredData(filtered);
    setCurrentPage(1);
  };

  const handleProductTypeChange = (selectedOptions) => {
    setSelectedProductTypes(selectedOptions);
  };

  const paginationHandler = (page) => {
    setCurrentPage(page);
  };

  const changeHandler = (event) => {
    setDataPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const inputChangeHandler = (searchVal) => {
    setValues(searchVal.toLowerCase());
  };

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  const currentData = filteredData.slice(indexOfFirstData, indexOfLastData);

  const pageCount = Math.ceil(filteredData.length / dataPerPage);

  return {
    values,
    inputChangeHandler,
    changeHandler,
    pageLoading,
    currentData,
    filteredData,
    getContentDataHandler,
    currentPage,
    dataPerPage,
    pageCount,
    paginationHandler,
    history,
    selectedProductTypes,
    handleProductTypeChange,
    productTypes,
  };
};

export default useCommonCouponAndOffer;