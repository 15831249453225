import CoursesQuizContainer from "app/container/CoursesCreationContainer/CoursesQuiz";
import BasePage from "app/pages/BasePage";

const CoursesQuiz = () => {
    return (
        <BasePage>
            <CoursesQuizContainer />
        </BasePage>
    );
}

export default CoursesQuiz;