import React from 'react';

// Pages
import BasePage from 'app/pages/BasePage'; 
import CreateHomePageContainer from 'container/HomePageSections/CreateHomePages'
const CreateHomePage = () => {
  return (
    <BasePage>
      <CreateHomePageContainer />
    </BasePage>
  );
};

export default CreateHomePage;
