import { useHistory } from "react-router"
import './index.scss'

function CustomTable({ data, tableHeader, handleDelete,handleInputChangeHandler, handleEdit }) { 
     const history = useHistory()
     const handleNavigation = (items) =>{
          
          history.push({
               pathname: `/navigation/edit/${items.id}`,
               state: {
                    ...items,
               },
          });
     }
     return (
          <table>
               <tr>
                    {tableHeader.map((item,i) => {
                         return (
                              <th className='nowrap text-center'>{item.Header}</th>
                         )
                    })} 
               </tr>
               {data.map((items, index) => (<tr key={index}>
                    <td>{items.title}</td>
                    
                    <td>{items.navigation_point}</td>
                    <td>{items.url_type}</td>
                    <td>{items.url}</td> 
                    <td>
                    <div className="btn-group" role="group" aria-label="Basic outlined example">
                              <button
                                   type="button"
                                   className="btn btn-outline-primary"
                                   onClick={() => handleNavigation(items) }
                              >
                                   Edit
                              </button>
                              <button type="button"
                                   onClick={() => handleDelete(items) }
                                   className="btn btn-outline-primary">Delete</button>
                               
                         </div> 
                    </td>
                   
               </tr>))}
               {data.length ===0 &&  <tr><td colspan="10000">No data found</td> </tr>}
          </table>
     )
}

export default CustomTable