import React, { useState } from 'react';

// Pages
import BasePage from 'pages/BasePage';
import CreateWebinar from 'app/container/CreateWebinar';

const CreateWebinarPage = (props) => {
  return (
    <BasePage>
      <CreateWebinar />
    </BasePage>
  );
};

export default CreateWebinarPage;
