import { API_CONSTANTS } from 'app/constants/api-contants';
import * as dataAccess from 'utils/ajax';
import Parsers from 'utils/Parsers';
import Toast from 'app/utils/Toast';
import ApiParser from 'utils/ApiParser';


export const getAuditLogs = async (start, filterPayload) => {
  let payload = {
    data: {
      page: start ? start : 1,
      limit: 10,
      ...filterPayload,
    },
  };
    let responseObj = await dataAccess.post(API_CONSTANTS.GET_AUDIT_LOGS, payload);
    return responseObj
  };

  export const searchAuditLogs = async (searchTerm, start=0, filterPayload = {}) => {
    if (!searchTerm) {
      // Handle empty search term (e.g., use getAuditLogs)
      return getAuditLogs(start, filterPayload);
    }
    let payload = {
      data: {
        search_item: searchTerm,
        page: start ,
        limit: 10,
        ...filterPayload,
      },
    };
    try {
      const responseObj = await dataAccess.post(API_CONSTANTS.SEARCH_AUDIT_LOGS, payload); // Use separate endpoint if available
      return responseObj;
    } catch (error) {
      Toast.showError('Error searching audit logs:', error);
      throw error; // Re-throw error for further handling if necessary
    }
  };
  