import { useEffect, useState } from 'react';
import Parsers from 'utils/Parsers';
import './index.scss';

const FilePreview = ({ data }) => {
  let { attachmentFile } = data;
  const [fileType, setFileType] = useState();
  const [file, setFile] = useState();

  useEffect(() => {
    if (attachmentFile) {
      if (typeof attachmentFile === 'string') {
        setFileType(Parsers.GetURLExtension(attachmentFile));
        setFile(attachmentFile);
      } else if (attachmentFile instanceof File && attachmentFile.name) {
        setFileType(Parsers.GetURLExtension(attachmentFile.name));
        var reader = new FileReader();
        reader.onloadend = function () {
          // console.log('RESULT', reader.result);
          setFile(reader.result);
        };
        reader.readAsDataURL(attachmentFile);
      }
    }
  }, []);

  const renderContent = () => {
    if (attachmentFile) {
      return (
        <div>
          {file && (
            <img
              className={`${
                fileType === 'gif'
                  ? 'file_view_image file_view_gif'
                  : 'file_view_image'
              }`}
              src={file}
              alt={'title'}
            />
          )}
        </div>
      );
    }

    return null;
  };

  return <div>{renderContent()}</div>;
};

export default FilePreview;
