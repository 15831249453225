import React from 'react';

// Pages
import BasePage from 'pages/BasePage';
import LatestCategories from 'container/LatestCategories';

const DiagnosticCategoriesPage = () => {
  return (
    <BasePage>
      <LatestCategories type={'Diagnostic Categories'} />
    </BasePage>
  );
};

export default DiagnosticCategoriesPage;
