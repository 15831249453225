import { API_CONSTANTS } from 'constants/api-contants';
import ApiParser from 'utils/ApiParser';
import * as dataAccess from 'utils/ajax';

export const getModule = async (start, limit = 10) => {
  let payload = {
    data: {
      page: start ? start : 1,
      limit: limit,
    },
  };
  let responseObj = await dataAccess.post(API_CONSTANTS.GET_MODULE, payload);

  return ApiParser.parseFaqListing(responseObj, start);
};
export const getFaqListByModuleId = async (id, lang = '') => {
  let url = '';
  if (lang === 'en') {
    url = `${API_CONSTANTS.GET_DATAIL_MODULE}/${id}`;
  } else {
    url = `${API_CONSTANTS.GET_DATAIL_MODULE}/${id}?lang=${lang}`;
  }

  let responseObj = await dataAccess.get(url);

  return responseObj;
};

export const deleteModule = async (module_id) => {
  if (!module_id) {
    return;
  }

  let payload = {
    data: {
      module_id,
    },
  };
  return await dataAccess.del(API_CONSTANTS.DELETE_MODULE, payload);
};

export const updateModule = async (incomingData, id, lang) => {
  let payload = {
    data: {
      ...incomingData,
    },
  };
  let url = '';
  if (lang === 'en') {
    url = `${API_CONSTANTS.UPDATE_MODULE}/${id}`;
  } else {
    url = `${API_CONSTANTS.UPDATE_MODULE_VERSION3}`;
  }

  return await dataAccess.put(url, payload);
};

export const updateModuleStatus = async (incomingData, id) => {
  let payload = {
    data: {
      ...incomingData,
    },
  };
  let url = `${API_CONSTANTS.UPDATE_MODULE_STATUS}/${id}`;
  return await dataAccess.put(url, payload);
};

export const addModule = async (incomingData) => {
  let payload = {
    data: {
      ...incomingData,
    },
  };

  return await dataAccess.post(API_CONSTANTS.ADD_MODULE, payload);
};
export const deleteFaqAPI = async (faq_id) => {
  if (!faq_id) {
    return;
  }

  let payload = {
    data: {
      faq_id,
    },
  };
  return await dataAccess.del(API_CONSTANTS.DELETE_FAQ, payload);
};
