// Pages
import CreateRecommendedActionContainer from 'container/RecommendedAction/CreateRecommendedAction';
import BasePage from 'pages/BasePage';
const CreateRecommendedAction = () => {
  return (
    <BasePage>
      <CreateRecommendedActionContainer />
    </BasePage>
  );
};

export default CreateRecommendedAction;
