import BasePage from 'pages/BasePage';
import LcpSessionContainer from 'app/container/LcpContainer';

const LcpSession = () => {
  return (
    <>
      <BasePage>
        <LcpSessionContainer/>
      </BasePage>
    </>
  );
};

export default LcpSession;
