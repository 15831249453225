import "./index.scss";
import Search from "app/components/search";
import { useEffect, useState } from "react";
import Button from "app/components/button";
import CustomTable from './table';
import Pagination from "./pagination"; 
import { useHistory } from 'react-router-dom';
import ArticleModal from "app/components/ArticleModal";
import Toast from "app/utils/Toast";
import { getRecommendedList, deleteRecommendedStoryById, updateRecommendedStory } from "app/api/recommendedAction"; 
import moment from 'moment'; 
const options = [
    { value: 10, label: 10, },
    { value: 20, label: 20, },
    { value: 30, label: 30, },
    { value: 40, label: 40, },
    { value: 50, label: 50, },
]

const RecommendedAction = (tableHeader) => {
    const history = useHistory();

    const [values, setValues] = useState("");
    const [data, setData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [pageCount, setPageCount] = useState(1); 
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10);
    const [selectedItem, setSelectedItem] = useState("1");

    // const radioMenu = [
    //      {
    //           label: "Asthma",
    //           id: "1",
    //           value: "1"
    //      },
    //      {
    //           label: "COPD",
    //           id: "2",
    //           value: "2"
    //      },
    //      {
    //         label: "AR",
    //         id: "3",
    //         value: "3"
    //    },
    //    {
    //         label: "ILD",
    //         id: "4",
    //         value: "4"
    //    },
    //    {
    //         label: "Others",
    //         id: "5",
    //         value: "5"
    //     }
    // ]
    // const radioButtonSelected = (item) => {
    //      setSelectedItem(item.value)
    // }
    useEffect(() => {
        getContentDataHandler();
    }, []);

    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    let currentData = data.slice(indexOfFirstData, indexOfLastData)

    async function getContentDataHandler() {
        const dataArr = await getRecommendedList()
        let updatedData =[]
        dataArr.forEach((item) => {  
            updatedData.push(  {
                isDisabled:true,
                ...item
            })
            
       });
        setData(updatedData);
        setTotalData(updatedData)
        let count = Math.ceil(updatedData.length / dataPerPage);
        setPageCount(count)
    }

    function inputChangeHandler(searchVal) {
        if (searchVal.trim() === "") {
            setValues(searchVal.toLowerCase());
            // getContentDataHandler()
            setData(totalData)
            return;
        }
        const filteredData = []
        totalData.forEach((item) => {
            
            if (item.title.toLowerCase().includes(searchVal.toLowerCase())) {
                filteredData.push(item);
            }
        });
        setData(filteredData);
        setValues(searchVal);
    };

    function paginationHandler(page) {
        setCurrentPage(page);
    }
 
    const handleDelete = async(data) => { 
        await deleteRecommendedStoryById(data.id)
        getContentDataHandler()

   }
   const handleInputChangeHandler = (key,e, index)  => {
        let tempData = []
        data.forEach((val, i) => {
            if(index === i ){
            let temp = val
            val[key] =   Number(e.target.value) ?  Number(e.target.value) : ''
            tempData.push(temp)

            }else{
                tempData.push(val)

            }
        });
        setData(tempData) 

    }
    const handleEdit = async (item, key, index) => { 
        if(key ==='Edit'){
            let tempData = []
            data.forEach((val, i) => {
                if(index === i ){
                let temp = val
                val['isDisabled'] = false
                tempData.push(temp)
                }else{
                    tempData.push(val)
                }
            });
            setData(tempData)
        }else {
            let {asthma_order, copd_order, ild_order, ar_order, other_order,title, id, url_type, expire_date,  url,thumbnail_image} = item 
            let payload ={
                story_id:id,
                asthma_order:asthma_order?asthma_order:null, 
                copd_order:copd_order?copd_order:null, 
                ild_order:ild_order?ild_order:null, 
                ar_order:ar_order?ar_order:null, 
                other_order:other_order?other_order:null,
                title,   
                url_type,
                url,
                expire_date:moment(expire_date).format('YYYY-MM-DD HH:mm:ss'),
                image1:thumbnail_image[0],
                image2:thumbnail_image[1],
                image3:thumbnail_image[2],
                image4:thumbnail_image[3],
                image5:thumbnail_image[4],
                image6:thumbnail_image[5],
                image7:thumbnail_image[6], 
            } 
            
           const  responseObj = await updateRecommendedStory(payload)
            
        
            if (
              responseObj &&
              responseObj.response &&
              responseObj.response.code === 200
          ) {
              Toast.success(responseObj.response.alert[0].message);
              let tempData = []
              data.forEach((val, i) => {
                  if(index === i ){
                  let temp = val
                  val['isDisabled'] = true
                  tempData.push(temp)
                  }else{
                      tempData.push(val)
                  }
              });
              setData(tempData)
            //   getContentDataHandler()
          } else {
              Toast.error(responseObj.data && responseObj.data.msg?responseObj.data.msg:responseObj.response.alert[0].message);
    
         }
           
        }
       
       }
    return (<> {showFilter && (
        <ArticleModal
            modalCloseClicked={() => setShowFilter(false)}
            filterApplyClicked={() => { }}
            ResetFilterCicked={() => { }}
            handleChange={() => { }}
            values={""}
            error={""}
        />
    )}
        <div className="cms-content__container">
            <h4>Recommended Action</h4>
            {/* <div className="content-selector">
               <h2>Select Cohort</h2>
               {radioMenu.map((item, key) => (
                    <RadioButton
                         id={item.id}
                         label={item.label}
                         isChecked={selectedItem == item.value ? true : false}
                         value={item.value}
                         onSelect={() => { radioButtonSelected(item) }}
                         name={item.label}
                    />
               ))}
            </div> */}
            <div className="content-container__actionBox">
                <div className="content-container__filterSearch">
                    <Search
                        value={values}
                        placeholder="Search"
                        className={""}
                        onChange={inputChangeHandler}
                    /> 
                </div>
                <Button 
                onClick={() => history.push('/recommended-action/create')} 
                className="content-container__filter-btn">Add Action</Button>
            </div>
            {/* <div className="content-container__perPage">
                Show
                <SelectComp
                    options={options}
                    onChangeHandler={changeHandler}
                />
                entries
            </div> */}

            <div className="content-container__table">
                <CustomTable
                    data={data}
                    tableHeader={tableHeader.tableHeader}
                    handleDelete={handleDelete}
                    handleInputChangeHandler={handleInputChangeHandler}
                    handleEdit={handleEdit}
                />
            </div>

            <div className="content-container__pagination">
                <Pagination
                    pages={pageCount}
                    onChange={paginationHandler}
                />
            </div>
        </div>
    </>

    );
}

export default RecommendedAction