import "../index.scss";
import Search from "app/components/search";
import { useEffect, useState } from "react";
import Button from "app/components/button";
import CustomTable from './table';
import Pagination from "../pagination";
import { useHistory } from 'react-router-dom';
import { getUserJourneyCouponList, deleteCouponById } from "app/api/user-journey";
import SelectComp from '../select-tag'
const options = [
    { value: 10, label: 10, },
    { value: 20, label: 20, },
    { value: 30, label: 30, },
    { value: 40, label: 40, },
    { value: 50, label: 50, },
]

const CouponList = (tableHeader) => {
    const history = useHistory();

    const [values, setValues] = useState("");
    const [data, setData] = useState([]);
    const [totalData, setTotalData] = useState([]);
    const [pageCount, setPageCount] = useState(1); 
    const [currentPage, setCurrentPage] = useState(1);
    const [dataPerPage, setDataPerPage] = useState(10);

    useEffect(() => {
        getContentDataHandler();
    }, []);

    const indexOfLastData = currentPage * dataPerPage;
    const indexOfFirstData = indexOfLastData - dataPerPage;
    let currentData = data.slice(indexOfFirstData, indexOfLastData)

    async function getContentDataHandler() {
        const dataArr = await getUserJourneyCouponList()
        setTotalData(dataArr)
        setData(dataArr);
        let count = Math.ceil(dataArr.length / dataPerPage);
        setPageCount(count)
    }

    function changeHandler(event) {
        const pageLength = event.target.value
        setDataPerPage(pageLength);
        let count = Math.ceil(data.length / pageLength);
        setPageCount(count)
    }

    function inputChangeHandler(searchVal) {
        const filteredData = []
        totalData.forEach((item) => { 
            let tempvalue = searchVal.toLowerCase()
            if (item.coupon_code.toLowerCase().includes(tempvalue)) {
                filteredData.push(item);
            }
        });
        setData(filteredData);
        setValues(searchVal);
    };

    function paginationHandler(page) {
        setCurrentPage(page);
    }

    const handleDelete = async(data) => { 
        await deleteCouponById(data.id)
        getContentDataHandler()

   }
 
    return (<> 
        <div className="cms-content__container">
            <h4>User Journey/ Coupons</h4>
            <div className="content-container__actionBox">
                <div className="content-container__filterSearch">
                    <Search
                        value={values}
                        placeholder="Search"
                        className={""}
                        onChange={inputChangeHandler}
                    /> 
                </div>
                <Button 
                onClick={() => history.push(`/userjourney/coupon/create`)} 
                className="content-container__filter-btn">Create Coupon</Button>
            </div>
            <div className="content-container__perPage">
                Show
                <SelectComp
                    options={options}
                    onChangeHandler={changeHandler}
                />
                entries
            </div>

            <div className="content-container__table">
                <CustomTable
                    data={currentData}
                    tableHeader={tableHeader.tableHeader}
                    handleDelete={handleDelete}
                />
            </div>

            <div className="content-container__pagination">
                <Pagination
                    pages={pageCount}
                    onChange={paginationHandler}
                />
            </div>
        </div>
    </>

    );
}

export default CouponList