import React, { useEffect, useRef } from 'react';
// import { useHistory } from 'react-router-dom';
import './index.scss';

const ActionMenuUpload = ({
  noshowThumbnail = false,
  onClickOutside,
  onDelete,
  onEdit,
  onActionTriggered,
}) => {
  // const history = useHistory();
  const dropdownWrapperRef = useRef(null);

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', (e) => {
      handleClickOutside(e);
    });
    document.addEventListener('keydown', (e) => {
      handleHideDropdown(e);
    });
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', (e) => {
        handleClickOutside(e);
      });
      document.removeEventListener('click', (e) => {
        handleClickOutside(e);
      });
    };
  }, [dropdownWrapperRef]);

  const handleClickOutside = (event) => {
    if (
      dropdownWrapperRef.current &&
      !dropdownWrapperRef.current.contains(event.target)
    ) {
      onClickOutside();
    }
  };

  const handleHideDropdown = (event) => {
    if (event.key === 'Escape') {
      onClickOutside();
    }
  };

  const onActionButtonClicked = (type) => {
    onActionTriggered && onActionTriggered(type);
  };

  return (
    <div ref={dropdownWrapperRef} className='ActionUpload'>
      {!noshowThumbnail && (
        <p
          onClick={() => onActionButtonClicked('image')}
          className='ActionUpload-text'
        >
          Image
        </p>
      )}

      <p
        onClick={() => onActionButtonClicked('video')}
        className='ActionUpload-text'
      >
        Video
      </p>
    </div>
  );
};

export default ActionMenuUpload;
