import { getTagsApi } from 'app/api/createContentAPI';
import RadioButton from 'app/shared/components/RadioButton';
import MultiSelectDropdown from 'components/MultiSelectDropdown';
import NewInput from 'components/NewInput';
import Button from 'components/button';
import Incorrect from 'images/incorrect.png';
import { useEffect, useState } from 'react';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const ContentFilterModal = ({
  modalCloseClicked,
  updateFilters,
  resetFilter,
  filters,
  error,
}) => {
  // const { handleChange, values, error, setValues } = useContentForm(validate);
  const [options, setOptions] = useState('');
  const dropdownItems = [
    {
      name: 'active',
      value: 'ACTIVE',
    },
    {
      name: 'Inactive',
      value: 'INACTIVE',
    },
  ];
  const [state, setState] = useState({
    title: '',
    tags: [],
    status: '',
    content_type: '',
  });

  useEffect(() => {
    if (filters) {
      let temporaryFilter = {};
      let filterArray = Object.keys(filters);
      for (let index in filterArray) {
        temporaryFilter[filterArray[index]] = filters[filterArray[index]];
      }
      setState({ ...state, ...temporaryFilter });
    }
  }, []);

  const radioMenu = [
    {
      label: 'External Article',
      id: 'extArtcl',
      value: 'EXTERNAL_ARTICLE',
    },
    {
      label: 'Self Hosted Article',
      id: 'SELF_HOSTED_ARTICLE',
      value: 'SELF_HOSTED_ARTICLE',
    },
    {
      label: 'Video',
      id: 'video',
      value: 'VIDEO',
    },
    {
      label: 'Shorts',
      id: 'shorts',
      value: 'SHORTS',
    },
    {
      label: 'Quiz',
      id: 'quiz',
      value: 'QUIZ',
    },
  ];
  const handleApplyFilter = (e) => {
    updateFilters(state);
  };
  const handleResetFilter = (e) => {
    setState({
      title: '',
      tags: [],
      status: '',
      content_type: '',
    });

    resetFilter();
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const getTags = async () => {
    const response = await getTagsApi();
    setOptions(response);
  };

  useEffect(() => {
    getTags();
  }, []);

  return (
    <Modal
      isOpen={true}
      onRequestClose={modalCloseClicked}
      style={customStyles}
      className='ArticleModal'
      overlayClassName='ArticleModal__Overlay'
    >
      <div className='Filters'>
        <div className='Filters_title'>Filter</div>
        <img
          onClick={modalCloseClicked}
          src={Incorrect}
          alt='Go to Blogs'
          width='29'
          className='Wrong-Icon'
          height='29'
        />
      </div>
      <div className='wrapped-component'>
        <NewInput
          label='Title'
          placeholder='Enter Title'
          name='title'
          value={state.title}
          onChange={handleChange}
        />

        {options && (
          <MultiSelectDropdown
            label='Select Tag'
            name='tags'
            value={state.tags}
            onChange={handleChange}
            options={options}
          />
        )}
        <div className='userFormArea'>
          <div className='dropdown-area'>
            <label>Status</label>
            {/* <Dropdown
                            name='Select'
                            value={state.status?.name}
                            options={ }
                            [{
                            name: "active",
                        value: "ACTIVE"
                            }, {
                            name: "Inactive",
                        value: "INACTIVE"
                            }]
                            handleChange={(e) => {
                                setState({ ...state, status: e.target.value })
                            }}
                        /> */}
            <select
              name='status'
              className='custom-select'
              onChange={(e) => {
                setState({ ...state, status: e.target.value });
              }}
            >
              <option value=''>Select</option>
              {dropdownItems?.map((item, indx) => (
                <option
                  key={indx}
                  value={item.value}
                  selected={state?.status === item.value ? true : false}
                >
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className='content-selector'>
          <label className='head-label'>Select Content Type</label>
          {radioMenu.map((item, key) => (
            <RadioButton
              id={item.id}
              label={item.label}
              isChecked={state.content_type === item.value ? true : false}
              value={item.value}
              onSelect={() => {
                setState({ ...state, content_type: item.value });
              }}
              name={item.label}
            />
          ))}
        </div>
      </div>
      <div className='mt1'>
        <Button onClick={handleApplyFilter} type='submit' name='Apply filter' />
        <Button
          className='ml1'
          onClick={handleResetFilter}
          type='submit'
          name='Reset filter'
        />
      </div>
    </Modal>
  );
};

export default ContentFilterModal;
