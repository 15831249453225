import { useHistory } from "react-router"
import './index.scss'
import moment from 'moment';

function CustomTable({ data, tableHeader, handleDelete, handlePublishUnpublish }) { 
     const history = useHistory()

     //handle navigation
     const handleNavigation = (item) =>{
          
          history.push({
               pathname: `/static-widgets/edit/${item.widget_id}`,
               state: {
                    ...item,
                  },
          });
     }
     return (
          <table>
               <tr>
                    {tableHeader.map((item,i) => {
                         return (
                              <th className='nowrap text-center'>{item.Header}</th>
                         )
                    })} 
               </tr>
               {data.map((items, indx) => (<tr key={indx}>
                    <td>{items.widget_name}</td> 
                    <td>{items.created_on}</td> 
                    <td>{items.updated_on}</td>  
                    <td>{items.widget_id}</td>
                    <td>{items.mapped_section?items.mapped_section:'-' }</td>
                    <td>{items.status?items.status:'-'}</td>
                     
                    <td>
                         <div className="btn-group" role="group" aria-label="Basic outlined example">
                              {items.widget_name === 'Top Strip Banner' ?
                              <button
                                   type="button"
                                   className="btn btn-outline-primary"
                                   onClick={() => handleNavigation(items) }
                              >
                                   Edit
                              </button>
                              : "NA"
                              }
                               
                         </div> 
                         
                    </td>
               </tr>))}
               {data.length ===0 &&  <tr><td colspan="10000">No data found</td> </tr>}
          </table>
     )
}

export default CustomTable