import './index.scss';
import Search from 'app/components/search';
import { useEffect, useState } from 'react';
import Button from 'app/components/button';
import CustomTable from './table';
import Pagination from './pagination';
import { useHistory } from 'react-router-dom';
import Toast from 'app/utils/Toast';
import PageLoader from 'app/components/PageLoader';
import SelectComp from '../select-tag';
import { APP_CONSTANTS } from 'app/constants/app-constants';
import {
  getCoursesList,
  updateCourseStatus,
} from 'app/api/latest/Courses/courses';

const options = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
  { value: 40, label: 40 },
  { value: 50, label: 50 },
];

const CoursesList = (tableHeader) => {
  const history = useHistory();

  const [values, setValues] = useState('');
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [loader, setLoader] = useState(false);

  const [statusData, setStatusData] = useState([]);

  useEffect(() => {
    getContentDataHandler();
  }, []);

  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  let currentData = data.slice(indexOfFirstData, indexOfLastData);

  async function getContentDataHandler() {
    setLoader(true);
    const response = await getCoursesList();
    const dataArr = response?.data;
    if (dataArr && dataArr) {
      setData(dataArr);
      setTotalData(dataArr);
      let count = Math.ceil(dataArr.length / dataPerPage);
      setPageCount(count);
    }
    setLoader(false);
  }

  function paginationHandler(page) {
    setCurrentPage(page);
  }

  const handleUnpublish = async (data, isChecked) => {
    let text = `Are you sure, want to ${
      data.status === 'ACTIVE' ? 'Unpublish' : 'Publish'
    }`;
    if (window.confirm(text) == true) {
      if (data) {
        setLoader(true);
        let payload = {
          publish_status: !isChecked ? false : true,
        };
        let responseObj = await updateCourseStatus(payload, data.id);

        if (
          responseObj &&
          responseObj.response &&
          responseObj.response.code === 200
        ) {
          Toast.success(responseObj.response.alert[0].message);
          getContentDataHandler();
        } else {
          setLoader(false);
          Toast.error(
            responseObj.data
              ? responseObj.data.msg
              : responseObj.response.alert[0].message
              ? responseObj.response.alert[0].message
              : ''
          );
        }
        setLoader(false);
      }
    }
  };

  function changeHandler(event) {
    const pageLength = event.target.value;
    setDataPerPage(pageLength);
    let count = Math.ceil(data.length / pageLength);
    setPageCount(count);
  }

  const handleEdit = async (item, key, index) => {
    if (key === 'Edit') {
      let tempData = [];
      data.forEach((val, i) => {
        if (index === i) {
          let temp = val;
          val['isDisabled'] = false;
          tempData.push(temp);
        } else {
          tempData.push(val);
        }
      });
      setData(tempData);
    }
  };

  let pageMeta = {
    total: data,
    pageSize: Number(dataPerPage),
    page: currentPage,
    pageCount: pageCount,
  };

  return (
    <>
      <div className='cms-content__container mt-3'>
        <div className='content-container__actionBox'>
          <h4>Courses Creation</h4>
          <Button
            onClick={() => history.push(APP_CONSTANTS.COURSES_CREATE)}
            className='content-container__filter-btn'
          >
            Add Course
          </Button>
        </div>
        <div className='content-container__perPage'>
          Show
          <SelectComp options={options} onChangeHandler={changeHandler} />
          entries
        </div>

        <div className='content-container__table'>
          <CustomTable
            data={currentData}
            tableHeader={tableHeader.tableHeader}
            handleUnpublish={handleUnpublish}
            handleEdit={handleEdit}
            currentPage={currentPage}
          />
        </div>

        <div className='content-container__pagination'>
          {currentData && currentData.length > 0 && (
            <Pagination
              pageMeta={pageMeta}
              pages={pageCount}
              onChange={paginationHandler}
            />
          )}
        </div>
      </div>
      {loader && <PageLoader pageLoading={loader} />}
    </>
  );
};

export default CoursesList;
