import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Decimal from 'decimal.js';

import countryListOptions from 'JsonData/countryListOptions';
import {
  scansPerDayOptions,
  emergencyScansPerDayOptions,
  billingFrequencyOptions,
  planDurationOptions,
} from 'JsonData/family-plan-dropdowns-list';

// Components
import HeaderWithBackButton from 'components/HeaderWithBackButton';
import NewInput from 'components/NewInput';
import Button from 'components/button';
import PageLoader from 'components/PageLoader';
import Dropdown from 'app/shared/components/Dropdown';
import DropdownOptions from 'utils/DropdownOptions';

// API
import {
  getServicesFamilyPlans,
  addFamilyPlans,
  updateFamilyPlans,
  getFamilyPlansDetails,
} from 'api/latest/FamilyPlans/familyPlans';

// Constants
import { APP_CONSTANTS } from 'constants/app-constants';
import Calender from 'app/components/Calender';

// Utils
import Toggle from 'react-toggle';
import Toast from 'utils/Toast';
import ValidationMessage from 'utils/ValidationMessage';
import { Messages } from 'app/constants/messages';
import moment from 'moment';

const CreateFamilyPlans = () => {
  const history = useHistory();
  const params = useParams();
  const [pageLoading, setPageLoading] = useState(false);
  const [toggleStatus, setToggleStatus] = useState(false);
  const [paidToggleStatus, setPaidToggleStatus] = useState(false);
  const [emergencyScansToggleStatus, setEmergencyScansToggleStatus] =
    useState(false);
  const [recommendToggleStatus, setRecommendToggleStatus] = useState(false);

  const [productFamilyOptions, setProductFamilyOptions] = useState([]);
  const [productFamilySelectedOption, setProductFamilySelectedOption] =
    useState('');

  const [scansPerDaySelectedOption, setScansPerDaySelectedOption] =
    useState(null);
  const [
    emergencyScansPerDaySelectedOption,
    setEmergencyScansPerDaySelectedOption,
  ] = useState(null);
  const [billingFrequencySelectedOption, setBillingFrequencySelectedOption] =
    useState('MONTHLY');
  const [countrySelectedOption, setCountrySelectedOption] = useState('');
  const [planDurationSelectedOption, setPlanDurationSelectedOption] =
    useState('DAY');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [createFamilyForm, setCreateFamilyForm] = useState({
    plan_name: '',
    internal_plan_name: '',
    description: '',
    billing_frequency: '',
    display_pricing: '',
    discount: '',
    final_pricing: '',
    monthly_pricing: null,
    plan_duration: '',
    number_of_days: '',
    plan_start_from: '',
    plan_end_at: '',
    user_experience: '',
    service_id:'',
    service_value:''
  });

  const getProductFamilyDetailsById = async (id) => {
    const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
    setPageLoading(true);
    let responseObj = await getFamilyPlansDetails(id);
    setProductFamilySelectedOption(responseObj?.product_family);
    setScansPerDaySelectedOption(responseObj?.scans_per_day);
    setEmergencyScansToggleStatus(responseObj?.emergency_scans_status);
    setEmergencyScansPerDaySelectedOption(responseObj?.emergency_scans_per_day);
    setPaidToggleStatus(responseObj?.paid);
    setBillingFrequencySelectedOption(responseObj?.billing_frequency);
    setCountrySelectedOption(responseObj?.country);
    setRecommendToggleStatus(responseObj?.show_as_recommended_plan);
    setPlanDurationSelectedOption(responseObj?.plan_duration);
    setToggleStatus(responseObj?.status === 'ACTIVE' ? true : false);
    setCreateFamilyForm({
      plan_name: responseObj?.plan_name,
      internal_plan_name: responseObj?.internal_plan_name,
      description: responseObj?.description,
      billing_frequency: responseObj?.billing_frequency,
      display_pricing: responseObj?.display_pricing,
      discount: responseObj?.discount,
      final_pricing: responseObj?.final_pricing,
      monthly_pricing: responseObj?.monthly_pricing,
      number_of_days: responseObj?.number_of_days,
      plan_start_from: moment(responseObj?.plan_start_from).format(
        dateTimeFormat
      ),
      plan_end_at: moment(responseObj?.plan_end_at).format(dateTimeFormat),
      status: responseObj?.status,
      user_experience: responseObj?.user_experience,
    });
    // updateValue(responsedata);
    if (responseObj?.plan_start_from) {
      setStartDate(moment(responseObj?.plan_start_from).format(dateTimeFormat));
    }
    if (responseObj?.plan_end_at) {
      setEndDate(moment(responseObj?.plan_end_at).format(dateTimeFormat));
    }
    setPageLoading(false);
  };

  const productFamilyOptionsData = async () => {
    const response = await getServicesFamilyPlans();
    setProductFamilyOptions(response.data);
  };

  useEffect(() => {
    productFamilyOptionsData();
  }, []);

  useEffect(() => {
    if (params?.id) {
      getProductFamilyDetailsById(params?.id);
    }
  }, [params?.id]);

  const handleToggleChange = () => {
    setToggleStatus(!toggleStatus);
  };

  const handlePaidToggleChange = () => {
    setPaidToggleStatus(!paidToggleStatus);
  };

  const handleEmergencyToggleChange = () => {
    setEmergencyScansToggleStatus(!emergencyScansToggleStatus);
  };

  const handleRecommendToggleChange = () => {
    setRecommendToggleStatus(!recommendToggleStatus);
  };

  const addNewFamilyPlan = async () => {
    const selectedProductFamily = productFamilyOptions.find(
      (option) => option.value === productFamilySelectedOption
    );
    const selectedProductFamilyId = selectedProductFamily?.id;
    const selectedProductFamilyServiceId = selectedProductFamily?.service_id;
    const selectedProductFamilyServiceValue=selectedProductFamily?.service_value;
    const selectedScansPerDay = scansPerDayOptions.find(
      (option) => option.value === scansPerDaySelectedOption
    );
    const selectedScansPerDayId = selectedScansPerDay?.id;

    const selectedEmergencyScansPerDay = emergencyScansPerDayOptions.find(
      (option) => option.value === emergencyScansPerDaySelectedOption
    );
    const selectedEmergencyScansPerDayId = emergencyScansToggleStatus
      ? selectedEmergencyScansPerDay?.id
      : null;

    const selectedBillingFrequency = billingFrequencyOptions.find(
      (option) => option.value === billingFrequencySelectedOption
    );
    const selectedBillingFrequencyName = selectedBillingFrequency?.value;

    const selectedPlan = planDurationOptions.find(
      (option) => option.value === planDurationSelectedOption
    );
    const selectedPlanType = selectedPlan?.value;

    let commonData = {
      product_family: selectedProductFamilyId,
      plan_name: createFamilyForm?.plan_name,
      internal_plan_name: createFamilyForm?.plan_name,
      description: createFamilyForm?.description,
      scans_per_day: selectedScansPerDayId,
      emergency_scans_status: emergencyScansToggleStatus,
      emergency_scans_per_day: emergencyScansToggleStatus
        ? selectedEmergencyScansPerDayId
        : emergencyScansPerDaySelectedOption,
      paid: paidToggleStatus,
      status: toggleStatus ? 'ACTIVE' : 'INACTIVE',
      user_experience: createFamilyForm?.user_experience,
      service_id:selectedProductFamilyServiceId,
      service_value:selectedProductFamilyServiceValue
    };

    let payload;

    if (paidToggleStatus) {
      const selectedCountry = countryListOptions.find(
        (option) => option.value === countrySelectedOption
      );
      const selectedCountryName = selectedCountry?.value;
      payload = {
        ...commonData,
        billing_frequency: selectedBillingFrequencyName,
        country: selectedCountryName,
        display_pricing: Number(createFamilyForm?.display_pricing),
        discount: Number(createFamilyForm?.discount),
        final_pricing: Number(createFamilyForm?.final_pricing),
        monthly_pricing: Number(createFamilyForm?.monthly_pricing),
        show_as_recommended_plan: recommendToggleStatus,
        number_of_days: Number(createFamilyForm?.number_of_days) ? Number(createFamilyForm?.number_of_days) : null
      };
    } else {
      if (selectedPlanType === 'CUSTOM_DATE_RANGE') {
        // Free plan with custom range
        payload = {
          ...commonData,
          plan_duration: selectedPlanType,
          plan_start_from: createFamilyForm?.plan_start_from,
          plan_end_at: createFamilyForm?.plan_end_at,
        };
      } else {
        // Free plan with number_of_days
        payload = {
          ...commonData,
          plan_duration: selectedPlanType,
          number_of_days: Number(createFamilyForm?.number_of_days),
        };
      }
    }

    setPageLoading(true);
    const responseObj = await addFamilyPlans(payload);
    setPageLoading(false);
    if (responseObj?.response?.code === 201) {
      history.goBack();
      Toast.success('The family has been created successfully');
    } else {
      Toast.error(
        responseObj?.data ? responseObj?.data : Messages?.Error?.COMMON_MESSAGE
      );
    }
  };

  const handleFormInputChange = (e) => {
    e.preventDefault();
    const { value, name } = e.target;
    setCreateFamilyForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    let displayPricing = new Decimal(createFamilyForm?.display_pricing || 0);
    let discount = new Decimal(createFamilyForm?.discount || 0);
    let finalPricing = displayPricing?.dividedBy(100).times(100 - discount);

    setCreateFamilyForm((prevState) => ({
      ...prevState,
      final_pricing: finalPricing.toFixed(2),
    }));

    // Calculate the monthly pricing by dividing the final pricing by 12
    let newfinalPricing = new Decimal(createFamilyForm?.final_pricing || 0);

    let monthlyPricing = null;
    if (billingFrequencySelectedOption === 'QUARTERLY') {
      monthlyPricing = newfinalPricing?.dividedBy(3);
    }
    if (billingFrequencySelectedOption === 'ANNUALLY') {
      monthlyPricing = newfinalPricing?.dividedBy(12);
    }
    setCreateFamilyForm((prevState) => ({
      ...prevState,
      monthly_pricing: monthlyPricing?.toFixed(2),
    }));
  }, [
    billingFrequencySelectedOption,
    createFamilyForm?.display_pricing,
    createFamilyForm?.discount,
    createFamilyForm?.final_pricing,
  ]);
  const BreathingExercise = productFamilyOptions.find(ele => ele.value === productFamilySelectedOption);
  const handleCreateFamilyPlan = async (e) => {
    e.preventDefault();
    let {
      plan_name,
      display_pricing,
      discount,
      final_pricing,
      monthly_pricing,
      number_of_days,
      internal_plan_name,
      description,
      user_experience,
    } = createFamilyForm;
    if (productFamilySelectedOption === '') {
      Toast.error(ValidationMessage.PRODUCT_FAMILY);
      setPageLoading(false);
      return;
    }
    if (!plan_name) {
      Toast.error(ValidationMessage.PLAN_NAME);
      setPageLoading(false);
      return;
    }
    if (plan_name.length > 35) {
      Toast.error(ValidationMessage.PLAN_NAME_LENGTH);
      setPageLoading(false);
      return;
    }
    if (internal_plan_name !== '' && internal_plan_name.length > 45) {
      Toast.error(ValidationMessage.INTERNAL_PLAN_NAME_LENGTH);
      setPageLoading(false);
      return;
    }
    if (description !== '' && description.length > 45) {
      Toast.error(ValidationMessage.DESCRIPTION_FAMILY_PLAN);
      setPageLoading(false);
      return;
    }

    if (!scansPerDaySelectedOption && BreathingExercise.service_value !== "breathing_exercise") {
      Toast.error(ValidationMessage.SCANS_PER_DAY);
      setPageLoading(false);
      return;
    }
    if (emergencyScansToggleStatus && !emergencyScansPerDaySelectedOption) {
      Toast.error(ValidationMessage.EMERGENCY_SCANS_PER_DAY);
      setPageLoading(false);
      return;
    }
    if (paidToggleStatus && !billingFrequencySelectedOption) {
      Toast.error(ValidationMessage.BILLING_FREQUENCY);
      setPageLoading(false);
      return;
    }
    if (paidToggleStatus && countrySelectedOption === null) {
      Toast.error(ValidationMessage.COUNTRY);
      setPageLoading(false);
      return;
    }
    if (paidToggleStatus && !display_pricing) {
      Toast.error(ValidationMessage.ADD_DISPLAY_PRICING);
      setPageLoading(false);
      return;
    }
    if (paidToggleStatus && !discount) {
      Toast.error(ValidationMessage.ADD_DISCOUNT_PRICING);
      setPageLoading(false);
      return;
    }
    if (paidToggleStatus && !final_pricing) {
      Toast.error(ValidationMessage.ADD_FINAL_PRICING);
      setPageLoading(false);
      return;
    }
    if (
      paidToggleStatus &&
      billingFrequencySelectedOption !== 'MONTHLY' &&  billingFrequencySelectedOption !== 'DAYS' &&
      !monthly_pricing
    ) {
      Toast.error(ValidationMessage.ADD_MONTHLY_PRICING);
      setPageLoading(false);
      return;
    }
    if (
      paidToggleStatus &&
      billingFrequencySelectedOption === 'DAYS' &&
      (!number_of_days || number_of_days === 0)
    ) {
      Toast.error(ValidationMessage.NUMBER_OF_DAYS);
      setPageLoading(false);
      return;
    }
    if (
      paidToggleStatus &&
      billingFrequencySelectedOption === 'DAYS' &&
      (number_of_days < 1 || number_of_days > 30)
    ) {
      Toast.error("Please enter number of days between 1 to 30");
      setPageLoading(false);
      return;
    }
    if (!paidToggleStatus && !planDurationSelectedOption) {
      Toast.error(ValidationMessage.PLAN_DURATION);
      setPageLoading(false);
      return;
    }
    if (
      !paidToggleStatus &&
      planDurationSelectedOption === 'DAY' &&
      (!number_of_days || number_of_days === 0)
    ) {
      Toast.error(ValidationMessage.NUMBER_OF_DAYS);
      setPageLoading(false);
      return;
    }
    if (!user_experience && BreathingExercise.service_value !== "breathing_exercise") {
      Toast.error(ValidationMessage.USER_SCAN_EXXPERIENCE);
      setPageLoading(false);
      return;
    }

    const selectedProductFamily = productFamilyOptions.find(
      (option) => option.value === productFamilySelectedOption
    );
    const selectedProductFamilyId = selectedProductFamily?.id;
    const selectedProductFamilyServiceId = selectedProductFamily?.service_id;
    const selectedProductFamilyServiceValue=selectedProductFamily?.service_value;
    const selectedScansPerDay = scansPerDayOptions.find(
      (option) => Number(option.value) === Number(scansPerDaySelectedOption)
    );
    const selectedScansPerDayId = selectedScansPerDay?.id;

    const selectedEmergencyScansPerDay = emergencyScansPerDayOptions.find(
      (option) =>
        Number(option.value) === Number(emergencyScansPerDaySelectedOption)
    );
    const selectedEmergencyScansPerDayId = emergencyScansToggleStatus
      ? selectedEmergencyScansPerDay?.id
      : null;

    const selectedPlan = planDurationOptions.find(
      (option) => option.value === planDurationSelectedOption
    );
    const selectedPlanType = selectedPlan?.value;

    let commonData = {
      product_family: selectedProductFamilyId,
      plan_name: createFamilyForm?.plan_name,
      internal_plan_name: createFamilyForm?.plan_name,
      description: createFamilyForm?.description,
      scans_per_day: selectedScansPerDayId,
      emergency_scans_status: emergencyScansToggleStatus,
      emergency_scans_per_day: emergencyScansToggleStatus
        ? selectedEmergencyScansPerDayId
        : emergencyScansPerDaySelectedOption,
      paid: paidToggleStatus,
      status: toggleStatus ? 'ACTIVE' : 'INACTIVE',
      user_experience: createFamilyForm?.user_experience,
      service_id:selectedProductFamilyServiceId,
      service_value:selectedProductFamilyServiceValue
    };

    let payload;
    if (paidToggleStatus) {
      const selectedCountry = countryListOptions.find(
        (option) => option.value === countrySelectedOption
      );
      const selectedCountryName = selectedCountry?.value;
      const selectedBillingFrequency = billingFrequencyOptions.find(
        (option) => option.value === billingFrequencySelectedOption
      );
      const selectedBillingFrequencyName = selectedBillingFrequency.value;
      payload = {
        ...commonData,
        billing_frequency: selectedBillingFrequencyName,
        country: selectedCountryName,
        display_pricing: Number(createFamilyForm?.display_pricing),
        discount: Number(createFamilyForm?.discount),
        final_pricing: Number(createFamilyForm?.final_pricing),
        monthly_pricing: Number(createFamilyForm?.monthly_pricing),
        show_as_recommended_plan: recommendToggleStatus,
        number_of_days: Number(createFamilyForm?.number_of_days)?Number(createFamilyForm?.number_of_days):null,
      };
    } else {
      if (selectedPlanType === 'CUSTOM_DATE_RANGE') {
        // Free plan with custom range
        payload = {
          ...commonData,
          plan_duration: selectedPlanType,
          plan_start_from: createFamilyForm?.plan_start_from,
          plan_end_at: createFamilyForm?.plan_end_at,
        };
      } else {
        // Free plan with number_of_days
        payload = {
          ...commonData,
          plan_duration: selectedPlanType,
          number_of_days: Number(createFamilyForm?.number_of_days),
        };
      }
    }

    setPageLoading(true);
    let responseObj;
    if (params?.id) {
      payload.id = params?.id;

      responseObj = await updateFamilyPlans(payload, payload?.id);
      if (responseObj.response.code === 200) {
        Toast.success('The family has been updated successfully');
        history.goBack();
      } else {
        setPageLoading(false);
        Toast.error(
          responseObj?.data
            ? responseObj?.data
            : Messages?.Error?.COMMON_MESSAGE
        );
      }
    } else {
      addNewFamilyPlan();
    }
  };

  const handleProductFamilySelectChange = (e) => {
    e.preventDefault();
    setProductFamilySelectedOption(e.target.value);
  };

  const handleScanPerDaySelectChange = (e) => {
    e.preventDefault();
    if (!e.target.value) setScansPerDaySelectedOption(null);
    setScansPerDaySelectedOption(e.target.value);
  };

  const handleEmergencyScanPerDaySelectChange = (e) => {
    e.preventDefault();
    if (!e.target.value) setEmergencyScansPerDaySelectedOption(null);
    setEmergencyScansPerDaySelectedOption(e?.target?.value);
  };

  const handleBillingFrequencySelectChange = (e) => {
    e.preventDefault();
    setBillingFrequencySelectedOption(e.target.value);
  };

  const handlePlanDurationSelectChange = (e) => {
    e.preventDefault();

    const { value } = e.target;
    if (!value) {
      setPlanDurationSelectedOption(null);
    }
    setPlanDurationSelectedOption(value);
  };

  const handleCountrySelectChange = (e) => {
    e.preventDefault();
    setCountrySelectedOption(e.target.value);
  };

  const onStartDateSelected = (date) => {
    setStartDate(date);
    setCreateFamilyForm((prevFormData) => ({
      ...prevFormData,
      plan_start_from: date,
    }));
  };

  const onEndDateSelected = (date) => {
    setEndDate(date);
    setCreateFamilyForm((prevFormData) => ({
      ...prevFormData,
      plan_end_at: date,
    }));
  };

  const handleChange = (e) => { 
    const { value, name } = e.target;
    setCreateFamilyForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  
  return (
    <>
      <div className='py-3 px-5'>
        <HeaderWithBackButton
          title='Add/edit Family Plans'
          goTo={APP_CONSTANTS.CMS_VITALS_FAMILY_PLANS}
        />

        <label className='mt-4 d-block w-100'>
          Product Family*
          <select
            className='mt-2 w-100 d-block c-select'
            name='product_family'
            value={productFamilySelectedOption}
            onChange={handleProductFamilySelectChange}
          >
            <option value=''>Please select an option</option>
            {productFamilyOptions &&
              productFamilyOptions?.map((option) => (
                <option key={option.id} value={option.value}>
                  {option.value}
                </option>
              ))}
          </select>
        </label>

        <NewInput
          id='plan_name'
          label='Plan Name*'
          type='text'
          placeholder='Plan Name'
          name='plan_name'
          value={createFamilyForm.plan_name}
          onChange={handleFormInputChange}
        />

        <NewInput
          id='internal_plan_name'
          label='Internal Plan Name'
          type='text'
          placeholder='Internal Plan Name'
          name='internal_plan_name'
          value={createFamilyForm.internal_plan_name}
          onChange={handleFormInputChange}
        />

        <NewInput
          id='description'
          label='Description'
          type='text'
          placeholder='Description'
          name='description'
          value={createFamilyForm.description}
          onChange={handleFormInputChange}
        />
        {BreathingExercise?.service_value === "breathing_exercise" ? '' : (
          <label className='mt-4 d-block w-25'>
            Scans per day*
            <select
              className='mt-2 w-100 d-block c-select'
              name='scans_per_day'
              value={scansPerDaySelectedOption}
              onChange={handleScanPerDaySelectChange}
            >
              <option value=''>Please select an option</option>
              {scansPerDayOptions &&
                scansPerDayOptions?.map((option) => (
                  <option key={option.id} value={option.value}>
                    {option.value}
                  </option>
                ))}
            </select>
          </label>
        )}

        {BreathingExercise?.service_value === "breathing_exercise" ? '' : (
          <div className='d-flex align-items-start mt-4'>
            <div className='d-inline-block'>
              <label className='d-block'>Emergency scans per day*</label>
            </div>
            <div className='d-inline-block mx-4'>
              {/* Emergency Toggle Start */}
              <div className='d-inline-block'>
                <span className='me-2 d-block'>Status</span>
                <label className='d-inline-block mt-2'>
                  <div className='d-inline-flex align-items-center'>
                    <div className='d-inline-flex text-danger'>OFF</div>
                    <div className='d-inline-flex mx-2'>
                      <Toggle
                        checked={emergencyScansToggleStatus}
                        icons={false}
                        onChange={handleEmergencyToggleChange}
                      />
                    </div>
                    <div className='d-inline-flex text-green'>ON</div>
                  </div>
                </label>
              </div>
            </div>
            <div className='d-inline-block w-25'>
              <select
                disabled={emergencyScansToggleStatus === false ? true : false}
                className='mt-2 w-100 d-inline-block c-select'
                name='emergency_scans_per_day'
                value={emergencyScansPerDaySelectedOption}
                onChange={handleEmergencyScanPerDaySelectChange}
              >
                <option value=''>Please select an option</option>
                {emergencyScansPerDayOptions &&
                  emergencyScansPerDayOptions?.map((option) => (
                    <option key={option.id} value={option.value}>
                      {option.value}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        )}


        {/* Paid Toggle Start */}
        <div className='d-block my-4'>
          <span className='me-2'>Paid*</span>
          <label className='d-inline-block'>
            <div className='d-inline-flex align-items-center'>
              <div className='d-inline-flex text-danger'>OFF</div>
              <div className='d-inline-flex mx-2'>
                <Toggle
                  checked={paidToggleStatus}
                  icons={false}
                  onChange={handlePaidToggleChange}
                />
              </div>
              <div className='d-inline-flex text-green'>ON</div>
            </div>
          </label>
        </div>

        {/* For Paid and Free Start */}
        {paidToggleStatus ? (
          <>
            <div className="d-flex align-items-center gap-5">
              <label className='mt-4 d-block w-25'>
                Billing Frequency*
                <select
                  className='mt-2 w-100 d-block c-select'
                  name='billing_frequency'
                  value={billingFrequencySelectedOption}
                  onChange={handleBillingFrequencySelectChange}
                >
                  <option value=''>Please select an option</option>
                  {billingFrequencyOptions &&
                    billingFrequencyOptions?.map((option) => (
                      <option key={option.id} value={option.value}>
                        {option.value}
                      </option>
                    ))}
                </select>
              </label>
              {
                billingFrequencySelectedOption === 'DAYS' && (

                  <NewInput
                    id="number_of_days"
                    label="Number of Days"
                    type="number"
                    min="1"
                    max="30"
                    placeholder="Number of Days"
                    name="number_of_days"
                    value={createFamilyForm.number_of_days}
                    onChange={handleFormInputChange}
                  />

                )
              }
            </div>
            <label className='mt-4 d-block w-25'>
              Country*
              <select
                className='mt-2 w-100 d-block c-select'
                name='country'
                value={countrySelectedOption}
                onChange={handleCountrySelectChange}
              >
                <option value=''>Please select an option</option>
                {countryListOptions &&
                  countryListOptions?.map((option) => (
                    <option key={option.id} value={option.value}>
                      {option.value}
                    </option>
                  ))}
              </select>
            </label>

            <div className='d-flex align-items-center mt-4'>
              <label className='d-inline-block me-3'>Add Pricing*</label>
              <label className='d-inline-block w-25 text-center py-2 c-label'>
                {billingFrequencySelectedOption}
              </label>
            </div>

            <div
              className='d-block w-50 mt-4'
              style={{ border: '1px solid #CBCBCB', padding: '0 30px 30px' }}
            >
              <NewInput
                id='display_pricing'
                label='Add Display Pricing'
                type='number'
                placeholder='Rs'
                name='display_pricing'
                value={createFamilyForm?.display_pricing}
                onChange={handleFormInputChange}
              />
              <NewInput
                id='discount'
                label='Add Discount'
                type='number'
                placeholder='%'
                name='discount'
                value={createFamilyForm?.discount}
                onChange={handleFormInputChange}
              />
              <NewInput
                id='final_pricing'
                label='Final Pricing'
                type='number'
                placeholder='Rs'
                name='final_pricing'
                value={createFamilyForm?.final_pricing}
                onChange={handleFormInputChange}
              />
              {billingFrequencySelectedOption &&
              billingFrequencySelectedOption !== 'MONTHLY' && billingFrequencySelectedOption !== 'DAYS' ? (
                <NewInput
                  id='monthly_pricing'
                  label='Monthly Pricing'
                  type='number'
                  placeholder='Rs'
                  name='monthly_pricing'
                  value={createFamilyForm?.monthly_pricing}
                  onChange={handleFormInputChange}
                />
              ) : (
                ''
              )}
              <div className='d-flex justify-content-between mt-4'>
                <label className='d-inline-block'>
                  Show as Recommended Plan
                </label>
                <label className='d-inline-block'>
                  {/* Recommended Plan Toggle Start */}
                  <div className='d-inline-flex align-items-center'>
                    <div className='d-inline-flex text-danger'>OFF</div>
                    <div className='d-inline-flex mx-2'>
                      <Toggle
                        checked={recommendToggleStatus}
                        icons={false}
                        onChange={handleRecommendToggleChange}
                      />
                    </div>
                    <div className='d-inline-flex text-green'>ON</div>
                  </div>
                </label>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='d-flex align-items-end'>
              <label className='mt-4 d-inline-block w-25'>
                Plan Duration*
                <select
                  className='mt-2 w-100 d-block c-select'
                  name='plan_duration'
                  value={planDurationSelectedOption}
                  onChange={handlePlanDurationSelectChange}
                >
                  <option value=''>Please select an option</option>
                  {planDurationOptions &&
                    planDurationOptions?.map((option) => (
                      <option key={option.id} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                </select>
              </label>

              {planDurationSelectedOption === 'DAY' ? (
                <div className='d-inline-block w-25 ms-4'>
                  <NewInput
                    id='number_of_days'
                    label='Number of Days'
                    type='number'
                    placeholder='Number of Days'
                    name='number_of_days'
                    value={createFamilyForm.number_of_days}
                    onChange={handleFormInputChange}
                  />
                </div>
              ) : null}
            </div>

            {planDurationSelectedOption === 'CUSTOM_DATE_RANGE' ? (
              <>
                {/* Date Picker */}
                <Calender
                  onStartDateSelected={onStartDateSelected}
                  onEndDateSelected={onEndDateSelected}
                  startDate={startDate}
                  endDate={endDate}
                  mandatory={true}
                />
              </>
            ) : null}
          </>
        )}
        {/* For Paid and Free End */}

        {/* Status Toggle Start */}
        {!paidToggleStatus && (
          <div className='d-block my-4'>
            <span className='me-2'>Status*</span>
            <label className='d-inline-block'>
              <div className='d-inline-flex align-items-center'>
                <div className='d-inline-flex text-danger'>OFF</div>
                <div className='d-inline-flex mx-2'>
                  <Toggle
                    checked={toggleStatus}
                    icons={false}
                    onChange={handleToggleChange}
                  />
                </div>
                <div className='d-inline-flex text-green'>ON</div>
              </div>
            </label>
          </div>
        )}
        {BreathingExercise?.service_value === "breathing_exercise" ? '' : (
          <div className='row'>
            <div className='col-sm-3 mb-4'>
              <Dropdown
                label='User Scan Experience*'
                placeholder='Select'
                name='user_experience'
                value={createFamilyForm.user_experience}
                options={DropdownOptions.userExperienceOptions}
                handleChange={handleChange}
              />
            </div>
          </div>
        )}

        <Button
          className={paidToggleStatus && 'mt-4'}
          onClick={handleCreateFamilyPlan}
          type='submit'
          name='Submit'
        />
      </div>

      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </>
  );
};

export default CreateFamilyPlans;
