import React from 'react';

// Pages
import BasePage from 'pages/BasePage';  

import { AdTaskListHeader } from 'constants/table-header-constants';
import AdTaskList from 'app/container/UserJourney/AdTaskList';

const AdTask = () => {
  return (
    <BasePage>
      <AdTaskList  tableHeader={AdTaskListHeader}/>
    </BasePage>
  );
};

export default AdTask;
