import React, { useEffect, useRef } from 'react';
import './index.scss';

const FaqActionMenu = ({ onClickOutside, row, menuAction, index }) => {
  const dropdownWrapperRef = useRef(null);
  const handleClickOutside = (event) => {
    if (
      dropdownWrapperRef.current &&
      !dropdownWrapperRef.current.contains(event.target)
    ) {
      onClickOutside();
    }
  };
  const handleHideDropdown = (event) => {
    if (event.key === 'Escape') {
      onClickOutside();
    }
  };
  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', (e) => {
      handleClickOutside(e);
    });
    document.addEventListener('keydown', (e) => {
      handleHideDropdown(e);
    });
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', (e) => {
        handleClickOutside(e);
      });
      document.removeEventListener('click', (e) => {
        handleClickOutside(e);
      });
    };
  }, [dropdownWrapperRef]);

  return (
    <div ref={dropdownWrapperRef} className='FaqAction'>
      {menuAction &&
        menuAction.length > 0 &&
        menuAction.map((menu) => {
          return (
            <p
              onClick={(e) => {
                setTimeout(() => {
                  onClickOutside && onClickOutside();
                }, 50);
                menu.onClick(e, row, index);
              }}
              className='FaqAction-text'
            >
              {menu.name}
            </p>
          );
        })}
    </div>
  );
};

export default FaqActionMenu;
