import CTAWidgetComponent from 'app/components/NewDesign/CTAWidgetComponent';
import NewAttachment from 'app/components/NewDesign/NewAttachment';

import SmallBannerCard from 'app/components/SmallBannerCard';
import DropdownOptions from 'app/utils/DropdownOptions';
import useCoursesBanner from './useCoursesBanner';
import Button from 'app/components/button';
import PageLoader from 'app/components/PageLoader';

const CoursesBannerContainer = () => {
  const {
    OnhandleChange,
    bannerlistData,
    isPublishButtonDisabled,
    onFileSelected,
    handleResourseToggle,
    validateRequestpreviewScreen,
    pageLoading,
  } = useCoursesBanner();
  return (
    <>
      <div className='section-header'>
        <h4>Courses Banners</h4>

        <Button
          onClick={validateRequestpreviewScreen}
          type='submit'
          name='Publish'
          isDisabled={isPublishButtonDisabled}
          // cl
        />
      </div>
      {bannerlistData.map((data, index) => {
        return (
          <SmallBannerCard
            smallBannerCardkey={data?.id}
            title={data?.title}
            index={index}
            // handleRemoveCard={removeServiceSection}
            isNew={data?.isNew}
            toggle={data?.toggle}
            showToggle={true}
            handleResourseToggle={handleResourseToggle}
          >
            <div className='widget_box'>
              <div>
                <NewAttachment
                  value={data?.attachment}
                  id={data?.id}
                  onFileSelected={onFileSelected}
                  fileType='image'
                  label={`Add Service ${index + 1} `}
                  className='widget_box_feed__attachment'
                />
              </div>
              <div className='widget_box_divide'></div>
              <CTAWidgetComponent
                id={data?.id}
                index={data?.id}
                externalUrl={data?.externalUrl}
                ctaValue={data?.title}
                urlType={data?.urlType}
                DropdownOptions={DropdownOptions.InternalUrl}
                value={data?.url}
                isHide={true}
                onChange={OnhandleChange}
                swappingOrder={data?.swappingOrder}
                redirection_id={
                  data?.redirection_id ? data?.redirection_id : null
                }
                OnhandleChange={OnhandleChange}
                urlCopy={data?.urlCopy}
                redirection_idURL={data?.redirection_idURL}
              />
            </div>
          </SmallBannerCard>
        );
      })}
      {pageLoading && <PageLoader pageLoading={pageLoading} />}
    </>
  );
};

export default CoursesBannerContainer;
