import RadioButton from 'app/shared/components/RadioButton';
import Button from 'app/components/button';
import '../index.scss';
import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import NewInput from 'app/components/NewInput';
import moment from 'moment';
import Toast from 'app/utils/Toast';
import PageLoader from 'app/components/PageLoader';
import { imageSizeValidation } from 'utils/Parsers';
import DropdownOptions from 'app/utils/DropdownOptions';
import ValidationMessage from 'utils/ValidationMessage';
import WebinarCalendar from 'app/components/WebinarCalendar';
import {
  createRecommendedStory,
  getRecommendedStoryDetails,
  updateRecommendedStory,
  updateRecommendedLocalisationStory,
} from 'app/api/recommendedAction';
import { CloseBlackIcon } from 'app/constants/image-constants';
import CTAType from 'app/components/CTAType';
import TabBar from 'app/components/NewDesign/TabBar';
import useTabBarOptions from 'app/utils/useTabbarOptions';
const CreateRecommendedActionContainer = () => {
  const [activeTab, setActiveTab] = useState({
    id: '1',
    name: 'English',
    short_name: 'en',
    display_order: 1,
    status: 'ACTIVE',
    value: 'English',
  });
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const params = useParams();
  const [internalUrl, setInternalURL] = useState([]);
  const { LanguageOptions } = useTabBarOptions();
  const [state, setState] = useState({
    // title: "",
    url_type: 'INTERNAL',
    url: '',
    expire_date: '',
    image1: '',
    image2: '',
    image3: '',
    image4: '',
    image5: '',
    image6: '',
    image7: '',
    asthma_order: '',
    copd_order: '',
    ar_order: '',
    ild_order: '',
    other_order: '',
    redirection_id: '',
    internal_for: '',
    internal_url: '',
    profile_data: false,
    custom_object: '',
  });
  const [storyInput, setStoryInput] = useState({
    image1: {
      label: 'Image 1',
      image_id: 'upload_one',
      image: '',
      name: 'image1',
      thumbnail_image: '',
    },
    image2: {
      label: 'Image 2',
      image_id: 'upload_two',
      image: '',
      name: 'image2',
      thumbnail_image: '',
    },
    image3: {
      label: 'Image 3',
      image_id: 'upload_three',
      image: '',
      name: 'image3',
      thumbnail_image: '',
    },
    image4: {
      label: 'Image 4',
      image_id: 'upload_four',
      image: '',
      name: 'image4',
      thumbnail_image: '',
    },
    image5: {
      label: 'Image 5',
      image_id: 'upload_five',
      image: '',
      name: 'image5',
      thumbnail_image: '',
    },
    image6: {
      label: 'Image 6',
      image_id: 'upload_six',
      image: '',
      name: 'image6',
      thumbnail_image: '',
    },
    image7: {
      label: 'Image 7',
      image_id: 'upload_seveen',
      image: '',
      name: 'image7',
      thumbnail_image: '',
    },
  });

  let history = useHistory();
  useEffect(() => {
    let internalUrl = [];
    DropdownOptions.InternalUrl.map((item, i) => {
      if (
        item.value !== 'feed' &&
        item.value !== 'content_detail' &&
        item.value !== 'quiz' &&
        item.value !== 'vital_activity' &&
        item.value !== 'webinar_details' &&
        item.value !== 'individual_quiz'
      ) {
        internalUrl.push(item);
      }
    });
    setInternalURL(internalUrl);
    if (params.id) {
      getRecommendedStoryDetail(params.id);
    }
  }, [activeTab?.short_name]);

  const getRecommendedStoryDetail = async (id) => {
    const storyDetail = await getRecommendedStoryDetails(
      id,
      activeTab?.short_name
    );
    setState({
      title: storyDetail.title,
      url_type: storyDetail.url_type,
      url: storyDetail.url,
      expire_date: moment(storyDetail.expire_date).format(
        'YYYY-MM-DD HH:mm:ss'
      ),
      image1: storyDetail.thumbnail_image[0],
      image2: storyDetail.thumbnail_image[1],
      image3: storyDetail.thumbnail_image[2],
      image4: storyDetail.thumbnail_image[3],
      image5: storyDetail.thumbnail_image[4],
      image6: storyDetail.thumbnail_image[5],
      image7: storyDetail.thumbnail_image[6],
      asthma_order: storyDetail.asthma_order ? storyDetail.asthma_order : '',
      copd_order: storyDetail.copd_order ? storyDetail.copd_order : '',
      ar_order: storyDetail.ar_order ? storyDetail.ar_order : '',
      ild_order: storyDetail.ild_order ? storyDetail.ild_order : '',
      other_order: storyDetail.other_order ? storyDetail.other_order : '',
      redirection_id: storyDetail.redirection_id
        ? storyDetail.redirection_id
        : '',
      internal_for: storyDetail.internal_for,
      internal_url: storyDetail.internal_url,
      profile_data: storyDetail.profile_data,
      custom_object: storyDetail.custom_object,
    });

    let tempObj = storyInput;
    let storyArray = Object.keys(storyInput);

    for (let index in storyArray) {
      tempObj[storyArray[index]]['image'] = storyDetail.thumbnail_image[index];
      if (storyDetail.thumbnail_image[index]) {
        tempObj[storyArray[index]][
          'thumbnail_image'
        ] = `${process.env.REACT_APP_S3_BUCKET_URL}/${storyDetail.thumbnail_image[index]}`;
      } else {
        tempObj[storyArray[index]]['thumbnail_image'] = '';
      }
    }
    setStoryInput(tempObj);
  };

  const radioMenu = [
    {
      id: 'internal_redirect',
      label: 'Internal Redirect',
      value: 'INTERNAL',
    },
    {
      id: 'external_redirect_pwa',
      label: 'External Redirect(PWA)',
      value: 'EXTERNAL_PWA',
    },
    {
      id: 'external_redirect_outside_app',
      label: 'External Redirect(Outside APP)',
      value: 'EXTERNAL',
    },
  ];

  const handleTextChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleMediaUpload = (e, key, subKey) => {
    let file = e.target.files[0];
    if (
      (file.type === 'image/jpeg' ||
        file.type === 'image/jpg' ||
        file.type === 'image/png') &&
      imageSizeValidation(e)
    ) {
      Toast.error(ValidationMessage.IMAGE_SIZE);
      return;
    } else {
      let reader = new FileReader();
      // let thumbnail_image
      reader.onloadend = function () {
        setStoryInputById(key, subKey, file, reader.result);
      };
      reader.readAsDataURL(file);

      // setStoryInputById(key, subKey,file,  )
      Toast.success(file?.name + ' uploaded');
    }
  };

  const onWebinarStartDateSelected = (date) => {
    setState({ ...state, expire_date: date });
    // setWebinarStartDate(date);
  };

  const setStoryInputById = (key, subKey, value, thumbnail_image) => {
    setStoryInput({
      ...storyInput,
      [key]: {
        ...storyInput[key],
        [subKey]: value,
        thumbnail_image: thumbnail_image,
      },
    });
  };

  const handleSubmit = async () => {
    let payload = {};
    if (activeTab?.name === 'English') {
      payload = { ...state };
    }
    if (
      !storyInput.image1.image ||
      !storyInput.image2.image ||
      !storyInput.image3.image ||
      !storyInput.image4.image ||
      !storyInput.image5.image ||
      !storyInput.image6.image ||
      !storyInput.image7.image
    ) {
      Toast.error('Please complete all fields');
      return;
    } else {
      payload.image1 = storyInput.image1.image;
      payload.image2 = storyInput.image2.image;
      payload.image3 = storyInput.image3.image;
      payload.image4 = storyInput.image4.image;
      payload.image5 = storyInput.image5.image;
      payload.image6 = storyInput.image6.image;
      payload.image7 = storyInput.image7.image;

      setLoader(true);

      let responseObj;
      if (params.id) {
        payload.story_id = params.id;
        if (activeTab?.name === 'English') {
          responseObj = await updateRecommendedStory(payload);
        } else {
          responseObj = await updateRecommendedLocalisationStory(
            payload,
            activeTab?.short_name
          );
        }
      } else {
        responseObj = await createRecommendedStory(payload);
      }

      if (
        responseObj &&
        responseObj.response &&
        responseObj.response.code === 200
      ) {
        Toast.success(responseObj.response.alert[0].message);
        history.push('/recommended-action');
      } else {
        setLoader(false);
        Toast.error(
          responseObj.response.alert[0].message
            ? responseObj.response.alert[0].message
            : responseObj.data
            ? responseObj.data.msg
            : ''
        );
      }
    }
  };

  const [hideFields, setHideFields] = useState(false);
  const handleTabChange = (val) => {
    setActiveTab(val);
    if (val?.name === 'English') {
      setHideFields(false);
    } else {
      setHideFields(true);
    }
    // Perform any other actions on tab change
  };

  return (
    <div className='container'>
      <h1 className='section-header__title'>Recommended Action/ Add Action</h1>

      {params.id && (
        <TabBar
          tabs={LanguageOptions}
          initialTab={activeTab}
          onTabChange={handleTabChange}
          parentStyle={'service-tab-style'}
        />
      )}

      <div className='separator'>
        <p>Add Action</p>
      </div>
      <div className='userInput'>
        {/* <NewInput
                    label="Action Title"
                    name={"title"}
                    value={state.title}
                    onChange={handleTextChange}
                /> */}
        {!hideFields && (
          <div className='userFormArea'>
            <label>Redirect Type</label>
            {/* {radioMenu.map((item, index) => (
                        <RadioButton
                            id={item.id}
                            label={item.label}
                            isChecked={state.url_type == item.value ? true : false}
                            value={item.value}
                            onSelect={() => { setState({ ...state, url_type: item.value }) }}
                            name={item.label}
                           style={{ marginTop: '-15px'}}
                        />
                    ))} */}

            <CTAType
              cta_type={state.url_type}
              handleChange={(key, data) => {
                if (key === 'cta_type') {
                  if(!data ){
                    setState({ ...state, url_type: data, url:'' });
                  }else{
                    setState({ ...state, url_type: data });
                  } 
                } else {
                  if (
                    key === 'url' &&
                    (data !== 'zoom_live_session' ||
                      data !== 'infinite_scroll' ||
                      data !== 'group_of_collection')
                  ) {
                    setState({ ...state, [key]: data, redirection_id: '' });
                  } else {
                    setState({ ...state, [key]: data });
                  }
                }
              }}
              internalUrl={internalUrl}
              internal_for={state.internal_for}
              internal_url={state.internal_url}
              profile_data={state.profile_data}
              custom_object={state.custom_object}
              url={state.url}
              redirection_id={state.redirection_id}
              handleTextChange={handleTextChange}
            />
          </div>
        )}
        <div className='userFormArea horizontal-align'>
          {/* {state.url_type === 'INTERNAL' ? 
                    <div className="dropdown-area">
                        <label>Internal App</label> 
                        <select
                            name="url"
                            
                            className="custom-select" onChange={(e) => {
                                setState({ ...state, url: e.target.value })
                            }}>
                            <option value="">Select</option>
                            {
                                internalUrl?.map((item, indx) => (
                                    <option key={indx}
                                        value={item.value}
                                        selected={state.url == item.value ? true : false}
                                    >
                                        {item.label}</option>
                                ))
                            }

                        </select>
                    </div>
                    :
                    <div className="input-container">
                        <NewInput
                            label="External Redirect Link"
                            name="url" 
                            onChange={handleTextChange}
                            value={state.url}

                        />
                        
                    </div>
                    } */}
        </div>
        {/* {(state.url === 'infinite_scroll' || state.url === 'zoom_live_session') && 
                     <div className="input-container">
                        <NewInput
                            label="Redirection Id"
                            name="redirection_id" 
                            onChange={handleTextChange}
                            value={state.redirection_id}

                        />
                        
                    </div>
                    } */}
        <div className='userFormArea horizontal-align'>
          <div className='col'>
            <div className='row'>
              {Object.keys(storyInput).map((item, index) => (
                <div key={index}>
                  <div className='label-button'>
                    <input
                      type='file'
                      id={storyInput[item].image_id}
                      style={{ display: 'none' }}
                      onChange={(e) => {
                        handleMediaUpload(e, item, 'image');
                      }}
                    />
                    <label className='label-title-input'>
                      {storyInput[item].label} :{' '}
                    </label>
                    <Button
                      isDisabled={storyInput[item].image}
                      name={storyInput[item].image ? 'Uploaded' : 'Upload'}
                      onClick={() => {
                        const m = document.getElementById(
                          storyInput[item].image_id
                        );
                        m.click();
                      }}
                    />
                    <label
                      role={'button'}
                      className='label-title-input'
                      style={{ paddingLeft: '10px' }}
                      onClick={(e) => {
                        if (storyInput[item].image != '') {
                          setStoryInputById(item, 'image', '', '');
                        }
                      }}
                    >
                      {storyInput[item].image != '' ? 'Cancel Upload' : ''}
                    </label>

                    <div className='d-inline-block'>
                      {storyInput[item].thumbnail_image && (
                        <div className='attachment_img_wrapper'>
                          <img
                            src={storyInput[item].thumbnail_image}
                            alt='sample'
                            className='feed__attachment'
                          />
                          <div className='attachment_img_wrapper_close'>
                            <img
                              src={CloseBlackIcon}
                              alt='Remove'
                              onClick={(e) => {
                                setStoryInputById(item, 'image', '', '');
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
              {/* {imageArray.map((item, index) =>{
                            let tempName = item.name
                            return(
                                <div className="col-md-12">
                               
                                <input type="file" id={item.name}
                                    style={{ display: "none" }}
                                    name={item.name}
                                    onChange={(e) => {
                                        handleMediaUpload(e)
                                    }}
                                /> 
                                <label className="label-title-input">{item.label} </label>
                                    <Button
                                        onClick={() => {
                                            const image = document.getElementById(item.name)
                                            image.click()
                                        }}
                                        // isDisabled={state.item.name}
                                        type='button'
                                        name={state.thumbnail_image != "" ? "Uploaded" : 'Upload Image'}

                                    />
                                    <label> 
                                        {
                                            state.thumbnail_image && <p className="cancel" onClick={(e) => { 
                                                setState({ ...state, thumbnail_image: "" })
                                            }}>Cancel Thumbnail</p>
                                        }
                                    </label> 
                            </div>
                            )
                            })} */}
            </div>
          </div>
        </div>
        <NewInput
          label={'Asthama Order'}
          name={'asthma_order'}
          value={state.asthma_order}
          onChange={handleTextChange}
          isFieldHidden={hideFields}
        />
        <NewInput
          label={'COPD Order'}
          name={'copd_order'}
          value={state.copd_order}
          onChange={handleTextChange}
          isFieldHidden={hideFields}
        />
        <NewInput
          label={'ILD Order'}
          name={'ild_order'}
          value={state.ild_order}
          onChange={handleTextChange}
          isFieldHidden={hideFields}
        />
        <NewInput
          label={'AR Order'}
          name={'ar_order'}
          value={state.ar_order}
          onChange={handleTextChange}
          isFieldHidden={hideFields}
        />
        <NewInput
          label={'Other Order'}
          name={'other_order'}
          value={state.other_order}
          onChange={handleTextChange}
          isFieldHidden={hideFields}
        />
        {!hideFields && (
          <WebinarCalendar
            onStartDateSelected={onWebinarStartDateSelected}
            startDate={state.expire_date}
            label='Expiry Time'
          />
        )}
        <div className='userFormArea'>
          <Button
            name={'Save'}
            onClick={handleSubmit}
            // isDisabled={!state.video_thumbnail || !state.endless_scroll_media || !state.title}
          />
          <label
            style={{ paddingLeft: '10px', cursor: 'pointer' }}
            onClick={() => {
              history.goBack();
            }}
          >
            Cancel
          </label>
        </div>
      </div>
      {loader && <PageLoader pageLoading={loader} />}
    </div>
  );
};

export default CreateRecommendedActionContainer;
